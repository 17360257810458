import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';

import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { ApplicationLabels } from 'components/ApplicationLabels';
import { UserAvatar } from 'components/UserAvatar';
import { Text } from 'components/Text';
import { ApplicationOptions } from 'components/ApplicationOptions';
import { IconButton } from 'components/IconButton';
import { IconOnboarding } from 'components/icons/IconOnboarding';
import { ButtonGroup } from 'components/Button';
import { Tooltip } from 'components/Tooltip';
import { IconPersonAdd } from 'components/icons/IconPersonAdd';
import { Select } from 'components/Select';
import { IconTextShort } from 'components/icons/IconTextShort';
import { useMobile } from 'hooks/useMobile';

export const ApplicationHeader = observer(({ data }) => {
  const { hasPerm, openModal, t } = useStore();
  const { job } = data;
  const isMobile = useMobile();
  const dt = format(new Date(data.date), 'MMMM do yyyy');

  function assign() {
    openModal('ASSIGN_APPLICANT', {
      autoWidth: false,
      applicant: data
    });
  }

  const stageItems = Array.from({ length: job.numStages }).map((_, i) => ({
    display: job[`stage${i + 1}Name`],
    value: i + 1
  }));

  return (
    <Box flex flexDirection={isMobile ? 'column' : 'row'}>
      <Box flex grow style={{ width: '100%' }}>
        <Tooltip id="apptip" />
        <UserAvatar name={data.name} size="xl" src={data.picture} />
        <Box ml="16" alignItems="center">
          <Text type="h1" color="purple100">
            {data.name}
          </Text>
          <Text type="regular16" color="purple60" mt="-4">
            {t('application.header.applied')} &bull; {dt}
          </Text>
          <ApplicationLabels application={data} />
        </Box>
      </Box>

      {hasPerm('application:view-options') && (
        <ButtonGroup right noWrap mt={isMobile ? 16 : 0}>
          {!data.assignedTo ? (
            <IconButton
              round
              onClick={assign}
              data-for="apptip"
              data-tip={t('application.tip.assign')}
            >
              <IconPersonAdd />
            </IconButton>
          ) : (
            <Box
              onClick={assign}
              data-for="apptip"
              data-tip={t('application.header.assignedto', data.assignedTo.name)}
            >
              <UserAvatar src={data.assignedTo.picture} name={data.assignedTo.name} size="lg" />
            </Box>
          )}
          {data.isHired && (
            <Link to={data.onboardingUrl}>
              <IconButton data-for="apptip" data-tip={t('application.gotoonboarding')}>
                <IconOnboarding />
              </IconButton>
            </Link>
          )}
          <Select
            maxWidth="17.5rem"
            onChange={stage => data.changeStage(Number(stage))}
            items={stageItems}
            defaultValue={data.stage}
            getTitleDisplayText={stage => (
              <Box flex alignItems="center">
                <IconTextShort />
                <Box ml="4">{stage}</Box>
              </Box>
            )}
          />
          <Box ml="8">
            <ApplicationOptions data={data} redirect={data.job.detailUrl} grayBg t={t} />
          </Box>
        </ButtonGroup>
      )}
    </Box>
  );
});
