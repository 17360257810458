import { types as t } from 'mobx-state-tree';

const CompanyTrigger = t.model('CompanyTrigger', {
  name: t.identifier,
  statuses: t.array(t.string)
});

const CompanyCategory = t.model('CompanyCategory', {
  id: t.number,
  name: t.string
});

export const CompanyInfo = t
  .model('CompanyInfo', {
    banner: t.maybeNull(t.string),
    companyName: t.string,
    defaultLanguage: t.enumeration('CompanyLanguage', ['en', 'is']),
    email: t.maybeNull(t.string),
    gaTracker: t.maybeNull(t.optional(t.string, '')),
    isEnterprise: t.boolean,
    languages: t.string,
    logo: t.maybeNull(t.string),
    categories: t.array(CompanyCategory),
    name: t.string,
    useSsnRegistry: false,
    numStages: t.number,
    stage1Name: 'Stage 1',
    stage2Name: 'Stage 2',
    stage3Name: 'Stage 3',
    stage4Name: 'Stage 4',
    stage5Name: 'Stage 5',
    stage6Name: 'Stage 6',
    theme: t.frozen(),
    url: t.string,
    isHiredTriggers: t.array(CompanyTrigger)
  })
  .views(self => ({
    get langs() {
      if (self.languages) {
        return self.languages.split(',');
      }
      return [];
    }
  }))
  .actions(self => ({
    setName(name) {
      self.companyName = name;
    },
    setUrl(url) {
      self.url = url;
    },
    setLanguage(lang) {
      self.defaultLanguage = lang;
    },
    setLanguages(langs) {
      const keys = langs ? langs.split(',') : [];
      if (!keys.includes(self.defaultLanguage) && keys.length > 0) {
        self.defaultLanguage = keys[0];
      }
      self.languages = langs;
    }
  }));
