import { types as t } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

export const EmailNotifications = t
  .model('EmailNotifications', {
    newApplication: false,
    mentionedInNote: false
  })
  .views(withEnv)
  .actions(self => ({
    toggleNewApplication() {
      self.env.api.notifications.toggleNewApplicantEmail(!self.newApplication);
      self.newApplication = !self.newApplication;
    }
  }));
