import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconFacebook32 = props => (
  <Svg32 {...props}>
    <rect x="1" y="1" width="30" height="30" rx="15" fill="#fff" stroke="#fff" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.778C8.145 1.778 1.778 8.145 1.778 16c0 7.855 6.367 14.222 14.222 14.222V19.477h-3.476v-4.741H16v-1.847c0-3.186 2.392-6.054 5.333-6.054h3.833v4.74h-3.833c-.419 0-.908.51-.908 1.272v1.889h4.74v4.74h-4.74V29.52c5.688-1.86 9.797-7.21 9.797-13.52 0-7.855-6.367-14.222-14.222-14.222z"
      fill="#4963A4"
    />
  </Svg32>
);
