import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Button } from 'components/Button';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';

export const OnboardApplicantModal = observer(({ closeModal, applicant }) => {
  const { t } = useStore();
  function onSubmit() {
    closeModal();
    applicant.goToOnboarding();
  }

  return (
    <>
      <ModalHeader>{t('application.onboardmodal.title')}</ModalHeader>
      <ModalText
        dangerouslySetInnerHTML={{
          __html: t('application.onboardmodal.confirm', applicant.name)
        }}
      />

      <ModalActions>
        <Button gray onClick={closeModal}>
          {t('application.onboardmodal.button.later')}
        </Button>
        <Button onClick={onSubmit}>{t('application.onboardmodal.button.go')}</Button>
      </ModalActions>
    </>
  );
});
