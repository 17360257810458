import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { isAlive } from 'mobx-state-tree';

export const Toasts = observer(({ store }) => (
  <ToastsContainer>
    {store.toasts.map(toast => (
      <Toast key={toast.id} toast={toast} />
    ))}
  </ToastsContainer>
));

export const Toast = observer(({ toast }) => {
  const [show, setShow] = useState(true);
  const [close, setClose] = useState(false);

  const closeRef = useRef(null);
  const clickRef = useRef(null);
  const showRef = useRef(null);

  const setTimeouts = useCallback(() => {
    closeRef.current = setTimeout(() => {
      setClose(true);
    }, 5000);
    showRef.current = setTimeout(() => {
      setShow(false);
      if (isAlive(toast)) {
        toast.delete();
      }
    }, 5500);
  }, [closeRef, toast]);

  useEffect(() => {
    setTimeouts();
    return () => clearTimeouts();
  }, [setTimeouts]);

  function clearTimeouts() {
    clearTimeout(closeRef.current);
    clearTimeout(showRef.current);
    clearTimeout(clickRef.current);
  }

  function onMouseEnter() {
    clearTimeouts();
  }

  function onMouseLeave() {
    setTimeouts();
  }

  function clickToClose() {
    setClose(true);
    clickRef.current = setTimeout(() => {
      setShow(false);
      if (isAlive(toast)) {
        toast.delete();
      }
      clearTimeouts();
    }, 500);
  }
  return (
    <ToastMessage
      type={toast.type}
      onClick={clickToClose}
      show={show}
      hide={!show}
      close={close}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div>{toast.text}</div>
    </ToastMessage>
  );
});

const ToastsContainer = styled.ul`
  width: 23.75rem;
  position: fixed;
  z-index: 99999999;
  padding: 0 0.75rem;
  bottom: 2rem;
  left: 50%;
  margin-left: -11.875rem;
`;

const fadeIn = keyframes`
  from {bottom: -2rem; opacity: 0;}
  to {bottom: 0px; opacity: 1;}
`;

const fadeOut = keyframes`
  from {bottom: 0px; opacity: 1;}
  to {bottom: -2rem; opacity: 0;}
`;

const ToastMessage = styled.li`
  background: linear-gradient(180deg, rgba(61, 64, 102, 0.9) 0%, #3d4066 100%);
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4), 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: ${p => p.theme.spacing.m12};
  position: relative;
  visibility: hidden;
  color: #fff;
  text-align: center;
  font-weight: ${p => p.theme.weights.bold};
  width: 100%;
  min-height: 3rem;
  overflow: hidden;
  border-radius: 0.25rem;
  z-index: 2;
  padding: ${p => p.theme.spacing.m16};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  ${p =>
    p.type === 'success' &&
    css`
      background: linear-gradient(180deg, rgba(61, 64, 102, 0.9) 0%, #3d4066 100%);
      box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4), 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  ${p =>
    p.type === 'error' &&
    css`
      background: linear-gradient(180deg, rgba(224, 88, 88, 0.9) 0%, #e05858 100%);
      box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4), 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  ${p =>
    p.show &&
    css`
      visibility: visible; /* Show the snackbar */
      animation: ${fadeIn} 0.5s;
    `};

  ${p =>
    p.hide &&
    css`
      visibility: hidden; /* Show the snackbar */
    `};
  ${p =>
    p.close &&
    css`
      animation: ${fadeOut} 0.5s;
    `};
`;
