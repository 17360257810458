export default {
  'application.addnote.error': 'Adding comment failed. Please try again.',
  'application.addnote.success': 'Comment successfully added.',
  'application.changestage.error': 'Error moving {0}. Please try again.',
  'application.changestage.success': '{0} moved to stage {1}',
  'application.chat.update.error': 'Unable to update note. Please try again.',
  'application.chat.update.success': 'Successfully updated note.',
  'application.setassignedto.success': 'Successfully assigned {0} to {1}',
  'application.setassignedto.remove': 'Successfully removed assignment from {0}',
  'application.setassignedto.error': 'Unable to assign {0} to {1}',
  'application.grantfollower.error': 'Unable to grant permission. Please try again.',
  'application.grantfollower.success': "Successfully granted {0} permission to {1}'s application.",
  'application.grantmultifollowers.error': 'Unable to grant permissions. Please try again.',
  'application.grantmultifollowers.success': "Successfully granted {0} permission(s) to {1}'s application.",
  'application.hire.error': 'Unable to hire {0}. Please try again.',
  'application.hire.success': 'Successfully hired {0}.',
  'application.onboard.error': 'Unable to onboard {0}. Please try again.',
  'application.onboard.success': 'Successfully marked {0} as onboarded.',
  'application.unonboard.error': 'Unable to unmark as {0} as onboarded. Please try again.',
  'application.unonboard.success': 'Successfully unmarked {0} as onboarded.',
  'application.keepname.error': 'Unable to update the applicant. Please try again.',
  'application.keepname.success': 'Successfully accepted the current applicant name.',
  'application.matchname.error': 'Unable to update the applicant name. Please try again.',
  'application.matchname.success': 'Applicant name now matches the national registry.',
  'application.reject.error': 'Unable to reject applicant. Please contact 50skills.',
  'application.reject.success.rejection': 'Successfully rejected applicant. A rejection e-mail will be sent.',
  'application.reject.success': 'Successfully rejected applicant.',
  'application.removefollower.error': 'Unable to revoke permission. Please try again.',
  'application.removefollower.success': "Successfully revoked {0} permission from {1}'s application.",
  'application.removenote.error': 'Removing comment failed. Please try again.',
  'application.removenote.success': 'Comment successfully removed',
  'application.removetasklist.error': 'Unable to remove checklist from application. Please try again.',
  'application.removetasklist.success': 'Successfully removed checklist from application.',
  'application.resetreview.error': 'Unable to reset your rating for {0}. Please try again.',
  'application.resetreview.success': 'Successfully reset your rating for {0}.',
  'application.setreview.error': 'Unable to set your rating for {0}. Please try again.',
  'application.setreview.success': 'Successfully set your rating for {0}',
  'application.store.delete.error': "Unable to delete {0}'s application. Please try again.",
  'application.store.delete.success': "Successfully deleted {0}'s application.",
  'application.task.toggle.error.done': 'Unable to mark {0} as done',
  'application.task.toggle.error.notdone': 'Unable to mark {0} as not done',
  'application.task.toggle.success.done': 'Successfully marked {0} as done',
  'application.task.toggle.success.notdone': 'Successfully marked {0} as not done',
  'application.unreject.error': 'Unable to un-reject applicant. Please try again.',
  'application.unreject.success': 'Applicant successfully un-rejected.',
  'application.unhire.error': 'Unable to un-hire applicant. Please try again or contact 50skills.',
  'application.unhire.success': 'Applicant successfully un-hired.',
  'application.retract.error': 'Unable to mark applicant as retracted. Please try again or contact 50skills.',
  'application.retract.success': 'Applicant successfully marked as retracted.',
  'application.unretract.error': 'Unable to unmark applicant as retracted. Please try again or contact 50skills.',
  'application.unretract.success': 'Applicant successfully unmarked as retracted.',
  'application.verifyssn.error.notfound': 'Unable to find SSN / ID number in the national registry.',
  'application.verifyssn.error': 'Unable to verify SSN / ID number. Please try again.',
  'application.verifyssn.keepormatch': 'SSN / ID number successfully verified but the name does not match the national registry record',
  'application.verifyssn.success': 'Name and SSN / ID number successfully verified.',
  'application.edit.ssn.success': 'Application SSN / ID number successfully updated',
  'application.edit.ssn.error': 'Unable to update application SSN / ID number. Please try again.',
  'changepass.submit.error': 'Unable to change password.',
  'changepass.submit.success': 'Password successfully changed',
  'changepass.validate.pass2error': "New passwords don't match.",
  'company.update.error': 'Unable to update company information. Please try again.',
  'company.update.success': 'Company information successfully updated.',
  'jobs.list.applicants': 'Applicants',
  'jobs.list.applicantstoday': 'Today',
  'jobs.list.location': 'Location',
  'jobs.list.deadline': 'Deadline',
  'dashboard.createjob': 'New job',
  'jobs.tabs.archived': 'Archived',
  'jobs.tabs.drafts': 'Drafts',
  'jobs.tabs.expired': 'Expired',
  'jobs.tabs.published': 'Published',
  'dashboardsettings.archived': 'Archived',
  'dashboardsettings.drafts': 'Drafts',
  'dashboardsettings.expired': 'Expired',
  'dashboardsettings.published': 'Published',
  'edituser.create.error': 'Error while creating user.',
  'edituser.create.success': 'User {0} successfully created.',
  'errors.required': 'This field is required',
  'general.en': 'English',
  'general.is': 'Icelandic',
  'helpers.defno': 'Definite no!',
  'helpers.no': 'No',
  'helpers.strongyes': 'Strong yes!',
  'helpers.yes': 'Yes',
  'hiremodal.button.cancel': 'Cancel',
  'hiremodal.button.submit': 'Hire',
  'hiremodal.confirmtext': 'Are you sure you want to hire the candidate',
  'hiremodal.title': 'Hire applicant',
  'retractmodal.button.cancel': 'Cancel',
  'retractmodal.button.submit': 'Mark as retracted',
  'retractmodal.confirmtext': 'Are you sure you want to mark the applicant as retracted:',
  'retractmodal.title': 'Mark applicant as retracted',
  'exportmodal.title': 'Export applicants',
  'exportmodal.labels.exporttype': 'Export type',
  'exportmodal.labels.headers': 'Select headers',
  'exportmodal.labels.questions': 'Application questions',
  'exportmodal.exporttype.xlsx': 'XLSX',
  'exportmodal.exporttype.csv': 'CSV',
  'exportmodal.fields.id': 'Application ID',
  'exportmodal.fields.name': 'Name',
  'exportmodal.fields.email': 'Email',
  'exportmodal.fields.phone': 'Phone',
  'exportmodal.fields.ssn': 'SSN / ID number',
  'exportmodal.fields.date': 'Date applied',
  'exportmodal.fields.is_rejected': 'Is rejected',
  'exportmodal.fields.is_notified_of_rejection': 'Is notified of rejection',
  'exportmodal.fields.date_rejected': 'Date of rejection',
  'exportmodal.fields.is_hired': 'Is hired',
  'exportmodal.fields.date_hired': 'Date of hire',
  'exportmodal.fields.is_retracted': 'Is retracted',
  'exportmodal.fields.is_manually_added': 'Is manually added',
  'exportmodal.fields.referred_site': 'Source',
  'exportmodal.fields.stage_name': 'Stage name',
  'exportmodal.fields.num_reviews': 'Number of reviews',
  'exportmodal.fields.num_notes': 'Number of notes',
  'exportmodal.select': 'Select all',
  'exportmodal.deselect': 'Deselect all',
  'exportmodal.button.cancel': 'Cancel',
  'exportmodal.button.submit': 'Export',
  'exportmodal.button.close': 'Close',
  'exportmodal.submitted.title': 'Gathering data',
  'exportmodal.submitted.confirmtext': 'The file is being processed and will be sent to your e-mail address: <strong>{0}</strong> when it is ready',
  'exportmodal.submitted.notice': 'Please notice this might take a couple of minutes and check your spam folder if you can\'t find it.',
  'integration.submit.error': 'Submission error. Please try again.',
  'integration.submit.save': 'Form saved.',
  'integration.delete.error': 'Delete error. Please try again.',
  'integration.preview.error': 'Preview error. Please try again or contact 50skills.',
  'integration.validators.ssn': 'Invalid SSN / ID number',
  'integration.validators.number': 'Input should only contain numbers',
  'integration.validators.bankNumber': 'Bank number should be 1-4 digits',
  'integration.validators.ledger': 'Ledger should be 2 digits',
  'integration.validators.accountNumber': 'Account number should be 1-6 digits',
  'job.addapplicant.error': 'Error adding applicant {0}. Please check for errors in the form and try again.',
  'job.addapplicant.success': 'Applicant {0} successfully added.',
  'job.archive.error': 'Error occured while archiving job. Please try again.',
  'job.archive.success': 'Successfully archived job.',
  'job.bulkgrant.error': 'Unable to reject applicant(s). Please try again or contact 50skills.',
  'job.bulkgrant.success': 'Successfully granted {0} user(s) access to {1} applicant(s).',
  'job.bulkreject.error': 'Unable to reject applicant(s). Please contact 50skills.',
  'job.bulkreject.success.marked': 'Successfully rejected {0} applicant(s) and marked them as notified',
  'job.bulkreject.success.notified': 'Successfully rejected {0} applicant(s) and notified them.',
  'job.bulkreject.success': 'Successfully rejected {0} applicant(s).',
  'job.bulkmove.success': 'Successfully moved {0} applicant(s) to stage {1}.',
  'job.bulkmove.error': 'Unable to move {0} applicant(s) to stage {1}.',
  'job.bulkmessage.error': 'Unable to send message to applicant(s). Please contact 50skills.',
  'job.bulkmessage.success': 'Successfully messaged {0} applicant(s).',
  'job.bulkmessage.title': 'Message applicant(s)',
  'job.bulkmessage.confirm.signel': 'Are you sure you want to message <strong>{0}</strong>?',
  'job.bulkmessage.confirm.multi': 'Are you sure you want to message the following applicants?',
  'job.bulkmessage.button.cancel': 'Cancel',
  'job.bulkmessage.button.submit': 'Send',
  'job.create.nolang.title': 'No language selected',
  'job.create.nolang.body': 'Press the + button on the left to add a language for this job.',
  'job.create.error': 'Unable to create job. Please try again.',
  'job.create.success': 'Successfully saved job as draft.',
  'job.create.field.required': 'This field is required',
  'job.drafts.error': 'Error occurred while moving job. Please try again.',
  'job.drafts.success': 'Job successfully moved to drafts.',
  'job.duplicate.error': 'Error white duplicating {0}. Please try again.',
  'job.duplicate.success': 'Successfully duplicated {0} and saved as draft.',
  'job.immediateupdate.error': 'Unable to update job detail. Please try agian.',
  'job.immediateupdate.success': 'Successfully updated job detail',
  'job.nodeadline': 'No deadline set',
  'job.nolocation': 'No location set',
  'job.notitle': 'No title set',
  'job.publish.error': 'Error while publishing {0}. Please try again.',
  'job.publish.success': 'Successfully published {0}.',
  'job.statistics.all': 'All time',
  'job.statistics.seven': 'Past 7 days',
  'job.statistics.thirty': 'Past 30 days',
  'job.statistics.today': 'Today',
  'job.statistics.from': 'From:',
  'job.tips.notvisible': 'Job is not visible on career page (private)',
  'job.tips.visible': 'Job is visible on career page (public)',
  'job.type.employee': 'Employee',
  'job.type.intern': 'Intern',
  'job.type.not_specified': 'Not specified',
  'job.type.seasonal': 'Seasonal',
  'job.type.temporary': 'Temporary',
  'job.unpublish.error': 'Error while unpublishing {0}. Please try again.',
  'job.unpublish.success': 'Successfully unpublished {0}.',
  'job.update.description.error': "Unable to update {0}'s description. Please try again.",
  'job.update.description.success': "{0}'s description updated successfully",
  'job.update.followers.error': "Unable to update {0}'s hiring team. Please try again.",
  'job.update.followers.success': "{0}'s hiring team updated successfully.",
  'job.update.form.error': "Unable to update {0}'s application form. Please try again.",
  'job.update.form.success': "{0}'s application form updated successfully.",
  'job.update.rewards.error': "Unable to update {0}'s rewards. Please try again.",
  'job.update.rewards.success': "{0}'s rewards updated successfully.",
  'job.options.archive': 'Archive',
  'job.options.drafts': 'Move to drafts',
  'job.options.duplicate': 'Duplicate',
  'job.options.edit': 'Edit',
  'job.options.publish': 'Publish',
  'job.options.share': 'Share',
  'job.options.unpublish': 'Unpublish',
  'job.options.delete': 'Delete',
  'job.options.delete.confirmation': 'job and all it\'s applications',
  'job.options.export': 'Export applicants',
  'job.store.delete.error': "Unable to delete {0}. Please try again.",
  'job.store.delete.success': "Successfully deleted {0}.",
  'jobsettings.tab.applicants': 'Applicants',
  'jobsettings.tab.integrations': 'Integrations',
  'jobsettings.tab.statistics': 'Statistics',
  'jobsettings.tab.team': 'Team',
  'navigation.careersite': 'Career page',
  'navigation.dashboard': 'Dashboard',
  'navigation.applicants': 'Applicants',
  'navigation.onboarding': 'Onboarding',
  'navigation.sharing': 'Sharing',
  'navigation.team': 'Team',
  'navigation.expand': 'Fix position',
  'navigation.collapse': 'Unfix position',
  'navigation.companies': 'Companies',
  'templates.store.newtasklist.name': 'New task list',
  'templates.store.removetasklist.error': 'Failed to remove template. Please try again.',
  'templates.store.removetasklist.success': 'Tasklist template successfully removed',
  'user.grant.error': 'Unable to grant {0} access to {1}. Please try again.',
  'user.grant.success': 'Granted {0} access to {1}.',
  'user.inactive': 'inactive',
  'user.revoke.error': "Unable to revoke {0}'s acces to {1}. Please try again.",
  'user.revoke.success': "{0}'s access to {1} successfully revoked.",
  'user.role.admin': 'Admin',
  'user.role.limited': 'Limited team member',
  'user.role.recruiter': 'Recruiter',
  'user.role.user': 'Employee',
  'usermenu.notifications': 'Notification settings',
  'usermenu.company.settings': 'Company settings',
  'usermenu.company.templates': 'Company templates',
  'usermenu.company.templates.emails': 'Email templates',
  'usermenu.company.templates.tasks': 'Task templates',
  'usermenu.company.apps': 'Apps / Integrations',
  'usermenu.help': 'Help',
  'usermenu.profile': 'Profile',
  'usermenu.signout': 'Sign out',
  'userstore.delete.error': 'Unable to delete user. Please try again.',
  'userstore.delete.success': 'User successfully deleted.',
  'job.header.applicants': 'Applicants',
  'job.header.today': 'Today',
  'job.followermenu.title': 'Hiring team',
  'application.onboardmodal.title': 'Onboard applicant',
  'application.onboardmodal.confirm': 'Would you like to onboard employee <strong>{0}</strong> now?',
  'application.onboardmodal.button.later': 'Onboard later',
  'application.onboardmodal.button.go': 'Go to onboarding',
  'rejectmodal.types.reject': 'Reject',
  'rejectmodal.types.rejecthelp': 'Marks the candidate as rejected. No notification is sent',
  'rejectmodal.types.rejectsend': 'Reject and send rejection email',
  'rejectmodal.types.rejectsendhelp': 'Send rejection emails to the selected candidates',
  'rejectmodal.types.rejectmark': 'Mark as rejected and notified',
  'rejectmodal.types.rejectmarkhelp': 'Marks the candidate as rejected and notified. No notification is sent',
  'rejectmodal.title': 'Reject applicant(s)',
  'rejectmodal.confirm.single': 'Are you sure you want to reject <strong>{0}</strong>?',
  'rejectmodal.confirm.multi': 'Are you sure you want to reject the following candidates?',
  'rejectmodal.confirmed.reject': 'The following candidates will be marked as rejected:',
  'rejectmodal.confirmed.rejectsend': 'The following candidates will receive a rejection email:',
  'rejectmodal.confirmed.rejectmark': 'The following candidates will be rejected and marked as notified:',
  'rejectmodal.excluded': 'The following candidates will not be included in this action:',
  'rejectmodal.already.notified': '(already rejected and notified)',
  'rejectmodal.already.rejected': '(already rejected)',
  'rejectmodal.method.title': 'Rejection method',
  'rejectmodal.method.drop.title': 'Select rejection method',
  'rejectmodal.button.cancel': 'Cancel',
  'rejectmodal.button.submit': 'Reject',
  'assignmodal.button.cancel': 'Cancel',
  'assignmodal.button.submit': 'Assign',
  'assignmodal.header': 'Assign applicant',
  'assignmodal.description': 'Assign a user from your team to this applicant',
  'assignmodal.select.emptystate': 'Select user',
  'rejectform.label.from': 'From',
  'rejectform.label.message': 'Message',
  'rejectform.label.templateselect': 'Name of template',
  'rejectform.template.drop.title': 'Select template',
  'rejectform.error.message': 'Please add some body text to the e-mail.',
  'editor.placeholder.title': 'Placeholder',
  'editor.placeholder.name': 'Applicant name',
  'editor.placeholder.job_title':'Job title',
  'editor.placeholder.sender_name': 'Sender name',
  'editor.placeholder.first_name': 'Applicant first name',
  'editor.placeholder.last_name': 'Applicant last name',
  'sharejob.header': 'Share job',
  'sharejob.copy.header': 'direct link',
  'copy.button.text': 'copy',
  'copy.button.copied': 'copied',
  'sharejob.maintext': 'This is a permanent link that references your 50skills Job ad. It does not change when you change the job ad title. This is the recommended way to share your form.',
  'sharejob.share.media': 'Share via <strong>{0}</strong>',
  'sharejob.rewards.header': 'Rewards for hired applicant',
  'userlist.header.name': 'name',
  'userlist.header.email': 'email',
  'userlist.header.role': 'role',
  'userlist.header.access': 'access',
  'userlist.header.status': 'status',
  'userlist.header.options': 'options',
  'application.options.email': 'Send email',
  'application.options.move': 'Move to job',
  'application.options.copy': 'Copy to job',
  'application.options.delete': 'Delete',
  'application.options.reject': 'Reject',
  'application.options.unreject': 'Unreject',
  'application.options.hire': 'Hire',
  'application.options.unhire': 'Unhire',
  'application.options.retract': 'Mark as retracted',
  'application.options.unretract': 'Unmark as retracted',
  'application.options.onboard': 'Onboard',
  'application.tip.assign': 'Assign applicant to a member of your team',
  'modals.customconfirm.title': 'Confirm',
  'modals.customconfirm.warning': 'This action can not be undone!',
  'modals.customconfirm.button.cancel': 'Cancel',
  'modals.customconfirm.button.submit': 'Confirm',
  'modals.removeconfirm.title': 'Confirm deletion',
  'modals.removeconfirm.confirm': 'Are you sure you wish to delete this {0}?',
  'modals.removeconfirm.warning': 'Warning, this action can not be undone!',
  'modals.removeconfirm.button.cancel': 'Cancel',
  'modals.removeconfirm.button.submit': 'Delete',
  'job.create.crumb.new': 'New job post',
  'job.create.crumb.editing': 'Edit job post',
  'job.create.step.1': 'Job details',
  'job.create.step.1.mobile': '1',
  'job.create.step.2': 'Application form',
  'job.create.step.2.mobile': '2',
  'job.create.step.3': 'Rewards',
  'job.create.step.3.mobile': '3',
  'job.create.step.4': 'Hiring team',
  'job.create.step.4.mobile': '4',
  'job.create.step.5': 'Preview and Publish',
  'job.create.step.5.live': 'Preview',
  'job.create.step.5.mobile': '5',
  'job.create.form.header': 'Application form',
  'job.create.form.show': 'Show',
  'job.create.form.required': 'Required',
  'job.create.form.social.header': 'Social Profiles',
  'job.create.form.questions.header': 'Questions',
  'job.create.form.button.addquestion': 'Add question',
  'job.create.form.label.coverletter': 'Cover Letter',
  'job.create.form.label.cv': 'CV / Resume',
  'job.create.form.label.name': 'Name',
  'job.create.form.label.email': 'Email',
  'job.create.form.label.phone': 'Phone',
  'job.create.form.label.ssn': 'SSN / ID number',
  'job.create.form.label.language': 'Application language',
  'job.create.form.questions.type.short': 'Short answer',
  'job.create.form.questions.type.para': 'Paragraph',
  'job.create.form.questions.type.single': 'Single-select',
  'job.create.form.questions.type.multi': 'Multi-select',
  'job.create.form.questions.type.attachment': 'Attachment',
  'job.create.form.questions.title.edit': 'Edit question',
  'job.create.form.questions.title': 'Add question',
  'job.create.form.questions.type.label': 'Question type',
  'job.create.form.questions.qlabel': 'Question',
  'job.create.form.questions.qerror': 'What would you like to know?',
  'job.create.form.questions.qopts': 'Options',
  'job.create.form.questions.button.cancel': 'Cancel',
  'job.create.form.questions.button.submit': 'Add Question',
  'job.create.form.questions.button.submitedit': 'Update Question',
  'job.create.form.questions.option.edit': 'Edit',
  'job.create.form.questions.option.moveup': 'Move Up',
  'job.create.form.questions.option.movedn': 'Move Down',
  'job.create.form.questions.option.delete': 'Delete',
  'job.create.form.questions.seeoptions': 'Click to see options',
  'job.create.form.options.tip.remove': 'Remove this option',
  'job.create.form.options.tip.add': 'Add another option below this one',
  'job.create.form.options.empty': 'Option value should not be empty',
  'job.create.header': 'Create job',
  'job.create.header.edit': 'Edit job',
  'job.create.description.header': 'Job Description',
  'job.create.button.cancel': 'Cancel',
  'job.create.button.save': 'Save',
  'job.create.button.back': 'Back',
  'job.create.button.savedraft': 'Save Draft',
  'job.create.button.cont': 'Continue',
  'job.create.button.savecont': 'Save & Continue',
  'job.create.label.title': 'Job title',
  'job.create.label.location': 'Location',
  'job.create.label.type': 'Job Type',
  'job.create.label.status': 'Job Status',
  'job.create.label.deadline': 'Application deadline (optional)',
  'job.create.label.short': 'Short description',
  'job.create.label.visibility': 'Visibility',
  'job.create.label.category': 'Department',
  'job.create.label.category.title': 'Select department',
  'job.create.short.error': 'Short job description is required',
  'job.create.save.language.error': 'Unable to save job. This job is currently published and {0} is enabled but contains errors. Please fix the errors or disable the language for this job.',
  'job.create.location.error': 'Location is required',
  'job.create.title.error': 'Job title is required',
  'job.create.label.long': 'About the job',
  'job.create.preview.button.close': 'Close',
  'job.create.preview.button.edit': 'Edit job',
  'job.create.preview.button.publish': 'Publish job',
  'job.create.preview.text': 'Live preview',
  'job.create.preview.openings': 'current openings',
  'job.create.preview.apply': 'apply now',
  'job.create.linkedField.tip': 'Linked between languages',
  'job.create.publish.language.error': 'Unable to publish job. {0} is enabled but is incomplete.',
  'job.create.publish.no_enabled_language': 'Unable to publish job. There are no enabled languages for it.',

  'job.create.languages.missing.title': 'Title',
  'job.create.languages.missing.description': 'Job description',
  'job.create.languages.missing.short': 'Short description',
  'job.create.languages.missing.location': 'Location',
  'job.create.languages.missing.form': 'Question form',
  'job.create.languages.header': 'Languages',
  'job.create.languages.add': 'Add language',
  'job.create.languages.formIncomplete': 'Form incomplete',
  'job.create.languages.descIncomplete': 'Description incomplete',
  'job.create.languages.menu.publish': 'Enable language',
  'job.create.languages.menu.unpublish': 'Disable language',
  'job.create.languages.menu.delete': 'Remove language',
  'job.create.languages.publish.incomplete': 'Could not enable {0} as the language data is incomplete. Please add the missing data and try enabling again. Incomplete: {1}',
  'job.create.languages.publish.error': 'Could not enable {0}. Please try again.',
  'job.create.languages.publish.success': 'Successfully enabled {0}.',
  'job.create.languages.unpublish.onlyone': 'Cannot disable {0}. The job is published and only has this language set.',
  'job.create.languages.unpublish.error': 'Could not disable {0}. Please try again.',
  'job.create.languages.unpublish.success': 'Successfully disabled {0}.',
  'job.create.languages.delete.error': 'Unable to remove language. {0}',
  'job.create.languages.delete.success': 'Language successfully removed.',
  'job.create.missing.question': 'No question text specified',
  'job.create.languages.enabled': 'This language is enabled',
  'job.create.languages.enabled.live': 'This language is currently live',
  'job.create.languages.disabled': 'This language is disabled',

  'job.types.employee': 'Employee',
  'job.types.temporary': 'Temporary / Contract',
  'job.types.intern': 'Intern',
  'job.types.seasonal': 'Seasonal',
  'job.types.not_specified': 'Not specified',
  'job.status.full_time': 'Full time',
  'job.status.part_time': 'Part time',
  'job.status.per_diem': 'Per diem',
  'job.status.not_specified': 'Not specified',
  'job.visibility.public': 'Shown on career page (public)',
  'job.visibility.private': 'Only via direct link (private)',

  'job.create.publish.modal.title': 'Job successfully published',
  'job.create.publish.modal.tagline': 'Now share it with the world!',
  'job.create.publish.modal.direct.title': 'Direct (for job boards etc.)',
  'job.create.publish.modal.direct.title.single': 'Direct',
  'job.create.publish.modal.en': 'English job description',
  'job.create.publish.modal.is': 'Icelandic job description',
  'job.create.publish.modal.direct.head': 'Direct link',
  'job.create.publish.modal.direct.sub': 'for job boards etc.',
  'job.create.publish.modal.refer.title': 'Referral',
  'job.create.publish.modal.refer.head': 'Unique referral link',
  'job.create.publish.modal.button.close': 'Close',
  'job.create.rewards.title': 'Rewards',
  'job.create.rewards.tagline': 'Select bonuses for your employees if their job share or sourcing leads to a hire.',
  'job.create.rewards.toggle': 'Sourcing bonus',
  'job.create.rewards.label.title': 'Title',
  'job.create.rewards.label.reward': 'Amount or Reward',
  'job.create.recruiters.title': 'Hiring team',
  'job.create.recruiters.pitch': 'Add existing users to the hiring team for this job. These users will be able to access this job-ad from the dashboard and all applications within it. If you are not sure, you can also do this after the job-ad has been created!',
  'job.create.recruiters.search.placeholder': 'Search',
  'dashboard.nojobs': 'No {0} jobs',
  'dashboard.alljobs': 'All {0} jobs will appear here',
  'dashboard.nosearchjobs': 'No results found',
  'dashboard.nosearchjobs.subtitle': 'There are no results based on your search',
  'dashboard.tabs.mobile.display': 'Show: {0}',
  'dashboard.search.placeholder': 'Search',
  'dashboard.search.tip': 'Search for job',
  'dashboard.tabs.jobs': 'Jobs',
  'dashboard.tabs.apps': 'Apps',
  'forgotpw.reseterror': 'No matching email found',
  'forgotpw.button.submit': 'Reset password',
  'forgotpw.button.submitting': 'Resetting...',
  'forgotpw.heading': 'Forgot your password?',
  'forgotpw.instruction': 'Enter your email address and we will send you instructions for resetting your password',
  'forgotpw.label.email': 'Email address',
  'forgotpw.button.back': 'Back to login page',
  'forgotpw.emailsent.heading': 'Check your email',
  'forgotpw.emailsent.text': 'We have sent a password reset link to:',
  'setpw.error.notmatching': 'Passwords do not match',
  'setpw.button.submit': 'Set password',
  'setpw.button.submitting': 'Setting password...',
  'setpw.heading': 'Choose a password',
  'setpw.instruction': 'You will use your email address and password to sign into 50skills.',
  'setpw.label.password': 'Password',
  'setpw.label.confirm': 'Confirm password',
  'company.settings.crumb.root': 'Settings',
  'company.settings.tabs.company': 'Company',
  'company.settings.tabs.apps': 'Apps',
  'company.settings.apps.slack.cta': 'Press the button below to link 50skills with Slack',
  'company.settings.root.selectlangs': 'Select languages',
  'company.settings.root.heading': 'Company Profile',
  'company.settings.root.label.name': 'Company name',
  'company.settings.root.label.url': 'Company URL',
  'company.settings.root.label.langs': 'Career page languages',
  'company.settings.root.label.lang': 'Default job language',
  'company.settings.root.button.cancel': 'Cancel',
  'company.settings.root.button.submit': 'Save',
  'company.settings.save.empty.languages': 'Unable to save. You need to select at least one career page language',
  'sharejob.careersite.maintext': 'This is a permanent link that references your 50skills career page. This is the recommended way to share your job listings.',
  'sharejob.careersite.title': 'Share career page',
  'sharejob.header.crumb.root': 'Sharing',
  'sharejob.header.crumb.careers': 'Career page',
  'sharejob.button.careers': 'Share career page',
  'sharejob.tips.reward': 'Job has reward for a referral that leads to a hire',
  'sharejob.tips.social': 'Share this job on social media',
  'sharejob.button.submit': 'Share',
  'signin.forgotpassword': 'Forgot your password?',
  'signin.button.submit': 'Sign in',
  'signin.button.submitting': 'Signing in...',
  'signin.heading': 'Sign in',
  'signin.label.email': 'Email',
  'signin.label.password': 'Password',
  'slack.success.bot': 'Slack Bot installed',
  'slack.success.user': 'Slack Account linked',

  'user.create.heading': 'Create a new user',
  'user.create.label.name': 'Name',
  'user.create.label.email': 'Email',
  'user.create.label.role': 'Role',
  'user.role.user.help': 'Can only access the job-ad sharing-page',
  'user.role.limited.help': 'Can only access specific applications',
  'user.role.recruiter.help': 'Can see and work with selected jobs',
  'user.role.admin.help': 'Full edit rights on eligible jobs & manage users',

  'user.create.sendinvite.toggle': 'Send invite',
  'user.create.addtonewjobs.toggle': 'Automatically add to new jobs',
  'user.create.button.cancel': 'Cancel',
  'user.create.button.submit': 'Save',

  'user.edit.update.errors': 'Unable to edit user. Check form errors and please try again.',
  'user.edit.update.success': 'User information successfully updated',
  'user.edit.heading': 'Edit user',
  'user.accessform.heading': 'Job access',
  'user.accessform.applicants': 'Applicants',
  'user.accessform.draft': 'Draft',
  'user.accessform.select': 'Select all',
  'user.accessform.deselect': 'Deselect all',

  'user.tabs.all': 'All users',
  'user.tabs.admins': 'Admins',
  'user.tabs.recruiters': 'Recruiters',
  'user.tabs.limited': 'Limited team members',
  'user.tabs.employees': 'Employees',
  'user.tabs.mobile.display': 'Show: {0}',
  'user.button.create': 'Add user',
  'user.search.tip': 'Search',

  'user.status.joined': 'Joined',
  'user.status.created': 'Created',
  'user.access.loading': 'Loading...',
  'user.access.sharingonly': 'Sharing page only',
  'user.access.jobs.singular': 'job',
  'user.access.jobs.plural': 'jobs',
  'user.access.apps.singular': 'application',
  'user.access.apps.plural': 'applications',
  'user.options.edit': 'Edit',
  'user.options.delete': 'Delete',

  'user.crumbs.team': 'Team',
  'user.tabs.profile': 'Profile',
  'user.tabs.password': 'Password',
  'user.tabs.notifications': 'Notifications',
  'user.changepw.heading': 'Change password',
  'user.changepw.label.old': 'Old password',
  'user.changepw.label.new': 'New password',
  'user.changepw.label.new2': 'Confirm password',
  'user.changepw.button.cancel': 'Cancel',
  'user.changepw.button.submit': 'Change',

  'user.notifications.emailHeading': 'Email preferences',
  'user.notifications.heading': 'Notifications preferences',
  'user.notifications.emailTableHeader': 'Email me when',
  'user.notifications.tableHeader': 'Notify me when',
  'user.notifications.mail': 'Mail',
  'user.notifications.desktop': 'In-app',
  'user.notifications.applicant_applies': 'An applicant applies for a job',
  'user.notifications.applicant_rated': 'An applicant receives a rating',
  'user.notifications.applicant_noted': 'An applicant receives a new note',
  'user.notifications.applicant_rejected': 'An applicant is rejected',
  'user.notifications.applicant_assigned': 'An applicant is assigned to you',
  'user.notifications.applicant_hired': 'An applicant is hired',
  'user.notifications.applicant_moved': 'An applicant moves to another stage',
  'user.notifications.applicants_bulk_rejected': 'Applicants are group rejected',
  'user.notifications.add_to_hiring_team': 'You are added to a hiring team',
  'user.notifications.job_archived': 'A job is archived',
  'user.notifications.job_published': 'A job is published',
  'user.notifications.job_expired': 'A job is expired',
  'user.notifications.integration': 'Onboarding form sent or received',
  'user.notifications.save_notification': 'Notification settings saved',
  'notifications.title': 'Notifications',
  'notifications.title.latest': 'Latest',
  'notifications.title.mark_all_as_read': 'Mark all as read',
  'notifications.tooltips.mark_as_read': 'Mark as read',
  'notifications.tooltips.settings': 'Settings',
  'notifications.tooltips.close': 'Close',
  'notifications.new_notifications.singular': 'New notification',
  'notifications.new_notifications.plural': 'New notifications',
  'notifications.single.applicant_hired': '{0} marked <strong>{1}</strong> as hired',
  'notifications.multiple.applicant_hired': '{0} marked <strong>{1} applicants</strong> as hired',
  'notifications.single.applicant_rejected': '{0} marked <strong>{1}</strong> as rejected',
  'notifications.multiple.applicant_rejected': '{0} marked <strong>{1} applicants</strong> as rejected',
  'notifications.single.applicant_assigned': '{0} assigned an applicant, <strong>{1}</strong>, to you',
  'notifications.multiple.applicant_assigned': '{0} assigned <strong>{1} applicants</strong> to you',
  'notifications.single.move_stage': '{0} moved <strong>{1}</strong> from stage {2} to {3}',
  'notifications.multiple.move_stage': '{0} moved <strong>{1} applicants</strong> from stage {2} to {3}',
  'notifications.single.job_archived': '{0} archived a job',
  'notifications.single.job_published': '{0} published a job',
  'notifications.single.job_expired': 'Job is expired',
  'notifications.single.add_to_hiring_team': '{0} added <strong>{1}</strong> to a hiring team',
  'notifications.multiple.add_to_hiring_team': '{0} added <strong>{1} users</strong> to a hiring team',
  'notifications.single.new_applications': 'New application: <strong>{0}</strong>',
  'notifications.multiple.new_applications': '<strong>{0}</strong> new applications',
  'notifications.single.add_rating': '{0} left a new rating on applicant <strong>{1}</strong>',
  'notifications.multiple.add_rating': '{0} left a new rating on <strong>{1} applicants</strong>',
  'notifications.single.add_note': '{0} left a new note on applicant <strong>{1}</strong>',
  'notifications.multiple.add_note': '{0} left a new note on <strong>{1} applicants</strong>',
  'notifications.single.bulk_reject': '{0} group rejected <strong>{1} applicants</strong>',
  'notifications.integrations.onboarding_form.send': '{0} sent an onboarding form to <strong>{1}</strong>',
  'notifications.integrations.onboarding_form.finished': 'New employee, <strong>{0}</strong>, has completed an onboarding form',
  'notifications.single.message': 'New message on applicant <strong>{0}</strong>',
  'notifications.multiple.message': '<strong>{0}</strong> new messages on applicants',

  'user.profile.error.email': 'Invalid e-mail entered.',
  'user.profile.error.generic': 'Unable to save user. {0} Please try again.',
  'user.profile.update.success': 'User information successfully updated',
  'user.profile.heading': 'Profile',
  'user.profile.label.image': 'Profile Image',
  'user.profile.image.choose': 'Choose file',
  'user.profile.image.nofile': 'No file chosen',
  'user.profile.label.name': 'Name',
  'user.profile.label.email': 'Email',
  'user.profile.label.language': 'Language',
  'user.profile.label.language.english': 'English',
  'user.profile.label.language.icelandic': 'Icelandic',
  'user.profile.button.cancel': 'Cancel',
  'user.profile.button.submit': 'Save',
  'user.social.heading': 'Social',

  'company.templates.default.yes': 'Yes',
  'company.templates.default.no': 'No',
  'company.templates.tasklist.header': 'Task lists',
  'company.templates.crumbs.root': 'Templates',
  'company.templates.tabs.tasks': 'Tasks',
  'company.templates.tabs.emails': 'Emails',
  'company.templates.addTemplate': 'Add template',
  'company.templates.options.edit': 'Edit',
  'company.templates.options.delete': 'Delete',
  'company.templates.update.success': 'Tasklist successfully updated',
  'company.templates.update.error': 'Unable to update tasklist. Please try again.',
  'company.templates.create.success': 'Tasklist successfully created',
  'company.templates.create.error': 'Unable to create tasklist. Please try again.',
  'company.templates.tasks.create': 'Add task',
  'company.templates.button.cancel': 'Cancel',
  'company.templates.button.submit': 'Save',
  'company.templates.tasks.label.title': 'Title',
  'company.templates.type.applicant_new_application': 'New Application',
  'company.templates.type.rejection': 'Rejection',
  'company.templates.type.message': 'Message',
  'company.templates.email.button.cancel': 'Cancel',
  'company.templates.email.button.save': 'Save',
  'company.templates.email.button.back': 'Back',
  'company.templates.email.create.error': 'Unable to create template. Please try again or contact 50skills.',
  'company.templates.email.create.success': 'Template successfully created',
  'company.templates.email.delete.error': 'Unable to delete email template. Please try again or contact 50skills.',
  'company.templates.email.delete.success': 'Successfully deleted email template.',
  'company.templates.email.delete.text': 'Are you sure you want to remove the template <strong>{0}</strong>?',
  'company.templates.email.menu.delete': 'Delete template',
  'company.templates.email.menu.edit': 'Edit template',
  'company.templates.email.message.placeholder': 'Compose a new e-mail template',
  'company.templates.email.title.placeholder': 'Choose a descriptive title for the template',
  'company.templates.email.update.error': 'Unable to update template. {0}.',
  'company.templates.email.update.success': 'Template successfully updated',
  'company.templates.email.label.message': 'Message',
  'company.templates.email.label.title': 'Title',
  'company.templates.email.label.type': 'Type',
  'company.templates.email.label.default': 'Default',
  'company.templates.email.label.options': 'Options',
  'company.templates.email.type.title': 'Select type',
  'company.templates.email.label.setdefault': 'Set as default email',
  'company.integrations.configured_apps': 'Configured apps',
  'company.integrations.upsell.title': 'More apps available',
  'company.integrations.none': 'No integrations configured',
  'company.integrations.upsell.body': 'To connect internal chat software such as Workplace, Slack or integrate with some of your existing HR software, please contact us.',
  'company.integrations.detail.hired': 'Hired • {0} • by {1}',

  'applicants.none_found': 'No applicants found',
  'applicants.filters.header': 'Applicant filters',
  'applicants.filters.sub_header': 'Showing {0} / {1} applicants',
  'applicants.filters.search.placeholder': 'Search applicants',
  'applicants.filters.titles.show': 'Status',
  'applicants.filters.date.label': 'From:',
  'applicants.filters.date.today': 'Today',
  'applicants.filters.date.past_seven': 'Past 7 days',
  'applicants.filters.date.past_thirty': 'Past 30 days',
  'applicants.filters.date.all_time': 'All time',
  'applicants.filters.integrations.all': 'All',
  'applicants.filters.integrations.not_started': 'Not started',
  'applicants.filters.integrations.in_progress': 'In progress',
  'applicants.filters.integrations.completed': 'Completed',
  'applicants.filters.active': 'Active',
  'applicants.filters.sidebar.hired': 'Hired',
  'applicants.filters.sidebar.rejected': 'Rejected',
  'applicants.filters.sidebar.retracted': 'Retracted',
  'applicants.filters.jobs.all': 'All',
  'applicants.filters.assignment.anyone': 'Anyone',
  'onboarding.filters.header': 'Onboarding filters',
  'onboarding.filters.sub_header': 'Showing {0} hired applicants',
  'onboarding.sidebar.title.integrations': 'Integration status',
  'onboarding.none_found.no_filters.header': 'No applicants in onboarding',
  'onboarding.none_found.filters.header': 'No applicants found',
  'onboarding.none_found.no_filters.sub_header': 'There are no applicants currently being onboarded',
  'onboarding.none_found.filters.sub_header': 'No applicants matching current filters',
  'job.filters.loading': 'Loading...',
  'job.filters.numfilteredout': '{0} applicant(s) filtered out',
  'job.filters.clear': 'clear filters',
  'job.filters.empty': 'No applicants filtered out',
  'job.tabs.pipeline': 'Pipeline',
  'job.tabs.applicants': 'Applicants',
  'job.tabs.recruiters': 'Hiring Team',
  'job.tabs.statistics': 'Statistics',
  'job.tabs.integrations': 'Apps',
  'job.actions.bulk': 'Bulk actions',
  'job.actions.bulk.reject': 'Reject',
  'job.actions.bulk.grant': 'Grant Access',
  'job.actions.bulk.movetostage': 'Move to stage',
  'job.actions.bulk.message': 'Send message',
  'job.actions.bulk.noselected': 'No applicants selected',
  'job.addapplicant': 'Add applicant',
  'job.addrecruiter': 'Add to hiring team',
  'errors.field.required': 'This field is required',
  'modals.addapplicant.header': 'Add applicant',
  'modals.addapplicant.label.name': '',
  'modals.addapplicant.button.cancel': 'Cancel',
  'modals.addapplicant.button.submit': 'Save',
  'modals.addapplicant.field.cv': 'CV/Resumé',
  'modals.addapplicant.field.cover': 'Cover Letter',
  'modals.addrecruiter.heading': 'Add to hiring team',
  'modals.addrecruiter.select.empty': 'No users available',
  'modals.addrecruiter.select': 'Select users to add to the hiring team',
  'modals.addrecruiter.button.cancel': 'Cancel',
  'modals.addrecruiter.button.submit': 'Add',
  'job.applicants.empty': 'No applicants',
  'job.applicants.empty.search': 'No applicants found',
  'job.applicants.msg.search': 'No applicants matching current filters',
  'job.applicants.msg.all': 'All applicants will appear here',
  'job.applicants.msg.rejected': 'Rejected applicants will appear here',
  'job.applicants.label.name' : 'Name',
  'job.applicants.label.email' : 'Email',
  'job.applicants.label.job' : 'Job',
  'job.applicants.label.applied' : 'Applied',
  'job.applicants.label.stage' : 'Stage',
  'job.applicants.label.reviews' : '# Ratings',
  'job.applicants.label.avgrating' : 'AVG Rating',
  'job.applicants.label.messages' : 'Messages',
  'job.applicants.label.notes' : 'Notes',
  'job.applicants.label.options' : 'Options',
  'job.applicants.label.status' : 'Status',
  'onboarding.applicants.label.name' : 'Name',
  'onboarding.applicants.label.hired' : 'Hired',
  'onboarding.applicants.label.job' : 'Job',
  'onboarding.applicants.label.status' : 'Onboarding status',
  'onboarding.applicants.status': '{0} of {1} completed',
  'onboarding.applicants.status.completed': 'Completed',
  'onboarding.applicants.status.inprogress': 'In progress',
  'onboarding.applicants.status.notstarted': 'Not started',
  'application.label.sourced' : 'Sourced',
  'application.label.rejectionsent' : 'Rejection sent',
  'application.label.retracted' : 'Retracted',
  'application.label.new' : 'New',
  'job.statistics.bystage': 'Applicants by stage',
  'job.statistics.overview.summary': 'Summary',
  'job.statistics.overview.applicants': 'Total applicants',
  'job.statistics.overview.views': 'Total views',
  'job.statistics.overview.cleared': 'Expired applicants (GDPR)',
  'job.statistics.overview.status': 'Status',
  'job.statistics.overview.hired': 'Hired',
  'job.statistics.overview.active': 'Active',
  'job.statistics.overview.rejected': 'Rejected',
  'job.statistics.overview.source': 'Source',
  'job.statistics.overview.direct': 'Direct',
  'job.statistics.overview.sourced': 'Sourced',
  'job.statistics.overview.manual': 'Manual adds',
  'job.statistics.sourcing.heading': 'Top sourcing employees',
  'job.statistics.sourcing.empty': 'No applicants were sourced by employees',
  'job.statistics.applicants': 'Applicants',
  'job.statistics.hired': 'Hired',
  'job.statistics.traffic.heading': 'Traffic sources',
  'job.statistics.traffic.empty': 'No traffic sources found',
  'job.filters.tip.stages': 'Job stages view',
  'job.filters.tip.list': 'Applicant list view',
  'job.filters.tip.toggle': 'Toggle filter menu',
  'job.filters.tip.search': 'Search',
  'job.sidebar.title.filters': 'Job filters',
  'job.sidebar.title.language': 'Language',
  'job.sidebar.stagefilter.all': 'All',
  'job.sidebar.languagefilter.all': 'All',
  'job.sidebar.assignedtofilter.anyone': 'Anyone',
  'job.sidebar.assignedtofilter.none': 'No one',
  'job.sidebar.assignedtofilter.me': 'Me',
  'job.sidebar.filters.showing': 'Showing {0} / {1} applicants',
  'job.sidebar.title.show': 'Show',
  'job.sidebar.active': 'Active',
  'job.sidebar.hired': 'Hired',
  'job.sidebar.rejected': 'Rejected',
  'job.sidebar.retracted': 'Retracted',
  'job.sidebar.title.labels': 'Labels',
  'job.sidebar.labels.none': 'No Label',
  'job.sidebar.labels.sourced': 'Sourced',
  'job.sidebar.labels.rejectionsent': 'Rejection sent',
  'job.sidebar.title.avg': 'Average rating',
  'job.sidebar.avg.none': 'No reviews',
  'job.sidebar.avg.strongy': 'Strong yes',
  'job.sidebar.avg.yes': 'Yes',
  'job.sidebar.avg.no': 'No',
  'job.sidebar.avg.defno': 'Definite no',
  'job.sidebar.title.stage': 'Stage',
  'job.sidebar.title.assignment': 'Assigned to',
  'job.sidebar.title.byq': 'By question response',
  'job.sidebar.title.job': 'Job',
  'job.sidebar.title.jobs': 'Jobs',
  'job.sidebar.removefilter': 'Remove filter',
  'job.sidebar.chooseq': 'Choose question',
  'job.sidebar.choosea': 'Choose answer',
  'modals.grantmulti.heading': 'Grant access',
  'modals.grantmulti.applicantsmsg': 'Are you sure you want to grant access to the following candidates:',
  'modals.grantmulti.usersmsg': 'To the following users:',
  'modals.grantmulti.users.select': 'Select users that should access these applicants',
  'modals.grantmulti.users.empty': 'No users available',
  'modals.grantmulti.button.cancel': 'Cancel',
  'modals.grantmulti.button.submit': 'Grant',
  'modals.movetostage.heading': 'Move to stage',
  'modals.movetostage.applicantsmsg': 'Are you sure you want move the following applicants:',
  'modals.movetostage.stagemsg': 'To the following stage:',
  'modals.movetostage.stage.select': 'Select new stage',
  'modals.movetostage.button.cancel': 'Cancel',
  'modals.movetostage.button.submit': 'Move',
  'job.integrations.button.submit': 'Submit',
  'job.integrations.list.title': 'Apps',
  'job.integrations.list.empty': 'No custom integrations configured. Want to connect third-party software to your hiring process? Contact info@50skills.com for more details.',
  'job.integrations.empty.title': 'No integrations configured',
  'job.integrations.empty.text': 'If you want to connect your company\'s internal communication software e.g. Slack, Facebook Workplace or any other similar software, please contact us!',
  'job.applications.search.tip': 'Search for applicant',
  'application.onboarding.crumb.onboard' : 'Onboard',
  'application.onboarding.tabs.details' : 'Details',
  'application.onboarding.tabs.sourcing' : 'Sourcing Rewards',
  'application.onboarding.tip.ssn_only': 'Does not match national registry records ({0}) but was accepted',
  'application.onboarding.tip.keep_or_match': 'Does not match national registry records ({0})',
  'application.onboarding.tip.error': 'SSN / ID number not found in national registry records',
  'application.onboarding.applied': 'Applied',
  'application.onboarding.label.name': 'Name',
  'application.onboarding.label.phone': 'Phone',
  'application.onboarding.label.email': 'Email',
  'application.onboarding.label.ssn': 'SSN / ID number',
  'application.onboarding.verifyssn.keepormatch': '<span>Name</span> does not match records of the national registry: {0}',
  'application.onboarding.button.keep': 'Keep name',
  'application.onboarding.button.match': 'Update to match',
  'application.onboarding.button.verify': 'Verify',
  'application.onboarding.button.again': 'Re-verify SSN / ID number',
  'application.onboarding.unverified': 'Verify you have the correct <span>Name</span> and <span>SSN / ID number</span> through the national registry.',
  'application.onboarding.changetemplate.text' : 'Are you sure you want to change templates? Current tasklist will be removed',
  'application.onboarding.removetemplate.text' : 'Are you sure you want to remove the selected template? Current tasklist will be removed',
  'application.onboarding.tasklist.nocreated' : 'No task lists created',
  'application.onboarding.tasklist.create' : 'Create task list',
  'application.onboarding.tasklist.heading': 'Task list',
  'application.onboarding.tasklist.choose': 'Choose template',
  'application.onboarding.tasklist.remove': 'Remove task list',
  'application.onboarding.integration.save': 'Save for later',
  'application.onboarding.integration.resend': 'Resend',
  'application.onboarding.integration.submit': 'Submit',
  'application.onboarding.integration.delete': 'Delete',
  'application.onboarding.integration.preview': 'Preview',
  'application.onboarding.rewards.header': 'Sourcing rewards',
  'application.onboarding.rewards.norewards': 'No reward has been set for this job',
  'application.onboarding.rewards.sourcedby': 'Sourced by',
  'application.onboarding.rewards.nosource': 'Nobody sourced this applicant.',
  'application.onboarding.mark_onboarded': 'Mark as onboarded',
  'application.onboarding.unmark_onboarded': 'Unmark as onboarded',
  'job.recruiters.actions.revoke': 'Revoke job access',
  'job.recruiters.access.isjobfollower': 'All applicants',
  'job.recruiters.access.applicants': '{0} applicant(s)',
  'job.recruiters.editaccess.select': 'Select applicants to grant permissions to',
  'job.recruiters.editaccess.noselect': 'No applicants available',
  'job.recruiters.editaccess.noapplicantsselected': 'No applicants selected. All individual application permissions will be cleared for {0} in this job.',
  'job.recruiters.editaccess.button.cancel': 'Cancel',
  'job.recruiters.editaccess.button.submit': 'Save',
  'job.recruiters.editaccess.heading': 'Edit application access for {0}',
  'job.recruiters.editaccess.success': 'Successfully removed {0}\'s access from {1} application(s).',
  'job.recruiters.editaccess.error': 'Unable to remove {0}\'s access to {1} application(s). Please try again or contact 50skills if the problem persists.',
  'job.stages.loadingMore': 'Loading more...',
  'job.stages.noapplicants.header': 'No applicants',
  'job.stages.noapplicants.text': 'All applicants will appear here',
  'job.stages.noapplicants.filtered.header': 'No applicants found',
  'job.stages.noapplicants.filtered.text': 'There are no applicants that match your current filters.',
  'job.stages.menu.sort.title': 'Sort applicants by',
  'job.stages.menu.sort.name-asc': 'Name ascending (A to Z)',
  'job.stages.menu.sort.name-desc': 'Name descending (Z to A)',
  'job.stages.menu.sort.newest': 'Date applied (Newest first)',
  'job.stages.menu.sort.oldest': 'Date applied (Oldest first)',
  'job.stages.menu.sort.notes.highest': 'Number of notes (Highest first)',
  'job.stages.menu.sort.notes.lowest': 'Number of notes (Lowest first)',
  'job.stages.menu.sort.avgvote.highest': 'Average vote (Highest first)',
  'job.stages.menu.sort.avgvote.lowest': 'Average vote (Lowest first)',
  'job.stages.menu.sort.votecount.highest': 'Number of votes (Highest first)',
  'job.stages.menu.sort.votecount.lowest': 'Number of votes (Lowest first)',
  'job.stages.menu.title': 'Stage options',
  'job.stages.menu.applicants': 'Applicants',
  'job.stages.menu.rename': 'Rename stage',
  'job.stages.menu.sort': 'Sort applicants...',
  'job.stages.menu.reject': 'Reject all in stage',
  'job.stages.menu.editname.tip.submit': 'Save stage name',
  'job.stages.menu.editname.tip.cancel': 'Cancel',
  'job.stages.mobile.displaytext': 'Stage: {0}',
  'application.gotoonboarding': 'Onboard',
  'application.access.addmodal.header': 'Grant single applicant access',
  'application.access.addmodal.maintext': 'Give access to this application to users outside the hiring team. They will only be able to view this application and others they have been added to manually.',
  'application.access.addmodal.select': 'Select users to grant them access',
  'application.access.addmodal.noselect': 'No users available',
  'application.access.addmodal.button.cancel': 'Cancel',
  'application.access.addmodal.button.submit': 'Add',
  'application.gdpr.header': 'Applicant expired',
  'application.gdpr.maintext': 'Due to the EU General Data Protection Regulation (GDPR), this applicant\'s data has expired and consequently removed.',
  'application.tabs.details': 'Details',
  'application.tabs.contact': 'Contact',
  'application.tabs.notes': 'Notes',
  'application.tabs.messages': 'Messages',
  'application.tabs.ratings': 'Ratings',
  'application.tabs.files': 'Files',
  'application.tabs.access': 'Access',
  'application.tabs.attachments': 'Attachments',
  'application.header.applied': 'Applied',
  'application.header.answers': 'Application answers',
  'application.header.sourced': 'Sourced by',
  'application.header.access': 'Single candidate access',
  'application.header.assignedto': 'Assigned to {0}',
  'application.header.files': 'Files',
  'application.details.header.applied_to': 'Applied to • {0} other jobs',
  'application.details.header.show_all': 'Show all',
  'application.details.header.collapse': 'Collapse',
  'application.details.header.cv': 'CV / Resume',
  'application.details.header.cl': 'Cover Letter',
  'application.details.nocvpreview': 'Unable to preview CV.',
  'application.details.noclpreview': 'Unable to preview Cover Letter.',
  'application.details.nocvuploaded': 'No CV was uploaded',
  'application.details.nocluploaded': 'No Cover Letter was uploaded',
  'application.details.noanswer': '(No answer provided)',
  'application.details.attachtip': 'Click to view attached file',
  'application.attachment.nopreview': 'Unable to preview Attachment.',
  'application.attachment.download': 'Download',
  'application.attachments.add.success': 'Successfully uploaded the file',
  'application.attachments.add.error': 'Error uploading the file',
  'application.attachments.remove.success': 'Successfully removed the file',
  'application.attachments.remove.error': 'Error removing the file',
  'application.clicktodownload': 'Click to download',
  'application.list.allstages': 'All stages',
  'application.list.cardlistname': 'Applicants',
  'application.access.header': 'Hiring team',
  'application.access.roleheader': 'Role',
  'application.access.revoke': 'Revoke Access',
  'application.access.revoking': 'Revoking Access...',
  'application.access.noaccess': 'Applicant is currently not viewable by anyone outside the hiring team.',
  'application.access.button.submit': 'Add user',
  'application.header.contact': 'Contact',
  'application.contact.name': 'Full name',
  'application.contact.email': 'E-mail address',
  'application.contact.phone': 'Phone number',
  'application.contact.ssn': 'SSN / ID number',
  'application.header.social': 'Social profiles',
  'application.contact.link': 'Link',
  'application.contact.nocontent': 'This applicant did not add any social profiles',

  'application.messages.header': 'Messages',
  'application.messages.textbox.button.submit': 'Send message',
  'application.messages.send.success': 'Message sent',
  'application.messages.send.error': 'Error occurred',
  'application.messages.editor.placeholder': 'Send a message to the applicant as an e-mail',
  'application.messages.footer.rejection': 'Rejection',
  'application.messages.footer.application': 'Application',
  'application.messages.confirm': 'The following message will be emailed to the applicant {0}:',
  'application.messages.send': 'Send message',

  'application.notes.actions.edit': 'Edit',
  'application.notes.actions.delete': 'Delete',
  'application.notes.header': 'Team notes',
  'application.reviews.nocontent': 'This applicant does not have any reviews from your team yet.',
  'application.reviews.header.you': 'Your rating',
  'application.reviews.header.team': 'Team ratings',
  'application.reviews.average': 'Average rating: {0}',
  'application.notes.edit.button.cancel': 'Cancel',
  'application.notes.edit.button.submit': 'Update',
  'application.notes.textbox.admin_note.label': 'Private note',
  'application.notes.textbox.switch.admin': 'Private note',
  'application.notes.textbox.info.admin': 'Only visible for admins',
  'application.notes.textbox.info.regular': 'Visible to hiring team',
  'application.notes.textbox.button.submit.regular': 'Save note',
  'application.notes.textbox.button.submit.admin': 'Save private note',
  'application.notes.textbox.placeholder.regular': 'Add a note or @ mention your co-workers to notify them',
  'application.notes.textbox.placeholder.admin': 'Add a private note to the applicant - only other admin users can see these notes',
  'application.removeconfirm.header': 'Confirm deletion',
  'application.removeconfirm.confirm': 'Are you sure you wish to delete {0}\'s application?',
  'application.removeconfirm.warning': 'This action can not be undone!',
  'application.removeconfirm.button.cancel': 'Cancel',
  'application.removeconfirm.button.submit': 'Confirm',
  'application.rating.no': 'No',
  'application.rating.defno': 'Definite no!',
  'application.rating.yes': 'Yes',
  'application.rating.strongyes': 'Strong yes!',
  'application.files.add': 'Add file',
  'application.files.attached': 'attached',
  'application.files.remove': 'Remove attached file',
  'application.files.hoveropen': 'Click to view file',
  'application.move.success': 'Successfully moved {0}\'s application to {1}',
  'application.move.error': 'Unable to move {0}\'s application to {1}. Please try again or contact 50skills.',
  'application.copy.success': 'Successfully copied {0}\'s application to {1}',
  'application.copy.error': 'Unable to copy {0}\'s application to {1}. Please try again or contact 50skills.',
  'application.copy.title': 'Copy application to',
  'application.move.title': 'Move application to',
  'application.movecopy.loadingjobs': 'Loading jobs...',
  'application.movecopy.selectjob': 'Select job',
  'application.movecopy.selectstage': 'Select stage',
  'application.movecopy.label.company': 'Company',
  'application.movecopy.label.job': 'Job',
  'application.movecopy.label.stage': 'Stage',
  'application.movecopy.label.include': 'Include',
  'application.movecopy.label.notes': 'Notes',
  'application.movecopy.label.files': 'Employee uploaded files',
  'application.movecopy.label.responses': 'Question responses',
  'application.movecopy.label.ratings': 'Ratings',
  'application.movecopy.responses.message': 'You will be prompted to match current job questions to the new job in the next step.',
  'application.movecopy.warning.message': 'Anything you do not include will be lost, this can not be undone.',
  'application.movecopy.button.cancel': 'Cancel',
  'application.movecopy.button.cont': 'Continue',
  'application.movecopy.current.resp': 'Current application responses',
  'application.movecopy.new.resp': 'New job application questions',
  'application.movecopy.button.back': 'Back',
  'application.movecopy.button.move': 'Move',
  'application.movecopy.button.copy': 'Copy',
  'application.movecopy.addfile': 'Click to add file',
  'application.movecopy.addresp': 'Click to add custom response',
  'application.confirmcopy.title': 'Are you sure?',
  'application.confirmcopy.main': 'Not selecting all of the include options will result in them being deleted. Currently you have not selected {0}.',
  'application.confirmcopy.warn': 'This action cannot be undone.',
  'application.confirmcopy.cta': 'Do you wish to proceed?',
  'application.confirmcopy.back': 'No, go back!',
  'application.confirmcopy.proc': 'Proceed',
  'showmore': 'Show more',
  'companies.switchTo': 'Switch to {0}',
  'companies.successfulSwitch': 'Successfully switched companies to {0}',
  'autocomplete.search.jobs': 'Start typing to search for jobs',
  'autocomplete.search.jobs.loading': 'Searching...',
  'autocomplete.search.jobs.notfound': 'No jobs found'
};