import React from 'react';
import styled from 'styled-components';

import InternalTasklists from 'shared/static/images/internal_tasklists.png';
import Signet from 'shared/static/images/signet.png';
import MyTimePlan from 'shared/static/images/mtp.png';
import OneSystem from 'shared/static/images/one_system.png';

import { IconOnboarding } from 'components/icons/IconOnboarding';
import { IconIntegrationAsana } from 'components/icons/IconIntegrationAsana';
import { IconIntegrationContract } from 'components/icons/IconIntegrationContract';
import { IconIntegrationGetAccept } from 'components/icons/IconIntegrationGetAccept';
import { IconIntegrationH3 } from 'components/icons/IconIntegrationH3';
import { IconIntegrationSAP } from 'components/icons/IconIntegrationSAP';
import { IconIntegrationSlack } from 'components/icons/IconIntegrationSlack';
import { IconIntegrationTimon } from 'components/icons/IconIntegrationTimon';
import { IconIntegrationWorkplace } from 'components/icons/IconIntegrationWorkplace';
import { IconIntegrationAdd } from 'components/icons/IconIntegrationAdd';

const Svgs = {
  Slack: IconIntegrationSlack,
  'Onboarding Form': IconOnboarding,
  Workplace: IconIntegrationWorkplace,
  H3: IconIntegrationH3,
  GetAccept: IconIntegrationGetAccept,
  Tímon: IconIntegrationTimon,
  SAP: IconIntegrationSAP,
  Contract: IconIntegrationContract,
  Asana: IconIntegrationAsana,
  AddNew: IconIntegrationAdd
};

const integrationMap = {
  'Internal Tasklists': InternalTasklists,
  OneSystem,
  Signet,
  MyTimePlan
};

export const IntegrationIcon = ({ integrationName }) => {
  const Icon = Svgs[integrationName];
  if (Icon) {
    return (
      <Container>
        <Icon />
      </Container>
    );
  }
  const iconPng = integrationMap[integrationName];
  return (
    <Container>
      <img src={iconPng} alt={integrationName} width="100%" height="100%" />
    </Container>
  );
};

const Container = styled.div({
  maxWidth: '2rem',
  maxHeight: '2rem',
  minWidth: '2rem',
  minHeight: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
