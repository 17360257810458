import React from 'react';
import PropTypes from 'prop-types';

function selectInputText(element) {
  element.setSelectionRange(0, element.value.length);
}

export default class InlineEdit extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    paramName: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    validate: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    style: PropTypes.object,
    editingElement: PropTypes.string,
    staticElement: PropTypes.string,
    tabIndex: PropTypes.number,
    isDisabled: PropTypes.bool,
    editing: PropTypes.bool
  };

  static defaultProps = {
    minLength: 1,
    maxLength: 256,
    editingElement: 'input',
    staticElement: 'span',
    tabIndex: 0,
    isDisabled: false,
    editing: false
  };

  elem = null;

  state = {
    editing: this.props.editing,
    text: this.props.text,
    minLength: this.props.minLength,
    maxLength: this.props.maxLength
  };

  componentWillMount() {
    this.isInputValid = this.props.validate || this.isInputValid;
  }

  componentWillReceiveProps(nextProps) {
    const isTextChanged = nextProps.text !== this.props.text;
    const isEditingChanged = nextProps.editing !== this.props.editing;
    let nextState = {};
    if (isTextChanged) {
      nextState.text = nextProps.text;
    }
    if (isEditingChanged) {
      nextState.editing = nextProps.editing;
    }
    if (isTextChanged || isEditingChanged) {
      this.setState(nextState);
    }
  }

  componentDidMount() {
    if (this.elem) {
      this.elem.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let inputElem = this.elem;
    if (this.state.editing && !prevState.editing) {
      inputElem.focus();
      selectInputText(inputElem);
    } else if (this.state.editing && prevProps.text !== this.props.text) {
      this.finishEditing();
    }
  }

  startEditing = e => {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({ editing: true, text: this.props.text });
  };

  finishEditing = async () => {
    if (this.isInputValid(this.state.text) && this.props.text !== this.state.text) {
      await this.commitEditing();
    } else if (this.props.text === this.state.text || !this.isInputValid(this.state.text)) {
      this.cancelEditing();
    }
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  cancelEditing = () => {
    this.setState({ editing: false, text: this.props.text });
  };

  commitEditing = async () => {
    this.setState({ editing: false, text: this.state.text });
    let newProp = {};
    newProp[this.props.paramName] = this.state.text;
    const success = await this.props.change(newProp);
    if (!success) {
      this.cancelEditing();
    }
  };

  clickWhenEditing = e => {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
  };

  isInputValid = text => {
    return text.length >= this.state.minLength && text.length <= this.state.maxLength;
  };

  keyDown = event => {
    if (event.keyCode === 13) {
      this.finishEditing();
    } else if (event.keyCode === 27) {
      this.cancelEditing();
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    }
  };

  textChanged = event => {
    this.setState({
      text: event.target.value.trim()
    });
  };

  render() {
    if (this.props.isDisabled) {
      const Element = this.props.element || this.props.staticElement;
      return (
        <Element className={this.props.className} style={this.props.style}>
          {this.state.text || this.props.placeholder}
        </Element>
      );
    } else if (!this.state.editing) {
      const Element = this.props.element || this.props.staticElement;
      return (
        <Element
          className={this.props.className}
          onClick={this.startEditing}
          tabIndex={this.props.tabIndex}
          style={this.props.style}
        >
          {this.state.text || this.props.placeholder}
        </Element>
      );
    } else {
      const Element = this.props.element || this.props.editingElement;
      return (
        <Element
          onClick={this.clickWhenEditing}
          onKeyDown={this.keyDown}
          onFocus={this.props.onFocus}
          onBlur={this.finishEditing}
          className={this.props.activeClassName}
          placeholder={this.props.placeholder}
          defaultValue={this.state.text}
          onChange={this.textChanged}
          style={this.props.style}
          ref={inst => (this.elem = inst)}
        />
      );
    }
  }
}
