export const matchesLanguage = (a, settings) => {
  if (settings.sidebar.language === 'all') return true;
  return a.language === settings.sidebar.language;
};

export const matchesStage = (a, settings) => {
  if (settings.sidebar.stage === 'all') return true;
  return a.stage.toString() === settings.sidebar.stage;
};

export const matchesAssignedTo = (a, settings) => {
  if (settings.sidebar.assignedTo === 'anyone') return true;
  if (settings.sidebar.assignedTo === 'none') return !a.assignedTo;
  if (settings.sidebar.assignedTo === 'me') return a.assignedTo && a.assignedTo.isMe;
  return a.assignedTo && a.assignedTo.id.toString() === settings.sidebar.assignedTo;
};

export const matchesQuestions = (a, settings) => {
  const { questionResponses } = settings.sidebar;
  if (questionResponses.length > 0) {
    return questionResponses.every(qr => {
      let q = null;
      a.questions.forEach(questionResponse => {
        if (!questionResponse || !qr.question) return;
        if (questionResponse.id === qr.question) {
          q = questionResponse;
        }
      });

      if (!q || !q.value) return false;

      return q.value.includes(qr.value);
    });
  }
  return true;
};

export const matchesType = (applicant, settings) => {
  const { isRetracted, isRejected, isHired, isNotifiedOfRejection, referredBy } = applicant;

  let showMatch = false;
  if (settings.sidebar.isRetracted && isRetracted) showMatch = true;
  if (settings.sidebar.isRejected && isRejected) showMatch = true;
  if (settings.sidebar.isHired && isHired) showMatch = true;
  if (settings.sidebar.active && !isHired && !isRejected && !isRetracted) showMatch = true;
  if (!showMatch) return false;

  if (settings.sidebar.noLabel && !isNotifiedOfRejection && !referredBy) return true;
  if (settings.sidebar.isNotified && isNotifiedOfRejection) return true;
  if (settings.sidebar.referredBy && referredBy) return true;

  return false;
};

export const matchesAverageRating = (applicant, settings) => {
  const { averageRating } = settings.sidebar;
  const { averageRatingLetter } = applicant;
  const matchesExact = averageRating.indexOf(averageRatingLetter) > -1;
  const noRating = averageRating.indexOf('X') > -1 && averageRatingLetter === '-';
  return matchesExact || noRating;
};

export const filterApplicants = (applicants, settings) => {
  let str = settings.searchStr.toLowerCase().trim();
  return applicants.filter(a => {
    const matchName = a.name && a.name.toLowerCase().includes(str);
    if (str && !matchName) return false;

    if (!matchesType(a, settings)) return false;
    if (!matchesQuestions(a, settings)) return false;
    if (!matchesAverageRating(a, settings)) return false;
    if (!matchesStage(a, settings)) return false;
    if (!matchesLanguage(a, settings)) return false;
    if (!matchesAssignedTo(a, settings)) return false;

    return true;
  });
};

export const buildOrderString = order => {
  const [prop, ascdesc] = order.split('-');
  let str = ascdesc === 'desc' ? '-' : '';
  switch (prop) {
    case 'votes':
      return str + 'num_rating';
    case 'avgvote':
      return str + 'averageReviewsNumber';
    case 'notes':
      return str + 'num_notes';
    default:
      return str + prop;
  }
};

export const buildApplicationSearchQuery = (limit, order, job, stage, me) => {
  let str = `?limit=${limit || 10}&job=${job.id}&ordering=${buildOrderString(order)}`;
  const {
    searchStr,
    sidebar: {
      active,
      isHired,
      isNotified,
      isRejected,
      isRetracted,
      noLabel,
      referredBy,
      assignedTo,
      questionResponses,
      stage: sidebarStage,
      language,
      averageRating
    }
  } = job.settings;

  if (questionResponses.length > 0) {
    questionResponses.forEach(qr => {
      str += `&question_filter=${qr.question}__${qr.value}`;
    });
  }

  if (searchStr !== '') str += `&search_multi_match=${searchStr}`;
  if (active) str += `&status=active`;
  if (isHired) str += `&status=hired`;
  if (isRejected) str += `&status=rejected`;
  if (isRetracted) str += `&status=retracted`;
  if (noLabel) str += `&labels=none`;
  if (referredBy) str += `&labels=sourced`;
  if (isNotified) str += `&labels=notified`;

  if (assignedTo !== 'anyone') {
    if (assignedTo === 'me') {
      str += `&assigned_to=${me.id}`;
    } else if (assignedTo === 'none') {
      str += `&assigned_to__isnull=true`;
    } else {
      str += `&assigned_to=${assignedTo}`;
    }
  }
  if (sidebarStage !== 'all') {
    str += `&stage=${sidebarStage}`;
  } else if (stage !== undefined) {
    str += `&stage=${stage}`;
  }

  if (language !== 'all') {
    str += `&language=${language}`;
  }

  averageRating.split('').forEach(key => (str += `&rating=${key === 'X' ? '-' : key}`));

  return str;
};
