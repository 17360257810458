import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import CookieConsent from 'shared/modules/CookieConsent';
import LocaleProvider from 'shared/locale/LocaleProvider';
import sharedIS from 'shared/locale/is.json';
import sharedEN from 'shared/locale/en.json';

import { usePageViews } from 'hooks/usePageViews';
import Intercom from 'components/Intercom';
import { Notifications } from 'components/Notifications';
import PlaceholderPage from 'components/PlaceholderPage';
import { Toasts } from 'components/Toasts';
import { useStore } from 'models/Provider';
import { SideNavigation, SideNavigationOpenProvider } from 'components/SideNavigation';
import { Routes } from 'components/Routes';
import { Modals } from './modals';

const LOCALES = {
  is: sharedIS,
  en: sharedEN
};

export const App = observer(() => {
  const [intercom, setIntercom] = useState(null);
  const { current, me, lang, toastStore, modalStore, userStore } = useStore();
  usePageViews();

  useEffect(() => {
    if (process.env.REACT_APP_USE_INTERCOM === 'true' && me) {
      setIntercom({
        user: { user_id: me.id, email: me.email, name: me.name },
        appID: process.env.REACT_APP_INTERCOM_APPID
      });
    }
  }, [me]);

  if (!me || !userStore.loaded || !current.info) {
    return <PlaceholderPage />;
  }

  return (
    <LocaleProvider current="en" locales={LOCALES}>
      <SideNavigationOpenProvider>
        <CookieConsent lang={lang} site="admin" />
        {intercom && <Intercom appID={intercom.appID} user={intercom.user} />}
        <Modals store={modalStore} />
        <Toasts store={toastStore} />
        <Layout>
          <SideNavigation />
          <Notifications />
          <Routes />
        </Layout>
      </SideNavigationOpenProvider>
    </LocaleProvider>
  );
});

const Layout = styled.div({
  display: 'flex',
  height: '100%',
  width: '100%'
});
