import styled, { css } from 'styled-components/macro';

export const RecruiterItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: ${p => (p.selected ? '0.25rem 0' : '0 0.5rem')};
  min-height: 2.5rem;
  line-height: 2.5rem;
  text-decoration: none;
  transition: all 0.15s ease-out;
  ${p =>
    !p.selected &&
    css`
      :hover {
        border-radius: 0.25rem;
        background-color: rgba(0, 0, 0, 0.04);
      }
    `};

  ${p =>
    p.selected &&
    css`
      border-radius: 0.25rem;
      background-color: rgba(0, 0, 0, 0.04);
    `};
`;
