import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/Modal';

import { UserModalCreate } from 'components/UserModalCreate';
import { UserModalEdit } from 'components/UserModalEdit';
import AddApplicantModal from './pages/Job/AddApplicantModal';
import AddApplicationAccessModal from './pages/Job/Application/AddApplicationAccessModal';
import AddRecruiterModal from './pages/Job/AddRecruiterModal';
import AssignToApplicantModal from './components/AssignToApplicantModal';
import CommonRemoveConfirm from './components/RemoveConfirmModal';
import CustomConfirm from './components/CustomConfirmModal';
import EditApplicationAccessModal from 'pages/Job/Recruiters/EditApplicationAccessModal';
import FilePreviewModal from './components/FilePreviewModal';
import GrantMultipleModal from './pages/Job/GrantMultipleModal';
import MoveToStageModal from './pages/Job/MoveToStageModal';
import { HireApplicantModal } from 'components/HireApplicantModal';
import { ExportJobModal } from 'components/modals/ExportJobModal';
import RetractApplicantModal from './components/RetractApplicantModal';
import IntegrationFilePreviewModal from './components/IntegrationFilePreviewModal';
import { OnboardApplicantModal } from './components/OnboardApplicantModal';
import PublishedModal from './pages/CreateJob/Preview/PublishedModal';
import QuestionModal from './pages/CreateJob/ApplicationForm/QuestionModal';
import RejectApplicantsModal from './components/RejectApplicantsModal';
import MessageApplicantsModal from './components/MessageApplicantsModal';
import RemoveConfirmModal from './pages/Job/Application/RemoveConfirmModal';
import { ConfirmNoQuestions } from 'pages/Job/Application/ApplicationMoveCopy';

const MODAL_COMPONENTS = {
  ADD_APPLICANT: AddApplicantModal,
  ADD_APPLICATION_ACCESS: AddApplicationAccessModal,
  ADD_RECRUITER: AddRecruiterModal,
  COMMON_REMOVE_CONFIRM: CommonRemoveConfirm,
  CREATE_USER: UserModalCreate,
  CUSTOM_CONFIRM: CustomConfirm,
  EDIT_CANDIDATE_ACCESS: EditApplicationAccessModal,
  EDIT_USER: UserModalEdit,
  GRANT_MULTIPLE: GrantMultipleModal,
  MOVE_TO_STAGE: MoveToStageModal,
  HIRE_APPLICANT: HireApplicantModal,
  RETRACT_APPLICANT: RetractApplicantModal,
  JOB_PUBLISHED: PublishedModal,
  ONBOARD_APPLICANT: OnboardApplicantModal,
  QUESTION: QuestionModal,
  REJECT_APPLICANTS: RejectApplicantsModal,
  MESSAGE_APPLICANTS: MessageApplicantsModal,
  REMOVE_CONFIRM: RemoveConfirmModal,
  ASSIGN_APPLICANT: AssignToApplicantModal,
  CONFIRM_MOVE_NO_QUESTIONS: ConfirmNoQuestions,
  EXPORT_JOB: ExportJobModal,

  // Full screen
  FILE_PREVIEW: FilePreviewModal,
  INTEGRATION_FILE_PREVIEW: IntegrationFilePreviewModal
};

export const Modals = ({ store }) => {
  return <Modal components={MODAL_COMPONENTS} store={store} />;
};

Modals.propTypes = {
  store: PropTypes.object
};
