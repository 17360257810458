import { types as t, getParent, flow } from 'mobx-state-tree';
import moment from 'moment';

import { withEnv } from 'models/withEnv';
import { Application } from 'models/applications';
import { UserRef } from 'models/users';

export const ApplicationChat = t
  .model('ApplicationChat', {
    id: t.identifierNumber,
    application: t.maybe(t.reference(t.late(() => Application))),
    user: t.maybe(t.late(() => UserRef)),
    dateCreated: t.maybe(t.string),
    text: t.maybe(t.string),
    isUnread: t.maybe(t.boolean),
    isAdminNote: t.maybe(t.boolean),

    isEditing: false
  })
  .views(withEnv)
  .actions(self => ({
    edit() {
      self.isEditing = true;
    },
    remove() {
      return self.parent.removeNote(self);
    },
    update: flow(function*(text) {
      const res = yield self.env.api.applications.editNote(self.id, { text });
      if (!res.error) {
        self.text = text;
        self.env.notify('success', self.env.t('application.chat.update.success'));
        return true;
      } else {
        self.env.notify('error', self.env.t('application.chat.update.error'));
        return false;
      }
    })
  }))
  .views(self => ({
    get dateDisplay() {
      const name = self.user.displayName;
      const momentObj = moment(self.dateCreated);
      const date = momentObj.format(' MMMM Do YYYY');
      const time = momentObj.format(' HH:mm');
      return `${name} • ${date} • ${time}`;
    },
    get parent() {
      return getParent(self, 2);
    }
  }));
