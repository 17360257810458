import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconTeam = props => (
  <Svg {...props}>
    <path
      d="M2 17.25c0-2.33 4.66-3.5 7-3.5s7 1.17 7 3.5V19H2v-1.75zM12.5 8.5c0 1.93-1.57 3.5-3.5 3.5s-3.5-1.57-3.5-3.5S7.07 5 9 5s3.5 1.57 3.5 3.5zM18 17.25c0-1.48-.8-2.6-1.96-3.44 2.46.27 5.96 1.42 5.96 3.44V19h-4v-1.75zM18.5 8.5c0 1.93-1.57 3.5-3.5 3.5-.54 0-1.04-.13-1.5-.35.63-.89 1-1.98 1-3.15s-.37-2.26-1-3.15c.46-.22.96-.35 1.5-.35 1.93 0 3.5 1.57 3.5 3.5z"
      fill="currentColor"
    />
  </Svg>
);
