import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconNotifications = props => (
  <Svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.368a2 2 0 002-1.986h-4c0 1.092.89 1.986 2 1.986zm6-5.96v-4.965c0-3.05-1.64-5.602-4.5-6.278V3.49C13.5 2.665 12.83 2 12 2s-1.5.665-1.5 1.49v.675C7.63 4.841 6 7.383 6 10.443v4.966l-2 1.986v.994h16v-.994l-2-1.986z"
      fill="currentColor"
    />
  </Svg>
);
