import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {
  useTabState as useReaTabState,
  Tab as ReaTab,
  TabList as ReaTabList,
  TabPanel as ReaTabPanel
} from 'reakit/Tab';
import { Select } from 'components/Select';
import { useMobile } from 'hooks/useMobile';
import { useStore } from 'models/Provider';

const TabStateContext = React.createContext();

const TabList = observer(
  (props, ref) => {
    const {
      label,
      children,
      state,
      variant = 'base',
      border = false,
      onSelect,
      collapseOnMobile = false,
      ...rest
    } = props;
    const isMobile = useMobile();
    const { router } = useStore();

    if (isMobile && collapseOnMobile) {
      const childrenItems = React.Children.map(children, (child, index) => {
        if (!child) return null;
        return { display: child.props.children, value: String(index), link: child.props.link };
      });

      return (
        <TabStateContext.Provider value={{ state, variant }}>
          <div ref={ref}>
            <Select
              onChange={idx => {
                state.select(idx);
                onSelect && onSelect(Number(idx));
                const link = childrenItems[idx].link;
                link && router.push(link);
              }}
              defaultValue={state.selectedId}
              items={childrenItems}
            />
          </div>
        </TabStateContext.Provider>
      );
    }

    const childrenWithIndexedStops = React.Children.map(children, (child, index) => {
      if (!child) return null;
      return React.cloneElement(child, { ...child.props, stopId: String(index), onSelect });
    });

    return (
      <TabStateContext.Provider value={{ state, variant }}>
        <div ref={ref}>
          <StyledTabList {...rest} {...state} variant={variant} aria-label={label}>
            {childrenWithIndexedStops}
            {border && <Border />}
          </StyledTabList>
        </div>
      </TabStateContext.Provider>
    );
  },
  { forwardRef: true }
);

const Border = styled.div(p => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '0.0625rem',
  backgroundColor: p.theme.colors.purpleTrans10
}));

const TabPanelList = observer(({ state, children }) => {
  const newChildren = React.Children.map(children, (child, index) => {
    if (!child) return null;
    return React.cloneElement(child, { ...child.props, stopId: String(index) });
  });

  return <TabStateContext.Provider value={{ state }}>{newChildren}</TabStateContext.Provider>;
});

const TabPanel = observer(({ stopId, ...rest }) => {
  const { state } = React.useContext(TabStateContext);
  if (stopId !== state.selectedId) return null;
  return <StyledTabPanel stopId={stopId} {...state} {...rest} />;
});

const StyledTabPanel = styled(ReaTabPanel)(p => ({
  outline: 'none'
}));

const Tab = observer(({ children, stopId, onSelect, link, ...rest }) => {
  const { state, variant } = React.useContext(TabStateContext);
  const isThisTabSelected = stopId === state.selectedId;

  const selectTab = index => {
    state.select(index);
    if (onSelect) {
      onSelect(Number(index));
    }
  };

  if (variant === 'pill') {
    return (
      <PillTabContainer
        active={isThisTabSelected}
        stopId={stopId}
        {...state}
        select={selectTab}
        {...rest}
        data-tab-item
      >
        <span>{children}</span>
      </PillTabContainer>
    );
  }
  return (
    <TabContainer
      select={selectTab}
      active={isThisTabSelected}
      stopId={stopId}
      {...state}
      {...rest}
      data-tab-item
    >
      {link ? <Link to={link}>{children}</Link> : <span>{children}</span>}
      <TabActiveBorder visible={isThisTabSelected} />
    </TabContainer>
  );
});

const useTabState = ({ selectedId, ...args } = {}) => {
  const tabState = useReaTabState({ selectedId: String(selectedId), ...args });
  React.useLayoutEffect(() => {
    if (selectedId === undefined) return;
    if (String(selectedId) !== tabState.selectedId) {
      tabState.select(String(selectedId));
    }
  }, [selectedId, tabState]);
  return tabState;
};

export { useTabState, TabList, TabPanelList, TabPanel, Tab };

// @STYLES
const StyledTabList = styled(({ variant, ...rest }) => <ReaTabList {...rest} />)(p => ({
  position: 'relative',
  display: 'flex',
  'button:not(:first-of-type)': {
    '&[data-tab-item]': {
      marginLeft: p.variant === 'pill' ? p.theme.spacing.m16 : p.theme.spacing.m32
    }
  }
}));

const TabActiveBorder = styled.div(p => ({
  transition: p.visible ? 'all 200ms ease-out' : 'all 100ms ease-out',
  backgroundColor: p.theme.colors.green100,
  position: 'absolute',
  bottom: 0,
  transform: p.visible ? 'scaleX(1) translateX(0px)' : 'scaleX(0) translateX(50%)',
  width: '100%',
  height: '0.25rem',
  zIndex: 2
}));

const TabContainer = styled(({ active, ...rest }) => <ReaTab {...rest} />)(p => ({
  height: '2.5rem',
  position: 'relative',
  ...p.theme.text.semibold14,
  textTransform: 'uppercase',
  transition: 'color 200ms ease-out',
  color: p.disabled
    ? p.theme.colors.purpleTrans20
    : p.active
    ? p.theme.colors.green100
    : p.theme.colors.purple50,
  zIndex: 2,
  'span, a': {
    display: 'block',
    outline: 'none',
    height: '2.5rem',
    ':focus': {
      color: p.theme.colors.green100
    }
  },
  ':focus': {
    outline: 'none'
  },
  display: 'flex',
  ...(!p.active &&
    !p.disabled && {
      ':hover, :focus': {
        color: p.theme.colors.purple70,
        cursor: 'pointer'
      }
    })
}));

const PillTabContainer = styled(({ active, ...rest }) => <ReaTab {...rest} />)(p => ({
  display: 'inline-block',
  padding: `${p.theme.spacing.m12} ${p.theme.spacing.m24}`,
  transition: 'all 200ms ease',
  borderRadius: '20px',
  ...p.theme.text.semibold14,
  backgroundColor: p.theme.colors.purpleTrans5,
  color: p.theme.colors.purple50,
  ...(!p.active &&
    !p.disabled && {
      ':hover, :focus': {
        cursor: 'pointer',
        color: p.theme.colors.purple60,
        backgroundColor: p.theme.colors.purpleTrans10
      }
    }),
  ...(p.active && {
    color: p.theme.colors.white100,
    backgroundColor: p.theme.colors.purple100
  })
}));
