import styled from 'styled-components/macro';

export default styled.div`
  min-height: 18px;
  color: ${props => props.theme.formLabelColor};
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 4px;
  ${p => p.capitalize && `text-transform: uppercase`};
  ${p => p.margin && `margin-bottom: 10px`};
`;
