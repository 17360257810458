import { types as t } from 'mobx-state-tree';

import { TasklistTemplateStore, EmailTemplateStore } from 'models/templates';

export const TemplateStore = t
  .model('TemplateStore', {
    tasklist: t.optional(TasklistTemplateStore, {}),
    email: t.optional(EmailTemplateStore, {})
  })
  .views(self => ({
    get emailTemplates() {
      return self.email.items.filter(
        template =>
          template.templateType === 'rejection' ||
          template.templateType === 'applicant_new_application' ||
          template.templateType === 'message'
      );
    },
    get tasklistTemplates() {
      return self.tasklist.items;
    }
  }))
  .actions(self => ({
    load() {
      self.loadTasklist();
      self.loadEmail();
    },
    loadTasklist() {
      return self.tasklist.load();
    },
    loadEmail() {
      return self.email.load();
    },
    addEmailTemplate(template) {
      self.email.addTemplate(template);
    }
  }));
