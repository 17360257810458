import React from 'react';

import puffy from 'shared/static/images/puffy.png';
import { getVisibleHeight } from 'shared/utils/browser';
import { GDPRApplicationWrapper } from './styles';

function GDPRApplication({ t }) {
  const ht = getVisibleHeight() - 200;
  return (
    <GDPRApplicationWrapper ht={ht + 'px'}>
      <div>
        <img src={puffy} alt="no avatar" />
        <div>
          <h2>{t('application.gdpr.header')}</h2>
          <p>{t('application.gdpr.maintext')}</p>
        </div>
      </div>
      <div className="fake-text">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </GDPRApplicationWrapper>
  );
}

export default GDPRApplication;
