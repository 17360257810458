import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useSideNavigation } from 'components/SideNavigation';
import { useMobile } from 'hooks/useMobile';

export const FixedBottomActions = ({ children }) => {
  const [height, setHeight] = useState(0);
  const { open } = useSideNavigation();
  const actionsRef = useRef(null);
  const mobile = useMobile();

  const setRectHeight = () => {
    const rect = actionsRef.current.getBoundingClientRect();
    setHeight(rect.height);
  };

  useEffect(() => {
    setRectHeight();
  }, [mobile]);

  return (
    <>
      <ActionsSpacer height={height} />
      <Actions ref={actionsRef} sidebarOpen={open}>
        {children}
      </Actions>
    </>
  );
};

const ActionsSpacer = styled.div(p => ({
  position: 'relative',
  minHeight: p.height,
  maxHeight: p.height,
  height: p.height,
  width: '100%'
}));

export const Actions = styled.div(p => ({
  position: 'fixed',
  transition: 'left 150ms ease-out',
  bottom: 0,
  left: p.sidebarOpen ? '12.5rem' : '4.5rem',
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  boxShadow: 'inset 0 2px 0 0 rgba(255, 255, 255, 0.3), 0 2px 5px 0 rgba(0, 0, 0, 0.1)',
  padding: 20,
  '> *:not(:first-child)': {
    marginLeft: 10
  },
  '@media (max-width: 768px)': {
    flexDirection: 'column',

    '> *:not(first-child)': {
      marginLeft: 0,
      marginBottom: 10,
      width: '100%',
      maxWidth: 500
    }
  },
  [p.theme.mobileQuery]: {
    left: 0
  }
}));
