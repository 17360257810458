import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconPerson32 = props => (
  <Svg32 {...props}>
    <rect x="1" y="1" width="30" height="30" rx="15" fill="#3C3C64" stroke="#fff" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.336 0-7 1.117-7 3.333V23h14v-1.667C23 19.117 18.336 18 16 18z"
      fill="#F6F6F6"
    />
  </Svg32>
);
