import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import ClickOutside from 'shared/layout/ClickOutside';
import { useStore } from 'models/Provider';
import {
  Menu,
  MenuHeader,
  MenuItem,
  MenuItems,
  MenuSeparator,
  TransparentMenuButton
} from 'components/Menu';
import { IconEdit } from 'components/icons/IconEdit';
import { IconOptions } from 'components/icons/IconOptions';
import { IconSort } from 'components/icons/IconSort';
import { Box } from 'components/Box';
import { IconArrowLeft } from 'components/icons/IconArrowLeft';
import { IconButton } from 'components/IconButton';

export const StageActionMenu = observer(({ onSelect, stage, onEdit }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const { openModal } = useStore();

  function selectMenuEntry(entry) {
    onSelect(entry);
    setOpen(false);
    setPage(0);
  }

  function onOutsideClick() {
    if (open) {
      setOpen(false);
    }
    if (page !== 0) {
      setPage(0);
    }
  }

  function click() {
    setOpen(old => !old);
    setPage(0);
  }

  function goToSort() {
    setPage(1);
  }

  function goToRoot() {
    setPage(0);
  }

  async function rejectAllInStage() {
    click();
    await stage.loadAll();
    openModal('REJECT_APPLICANTS', {
      autoWidth: false,
      applicants: stage.applications
    });
  }

  return (
    <ClickOutside onOutsideClick={onOutsideClick}>
      <Menu label="Stage actions">
        <TransparentMenuButton>
          <IconOptions />
        </TransparentMenuButton>
        {page === 0 && <PageZero onReject={rejectAllInStage} nextPage={goToSort} onEdit={onEdit} />}
        {page === 1 && (
          <PageOne goBack={goToRoot} onSortClick={selectMenuEntry} currentSort={stage.order} />
        )}
      </Menu>
    </ClickOutside>
  );
});

const PageZero = observer(({ nextPage, onReject, onEdit }) => {
  const { t } = useStore();
  return (
    <MenuItems>
      <MenuHeader>{t('job.stages.menu.title')}</MenuHeader>
      <MenuItem onClick={onEdit}>
        <IconEdit />
        <Box ml="8">{t('job.stages.menu.rename')}</Box>
      </MenuItem>
      <MenuItem
        hideOnClick={false}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          nextPage();
        }}
      >
        <IconSort />
        <Box ml="8">{t('job.stages.menu.sort')}</Box>
      </MenuItem>
      <MenuSeparator />
      <MenuHeader>{t('job.stages.menu.applicants')}</MenuHeader>
      <MenuItem variant="danger" onClick={onReject}>
        {t('job.stages.menu.reject')}
      </MenuItem>
    </MenuItems>
  );
});

const PageOne = observer(({ goBack, onSortClick, currentSort }) => {
  const { t } = useStore();
  return (
    <MenuItems width="auto">
      <MenuHeader>
        <IconButton onClick={goBack} trans small icon={IconArrowLeft} />
        <Box ml="16">{t('job.stages.menu.sort.title')}</Box>
      </MenuHeader>
      <MenuSeparator />
      {sorting.map(({ text, key }) => (
        <MenuItem active={currentSort === key} key={key} onClick={() => onSortClick(key)}>
          {t(text)}
        </MenuItem>
      ))}
    </MenuItems>
  );
});

const sorting = [
  { text: 'job.stages.menu.sort.name-asc', key: 'name-asc' },
  { text: 'job.stages.menu.sort.name-desc', key: 'name-desc' },
  { text: 'job.stages.menu.sort.newest', key: 'date-desc' },
  { text: 'job.stages.menu.sort.oldest', key: 'date-asc' },
  { text: 'job.stages.menu.sort.notes.highest', key: 'notes-desc' },
  { text: 'job.stages.menu.sort.notes.lowest', key: 'notes-asc' },
  { text: 'job.stages.menu.sort.avgvote.highest', key: 'avgvote-desc' },
  { text: 'job.stages.menu.sort.avgvote.lowest', key: 'avgvote-asc' },
  { text: 'job.stages.menu.sort.votecount.highest', key: 'votes-desc' },
  { text: 'job.stages.menu.sort.votecount.lowest', key: 'votes-asc' }
];
