import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconAttachment = props => (
  <Svg {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5a2.5 2.5 0 010-5H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h24v24H0V0z" fill="#fff" />
      </clipPath>
    </defs>
  </Svg>
);
