import React from 'react';
import styled from 'styled-components/macro';
import ClickOutside from '../../layout/ClickOutside';

const Wrap = styled.div`
  .wrapper-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    ${p => !p.fullSize && 'max-width: 380px'};
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
    list-style: none;
    font-size: 1rem;
    line-height: 1.5rem;

    border-radius: 0.25rem;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-out;
    background-color: ${p => p.theme.colors.purpleTrans5};
    color: ${p => p.theme.colors.purple80};
  }

  .wrapper-dropdown:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -2px;
    border-width: 5px 4px 0 4px;
    border-style: solid;
    border-color: #81839b transparent;
    opacity: 0.65;
  }

  .wrapper-dropdown .dropdown {
    z-index: 10;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px 0;
    display: none;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.1);
    list-style: none;

    max-height: 0;
    overflow: hidden;
  }

  .wrapper-dropdown.active .dropdown {
    display: block;
    max-height: 300px;
    overflow-y: auto;
  }
`;

class MultiSelectDropDown extends React.Component {
  state = {
    open: false
  };

  toggle = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  select = d => {
    if (this.props.onChange) {
      this.props.onChange(d.value);
    }
  };

  render() {
    const { text } = this.props;
    let cls = 'wrapper-dropdown';
    if (this.state.open) cls += ' active';

    const children = React.Children.map(this.props.children, child => <li>{child}</li>);

    return (
      <ClickOutside onOutsideClick={this.state.open ? this.toggle : null}>
        <Wrap fullSize={this.props.fullSize}>
          <div onClick={children.length > 0 ? this.toggle : null} className={cls} tabIndex="1">
            <span>{text}</span>
            <ul className="dropdown">{children}</ul>
          </div>
        </Wrap>
      </ClickOutside>
    );
  }
}

export default MultiSelectDropDown;
