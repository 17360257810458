import { types as t, flow, applySnapshot, getSnapshot, detach } from 'mobx-state-tree';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';

import { transformJob } from 'helpers';
import { isSameEditorState, isEditorHtmlEmpty } from 'components/TextEditor';
import { withEnv } from 'models/withEnv';
import { Job } from 'models/jobs';
import { Language } from 'models/ui';

const JobEditErrors = t.model('JobEditErrors', {
  language: t.identifier,
  location: false,
  title: false,
  shortDescription: false
});

const JobStoreRef = t.maybe(
  t.reference(Job, {
    get(identifier, parent) {
      return parent.env.jobStore.items.get(identifier);
    },
    set(value) {
      return value.id;
    }
  })
);

const Edit = t
  .model('JobEditModel', {
    currentLanguage: t.maybe(Language),
    available: t.array(Language),
    saving: false,
    editorLoaded: false,
    original: JobStoreRef,
    errors: t.map(JobEditErrors),
    showLanguageErrors: false
  })
  .views(withEnv)
  .actions(self => {
    let initialState;
    return {
      goBackOneStep: currentStep => {
        switch (currentStep) {
          case 2:
            return self.goToEditForm();
          case 3:
            return self.goToEditRewards();
          case 4:
            return self.goToEditHiringTeam();
          case 5:
            return self.goToEditPreview();
          case 1:
          default:
            return self.goToEdit();
        }
      },
      setInitialState: () => (initialState = getSnapshot(self)),
      resetState: () => {
        applySnapshot(self, {
          ...initialState,
          currentLanguage: self.env.current.info.defaultLanguage
        });
      },
      saveDraftStage: stage => {
        if (self.draftStage >= stage) {
          return;
        }

        const draftStage = Math.max(self.draftStage, stage);
        self.env.api.jobs.edit(self.id, { draftStage }).then(res => {
          if (!res.error) {
            self.localEdit('draftStage', stage);
          }
        });
      },
      removeLanguageLocal: language => {
        const langsWithoutDeleted = self.languages.filter(obj => obj.language !== language);
        if (langsWithoutDeleted.length >= 1) {
          self.selectLanguage(langsWithoutDeleted[0].language);
        } else {
          self.selectLanguage(undefined);
        }
        self.removeLanguage(language);
        if (self.original) {
          self.original.removeLanguage(language);
        }
      },
      deleteLanguage: flow(function*(language) {
        const isLocal = self.languages.find(obj => obj.language === language).isLocal;
        if (isLocal) {
          self.removeLanguageLocal(language);
          return;
        }
        const res = yield self.env.api.jobs.deleteLanguage(self.id, language);
        if (res.error) {
          self.env.notify('error', self.env.t('job.create.languages.delete.error', res.data));
        } else {
          self.env.notify('success', self.env.t('job.create.languages.delete.success'));
          self.removeLanguageLocal(language);
        }
      }),
      selectLanguage: lang => (self.currentLanguage = lang),
      setAvailable: langs => (self.available = langs.split(',').map(key => key.trim())),
      saveEdit: (step, cont) => {
        self[`saveStep${step}`](cont);
      },
      afterCreate: () => {
        if (self.languages.length > 0) {
          self.currentLanguage = self.languages[0].language;
        } else {
          self.addLanguage(self.env.current.info.defaultLanguage);
          self.currentLanguage = self.env.current.info.defaultLanguage;
        }
        self.languages.forEach(lang => self.errors.put({ language: lang.language }));
        self.setInitialState();
      },
      enableLanguage: flow(function*(lang) {
        const obj = self.languages.find(obj => obj.language === lang);

        if (self.isPublished && !obj.isComplete) {
          self.notifyIncompletes(lang);
          return;
        }
        self.saving = true;
        const res = yield self.env.api.jobs.edit(self.id, {
          languages: [{ language: lang, isEnabled: true }]
        });

        if (!res.error) {
          self.env.notify(
            'success',
            self.env.t('job.create.languages.publish.success', obj.display)
          );
          const og = self.original.languages.find(obj => obj.language === lang);
          if (og) og.enable();
          obj.enable();
        } else {
          self.env.notify('error', self.env.t('job.create.languages.publish.error', obj.display));
        }
        self.saving = false;
      }),
      disableLanguage: flow(function*(lang) {
        const obj = self.languages.find(obj => obj.language === lang);
        const others = self.languages.filter(obj => obj.language !== lang);
        const someOtherPublished = others.some(lang => lang.isEnabled && !lang.isLocal);

        if (self.isPublished && (self.languages.length < 2 || !someOtherPublished)) {
          self.env.notify(
            'error',
            self.env.t('job.create.languages.unpublish.onlyone', obj.display)
          );
          return;
        }

        self.saving = true;
        const res = yield self.env.api.jobs.edit(self.id, {
          languages: [{ language: lang, isEnabled: false }]
        });

        if (!res.error) {
          self.env.notify(
            'success',
            self.env.t('job.create.languages.unpublish.success', obj.display)
          );
          const og = self.original.languages.find(obj => obj.language === lang);
          if (og) og.disable();
          obj.disable();
        } else {
          self.env.notify('error', self.env.t('job.create.languages.unpublish.error', obj.display));
        }
        self.saving = false;
      }),
      removeLanguage: language => {
        const langsWithoutDeleted = self.languages.filter(obj => obj.language !== language);
        const node = self.languages.find(obj => obj.language === language);
        if (node) {
          detach(node);
          self.languages = langsWithoutDeleted;
        }
        self.errors.delete(language);
      },
      addLanguage(lang) {
        if (self.langIds.includes(lang)) return;
        if (self.languages.length > 0) {
          let existingForm = getSnapshot(self.languages[0].form);
          self.languages.push({
            isLocal: true,
            language: lang,
            form: {
              id: existingForm.id,
              sections: existingForm.sections.map(section => ({
                ...section,
                questions: section.questions.map(question => ({
                  ...question,
                  title: '',
                  options: question.options
                    ? question.options.map(option => ({ ...option, display: '' }))
                    : []
                }))
              }))
            }
          });
        } else {
          self.languages.push({ language: lang, isLocal: true });
        }
      }
    };
  })
  .views(self => ({
    get languagesWithErrors() {
      let langs = [];
      self.languages.forEach(lang => {
        const errors = self.errors.get(lang.language);
        if (!errors) return;
        if (Object.keys(errors).some(key => errors[key] === true)) {
          langs.push(lang.language);
        }
      });
      return langs;
    },
    get currentLanguageErrors() {
      return self.errors.get(self.currentLanguage) || {};
    },
    get form() {
      return self.selectedLanguageData.form;
    },
    get title() {
      return self.selectedLanguageData.title;
    },
    get location() {
      return self.selectedLanguageData.location;
    },
    get shortDescription() {
      return self.selectedLanguageData.shortDescriptionHtml;
    },
    get description() {
      return self.selectedLanguageData.description;
    },
    get editing() {
      return self.id !== -1;
    },
    get selectedLanguageData() {
      return self.languages.find(data => data.language === self.currentLanguage);
    },
    get oldLanguageData() {
      return self.original.languages.find(data => data.language === self.currentLanguage);
    },
    get hasErrors() {
      let hasErrors = false;
      for (let error of self.errors.values()) {
        if (Object.keys(error).some(key => error[key] === true)) {
          hasErrors = true;
        }
      }
      return hasErrors;
    },
    get dirtyDeadline() {
      if (self.original) {
        return self.deadline !== self.original.deadline;
      }
      return self.deadline !== null;
    },
    get dirtyJobType() {
      if (self.original) {
        return self.jobType !== self.original.jobType;
      }
      return true;
    },
    get dirtyJobCategory() {
      if (self.original) {
        return self.category !== self.original.category;
      }
      return Boolean(self.category);
    },
    get dirtyJobStatus() {
      if (self.original) {
        return self.status !== self.original.status;
      }
      return true;
    },
    get dirtyVisibility() {
      if (self.original) {
        return self.visibility !== self.original.visibility;
      }
      return true;
    },
    get otherLanguageData() {
      return self.languages.filter(obj => obj.language !== self.currentLanguage);
    }
  }));

const JobEditStep0 = t
  .model('JobEditStep0', {})
  .actions(self => ({
    clearErrorForField(field) {
      const errorObj = self.errors.get(self.currentLanguage);
      if (!errorObj) {
        self.errors.put({ language: self.currentLanguage });
      } else {
        errorObj[field] = false;
        self.errors.put(errorObj);
      }
    },
    setEditorLoaded() {
      self.editorLoaded = true;
    },
    setStatus(newStatus) {
      self.status = newStatus;
    },
    setVisibility(newVisibility) {
      self.visibility = newVisibility;
    },
    setCategory(newCategory) {
      self.category = newCategory;
    },
    setJobType(newType) {
      self.jobType = newType;
    },
    setShortDescription(newShortDescription) {
      if (!isEditorHtmlEmpty(newShortDescription)) {
        self.clearErrorForField('shortDescription');
      }
      self.selectedLanguageData.setShortDescription(sanitizeHtml(newShortDescription));
    },
    setLocation(newLocation) {
      if (newLocation !== '') {
        self.clearErrorForField('location');
      }
      self.selectedLanguageData.setLocation(newLocation);
    },
    setTitle(newTitle) {
      if (newTitle !== '') {
        self.clearErrorForField('title');
      }
      self.selectedLanguageData.setTitle(newTitle);
    },
    clearDeadline() {
      self.deadline = null;
    },
    setDeadline(deadline) {
      self.deadline = moment(deadline).format('YYYY-MM-DD');
    },
    setDescription(description) {
      self.selectedLanguageData.setDescription(sanitizeHtml(description));
    },
    create: flow(function*(cont) {
      const data = {
        jobType: self.jobType,
        status: self.status,
        languages: getSnapshot(self.languages),
        deadline: self.deadline,
        visibility: self.visibility,
        draftStage: 1
      };

      const res = yield self.env.api.jobs.create(data);
      if (!res.error) {
        self.env.jobStore.addOrUpdate(transformJob(res.data));
        self.env.notify('success', self.env.t('job.create.success'));
        if (cont) {
          self.env.router.replace(`/edit/${res.data.id}/form`);
        } else {
          self.env.router.replace(`/edit/${res.data.id}`);
        }
      } else {
        self.env.notify('error', self.env.t('job.create.error'));
      }
    }),
    saveStep0: flow(function*(cont) {
      if (!self.step0Dirty) {
        self.saveDraftStage(1);
        cont && self.goToEditForm();
        return;
      }

      if (!self.validateStep0()) {
        return;
      }

      self.saving = true;

      if (self.id === -1) {
        yield self.create(cont);
        self.saving = false;
        return;
      }

      let obj = self.step0Diff;
      obj.draftStage = Math.max(self.draftStage, 1);
      const res = yield self.env.api.jobs.edit(self.id, obj);
      if (!res.error) {
        self.setInitialState();
        self.env.notify('success', self.env.t('job.update.description.success', self.title));
        self.updateLocal(res.data);
        self.original.updateLocal(res.data);
        cont && self.goToEditForm();
      } else {
        self.env.notify('error', self.env.t('job.update.description.error', self.title));
      }
      self.saving = false;
    }),
    validateStep0() {
      self.languages.forEach(obj => {
        let errorObj = self.errors.get(obj.language);
        if (!errorObj) {
          self.errors.put({ language: obj.language });
          errorObj = self.errors.get(obj.language);
        }

        if (!obj.title) {
          errorObj.title = true;
        }
        if (!obj.location) {
          errorObj.location = true;
        }
        if (isEditorHtmlEmpty(obj.shortDescriptionHtml)) {
          errorObj.shortDescription = true;
        }
        self.errors.put(errorObj);
      });
      return !self.hasErrors;
    }
  }))
  .views(self => ({
    get step0Diff() {
      let d = {};
      if (self.original) {
        self.languages.forEach(({ isLocal, ...obj }) => {
          const old = self.original.languages.find(oldObj => oldObj.language === obj.language);
          if (!old) {
            if (d.languages === undefined) d.languages = [];
            d.languages.push(obj);
            return;
          }
          let lang = { language: obj.language };
          if (old.title !== obj.title) lang.title = obj.title;
          if (old.location !== obj.location) lang.location = obj.location;
          if (!isSameEditorState(old.shortDescriptionHtml, obj.shortDescriptionHtml)) {
            lang.shortDescriptionHtml = obj.shortDescriptionHtml;
          }
          if (!isSameEditorState(old.description, obj.description)) {
            lang.description = obj.description;
          }
          if (Object.keys(lang).length > 1) {
            if (d.languages === undefined) d.languages = [];
            d.languages.push(lang);
          }
        });
      } else {
        self.languages.forEach(obj => {
          let lang = { language: obj.language };
          if (obj.title) lang.title = obj.title;
          if (obj.location) lang.location = obj.location;
          if (!obj.descriptionEmpty) lang.description = obj.description;
          if (!obj.shortDescriptionEmpty) lang.shortDescriptionHtml = obj.shortDescriptionHtml;

          if (Object.keys(lang).length > 1) {
            if (d.languages === undefined) d.languages = [];
            d.languages.push(lang);
          }
        });
      }

      if (self.dirtyDeadline) {
        d.deadline = self.deadline;
      }
      if (self.dirtyJobStatus) {
        d.status = self.status;
      }
      if (self.dirtyJobCategory) {
        d.category = self.category;
      }
      if (self.dirtyJobType) {
        d.jobType = self.jobType;
      }
      if (self.dirtyVisibility) {
        d.visibility = self.visibility;
      }

      return d;
    },
    get step0Dirty() {
      const initialState = {
        status: 'full_time',
        jobType: 'employee',
        visibility: 'public'
      };
      const hasInitialState = Object.keys(initialState).every(
        key => initialState[key] === self.step0Diff[key]
      );
      return (
        (Object.keys(self.step0Diff).length > 0 && self.original) ||
        (Object.keys(self.step0Diff).length > 0 && !self.original && !hasInitialState)
      );
    }
  }));

const ensureRequiredAlsoIncluded = data => {
  Object.keys(data).forEach(key => {
    if (key.startsWith('require') && data[key]) {
      data[key.replace('require', 'include')] = true;
    }
  });
};

const JobEditStep1 = t
  .model('JobEditStep1', {})
  .actions(self => ({
    saveStep1: flow(function*(cont) {
      if (!self.step1Dirty) {
        self.saveDraftStage(2);
        cont && self.goToEditRewards();
        return;
      }
      self.saving = true;
      if (self.isPublished) {
        let submit = true;
        self.languages.forEach(obj => {
          if (obj.isEnabled && !obj.isComplete) {
            self.notifyIncompletes(obj.language);
            submit = false;
          }
        });
        if (!submit) {
          self.saving = false;
          return;
        }
      }

      let obj = self.draftStage === 1 ? { ...self.step1Fields, ...self.step1Diff } : self.step1Diff;
      ensureRequiredAlsoIncluded(obj);
      obj.draftStage = Math.max(self.draftStage, 2);
      const res = yield self.env.api.jobs.edit(self.id, obj);
      if (!res.error) {
        self.env.notify('success', self.env.t('job.update.form.success', self.title));
        self.updateLocal(res.data);
        self.original.updateLocal(res.data);
        self.setInitialState();
        cont && self.goToEditRewards();
      } else {
        self.env.notify('error', self.env.t('job.update.form.error', self.title));
      }
      self.saving = false;
    }),
    actionForQuestion(id, actionFn) {
      self.languages.forEach(obj => {
        obj.form.sections.forEach(section => {
          section.questions.forEach(question => {
            if (question.id === id) {
              actionFn(question);
            }
          });
        });
      });
    },
    setSwitchValue(field, val) {
      switch (val) {
        case 'required':
          self[`require${field}`] = true;
          self[`include${field}`] = true;
          break;
        case 'optional':
          self[`require${field}`] = false;
          self[`include${field}`] = true;
          break;
        case 'off':
          self[`require${field}`] = false;
          self[`include${field}`] = false;
          break;
        default:
          throw new Error();
      }
    },
    removeQuestion(id) {
      self.actionForQuestion(id, question => question.remove());
    },
    moveQuestionDown(id) {
      self.actionForQuestion(id, question => question.moveDown());
    },
    moveQuestionUp(id) {
      self.actionForQuestion(id, question => question.moveUp());
    },
    setQuestionSwitch(id, value) {
      self.actionForQuestion(id, question => question.setSwitch(value));
    }
  }))
  .views(self => ({
    get step1FormDiff() {
      let d = { languages: [] };

      self.languages.forEach(obj => {
        if (!self.original) {
          d.languages.push({ language: obj.language, form: obj.form });
        } else {
          const oldLang = self.original.languages.find(oldObj => oldObj.language === obj.language);
          if (!oldLang) {
            d.languages.push({ language: obj.language, form: obj.form });
          } else {
            if (JSON.stringify(oldLang.form) !== JSON.stringify(obj.form)) {
              d.languages.push({ language: obj.language, form: obj.form });
            }
          }
        }
      });

      if (d.languages.length === 0) {
        delete d.languages;
      }

      return d;
    },
    get step1Diff() {
      return {
        ...Object.keys(self.step1Fields)
          .filter(k => self[k] !== self.original[k])
          .reduce((obj, key) => {
            obj[key] = self[key];
            return obj;
          }, {}),
        ...self.step1FormDiff
      };
    },
    get step1Dirty() {
      return Object.keys(self.step1Diff).length > 0;
    },
    get step1Fields() {
      return {
        includeCoverLetter: self.includeCoverLetter,
        includeCv: self.includeCv,
        includePhone: self.includePhone,
        includeSex: self.includeSex,
        includeSsn: self.includeSsn,
        requireCoverLetter: self.requireCoverLetter,
        requireCv: self.requireCv,
        requirePhone: self.requirePhone,
        requireSex: self.requireSex,
        requireSsn: self.requireSsn
      };
    }
  }));

const JobEditStep2 = t
  .model('JobEditStep2', {
    rewardsTitleError: false,
    rewardsError: false
  })
  .actions(self => ({
    saveStep2: flow(function*(cont) {
      if (!self.step2Dirty) {
        self.saveDraftStage(3);
        cont && self.goToEditHiringTeam();
        return;
      }

      if (self.step2Valid) {
        const success = yield self.saveRewards();
        if (success) {
          self.setInitialState();
          cont && self.goToEditHiringTeam();
        }
      } else {
        self.setRewardErrors();
      }
    }),
    setRewardErrors() {
      if (!self.hasRewards) return;
      self.rewardsTitleError = !self.rewardsTitle;
      self.rewardsError = !self.rewards;
    },
    setHasRewards(hasRewards) {
      if (!hasRewards) {
        self.rewardsError = false;
        self.rewardsTitleError = false;
      }
      self.hasRewards = hasRewards;
    },
    setRewardsTitle(rewardsTitle) {
      self.rewardsTitleError = false;
      self.rewardsTitle = rewardsTitle;
    },
    setRewardsText(rewardsText) {
      self.rewardsError = false;
      self.rewards = rewardsText;
    },
    saveRewards: flow(function*() {
      self.saving = true;
      const obj = self.step2Diff;
      obj.draftStage = self.draftStage = Math.max(self.draftStage, 3);
      const res = yield self.env.api.jobs.edit(self.id, obj);
      if (!res.error) {
        self.saving = false;
        self.original.updateLocal(obj);
        self.env.notify('success', self.env.t('job.update.rewards.success', self.title));
        return true;
      }
      self.saving = false;
      self.env.notify('error', self.env.t('job.update.rewards.error', self.title));
      return false;
    })
  }))
  .views(self => ({
    get step2Valid() {
      if (!self.hasRewards) return true;
      return !!(self.rewardsTitle && self.rewards);
    },
    get oldRewardValues() {
      return {
        rewards: self.original.rewards,
        rewardsTitle: self.original.rewardsTitle,
        hasRewards: self.original.hasRewards
      };
    },
    get rewardValues() {
      return {
        rewards: self.rewards,
        rewardsTitle: self.rewardsTitle,
        hasRewards: self.hasRewards
      };
    },
    get step2Dirty() {
      return Object.keys(self.step2Diff).length > 0;
    },
    get step2Diff() {
      const old = self.oldRewardValues;
      const now = self.rewardValues;
      if (!old.hasRewards && !now.hasRewards) return {};
      return Object.keys(now)
        .filter(key => old[key] !== now[key])
        .reduce((obj, key) => {
          obj[key] = now[key];
          return obj;
        }, {});
    }
  }));

const JobEditStep3 = t
  .model('JobEditStep3', {})
  .actions(self => ({
    saveStep3: flow(function*(cont) {
      if (!self.step3Dirty) {
        self.saveDraftStage(4);
        cont && self.goToEditPreview();
        return;
      }

      self.saving = true;
      const success = yield self.saveFollowers();
      if (success) {
        self.setInitialState();
        cont && self.goToEditPreview();
      }
      self.saving = false;
    }),
    saveFollowers: flow(function*() {
      const obj = {
        followers: getSnapshot(self.followers),
        draftStage: Math.max(self.draftStage, 4)
      };
      const res = yield self.env.api.jobs.edit(self.id, obj);
      if (!res.error) {
        self.original.updateLocal(obj);
        self.env.notify('success', self.env.t('job.update.followers.success', self.title));
        return true;
      }
      self.env.notify('error', self.env.t('job.update.followers.error', self.title));
      return false;
    })
  }))
  .views(self => ({
    get step3Dirty() {
      const now = self.followers;
      const old = self.original.followers;
      const oldDiff = old.filter(f => !now.includes(f));
      const nowDiff = now.filter(f => !old.includes(f));
      return oldDiff.length !== 0 || nowDiff.length !== 0;
    }
  }));

export const JobEdit = t
  .compose(Job, Edit, JobEditStep0, JobEditStep1, JobEditStep2, JobEditStep3)
  .named('JobEdit');
