import { types as t } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

export const NotificationSettings = t
  .model('NotificationSettings', {
    id: t.identifierNumber,
    notificationType: t.string,
    notifyInApp: t.boolean
  })
  .views(withEnv)
  .actions(self => ({
    toggleSetting(id) {
      self.env.api.notifications.toggleSetting(id, !self.notifyInApp);
      self.notifyInApp = !self.notifyInApp;
      self.env.notify('success', self.env.t('user.notifications.save_notification'));
    }
  }));
