import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'components/Button';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { useStore } from 'models/Provider';

function RemoveConfirmModal({ data, redirect, closeModal, confirm }) {
  const { router, t } = useStore();
  const [submitting, setSubmitting] = useState(false);
  async function submit() {
    setSubmitting(true);
    const success = await confirm(data.id);
    setSubmitting(false);
    if (success) {
      if (redirect) {
        router.push(`${redirect}`);
      }
      closeModal();
    }
  }
  return (
    <>
      <ModalHeader>{t('application.removeconfirm.header')}</ModalHeader>
      {t('application.removeconfirm.confirm', data.name)}
      <ModalText as="p">
        <strong>{t('application.removeconfirm.warning')}</strong>
      </ModalText>
      <ModalActions>
        <Button gray onClick={!submitting ? closeModal : null} disabled={submitting}>
          {t('application.removeconfirm.button.cancel')}
        </Button>
        <Button red onClick={!submitting ? submit : null} disabled={submitting}>
          {t('application.removeconfirm.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
}

export default observer(RemoveConfirmModal);
