import React from 'react';
import { Svg } from './Svg';

export default props => {
  return (
    <Svg {...props}>
      <path
        opacity="0.8"
        d="M12 8V16M12 16L15 14M12 16L9 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect
        opacity="0.8"
        x="1"
        y="1"
        width="22"
        height="22"
        rx="1"
        stroke="currentColor"
        strokeWidth="2"
      />
    </Svg>
  );
};
