import { types as t } from 'mobx-state-tree';

export const ApplicationUserRead = t.model('ApplicationUserRead', {
  newApplication: t.boolean,
  messages: t.boolean,
  notes: t.boolean,
  ratings: t.boolean,
  assignedTo: t.boolean,
  taskList: t.boolean,
  files: t.boolean
});
