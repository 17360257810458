import React from 'react';
import { useState } from 'react';
import createLinkifyPlugin from 'draft-js-linkify-plugin';

export const useLinkifyPlugin = () => {
  const [state] = useState(() => {
    const plugin = createLinkifyPlugin({
      target: '_blank',
      component: ({ blockKey, ...rest }) => (
        <a // eslint-disable-line jsx-a11y/anchor-has-content
          {...rest}
          onClick={e => {
            e.stopPropagation();
          }}
          style={{ textDecoration: 'underline' }}
        />
      )
    });
    return [plugin];
  });

  return state;
};
