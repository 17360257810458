import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { Box } from 'components/Box';
import { H2, P, Text, TextLabel } from 'components/Text';
import { IconFacebook32 } from 'components/icons/IconFacebook32';
import { NamePlate } from 'components/NamePlate';

function Rewards({ job, application, t }) {
  return (
    <>
      <H2>
        {t('application.onboarding.rewards.header')}{' '}
        <span role="img" aria-label="reward">
          🎉
        </span>
      </H2>
      {job.hasRewards && (
        <Text mt="16">
          <strong>{job.rewardsTitle}</strong>
          <Text>{job.rewards}</Text>
        </Text>
      )}

      {!job.hasRewards && <P>{t('application.onboarding.rewards.norewards')}</P>}

      <Box mt="24">
        <TextLabel>{t('application.onboarding.rewards.sourcedby')}</TextLabel>
      </Box>

      {application.referredBy && (
        <Box mt="8">
          <RewardsCard>
            <NamePlate
              name={application.referredBy.name}
              size="lg"
              pic={application.referredBy.picture}
              title={application.referredBy.roleDisplay}
            />
            {application.referredSite && application.referredSite.indexOf('facebook.com') > 1 && (
              <IconFacebook32 />
            )}
          </RewardsCard>
        </Box>
      )}
      {!application.referredBy && <Text>{t('application.onboarding.rewards.nosource')}</Text>}
    </>
  );
}

export default observer(Rewards);

const RewardsCard = styled.div(p => ({
  backgroundColor: p.theme.colors.white100,
  borderRadius: '0.25rem',
  padding: '0.5rem 0.75rem',
  display: 'flex',
  alignItems: 'center'
}));
