import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';

import { useStore } from 'models/Provider';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import ShowMore from 'components/ShowMore';
import { Separator } from 'components/Separator';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { Box } from 'components/Box';
import {
  convertPlaceholders,
  isEditorStateEmpty,
  makeEditorState,
  placeholdersToBrackets
} from 'components/TextEditor';
import Form from './Form';

function MessageApplicantsModal({ applicants, applicant, closeModal }) {
  const { current, t, me } = useStore();
  const [submitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState({
    message: EditorState.createEmpty()
  });
  const templates = current.messageTemplates;

  function onTemplateSelect(id) {
    const select = templates.find(t => t.id === id);
    if (select) {
      const { contentHtml, contentTxt } = select;
      const editorState = makeEditorState(contentHtml || contentTxt);

      setSelected(old => ({
        message: convertPlaceholders(editorState, t)
      }));
    }
  }

  function setEditorState(editorState) {
    setSelected(old => ({
      ...old,
      message: convertPlaceholders(editorState, t)
    }));
  }

  async function onSubmit() {
    const { message } = selected;
    const job = applicant ? applicant.job : applicants[0].job;

    let success;
    setSubmitting(true);

    const rawData = convertToRaw(message.getCurrentContent());
    const converted = placeholdersToBrackets(rawData);

    const emailData = {
      message: draftToHtml(converted)
    };
    if (applicant) {
      success = await applicant.sendMessage(message);
    } else {
      success = await job.bulkMessageApplicants(applicants, emailData);
    }

    if (success) {
      closeModal();
    } else {
      setSubmitting(false);
    }
  }

  const disableButtons = submitting || isEditorStateEmpty(selected.message);
  return (
    <>
      <ModalHeader>{t('job.bulkmessage.title')}</ModalHeader>
      {submitting && <Loading />}

      {applicant && (
        <ModalText
          dangerouslySetInnerHTML={{
            __html: t('job.bulkmessage.confirm.single', applicant.name)
          }}
        />
      )}

      {applicants && (
        <>
          <ModalText>{t('job.bulkmessage.confirm.multi')}</ModalText>
          <Box mt="12">
            <ShowMore
              max={applicants.length}
              count={applicants.length > 15 ? 10 : applicants.length}
              inc={1000}
              render={({ count }) => (
                <div>
                  {applicants.slice(0, count).map((a, i) => (
                    <Name key={i}>
                      <span>{a.name}</span>
                    </Name>
                  ))}
                </div>
              )}
            />
          </Box>
        </>
      )}

      <Separator mt="24" mb="24" />

      <Form
        disabled={submitting}
        templates={templates}
        isOpen
        selected={selected}
        onSelect={onTemplateSelect}
        user={me}
        setEditorState={setEditorState}
      />
      <ModalActions>
        <Button disabled={submitting} gray onClick={closeModal}>
          {t('job.bulkmessage.button.cancel')}
        </Button>
        <Button disabled={disableButtons} onClick={onSubmit}>
          {t('job.bulkmessage.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
}

export default observer(MessageApplicantsModal);

const Name = styled.div`
  color: ${p => p.theme.textColor};
  > span:nth-child(1) {
    font-weight: bold;
  }
  > span:nth-child(2) {
    font-style: italic;
    margin-left: 5px;
  }
`;
