import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';

import { useStore } from 'models/Provider';
import { CompanyInfo } from 'models/companies';
import { Button, ButtonGroup } from 'components/Button';
import { Box } from 'components/Box';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { Text, FormLabel } from 'components/Text';
import { Loading } from 'components/Loading';

export const CompanySettings = observer(() => {
  const { t, current, notify } = useStore();
  const [saving, setSaving] = useState(false);
  const [draft, setDraft] = useState(() => CompanyInfo.create(getSnapshot(current.info)));
  const info = current.info;

  function isDirty() {
    if (!current) return false;
    return (
      info.defaultLanguage !== draft.defaultLanguage ||
      info.url !== draft.url ||
      info.companyName !== draft.companyName ||
      info.languages !== draft.languages
    );
  }

  function cancel() {
    setDraft(CompanyInfo.create(getSnapshot(current.info)));
  }

  async function save() {
    if (draft.langs.length === 0) {
      notify('error', t('company.settings.save.empty.languages'));
      return;
    }
    setSaving(true);
    const success = await current.update({
      companyName: draft.companyName,
      url: draft.url,
      defaultLanguage: draft.defaultLanguage,
      languages: draft.languages
    });
    if (success) {
      cancel();
    }
    setSaving(false);
  }

  if (!draft) return <Loading />;
  const dirty = isDirty();
  const disable = !dirty || saving;
  const defaultSelectedLangs = draft.langs.reduce((res, lang) => {
    res[lang] = true;
    return res;
  }, {});
  const defaultLangItems = draft.langs.map(lang => ({
    value: lang,
    display: t(`general.${lang}`)
  }));

  return (
    <Section>
      <Text type="h2" color="green100">
        {t('company.settings.root.heading')}
      </Text>

      <Box mt="24">
        <FormLabel>{t('company.settings.root.label.name')}</FormLabel>
        <Input disabled={saving} value={draft.companyName} onChange={draft.setName} />
      </Box>

      <Box mt="16">
        <FormLabel>{t('company.settings.root.label.url')}</FormLabel>
        <Input disabled={saving} value={draft.url} onChange={draft.setUrl} />
      </Box>

      <Box mt="16">
        <FormLabel>{t('company.settings.root.label.langs')}</FormLabel>
        <Select
          title={t('company.settings.root.selectlangs')}
          multi
          error={draft.langs.length === 0 && t('errors.required')}
          disable={saving}
          defaultValue={defaultSelectedLangs}
          items={[
            { value: 'en', display: t('general.en') },
            { value: 'is', display: t('general.is') }
          ]}
          onChange={draft.setLanguages}
        />
      </Box>

      <Box mt="16">
        <FormLabel>{t('company.settings.root.label.lang')}</FormLabel>
        <Select
          key={draft.defaultLanguage + defaultLangItems.length}
          disable={saving || defaultLangItems.length === 1}
          defaultValue={draft.defaultLanguage}
          items={defaultLangItems}
          onChange={draft.setLanguage}
        />
      </Box>

      <ButtonGroup right mt="24">
        {dirty && (
          <Button gray onClick={cancel}>
            {t('company.settings.root.button.cancel')}
          </Button>
        )}
        <Button disabled={disable} onClick={disable ? null : save}>
          {t('company.settings.root.button.submit')}
        </Button>
      </ButtonGroup>
    </Section>
  );
});

const Section = styled.div({
  width: '100%',
  maxWidth: '23.75rem',
  margin: '0 auto'
});
