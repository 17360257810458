import styled from 'styled-components/macro';

export const IntegrationsContentContainer = styled.div`
  display: flex;
  max-width: 1100px;
  flex-wrap: wrap;
  align-items: flex-start;
  > div:nth-child(1) {
    width: 100%;
    max-width: 280px;

    @media (max-width: ${p => p.theme.bp.md}) {
      max-width: 380px;
    }
  }
  @media (max-width: ${p => p.theme.bp.md}) {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    > div:not(:nth-child(1)) {
      margin-top: 30px;
    }
  }
`;

export const IntegrationsContent = styled.div`
  max-width: 380px;
  width: 100%;
  @media (min-width: ${p => p.theme.bp.md}) {
    margin-left: 10%;
  }
`;

export const IntegrationExamples = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const IntegrationExample = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.white100};
  border-radius: 50%;
  box-shadow: 0 0.1875rem 0.25rem 0 rgba(0, 0, 0, 0.05);
  display: flex;
  height: 5rem;
  justify-content: center;
  margin: 10px;
  text-align: center;
  width: 5rem;
  svg {
    height: 2.5rem;
    width: 2.5rem;
  }
`;

export const IntegrationIcon = styled.img`
  height: 2.5rem;
`;
