import { types as t } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

export const JobRoutes = t
  .model('JobRoutes', {})
  .views(withEnv)
  .actions(self => ({
    goToDetail: () => self.env.router.push(`/jobs/${self.id}`),
    goToEdit: () => self.env.router.push(`/edit/${self.id}`),
    goToEditForm: () => self.env.router.push(`/edit/${self.id}/form`),
    goToEditRewards: () => self.env.router.push(`/edit/${self.id}/rewards`),
    goToEditHiringTeam: () => self.env.router.push(`/edit/${self.id}/recruiters`),
    goToEditPreview: () => self.env.router.push(`/edit/${self.id}/preview`),
    goToSharingDetail: () => self.env.router.push(`/sharing/${self.id}`)
  }))
  .views(self => ({
    get integrationsUrl() {
      return `/jobs/${self.id}/integrations`;
    },
    get detailUrl() {
      return `/jobs/${self.id}`;
    },
    get applicantsUrl() {
      return `/jobs/${self.id}/list`;
    },
    get hiringTeamUrl() {
      return `/jobs/${self.id}/team`;
    },
    get pipelineUrl() {
      return `/jobs/${self.id}/pipeline`;
    },
    get statisticsUrl() {
      return `/jobs/${self.id}/statistics`;
    }
  }));
