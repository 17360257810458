import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Select } from 'components/Select';
import { Switch } from 'components/Switch';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { FormLabel } from 'components/Text';
import { Box } from 'components/Box';
import { CreateUserJobAccessForm } from './CreateUserJobAccessForm';

export const CreateUserForm = observer(props => {
  const { close, user, save, errors, allowSend = true, disabled, jobs, t } = props;
  const { name, email, role, sendInvite } = user;
  const userTypes = [
    {
      value: 'user',
      display: t('user.role.user'),
      helpText: t('user.role.user.help')
    },
    {
      value: 'limited',
      display: t('user.role.limited'),
      helpText: t('user.role.limited.help')
    },
    {
      value: 'recruiter',
      display: t('user.role.recruiter'),
      helpText: t('user.role.recruiter.help')
    },
    {
      value: 'admin',
      display: t('user.role.admin'),
      helpText: t('user.role.admin.help')
    }
  ];

  let errorObj = {};
  for (let [key, val] of errors.entries()) {
    errorObj[key] = val.slice().join(', ');
  }

  // We'll show errors for name/email below their own inputs.
  // Only do this global error container for other errors.
  const errorContainer = Object.keys(errorObj)
    .filter(key => key !== 'email' && key !== 'name')
    .map((errorKey, i) => {
      return <ErrorMessage key={i}>{errorObj[errorKey]}</ErrorMessage>;
    });

  return (
    <>
      {errorContainer}
      <Box mt="24">
        <FormLabel>{t('user.create.label.name')}</FormLabel>
        <Input value={name} onChange={user.setName} error={errorObj.name} />
      </Box>

      <Box mt="12">
        <FormLabel>{t('user.create.label.email')}</FormLabel>
      </Box>
      <Input value={email} onChange={user.setEmail} error={errorObj.email} />

      <Box mt="12">
        <FormLabel>{t('user.create.label.role')}</FormLabel>
      </Box>
      <Select inModal defaultValue={role} items={userTypes} onChange={user.setRole} />
      {role === 'admin' && (
        <Box mt="16">
          <ToggleButtonsContainer>
            <Switch value={user.addToNewJobs} onToggle={user.toggleAddToNewJobs} />
            <ToggleText>{t('user.create.addtonewjobs.toggle')}</ToggleText>
          </ToggleButtonsContainer>
        </Box>
      )}
      {(role === 'admin' || role === 'recruiter') && (
        <Box mt="16">
          <CreateUserJobAccessForm user={user} jobs={jobs} t={t} />
        </Box>
      )}

      {allowSend && (
        <Box mt="16">
          <ToggleButtonsContainer>
            <Switch value={sendInvite} onToggle={user.toggleSendInvite} disabled={disabled} />
            <ToggleText>{t('user.create.sendinvite.toggle')}</ToggleText>
          </ToggleButtonsContainer>
        </Box>
      )}

      <Box mt={allowSend ? '16' : '32'} flex justifyContent="flex-end">
        <Link to="/team" onClick={close}>
          <Button gray>{t('user.create.button.cancel')}</Button>
        </Link>
        <Button onClick={save} disabled={disabled} ml="8">
          {t('user.create.button.submit')}
        </Button>
      </Box>
    </>
  );
});

const ToggleText = styled.div`
  color: #7d7f97;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
`;

const ToggleButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-right: 10px;
  }
`;

const ErrorMessage = styled.div`
  color: #d50000;
  font-size: 13px;
`;
