import { applySnapshot, flow, destroy, getParent, types as t } from 'mobx-state-tree';
import { sortBy, transformJob } from 'helpers';
import { withEnv } from 'models/withEnv';
import { Job } from 'models/jobs';

const JobSharing = t.compose(Job).named('JobSharing');
const JobMinimal = t.compose(Job).named('JobMinimal');

export const JobStore = t
  .model('JobStore', {
    items: t.map(t.late(() => Job)),
    searchedJobs: t.array(t.reference(t.late(() => Job))),
    sharing: t.array(JobSharing),
    minimal: t.map(JobMinimal),
    loading: false,
    loaded: false,
    loadingMinimal: false,
    loadedMinimal: false,
    sharingLoaded: false
  })
  .views(withEnv)
  .actions(self => ({
    loadJob(jobId) {
      const job = self.items.get(jobId);
      if (!job) {
        self.addOrUpdate({ id: Number(jobId) });
        self.loadApplicationCount(jobId);
      }
      self.items.get(jobId).load();
    },
    loadForUserAccess: flow(function*() {
      const res = yield self.env.api.jobs.loadForUserAccess();
      if (!res.error) {
        res.data.results.forEach(j => {
          self.addOrUpdate(j);
        });
      }
    }),
    load: flow(function*(status) {
      if (self.loading) return;
      const searchStr = getParent(self).uiSettings.dashboard.searchStr;
      self.loading = true;
      let size;
      switch (status) {
        case 'published':
          size = Array.from(self.items.values()).filter(
            j => j.isPublished && !j.isArchived && !j.isExpired
          ).length;
          break;
        case 'drafts':
          size = Array.from(self.items.values()).filter(j => !j.isPublished && !j.isArchived)
            .length;
          break;
        case 'expired':
          size = Array.from(self.items.values()).filter(
            j => j.isPublished && !j.isArchived && j.isExpired
          ).length;
          break;
        case 'archived':
          size = Array.from(self.items.values()).filter(j => j.isArchived).length;
          break;
        default:
          size = Array.from(self.items.values()).filter(
            j => j.isPublished && !j.isArchived && !j.isExpired
          ).length;
          break;
      }

      const res = yield self.env.api.jobs.load(status, size, searchStr);
      self.env.current.uiSettings.dashboard.setNextUrl(status, res.data.next);

      res.data.results.forEach(j => {
        self.addOrUpdate(j);
        if (!j.isArchived) {
          self.loadApplicationCount(j.id);
        }
      });
      if (searchStr !== undefined && searchStr !== '') {
        self.searchedJobs.clear();
        res.data.results.forEach(j => {
          self.searchedJobs.push(j.id);
        });
      }

      self.loading = false;
      self.loaded = true;
    }),
    loadApplicationCount: flow(function*(jobId) {
      const [totalRes, todayRes] = yield Promise.all([
        self.env.api.applications.loadCountForJob(jobId),
        self.env.api.applications.loadCountForJobToday(jobId)
      ]);
      if (!totalRes.error) {
        self.items.get(jobId).setNumApplications(totalRes.data.count);
      }
      if (!todayRes.error) {
        self.items.get(jobId).setNumApplicationsToday(todayRes.data.count);
      }
    }),
    loadNext: flow(function*(status) {
      if (self.loading) return;
      self.loading = true;
      const url = self.env.current.uiSettings.dashboard.nextUrls[status];
      const res = yield self.env.api.get(url);
      self.env.current.uiSettings.dashboard.setNextUrl(status, res.data.next);

      res.data.results.forEach(j => {
        self.addOrUpdate(j);
        if (!j.isArchived) {
          self.loadApplicationCount(j.id);
        }
      });
      self.loading = false;
      self.loaded = true;
    }),
    loadMinimal: flow(function*() {
      if (self.loadedMinimal || self.loadingMinimal) return;
      self.loadingMinimal = true;

      const res = yield self.env.api.jobs.loadMinimal();

      res.data.results.forEach(j => {
        self.minimal.put(j);
      });
      self.loadingMinimal = false;
      self.loadedMinimal = true;
    }),
    loadSharing: flow(function*() {
      self.sharingLoaded = false;
      const res = yield self.env.api.jobs.loadSharing();
      if (!res.error) {
        applySnapshot(self.sharing, res.data);
        if (self.items.size > 0) {
          res.data.forEach(job => {
            const j = self.items.get(job.id);
            if (j && job.hasRewards) {
              j.localEdit('hasRewards', job.hasRewards);
              j.localEdit('rewardsTitle', job.rewardsTitle);
              j.localEdit('rewards', job.rewards);
            }
          });
        }
      }
      self.sharingLoaded = true;
    }),
    goToDetail(jobId) {
      self.env.router.push(`/jobs/${jobId}`);
    },
    goToEdit(jobId) {
      self.env.router.push(`/edit/${jobId}`);
    },
    addOrUpdate(j) {
      let job = transformJob(j);

      const curr = self.items.get(job.id);

      if (!curr) {
        self.items.put(job);
      } else {
        self.items.put({ ...curr, ...job });
      }
    },
    delete: flow(function*(job) {
      const res = yield self.env.api.jobs.delete(job.id);
      if (!res.error) {
        self.env.notify('success', self.env.t('job.store.delete.success', job.title));
        self.env.router.push('/');
        destroy(job);
        return true;
      } else {
        self.env.notify('error', self.env.t('job.store.delete.error', job.title));
        return false;
      }
    })
  }))
  .views(self => ({
    get sorted() {
      return sortBy(self.filtered, 'id', 'desc');
    },
    get filtered() {
      const str = getParent(self).uiSettings.dashboard.searchStr;
      if (str) {
        return self.searchedJobs;
      } else {
        return self.list;
      }
    },
    get list() {
      return Array.from(self.items.values());
    },
    get minimalList() {
      return Array.from(self.minimal.values());
    },
    get sortedByTitle() {
      return sortBy(self.list, 'title', 'asc');
    },
    get sortedMinimalByTitle() {
      return sortBy(self.minimalList, 'title', 'asc');
    },
    get notArchived() {
      return self.sorted.filter(j => !j.isArchived);
    },
    get drafts() {
      return self.sorted.filter(j => !j.isPublished && !j.isArchived);
    },
    get published() {
      return self.sorted.filter(j => j.isPublished && !j.isExpired);
    },
    get expired() {
      return self.sorted.filter(j => j.isPublished && j.isExpired && !j.isArchived);
    },
    get archived() {
      return self.sorted.filter(j => j.isArchived);
    },
    get sharingList() {
      return self.sharing.filter(j => j.isPublished && !j.isArchived && !j.isExpired);
    }
  }));
