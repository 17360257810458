import styled from 'styled-components/macro';
import React from 'react';

const Wrapper = styled.div`
  .input-radio {
    opacity: 0;
    display: none;
    + .radio-label {
      margin-right: 20px;
      color: ${p => p.theme.textColor};
      &:before {
        box-sizing: border-box;
        content: '';
        background: #f6f6f6;
        border-radius: 100%;
        border: 2px solid #717171;
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: -2px;
        margin-right: 8px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: ${p => p.theme.radioColor || p.theme.primaryColor};
          box-shadow: inset 0 0 0 4px #f4f4f4;
          border-color: ${p => p.theme.radioColor || p.theme.primaryColor};
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #f4f4f4;
          border-color: #717171;
          background: #717171;
        }
      }
    }
    + .radio-label {
      :hover {
        cursor: pointer;
      }
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
`;

function Radio({ onCheck, checked, label, domValue, dataId, value }) {
  return (
    <Wrapper>
      <input
        className="input-radio"
        id={'radio' + value}
        name={'radio' + domValue}
        type="radio"
        checked={checked}
        onChange={onCheck}
        data-id={dataId}
        value={value}
      />
      <label htmlFor={'radio' + value} className="radio-label">
        {label}
      </label>
    </Wrapper>
  );
}
export default Radio;
