import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

const ClickOutside = ({ onOutsideClick, children }) => {
  const containerRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (!containerRef.current.contains(event.target) && onOutsideClick) {
        onOutsideClick(event);
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, [onOutsideClick]);

  return (
    <div style={{ display: 'contents' }} ref={containerRef}>
      {children}
    </div>
  );
};

export default observer(ClickOutside);
