import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';

import MultiSelectDropDown from 'shared/modules/MultiSelectDropDown';
import { useStore } from 'models/Provider';
import { Separator } from 'components/Separator';
import { Button } from 'components/Button';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { NamePlate } from 'components/NamePlate';
import { useOnDDClick } from 'hooks/useOnDDClick';

function AssignToApplicantModal({ closeModal, applicant }) {
  const [assignedTo, setAssignedTo] = useState(applicant.assignedTo);
  const [submitting, setSubmitting] = useState(false);
  const onDDClick = useOnDDClick('assign-applicant-modal');
  const { userStore, t } = useStore();

  function onSubmit() {
    setSubmitting(true);
    const success = applicant.setAssignedTo(assignedTo);
    if (success) {
      closeModal();
    } else {
      setSubmitting(false);
    }
  }

  return (
    <>
      <ModalHeader>{t('assignmodal.header')}</ModalHeader>
      <ModalText>{t('assignmodal.description')}</ModalText>
      <Separator mt="24" mb="24" />
      {!assignedTo && (
        <div id="assign-applicant-modal" onClick={onDDClick}>
          <MultiSelectDropDown
            inModal
            fullSize
            onChange={id => setAssignedTo(userStore.items.get(id))}
            text={t('assignmodal.select.emptystate')}
          >
            {applicant.allFollowers.map(user => (
              <UserItem key={user.id} onClick={() => setAssignedTo(userStore.items.get(user.id))}>
                <NamePlate name={user.name} pic={user.picture} title={user.roleDisplay} />
              </UserItem>
            ))}
          </MultiSelectDropDown>
        </div>
      )}
      {assignedTo && (
        <UserItem selected key={assignedTo.id}>
          <NamePlate
            name={assignedTo.name}
            pic={assignedTo.picture}
            title={assignedTo.roleDisplay}
            onRemove={() => setAssignedTo(null)}
          />
        </UserItem>
      )}

      <ModalActions>
        <Button disabled={submitting} gray onClick={closeModal}>
          {t('assignmodal.button.cancel')}
        </Button>
        <Button disabled={submitting} onClick={onSubmit}>
          {t('assignmodal.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
}

export default observer(AssignToApplicantModal);

const UserItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: ${p => (p.selected ? '0.25rem 0' : '0 0.5rem')};
  min-height: 2.5rem;
  line-height: 2.5rem;
  text-decoration: none;
  transition: all 0.15s ease-out;
  ${p =>
    !p.selected &&
    css`
      :hover {
        border-radius: 0.25rem;
        background-color: rgba(0, 0, 0, 0.04);
      }
    `};

  ${p =>
    p.selected &&
    css`
      border-radius: 0.25rem;
      background-color: rgba(0, 0, 0, 0.04);
    `};
`;
