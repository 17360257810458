import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import SingleLanguagePublishedModal from './SingleLanguagePublishedModal';
import MultiLanguagePublishedModal from './MultiLanguagePublishedModal';

function PublishedModal({ closeModal, job, t, redirectOnSuccess = true }) {
  const { current } = useStore();
  if (current.langs.length === 1 && job.languages.length === 1) {
    return (
      <SingleLanguagePublishedModal
        closeModal={closeModal}
        job={job}
        t={t}
        redirectOnSuccess={redirectOnSuccess}
      />
    );
  }
  return (
    <MultiLanguagePublishedModal
      closeModal={closeModal}
      job={job}
      t={t}
      redirectOnSuccess={redirectOnSuccess}
    />
  );
}

export default observer(PublishedModal);
