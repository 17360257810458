import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { IconCloud } from 'components/icons/IconCloud';
import { Text } from 'components/Text';

export const FileUpload = observer(({ file, disabled, error, onChange }) => {
  const inputRef = React.useRef();

  const changeFile = e => {
    const file = e.target.files.length > 0 ? e.target.files[0] : null;
    if (onChange) {
      onChange(file);
    }
  };

  const uploadClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Container
        onKeyDown={e => {
          if (e.key === 'Enter') {
            uploadClick();
          }
        }}
        tabIndex={0}
        onClick={disabled ? null : uploadClick}
        filled={file !== null}
        disabled={disabled}
        error={error}
      >
        <IconCloud />
        {file !== null && (
          <Text
            ml="16"
            type="semibold12"
            color="white100"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {file.name}
          </Text>
        )}
      </Container>
      <input ref={inputRef} style={{ display: 'none' }} type="file" onChange={changeFile} />
    </>
  );
});

const Container = styled.div(p => ({
  opacity: p.filled ? 1 : 0.65,
  padding: '0 1rem',
  display: 'flex',
  justifyContent: p.filled ? 'flex-start' : 'center',
  backgroundColor: p.filled ? p.theme.colors.purple100 : 'transparent',
  color: p.filled ? p.theme.colors.white100 : p.theme.colors.purple100,
  alignItems: 'center',
  height: '2.5rem',
  width: '100%',
  borderWidth: '0.0625rem',
  borderStyle: 'solid',
  borderColor: p.filled ? p.theme.colors.purple100 : p.theme.colors.purpleTrans10,
  borderRadius: '0.25rem',
  cursor: 'pointer',
  ...(!p.filled && {
    ':hover, :focus': {
      borderColor: p.theme.colors.purple100,
      opacity: 1,
      outline: 'none'
    }
  })
}));
