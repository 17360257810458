import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconArrowForward = props => (
  <Svg {...props}>
    <path
      d="M19 12H5m14 0l-6-6m6 6l-6 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
