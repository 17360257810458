import { types as t } from 'mobx-state-tree';
import moment from 'moment';

import { User } from '../users';
import { Attachment } from '.';

export const ApplicationMessage = t
  .model('ApplicationMessage', {
    id: t.identifierNumber,
    user: t.maybeNull(t.reference(t.late(() => User))),
    created: t.maybe(t.string),
    modified: t.maybe(t.string),
    message: t.maybeNull(t.string),
    messageType: t.maybe(t.string),
    attachment: t.maybeNull(t.late(() => Attachment)),

    // We compute the attachments in the transformer so we can
    // easily group together attachments with their messages
    attachments: t.array(t.late(() => Attachment))
  })
  .views(self => ({
    get dateDisplay() {
      const momentObj = moment(self.created);
      const date = momentObj.format(' MMMM Do YYYY');
      const time = momentObj.format(' HH:mm');
      return `${date} • ${time}`;
    }
  }));
