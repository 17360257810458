import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

export const UserAvatar = observer(props => {
  const {
    src,
    srcset,
    name,
    size = 'sm',
    color = 'white100',
    backgroundColor = 'green100',
    ...rest
  } = props;

  const abbr = React.useMemo(() => {
    if (name) {
      const names = name.split(' ');
      if (names.length > 1) {
        return names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
      } else {
        return names[0].charAt(0).toUpperCase();
      }
    }
    return '?';
  }, [name]);

  const hasImg = src || srcset;

  return (
    <Container size={size} hasImg={hasImg} bg={backgroundColor} color={color} {...rest}>
      <div>{abbr}</div>
      {hasImg && <Img src={src} srcSet={srcset} alt="avatar" />}
    </Container>
  );
});

const setDimension = dim => ({
  width: dim,
  minWidth: dim,
  height: dim,
  minHeight: dim
});

const Container = styled.div(p => ({
  position: 'relative',
  backgroundColor: p.bg ? p.theme.colors[p.bg] : p.theme.colors.green100,
  color: p.color ? p.theme.colors[p.color] : p.theme.colors.white100,
  borderWidth: 0,
  borderColor: p.theme.colors.purple40,
  borderStyle: 'solid',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(p.size === 'sm' && setDimension('1.5rem')),
  ...(p.size === 'md' && {
    ...setDimension('2rem'),
    ...(p.hasImg && { borderWidth: '0.125rem' })
  }),
  ...(p.size === 'lg' && {
    ...setDimension('2.5rem'),
    ...(p.hasImg && { borderWidth: '0.125rem' })
  }),
  ...(p.size === 'xl' && {
    ...setDimension('5rem'),
    ...(p.hasImg && { borderWidth: '0.25rem' }),
    ...p.theme.text.h1
  })
}));

const Img = styled.img(p => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  zIndex: 2,
  objectFit: 'cover'
}));
