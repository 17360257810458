import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconSort = props => (
  <Svg {...props} style={{ transform: 'rotate(90deg)' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z"
      fill="currentColor"
    />
  </Svg>
);
