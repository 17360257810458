import { flow, types as t } from 'mobx-state-tree';
import moment from 'moment';

import { storage } from 'shared/utils/browser';
import { groupBy, sortBy } from 'helpers';
import { withEnv } from 'models/withEnv';
import { Notification, NotificationSettings, EmailNotifications } from 'models/notifications';

export const NotificationStore = t
  .model('NotificationStore', {
    settings: t.map(NotificationSettings),
    emailSettings: t.optional(EmailNotifications, {
      newApplication: false,
      mentionedInNote: false
    }),
    notifications: t.map(Notification),
    latestNotification: t.maybeNull(t.string),
    loadingSettings: false,
    loadedSettings: false,
    loading: false,
    loaded: false,
    show: false
  })
  .views(withEnv)
  .actions(self => ({
    toggle() {
      self.show = !self.show;
    },
    load: () => {
      if (self.loading || self.loaded) return;
      let interval = Number(storage.get('50_notifications_interval')) * 1000 || 60000;
      self.loadNotifications();
      setInterval(function() {
        self.loadNotifications();
      }, interval);
    },
    loadNotifications: flow(function*() {
      if (self.loading) return;
      self.loading = true;

      const res = yield self.env.api.notifications.load(
        self.latestNotification ? encodeURIComponent(self.latestNotification) : null
      );
      const today = moment();
      res &&
        res.data &&
        res.data.results.forEach(async n => {
          const notification = self.notifications.get(n.id);
          if (!notification) {
            n.isToday = today.isSame(moment(n.created), 'd');
            self.notifications.put(n);
          }
        });

      if (res.data && res.data.results.length) {
        self.latestNotification = res.data.results[0].created;
      }
      self.loaded = true;
      self.loading = false;
    }),
    loadSettings: flow(function*() {
      if (self.loadedSettings || self.loadingSettings) return;
      self.loadingSettings = true;

      const res = yield self.env.api.notifications.loadSettings();

      res.data.forEach(s => self.settings.put(s));

      const userRes = yield self.env.api.notifications.loadUser();

      self.emailSettings.newApplication = userRes.data.receiveNewApplicationEmail;
      self.loadingSettings = false;
      self.loadedSettings = true;
    }),
    markAsRead: ids => {
      ids.forEach(id => {
        self.notifications.get(id).markAsRead();
      });
    },
    markAllAsRead: () => {
      const highestId = Math.max.apply(
        Math,
        Array.from(self.notifications.values()).map(function(o) {
          return o.id;
        })
      );
      self.env.api.notifications.markAllAsRead(highestId);
      self.notifications.forEach(n => {
        if (!n.isRead) {
          n.isRead = true;
        }
      });
    }
  }))
  .views(self => ({
    get listSettings() {
      return Array.from(self.settings.values());
    },
    get list() {
      const notifications = Array.from(self.notifications.values()).filter(a => {
        return !(a.applicationId && !a.applicationName);
      });
      return groupBy(sortBy(notifications, 'created', 'desc'), [
        'user',
        'jobId',
        'notificationType',
        'isRead',
        'before',
        'after',
        'isToday',
        'triggerId',
        'triggerType'
      ]);
    },
    get unread() {
      return self.list.filter(n => {
        return !n.isRead;
      });
    },
    get settingsUrl() {
      if (!self.env.me) return '';
      return `/team/${self.env.me.id}/notifications/`;
    }
  }));
