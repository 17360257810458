import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { storage } from 'shared/utils/browser';
import RoundButton from 'shared/buttons/RoundButton';

const locale = {
  en: {
    main_text: 'By using the site, you agree to our',
    privacy_cta_text: ' cookie policy.',
    privacy_button_text: 'Dismiss'
  },
  is: {
    main_text: 'Með því að nota síðuna samþykkirðu',
    privacy_cta_text: ' notkun á kökum (e. cookies).',
    privacy_button_text: 'Fela'
  }
};

function CookieConsent({ lang, site }) {
  const [status, setStatus] = useState('closed');

  useEffect(() => {
    const siteToken = storage.get(`50_cc_${site}`);
    if (!siteToken) {
      setStatus('show');
    }
  }, [site]);

  function onClose() {
    storage.set(`50_cc_${site}`, true);
    setStatus('hide');
    setTimeout(() => {
      setStatus('closed');
    }, 300);
  }

  if (status === 'closed') return null;

  return (
    <Wrapper show={status === 'show'} close={status === 'hide'}>
      <div>
        <span>{locale[lang].main_text}</span>
        <a href="https://www.50skills.com/privacy-policy" target="_blank" rel="noopener noreferrer">
          {locale[lang].privacy_cta_text}
        </a>
      </div>
      <RoundButton onClick={onClose} green>
        {locale[lang].privacy_button_text}
      </RoundButton>
    </Wrapper>
  );
}

export default CookieConsent;

const fadeIn = keyframes`
  from {bottom: -30px; opacity: 0;}
  to {bottom: 0px; opacity: 1;}
`;

const fadeOut = keyframes`
  from {bottom: 0px; opacity: 1;}
  to {bottom: -30px; opacity: 0;}
`;

const Wrapper = styled.div`
  position: fixed;
  min-height: 60px;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(61, 64, 102, 0.9) 0%, #3d4066 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  color: #fff;
  z-index: 99999;
  transition: all 0.2s ease-in-out;
  @media (max-width: 720px) {
    padding: 30px 20px;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
  }

  > div:nth-child(1) {
    margin-right: 40px;
    @media (max-width: 720px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  a {
    opacity: 1;
    color: rgba(0, 200, 130, 1.2);

    :hover {
      opacity: 0.65;
      transition: all 0.2s ease-in-out;
    }
  }

  ${p =>
    p.show &&
    css`
      visibility: visible;
      animation: ${fadeIn} 0.5s;
    `};

  ${p =>
    p.close &&
    css`
      animation: ${fadeOut} 0.5s;
    `};
`;
