import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { sortBy } from 'helpers';
import { useStore } from 'models/Provider';
import {
  ReadOnlyTextEditor,
  editorStateToHtml,
  TextEditor,
  TextEditorBoxStyle,
  useEditorState
} from 'components/TextEditor';
import { Box } from 'components/Box';
import { Button, ButtonGroup } from 'components/Button';
import { Text, TextLabel } from 'components/Text';
import { IconEmail } from 'components/icons/IconEmail';
import { UserAvatar } from 'components/UserAvatar';
import { IconOpen } from 'components/icons/IconOpen';
import { Tooltip } from 'components/Tooltip';
import { useMobile } from 'hooks/useMobile';

export const ApplicationMessages = observer(({ application }) => {
  const { t } = useStore();

  return (
    <Box mt="24">
      <Tooltip id="attach-tip" />
      <TextLabel mb="16">{t('application.messages.header')}</TextLabel>

      <ApplicationMessageEditor application={application} />
      <Messages application={application} />
    </Box>
  );
});

const Messages = observer(({ application }) => {
  const messages = sortBy(application.messages, 'modified', 'desc');
  return messages.map(m => <Message key={m.id} message={m} application={application} />);
});

const Message = observer(({ message, application }) => {
  if (message.messageType === 'announcement') {
    return <SystemMessage message={message} />;
  } else if (message.messageType === 'message') {
    return <UserMessage message={message} application={application} />;
  } else if (message.messageType === 'rejection') {
    return <RejectionMessage message={message} application={application} />;
  }
});

const ApplicationMessageEditor = observer(({ application }) => {
  application.setUserRead('messages', true);
  const { t, openModal } = useStore();
  const { editorState, resetEditorState, isEmpty, setEditorState } = useEditorState();
  const [saving, setSaving] = useState(false);
  const isMobile = useMobile();

  const confirm = () => {
    openModal('CUSTOM_CONFIRM', {
      autoWidth: true,
      submit: null,
      data: {
        text: (
          <>
            {t('application.messages.confirm', application.name)}
            <div
              style={{ margin: 30 }}
              dangerouslySetInnerHTML={{ __html: editorStateToHtml(editorState) }}
            />
          </>
        )
      },
      confirm: saveMessage
    });
  };

  const saveMessage = async () => {
    setSaving(true);
    const res = await application.sendMessage(editorStateToHtml(editorState));
    setSaving(false);
    if (!res.error) {
      resetEditorState();
      return true;
    }
    return false;
  };

  return (
    <TextEditor
      disabled={saving}
      editorState={editorState}
      setEditorState={setEditorState}
      plugins={['toolbar', 'linkify']}
      placeholder={t('application.messages.editor.placeholder')}
    >
      {({ EditorBox, EditorInput, EditorToolbar }) => (
        <>
          {isMobile && (
            <Box mb="4">
              <EditorToolbar />
            </Box>
          )}
          <EditorBox component={MessageBox}>
            <EditorInput />
            <Box flex mt="24" alignItems="center">
              <Button disabled={isEmpty || saving} onClick={confirm}>
                {t('application.messages.send')}
              </Button>
              {!isMobile && (
                <Box ml="24">
                  <EditorToolbar />
                </Box>
              )}
            </Box>
          </EditorBox>
        </>
      )}
    </TextEditor>
  );
});

const SystemMessage = observer(({ message }) => {
  const { t } = useStore();
  return (
    <Box mt="32">
      <ReadOnlyTextEditor html={message.message} box={SystemMessageBox} />
      <Box flex alignItems="center" color="purple60" mt="4">
        <IconEmail />
        <Text type="regular14" color="purple60" ml="4">
          {t('application.messages.footer.application')} &bull; {message.dateDisplay}
        </Text>
      </Box>
    </Box>
  );
});

const RejectionMessage = observer(({ message }) => {
  const { t } = useStore();
  return (
    <Box mt="32">
      <ReadOnlyTextEditor html={message.message} box={RejectionMessageBox} />
      <Box flex alignItems="center" color="red100" mt="4">
        <IconEmail />
        <Text type="regular14" color="red100" ml="4">
          {t('application.messages.footer.rejection')} &bull; {message.dateDisplay}
        </Text>
      </Box>
    </Box>
  );
});

const UserMessage = observer(({ message, application }) => {
  const { openFullscreenModal, t } = useStore();
  if (!message.user) {
    return (
      <Box mt="32">
        <Box flex>
          <Box mr="16">
            <UserAvatar
              className="avatar"
              src={application.picture}
              name={application.name}
              size="lg"
            />
          </Box>
          <div>
            <ReadOnlyTextEditor html={message.message} box={ApplicantMessageBox} />
            {message.attachments.length > 0 && (
              <ButtonGroup left mt="8">
                {message.attachments.map(att => (
                  <Button
                    gray
                    data-for="attach-tip"
                    data-tip={t('application.files.hoveropen')}
                    key={att.id}
                    onClick={() => {
                      openFullscreenModal('FILE_PREVIEW', {
                        value: att.id,
                        attachments: message.attachments
                      });
                    }}
                    icon={IconOpen}
                  >
                    {att.fileName.replace(/_\w+.pdf/, '.pdf')}
                  </Button>
                ))}
              </ButtonGroup>
            )}
            <Text type="regular14" mt="4" color="purple60">
              {application.name} &bull; {message.dateDisplay}
            </Text>
          </div>
        </Box>
      </Box>
    );
  }

  return (
    <Box mt="32">
      <Box flex justifyContent="flex-end">
        <Box flex flexDirection="column" alignItems="flex-end">
          <ReadOnlyTextEditor html={message.message} box={UserMessageBox} />
          <Text type="regular14" mt="4" color="purple60">
            {message.user.name} &bull; {message.dateDisplay}
          </Text>
        </Box>
        <Box ml="16">
          <UserAvatar
            className="avatar"
            src={message.user.picture}
            name={message.user.name}
            size="lg"
          />
        </Box>
      </Box>
    </Box>
  );
});

const MessageBox = styled(TextEditorBoxStyle)(p => ({
  backgroundColor: p.theme.colors.white100,
  borderRadius: '0.5rem'
}));

const SystemMessageBox = styled(TextEditorBoxStyle)(p => ({
  backgroundColor: p.theme.colors.purpleTrans5,
  borderColor: p.theme.colors.purpleTrans20,
  color: p.theme.colors.purple100,
  borderRadius: '0.25rem'
}));

const RejectionMessageBox = styled(TextEditorBoxStyle)(p => ({
  backgroundColor: p.theme.colors.redTrans5,
  borderColor: p.theme.colors.red100,
  color: p.theme.colors.red100,
  borderRadius: '0.25rem'
}));

const ApplicantMessageBox = styled(TextEditorBoxStyle)(p => ({
  backgroundColor: '#fff',
  borderColor: p.theme.colors.grayMedium,
  color: p.theme.colors.purple100,
  width: 'auto',
  maxWidth: '32rem'
}));

const UserMessageBox = styled(TextEditorBoxStyle)(p => ({
  backgroundColor: p.theme.colors.greenTrans8,
  borderColor: p.theme.colors.green100,
  color: p.theme.colors.purple100,
  maxWidth: '32rem',
  width: 'auto'
}));
