import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

export const Checkbox = observer(({ label, checked, onClick, disabled, color, readOnly }) => {
  return (
    <Wrapper readOnly={readOnly} disabled={disabled} onClick={disabled ? null : onClick}>
      <HiddenCheckboxInput checked={checked || false} />
      <CheckboxSpacer>
        <CheckboxBorder checked={checked} color={color} disabled={disabled}>
          <Svg>
            <CheckContainer color={color} checked={checked} />
            <Checkmark checked={checked} />
          </Svg>
        </CheckboxBorder>
      </CheckboxSpacer>
      {label && <CheckboxLabel data-checkbox-label>{label}</CheckboxLabel>}
    </Wrapper>
  );
});

const CheckboxSpacer = styled.div(p => ({
  flexShrink: 0,
  height: '1.5rem',
  width: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Wrapper = styled.div(p => ({
  display: 'flex',
  alignItems: 'center',

  ...(p.disabled && { opacity: 0.65 }),
  ...(!p.disabled &&
    !p.readOnly && {
      cursor: 'pointer',
      ':hover, :focus': {
        'svg > path': {
          strokeDashoffset: 0
        },
        '[data-checkbox-label]': {
          color: p.theme.colors.purple100
        }
      }
    })
}));

const CheckboxBorder = styled.div(p => ({
  position: 'relative',
  width: '1.25rem',
  height: '1.25rem',
  border: `0.125rem solid ${p.theme.colors.purple60}`,
  borderRadius: '0.125rem',

  ...(p.checked && {
    borderColor: p.color ? p.theme.colors[p.color] : p.theme.colors.green100
  })
}));

const CheckboxLabel = styled.div(p => ({
  ...p.theme.text.regular14,
  color: p.theme.colors.purple80,
  marginLeft: p.theme.spacing.m8,
  transition: 'all 150ms ease-out'
}));

const Svg = styled.svg.attrs({ viewBox: '0 0 20 20' })({
  position: 'absolute',
  top: '-0.125rem',
  left: '-0.125rem',
  height: '1.25rem',
  width: '1.25rem'
});

const HiddenCheckboxInput = styled.input.attrs({
  type: 'checkbox',
  readOnly: true
})({
  display: 'none'
});

const CheckContainer = styled.path.attrs({
  // prettier-ignore
  d: 'M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z'
})(p => ({
  transition: 'all 0.6s ease',
  fill: p.checked ? (p.color ? p.theme.colors[p.color] : p.theme.colors.green100) : 'none',
  stroke: p.color ? p.theme.colors[p.color] : p.theme.colors.green100,
  strokeWidth: '0.125rem',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeDasharray: '4.4375rem',
  strokeDashoffset: p.checked ? '0' : '4.4375rem'
}));

const Checkmark = styled.polyline.attrs({
  points: '4 11 8 15 16 6'
})(p => ({
  fill: 'none',
  stroke: p.theme.white100,
  strokeWidth: '0.125rem',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeDasharray: '1.125rem',
  transition: 'all 0.3s ease',
  strokeDashoffset: p.checked ? '0' : '1.125rem'
}));
