import { types as t } from 'mobx-state-tree';

const MultiDropdownOption = t.model('MultiDropdownOption', {
  value: t.string,
  display: t.string
});

export const ApplicationQuestionMinimal = t.model('ApplicationQuestionMinimal', {
  id: t.identifier,
  value: t.frozen()
});

export const ApplicationFormQuestion = t
  .model('ApplicationFormQuestion', {
    id: t.identifier,
    options: t.maybe(t.array(MultiDropdownOption)),
    order: t.maybe(t.number),
    required: t.maybe(t.boolean),
    title: t.maybe(t.string),
    type: t.maybe(t.string),
    value: t.maybeNull(t.maybe(t.union(t.string, t.number, t.array(t.string))))
  })
  .actions(self => ({
    toggleOption(option) {
      if (!self.value) {
        self.value = self.type === 'multidropdown' ? [option] : option;
        return;
      }

      if (self.type === 'multidropdown') {
        const idx = self.value.indexOf(option);
        if (idx === -1) {
          self.value.push(option);
        } else {
          self.value.splice(idx, 1);
        }
        return;
      }

      if (self.value === option) {
        self.value = '';
      } else {
        self.value = option;
      }
    },
    setValue(value) {
      if (self.type === 'multidropdown') {
        if (Array.isArray(value)) {
          self.value = value;
        } else {
          self.value = Object.keys(value)
            .filter(k => value[k])
            .map(k => k);
        }
      } else {
        self.value = value;
      }
    }
  }));

export const ApplicationFormSection = t.model('ApplicationFormSection', {
  id: t.maybe(t.identifier),
  order: t.maybe(t.number),
  title: t.maybe(t.string),
  questions: t.array(ApplicationFormQuestion)
});

export const ApplicationForm = t.model('ApplicationForm', {
  sections: t.array(ApplicationFormSection)
});
