import React, { Suspense, useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';

import { Box } from 'components/Box';
import { LargeApplicationCard } from 'components/LargeApplicationCard';
import { IconEmail32 } from 'components/icons/IconEmail32';
import { IconPhone32 } from 'components/icons/IconPhone32';
import { IconSelectArrow } from 'components/icons/IconSelectArrow';
import { Text, TextLabel, TextLink } from 'components/Text';
import { Separator } from 'components/Separator';
import { Tooltip } from 'components/Tooltip';
import { NamePlate } from 'components/NamePlate';
import { AnswerCard, QuestionAnswer } from 'components/QuestionAnswer';
import { IconPerson32 } from 'components/icons/IconPerson32';

const PDF = React.lazy(() => import('./PDF' /* webpackChunkName: 'cv' */));

export const ApplicationDetails = observer(({ application }) => {
  return (
    <Box mt="24" id="content-container">
      <ContactDetails application={application} />
      <Separator mt="32" mb="32" />
      <ApplicationsWithSameEmail application={application} />
      <ApplicationCV application={application} />
      <Separator mt="32" mb="32" />
      <ApplicationCoverLetter application={application} />
      <Separator mt="32" mb="32" />
      <ApplicationAnswers application={application} />
      {application.referredBy && (
        <>
          <Separator mt="32" mb="32" />
          <ApplicationSourcedBy application={application} />
        </>
      )}
    </Box>
  );
});

const ApplicationsWithSameEmail = observer(({ application }) => {
  const { api, t } = useStore();
  const [otherApplications, setOtherApplications] = useState([]);
  const [count, setCount] = useState(0);
  const [showAll, setShowAll] = useState(false);

  React.useEffect(() => {
    (async () => {
      const res = await api.applications.findApplicationsByEmail(application.email);
      if (!res.error) {
        res.data && setOtherApplications(res.data.results.filter(a => a.id !== application.id));
        res.data && setCount(res.data.count);
      }
    })();
  }, [api.applications, application]);
  if (count <= 1) {
    return;
  }

  return (
    <>
      <Box
        mb={16}
        flex
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
      >
        <TextLabel>{t('application.details.header.applied_to', count - 1)}</TextLabel>
        {count - 1 > 1 && <ShowAllOrCollapse setShowAll={setShowAll} showAll={showAll} />}
      </Box>
      {showAll && otherApplications.map(a => <LargeApplicationCard key={a.id} a={a} />)}
      {!showAll && <LargeApplicationCard a={otherApplications[0]} />}
      <Separator mt="32" mb="32" />
    </>
  );
});

const ApplicationAnswers = observer(({ application }) => {
  const { t } = useStore();
  return (
    <>
      <Tooltip id="qtip" />
      <TextLabel>{t('application.header.answers')}</TextLabel>
      {application.formQuestions.map(q => (
        <QuestionAnswer q={q} application={application} key={q.id || q.title} />
      ))}
    </>
  );
});

const ApplicationSourcedBy = ({ application }) => {
  const { t } = useStore();
  const { name, picture } = application.referredBy;

  return (
    <>
      <TextLabel mb="8">{t('application.header.sourced')}</TextLabel>
      <AnswerCard>
        <Box flex alignItems="center">
          <NamePlate pic={picture} name={name} size="md" />
        </Box>
      </AnswerCard>
    </>
  );
};

const ContactDetails = observer(({ application }) => {
  const { t } = useStore();
  return (
    <>
      {application.email && (
        <ContactEntry flex alignItems="center">
          <IconEmail32 />
          <TextLabel ml="16" style={{ minWidth: '10rem' }}>
            {t('application.contact.email')}
          </TextLabel>
          <Text ml="40" type="regular16" color="purple60">
            <a href={'mailto:' + application.email}>{application.email}</a>
          </Text>
        </ContactEntry>
      )}
      {application.phone && (
        <ContactEntry flex alignItems="center" mt="8">
          <IconPhone32 />
          <TextLabel ml="16" style={{ minWidth: '10rem' }}>
            {t('application.contact.phone')}
          </TextLabel>
          <Text ml="40" type="regular16" color="purple60">
            {application.phone}
          </Text>
        </ContactEntry>
      )}
      {application.ssn && (
        <ContactEntry flex alignItems="center" mt="8">
          <IconPerson32 />
          <TextLabel ml="16" style={{ minWidth: '10rem' }}>
            {t('application.contact.ssn')}
          </TextLabel>
          <Text ml="40" type="regular16" color="purple60">
            {application.ssn}
          </Text>
        </ContactEntry>
      )}
    </>
  );
});

const ContactEntry = styled(Box)(p => ({
  display: 'flex',
  alignItems: 'center',
  [p.theme.mobileQuery]: {
    '> span:not(:last-child)': {
      display: 'none'
    },
    '> span:last-child': {
      marginLeft: p.theme.spacing.m16
    }
  }
}));

const ApplicationCV = observer(({ application }) => {
  const { t } = useStore();

  React.useEffect(() => {
    if (application.cv) {
      application.loadCv();
    }
  }, [application]);
  return (
    <>
      <TextLabel>{t('application.details.header.cv')}</TextLabel>

      {application.cv && (
        <TextLink href={application.cv} target="_blank" rel="noopener noreferrer">
          {application.cvFileName}
        </TextLink>
      )}

      {application.noCvPreview && (
        <Box mt="24" mb="24">
          <Text block={false}>{t('application.details.nocvpreview')}</Text>
          <TextLink ml="8" href={application.cv} target="_blank" rel="noopener noreferrer">
            {t('application.clicktodownload')}
          </TextLink>
        </Box>
      )}

      {!application.cv && <Text mt="24">{t('application.details.nocvuploaded')}</Text>}

      {application.cvBlob !== null && (
        <Suspense fallback={<div />}>
          <PDF file={application.cvBlob} name={application.id} />
        </Suspense>
      )}
    </>
  );
});

const ApplicationCoverLetter = observer(({ application }) => {
  const { t } = useStore();

  React.useEffect(() => {
    if (application.coverLetter) {
      application.loadCoverLetter();
    }
  }, [application]);

  return (
    <>
      <TextLabel>{t('application.details.header.cl')}</TextLabel>

      {application.coverLetter && (
        <TextLink href={application.coverLetter} target="_blank" rel="noopener noreferrer">
          {application.coverLetterFileName}
        </TextLink>
      )}

      {application.noCoverLetterPreview && (
        <Box mt="24" mb="24">
          <Text block={false}>{t('application.details.noclpreview')}</Text>
          <TextLink ml="8" href={application.coverLetter} target="_blank" rel="noopener noreferrer">
            {t('application.clicktodownload')}
          </TextLink>
        </Box>
      )}

      {!application.coverLetter && <Text mt="24">{t('application.details.nocluploaded')}</Text>}

      {application.coverLetterBlob !== null && (
        <Suspense fallback={<div />}>
          <PDF file={application.coverLetterBlob} name={application.id} />
        </Suspense>
      )}
    </>
  );
});

const ShowAllOrCollapse = ({ setShowAll, showAll }) => {
  const { t } = useStore();
  if (showAll) {
    return (
      <Box onClick={() => setShowAll(false)} color={'purple50'} flex alignItems={'center'}>
        <Text color="purple50" caps type="bold12">
          {t('application.details.header.collapse')}
        </Text>
        <IconSelectArrow style={{ transform: 'rotate(180deg)' }} />
      </Box>
    );
  } else {
    return (
      <Box onClick={() => setShowAll(true)} color={'purple50'} flex alignItems={'center'}>
        <Text color="purple50" caps type="bold12">
          {t('application.details.header.show_all')}
        </Text>
        <IconSelectArrow />
      </Box>
    );
  }
};
