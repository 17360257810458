import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import { useStore } from 'models/Provider';
import { Container } from './styles';
import TasklistForm from './TasklistForm';
import TemplateList from './TemplateList';

function Tasklists({ match }) {
  const { templateStore, router } = useStore();
  const { tasklist } = templateStore;
  const initialTasklistID = useRef(match.params.tasklistID).current;

  useEffect(() => {
    if (Number(initialTasklistID) === -1) {
      router.replace('/templates/tasks');
    }
    return () => templateStore.tasklist.clearSelected();
  }, [initialTasklistID, templateStore, router]);

  useEffect(() => {
    templateStore.loadTasklist().then(() => {
      if (match.params.tasklistID && Number(match.params.tasklistID) !== -1) {
        templateStore.tasklist.select(match.params.tasklistID);
      }
    });
  }, [match.params.tasklistID, templateStore, router]);

  useEffect(() => {
    const { tasklist } = templateStore;
    const { tasklistID } = match.params;
    if (tasklist.selected && !tasklistID) {
      tasklist.clearSelected();
    }
  });
  return (
    <Container>
      <TemplateList tasklist={tasklist} />
      {tasklist.selected && (
        <TasklistForm key={tasklist.selected.id} selected={tasklist.selected} />
      )}
    </Container>
  );
}

export default withRouter(observer(Tasklists));
