import { types as t, getParent } from 'mobx-state-tree';

export const TaskTemplate = t
  .model('TaskTemplate', {
    id: t.maybe(t.number),
    description: t.maybeNull(t.string),
    order: t.number,
    text: t.string,
    new: false
  })
  .views(self => ({
    get parent() {
      return getParent(self, 2);
    }
  }))
  .actions(self => ({
    setText(newText) {
      self.text = newText;
    },
    remove() {
      self.parent.removeTaskTemplate(self);
    }
  }));
