import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Stage } from '../Stages/Stage';
import { Select } from 'components/Select';
import { Box } from 'components/Box';

export const ApplicantList = observer(({ selectedApplication, job }) => {
  const [selected, setSelected] = useState(selectedApplication.stage);

  const stage = job.stages.items.get(selected);
  useEffect(() => {
    if (stage && stage.state !== 'loaded') {
      stage.load();
    }
  }, [stage]);

  if (!stage) return null;

  return (
    <>
      <Box mb="8">
        <Select
          maxWidth="17.5rem"
          defaultValue={selected}
          items={job.applicantListStages}
          onChange={stage => setSelected(Number(stage))}
        />
      </Box>
      <Stage hideHeader stage={stage} selectedId={selectedApplication.id} />
    </>
  );
});
