import { useState } from 'react';
import { useStore } from 'models/Provider';
import { createPlaceholderPlugin } from './placeholderPlugin';

export const usePlaceholderPlugin = () => {
  const { t } = useStore();
  const [state] = useState(() => {
    const plugin = createPlaceholderPlugin(t);
    return [plugin, plugin.PlaceholderMenu];
  });

  return state;
};
