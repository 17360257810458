import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Text } from 'components/Text';

const Container = styled.div(p => ({
  width: p.small ? '1.875rem' : '2.5rem',
  height: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  ':hover': {
    cursor: 'pointer'
  }
}));

const SliderBack = styled.div(p => ({
  position: 'relative',
  transition: 'background-color 200ms ease-out',
  backgroundColor: p.active ? p.theme.colors.green100 : p.theme.colors.purpleTrans20,
  height: p.small ? '1rem' : '1.375rem',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0.6875rem',
  width: '100%'
}));

const Ball = styled.div(p => ({
  position: 'absolute',
  transition: 'left 200ms ease-out',
  left: p.active ? (p.small ? '1rem' : '1.25rem') : '0.125rem',
  width: p.small ? '0.75rem' : '1.125rem',
  height: p.small ? '0.75rem' : '1.125rem',
  borderRadius: '50%',
  backgroundColor: p.theme.colors.white100
}));

export const Switch = observer(({ small = false, value, id, onToggle }) => {
  return (
    <Container small={small}>
      <SliderBack small={small} active={value} onClick={() => onToggle(!value)}>
        <Ball small={small} active={value} />
      </SliderBack>
    </Container>
  );
});

export const SwitchLabel = props => (
  <Text ml="8" type="regular14" color="purple80" style={{ lineHeight: '1.5rem' }} {...props} />
);
