import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { useStore } from 'models/Provider';
import { CardList, CardListHeader, CardListItems } from 'components/CardList';
import { Menu, MenuItem, MenuItems, TransparentMenuButton } from 'components/Menu';
import { IconButton } from 'components/IconButton';
import { IconOptions } from 'components/icons/IconOptions';
import { IconPlus } from 'components/icons/IconPlus';
import { Text } from 'components/Text';
import { Box } from 'components/Box';

function TemplateList({ tasklist }) {
  const { t, router, openModal } = useStore();
  const newOpen = tasklist.selected && tasklist.selected.id === -1;

  function onItemSelect(id) {
    tasklist.select(id);
    router.push(`/templates/tasks/${id}`);
  }

  function removeTasklistModal(item) {
    openModal('COMMON_REMOVE_CONFIRM', {
      width: '400px',
      autoWidth: true,
      submit: null,
      data: {
        id: item.id,
        type: 'Task list'
      },
      confirm: item.delete
    });
  }

  return (
    <CardList style={{ height: '100%' }}>
      <CardListHeader>
        <Text type="bold14" color="purple80">
          {t('company.templates.tasklist.header')}
        </Text>
        {!newOpen && (
          <IconButton trans onClick={tasklist.addNew}>
            <IconPlus />
          </IconButton>
        )}
      </CardListHeader>
      <CardListItems>
        {tasklist.items.map(item => (
          <Card
            key={item.id}
            onClick={() => onItemSelect(item.id)}
            active={item === tasklist.selected}
          >
            <Text type="regular16" color="purple100">
              {item.name}
            </Text>
            {!newOpen && (
              <Menu label="Tasklist actions">
                <TransparentMenuButton>
                  <IconOptions />
                </TransparentMenuButton>
                <MenuItems>
                  <MenuItem onClick={() => onItemSelect(item.id)}>
                    {t('company.templates.options.edit')}
                  </MenuItem>
                  <MenuItem variant="danger" onClick={() => removeTasklistModal(item)}>
                    {t('company.templates.options.delete')}
                  </MenuItem>
                </MenuItems>
              </Menu>
            )}
          </Card>
        ))}
      </CardListItems>
    </CardList>
  );
}

export default observer(TemplateList);

const Card = styled(Box)(p => ({
  transition: 'transform 150ms ease-out, box-shadow 150ms ease-out',
  backgroundColor: p.theme.colors.white,
  borderRadius: '0.25rem',
  padding: '0.25rem 0.25rem 0.25rem 0.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderStyle: 'solid',
  borderWidth: '0.125rem',
  borderColor: 'transparent',
  minHeight: '3.25rem',
  ':hover': {
    transform: 'translateY(-1px)',
    boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)',
    cursor: 'pointer'
  },
  ...(p.active && {
    borderColor: p.theme.colors.green100
  })
}));
