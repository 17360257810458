import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconCompany = props => (
  <Svg {...props}>
    <path
      d="M4.5 9L12 3l7.5 6-7.5 6-7.5-6z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 14l7.5 6 7.5-6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
