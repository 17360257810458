import React from 'react';
import { observer } from 'mobx-react-lite';

import styled from 'styled-components/macro';

import Flex from 'shared/layout/Flex';
import Spacer from 'shared/layout/Spacer';

import { useStore } from 'models/Provider';
import { TextEditor, usePlaceholderMap } from 'components/TextEditor';
import TemplateSelector from './TemplateSelector';

function Form({ isOpen, templates, selected, setEditorState, disabled, onSelect }) {
  const { t } = useStore();
  const placeholderMap = usePlaceholderMap('message');

  return (
    <FormWrapper isOpen={isOpen}>
      {templates.length > 0 && (
        <Spacer top={20} bottom={20}>
          <TemplateSelector templates={templates} onChange={onSelect} />
        </Spacer>
      )}

      <Spacer top={20} bottom={20}>
        <FormLabel>{t('rejectform.label.message')}</FormLabel>
        <TextEditor
          readOnly={disabled}
          editorState={selected.message}
          setEditorState={setEditorState}
          plugins={['toolbar', 'linkify', 'placeholder']}
          placeholderMap={placeholderMap}
        >
          {({ EditorBox, EditorInput, EditorToolbar, EditorPlaceholderMenu }) => (
            <>
              <Flex justifyContent="space-between" alignItems="center">
                <EditorToolbar />
                <EditorPlaceholderMenu />
              </Flex>
              <EditorBox>
                <EditorInput />
              </EditorBox>
            </>
          )}
        </TextEditor>
      </Spacer>
    </FormWrapper>
  );
}

export default observer(Form);

const FormWrapper = styled.div`
  max-height: ${p => (p.isOpen ? '1000px' : '0px')};
  transition: max-height 1s ease-in-out;
  overflow: hidden;
`;

const FormLabel = styled.div`
  color: ${props => props.theme.formLabelColor};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;
