import { applySnapshot, getSnapshot, types as t } from 'mobx-state-tree';
import uuid4 from 'uuid/v4';

import { Language } from 'models/ui';
import { JobFormQuestion } from 'models/jobs';

export const JobFormQuestionEdit = t
  .model('JobFormQuestionEdit', {
    questionId: t.maybe(t.string),
    selectedLanguage: t.maybe(Language),
    questions: t.array(JobFormQuestion),
    langs: t.array(Language),
    optionsError: false
  })
  .views(self => ({
    get current() {
      const langIndex = self.langs.indexOf(self.selectedLanguage);
      return self.questions[langIndex];
    },
    get isNew() {
      return !self.questionId;
    },
    get otherLanguageIndex() {
      const langIndex = self.langs.indexOf(self.selectedLanguage);
      if (langIndex === 0) {
        if (self.langs.length <= 1) {
          return -1;
        }
        return 1;
      } else {
        return 0;
      }
    },
    get titlePlaceholder() {
      const langIndex = self.otherLanguageIndex;
      if (langIndex === -1) {
        return '';
      }
      return self.questions[langIndex].title;
    },
    get optionPlaceholders() {
      const langIndex = self.otherLanguageIndex;
      if (langIndex === -1) {
        return [];
      }
      const question = self.questions[langIndex];
      if (question && question.options && question.options.length > 0) {
        return question.options.toJSON().map(opt => opt.display);
      }
      return [];
    },
    get langErrors() {
      let errors = self.langs.map(() => false);
      self.questions.forEach((question, index) => {
        if (question.error) {
          errors[index] = true;
          return;
        }
        if (question.options) {
          errors[index] = question.options.some(opt => opt.error);
        }
      });
      return errors;
    }
  }))
  .preProcessSnapshot(snapshot => {
    const { questionId, job } = snapshot;
    let questions = [];
    if (questionId) {
      job.languages.forEach(obj => {
        obj.form.sections.forEach(section => {
          section.questions.forEach(question => {
            if (question.id === questionId) {
              questions.push({
                ...question,
                options: question.options ? [...getSnapshot(question.options)] : []
              });
            }
          });
        });
      });
    } else {
      const id = uuid4();
      job.langIds.forEach(() => questions.push({ id }));
    }
    const selectedLanguage = job.currentLanguage;
    return {
      questionId: snapshot.questionId,
      selectedLanguage,
      questions,
      langs: job.langIds
    };
  })
  .actions(self => ({
    submitForJob(job) {
      const valid = self.isValid();
      if (!valid) return false;

      const questions = getSnapshot(self.questions);
      if (self.isNew) {
        self.langs.forEach((lang, index) => {
          const jl = job.languages.find(obj => obj.language === lang);
          jl.form.sections[jl.form.sections.length - 1].add(questions[index]);
        });
      } else {
        self.langs.forEach((lang, langIndex) => {
          const jl = job.languages.find(obj => obj.language === lang);
          jl.form.sections.forEach(section => {
            section.questions.forEach(question => {
              if (question.id === self.questionId) {
                applySnapshot(question, questions[langIndex]);
              }
            });
          });
        });
      }
      return true;
    },
    titleValid() {
      let valid = true;
      self.questions.forEach(question => {
        if (!question.title) {
          question.setError(true);
          valid = false;
        }
      });
      return valid;
    },
    optionsValid() {
      const questionType = self.questions[0].type;
      switch (questionType) {
        case 'dropdown':
        case 'multidropdown': {
          let valid = true;
          self.questions.forEach((question, index) => {
            question.options.forEach((option, optionIndex) => {
              if (!option.display) {
                valid = false;
                question.setOptionError(optionIndex, true);
              } else {
                question.setOptionError(optionIndex, false);
              }
            });
          });

          return valid;
        }
        default:
          return true;
      }
    },
    isValid() {
      let validTitle = self.titleValid();
      let validOptions = self.optionsValid();
      return validTitle && validOptions;
    },
    setType(newType) {
      const currentType = self.questions[0].type;
      self.questions.forEach(q => q.setType(newType));
      const isDD = currentType === 'dropdown' || currentType === 'multidropdown';
      const newDD = newType === 'dropdown' || newType === 'multidropdown';
      if (newDD && !isDD) {
        self.addOption(0);
        self.addOption(1);
        self.addOption(2);
      }
    },
    removeOption(index) {
      self.questions.forEach(q => q.removeOption(index));
    },
    addOption(index) {
      const id = uuid4();
      self.questions.forEach(q => q.addOption(index, id));
    },
    selectLanguage(lang) {
      self.selectedLanguage = lang;
    }
  }));
