import React from 'react';
import { Svg } from 'components/icons/Svg';

export const Icon50skills = props => (
  <Svg viewBox="0 0 32 32" height="2rem" width="2rem" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.833 3.048H4.167c-.618 0-1.12.5-1.12 1.119v23.666c0 .618.502 1.12 1.12 1.12h23.666a1.12 1.12 0 001.12-1.12V4.167c0-.618-.502-1.12-1.12-1.12zM4.167 0A4.167 4.167 0 000 4.167v23.666A4.167 4.167 0 004.167 32h23.666A4.167 4.167 0 0032 27.833V4.167A4.167 4.167 0 0027.833 0H4.167z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.138 29.117a9.475 9.475 0 01-1.582.128c-2.828 0-4.928-.686-6.318-2.197l1.905-2.667c1.097 1.146 2.865 2.286 4.571 2.286 1.926 0 2.84-1.591 2.84-2.908 0-1.438-.987-2.426-2.84-2.426-1.34 0-2.072.598-3.047 1.524l-3.048-.762v-9.143h11.429v2.59c1.094-1.573 2.827-2.59 5.272-2.59 4.632 0 6.73 3.688 6.73 7.882 0 2.378-.676 4.765-2.105 6.385.34.345.552.824.552 1.352 0 1.052-.836 1.905-1.866 1.905-.858 0-1.58-.59-1.799-1.395-.47.107-.974.164-1.512.164a6.771 6.771 0 01-1.66-.198c-.52 1.19-2.015 2.048-3.777 2.048-1.727 0-3.197-.825-3.745-1.978zm2.556-9.865a5.915 5.915 0 00-3.218-.966c-1.487 0-2.267.743-3.047 1.523V16h7.327c-.552.948-.901 2.06-1.062 3.252zm6.626 6.921c2.316 0 3.194-2.34 3.194-5.34 0-2.998-.878-5.314-3.194-5.314-2.34 0-3.218 2.316-3.218 5.315 0 2.998.877 5.339 3.218 5.339z"
      fill="currentColor"
    />
  </Svg>
);
