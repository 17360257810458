export const groupBy = (list, keys) => {
  const m = list.reduce((r, o) => {
    let values = [];
    for (let i = 0; i < keys.length; i++) {
      values.push(o[keys[i]]);
    }
    const key = values.join('-');

    const item =
      r[key] ||
      Object.assign({}, o, {
        instances: 0,
        ids: [],
        uniqueApplicationIds: [],
        applicationUrl: o.applicationUrl,
        ratingsUrl: o.ratingsUrl,
        notesUrl: o.notesUrl,
        integrationUrl: o.integrationUrl,
        jobUrl: o.jobUrl,
        messagesUrl: o.messagesUrl
      });

    if (!o.applicationId || !item.uniqueApplicationIds.includes(o.applicationId)) {
      item.instances += 1;
      item.uniqueApplicationIds.push(o.applicationId);
    }
    item.ids.push(o.id);

    r[key] = item;
    return r;
  }, {});

  return Object.keys(m).map(key => m[key]);
};
