import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background-color: #e0e0e0;
  height: 100%;
  min-height: ${props => props.minHeight};
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media (max-width: ${p => p.theme.bp.sm}) {
    margin-bottom: 140px;
  }
`;

export const CopyContainer = styled.div`
  margin-top: 5px;
  display: flex;
`;

export const UrlText = styled.div`
  height: 36px;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 16px;
  color: #6b6b6b;
  padding: 6px 12px 6px 12px;
  line-height: 22px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PublishedModalWrapper = styled.div`
  padding: 10px 0 20px 0;
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
`;

export const PublishedTagline = styled.div`
  color: #757575;
  font-size: 18px;
  line-height: 24px;
`;

export const LinkHeader = styled.span`
  font-weight: bold;
  line-height: normal;
  font-size: 13px;
  color: #7d7f97;
`;

export const LinkSubheader = styled.span`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #9ca6b0;
  opacity: 0.65;
  margin-left: 4px;
`;
