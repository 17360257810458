import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconDashboard = props => (
  <Svg {...props}>
    <path
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
      d="M4 4h9v4H4zM4 11h4v9H4zM16 4h4v9h-4zM11 16h9v4h-9z"
    />
  </Svg>
);
