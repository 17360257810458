import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconUnverified = props => (
  <Svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      fill="#9CA6B0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 17h-2v-2h2v2zM13 13h-2V7h2v6z"
      fill="#F6F6F6"
    />
  </Svg>
);
