import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { isEditorStateEmpty } from './helpers';
import { convertPlaceholders } from './placeholderPlugin';
import { useToolbarPlugin } from './useToolbarPlugin';
import { useLinkifyPlugin } from './useLinkifyPlugin';
import { useMentionPlugin } from './useMentionPlugin';
import { usePlaceholderPlugin } from './usePlaceholderPlugin';
import { TextEditorInput } from './TextEditorInput';
import { TextEditorBox } from './TextEditorBox';
import { useEditorState } from './useEditorState';

const TextEditorContext = React.createContext();

const usePlugins = plugins => {
  const [toolbarPlugin, EditorToolbar] = useToolbarPlugin();
  const [mentionPlugin, EditorMention] = useMentionPlugin();
  const [linkifyPlugin] = useLinkifyPlugin();
  const [placeholderPlugin, EditorPlaceholderMenu] = usePlaceholderPlugin();

  let c = {};
  let p = [];
  plugins.forEach(plugin => {
    if (plugin === 'toolbar') {
      p.push(toolbarPlugin);
      c.EditorToolbar = EditorToolbar;
    } else if (plugin === 'mention') {
      p.push(mentionPlugin);
      c.EditorMention = EditorMention;
    } else if (plugin === 'linkify') {
      p.push(linkifyPlugin);
    } else if (plugin === 'placeholder') {
      p.push(placeholderPlugin);
      c.EditorPlaceholderMenu = EditorPlaceholderMenu;
    }
  });
  return [p, c];
};

export const TextEditor = props => {
  const {
    children,
    editorState = null,
    setEditorState = () => null,
    plugins = [],
    readOnly = false,
    focusOnMount = false,
    stripPastedStyles = false,
    allowShortcuts = true,
    placeholder = '',
    disabled = false,
    error = false,
    placeholderMap
  } = props;

  const editorRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [editorPlugins, editorComponents] = usePlugins(plugins);

  const onFocus = useCallback(() => {
    if (!isFocused) {
      try {
        editorRef.current.focus();
      } catch (e) {}
      setIsFocused(true);
    }
  }, [isFocused]);

  const onBlur = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    }
  }, [isFocused]);

  const isEmpty = useMemo(() => isEditorStateEmpty(editorState), [editorState]);

  useEffect(() => {
    if (focusOnMount) {
      const to = setTimeout(() => {
        editorRef.current.focus();
        setIsFocused(true);
      }, 0);
      return () => clearTimeout(to);
    }
  }, [focusOnMount]);

  const hasPlaceholderPlugin = useMemo(() => plugins.includes('placeholder'), [plugins]);

  const onEditorStateChange = useCallback(() => {
    if (hasPlaceholderPlugin) {
      return editorState => setEditorState(convertPlaceholders(editorState, placeholderMap));
    }
    return editorState => setEditorState(editorState);
  }, [hasPlaceholderPlugin, placeholderMap, setEditorState]);

  const context = {
    plugins: editorPlugins,
    editorState,
    setEditorState: onEditorStateChange(),
    readOnly,
    stripPastedStyles,
    allowShortcuts,
    editorRef,
    isFocused,
    onBlur,
    onFocus,
    isEmpty,
    placeholder,
    disabled,
    error,
    placeholderMap
  };

  const renderProps = {
    EditorBox: TextEditorBox,
    EditorInput: TextEditorInput,
    ...editorComponents
  };

  return (
    <TextEditorContext.Provider value={context}>
      {typeof children === 'function' ? children(renderProps) : children}
    </TextEditorContext.Provider>
  );
};

export const useTextEditorState = () => React.useContext(TextEditorContext);

export const ReadOnlyTextEditor = ({ html, box: Box }) => {
  const { editorState, setEditorState } = useEditorState(html);
  return (
    <TextEditor
      editorState={editorState}
      setEditorState={setEditorState}
      plugins={['linkify', 'mention', 'placeholder']}
      readOnly
    >
      {({ EditorInput, EditorBox }) => (
        <EditorBox {...(Box && { component: Box })}>
          <EditorInput />
        </EditorBox>
      )}
    </TextEditor>
  );
};
