import React from 'react';
import { Button } from 'reakit/Button';

import styled from 'styled-components/macro';

export const IconButton = ({ children, icon: Icon, trans = false, ...rest }) => {
  return (
    <IconButtonContainer trans={trans} {...rest}>
      {Icon ? <Icon /> : children}
    </IconButtonContainer>
  );
};

const IconButtonContainer = styled(
  ({ trans, small = false, round = false, color, onClick, disabled, link, ...rest }) => (
    <Button onClick={disabled ? null : onClick} {...rest} disabled={disabled} />
  )
)(p => ({
  flexShrink: 0,
  zIndex: 0,
  borderRadius: p.small ? '0.125rem' : p.round ? '50%' : '0.25rem',
  height: p.small ? '1.5rem' : '2.5rem',
  width: p.small ? '1.5rem' : '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 200ms ease-out',
  color: p.color ? p.theme.colors[p.color] : p.theme.colors.purple50,
  backgroundColor: p.trans ? 'transparent' : p.theme.colors.purpleTrans5,
  ...(!p.disabled && {
    ':hover, :focus': {
      cursor: 'pointer',
      backgroundColor: p.theme.colors.purpleTrans10,
      color: p.color ? p.theme.colors[p.color] : p.theme.colors.purple80
    }
  }),
  ...(p.disabled && { opacity: 0.5 })
}));
