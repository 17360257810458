import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import MultiSelectDropDown from 'shared/modules/MultiSelectDropDown';

import { sortBy } from 'helpers';
import { useStore } from 'models/Provider';
import { useOnDDClick } from 'hooks/useOnDDClick';
import { ButtonGroup } from 'components/Button';
import { Button } from 'components/Button';
import ShowMore from 'components/ShowMore';
import { ModalHeader, ModalText } from 'components/Modal';
import { Loading } from 'components/Loading';
import { Box } from 'components/Box';
import { NamePlate } from 'components/NamePlate';
import { RecruiterItem } from '../AddRecruiterModal/styles';
import { GrantList } from './styles';

function GrantMultipleModal({ job, applicants, closeModal }) {
  const [selected, setSelected] = useState([]);
  const [saving, setSaving] = useState(false);
  const { userStore, t } = useStore();
  const onDDClick = useOnDDClick('grant-access-modal');

  async function addUsersToApplications() {
    setSaving(true);
    const success = await job.bulkGrantAccess(applicants, selected);
    setSaving(false);
    if (success) {
      closeModal();
    }
  }

  function removeUserFromSelection(user) {
    setSelected(old => [...old.filter(u => u.id !== user.id)]);
  }

  function selectUser(user) {
    setSelected(old => [...old, user]);
  }

  function usersWithoutSelected() {
    const users = userStore.list.filter(user => {
      return !(
        user.role === 'user' ||
        user.role === 'admin' ||
        selected.includes(user) ||
        job.followers.includes(user)
      );
    });
    return sortBy(users, 'name');
  }

  const users = usersWithoutSelected();

  return (
    <>
      <ModalHeader>{t('modals.grantmulti.heading')}</ModalHeader>

      {saving && <Loading />}
      <ModalText mb="24">{t('modals.grantmulti.applicantsmsg')}</ModalText>
      <ShowMore
        max={applicants.length}
        count={applicants.length > 10 ? 5 : applicants.length}
        inc={1000}
        render={({ count }) => (
          <GrantList>
            {applicants.slice(0, count).map((a, i) => (
              <div key={i}>{a.name}</div>
            ))}
          </GrantList>
        )}
      />
      <ModalText mb="24">{t('modals.grantmulti.usersmsg')}</ModalText>
      <div id="grant-access-modal" onClick={onDDClick}>
        <MultiSelectDropDown
          fullSize
          text={
            users.length > 0
              ? t('modals.grantmulti.users.select')
              : t('modals.grantmulti.users.empty')
          }
        >
          {users.map(u => (
            <RecruiterItem key={u.id} onClick={() => selectUser(u)}>
              <NamePlate name={u.name} pic={u.picture} title={u.roleDisplay} />
            </RecruiterItem>
          ))}
        </MultiSelectDropDown>
      </div>

      <Box mt="12" mb="24">
        {selected.map(s => (
          <RecruiterItem selected key={s.id}>
            <NamePlate
              name={s.name}
              title={s.roleDisplay}
              pic={s.picture}
              onRemove={() => removeUserFromSelection(s)}
            />
          </RecruiterItem>
        ))}
      </Box>

      <ButtonGroup right mt="24">
        <Button gray onClick={closeModal} disabled={saving}>
          {t('modals.grantmulti.button.cancel')}
        </Button>
        <Button
          onClick={saving || selected.length === 0 ? null : addUsersToApplications}
          disabled={saving || selected.length === 0}
        >
          {t('modals.grantmulti.button.submit')}
        </Button>
      </ButtonGroup>
    </>
  );
}

export default observer(GrantMultipleModal);
