export const prepData = form => {
  const data = new FormData();

  data.append('name', form.name || '');
  if (form.phone) {
    data.append('phone', form.phone || '');
  }
  data.append('email', form.email || '');

  if (form.ssn) {
    data.append('ssn', form.ssn);
  }

  if (form.cv !== null) {
    data.append('cv', form.cv || '');
  }

  if (form.language) {
    data.append('language', form.language);
  }

  if (form.coverLetter !== null) {
    data.append('coverLetter', form.coverLetter || '');
  }

  Object.keys(form.files).forEach(key => {
    if (form.files[key]) {
      data.append(key, form.files[key]);
    }
  });

  data.append('form', JSON.stringify({ sections: form.sections }));
  return data;
};
