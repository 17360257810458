import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Button } from 'components/Button';

import { useStore } from 'models/Provider';
import { CopyContainer, UrlText, LinkHeader, LinkSubheader } from './styles';

function LinkItem({ head, sub, url }) {
  const [copied, setCopied] = useState(false);
  const copyRef = useRef();
  const { t } = useStore();

  function onCopyClick() {
    setCopied(true);
    copyRef.current = setTimeout(() => {
      setCopied(false);
    }, 4000);
  }

  useEffect(() => {
    return () => {
      clearTimeout(copyRef.current);
    };
  }, []);

  return (
    <>
      <LinkHeader children={head} />
      {sub && <LinkSubheader children={sub} />}
      <CopyContainer>
        <UrlText title={url}>{url}</UrlText>
        <CopyToClipboard text={url} onCopy={onCopyClick}>
          <Button minWidth="70px">
            {copied ? t('copy.button.copied') : t('copy.button.text')}
          </Button>
        </CopyToClipboard>
      </CopyContainer>
    </>
  );
}

export default observer(LinkItem);
