import React from 'react';
import { observer } from 'mobx-react-lite';

import H3 from 'shared/elements/H3';
import Separator from 'shared/layout/Separator';
import { Button } from 'components/Button';
import Flex from 'shared/layout/Flex';
import Spacer from 'shared/layout/Spacer';

import { CAREERS_URL } from 'api';
import { useStore } from 'models/Provider';
import { PublishedModalWrapper, PublishedTagline } from './styles';
import LinkItem from './LinkItem';

function SingleLanguagePublishedModal({ closeModal, job, t, redirectOnSuccess }) {
  const { router, current, me, uiSettings } = useStore();

  function goToDash() {
    closeModal();
    if (redirectOnSuccess) {
      uiSettings.dashboard.changeTab(0);
      router.push('/');
    }
  }

  const uuid = `?referral_uuid=${me.uuid}`;
  const companyName = current.info.name;
  const lang = job.languages[0];
  const url = `${CAREERS_URL}/${companyName}/${lang.language}/${job.id}`;

  return (
    <PublishedModalWrapper>
      <div>
        <h2 style={{ marginBottom: 0 }}>
          {t('job.create.publish.modal.title')}{' '}
          <span role="img" aria-label="success">
            🎉
          </span>
        </h2>
        <PublishedTagline>{t('job.create.publish.modal.tagline')}</PublishedTagline>
        <Spacer top={20} bottom={20}>
          <Separator />
        </Spacer>
        <Spacer bottom={20}>
          <H3 noMargin>{t('job.create.publish.modal.direct.title.single')}</H3>
        </Spacer>
        <Spacer top={10}>
          <LinkItem
            head={t(`job.create.publish.modal.direct.head`)}
            sub={t(`job.create.publish.modal.direct.sub`)}
            url={url}
          />
        </Spacer>
      </div>
      <Spacer top={20} bottom={20}>
        <Separator />
      </Spacer>
      <div>
        <Spacer bottom={20}>
          <H3 noMargin>{t('job.create.publish.modal.refer.title')}</H3>
        </Spacer>
        <Spacer top={10}>
          <LinkItem head={t(`job.create.publish.modal.refer.head`)} url={url + uuid} />
        </Spacer>
      </div>
      <Spacer top={20} bottom={20}>
        <Separator />
      </Spacer>

      <Flex justify="flex-end">
        <Button blue onClick={goToDash}>
          {t('job.create.publish.modal.button.close')}
        </Button>
      </Flex>
    </PublishedModalWrapper>
  );
}

export default observer(SingleLanguagePublishedModal);
