import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { LabelBlue, LabelGreen, LabelOrange, LabelRed } from 'components/Label';

export const ApplicationLabels = observer(({ application }) => {
  const { t } = useStore();
  const isNew = application.userRead && !application.userRead.newApplication;
  return (
    <LabelGroup>
      {application.referredBy && (
        <LabelBlue small active children={t('application.label.sourced')} />
      )}
      {application.isNotifiedOfRejection && (
        <LabelRed small active children={t('application.label.rejectionsent')} />
      )}
      {isNew && <LabelGreen small active children={t('application.label.new')} />}
      {application.isRetracted && (
        <LabelOrange small active children={t('application.label.retracted')} />
      )}
    </LabelGroup>
  );
});

const LabelGroup = styled(Box).attrs({
  flex: true,
  wrap: true
})({
  '> div:not(:first-child)': {
    marginLeft: '0.25rem'
  }
});
