import React from 'react';
import { format } from 'date-fns';
import throttle from 'lodash-es/throttle';
import { Link } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { observer } from 'mobx-react-lite';

import { Box } from 'components/Box';
import { CardList, CardListHeader, CardListItem, CardListItems } from 'components/CardList';
import { Input } from 'components/Input';
import { IconButton } from 'components/IconButton';
import { IconCheckmark } from 'components/icons/IconCheckmark';
import { IconClose } from 'components/icons/IconClose';
import { LoadingMore } from 'components/LoadingMore';
import { Text } from 'components/Text';
import { useStore } from 'models/Provider';
import { sortJobApplications } from 'helpers';
import { StageActionMenu } from './StageActionMenu';
import { Tooltip } from 'components/Tooltip';

export const Stage = observer(({ stage, dropProps = {}, selectedId = -1, hideHeader = false }) => {
  const { isOver, oldStage, dropRef } = dropProps;

  return (
    <>
      <CardList transparent={isOver && oldStage !== stage.id} ref={dropRef} noHeader={hideHeader}>
        {!hideHeader && <StageHeader stage={stage} />}
        <List stage={stage} allowDrag={dropRef !== undefined} selectedId={selectedId} />
        {stage.nextState === 'loading' && <LoadingMore />}
      </CardList>
    </>
  );
});

const StageHeader = observer(({ stage }) => {
  const { t } = useStore();
  const [name, setName] = React.useState(stage.name);
  const [editOpen, setEditOpen] = React.useState(false);

  const submitNameChange = () => {
    if (name === stage.name) return;
    stage.job.immediateUpdate({
      [`stage${stage.id}Name`]: name
    });
    setEditOpen(false);
  };

  const resetNameChange = () => {
    setEditOpen(false);
    setName(stage.name);
  };

  if (editOpen) {
    return (
      <CardListHeader>
        <Input
          focusOnMount
          value={name}
          placeholder={stage.name}
          onChange={setName}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submitNameChange();
            }
          }}
        />
        <Box flex ml="4">
          <Tooltip id="edit-stage-confirm" />
          <Tooltip id="edit-stage-close" />
          <IconButton
            disabled={name === stage.name}
            data-for="edit-stage-confirm"
            data-tip={t('job.stages.menu.editname.tip.submit')}
            trans
            icon={IconCheckmark}
            color="green100"
            onClick={submitNameChange}
          />
          <IconButton
            data-for="edit-stage-close"
            data-tip={t('job.stages.menu.editname.tip.cancel')}
            trans
            icon={IconClose}
            color="red100"
            onClick={resetNameChange}
          />
        </Box>
      </CardListHeader>
    );
  }
  return (
    <CardListHeader>
      <Box flex alignItems="center">
        <Text type="bold14" color="purple80" children={stage.name} />
        <Text type="bold14" color="purple80" ml={4} mr={4}>
          &bull;
        </Text>
        <Text type="bold14" color="purple80" children={stage.count} />
      </Box>
      <StageActionMenu stage={stage} onSelect={stage.setOrder} onEdit={() => setEditOpen(true)} />
    </CardListHeader>
  );
});

export const DraggableStage = observer(({ stage }) => {
  const { me } = useStore();
  const allowMoves = me.role === 'recruiter' || me.role === 'admin';

  const [{ isOver, oldStage }, dropRef] = useDrop({
    accept: 'card',
    canDrop: props => allowMoves && props.application.stage !== stage.id,
    drop: props => allowMoves && props.application.changeStage(stage.id),
    collect: mon => {
      const item = mon.getItem();
      return {
        isOver: mon.isOver(),
        canDrop: mon.canDrop(),
        oldStage: item && item.application.stage
      };
    }
  });
  return <Stage stage={stage} dropProps={{ isOver, oldStage, dropRef }} />;
});

const List = observer(({ stage, allowDrag, selectedId }) => {
  const applications = sortJobApplications(stage._applications, stage.order);

  const onScroll = React.useCallback(
    throttle(e => {
      if (!stage.next) return;
      const el = e.target;
      const hasMoved = el.scrollTop > 0;
      if (!hasMoved) return;

      const maxHeight = el.scrollHeight;
      const heightWithScroll = el.clientHeight + el.scrollTop;
      if (maxHeight - heightWithScroll < 80) {
        stage.loadNext();
      }
    }, 200),
    [stage]
  );

  const ItemComponent = allowDrag ? DraggableItem : Item;

  return (
    <CardListItems
      onScroll={e => {
        e.persist();
        onScroll(e);
      }}
    >
      {applications.map(a => (
        <ItemComponent key={a.id} a={a} selected={selectedId === a.id} />
      ))}
    </CardListItems>
  );
});

const Item = observer(({ a, selected, dragRef }) => {
  const dt = a.date ? format(new Date(a.date), 'dd. MMMM yyyy') : '';

  React.useEffect(() => {
    if (selected) {
      const el = document.getElementById('application-' + a.id);
      el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selected, a.id]);

  if (selected) {
    return (
      <div key={a.id} id={`application-${a.id}`} ref={dragRef}>
        <CardListItem showLabels application={a} date={dt} selected={selected} />
      </div>
    );
  }

  return (
    <div key={a.id} id={`application-${a.id}`} ref={dragRef}>
      <Link
        to={{ pathname: a.detailUrl, state: { onCloseUrl: a.job.pipelineUrl } }}
        onClick={a.job.settings.clearSearchValue}
      >
        <CardListItem showLabels application={a} date={dt} />
      </Link>
    </div>
  );
});

const DraggableItem = observer(props => {
  const dragRef = useDrag({ item: { type: 'card', application: props.a } })[1];
  return <Item {...props} dragRef={dragRef} />;
});

export default DraggableStage;
