import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { ForgotPassword } from './ForgotPassword';

function ForgotPasswordPage() {
  const { authStore, t } = useStore();
  const [state, setState] = useState({
    email: '',
    error: '',
    disable: false,
    submitted: false
  });

  function handleChange(name, value) {
    setState(old => ({ ...old, [name]: value }));
  }

  async function resetPassword() {
    setState(old => ({ ...old, error: '', disable: true }));
    const url = window.location.origin + '/setpassword?uuid=';
    const success = await authStore.resetPassword(state.email, url);
    if (success) {
      setState(old => ({ ...old, submitted: true }));
    } else {
      setState(old => ({ ...old, error: t('forgotpw.reseterror'), disable: false }));
    }
  }

  return (
    <ForgotPassword
      t={t}
      email={state.email}
      onChange={handleChange}
      onSubmit={resetPassword}
      error={state.error}
      disableSubmit={state.disable}
      submitted={state.submitted}
    />
  );
}

export default observer(ForgotPasswordPage);
