import React from 'react';
import styled from 'styled-components/macro';
import { Loading } from 'components/Loading';
import { useMobile } from 'hooks/useMobile';

export default function PlaceholderPage() {
  const mobile = useMobile();
  if (mobile) return null;
  return (
    <Container>
      <Side />
      <Header />
      <Loading />
    </Container>
  );
}

const Header = styled.div(p => ({
  backgroundColor: p.theme.colors.white100,
  height: '5.5rem',
  width: '100%'
}));

const Side = styled.div(p => ({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  height: '100%',
  minWidth: '4.5rem',
  backgroundColor: p.theme.colors.purple100
}));

const Container = styled.div(p => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  backgroundColor: p.theme.colors.bgGray
}));
