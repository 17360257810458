import { types as t, getParent, flow } from 'mobx-state-tree';
import { updateMSTObject } from 'helpers';
import { withEnv } from 'models/withEnv';
import { IntegrationData } from 'models/integrations';
import { LoadingState } from 'models/ui';

export const Integration = t
  .model('Integration', {
    triggerId: t.number,
    name: t.string,
    description: t.string,
    successMessage: t.string,
    data: t.array(IntegrationData),
    canRetrigger: false,
    canDelete: false,
    canPreview: false,
    triggered: false,

    // Internal, not from API
    type: t.string,
    state: LoadingState
  })
  .views(withEnv)
  .views(self => ({
    get parent() {
      return getParent(self, 2);
    },
    get valueDict() {
      return self.data.reduce((agg, entry) => {
        if (entry.type === 'multiple') {
          if (entry.value) {
            agg[entry.name] = entry.value.toJS();
          } else {
            agg[entry.name] = [];
          }
        } else {
          agg[entry.name] = entry.value;
        }
        return agg;
      }, {});
    }
  }))
  .actions(self => ({
    isValid() {
      let valid = true;
      self.data.forEach(data => {
        if (!data.valid()) {
          valid = false;
        }
      });
      return valid;
    },
    hasErrors() {
      return !self.data.every(data => data.error === '');
    },
    firstError() {
      let errorData = null;
      for (let i = 0; i < self.data.length; i++) {
        if (!self.data[i].valid()) {
          errorData = self.data[i];
          break;
        }
      }
      return errorData;
    },
    submitForJob: flow(function*() {
      const res = yield self.env.api.jobs.submitIntegration(
        self.parent.id,
        'job_published',
        self.triggerId,
        self.valueDict
      );
      if (!res.error) {
        self.triggered = true;
        self.env.notify('success', self.successMessage);
      } else {
        self.env.notify('error', res.data || self.env.t('integration.submit.error'));
      }
    }),
    submitForApplication: flow(function*(retrigger, save) {
      let data = self.valueDict;
      if (retrigger) {
        data.retrigger = true;
      }
      if (save) {
        data.save = true;
      }
      const res = yield self.env.api.applications.submitIntegration(
        self.parent.id,
        'applicant_hired',
        self.triggerId,
        data
      );
      if (!res.error) {
        updateMSTObject(self, res.data);
        self.env.notify(
          'success',
          save ? self.env.t('integration.submit.save') : self.successMessage
        );
      } else {
        self.env.notify('error', res.data || self.env.t('integration.submit.error'));
      }
    }),
    previewForApplication: flow(function*() {
      let data = self.valueDict;
      const res = yield self.env.api.applications.previewIntegration(
        self.parent.id,
        'applicant_hired',
        self.triggerId,
        data
      );
      if (!res.error) {
        return res.data;
      } else {
        self.env.notify('error', res.data || self.env.t('integration.preview.error'));
      }
    }),
    refetchForApplication: flow(function*() {
      let data = self.valueDict;
      const res = yield self.env.api.applications.submitIntegration(
        self.parent.id,
        'applicant_hired',
        self.triggerId,
        data,
        true
      );
      if (!res.error) {
        self.data = res.data.data;
      } else {
        self.env.notify('error', res.data || self.env.t('integration.submit.error'));
      }
    }),
    deleteForApplication: flow(function*() {
      const res = yield self.env.api.applications.deleteIntegration(
        self.parent.id,
        'applicant_hired',
        self.triggerId
      );
      if (!res.error) {
        updateMSTObject(self, res.data);
      } else {
        self.env.notify('error', res.data || self.env.t('integration.delete.error'));
      }
    }),
    loadForJob: flow(function*() {
      if (self.state !== 'init') return;
      self.state = 'loading';
      const res = yield self.env.api.jobs.loadIntegration(
        self.parent.id,
        self.type,
        self.triggerId
      );
      if (!res.error) {
        self.state = 'loaded';
        updateMSTObject(self, res.data);
      } else {
        self.state = 'error';
      }
    }),
    loadForApplication: flow(function*() {
      if (self.state !== 'init') return;
      self.state = 'loading';
      const res = yield self.env.api.applications.loadIntegration(
        self.parent.id,
        self.type,
        self.triggerId
      );
      if (!res.error) {
        self.state = 'loaded';
        updateMSTObject(self, res.data);
      } else {
        self.state = 'error';
      }
    })
  }));
