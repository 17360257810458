import React from 'react';
import { useTheme } from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import ReactModal from 'react-modal';
import { Text, FormLabel } from 'components/Text';
import { Separator } from 'components/Separator';
import { ButtonGroup } from 'components/Button';
import { useMobile } from 'hooks/useMobile';

ReactModal.setAppElement('#root');

export const Modal = observer(({ components, store }) => {
  const { modalType, modalProps } = store;
  const isMobile = useMobile();
  const theme = useTheme();

  function closeModal() {
    if (modalProps && modalProps.onCloseModal) {
      modalProps.onCloseModal();
    }
    store.hide();
  }

  let modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.colors.modalBg,
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: '28.75rem',
      maxWidth: '98%',
      maxHeight: '98%',
      boxShadow: '0 1rem 1rem 0 rgba(0, 0, 0, 0.24)',
      borderRadius: '0.25rem',
      border: 'none',
      padding: isMobile ? '1.5rem' : '2.5rem',
      overflow: 'auto',
      margin: '0 auto'
    }
  };

  if (store.size === 'fullscreen') {
    modalStyles.overlay.backgroundColor = theme.colors.purple100;
    modalStyles.content.maxWidth = '100%';
    modalStyles.content.maxHeight = '100%';
    modalStyles.content.width = '100%';
    modalStyles.content.height = '100%';
    modalStyles.content.backgroundColor = theme.colors.purple100;
    modalStyles.content.borderRadius = '0';
    modalStyles.content.border = 'none';
    modalStyles.content.padding = '0';
  }
  let closeOnOverlayOrEsc = true;

  const SpecificModal = components[modalType];

  if (modalProps) {
    if (modalProps.width) {
      modalStyles.content.width = modalProps.width;
    }
    if (modalProps.autoWidth) {
      modalStyles.content.width = 'auto';
    }
    if (modalProps.minWidth) {
      modalStyles.content.minWidth = modalProps.minWidth;
    }
    if (modalProps.overflow) {
      modalStyles.content.overflow = modalProps.overflow;
    }
    if (modalProps.overlayClose !== undefined) {
      closeOnOverlayOrEsc = modalProps.overlayClose;
    }
  }

  return (
    <ReactModal
      style={modalStyles}
      isOpen={modalType !== ''}
      contentLabel="Modal"
      onRequestClose={closeModal}
      parentSelector={() => document.getElementById('root')}
      shouldCloseOnOverlayClick={closeOnOverlayOrEsc}
      shouldCloseOnEsc={closeOnOverlayOrEsc}
    >
      <div id="modal">
        {SpecificModal && <SpecificModal {...modalProps} closeModal={closeModal} />}
      </div>
    </ReactModal>
  );
});

export const ModalActions = observer(props => <ButtonGroup right mt="24" {...props} />);
export const ModalHeader = observer(props => {
  return (
    <div>
      <Text type="h1" color="purple100" {...props} />
      <Separator mt="24" mb="24" />
    </div>
  );
});
export const ModalSubHeader = observer(props => {
  return (
    <div>
      <FormLabel {...props} />
    </div>
  );
});
export const ModalText = observer(props => <Text color="purple80" {...props} />);
