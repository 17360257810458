import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconClose = props => (
  <Svg {...props}>
    <path
      d="M8 16l8-8M16 16L8 8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
