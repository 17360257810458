export const API_URL = `${process.env.REACT_APP_API_URL}/`;
export const APPLICATIONCHAT_URL = `${API_URL}applicationchat/`;
export const APPLICATIONS_URL = `${API_URL}applications/`;
export const COMPANY_URL = `${API_URL}company/`;
export const SEARCH_JOBS_URL = `${API_URL}search/jobs/`;
export const JOBS_URL = `${API_URL}jobs/`;
export const REVIEW_URL = `${API_URL}reviews/`;
export const TEMPLATES_URL = `${API_URL}templates/`;
export const USER_URL = `${API_URL}user/`;
export const USERS_URL = `${API_URL}users/`;
export const SEARCH_USERS_URL = `${API_URL}search/users/`;
export const NOTIFICATIONS_URL = `${API_URL}notifications/`;
export const SEARCH_NOTIFICATIONS_URL = `${API_URL}search/notifications/`;
export const NOTIFICATION_SETTINGS_URL = `${API_URL}notificationsettings/`;
export const INTEGRATIONS_URL = `${API_URL}company/integrations/`;

export const CAREERS_URL = process.env.REACT_APP_CAREERS_URL;
