import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import { useStore } from 'models/Provider';
import { Menu, MenuButton, MenuItem, MenuItems } from 'components/Menu';
import { Tooltip } from 'components/Tooltip';
import { useTextEditorState } from '../TextEditor';
import { insertPlaceholder } from '.';

const PlaceholderIcon = forwardRef(({ children, onKeyboardFocus, ...rest }, ref) => (
  <StyledPlaceholderIcon {...rest} ref={ref}>
    {children}
  </StyledPlaceholderIcon>
));

const StyledPlaceholderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 13px;
  height: 36px;
  line-height: 16px;
  padding: 2px 35px 2px 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: auto;
  background-color: #ececec;
  color: ${p => p.theme.textColor};
  font-weight: bold;
  :after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 35%;
    right: 15px;
    border-width: 5px 4px 0 4px;
    border-style: solid;
    border-color: #81839b transparent;
  }
  :hover,
  :focus {
    outline: none;
    cursor: pointer;
    background-color: ${p => p.theme.colors.purpleTrans10};
    color: ${p => p.theme.colors.purple80};
  }
`;

export const PlaceholderMenu = ({ getEditorState, setEditorState }) => {
  const { t } = useStore();
  const { placeholderMap, disabled } = useTextEditorState();
  if (!placeholderMap) return null;
  const keys = Object.keys(placeholderMap);

  const addPlaceholder = (e, label, data) => {
    if (disabled) return;
    e.preventDefault();
    const editorState = getEditorState();
    const newState = insertPlaceholder(editorState, label, data);
    newState && setEditorState(newState);
  };

  return (
    <div className="placeholder" style={{ flex: 1, textAlign: 'right' }}>
      <Tooltip id="placeholdertip" />
      <Menu label="Placeholders">
        <MenuButton variant="unstyled" data-for="placeholdertip" data-tip="Add placeholder">
          <PlaceholderIcon tabIndex={0}>{t('editor.placeholder.title')}</PlaceholderIcon>
        </MenuButton>
        <MenuItems>
          {keys.map(placeholder => (
            <MenuItem
              key={placeholder}
              onClick={e => addPlaceholder(e, placeholderMap[placeholder], placeholder)}
            >
              {placeholderMap[placeholder]}
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </div>
  );
};
