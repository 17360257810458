import { types as t } from 'mobx-state-tree';

const ApplicationPicture = t.model('ApplicationPicture', {
  id: t.identifierNumber,
  url: ''
});

export const ApplicationPictureStore = t
  .model('ApplicationPictureStore', {
    items: t.map(ApplicationPicture)
  })
  .actions(self => ({
    setUrl(id, url) {
      self.items.put({ id, url });
    }
  }));
