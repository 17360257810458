import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { sortBy } from 'helpers';
import { Text, TextLabel } from 'components/Text';
import { UserAvatar } from 'components/UserAvatar';
import { Button } from 'components/Button';
import { Box } from 'components/Box';
import { Separator } from 'components/Separator';
import { IconPlus } from 'components/icons/IconPlus';
import { HiringTeamAccessEntry, GrantAccessButton } from './styles';
import { useMobile } from 'hooks/useMobile';

export const ApplicationAccess = observer(({ application }) => {
  const { t, openModal, hasPerm } = useStore();
  const isMobile = useMobile();
  const [revoking, setRevoking] = useState({});

  function onAddUserClick() {
    openModal('ADD_APPLICATION_ACCESS', {
      application
    });
  }

  async function onRevokeClick(follower) {
    setRevoking(old => ({ ...old, [follower.id]: true }));
    await application.removeFollowerAccess(follower);
    setRevoking(old => ({
      ...old,
      [follower.id]: false
    }));
  }

  const sortedHiringTeam = sortBy(application.job.followers, 'name');
  const sortedApplicationFollowers = sortBy(application.followers, 'name');

  return (
    <>
      <Box flex justifyContent="space-between" mb="16" mt="24">
        <TextLabel>{t('application.access.header')}</TextLabel>
        <TextLabel grow={!isMobile} style={{ maxWidth: '60%' }}>
          {t('application.access.roleheader')}
        </TextLabel>
      </Box>
      {sortedHiringTeam.map(follower => (
        <HiringTeamAccessEntry key={follower.id}>
          <div>
            <UserAvatar src={follower.picture} name={follower.name} size="lg" />
            <span className="hiring-team-name">
              <Text type="regular14" color="purple80">
                {follower.name}
              </Text>
            </span>
          </div>
          <div>
            <div className="hiring-team-role">
              <Text type="regular14" color="purple80">
                {follower.roleDisplay}
              </Text>
            </div>
            {application.followers.includes(follower) && hasPerm('application:revoke-access') && (
              <GrantAccessButton
                onClick={revoking[follower.id] ? null : () => onRevokeClick(follower)}
              >
                {revoking[follower.id]
                  ? t('application.access.revoking')
                  : t('application.access.revoke')}
              </GrantAccessButton>
            )}
          </div>
        </HiringTeamAccessEntry>
      ))}
      <Separator mt="32" mb="32" />
      <TextLabel>{t('application.header.access')}</TextLabel>
      <div>
        {sortedApplicationFollowers.map(follower => (
          <HiringTeamAccessEntry key={follower.id}>
            <div>
              <UserAvatar src={follower.picture} name={follower.name} size="lg" />
              <span className="hiring-team-name">{follower.name}</span>
            </div>
            <div>
              <div className="hiring-team-role">{follower.roleDisplay}</div>
              {hasPerm('application:revoke-access') && (
                <GrantAccessButton
                  onClick={revoking[follower.id] ? null : () => onRevokeClick(follower)}
                >
                  {revoking[follower.id]
                    ? t('application.access.revoking')
                    : t('application.access.revoke')}
                </GrantAccessButton>
              )}
            </div>
          </HiringTeamAccessEntry>
        ))}
        <div>
          {sortedApplicationFollowers.length === 0 && (
            <Text type="regular16" color="purple60">
              {t('application.access.noaccess')}
            </Text>
          )}
        </div>
        {hasPerm('application:grant-access') && (
          <Button mt="24" gray onClick={onAddUserClick} icon={IconPlus}>
            {t('application.access.button.submit')}
          </Button>
        )}
      </div>
    </>
  );
});
