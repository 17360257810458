import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import Radio from 'shared/elements/Radio';

import { useStore } from 'models/Provider';

import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { ModalActions, ModalHeader, ModalSubHeader, ModalText } from 'components/Modal';

export const ExportJobModal = observer(({ job, closeModal }) => {
  const [submitted, setSubmitted] = useState(false);
  const { t, current } = useStore();
  const [exportType, setExportType] = useState('xlsx');

  let availableFields = {
    id: { label: t('exportmodal.fields.id'), checked: false },
    name: { label: t('exportmodal.fields.name'), checked: false },
    email: { label: t('exportmodal.fields.email'), checked: false },
    phone: { label: t('exportmodal.fields.phone'), checked: false },
    ssn: { label: t('exportmodal.fields.ssn'), checked: false },
    date: { label: t('exportmodal.fields.date'), checked: false },
    is_rejected: { label: t('exportmodal.fields.is_rejected'), checked: false },
    is_notified_of_rejection: {
      label: t('exportmodal.fields.is_notified_of_rejection'),
      checked: false
    },
    date_rejected: { label: t('exportmodal.fields.date_rejected'), checked: false },
    is_hired: { label: t('exportmodal.fields.is_hired'), checked: false },
    date_hired: { label: t('exportmodal.fields.date_hired'), checked: false },
    is_retracted: { label: t('exportmodal.fields.is_retracted'), checked: false },
    is_manually_added: { label: t('exportmodal.fields.is_manually_added'), checked: false },
    referred_site: { label: t('exportmodal.fields.referred_site'), checked: false },
    stage_name: { label: t('exportmodal.fields.stage_name'), checked: false },
    num_reviews: { label: t('exportmodal.fields.num_reviews'), checked: false },
    num_notes: { label: t('exportmodal.fields.num_notes'), checked: false }
  };
  const [fields, setFields] = useState(availableFields);
  const fieldsArray = Object.entries(fields).map(e => ({ id: e[0], ...e[1] }));
  function addOrRemoveField(fieldId) {
    let newFields = Object.assign({}, fields);
    newFields[fieldId].checked = !newFields[fieldId].checked;
    setFields(newFields);
  }

  let fieldList = [];
  for (let key in fields) {
    const field = fields[key];
    fieldList.push(
      <Checkbox
        key={key}
        label={field.label}
        checked={field.checked}
        onClick={() => addOrRemoveField(key)}
      />
    );
  }

  const selectedAllFields = fieldsArray.every(field => field.checked);
  const selectAllFields = () => {
    let newFields = Object.assign({}, fields);
    for (let key in newFields) newFields[key].checked = true;
    setFields(newFields);
  };
  const unselectAllFields = () => {
    let newFields = Object.assign({}, fields);
    for (let key in newFields) newFields[key].checked = false;
    setFields(newFields);
  };

  let availableQuestions = {};
  job.defaultLanguageData.form.sections.map(s =>
    s.questions.forEach(q => {
      if (q.type !== 'attachment') availableQuestions[q.id] = { label: q.title, checked: false };
    })
  );
  const [questions, setQuestions] = useState(availableQuestions);
  const questionsArray = Object.entries(questions).map(e => ({ id: e[0], ...e[1] }));

  function addOrRemoveQuestion(questionId) {
    let newQuestions = Object.assign({}, questions);
    newQuestions[questionId].checked = !newQuestions[questionId].checked;
    setQuestions(newQuestions);
  }
  let questionList = [];
  for (let key in questions) {
    const question = questions[key];
    questionList.push(
      <Checkbox
        key={key}
        label={question.label}
        checked={question.checked}
        onClick={() => addOrRemoveQuestion(key)}
      />
    );
  }

  const selectedAllQuestions = questionsArray.every(question => question.checked);
  const selectAllQuestions = () => {
    let newQuestions = Object.assign({}, questions);
    for (let key in newQuestions) newQuestions[key].checked = true;
    setQuestions(newQuestions);
  };
  const unselectAllQuestions = () => {
    let newQuestions = Object.assign({}, questions);
    for (let key in newQuestions) newQuestions[key].checked = false;
    setQuestions(newQuestions);
  };

  function onSubmit() {
    let checkedFields = [];
    for (let key in fields) {
      if (fields[key].checked) checkedFields.push(key);
    }
    for (let key in questions) {
      if (questions[key].checked) checkedFields.push(key);
    }
    const success = job.exportApplicants(checkedFields, exportType);
    if (success) {
      setSubmitted(true);
    }
  }

  return submitted ? (
    <>
      <ModalHeader>{t('exportmodal.submitted.title')}</ModalHeader>
      <ModalText
        dangerouslySetInnerHTML={{
          __html: t('exportmodal.submitted.confirmtext', current.users.me.email)
        }}
      />
      <ModalText mt={8}>{t('exportmodal.submitted.notice')}</ModalText>
      <ModalActions>
        <Button onClick={closeModal}>{t('exportmodal.button.close')}</Button>
      </ModalActions>
    </>
  ) : (
    <>
      <ModalHeader>{t('exportmodal.title')}</ModalHeader>
      <Box mt={8}>
        <ModalSubHeader>{t('exportmodal.labels.exporttype')}</ModalSubHeader>
        <Box mb="4">
          <Radio
            value={'xlsx'}
            checked={exportType === 'xlsx'}
            onCheck={() => setExportType('xlsx')}
            label={t('exportmodal.exporttype.xlsx')}
            key={'xlsx'}
            domValue={'xlsx'}
            dataId={'xlsx'}
          />
        </Box>
        <Box mb="4">
          <Radio
            value={'csv'}
            checked={exportType === 'csv'}
            onCheck={() => setExportType('csv')}
            label={t('exportmodal.exporttype.csv')}
            key={'csv'}
            domValue={'csv'}
            dataId={'csv'}
          />
        </Box>
      </Box>
      <Box mt={8} flex justifyContent="space-between">
        <ModalSubHeader>{t('exportmodal.labels.headers')}</ModalSubHeader>
        <SelectAll onClick={selectedAllFields ? unselectAllFields : selectAllFields}>
          {selectedAllFields ? t('exportmodal.deselect') : t('exportmodal.select')}
        </SelectAll>
      </Box>
      {fieldList.map(field => field)}
      <Box mt={8} flex justifyContent="space-between">
        <ModalSubHeader>{t('exportmodal.labels.questions')}</ModalSubHeader>
        <SelectAll onClick={selectedAllQuestions ? unselectAllQuestions : selectAllQuestions}>
          {selectedAllQuestions ? t('exportmodal.deselect') : t('exportmodal.select')}
        </SelectAll>
      </Box>
      {questionList.map(question => question)}

      <ModalActions>
        <Button gray onClick={closeModal}>
          {t('exportmodal.button.cancel')}
        </Button>
        <Button onClick={onSubmit}>{t('exportmodal.button.submit')}</Button>
      </ModalActions>
    </>
  );
});

const SelectAll = styled.div(p => ({
  ':hover': {
    cursor: 'pointer'
  },
  ...p.theme.text.regular14,
  opacity: 0.8,
  color: p.theme.colors.purple40,
  fontStyle: 'italic'
}));
