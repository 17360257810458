import moment from 'moment';
import { types as t, flow, getParent } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

export const IntegrationData = t
  .model('IntegrationData', {
    name: '',
    type: '',
    label: '',
    value: t.maybeNull(
      t.optional(t.union(t.string, t.number, t.boolean, t.Date, t.array(t.string)), '')
    ),
    required: t.maybe(t.boolean),
    choices: t.frozen(),
    dynamic: t.maybe(t.boolean),
    blob: t.optional(t.frozen(), null),
    loadedBlob: false,
    error: '',
    validators: t.array(t.string),
    alertType: ''
  })
  .views(withEnv)
  .views(self => ({
    get parent() {
      return getParent(self, 2);
    },
    get inputValue() {
      if (self.type === 'date') {
        return moment(self.value).format('dddd, MMMM Do YYYY');
      } else if (self.choices) {
        return self.getText(self.value);
      }
      return self.value;
    },
    get fileName() {
      if (self.type === 'file' || self.type === 'fileurl') {
        return self.value.substring(self.value.lastIndexOf('/') + 1, self.value.indexOf('?'));
      }
      return self.value;
    }
  }))
  .actions(self => ({
    getText(value) {
      for (let i = 0; i < self.choices.length; i++) {
        if (Number(self.choices[i][0]) === Number(value)) {
          return self.choices[i][1];
        }
      }
      return value;
    },
    valid() {
      const hasValue = self.value !== '' && self.value !== undefined && self.value !== null;
      if (self.type !== 'hidden' && self.required && !hasValue) {
        self.setError(self.env.t('errors.required'));
        return false;
      }
      return true;
    },
    setError(error) {
      self.error = error;
    },
    setValue(value) {
      self.error = '';
      if (self.type === 'multiple') {
        self.value = Object.keys(value).filter(key => value[key]);
      } else {
        self.value = value;
      }
    },
    refetch() {
      self.parent.refetchForApplication();
    },
    loadBlob: flow(function*() {
      if (self.loadedBlob) return;
      if (!self.value) {
        self.loadedBlob = true;
        return;
      }

      const res = yield self.env.api.applications.loadBlob(self.value);
      if (res.status === 200 && res.data.type === 'application/pdf') {
        self.blob = res.data;
      }
      self.loadedBlob = true;
    })
  }));
