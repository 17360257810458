import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { P } from 'components/Text';
import { Button } from 'components/Button';
import { ModalHeader, ModalActions, ModalText } from 'components/Modal';

function RemoveConfirmModal({ closeModal, data, confirm }) {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useStore();
  async function submit() {
    setSubmitting(true);
    const success = await confirm(data.id);
    setSubmitting(false);
    if (success) {
      closeModal();
    }
  }

  return (
    <>
      <ModalHeader>{t('modals.removeconfirm.title')}</ModalHeader>
      <ModalText as="p">{t('modals.removeconfirm.confirm', data.type)}</ModalText>
      <P color="red100" mt="16" type="bold16">
        {t('modals.removeconfirm.warning')}
      </P>

      <ModalActions>
        <Button gray onClick={!submitting ? closeModal : null} disabled={submitting}>
          {t('modals.removeconfirm.button.cancel')}
        </Button>
        <Button red onClick={!submitting ? submit : null} disabled={submitting}>
          {t('modals.removeconfirm.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
}

export default observer(RemoveConfirmModal);
