import React from 'react';
import styled from 'styled-components/macro';
import { Box } from 'components/Box';

export const Button = ({ onClick, disabled, children, icon: Icon, ...rest }) => (
  <StyledButton
    {...rest}
    onClick={disabled ? undefined : onClick}
    as="button"
    type="button"
    disabled={disabled}
  >
    {Icon && (
      <ButtonIconWrapper>
        <Icon />
      </ButtonIconWrapper>
    )}
    <div>{children}</div>
  </StyledButton>
);

const ButtonIconWrapper = styled.div({
  marginLeft: '-0.9375rem',
  height: '1.5rem',
  marginRight: '0.5rem'
});

const baseHoverFocus = p => ({
  cursor: 'pointer',
  boxShadow: '0px 0.125rem 0.25rem rgba(49, 211, 155, 0.25)',
  backgroundColor: p.theme.colors.green90
});

const StyledButton = styled(Box)(p => ({
  backgroundColor: p.theme.colors.green100,
  borderRadius: '0.25rem',
  color: p.theme.colors.white100,
  ...p.theme.text.semibold14,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: p.minWidth || '6.25rem',
  opacity: p.disabled ? 0.6 : 1,
  padding: '0 1.5rem',
  height: '2.5rem',
  position: 'relative',
  textAlign: 'center',
  lineHeight: '1rem',
  transition: 'all 200ms ease-out',

  ...(!p.disabled && {
    ':hover, :focus': baseHoverFocus(p)
  }),
  width: p.fullWidth ? '100%' : 'auto',
  ...(p.red && {
    backgroundColor: p.theme.colors.red100,
    ':hover, :focus': { ...baseHoverFocus(p), backgroundColor: p.theme.colors.red90 }
  }),
  ...(p.purple && {
    backgroundColor: p.theme.colors.purple100,
    ':hover, :focus': { ...baseHoverFocus(p), backgroundColor: p.theme.colors.purple90 }
  }),
  ...(p.blue && {
    backgroundColor: p.theme.colors.blue100,
    ':hover, :focus': {
      ...baseHoverFocus(p),
      backgroundColor: p.theme.colors.blue100,
      boxShadow: '0px 0.125rem 0.25rem rgba(80, 80, 116, 0.2)'
    }
  }),
  ...(p.orange && {
    backgroundColor: p.theme.colors.orange100,
    ':hover, :focus': { ...baseHoverFocus(p), backgroundColor: p.theme.colors.orange90 }
  }),
  ...(p.gray && {
    backgroundColor: p.theme.colors.purpleTrans5,
    color: p.theme.colors.purple80,
    ':hover, :focus': {
      ...baseHoverFocus(p),
      backgroundColor: p.theme.colors.purpleTrans10,
      boxShadow: 'none'
    }
  })
}));

export const ButtonGroup = styled(({ right, left, noWrap, ...rest }) => <Box {...rest} />)(p => ({
  display: 'flex',
  flexWrap: p.noWrap ? 'no-wrap' : 'wrap',
  justifyContent: p.left ? 'flex-start' : 'flex-end',
  width: '100%',
  ...(p.right && {
    '> *:not(:last-child)': {
      marginRight: '0.5rem'
    }
  }),

  ...(p.left && {
    '> *:not(:first-child)': {
      marginLeft: '0.5rem'
    }
  })
}));
