import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { Manager, Reference, Popper } from 'react-popper';
import ClickOutside from 'shared/layout/ClickOutside';
import { getElementHeight, getVisibleHeight } from 'shared/utils/browser';

import { FormError, Text } from 'components/Text';
import { IconSelectArrow } from 'components/icons/IconSelectArrow';
import { Checkbox } from 'components/Checkbox';
import { Box } from 'components/Box';

export const Select = observer(props => {
  const {
    className = '',
    items = [],
    onChange = null,
    defaultValue = null,
    error = '',
    fullSize = true,
    title = '',
    disableSelect = false,
    getDisplayText = null,
    getTitleDisplayText = null,
    disable = false,
    inModal = false,
    dataId = null,
    maxWidth = '100%',
    multi = false
  } = props;
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(320);
  const container = useRef(null);
  const dropdownRef = useRef(null);
  const idx = items.findIndex(item => item.value === defaultValue);
  const [focusedIndex, setFocusedIndex] = useState(idx > 0 ? idx : -1);
  const [selection, setSelection] = useState(() => getDefaultSelection(defaultValue, items));
  const chosen = React.useMemo(() => getChosenDisplay(selection, items), [selection, items]);
  const isDisabled = disable || items.length === 0;

  const onKeyDown = e => {
    if (isDisabled) return;
    const key = e.key;
    switch (key) {
      case 'Tab':
      case 'Escape':
        if (open) {
          setOpen(false);
          if (inModal) resetModalOverflow();
          const selectedIndices = getSelected(selection);
          const firstIndex = selectedIndices.length > 0 ? getIndex(items, selectedIndices[0]) : 0;
          setFocusedIndex(multi ? 0 : firstIndex);
          container.current.focus();
        } else {
          container.current.blur();
        }
        break;
      case 'Space':
      case 'Enter':
        if (!open) {
          if (inModal) fixModalScroll(container.current);
          setOpen(true);
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (focusedIndex < items.length - 1) {
          setFocusedIndex(old => old + 1);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (focusedIndex > 0) {
          setFocusedIndex(old => old - 1);
        }
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    setSelection(getDefaultSelection(defaultValue, items));
    // eslint-disable-next-line
  }, [defaultValue]);

  React.useEffect(() => {
    if (!open) return;
    if (focusedIndex !== -1) {
      const child = dropdownRef.current.children[focusedIndex];
      child.focus();
    }
  }, [open, focusedIndex]);

  function click() {
    if (isDisabled) return;
    setOpen(old => {
      if (inModal && old) {
        resetModalOverflow();
      }
      return !old;
    });
  }

  function onOutsideClick(e) {
    if (!open) return;

    e.preventDefault();
    e.stopPropagation();
    click();

    if (inModal) resetModalOverflow();
  }

  function select(event, value) {
    event.preventDefault();
    event.stopPropagation();
    const index = getIndex(items, value);
    if (disableSelect) return;
    setSelection(old => {
      let newSelection;
      if (multi) {
        newSelection = {
          ...old,
          [value]: !old[value]
        };
      } else {
        newSelection = {
          [value]: true
        };
      }
      if (onChange) {
        if (multi) {
          onChange(newSelection, items[index].link);
        } else {
          onChange(value, items[index].link);
        }
      }
      return newSelection;
    });
    if (!multi) {
      setFocusedIndex(index);
      setOpen(false);
      if (inModal) resetModalOverflow();
    }
  }

  function onClick() {
    if (isDisabled || !container.current) return;
    if (inModal) fixModalScroll(container.current);

    const rect = container.current.getBoundingClientRect();
    const bottomDiff = window.innerHeight - rect.bottom;

    if (bottomDiff < 320) {
      const topDiff = rect.top;
      if (bottomDiff > topDiff) {
        setMaxHeight(bottomDiff - 10);
      } else if (topDiff < 320) {
        setMaxHeight(topDiff - 10);
      } else {
        setMaxHeight(320);
      }
    } else if (maxHeight !== 320) {
      setMaxHeight(320);
    }
    click();
  }

  let titleDisplay = chosen || title;
  if (getDisplayText) {
    titleDisplay = getDisplayText(chosen);
  }
  if (getTitleDisplayText) {
    titleDisplay = getTitleDisplayText(chosen);
  }

  return (
    <ClickOutside onOutsideClick={onOutsideClick}>
      <SelectContainer
        maxWidth={maxWidth}
        open={open}
        disable={isDisabled}
        error={error}
        ref={container}
        onClick={onClick}
        className={className}
        tabIndex={isDisabled ? -1 : 0}
        onKeyDown={onKeyDown}
      >
        <Manager>
          <Reference>
            {({ ref }) => (
              <SelectTitleContainer
                key={isDisabled}
                ref={ref}
                fullSize={fullSize}
                disable={isDisabled}
                data-id={dataId}
              >
                <SelectTitle>{titleDisplay}</SelectTitle>
                <SelectArrow />
              </SelectTitleContainer>
            )}
          </Reference>
          {open && (
            <Popper placement="bottom-end" modifiers={popperModifiers}>
              {({ ref, style, placement, arrowProps }) => (
                <SelectMenuContainer
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  fullSize={fullSize}
                >
                  <SelectMenu open={open} maxHeight={maxHeight + 'px'} ref={dropdownRef}>
                    {items.map((item, i) => {
                      const sel = event => select(event, item.value);
                      return (
                        <SelectItem
                          tabIndex={0}
                          key={i}
                          danger={item.danger}
                          onClick={item.readOnly ? null : sel}
                          readOnly={item.readOnly}
                          onKeyDown={e => {
                            if (e.key === 'Enter' || e.key === 'Space') {
                              e.stopPropagation();
                              if (!item.readOnly) {
                                sel(e);
                              }
                              if (!multi) {
                                container.current.focus();
                              }
                            }
                          }}
                        >
                          <div>
                            {multi ? (
                              <SelectMultiDisplay>
                                <Checkbox checked={selection[item.value] || false} />
                                <Box ml="8">
                                  {getDisplayText ? getDisplayText(item.display) : item.display}
                                </Box>
                              </SelectMultiDisplay>
                            ) : (
                              <SelectDisplay>
                                {getDisplayText ? getDisplayText(item.display) : item.display}
                              </SelectDisplay>
                            )}
                            {item.helpText && (
                              <Text type="regular12" color="purple50" as="div">
                                {item.helpText}
                              </Text>
                            )}
                          </div>
                        </SelectItem>
                      );
                    })}
                  </SelectMenu>
                  <div ref={arrowProps.ref} style={arrowProps.style} />
                </SelectMenuContainer>
              )}
            </Popper>
          )}
        </Manager>
      </SelectContainer>
      {error && <FormError>{error}</FormError>}
    </ClickOutside>
  );
});

const getIndex = (items, value) => items.findIndex(item => item.value === value);
const getSelected = selection => Object.keys(selection).filter(key => Boolean(selection[key]));
const getChosenDisplay = (selection, items) => {
  let hasObject = false;
  if (items.length === 0) return '';
  const filtered = getSelected(selection)
    .map(key => {
      const item = items.find(item => item.value.toString() === key.toString());
      if (typeof item.display === 'object') {
        hasObject = true;
      }
      return item ? item.display : '';
    })
    .filter(Boolean);
  if (!hasObject) {
    return filtered.join(', ');
  }
  return filtered;
};

const getDefaultSelection = (defaultValue, items) => {
  if (typeof defaultValue === 'string' || typeof defaultValue === 'number') {
    const item = items.find(item => item.value.toString() === defaultValue.toString());
    if (!item) return {};
    return { [defaultValue]: true };
  }
  return defaultValue || {};
};

const popperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false }
};

const resetModalOverflow = () => {
  const mdl = document.getElementById('modal');
  mdl.parentNode.style.overflow = 'auto';
};

const fixModalScroll = container => {
  const mdl = document.getElementById('modal');
  const mdlHt = getElementHeight(mdl);
  const ddHt = getElementHeight(container);
  const viewHt = getVisibleHeight();
  if (mdlHt + ddHt >= viewHt - 50) {
    mdl.parentNode.style.overflow = 'auto';
  } else {
    mdl.parentNode.style.overflow = 'visible';
  }
};

const SelectContainer = styled.div(p => ({
  maxWidth: p.maxWidth || '17.5rem',
  minWidth: '12rem',
  width: '100%',
  minHeight: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  transition: 'all 150ms ease-out',
  position: 'relative',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: p.error ? p.theme.colors.red100 : p.borderColor || 'transparent',
  borderRadius: '4px',
  ...(!p.open && {
    backgroundColor: p.theme.colors.purpleTrans5,
    ...(p.disable && {
      ':focus': {
        outline: 'none'
      }
    }),
    ...(!p.disable && {
      ':hover': {
        backgroundColor: p.theme.colors.purpleTrans10,
        cursor: 'pointer'
      },
      ':focus': {
        outline: 'none',
        borderColor: p.theme.colors.green100,
        backgroundColor: p.theme.colors.white100
      }
    })
  }),
  ...(p.open && {
    outline: 'none',
    borderColor: p.theme.colors.green100,
    backgroundColor: p.theme.colors.white100
  }),
  [p.theme.mobileQuery]: {
    minWidth: 'auto'
  }
}));

const SelectArrow = styled(IconSelectArrow)(p => ({
  color: p.theme.colors.purple50,
  position: 'absolute',
  right: '0.5rem'
}));

const SelectMultiDisplay = styled.span(p => ({
  display: 'flex',
  justifyContent: 'center',
  ...p.theme.text.regular16,
  color: p.theme.colors.purple80
}));

const SelectDisplay = styled.span(p => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  ...p.theme.text.regular16,
  color: p.theme.colors.purple80
}));

const SelectMenuContainer = styled.div`
  z-index: 110;
  width: 100%;
  max-width: ${p => (p.fullSize ? '100%' : '380px')};
`;

const SelectTitle = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const SelectTitleContainer = styled.div`
  height: calc(2.5rem - 2px);
  z-index: 5;
  box-sizing: border-box;
  color: ${p => p.theme.colors.purple80};
  ${p => !p.disable && 'cursor: pointer'};
  display: flex;
  align-items: center;
  ${p => !p.fullSize && 'max-width: 380px'};
  outline: none;
  padding: 0 2.5rem 0 0.75rem;
  position: relative;
  transition: all 300s ease-out;
  width: 100%;
  opacity: ${p => (p.disable ? 0.65 : 1)};
`;

const SelectItem = styled.li(p => ({
  color: p.theme.colors.purple80,
  padding: '0.5rem 0.75rem',
  display: 'flex',
  alignItems: 'center',
  ...p.theme.text.regular14,
  transition: 'all 150ms ease-out',

  ...(p.readOnly && {
    span: {
      opacity: 0.6
    }
  }),
  ...(!p.readOnly && {
    ':hover, :focus': {
      outline: 'none',
      backgroundColor: p.theme.colors.purpleTrans5,
      span: {
        color: p.danger ? p.theme.colors.red100 : p.theme.colors.green100
      },
      cursor: 'pointer'
    }
  })
}));

const SelectMenu = styled.ul(p => ({
  margin: 0,
  width: '100%',
  padding: '1rem',
  display: 'none',
  backgroundColor: p.theme.colors.white100,
  borderRadius: '0.25rem',
  boxShadow: '0 0.25rem 2.5rem 0 rgba(0, 0, 0, 0.1)',
  listStyle: 'none',
  maxHeight: 0,
  overflow: 'hidden',
  ...(p.open && {
    display: 'block',
    maxHeight: p.maxheight || '20rem',
    overflowY: 'auto'
  })
}));
