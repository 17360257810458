import React from 'react';

import yes from 'shared/static/images/ui_yes.svg';
import defYes from 'shared/static/images/ui_def-yes.svg';
import no from 'shared/static/images/ui_no.svg';
import defNo from 'shared/static/images/ui_def-no.svg';

export const getAverageRatingForReviews = (reviews, options = {}) => {
  if (reviews.length === 0) return options.rating ? 0 : '-';

  const averages = reviews.map(r => {
    switch (r.doesThink) {
      case 'S':
        return 2;
      case 'Y':
        return 1;
      case 'N':
        return -1;
      case 'D':
        return -2;
      default:
        return 0;
    }
  });
  const avg = averages.reduce((a, b) => a + b, 0) / reviews.length;
  if (options.rating) return avg;

  if (avg <= -1.5) return options.letter ? 'D' : getTextForRating('D');
  if (avg <= 0) return options.letter ? 'N' : getTextForRating('N');
  if (avg >= 1.5) return options.letter ? 'S' : getTextForRating('S');
  return options.letter ? 'Y' : getTextForRating('Y');
};

export const getIconForRating = rating => {
  if (rating === 'S') return <img src={defYes} alt="strong yes" />;
  if (rating === 'Y') return <img src={yes} alt="yes" />;
  if (rating === 'N') return <img src={no} alt="no" />;
  if (rating === 'D') return <img src={defNo} alt="definite no" />;
  return null;
};

export const getTextForRating = rating => {
  if (rating === 'S') return 'helpers.strongyes';
  if (rating === 'Y') return 'helpers.yes';
  if (rating === 'N') return 'helpers.no';
  if (rating === 'D') return 'helpers.defno';
  return '-';
};
