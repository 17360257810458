import styled, { css } from 'styled-components/macro';

export const Error = styled.span`
  color: ${p => p.theme.errorColor};
  font-size: 12px;
`;

export const DropdownDisplay = styled.span`
  padding: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  line-height: 18px;
  color: ${p => p.theme[p.variant] || '#757575'};
`;

export const MultiDropdownDisplay = styled.span`
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  > div:first-child {
    margin-right: 10px;
  }
  font-size: 16px;
  color: ${p => p.theme[p.variant] || '#757575'};
`;

export const HelperText = styled.div`
  line-height: 20px;
  font-size: 12px;
  color: ${p => p.theme[p.variant] || '#757575'};
  opacity: 0.65;
  padding: 0 10px;
`;

export const DropdownContainer = styled.div`
  z-index: 110;
  width: 100%;
  max-width: ${p => (p.fullSize ? '100%' : '380px')};
`;

export const DropdownTitle = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: calc(100% - 30px);
`;

export const DropdownTitleContainer = styled.div`
  z-index: 5;
  background-color: ${p => p.bgColor || 'rgba(0, 0, 0, 0.04)'};
  border-style: solid;
  border-width: 1px;
  border-color: ${p => (p.error ? p.theme.errorColor : p.borderColor || 'transparent')};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${p => p.theme[p.variant] || '#757575'};
  ${p => !p.disable && 'cursor: pointer'};
  height: ${p => p.height || '36px'};
  display: flex;
  align-items: center;
  line-height: 36px;
  ${p => !p.fullSize && 'max-width: 380px'};
  outline: none;
  padding: 0 12px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 100%;
  opacity: ${p => (p.disable ? 0.65 : 1)};

  :after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -2px;
    border-width: 5px 4px 0 4px;
    border-style: solid;
    border-color: #81839b transparent;
    opacity: 0.65;
  }
`;

export const DropdownItem = styled.li`
  opacity: 0.8;
  padding: 4px 0px;
  line-height: 20px;
  min-height: 36px;
  transition: all 0.15s ease-out;
  display: flex;
  align-items: center;

  ${p =>
    p.readOnly
      ? css`
          span {
            color: #6b6b6b !important;
          }
          opacity: 0.6;
          background-color: #fff;
        `
      : css`
          :hover {
            span {
              color: ${p => (p.danger ? '#d0021b' : p.theme.primaryColor)};
            }
            background-color: rgba(0, 0, 0, 0.04);
            cursor: pointer;
          }
        `};
`;

export const Dropdown = styled.ul`
  margin: 0;
  width: 100%;
  padding: 10px;
  display: none;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.1);
  list-style: none;

  max-height: 0;
  overflow: hidden;

  ${p =>
    p.open &&
    css`
      display: block;
      max-height: ${p.maxHeight || '320px'};
      overflow-y: auto;
    `};
`;
