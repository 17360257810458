import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { clone, getSnapshot } from 'mobx-state-tree';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import useMedia from 'shared/hooks/useMedia';

import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { Button, ButtonGroup } from 'components/Button';
import { FixedBottomActions } from 'components/FixedBottomActions';
import { FormLabel } from 'components/Text';
import { IconPlus } from 'components/icons/IconPlus';
import { Input } from 'components/Input';
import { FormContainer } from './styles';
import TaskList from './TaskList';

const isSameMobx = (a, b) => {
  if (a && !b) return false;
  if (!a && b) return false;

  return JSON.stringify(getSnapshot(a)) !== JSON.stringify(getSnapshot(b));
};

function TasklistForm({ selected }) {
  const { templateStore, api, t, notify, router, theme } = useStore();
  const [editing, setEditing] = useState(clone(selected));
  const mobile = useMedia(`(max-width: ${theme.bp.md})`);

  function dirty() {
    return isSameMobx(selected, editing);
  }

  function cancel() {
    if (selected.id === -1) {
      router.push('/templates/tasks');
      templateStore.tasklist.clearSelected();
      templateStore.tasklist.removeNew();
      return;
    }
    setEditing(clone(selected));
  }

  function onDragEnd({ source, destination }) {
    if (!destination || source.index === destination.index) {
      return;
    }
    editing.moveItem(source.index, destination.index);
  }

  async function save() {
    const { tasklist } = templateStore;
    const { id, ...rest } = getSnapshot(editing);

    if (id !== -1) {
      const res = await api.templates.updateTasklist(id, rest);
      if (!res.error) {
        notify('success', t('company.templates.update.success'));
        selected.update(editing.toJSON());
        setEditing(clone(selected));
      } else {
        notify('error', t('company.templates.update.error'));
      }
    } else {
      const res = await api.templates.createTasklist(rest);
      if (!res.error) {
        notify('success', t('company.templates.create.success'));
        tasklist.clearSelected();
        tasklist.removeNew();
        tasklist.addTasklistTemplate(res.data);
        router.replace(`/templates/tasks/${res.data.id}`);
      } else {
        notify('error', t('company.templates.create.error'));
      }
    }
  }

  const showActionButtons = editing.id === -1 || dirty();
  return (
    <FormContainer>
      <FormLabel>{t('company.templates.tasks.label.title')}</FormLabel>
      <Input name="title" value={editing.name} onChange={editing.setName} />
      <Box mt="16">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => <TaskList items={editing.items} provided={provided} />}
          </Droppable>
        </DragDropContext>
      </Box>
      <ButtonGroup right mt="24">
        <Button gray fullWidth={mobile} onClick={editing.addTask} icon={IconPlus}>
          {t('company.templates.tasks.create')}
        </Button>
      </ButtonGroup>

      {showActionButtons && (
        <FixedBottomActions>
          <Button gray onClick={cancel}>
            {t('company.templates.button.cancel')}
          </Button>
          <Button onClick={save}>{t('company.templates.button.submit')}</Button>
        </FixedBottomActions>
      )}
    </FormContainer>
  );
}
export default observer(TasklistForm);
