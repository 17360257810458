import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const BreadCrumb = observer(({ children }) => {
  const nonNullChildren = React.Children.toArray(children).filter(Boolean);
  const newChildren = nonNullChildren.map((child, idx) => {
    return (
      <React.Fragment key={idx}>
        {child}
        {idx < nonNullChildren.length - 1 && <BreadCrumbSeparator>/</BreadCrumbSeparator>}
      </React.Fragment>
    );
  });
  return <BreadCrumbContainer>{newChildren}</BreadCrumbContainer>;
});

export const BreadCrumbPath = observer(({ active, children, link }) => {
  return (
    <>
      <BreadCrumbEntry active={active} hasLink={link !== undefined}>
        {link && <Link to={link}>{children}</Link>}
        {!link && children}
      </BreadCrumbEntry>
    </>
  );
});

const BreadCrumbContainer = styled.div(p => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...p.theme.text.bold18
}));

const BreadCrumbEntry = styled.div(p => ({
  textTransform: 'uppercase',
  ...p.theme.text.bold18,
  color: p.active ? p.theme.colors.green100 : p.theme.colors.purple50,
  transition: 'color 150ms ease',
  ...(p.hasLink && {
    ':hover': {
      cursor: 'pointer',
      color: p.theme.colors.purple70
    }
  })
}));

const BreadCrumbSeparator = styled.div(p => ({
  color: p.theme.colors.purpleTrans20,
  margin: '0 0.5rem'
}));
