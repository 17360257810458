import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTextEditorState } from './TextEditor';

export const TextEditorBoxStyle = styled.div`
  position: relative;
  display: inline-block;
  padding: 1rem 0.75rem;
  width: 100%;
  max-width: 100%;
  border-radius: 0.25rem;
  background-color: ${p => p.theme.colors.purpleTrans5};
  border: 1px solid transparent;
  color: ${p => p.theme.colors.textDark};
  transition: all 100ms ease-in;

  ${p =>
    p.isFocused &&
    css`
      border-color: ${p.theme.colors.green100};
      background-color: ${p => p.theme.colors.white100};
    `};

  ${p =>
    !p.readOnly &&
    css`
      .public-DraftEditor-content {
        min-height: 100px;
      }
      :hover {
        border-color: ${p => p.theme.colors.green100};
      }
    `};

  ${p =>
    p.error &&
    css`
      border-color: ${p.theme.colors.red100};
    `};

  .mention-confirmed {
    background-color: rgba(0, 0, 0, 0.06);
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
  }
`;

export const TextEditorBox = ({ children, component: Component = TextEditorBoxStyle, ...rest }) => {
  const { isFocused, readOnly, error, onFocus, onBlur } = useTextEditorState();
  return (
    <Component
      isFocused={isFocused}
      readOnly={readOnly}
      error={error}
      onClick={e => e.preventDefault()}
      onFocus={e => {
        if (!isFocused) {
          onFocus();
        }
      }}
      onBlur={e => {
        if (isFocused) {
          onBlur();
        }
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};
