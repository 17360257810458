import uuid4 from 'uuid/v4';
import { sortBy } from '.';

export const removeJobIdFromQuestions = questions => {
  return questions.map(question => {
    const rx = new RegExp(/\w+--/, 'g');
    return { ...question, id: question.id.replace(rx, '') };
  });
};

export const ensureQuestionsHaveIds = form => {
  if (!form || !form.sections) return { sections: [] };
  return {
    sections: form.sections.map(section => ({
      ...section,
      id: section.id || uuid4(),
      questions: section.questions.map(question => ({
        ...question,
        id: question.id || uuid4()
      }))
    }))
  };
};

// Use this function for backwards-incompatible migrations.
export const transformJob = job => {
  let j = { ...job };

  if (job.followers) {
    j.followers = j.followers.map(objectToReference);
  }

  if (job.applications) {
    delete j.applications;
  }

  if (job.languages) {
    j.languages = job.languages.map(jl => ({
      ...jl,
      shortDescriptionHtml: jl.shortDescriptionHtml || job.shortDescription || '',
      form: ensureQuestionsHaveIds(jl.form)
    }));
  }

  // Pluck stuff we dont want to apply to the model (because we are computing these).
  // We can stop doing this once we've removed all of these from the job serializer.
  const {
    shortDescription,
    title,
    description,
    location,
    form,
    isExpired,
    numApplications,
    ...rest
  } = j;
  return rest;
};

export const transformApplication = application => {
  if (application.questions && application.questions.length > 0) {
    application.questions = removeJobIdFromQuestions(application.questions);
  }

  if (application.messages) {
    const messages = sortBy(application.messages, 'id', 'desc');
    let mess = [];
    let atts = [];
    messages.forEach(message => {
      if (message.messageType === 'attachment') {
        atts.push(message.attachment);
      } else {
        mess.push({ ...message, attachments: [...atts] });
        atts = [];
      }
    });
    application.messages = mess;
    application.numMessages = mess.length;
  }

  if (application.attachments) {
    application.numAttachments = application.attachments.filter(att => att.user !== null).length;
  }

  if (application.taskListItems) {
    if (application.taskListItems.length === 1) {
      // length 1 means [{total: X, checked: Y}]
      const { total, checked } = application.taskListItems[0];
      application.taskListItems = total > 0 ? [checked, total] : [];
    } else if (application.taskListItems.length === 2) {
      // length 2 means [X, Y]
      const [checked, total] = application.taskListItems;
      application.taskListItems = total > 0 ? [checked, total] : [];
    }
  }

  if (application.followers && application.followers.length > 0) {
    application.followers = application.followers.map(objectToReference);
  }

  if (application.chat && application.chat.length > 0) {
    application.chat = application.chat.map(transformObjectWithUser);
    application.numNotes = application.chat.length;
  }

  if (application.numAdminNotes && application.numAdminNotes > 0) {
    application.numNotes = application.numNotes + application.numAdminNotes;
  }

  if (application.reviews && application.reviews.length > 0) {
    application.reviews = application.reviews.map(transformObjectWithUser);
    application.numReviews = application.reviews.filter(rew => rew.doesThink !== '').length;
  }

  if (application.referredBy) {
    application.referredBy = objectToReference(application.referredBy);
  }
  if (application.form) {
    application.form = ensureQuestionsHaveIds(application.form);
  }
  delete application.picture;
  return application;
};

export const transformObjectWithUser = obj => {
  if (obj.user) {
    if (typeof obj.user === 'number') return obj;
    return {
      ...obj,
      user: objectToReference(obj.user)
    };
  }
  return obj;
};

export const objectToReference = model => {
  if (typeof model === 'number') return model;
  return model.id;
};

export const transformTemplateContent = template => {
  if (!template.contentHtml) return template;
  const replaced = template.contentHtml
    .replace(/\[name\]/, '{{ applicant_name }}')
    .replace(/\[job_title\]/, '{{ job_name }}')
    .replace(/\[first_name\]/, '{{ applicant_first_name }}')
    .replace(/\[last_name\]/, '{{ applicant_last_name }}')
    .replace(/\[sender_name\]/, '{{ sender_name }}');
  return { ...template, contentHtml: replaced };
};
