import { types as t, applySnapshot, flow, destroy } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';
import { EmailTemplate } from 'models/templates';
import { LoadingState } from 'models/ui';
import { transformTemplateContent } from 'helpers';

export const EmailTemplateStore = t
  .model('EmailTemplateStore', {
    items: t.array(EmailTemplate),
    state: LoadingState
  })
  .views(withEnv)
  .actions(self => ({
    addTemplate(template) {
      self.items.push(template);
    },
    deleteTemplate(template) {
      const item = self.items.find(t => t.id === template);
      if (item) {
        destroy(item);
      }
    },
    load: flow(function*() {
      self.state = 'loading';
      const res = yield self.env.api.companies.loadTemplates();
      if (!res.error) {
        const data = res.data
          .filter(template => {
            if (!template.contentHtml) return false;
            return !template.contentHtml.startsWith('<!doctype html>');
          })
          .map(transformTemplateContent);
        applySnapshot(self.items, data);
        self.state = 'loaded';
      } else {
        self.state = 'error';
      }
    })
  }));
