import styled, { css } from 'styled-components/macro';
import { TextEditorBoxStyle } from './TextEditor';

export const NoteTextEditorBox = styled(TextEditorBoxStyle)`
  background-color: ${p => p.theme.colors.orange10};
  border-color: ${p => p.theme.colors.orange10};

  ${p =>
    p.readOnly &&
    css`
      width: auto;
      border-color: ${p.theme.colors.orange100};
      border-radius: 4px;
      .public-DraftEditor-content {
        min-height: 0;
      }
    `};
  ${p =>
    !p.readOnly &&
    css`
      :hover {
        border-color: ${p => p.theme.colors.orange100};
      }
    `};

  ${p =>
    p.isFocused &&
    css`
      border-color: ${p.theme.colors.orange100};
    `};
`;
