import { types as t } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

export const PermissionStore = t
  .model('PermissionStore', {})
  .views(withEnv)
  .views(self => ({
    get role() {
      return self.env.me.role;
    },
    get verifier() {
      return createRoleVerifier(self.permissions, self.env);
    },
    get permissions() {
      return rules[self.role];
    }
  }))
  .actions(self => ({
    has: (perm, ...props) => self.verifier(perm, props)
  }));

const rules = {
  admin: {
    static: [
      'applicants:view',
      'application:delete',
      'application:grant-access',
      'application:revoke-access',
      'application:view-options',
      'application:onboarding',
      'application:edit-tasklist',
      'company:view-settings',
      'company:view-templates',
      'company:edit-templates',
      'company:apps',
      'dashboard:view',
      'job:add-recruiter',
      'job:duplicate',
      'job:view-team',
      'job:view-integrations',
      'job:create',
      'job:options',
      'job:view',
      'job:edit-stage-name',
      'job:delete',
      'slack:auth',
      'team:create-user',
      'team:edit-user',
      'team:view',
      'team:view-detail'
    ],
    dynamic: {}
  },
  recruiter: {
    static: [
      'applicants:view',
      'application:edit-tasklist',
      'application:onboarding',
      'application:view-options',
      'dashboard:view',
      'job:edit-stage-name',
      'job:options',
      'job:view-integrations',
      'job:view-team',
      'job:view',
      'sharing:view',
      'slack:auth',
      'team:view-detail',
      'team:view'
    ],
    dynamic: {}
  },
  limited: {
    static: [
      'applicants:view',
      'application:edit-tasklist',
      'application:onboarding',
      'application:view-options',
      'dashboard:view',
      'job:edit-stage-name',
      'job:options',
      'job:view-integrations',
      'job:view-team',
      'job:view',
      'sharing:view',
      'slack:auth',
      'team:view-detail',
      'team:view'
    ],
    dynamic: {}
  },
  user: {
    static: ['sharing:view', 'slack:auth', 'team:view-detail'],
    dynamic: {}
  }
};

function createRoleVerifier(permissions, store) {
  return function check(action, props) {
    const staticPermissions = permissions.static;
    if (staticPermissions && staticPermissions.includes(action)) {
      return true;
    }

    const dynamicPermissions = permissions.dynamic;
    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (!permissionCondition) {
        return false;
      }

      return permissionCondition(store, props);
    }
    return false;
  };
}

export const ROLES = {
  user: 1,
  limited: 2,
  recruiter: 3,
  admin: 4
};
