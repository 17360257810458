import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { FormError } from 'components/Text';

const StyledTextArea = styled.textarea(p => ({
  resize: p.resize ? 'vertical' : 'none',
  width: '100%',
  borderRadius: '0.25rem',
  transition: 'all 100ms ease-out',

  backgroundColor: p.theme.colors.purpleTrans5,
  borderWidth: '0.0625rem',
  borderStyle: 'solid',
  borderColor: p.error ? p.theme.colors.red100 : 'transparent',
  ...p.theme.text.regular16,
  color: p.theme.colors.purple80,
  padding: '0.5rem 0.75rem',
  height: '8rem',
  ...(p.disabled && { opacity: 0.65 }),
  ...(!p.disabled && {
    ':hover': {
      backgroundColor: p.theme.colors.purpleTrans10
    }
  }),
  ':focus': {
    borderColor: p.error ? p.theme.colors.red100 : p.theme.colors.green100,
    backgroundColor: p.theme.colors.white100,
    outline: 'none'
  }
}));

export const TextArea = observer(
  (props, ref) => {
    const { onChange, focusOnMount, ...rest } = props;
    const localRef = React.useRef();
    const onUpdate = React.useCallback(e => void onChange(e.target.value), [onChange]);

    React.useEffect(() => {
      if (focusOnMount) {
        if (ref) {
          ref.current.focus();
        } else {
          localRef.current.focus();
        }
      }
    }, [focusOnMount, ref]);
    return (
      <>
        <StyledTextArea ref={ref || localRef} onChange={onUpdate} spellCheck="false" {...rest} />
        <FormError>{props.error}</FormError>
      </>
    );
  },
  { forwardRef: true }
);
