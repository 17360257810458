import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { abbreviation } from 'shared/utils/strings';

import { UserAvatar } from 'components/UserAvatar';
import { Box } from 'components/Box';
import { Text } from 'components/Text';
import { IconRating20 } from 'components/icons/IconRating20';
import { IconChat20 } from 'components/icons/IconChat20';
import { IconNotes20 } from 'components/icons/IconNotes20';
import { IconFiles20 } from 'components/icons/IconFiles20';
import { IconAssignee20 } from 'components/icons/IconAssignee20';
import { IconTasklist20 } from 'components/icons/IconTasklist20';
import { IconOnboarding } from 'components/icons/IconOnboarding';
import { ApplicationLabels } from 'components/ApplicationLabels';
import { Tooltip } from 'components/Tooltip';
import { getVisibleHeight } from 'shared/utils/browser';

export const CardListItem = observer(({ application, date, selected, showLabels = true }) => {
  const {
    isHired,
    isRejected,
    isRetracted,
    name,
    picture,
    referredBy,
    assignedTo,
    taskListItems,
    numAttachments,
    numMessages,
    numNotes,
    numReviews
  } = application;

  const isNew = application.userRead && !application.userRead.newApplication;
  const tip = `card-${application.id}`;

  return (
    <Card selected={selected}>
      <Tooltip id={tip} />
      <UserAvatar
        src={picture}
        name={name}
        size="md"
        backgroundColor={isRejected ? 'red100' : 'green100'}
      />
      <CenterSection>
        <Name>{name}</Name>
        <Text type="semibold12" color="purple50">
          {date}
        </Text>
        <Box flex wrap>
          {numReviews > 0 && (
            <SectionContent
              data-for={tip}
              data-tip={`Applicant has been rated ${numReviews} time(s).`}
              active={application.userRead && !application.userRead.ratings}
              icon={IconRating20}
              value={numReviews}
            />
          )}
          {application.job.useMessages && numMessages > 0 && (
            <SectionContent
              data-for={tip}
              data-tip={`Applicant has ${numMessages} message(s).`}
              active={application.userRead && !application.userRead.messages}
              icon={IconChat20}
              value={numMessages}
            />
          )}
          {numNotes > 0 && (
            <SectionContent
              data-for={tip}
              data-tip={`Applicant has ${numNotes} note(s).`}
              active={application.userRead && !application.userRead.notes}
              icon={IconNotes20}
              value={numNotes}
            />
          )}
          {numAttachments > 0 && (
            <SectionContent
              data-for={tip}
              data-tip={`Applicant has ${numAttachments} attached file(s).`}
              active={application.userRead && !application.userRead.files}
              icon={IconFiles20}
              value={numAttachments}
            />
          )}
          {assignedTo && (
            <SectionContent
              data-for={tip}
              data-tip={`Applicant has been assigned to ${assignedTo.name}.`}
              active={application.userRead && !application.userRead.assignedTo}
              icon={IconAssignee20}
              value={abbreviation(assignedTo.name)}
            />
          )}
          {taskListItems && taskListItems.length > 0 && taskListItems[1] > 0 && (
            <SectionContent
              data-for={tip}
              data-tip={`The onboarding tasklist for this applicant has ${Number(taskListItems[1]) -
                Number(taskListItems[0])} unfinished tasks.`}
              active={application.userRead && !application.userRead.taskList}
              icon={IconTasklist20}
              value={`${taskListItems[0]} / ${taskListItems[1]}`}
            />
          )}
        </Box>
        <Labels show={showLabels && (referredBy || isRejected || isNew || isRetracted)}>
          <ApplicationLabels application={application} />
        </Labels>
      </CenterSection>
      {isHired && (
        <ApplicantOnboarding
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            application.goToOnboarding();
          }}
        >
          <IconOnboarding />
        </ApplicantOnboarding>
      )}
      <ApplicantStatus hired={isHired} rejected={isRejected} />
    </Card>
  );
});

export const SectionContent = ({ icon: Icon, value, active, ...rest }) => {
  return (
    <ContentContainer {...rest}>
      <ContentIcon>
        <Icon />
      </ContentIcon>
      <ValueContainer active={active}>{value}</ValueContainer>
    </ContentContainer>
  );
};

export const CardListItems = props => {
  const ref = React.useRef();
  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      if (ref && ref.current) {
        const ht = getVisibleHeight() - ref.current.getBoundingClientRect().top - 40;
        ref.current.style.maxHeight = ht + 'px';
      }
    });
  });
  return <StyledCardListItems ref={ref} {...props} />;
};

export const CardList = styled.div(p => ({
  position: 'relative',
  flexShrink: 0,
  paddingBottom: p.theme.spacing.m8,
  ...(p.noHeader && {
    paddingTop: p.theme.spacing.m8
  }),
  backgroundColor: p.theme.colors.purpleTrans5,
  borderRadius: '0.25rem',
  borderColor: 'transparent',
  borderWidth: '0.125rem',
  borderStyle: 'dashed',
  width: '17.5rem',
  height: 'auto',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',

  ...(p.transparent && {
    backgroundColor: 'transparent',
    borderColor: p.theme.colors.purpleTrans10
  })
}));

export const CardListHeader = styled.div(p => ({
  minHeight: '3.5rem',
  padding: '0.5rem 0.5rem 0.5rem 1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const StyledCardListItems = styled.div(p => ({
  position: 'relative',
  '> *:not(:last-child)': {
    marginBottom: p.theme.spacing.m4
  },
  padding: '0 0.5rem',
  flexGrow: 1,
  overflowY: 'auto'
}));

const CenterSection = styled(Box).attrs({
  ml: '8',
  grow: true
})(p => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flexShrink: 1
}));

export const Name = styled(Text).attrs({
  type: 'bold14',
  color: 'purple80'
})({
  lineHeight: '1rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const ContentContainer = styled.div(p => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: p.theme.spacing.m4,
  marginTop: p.theme.spacing.m4
}));

const ContentIcon = styled.div(p => ({
  color: p.theme.colors.purple50,
  height: '1.5rem',
  width: '1.5rem'
}));

const ValueContainer = styled.div(p => ({
  minHeight: '1rem',
  minWidth: '1rem',
  borderRadius: '0.125rem',
  backgroundColor: p.theme.colors.purpleTrans10,
  color: p.theme.colors.purple80,
  ...p.theme.text.semibold10,
  padding: '0.125rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(p.active && {
    color: p.theme.colors.white100,
    backgroundColor: p.theme.colors.green100
  })
}));

export const Card = styled.div(p => ({
  backgroundColor: p.theme.colors.white100,
  borderRadius: '0.25rem',
  display: 'flex',
  ...(!p.selected && {
    ':hover': {
      transform: 'translateY(-0.0625rem)',
      boxShadow: '0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.08)',
      cursor: 'pointer'
    }
  }),
  transition: 'all 200ms ease-out',
  padding: '0.375rem 0.125rem 0.375rem 0.375rem',
  borderWidth: '0.125rem',
  borderStyle: 'solid',
  borderColor: 'transparent',
  ...(p.selected && { borderColor: p.theme.colors.green100 })
}));

const ApplicantOnboarding = styled.div(p => ({
  color: p.theme.colors.green100,
  borderRadius: '0.25rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '2rem',
  width: '2rem',
  flexShrink: 0,
  ':hover': {
    backgroundColor: p.theme.colors.purpleTrans5,
    cursor: 'pointer'
  }
}));

const ApplicantStatus = styled.div(p => ({
  borderRadius: '0.25rem',
  minWidth: '0.25rem',
  marginLeft: '0.25rem',
  ...(p.hired && {
    backgroundColor: p.theme.colors.green100
  }),
  ...(p.rejected && {
    backgroundColor: p.theme.colors.red100
  })
}));

const Labels = styled.div(p => ({
  display: 'none',
  ...(p.show && {
    marginTop: p.theme.spacing.m4,
    display: 'flex'
  })
}));
