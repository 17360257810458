import React from 'react';
import { useLocation } from 'react-router-dom';
import { getSnapshot } from 'mobx-state-tree';
import { action, computed, decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { removeJobIdFromQuestions, stageDisplayForJob } from 'helpers';
import { ApplicationForm } from 'models/applications';
import { useStore } from 'models/Provider';
import { PageContent } from 'components/Page';
import { H2, FormLabel, Text, TextLabel } from 'components/Text';
import { Box } from 'components/Box';
import { Select } from 'components/Select';
import { Checkbox } from 'components/Checkbox';
import { Separator } from 'components/Separator';
import { FixedBottomActions } from 'components/FixedBottomActions';
import { Button } from 'components/Button';
import { IconArrowForward } from 'components/icons/IconArrowForward';
import { AnswerCard, iconForQuestionType, QuestionAnswer } from 'components/QuestionAnswer';
import { Loading } from 'components/Loading';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { Input } from 'components/Input';
import { TextArea } from 'components/TextArea';
import { ApplicationPageHeader } from './ApplicationPageHeader';
import { JobSearch } from 'components/JobSearch';

export const ApplicationMoveCopy = observer(({ application, move }) => {
  const { companies, current, api, notify, t } = useStore();
  const { state } = useLocation();

  const goBack = () => {
    window.location.href = state.onCloseUrl;
  };

  const commit = async () => {
    model.setSaving(true);
    if (model.move) {
      const data = model.prepMoveData();
      const res = await api.applications.move(application.id, data);
      if (!res.error) {
        const oldId = application.job.id;
        const newId = model.job.id;
        notify('success', t('application.move.success', application.name, model.job.title));
        window.location.href = state.onCloseUrl.replace(`/jobs/${oldId}`, `/jobs/${newId}`);
      } else {
        notify('error', t('application.copy.error', application.name, model.job.title));
      }
      return;
    }
    const data = model.prepCopyData();
    const res = await api.applications.create(data);
    if (!res.error) {
      notify('success', t('application.copy.success', application.name, model.job.title));
      goBack();
      return;
    } else {
      notify('error', t('application.copy.error', application.name, model.job.title));
    }
    model.setSaving(false);
  };

  React.useLayoutEffect(() => {
    model.init({ current, companies, api, application, move });
    application.loadCv();
    application.loadCoverLetter();
    return () => {
      model.reset();
    };
  }, [api, application, companies, current, move]);

  return (
    <div>
      {model.saving && <Loading fixed />}
      <ApplicationPageHeader application={application} />
      <PageContent alwaysScroll bottomGap={80}>
        {model.step === 0 && <Step0 application={application} goBack={goBack} commit={commit} />}
        {model.step === 1 && <Step1 application={application} commit={commit} />}
      </PageContent>
    </div>
  );
});

const Step1 = observer(({ application, commit }) => {
  const { t } = useStore();
  return (
    <>
      <Step1Container>
        <Step1Section>
          <Box flex justifyContent="space-between">
            <Text type="h2" color="purple80">
              {t('application.movecopy.current.resp')}
            </Text>
            <Box color="green100" h="24" w="24">
              <IconArrowForward />
            </Box>
          </Box>
          <Separator mt="24" mb="24" />
          {application.formQuestions.map(q => {
            return <QuestionAnswer q={q} application={application} key={q.id || q.title} />;
          })}
        </Step1Section>
        <Step1Section>
          <Text type="h2" color="green100">
            {t('application.movecopy.new.resp')}
          </Text>
          <Separator mt="24" mb="24" />
          {model.newForm.sections.map(section => {
            return section.questions.map(question => {
              return (
                <React.Fragment key={question.id || question.title}>
                  <Box flex alignItems="center" color="purple80" mt="16">
                    <Box w="24" h="24">
                      {iconForQuestionType(question.type)}
                    </Box>
                    <TextLabel ml="8">{question.title}</TextLabel>
                  </Box>
                  <Box mt="8">
                    <RenderEditableAnswer question={question} />
                  </Box>
                </React.Fragment>
              );
            });
          })}
        </Step1Section>
      </Step1Container>
      <FixedBottomActions>
        <Button disabled={model.saving} gray onClick={() => model.setStep(0)}>
          {t('application.movecopy.button.back')}
        </Button>
        <Button disabled={model.saving} onClick={commit}>
          {model.move
            ? t('application.movecopy.button.move')
            : t('application.movecopy.button.copy')}
        </Button>
      </FixedBottomActions>
    </>
  );
});

const RenderEditableAnswer = observer(({ question }) => {
  const { t } = useStore();
  switch (question.type) {
    case 'one_line':
      if (!question.value && model.editing !== question.id) {
        return (
          <PlaceholderBox onClick={() => model.edit(question.id)}>
            {t('application.movecopy.addresp')}
          </PlaceholderBox>
        );
      }
      return (
        <Input
          disabled={model.saving}
          onBlur={model.resetEditing}
          onChange={question.setValue}
          focusOnMount
          value={question.value || ''}
        />
      );
    case 'multi_line':
      if (!question.value && model.editing !== question.id) {
        return (
          <PlaceholderBox onClick={() => model.edit(question.id)}>
            {t('application.movecopy.addresp')}
          </PlaceholderBox>
        );
      }
      return (
        <TextArea
          disabled={model.saving}
          onBlur={model.resetEditing}
          onChange={question.setValue}
          focusOnMount={!Boolean(question.value)}
          value={question.value || ''}
        />
      );
    case 'dropdown':
      return (
        <AnswerCard>
          <CheckboxContainer>
            {question.options.map(o => (
              <Checkbox
                disabled={model.saving}
                onClick={() => question.toggleOption(o.value)}
                key={o.value}
                checked={question.value === o.value}
                label={o.display}
              />
            ))}
          </CheckboxContainer>
        </AnswerCard>
      );
    case 'multidropdown':
      const value = question.value || [];
      return (
        <AnswerCard>
          <CheckboxContainer>
            {question.options.map(o => (
              <Checkbox
                disabled={model.saving}
                onClick={() => question.toggleOption(o.value)}
                key={o.value}
                checked={value.includes(o.value)}
                label={o.display}
              />
            ))}
          </CheckboxContainer>
        </AnswerCard>
      );
    case 'attachment':
      return (
        <PlaceholderBox
          onClick={() => {
            document.getElementById('attachment-' + question.id).click();
          }}
        >
          <input
            disabled={model.saving}
            onChange={e => model.changeFile(question.id, e)}
            type="file"
            id={'attachment-' + question.id}
            style={{ display: 'none' }}
          />
          {model.files[question.id] ? (
            <FileBox>
              <Box flex alignItems="center" style={{ overflow: 'hidden', height: '2.5rem' }}>
                <Text type="regular14" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {model.files[question.id].name}
                </Text>
              </Box>
            </FileBox>
          ) : (
            <span>{t('application.movecopy.addfile')}</span>
          )}
        </PlaceholderBox>
      );
    default:
      return null;
  }
});

const Step0 = observer(({ application, move, goBack, commit }) => {
  const { t, openModal } = useStore();

  return (
    <>
      <Step0Container>
        <H2>{model.move ? t('application.move.title') : t('application.copy.title')}</H2>

        {model.companies.length > 1 && (
          <Box mt="24">
            <FormLabel>{t('application.movecopy.label.company')}</FormLabel>
            <Select
              disabled={model.saving}
              onChange={model.setCompany}
              defaultValue={model.company ? model.company.id : null}
              items={model.displayCompanies}
            />
          </Box>
        )}

        <Box mt={model.companies.length === 0 ? '24' : '16'}>
          <FormLabel>{t('application.movecopy.label.job')}</FormLabel>
          <JobSearch onSelect={model.setJob} />
        </Box>

        {model.job && (
          <Box mt="16">
            <FormLabel>{t('application.movecopy.label.stage')}</FormLabel>
            <Select
              disabled={model.saving}
              defaultValue={model.stage}
              onChange={model.setStage}
              key={model.stage}
              items={model.jobStages}
              title={t('application.movecopy.selectstage')}
            />
          </Box>
        )}

        <Box mt="16">
          <FormLabel>{t('application.movecopy.label.include')}</FormLabel>
          <Checkbox
            disabled={model.saving}
            onClick={() => model.toggleInclude('notes')}
            label={t('application.movecopy.label.notes')}
            checked={model.include.notes}
          />
          <Box mt="8">
            <Checkbox
              disabled={model.saving}
              onClick={() => model.toggleInclude('ratings')}
              label={t('application.movecopy.label.ratings')}
              checked={model.include.ratings}
            />
          </Box>
          <Box mt="8">
            <Checkbox
              disabled={model.saving}
              onClick={() => model.toggleInclude('files')}
              label={t('application.movecopy.label.files')}
              checked={model.include.files}
            />
          </Box>
          <Box mt="8">
            <Checkbox
              disabled={model.saving}
              onClick={() => model.toggleInclude('responses')}
              label={t('application.movecopy.label.responses')}
              checked={model.include.responses}
            />
          </Box>
        </Box>

        {model.include.responses && (
          <Text mt="16">{t('application.movecopy.responses.message')}</Text>
        )}
        <Separator mt="32" mb="32" />
        {!model.allIncludesChecked && (
          <Text color="red100">{t('application.movecopy.warning.message')}</Text>
        )}
      </Step0Container>
      <FixedBottomActions>
        <Button onClick={goBack} gray>
          {t('application.movecopy.button.cancel')}
        </Button>
        <Button
          onClick={() => {
            if (model.job && model.stage) {
              if (!model.allIncludesChecked) {
                openModal('CONFIRM_MOVE_NO_QUESTIONS', {
                  onContinue: model.include.responses ? model.tryStep1 : commit,
                  submitText: model.include.responses
                    ? 'application.confirmcopy.proc'
                    : model.move
                    ? 'application.movecopy.button.move'
                    : 'application.movecopy.button.copy'
                });
              } else {
                model.tryStep1();
              }
            } else {
              model.tryStep1();
            }
          }}
        >
          {t('application.movecopy.button.cont')}
        </Button>
      </FixedBottomActions>
    </>
  );
});

export const ConfirmNoQuestions = ({ closeModal, onContinue, submitText }) => {
  const { t } = useStore();
  const submit = async () => {
    await onContinue();
    closeModal();
  };
  return (
    <>
      <ModalHeader>{t('application.confirmcopy.title')}</ModalHeader>
      <ModalText>
        {t(
          'application.confirmcopy.main',
          Object.keys(model.include)
            .filter(key => !model.include[key])
            .map(key => t(`application.movecopy.label.${key}`))
            .join(', ')
        )}
      </ModalText>
      <Text as="p" type="bold16" color="red100">
        {t('application.confirmcopy.warn')}
      </Text>

      <ModalText as="p">{t('application.confirmcopy.cta')}</ModalText>
      <ModalActions>
        <Button gray onClick={closeModal}>
          {t('application.confirmcopy.back')}
        </Button>
        <Button onClick={submit}>{t(submitText)}</Button>
      </ModalActions>
    </>
  );
};

const trimData = application => {
  return {
    email: application.email,
    isManuallyAdded: application.isManuallyAdded,
    language: application.language,
    name: application.name,
    phone: application.phone,
    referredBy: application.referredBy,
    referredSite: application.referredSite,
    assignedTo: application.assignedTo,
    sex: application.sex,
    ssn: application.ssn,
    ssnStatus: application.ssnStatus,
    ssnRegistryResult: application.ssnRegistryResult
  };
};

class ApplicationMoveModel {
  step = 0;
  api = null;
  loadingJobs = false;
  loadingJobDetail = false;
  jobs = [];
  companies = [];
  job = null;
  company = null;
  stage = '1';
  saving = false;
  include = {
    notes: true,
    ratings: true,
    files: true,
    responses: true
  };
  application = null;
  newForm = null;
  errors = {};
  editing = null;
  values = {};
  files = {};

  get allIncludesChecked() {
    return Object.keys(this.include).every(key => this.include[key]);
  }

  get displayCompanies() {
    return this.companies.map(company => ({
      value: company.id,
      display: company.info.companyName
    }));
  }

  prepMoveData = () => {
    const data = new FormData();

    data.append('stage', this.stage);
    data.append('job', this.job.id);

    Object.keys(this.files).forEach(key => {
      data.append(key, this.files[key]);
    });

    data.append('notes', this.include.notes);
    data.append('ratings', this.include.ratings);
    data.append('attachments', this.include.files);

    if (this.include.responses) {
      data.append('form', JSON.stringify(toJS(this.newForm)));
    }

    return data;
  };

  prepCopyData = () => {
    const data = new FormData();
    const trimmed = trimData(getSnapshot(this.application));
    Object.keys(trimmed).forEach(key => {
      if (trimmed[key] !== undefined && trimmed[key] !== null) {
        data.append(key, trimmed[key]);
      }
    });

    data.append('stage', this.stage);

    Object.keys(this.files).forEach(key => {
      data.append(key, this.files[key]);
    });

    if (!this.move) {
      data.append(
        'copy_from',
        JSON.stringify({
          id: this.application.id,
          notes: this.include.notes,
          ratings: this.include.ratings,
          attachments: this.include.files
        })
      );
    }

    if (this.include.responses) {
      data.append('form', JSON.stringify(toJS(this.newForm)));
    }

    data.append('job', this.job.id);
    return data;
  };

  setSaving = val => {
    this.saving = val;
  };

  changeFile = (questionId, e) => {
    if (e.target.files.length > 0) {
      this.files[questionId] = e.target.files[0];
    } else {
      delete this.files[questionId];
    }
  };

  toggleInclude = included => {
    this.include[included] = !this.include[included];
  };

  init = async ({ companies, current, api, application, move }) => {
    this.move = move;
    this.api = api;
    this.companies = companies;
    this.company = current;
    this.application = application;
    await this.loadJobs();
  };

  get jobStages() {
    if (!this.job) return [];
    return stageDisplayForJob(this.job);
  }

  setStep = step => {
    this.step = step;
  };

  loadJobs = async () => {
    this.loadingJobs = true;
    this.jobs = [];
    const res = await this.company.api.jobs.loadMinimalWithoutArchived();
    if (!res.error) {
      this.jobs = res.data.results.filter(job => job.id !== this.application.job.id);
    }
    this.loadingJobs = false;
  };

  edit = id => {
    this.editing = id;
  };

  resetEditing = () => {
    this.editing = null;
  };

  setCompany = async id => {
    this.job = null;
    this.stage = '1';
    this.company = this.companies.find(company => company.id === Number(id));
    await this.loadJobs();
  };

  setStage = id => {
    this.stage = id;
  };

  tryMigrate = () => {
    if (!this.application.form) return;

    this.application.form.sections.forEach(section => {
      const questions = removeJobIdFromQuestions(section.questions);
      questions.forEach(question => {
        if (!question.value) return;
        this.newForm.sections.forEach(ns => {
          ns.questions.forEach(nq => {
            if (question.id === nq.id) {
              if (Array.isArray(question.value)) {
                nq.setValue([...question.value]);
              } else {
                nq.setValue(question.value);
              }
            } else if (question.title === nq.title && question.type === nq.type) {
              if (question.type === 'dropdown' || question.type === 'multidropdown') {
                if (!question.options) return;
                const allQ = question.options.every(o =>
                  nq.options.find(it => it.value === o.value)
                );
                if (question.options.length === nq.options.length && allQ) {
                  nq.setValue(question.value);
                }
              }
            }
          });
        });
      });
    });
  };

  setJob = async id => {
    this.errors.job = undefined;
    this.loadingJobDetail = true;
    const res = await this.api.jobs.loadFromDb(id);
    if (!res.error) {
      this.stage = '1';
      this.job = res.data;
      const lang = this.application.language;
      const langObj =
        res.data.languages.find(obj => obj.language === lang) || res.data.languages[0];

      const formWithoutJobIds = {
        sections: langObj.form.sections.map(section => ({
          ...section,
          questions: removeJobIdFromQuestions([...section.questions])
        }))
      };
      this.newForm = ApplicationForm.create(formWithoutJobIds);
      this.tryMigrate();
    }
    this.loadingJobDetail = false;
  };

  tryStep1 = () => {
    if (this.job === null) {
      this.errors.job = 'errors.required';
      return;
    }

    this.setStep(1);
  };

  reset = () => {
    this.saving = false;
    this.move = false;
    this.application = null;
    this.errors = {};
    this.values = {};
    this.files = {};
    this.editing = null;
    this.loadingJobs = false;
    this.loadingJobDetail = false;
    this.jobs = [];
    this.companies = [];
    this.job = null;
    this.newForm = null;
    this.company = null;
    this.stage = '1';
    this.step = 0;
    this.include.notes = true;
    this.include.ratings = true;
    this.include.files = true;
    this.include.responses = true;
  };
}

decorate(ApplicationMoveModel, {
  application: observable,
  companies: observable,
  company: observable,
  include: observable,
  job: observable,
  jobs: observable,
  loadingJobs: observable,
  loadingJobDetail: observable,
  newForm: observable,
  stage: observable,
  step: observable,
  errors: observable,
  files: observable,
  editing: observable,
  values: observable,
  move: observable,
  saving: observable,

  allIncludesChecked: computed,
  displayCompanies: computed,
  jobStages: computed,

  init: action,
  loadJobs: action,
  setQuestionValue: action,
  toggleQuestionCheckboxValue: action,
  resetEditing: action,
  edit: action,
  reset: action,
  setCompany: action,
  setJob: action,
  setStage: action,
  setStep: action,
  toggleInclude: action,
  tryStep1: action
});

const model = new ApplicationMoveModel();
window.mdl = model;

const CheckboxContainer = styled.div(p => ({
  '> div:not(:first-child)': {
    marginTop: p.theme.spacing.m8
  }
}));

const Step1Section = styled.div(p => ({
  width: '100%',
  maxWidth: '23.75rem'
}));

const Step1Container = styled.div(p => ({
  margin: 'auto',
  widh: '100%',
  display: 'flex',
  justifyContent: 'center',
  '> div:last-child': {
    marginLeft: p.theme.spacing.m24
  }
}));

const Step0Container = styled.div(p => ({
  width: '100%',
  maxWidth: '23.75rem',
  margin: 'auto'
}));

const PlaceholderBox = styled.div(p => ({
  height: '2.5rem',
  width: '100%',
  color: p.theme.colors.purple50,
  ...p.theme.text.regular14,
  border: '1px dashed ' + p.theme.colors.purpleTrans20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0.25rem',
  cursor: 'pointer'
}));

const FileBox = styled.div(p => ({
  whiteSpace: 'no-wrap',
  backgroundColor: p.theme.colors.purpleTrans5,
  padding: '0 0.75rem',
  ':hover': {
    cursor: 'pointer'
  },
  width: '100%',
  overflow: 'hidden',
  height: '2.5rem',
  textOverflow: 'ellipsis'
}));
