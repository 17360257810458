import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';

import { useStore } from 'models/Provider';
import { User } from 'models/users';
import { UserModal } from 'components/UserModal';
import { CreateUserForm } from 'components/CreateUserForm';

export const UserModalEdit = observer(({ closeModal, user }) => {
  const { jobStore, userStore, t, api, notify } = useStore();
  const [disabled, setDisabled] = useState(false);
  const [editing, setEditing] = useState(userStore.editing);
  const jobs = jobStore.notArchived;

  useEffect(() => {
    if (disabled) return;
    userStore.setEditing(User.create(getSnapshot(user)));
    userStore.editing.setFollowingJobs(user.followingJobs.toJSON());
    setEditing(userStore.editing);
  }, [user.followingJobs, disabled, user, userStore]);

  async function save() {
    setDisabled(true);
    let data = {};
    if (user.name !== editing.name) {
      data.name = editing.name;
    }
    if (user.email !== editing.email) {
      data.email = editing.email;
    }
    if (user.role !== editing.role) {
      data.role = editing.role;
    }
    if (user.addToNewJobs !== editing.addToNewJobs) {
      data.addToNewJobs = editing.addToNewJobs;
    }
    if (Object.keys(data).length > 0) {
      const res = await api.users.updateDetails(user.id, data);
      if (res.error) {
        editing.setErrors(res.data);
        setDisabled(false);
        notify('error', t('user.edit.update.errors'));
        return;
      } else {
        notify('success', t('user.edit.update.success'));
        user.setName(editing.name);
        user.setRole(editing.role);
        user.setEmail(editing.email);
        user.setAddToNewJobs(editing.addToNewJobs);
      }
    }
    await Promise.all(
      user.followingJobs.map(async job => {
        if (!editing.followingJobs.includes(job)) {
          await user.revokeJobAccess(job, true);
        }
      })
    );
    await Promise.all(
      editing.followingJobs.map(async job => {
        if (!user.followingJobs.includes(job)) {
          await user.grantJobAccess(job, true);
        }
      })
    );
    closeModal();
  }
  function isDisabled() {
    if (disabled || !editing) {
      return true;
    }
    if (
      user.name !== editing.name ||
      user.email !== editing.email ||
      user.role !== editing.role ||
      user.addToNewJobs !== editing.addToNewJobs
    ) {
      return false;
    }

    if (user.followingJobs.length === editing.followingJobs.length) {
      const all = user.followingJobs.every(job => editing.followingJobs.includes(job));
      return all;
    } else {
      return false;
    }
  }

  if (!editing) return null;

  return (
    <UserModal title={t('user.edit.heading')}>
      <CreateUserForm
        t={t}
        allowSend={false}
        user={editing}
        close={closeModal}
        jobs={jobs}
        save={save}
        errors={editing.errors}
        disabled={isDisabled()}
      />
    </UserModal>
  );
});
