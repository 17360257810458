import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { getVisibleHeight } from 'shared/utils/browser';
import { useMobile } from 'hooks/useMobile';

export const StyledPageContent = styled.div(p => ({
  padding: p.noPadding ? 0 : p.theme.spacing.m40,
  flexGrow: 1,
  flexShrink: 0,
  height: 'auto',
  overflowY: p.alwaysScroll ? 'scroll' : 'auto',
  position: 'relative',
  [p.theme.mobileQuery]: {
    padding: p.noPadding ? 0 : p.theme.spacing.m24,
    overflowY: 'visible'
  }
}));

export const PageContent = observer(props => {
  const { scroll = true, alwaysScroll = true, noPadding = false, bottomGap = 0, ...rest } = props;
  const ref = React.useRef();
  const intervalRef = React.useRef(null);
  const mobile = useMobile();

  const setHeight = React.useCallback(() => {
    if (!ref.current) return;
    const y = ref.current.getBoundingClientRect().top;
    if (y > 0) {
      const height = getVisibleHeight() - y - bottomGap;
      ref.current.style.height = height + 'px';
      window.clearInterval(intervalRef.current);
    }
  }, [bottomGap]);

  React.useLayoutEffect(() => {
    if ((!scroll && !alwaysScroll) || mobile) return;
    intervalRef.current = window.setInterval(() => {
      setHeight();
    }, 16);
  }, [alwaysScroll, scroll, setHeight, mobile]);

  return (
    <StyledPageContent {...rest} ref={ref} alwaysScroll={alwaysScroll} noPadding={noPadding} />
  );
});

export const Page = styled.div(p => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  height: '100%',
  flexGrow: 1,
  overflowX: 'auto',
  overflowY: 'hidden',
  '@media(max-width: 768px)': {
    overflowY: 'visible'
  }
}));
