import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconIntegrationContract = props => (
  <Svg {...props}>
    <g clipPath="url(#clip0)" fill="#3C3C64">
      <path d="M17.75 7.003L14 3.253l-9.85 9.85c-.1.1-.15.22-.15.36v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15l9.86-9.85zm2.96-2.96a.996.996 0 000-1.41L18.37.292a.996.996 0 00-1.41 0L15 2.252l3.75 3.75 1.96-1.96zM2 20.003h20c1.1 0 2 .9 2 2s-.9 2-2 2H2c-1.1 0-2-.9-2-2s.9-2 2-2z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h24v24H0V0z" fill="#fff" />
      </clipPath>
    </defs>
  </Svg>
);
