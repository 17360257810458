import { flow, getParent, types as t } from 'mobx-state-tree';
import { Application } from 'models/applications';
import { ApplicationSortOrder } from 'models/jobs/JobSettings';
import { LoadingState } from 'models/ui';
import { withEnv } from 'models/withEnv';
import { sortJobApplications, buildApplicationSearchQuery } from 'helpers';

const JobStage = t
  .model('JobStage', {
    id: t.identifierNumber,
    _applications: t.array(t.reference(t.late(() => Application))),
    next: t.maybeNull(t.string),
    state: t.optional(LoadingState, 'init'),
    nextState: t.optional(LoadingState, 'init'),
    count: 0
  })
  .views(withEnv)
  .views(self => ({
    get applications() {
      return sortJobApplications(self._applications, self.order);
    },
    get job() {
      return getParent(self, 3);
    },
    get stageSettings() {
      return self.job.settings.stages.find(sts => sts.stage === self.id);
    },
    get name() {
      return self.job[`stage${self.id}Name`];
    },
    get order() {
      return self.stageSettings.order;
    }
  }))
  .actions(self => ({
    removeApplication(id) {
      const idx = self._applications.findIndex(a => a.id === id);
      if (idx !== -1) {
        self.count -= 1;
        self._applications.splice(idx, 1);
      }
    },
    addApplication(id) {
      if (!self._applications.find(a => a.id === id)) {
        self.count += 1;
        self._applications.push(id);
      }
    },
    setOrder(order) {
      self.job.settings.setApplicationSort(self.id, order);
      self.load();
    },
    reset() {
      self._applications.clear();
      self.next = null;
      self.count = 0;
    },
    setFromResponseData(data, clear = false) {
      if (clear) {
        self.reset();
      }
      let appIds = [];

      data.results.forEach(a => {
        // @Migration: Remove this spread when the api stops sending
        // chat, attachments, messages and reviews.
        const { chat, attachments, messages, reviews, ...rest } = a;

        if (!self.env.applicationStore.items.has(a.id)) {
          appIds.push(a.id);
        }

        self.env.applicationStore.addOrUpdate(rest, self.job.id);
        self.addApplication(a.id);
      });
      self.count = data.count;
      self.next = data.next;

      self.job.loadPicturesForApplications(appIds);
      self.job.loadApplicationUserRead(appIds);
    },
    loadNext: flow(function*() {
      if (self.nextState === 'loading') return;
      self.nextState = 'loading';
      const res = yield self.env.api.get(self.next);
      if (!res.error) {
        self.setFromResponseData(res.data);
        self.nextState = 'loaded';
      } else {
        self.nextState = 'error';
      }
    }),
    reload: flow(function*() {
      const min = getParent(self, 2).minimumLimit;
      yield self.load(min, true);
    }),
    load: flow(function*(limit = 10, clear = false) {
      const sidebarStage = self.job.settings.sidebar.stage;
      if (sidebarStage !== 'all' && Number(sidebarStage) !== self.id) {
        self.state = 'loaded';
        return;
      }
      const { hasSomeLabel, hasSomeRating } = self.job.settings.sidebar;
      if (false === hasSomeLabel || false === hasSomeRating) {
        self.reset();
        self.state = 'loaded';
        return;
      }
      self.state = 'loading';
      const res = yield self.env.api.applications.search(
        buildApplicationSearchQuery(limit, self.order, self.job, self.id, self.env.me)
      );
      if (!res.error) {
        self.setFromResponseData(res.data, clear);
        self.state = 'loaded';
      } else {
        self.state = 'error';
      }
    }),
    async loadAll(clear = false) {
      await self.load(10000, clear);
    }
  }));

export const JobStages = t
  .model('JobStages', {
    items: t.map(JobStage),
    order: t.optional(ApplicationSortOrder, 'date-desc'),
    next: t.maybeNull(t.string),
    state: t.optional(LoadingState, 'init'),
    nextState: t.optional(LoadingState, 'init'),
    minimumLimit: t.optional(t.number, 0),
    count: 0
  })
  .views(withEnv)
  .views(self => ({
    get applications() {
      let data = [];
      self.list.forEach(st => {
        st.applications.forEach(a => data.push(a));
      });
      return sortJobApplications(data, self.order);
    },
    get job() {
      return getParent(self);
    },
    get hasApps() {
      return self.list.some(st => st._applications.length > 0);
    },
    get list() {
      return Array.from(self.items.values());
    }
  }))
  .actions(self => ({
    removeApplication(id) {
      self.list.forEach(stage => stage.removeApplication(id));
    },
    setMinimumLimit(val) {
      self.minimumLimit = val;
    },
    onSettingsUpdate() {
      const { stage } = self.job.settings.sidebar;

      if (stage === 'all') {
        self.reloadAllStages();
      } else {
        self.reloadOneStageAndResetOthers(stage);
      }
    },
    reloadOneStageAndResetOthers(id) {
      self.list.forEach(stage => {
        if (stage.id === Number(id)) {
          stage.reload();
        } else {
          stage.reset();
        }
      });
    },
    reloadAllStages() {
      self.list.forEach(stage => stage.reload());
    },
    add(id) {
      self.items.put({ id });
    },
    setOrder(order) {
      self.order = order;
      self.load();
    },
    setDataFromAllStagesRequest(data) {
      let stages = {};
      let appIds = [];
      data.results.forEach(a => {
        appIds.push(a.id);
        if (stages[a.stage] === undefined) {
          stages[a.stage] = [];
        }
        stages[a.stage].push(a);
      });
      self.count = data.count;
      self.next = data.next;

      Object.keys(stages).forEach(stage => {
        const child = self.items.get(stage);
        stages[stage].forEach(a => {
          child.addApplication(a.id);
          self.env.applicationStore.addOrUpdate(a, self.job.id);
          appIds.push(a.id);
        });
      });

      self.job.loadPicturesForApplications(appIds);
    },
    loadNext: flow(function*() {
      self.nextState = 'loading';
      const res = yield self.env.api.get(self.next);
      if (!res.error) {
        self.setDataFromAllStagesRequest(res.data);
        self.nextState = 'loaded';
      } else {
        self.nextState = 'error';
      }
    }),
    load: flow(function*() {
      self.state = 'loading';
      const res = yield self.env.api.applications.search(
        buildApplicationSearchQuery(20, self.order, self.job, undefined, self.env.me)
      );
      if (!res.error) {
        self.setDataFromAllStagesRequest(res.data);
        self.state = 'loaded';
      } else {
        self.state = 'error';
      }
    })
  }));
