import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { ChangePassword } from 'models/users';
import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { Button, ButtonGroup } from 'components/Button';
import { Input } from 'components/Input';
import { Text, FormLabel } from 'components/Text';

export const TeamDetailsPassword = observer(() => {
  const { current, t } = useStore();
  const itemRef = useRef(ChangePassword.create({}, { ...current.env, api: current.api }));
  const item = itemRef.current;
  const disable = !item.valuesSet;

  return (
    <ProfileContainer>
      <Text type="h2" color="green100">
        {t('user.changepw.heading')}
      </Text>
      <Box mt="32">
        <FormLabel>{t('user.changepw.label.old')}</FormLabel>
      </Box>
      <Input
        type="password"
        value={item.oldPassword}
        onChange={item.setOldPassword}
        error={item.oldPasswordError}
      />
      <Box mt="16">
        <FormLabel>{t('user.changepw.label.new')}</FormLabel>
      </Box>
      <Input
        type="password"
        value={item.newPassword1}
        onChange={item.setNewPassword1}
        error={
          item.newPassword1Error &&
          item.newPassword1Error.split('\n').map((item, i) => <div key={i}>{item}</div>)
        }
      />
      <Box mt="16">
        <FormLabel>{t('user.changepw.label.new2')}</FormLabel>
      </Box>
      <Input
        type="password"
        value={item.newPassword2}
        onChange={item.setNewPassword2}
        error={item.newPassword2Error}
      />
      <ButtonGroup right mt="24">
        {item.dirty && (
          <Button gray onClick={item.reset}>
            {t('user.changepw.button.cancel')}
          </Button>
        )}
        <Button disabled={disable} onClick={item.validateAndSubmit}>
          {t('user.changepw.button.submit')}
        </Button>
      </ButtonGroup>
    </ProfileContainer>
  );
});

const ProfileContainer = styled.div`
  max-width: 380px;
  margin: 0 auto;
`;
