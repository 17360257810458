import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconAccess20 = props => (
  <Svg {...props} viewBox="0 0 20 20">
    <path
      d="M9.887 14l.013-1-.013 1zm6.494-4l.975.22a.998.998 0 000-.441l-.975.22zM3.619 10l-.975-.22a1 1 0 000 .441l.975-.22zm6.494-4l.013-1-.013 1zm-.239 9c3.513.045 6.818-1.842 7.482-4.78l-1.95-.44c-.378 1.67-2.53 3.258-5.506 3.22l-.026 2zm-7.23-4.779C3.29 13.074 6.44 14.955 9.874 15l.026-2c-2.898-.037-4.939-1.6-5.306-3.22l-1.95.442zm7.482-5.22c-3.513-.046-6.818 1.84-7.482 4.78l1.95.44c.378-1.67 2.53-3.259 5.506-3.22l.026-2zm7.23 4.778C16.71 6.926 13.56 5.045 10.126 5L10.1 7c2.898.037 4.939 1.6 5.305 3.22l1.95-.442z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M10 11a2 2 0 100-4 2 2 0 000 4z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </Svg>
);
