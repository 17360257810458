import { flow, types as t } from 'mobx-state-tree';
import jwtDecode from 'jwt-decode';

import { storage, request } from 'shared/utils/browser';
import { makeApiClient } from 'api';
import { withEnv } from 'models/withEnv';
import { AuthToken } from 'models/auth';
import history from 'routing/history';

let interval;

const AuthConfig = t.model('AuthConfig', {
  authUrl: t.string,
  resetPasswordUrl: t.string,
  setPasswordUrl: t.string
});

export const AuthStore = t
  .model('AuthStore', {
    tokens: t.map(AuthToken),
    config: AuthConfig
  })
  .views(withEnv)
  .views(self => ({
    get api() {
      if (self.env.api) return self.env.api;
      return makeApiClient(request);
    },
    get tokenList() {
      return Array.from(self.tokens.values());
    }
  }))
  .actions(self => ({
    afterCreate() {
      const storedTokens = storage.get('50t');
      if (storedTokens) {
        storedTokens.split(',').forEach(self.addToken);

        if (!self.isAuthenticated()) {
          self.signOut();
        } else {
          self.startInterval();
        }
      }
    },
    addToken(token) {
      const tokenData = jwtDecode(token);
      self.tokens.put({
        raw: token,
        company: tokenData.companyId,
        exp: tokenData.exp,
        userID: tokenData.userId,
        uuid: tokenData.userUuid,
        role: tokenData.role
      });
    },
    stopInterval() {
      window.clearInterval(interval);
    },
    startInterval() {
      self.stopInterval();
      interval = setInterval(() => {
        if (!self.isAuthenticated()) {
          self.signOut();
        }
      }, 10000);
    },
    getToken(companyId) {
      return self.tokens.get(companyId);
    },
    isAuthenticated() {
      return self.tokenList.length > 0 && self.tokenList.every(token => token.isValid());
    },
    resetPassword: flow(function*(email, url) {
      const data = { email, url };
      const res = yield self.api.users.resetPassword(self.config.resetPasswordUrl, data);
      return !res.error;
    }),
    setPassword: flow(function*(password1, password2, uuid) {
      const data = { password1, password2, uuid };
      const res = yield self.api.users.setPassword(self.config.setPasswordUrl, data);
      return !res.error;
    }),
    signIn: flow(function*(email, password) {
      const data = { email, password };
      const res = yield self.api.users.signIn(self.config.authUrl, data);
      if (!res.error) {
        const tokens = res.data.tokens.map(({ token }) => token);
        tokens.forEach(token => self.addToken(token));
        storage.set('50t', tokens.join(','));
        self.startInterval();
      }
      return res;
    }),
    clearStorage() {
      storage.remove('50t');
    },
    signOut() {
      window.clearInterval(interval);
      interval = null;
      self.clearStorage();
      history.replace('/');
      window.location.reload(true);
    }
  }));
