import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconRating20 = props => (
  <Svg {...props} viewBox="0 0 20 20">
    <path
      d="M7 15h6M7 8h6M10 5v6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
