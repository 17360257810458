import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { CardList, CardListHeader, CardListItems } from 'components/CardList';
import { Checkbox } from 'components/Checkbox';
import { Text } from 'components/Text';
import { Box } from 'components/Box';
import { Menu, MenuButton, MenuItem, MenuItems } from 'components/Menu';
import { Select } from 'components/Select';
import { IconOptions } from 'components/icons/IconOptions';

function Tasklist({ application }) {
  const { templateStore, hasPerm, t, router, openModal } = useStore();
  const [selected, setSelected] = useState(null);
  const tasklistPerm = hasPerm('application:edit-tasklist');
  const { tasklist } = application;
  let templates = [];

  const resetTemplateChange = useCallback(() => {
    if (tasklist && tasklist.name) {
      templates.forEach(template => {
        if (template.display === tasklist.name) {
          setSelected(template.value);
        }
      });
    }
  }, [tasklist, templates]);

  useEffect(() => {
    templateStore.tasklist.load();
    application.loadTasklist();
  }, [application, templateStore.tasklist]);

  useEffect(() => {
    resetTemplateChange();
  }, [tasklist, templates.length, resetTemplateChange]);

  templateStore.tasklist.items.forEach(item => {
    templates.push({
      display: item.name,
      value: item.id
    });
  });

  if (templateStore.tasklist.items.length === 0) {
    if (tasklistPerm) {
      templates.push(
        {
          display: t('application.onboarding.tasklist.nocreated'),
          value: -2,
          readOnly: true
        },
        {
          display: t('application.onboarding.tasklist.create'),
          value: -1,
          link: '/templates/tasks/'
        }
      );
    }
  } else {
    if (tasklistPerm) {
      templates.push({
        display: t('application.onboarding.tasklist.create'),
        value: -1,
        link: '/templates/tasks/'
      });
    }
  }

  function changeTemplateConfirm(templateId, link) {
    setSelected(templateId);
    if (templateId === selected) {
      return;
    }
    if (link) {
      router.push(link);
      return;
    }
    if (templateId < 0) {
      changeTemplate(templateId, link);
      return;
    }
    openModal('CUSTOM_CONFIRM', {
      submit: null,
      data: {
        id: templateId,
        text: t('application.onboarding.changetemplate.text')
      },
      confirm: changeTemplate,
      onCancel: resetTemplateChange
    });
  }

  function removeTemplateConfirm() {
    openModal('CUSTOM_CONFIRM', {
      autoWidth: true,
      submit: null,
      data: {
        text: t('application.onboarding.removetemplate.text')
      },
      confirm: application.removeTasklist
    });
  }

  function changeTemplate(templateId, link) {
    if (link) {
      router.push(link);
      return;
    }
    if (templateId < 0) return true;
    return application.changeTasklist(templateId);
  }

  const showTasklistOptions = tasklist && tasklistPerm;

  return (
    <CardList>
      <CardListHeader>
        <Text type="bold14" color="purple80">
          {t('application.onboarding.tasklist.heading')}
        </Text>
      </CardListHeader>
      <Box ml="8" mr="8" flex>
        {(templates.length > 0 || tasklistPerm) && (
          <Box grow style={{ maxWidth: showTasklistOptions ? 'calc(100% - 2.75rem' : '100%' }}>
            <Select
              key={selected}
              fullSize
              title={t('application.onboarding.tasklist.choose')}
              defaultValue={selected}
              items={templates}
              onChange={tasklist ? changeTemplateConfirm : changeTemplate}
            />
          </Box>
        )}
        {showTasklistOptions && (
          <Box ml="4">
            <Menu label="Tasklist actions">
              <MenuButton>
                <IconOptions />
              </MenuButton>
              <MenuItems>
                <MenuItem onClick={removeTemplateConfirm} variant="danger">
                  {t('application.onboarding.tasklist.remove')}
                </MenuItem>
              </MenuItems>
            </Menu>
          </Box>
        )}
      </Box>
      {tasklist && (
        <Box mt="12">
          <CardListItems>
            {tasklist.orderedTasks.map(item => (
              <Task key={item.id} onClick={tasklistPerm ? item.toggle : null}>
                <Checkbox checked={item.checked} />
                <Text ml="8" type="regular14">
                  {item.text}
                </Text>
              </Task>
            ))}
          </CardListItems>
        </Box>
      )}
    </CardList>
  );
}

export default observer(Tasklist);

const Task = styled.div(p => ({
  alignItems: 'center',
  backgroundColor: p.theme.colors.white100,
  borderRadius: '0.25rem',
  display: 'flex',
  minHeight: '3rem',
  padding: '0.5rem 1rem',
  width: '100%',
  ':hover': {
    cursor: 'pointer'
  }
}));
