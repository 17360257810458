import { types as t, flow } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';
import qs from 'qs';

import { CompanyIntegration, CompanyIntegrationDetail } from 'models/integrations';
import { LoadingState } from 'models/ui';

export const CompanyIntegrationStore = t
  .model('CompanyIntegrationStore', {
    items: t.map(CompanyIntegration),
    selected: t.maybe(CompanyIntegrationDetail),
    queryString: t.maybeNull(t.string),
    state: t.optional(LoadingState, 'init')
  })
  .views(withEnv)
  .views(self => ({
    get list() {
      return Array.from(self.items.values());
    },
    get queryParams() {
      if (!self.queryString) {
        return {};
      }
      return qs.parse(self.queryString, { ignoreQueryPrefix: true });
    },
    get sortBy() {
      if (self.queryParams.ordering) {
        if (self.queryParams.ordering.startsWith('-')) {
          return self.queryParams.ordering.substr(1);
        }
        return self.queryParams.ordering;
      }
      return '';
    },
    get sortType() {
      if (self.queryParams.ordering) {
        return self.queryParams.ordering.startsWith('-') ? 'asc' : 'desc';
      }
      return 'desc';
    }
  }))
  .actions(self => ({
    reset() {
      self.state = 'init';
    },
    updateQueryString(integrationName, field, newValue) {
      let queryParams = self.queryParams;
      queryParams[field] = newValue;
      self.queryString = `?${qs.stringify(queryParams)}`;
      self.loadIntegrationInfo(integrationName);
    },
    sort(integrationName, value) {
      let queryParams = self.queryParams;
      if (queryParams.ordering === value) {
        self.updateQueryString(integrationName, 'ordering', '-' + value);
      } else {
        self.updateQueryString(integrationName, 'ordering', value);
      }
    },
    load: flow(function*() {
      if (self.state !== 'init') return;
      self.state = 'loading';
      const res = yield self.env.api.companies.loadIntegrations();
      if (!res.error) {
        self.state = 'loaded';
        res.data.forEach(entry => self.items.put(entry));
      } else {
        self.state = 'error';
      }
    }),
    loadIntegrationInfo: flow(function*(integrationName) {
      self.state = 'loading';
      const res = yield self.env.api.companies.loadIntegrationInfo(
        integrationName,
        self.queryString
      );
      if (!res.error) {
        self.state = 'loaded';
        self.items.put(res.data);
      }
    }),
    loadIntegrationDetail: flow(function*(integrationName, tabName, objectId) {
      self.state = 'loading';
      self.selected = undefined;
      const res = yield self.env.api.companies.loadIntegrationDetail(
        integrationName,
        tabName,
        objectId
      );
      if (!res.error) {
        self.state = 'loaded';
        self.selected = res.data;
      }
    })
  }));
