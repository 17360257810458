import React from 'react';
import { NamePlate } from 'components/NamePlate';

export const MentionEntry = ({ mention, noMargin, isFocused, searchValue, ...rest }) => {
  return (
    <div {...rest}>
      <NamePlate size="sm" name={mention.name} pic={mention.picture} title={mention.email} />
    </div>
  );
};
