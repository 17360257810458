import { types as t } from 'mobx-state-tree';

import { withEnv } from 'models/withEnv';
import { UserRef } from 'models/users';
import { ApplicationUserRead, ApplicationTrigger } from 'models/applications';

export const ApplicationSearch = t
  .model('ApplicationSearch', {
    date: t.optional(t.string, ''),
    email: t.maybeNull(t.optional(t.string, '')),
    id: t.identifierNumber,
    isHired: t.optional(t.boolean, false),
    isManuallyAdded: t.optional(t.boolean, false),
    isNotifiedOfRejection: t.optional(t.boolean, false),
    isRejected: t.optional(t.boolean, false),
    status: '',
    jobId: t.number,
    jobTitle: '',
    name: t.maybeNull(t.optional(t.string, '')),
    referredBy: t.maybeNull(t.union(t.undefined, t.late(() => UserRef))),
    referredSite: t.maybeNull(t.optional(t.string, '')),
    assignedTo: t.maybeNull(t.union(t.undefined, t.late(() => UserRef))),
    stage: t.optional(t.number, 1),
    stageName: t.optional(t.string, ''),
    isHiredTriggers: t.array(t.late(() => ApplicationTrigger)),
    dateOfHire: t.maybeNull(t.optional(t.string, '')),
    loaded: false,
    userRead: t.maybeNull(t.late(() => ApplicationUserRead))
  })
  .views(withEnv)
  .views(self => ({
    get numIsHiredTriggers() {
      return self.isHiredTriggers.length;
    },
    get numCompletedIsHiredTriggers() {
      return self.isHiredTriggers.filter(t => t.status === 'completed').length;
    },
    get onboardingStatus() {
      return self.env.t(
        'onboarding.applicants.status',
        self.numCompletedIsHiredTriggers,
        self.numIsHiredTriggers
      );
    },
    get picture() {
      const appPicture = self.env.applicationPictureStore.items.get(self.id);
      return appPicture ? appPicture.url : '';
    }
  }))
  .actions(self => ({
    goToDetail() {
      self.env.router.push(`/jobs/${self.jobId}/application/${self.id}`);
    },
    goToOnboarding() {
      self.env.router.push(`/jobs/${self.jobId}/onboarding/${self.id}`);
    }
  }));
