import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { sortCompareSingle } from 'helpers';
import { useStore } from 'models/Provider';
import { Table } from 'components/Table';
import { EmailTemplateOptions } from './EmailTemplateOptions';

export const EmailTemplates = observer(() => {
  const { templateStore, t } = useStore();
  const columns = [
    {
      title: t('company.templates.email.label.title'),
      width: 2,
      defaultSort: 'asc',
      sorter: (a, b) => sortCompareSingle(a, b, 'title'),
      render: item => <span style={{ fontWeight: 600 }}>{item.title}</span>
    },
    {
      title: t('company.templates.email.label.type'),
      width: 2,
      render: item => t(`company.templates.type.${item.templateType}`),
      sorter: (a, b) => {
        const aType = t(`company.templates.type.${a.templateType}`);
        const bType = t(`company.templates.type.${b.templateType}`);
        return sortCompareSingle(aType, bType);
      }
    },
    {
      title: t('company.templates.email.label.default'),
      width: 0.5,
      render: item => {
        if (item.templateType !== 'applicant_new_application') return '-';
        return item.isDefault
          ? t('company.templates.default.yes')
          : t('company.templates.default.no');
      }
    },
    {
      title: t('company.templates.email.label.options'),
      width: 0.5,
      align: 'right',
      style: {
        display: 'flex',
        justifyContent: 'flex-end'
      },
      render: item => <EmailTemplateOptions template={item} />
    }
  ];

  return (
    <Table
      columns={columns}
      data={templateStore.emailTemplates}
      minTableWidth="800px"
      rowWrap={RowWrap}
    />
  );
});

const RowWrap = ({ item, children }) => (
  <DecoratedLink
    to={{
      pathname: `/templates/emails/${item.id}`
    }}
    children={children}
  />
);

const DecoratedLink = styled(Link)({
  display: 'block',
  transition: 'all 150ms ease-out',
  ':hover': {
    transform: 'translateY(-0.0625rem)',
    boxShadow: '0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.08)',
    cursor: 'pointer'
  }
});
