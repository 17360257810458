import { types as t, getParent, flow } from 'mobx-state-tree';

import { CAREERS_URL } from 'api';
import { UserRole } from 'models/users';
import { withEnv } from 'models/withEnv';

export const User = t
  .model('User', {
    id: t.identifierNumber,
    email: '',
    isAdmin: false,
    isActive: false,
    name: '',
    role: t.optional(UserRole, 'user'),
    uuid: t.maybe(t.string),
    picture: t.maybeNull(t.optional(t.string, '')),
    followingJobs: t.array(t.number),
    followingCandidates: t.array(t.number),
    hasSetPassword: false,
    addToNewJobs: false,
    language: t.maybeNull(t.optional(t.string, 'en')),

    loading: false,
    loaded: false,

    // For editing
    sendInvite: true,
    errors: t.map(t.array(t.string))
  })
  .views(withEnv)
  .views(self => ({
    get profileUrl() {
      return `/team/${self.id}/profile`;
    },
    get jobFollowCount() {
      return self.followingJobs.length;
    },
    get candidateFollowCount() {
      return self.followingCandidates.length;
    },
    get displayName() {
      return self.isActive ? self.name : `${self.name} (${self.env.t('user.inactive')})`;
    },
    get isMe() {
      return self.id === getParent(self, 2).me.id;
    },
    get companyInfo() {
      return self.env.current.info;
    },
    get sharingSiteUrl() {
      if (!self.companyInfo || !self.uuid) return '';
      const name = self.companyInfo.name.toLowerCase();
      const prefix = CAREERS_URL;
      return `${prefix}/${name}/?referral_uuid=${self.uuid}`;
    },
    get roleDisplay() {
      return self.env.t(`user.role.${self.role}`);
    }
  }))
  .actions(self => ({
    setErrors(errors) {
      self.errors = errors;
    },
    toggleSendInvite() {
      self.sendInvite = !self.sendInvite;
    },
    toggleAddToNewJobs() {
      self.addToNewJobs = !self.addToNewJobs;
    },
    setAddToNewJobs(value) {
      self.addToNewJobs = value;
    },
    create: flow(function*() {
      const res = yield self.env.api.users.create({
        sendInvite: self.sendInvite,
        name: self.name,
        email: self.email,
        role: self.role,
        addToNewJobs: self.addToNewJobs,
        url: window.location.origin + '/setpassword?uuid='
      });

      if (res.error) {
        self.env.notify('error', self.env.t('edituser.create.error'));
        self.errors = res.data;
        return false;
      } else {
        const user = self.env.userStore.add(res.data);
        self.env.notify('success', self.env.t('edituser.create.success', user.name));
        self.followingJobs.map(job => user.grantJobAccess(job));
        return true;
      }
    }),
    setFollowingJobs(jobs) {
      self.followingJobs = jobs;
    },
    setFollowingCandidates(candidates) {
      self.followingCandidates = candidates;
    },
    setPicture(picture) {
      self.picture = picture;
    },
    jobSharingUrl(id) {
      if (!self.companyInfo || !self.uuid) return '';
      const name = self.companyInfo.name.toLowerCase();
      const prefix = 'https://jobs.50skills.com';
      return `${prefix}/${name}/${id}/?referral_uuid=${self.uuid}`;
    },
    setName(name) {
      self.name = name;
    },
    setRole(role) {
      self.role = role;
    },
    setUuid(uuid) {
      self.uuid = uuid;
    },
    setEmail(email) {
      self.email = email;
    },
    setLanguage(language) {
      self.language = language;
    },
    followJob(job) {
      if (!self.followingJobs.includes(job)) {
        self.followingJobs.push(job);
      }
    },
    unfollowJob(job) {
      self.followingJobs = self.followingJobs.filter(j => j !== job);
    },
    followCandidate(candidateId) {
      if (!self.followingCandidates.includes(candidateId)) {
        self.followingCandidates.push(candidateId);
      }
    },
    unfollowCandidate(candidateId) {
      self.followingCandidates = self.followingCandidates.filter(id => id !== candidateId);
    },
    delete() {
      return getParent(self, 2).delete(self);
    },
    load: flow(function*() {
      if (self.loaded || self.loading) return;
      self.loading = true;

      const res = yield self.env.api.users.loadUser(self.id);
      res.data.forEach(d => {
        self.items.put(d);
        if (!self.items.has(d)) {
          self.list.push(d.id);
        }
      });

      self.loaded = true;
      self.loading = false;
    }),
    revokeJobAccess: flow(function*(jobId) {
      const res = yield self.env.api.jobs.bulkPurgeAccess(self.id, jobId);
      const job = self.env.jobStore.items.get(jobId);

      if (!res.error) {
        self.env.notify('success', self.env.t('user.revoke.success', self.name, job.title));
        job.removeFollower(self.id);
        self.unfollowJob(job.id);
        job.applications.forEach(application => {
          application.shallowRemoveFollower(self);
          self.unfollowCandidate(application.id);
        });
      } else {
        self.env.notify('error', self.env.t('user.revoke.error', self.name, job.title));
      }
    }),
    grantJobAccess: flow(function*(jobId, notifyOnSuccess = false) {
      const res = yield self.env.api.users.grantJobAccess(self.id, jobId);
      const job = self.env.jobStore.items.get(jobId);

      if (!res.error) {
        if (notifyOnSuccess) {
          self.env.notify('success', self.env.t('user.grant.success', self.name, job.title));
        }
        job.addFollower(self);
        self.followJob(job.id);
        return true;
      } else {
        self.env.notify('error', self.env.t('user.grant.error', self.name, job.title));
        return false;
      }
    })
  }));
