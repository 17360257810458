import React from 'react';

import styled from 'styled-components/macro';

import { observer } from 'mobx-react-lite';

import FormDropDown from 'shared/forms/FormDropDown';

import { useStore } from 'models/Provider';

function TemplateSelector({ onChange, templates }) {
  const { t } = useStore();
  const items = templates.map(t => {
    return {
      value: t.id,
      display: t.title || t.name
    };
  });
  return (
    <div>
      <FormLabel>{t('rejectform.label.templateselect')}</FormLabel>
      <div>
        <FormDropDown
          inModal
          title={t('rejectform.template.drop.title')}
          fullSize
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default observer(TemplateSelector);

const FormLabel = styled.div`
  color: ${props => props.theme.formLabelColor};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;
