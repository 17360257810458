import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconTextShort = props => (
  <Svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9h16v2H4V9zM4 13h10v2H4v-2z"
      fill="currentColor"
    />
  </Svg>
);
