export const emailTemplateTypes = [
  'new_user',
  'password_reset',
  'applicant_new_application',
  'company_new_application',
  'rejection',
  'hired',
  'custom',
  'message',
  'integrations_internal_taskslists',
  'integrations_one_system'
];
