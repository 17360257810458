import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { H1, FormLabel, Text } from 'components/Text';
import { Input } from 'components/Input';
import { UnauthedPage } from 'components/UnauthedPage';

export const Signin = observer(props => {
  const { email, password, onChange, onSubmit, error, disableSubmit, t } = props;

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }

  let submitLabel = disableSubmit ? t('signin.button.submitting') : t('signin.button.submit');

  return (
    <UnauthedPage>
      <H1>{t('signin.heading')}</H1>
      <Box mt="24">
        <FormLabel>{t('signin.label.email')}</FormLabel>
        <Input
          value={email}
          onChange={val => onChange('email', val)}
          disabled={disableSubmit}
          onKeyPress={handleKeyPress}
        />
      </Box>
      <Box mt="16">
        <FormLabel>{t('signin.label.password')}</FormLabel>
        <Input
          type="password"
          value={password}
          onChange={val => onChange('password', val)}
          error={error}
          disabled={disableSubmit}
          onKeyPress={handleKeyPress}
        />
      </Box>
      <Actions justifyContent="space-between" alignItems="center" mt="24">
        <Button disabled={disableSubmit} onClick={onSubmit}>
          {submitLabel}
        </Button>
        <Link to="/forgotpassword">
          <Text type="regular14" color="purple40">
            {t('signin.forgotpassword')}
          </Text>
        </Link>
      </Actions>
    </UnauthedPage>
  );
});

const Actions = styled(Box)(p => ({
  display: 'flex',
  [p.theme.mobileQuery]: {
    display: 'block',
    '> *': {
      textAlign: 'center',
      width: '100%'
    },
    '> a': {
      display: 'block',
      marginTop: p.theme.spacing.m12
    }
  }
}));
