import { types as t, flow, destroy, getParent } from 'mobx-state-tree';

import { sortBy } from 'helpers';
import { withEnv } from 'models/withEnv';
import { User, UserRef } from 'models/users';
import { LoadingState } from 'models/ui';

const filterUsers = (users, search) => {
  const str = search.toLowerCase();
  return users.filter(user => {
    const name = user.name.toLowerCase();
    const role = user.role.toLowerCase().replace('user', 'employee');
    const email = user.email.toLowerCase();
    return name.includes(str) || email.includes(str) || role.includes(str);
  });
};

export const UserStore = t
  .model('UserStore', {
    items: t.map(User),
    loading: false,
    loaded: false,
    followerStatsState: LoadingState,
    me: t.maybe(UserRef),
    editing: t.maybeNull(User)
  })
  .views(withEnv)
  .views(self => ({
    get sorted() {
      return sortBy(self.filteredUsers.slice(), self.settings.sortBy, self.settings.sortType);
    },
    get usersForTab() {
      switch (self.settings.chosenTab) {
        case 1:
          return self.admins;
        case 2:
          return self.recruiters;
        case 3:
          return self.limited;
        case 4:
          return self.employees;
        default:
          return self.sorted;
      }
    },
    get settings() {
      return getParent(self).uiSettings.team;
    },
    get admins() {
      return self.sorted.filter(u => u.role === 'admin');
    },
    get recruiters() {
      return self.sorted.filter(u => u.role === 'recruiter');
    },
    get limited() {
      return self.sorted.filter(u => u.role === 'limited');
    },
    get employees() {
      return self.sorted.filter(u => u.role === 'user');
    },
    get nonEmployees() {
      return self.sorted.filter(u => u.role !== 'user');
    },
    get filteredUsers() {
      return filterUsers(self.list, self.settings.searchStr);
    },
    get list() {
      return Array.from(self.items.values()).filter(u => u.isActive);
    }
  }))
  .actions(self => ({
    loadFollowerStats: flow(function*() {
      if (self.followerStatsState !== 'init' || self.me.role !== 'admin') {
        return;
      }

      self.followerStatsState = 'loading';
      const res = yield self.env.api.users.loadFollowingStats();
      if (!res.error) {
        res.data.forEach(user => {
          const u = self.items.get(user.id);
          if (user.followingJobs.length) {
            u.setFollowingJobs(user.followingJobs);
          }
          if (user.followingCandidates.length) {
            u.setFollowingCandidates(user.followingCandidates);
          }
        });
        self.followerStatsState = 'loaded';
      } else {
        self.followerStatsState = 'error';
      }
    }),
    load: flow(function*() {
      if (self.loaded || self.loading) return;
      self.loading = true;

      const res = yield self.env.api.users.load();
      if (!res.error) {
        res.data.results.forEach(d => {
          if (self.items.has(d.id)) {
            self.items.put({
              ...self.items.get(d.id),
              ...d
            });
          } else {
            self.items.put(d);
          }
        });
      }

      self.loaded = true;
      self.loading = false;

      self.env.api.users.loadPictures().then(res => {
        res.data.forEach((u, index) => {
          setTimeout(() => {
            self.items.get(u.id).setPicture(u.picture);
          }, index * 2);
        });
      });
    }),
    loadIndividual: flow(function*(id) {
      const res = yield self.env.api.users.loadUser(id);
      if (!res.error) {
        self.add(res.data);
      }
    }),
    setEditing(user) {
      self.editing = user;
    },
    clearEditing() {
      destroy(self.editing);
    },
    setMe(id) {
      self.me = id;
    },
    add(user) {
      if (!self.items.has(user.id)) {
        self.items.put(user);
      }
      return self.items.get(user.id);
    },
    delete: flow(function*(user) {
      const res = yield self.env.api.users.remove(user.id);
      if (!res.error) {
        user.isActive = false;
        self.env.notify('success', self.env.t('userstore.delete.success'));
        return true;
      } else {
        self.env.notify('error', self.env.t('userstore.delete.error'));
        return false;
      }
    })
  }));
