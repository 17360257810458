import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { useStore } from 'models/Provider';
import { Signin } from './Signin';

function SigninPage() {
  const { authStore, router, t } = useStore();
  const [state, setState] = useState({
    email: '',
    password: '',
    error: '',
    disable: false
  });
  const locationState = router.location.state;

  if (authStore && authStore.isAuthenticated()) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  function handleChange(name, value) {
    setState(old => ({ ...old, [name]: value, error: '' }));
  }

  async function signIn() {
    setState(old => ({ ...old, error: '', disable: true }));
    const { email, password } = state;

    const res = await authStore.signIn(email, password);
    if (res.error) {
      setState(old => ({ ...old, error: res.data.nonFieldErrors.join(' '), disable: false }));
    } else {
      const redir = locationState && (locationState.from ? locationState.from : '/');
      router.push(redir);
    }
  }

  return (
    <Signin
      t={t}
      email={state.email}
      password={state.password}
      onChange={handleChange}
      onSubmit={signIn}
      error={state.error}
      disableSubmit={state.disable}
    />
  );
}

export default observer(SigninPage);
