import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { useSideNavigation } from 'components/SideNavigation';
import { MobileHeader } from 'components/MobileHeader';
import { useMobile } from 'hooks/useMobile';

export const Header = observer(
  (props, ref) => {
    const { actions = null, breadcrumbs = null, tabs = null, mobileTitle } = props;
    const { open: sidebarOpen } = useSideNavigation();
    const height = tabs !== null ? (actions !== null ? '8rem' : '7.5rem') : '5.5rem';
    const mobile = useMobile();
    if (mobile) {
      return (
        <div>
          <MobileHeader children={mobileTitle} />
          {tabs && (
            <MobileTabsContainer>
              {tabs}
              <div style={{ height: 1, minWidth: '1.5rem' }} />
            </MobileTabsContainer>
          )}
          {actions && <HeaderActions>{actions}</HeaderActions>}
        </div>
      );
    }

    return (
      <HeaderContainer ref={ref} id="header">
        <HeaderSpacer height={height} />
        <HeaderContent fixedOpen={sidebarOpen} height={height}>
          <HeaderInnerContainer smallPad={!tabs && actions} padBottom={!tabs && !actions}>
            <HeaderBreadCrumbs>
              {breadcrumbs}
              {!tabs && actions}
            </HeaderBreadCrumbs>
            {tabs && (
              <HeaderTabs padTop={!actions}>
                {tabs}
                {actions && <HeaderActions withMargin>{actions}</HeaderActions>}
              </HeaderTabs>
            )}
          </HeaderInnerContainer>
          <HeaderBottomBorder />
        </HeaderContent>
      </HeaderContainer>
    );
  },
  { forwardRef: true }
);

const MobileTabsContainer = styled.div(p => ({
  backgroundColor: p.theme.colors.white100,
  padding: '1.5rem 1.5rem 0 1.5rem',
  overflowX: 'auto',
  maxWidth: '100vw',
  display: 'flex',
  alignItems: 'center'
}));

const HeaderBreadCrumbs = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
});

const HeaderTabs = styled.div(p => ({
  marginTop: p.padTop ? p.theme.spacing.m24 : p.theme.spacing.m8,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end'
}));

const HeaderActions = styled(({ withMargin, ...rest }) => <div {...rest} />)(p => ({
  marginLeft: p.theme.spacing.m32,
  minHeight: '2.5rem',
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'relative',
  ...(p.withMargin && { marginBottom: p.theme.spacing.m24 }),
  '@media(max-width: 768px)': {
    padding: `${p.theme.spacing.m24} ${p.theme.spacing.m24} 0 ${p.theme.spacing.m24}`,
    marginLeft: 0,
    justifyContent: 'flex-start'
  }
}));

const HeaderSpacer = styled.div(p => ({
  visibility: 'hidden',
  height: p.height
}));

const HeaderContent = styled.div(p => ({
  position: 'fixed',
  backgroundColor: p.theme.colors.white100,
  zIndex: 10,
  top: 0,
  right: 0,
  transition: 'left 200ms ease',
  left: p.fixedOpen ? '12.5rem' : '4.5rem',
  height: p.height,
  '@media(max-width: 768px)': {
    left: 0
  }
}));

const HeaderContainer = styled.div(p => ({
  position: 'relative',
  backgroundColor: p.theme.colors.white100,
  zIndex: 10
}));

const HeaderInnerContainer = styled.div(p => ({
  padding: '2rem 2.5rem 0rem 2.5rem',
  ...(p.smallPad && { paddingBottom: '1.5rem', paddingTop: '1.5rem' }),
  ...(p.padBottom && { paddingBottom: '2rem' })
}));

const HeaderBottomBorder = styled.div(p => ({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  left: 0,
  right: 0,
  height: '0.0625rem',
  backgroundColor: p.theme.colors.purpleTrans10
}));
