import { useStore } from 'models/Provider';

export const usePlaceholderMap = type => {
  const { t } = useStore();
  switch (type) {
    case 'applicant_new_application':
      return {
        '{{ applicant_name }}': t('editor.placeholder.name'),
        '{{ applicant_first_name }}': t('editor.placeholder.first_name'),
        '{{ applicant_last_name }}': t('editor.placeholder.last_name'),
        '{{ job_name }}': t('editor.placeholder.job_title')
      };
    case 'rejection':
    case 'message':
    default:
      return {
        '{{ applicant_name }}': t('editor.placeholder.name'),
        '{{ job_name }}': t('editor.placeholder.job_title'),
        '{{ sender_name }}': t('editor.placeholder.sender_name'),
        '{{ applicant_first_name }}': t('editor.placeholder.first_name'),
        '{{ applicant_last_name }}': t('editor.placeholder.last_name')
      };
  }
};
