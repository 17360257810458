import { types as t } from 'mobx-state-tree';

export const MetaName = t.model('MetaName', {
  'twitter:title': t.string,
  'twitter:image:src': t.string,
  'twitter:description': t.string
});

export const MetaProperty = t.model('MetaProperty', {
  'og:url': t.string,
  'og:type': t.string,
  'og:image': t.string,
  'og:title': t.string,
  'og:siteName': t.string,
  'og:description': t.string
});

export const Meta = t.model('Meta', {
  name: MetaName,
  property: MetaProperty
});

export const Theme = t.model('Theme', {
  meta: Meta
});
