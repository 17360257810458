import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconEmail32 = props => (
  <Svg32 {...props}>
    <rect
      x="1"
      y="1"
      width="30px"
      height="30px"
      rx="15"
      fill="#3C3C64"
      stroke="#fff"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.111 8.889H8.89c-.978 0-1.769.8-1.769 1.778l-.009 10.666c0 .978.8 1.778 1.778 1.778H23.11c.978 0 1.778-.8 1.778-1.778V10.667c0-.978-.8-1.778-1.778-1.778zm0 3.556L16 16.889l-7.111-4.444v-1.778L16 15.11l7.111-4.444v1.777z"
      fill="#fff"
    />
  </Svg32>
);
