import styled from 'styled-components/macro';

export const Svg = styled.svg.attrs(p => ({
  viewBox: p.viewBox || '0 0 24 24',
  height: p.height || '1.5rem',
  width: p.width || '1.5rem',
  fill: p.fill || 'none'
}))(p => ({
  flexShrink: 0,
  ...(p.color && { color: p.theme.colors[p.color] })
}));

export const Svg32 = styled.svg.attrs(p => ({
  viewBox: p.viewBox || '0 0 32 32',
  height: p.height || '2rem',
  width: p.width || '2rem',
  fill: p.fill || 'none'
}))(p => ({
  flexShrink: 0,
  ...(p.color && { color: p.theme.colors[p.color] })
}));
