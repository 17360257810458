import moment from 'moment';
import { types as t, flow, getParent } from 'mobx-state-tree';

import { updateMSTObject, transformJob, transformApplication, sortBy } from 'helpers';

import {
  JobIntegrations,
  JobLanguages,
  JobListView,
  JobRoutes,
  JobSettings,
  JobStatistics,
  JobStages,
  JobStatus,
  JobType,
  JobVisibility,
  Meta
} from 'models/jobs';
import { LoadingState } from 'models/ui';
import { withEnv } from 'models/withEnv';
import { UserRef } from 'models/users';

const DynamicStages = t.model('DynamicStages', {
  stage: t.number,
  autoOpenModal: t.enumeration('Modal', ['ASSIGN_APPLICANT', 'HIRE_APPLICANT', 'REJECT_APPLICANTS'])
});

const JobApplicationFollower = t.model('JobApplicationFollower', {
  id: t.number,
  numApplications: t.number
});

const JobBase = t
  .model('JobBase', {
    id: t.identifierNumber,
    isArchived: false,
    isPublished: false,
    metaData: t.maybeNull(Meta),
    settings: t.optional(JobSettings, {}),
    statistics: t.optional(JobStatistics, {}),
    hasRewards: false,
    rewards: t.maybeNull(t.string),
    rewardsTitle: t.maybeNull(t.string),
    deadline: t.maybeNull(t.string),
    status: t.optional(JobStatus, 'full_time'),
    jobType: t.optional(JobType, 'employee'),
    visibility: t.optional(JobVisibility, 'public'),
    draftStage: 0,
    numStages: 6,
    ordering: 0,
    stages: t.optional(JobStages, {}),
    listView: t.optional(JobListView, {}),
    category: t.maybeNull(t.string),
    followers: t.array(t.late(() => UserRef)),
    appFollowers: t.array(JobApplicationFollower),
    stage1Name: 'Stage 1',
    stage2Name: 'Stage 2',
    stage3Name: 'Stage 3',
    stage4Name: 'Stage 4',
    stage5Name: 'Stage 5',
    stage6Name: 'Stage 6',
    numApplications: t.optional(t.number, 0),
    numApplicationsToday: t.optional(t.number, 0),
    uuid: t.maybe(t.string),
    includeCoverLetter: false,
    requireCoverLetter: false,
    includeCv: false,
    requireCv: false,
    includePhone: false,
    requirePhone: false,
    includeSex: false,
    requireSex: false,
    includeSsn: false,
    requireSsn: false,
    useMessages: false,

    detailLoading: false,
    detailLoaded: false,
    integrationsState: LoadingState,
    dynamicStages: t.array(DynamicStages)
  })
  .views(withEnv)
  .actions(self => ({
    editAppFollowerCount(userId, count) {
      const index = self.appFollowers.findIndex(af => af.id === userId);
      if (~index) {
        if (count === 0) {
          self.appFollowers.splice(index, 1);
        } else {
          self.appFollowers[index].numApplications = count;
        }
      }
    },
    removeAppFollower(userId) {
      self.appFollowers = [...self.appFollowers.filter(u => u.id !== userId)];
    },
    removeFollower(userId) {
      self.followers = [...self.followers.filter(u => u.id !== userId)];
    },
    addFollower(user) {
      if (!self.followers.includes(user)) {
        self.followers.push(user);
      }
    },
    loadApplicationUserRead: appIds => {
      if (!appIds || appIds.length === 0) return;
      self.env.api.applications.loadApplicationsUserRead(appIds).then(res => {
        if (!res.error) {
          res.data.forEach(a => {
            self.env.applicationStore.items.get(a.applicationId).setAllUserRead(a);
          });
        }
      });
    },
    loadPicturesForApplications: appIds => {
      if (appIds.length > 0) {
        self.env.api.applications.loadPictures(appIds).then(res => {
          if (!res.error) {
            res.data.forEach((a, index) => {
              setTimeout(() => {
                self.env.applicationPictureStore.setUrl(a.id, a.picture);
              }, index * 2);
            });
          }
        });
      }
    },
    setNumApplicationsToday(count) {
      self.numApplicationsToday = count;
    },
    setNumApplications(count) {
      self.numApplications = count;
    },
    localEdit(field, value) {
      self[field] = value;
    },
    onSettingsUpdate() {
      const isPipeline = self.env.router.location.pathname.includes('/pipeline');
      if (isPipeline) {
        self.stages.onSettingsUpdate();
      } else {
        self.listView.onSettingsUpdate();
      }
    },
    loadFromDb: flow(function*() {
      const res = yield self.env.api.jobs.loadFromDb(self.id);
      if (!res.error) {
        updateMSTObject(self, transformJob(res.data));
      }
    }),
    load: flow(function*() {
      if (self.detailLoaded || self.detailLoading) return;
      self.detailLoading = true;

      let jobRes = yield self.env.api.jobs.loadJob(self.id);
      if (jobRes.error) {
        return;
      }
      jobRes.data = jobRes.data[0];

      updateMSTObject(self, transformJob(jobRes.data));
      yield self.settings.load();
      self.settings.stages.forEach(stageSetting => self.stages.add(stageSetting.stage));
      self.detailLoaded = true;
      self.detailLoading = false;
    }),
    delete() {
      return getParent(self, 2).delete(self);
    },
    removeApplication(id) {
      self.stages.removeApplication(id);
      self.listView.removeApplication(id);
      self.numApplications -= 1;
    },
    duplicate: flow(function*() {
      const res = yield self.env.api.jobs.duplicate(self.id);
      if (!res.error) {
        self.env.jobStore.addOrUpdate(res.data);
        self.env.notify('success', self.env.t('job.duplicate.success', self.title));
      } else {
        self.env.notify('error', self.env.t('job.duplicate.error', self.title));
      }
    }),
    unPublish: flow(function*() {
      const res = yield self.env.api.jobs.unPublish(self.id);
      if (!res.error) {
        self.isPublished = false;
        self.env.notify('success', self.env.t('job.unpublish.success', self.title));
      } else {
        self.env.notify('error', self.env.t('job.unpublish.error', self.title));
      }
    }),
    publish: flow(function*() {
      let submit = true;

      const hasEnabled = self.languages.some(obj => obj.isEnabled);
      if (!hasEnabled) {
        self.env.notify('error', self.env.t('job.create.publish.no_enabled_language'));
        return false;
      }

      self.languages.forEach(obj => {
        if (obj.isEnabled && !obj.isComplete) {
          self.notifyIncompletes(obj.language);
          submit = false;
        }
      });

      if (!submit) return false;

      const res = yield self.env.api.jobs.publish(self.id);
      if (!res.error) {
        self.isPublished = true;
        self.isArchived = false;
        if (self.original) {
          self.original.localEdit('isPublished', true);
          self.original.localEdit('isArchived', false);
        }
        self.env.notify('success', self.env.t('job.publish.success', self.title));
        return true;
      } else {
        self.env.notify('error', self.env.t('job.publish.error', self.title));
        return false;
      }
    }),
    moveToDrafts: flow(function*() {
      const res = yield self.env.api.jobs.moveToDrafts(self.id);
      if (!res.error) {
        self.isArchived = false;
        self.isPublished = false;
        self.env.notify('success', self.env.t('job.drafts.success'));
      } else {
        self.env.notify('error', self.env.t('job.drafts.error'));
      }
    }),
    moveToArchive: flow(function*() {
      const res = yield self.env.api.jobs.moveToArchive(self.id);
      if (!res.error) {
        self.isArchived = true;
        self.isPublished = false;
        self.env.notify('success', self.env.t('job.archive.success'));
      } else {
        self.env.notify('error', self.env.t('job.archive.error'));
      }
    }),
    immediateUpdate: flow(function*(data) {
      const res = yield self.env.api.jobs.edit(self.id, data);
      if (!res.error) {
        updateMSTObject(self, transformJob(data));
        self.env.notify('success', self.env.t('job.immediateupdate.success'));
        return true;
      } else {
        self.env.notify('error', self.env.t('job.immediateupdate.error'));
        return false;
      }
    }),
    addApplicant: flow(function*(formData) {
      formData.append('job', self.id);
      const res = yield self.env.api.applications.create(formData);
      const name = formData.get('name');
      if (!res.error) {
        self.stages.items.get(1).addApplication(res.data.id);
        self.listView.addApplication(res.data.id);
        self.numApplications += 1;
        self.numApplicationsToday += 1;
        self.env.applicationStore.addOrUpdate(transformApplication(res.data), self.id);
        self.env.notify('success', self.env.t('job.addapplicant.success', name));
      } else {
        self.env.notify('error', self.env.t('job.addapplicant.error', name));
      }
      return res;
    }),
    stageName(i) {
      return self[`stage${i}Name`];
    },
    updateLocal(data) {
      updateMSTObject(self, transformJob(data));
    },
    addToAppFollowCountForUser(user, count) {
      const idx = self.appFollowers.findIndex(af => af.id === user.id);
      if (~idx) {
        self.appFollowers[idx].numApplications += count;
      } else {
        self.appFollowers.push({
          numApplications: count,
          id: user.id
        });
      }
    },
    bulkGrantAccess: flow(function*(applicants, users) {
      const appIds = applicants.map(a => a.id);
      const userIds = users.map(u => u.id);
      const res = yield self.env.api.jobs.bulkGrantAccessToApplicants(self.id, appIds, userIds);

      if (!res.error) {
        applicants.forEach(a => users.forEach(u => a.setApplicationFollower(u)));
        users.forEach(u => {
          self.addToAppFollowCountForUser(u, appIds.length);
        });
        let successMsg = self.env.t('job.bulkgrant.success', userIds.length, appIds.length);
        self.env.notify('success', successMsg);
        return true;
      } else {
        self.env.notify('error', self.env.t('job.bulkgrant.error'));
        return false;
      }
    }),
    bulkRejectApplicants: flow(function*(applicants, markNotified, emailData) {
      const appIds = applicants.map(a => a.id);
      const res = yield self.env.api.jobs.bulkRejectApplicants(
        self.id,
        appIds,
        markNotified,
        emailData
      );

      let successMsg;
      if (markNotified) {
        if (emailData) {
          successMsg = self.env.t('job.bulkreject.success.notified', appIds.length);
        } else {
          successMsg = self.env.t('job.bulkreject.success.marked', appIds.length);
        }
      } else {
        successMsg = self.env.t('job.bulkreject.success', appIds.length);
      }

      if (!res.error) {
        applicants.forEach(a => {
          a.setRejected(true);
          if (markNotified) {
            a.setNotifiedOfRejection(true);
          }
        });
        self.env.notify('success', successMsg);
        return true;
      } else {
        self.env.notify('error', self.env.t('job.bulkreject.error'));
        return false;
      }
    }),
    bulkMoveToStage: flow(function*(applicants, stage) {
      const appIds = applicants.map(a => a.id);
      const stageName = self.stageName(stage);
      const res = yield self.env.api.jobs.bulkMoveToStage(self.id, appIds, stage);

      let successMsg = self.env.t('job.bulkmove.success', appIds.length, stageName);

      if (!res.error) {
        applicants.forEach(a => {
          a.setStage(stage, stageName);
        });
        self.env.notify('success', successMsg);
        return true;
      } else {
        self.env.notify('error', self.env.t('job.bulkmove.error'));
        return false;
      }
    }),
    bulkMessageApplicants: flow(function*(applicants, emailData) {
      const appIds = applicants.map(a => a.id);

      const res = yield self.env.api.jobs.bulkMessageApplicants(self.id, appIds, emailData);

      const successMsg = self.env.t('job.bulkmessage.success', appIds.length);

      if (!res.error) {
        self.env.notify('success', successMsg);
        return true;
      } else {
        self.env.notify('error', self.env.t('job.bulkmessage.error'));
        return false;
      }
    }),
    exportApplicants: flow(function*(fields, exportType) {
      const res = yield self.env.api.jobs.exportApplicants(self.id, fields, exportType);
      if (!res.error) {
        return true;
      } else {
        return false;
      }
    })
  }))
  .views(self => ({
    get applications() {
      return self.env.applicationStore.list.filter(a => a.job === self);
    },
    get activeFollowers() {
      return self.followers.filter(user => user.isActive);
    },
    get combinedFollowers() {
      const appFollowerUsers = self.appFollowers.map(af => self.env.userStore.items.get(af.id));
      const combined = self.followers.concat(appFollowerUsers).filter(user => user.isActive);
      return sortBy(combined, 'name');
    },
    get applicantListStages() {
      return [
        { value: 1, display: self.stage1Name },
        { value: 2, display: self.stage2Name },
        { value: 3, display: self.stage3Name },
        { value: 4, display: self.stage4Name },
        { value: 5, display: self.stage5Name },
        { value: 6, display: self.stage6Name }
      ].slice(0, self.numStages);
    },
    get dropdownStages() {
      return [
        { value: 1, display: self.stage1Name },
        { value: 2, display: self.stage2Name },
        { value: 3, display: self.stage3Name },
        { value: 4, display: self.stage4Name },
        { value: 5, display: self.stage5Name },
        { value: 6, display: self.stage6Name }
      ].slice(0, self.numStages);
    },
    get enabledLanguages() {
      return self.languages.filter(lang => lang.isEnabled);
    },
    get visibilityTypes() {
      return [
        { value: 'public', display: self.env.t('job.visibility.public') },
        { value: 'private', display: self.env.t('job.visibility.private') }
      ];
    },
    get statusTypes() {
      return [
        { value: 'full_time', display: self.env.t('job.status.full_time') },
        { value: 'part_time', display: self.env.t('job.status.part_time') },
        { value: 'per_diem', display: self.env.t('job.status.per_diem') },
        { value: 'not_specified', display: self.env.t('job.status.not_specified') }
      ];
    },
    get jobTypes() {
      return [
        { value: 'employee', display: self.env.t('job.types.employee') },
        { value: 'temporary', display: self.env.t('job.types.temporary') },
        { value: 'intern', display: self.env.t('job.types.intern') },
        { value: 'seasonal', display: self.env.t('job.types.seasonal') },
        { value: 'not_specified', display: self.env.t('job.types.not_specified') }
      ];
    },
    get jobTypeDisplay() {
      if (self.jobType === 'not_specified') {
        return '';
      }
      return self.env.t(`job.type.${self.jobType}`);
    },
    get isExpired() {
      if (!self.deadline) return false;
      const today = moment();
      const endOfDeadlineDay = moment(self.deadline).endOf('day');
      return today.isAfter(endOfDeadlineDay);
    },
    get defaultLanguageData() {
      const defaultLang = self.env.current.info.defaultLanguage;
      const def = self.languages.find(obj => obj.language === defaultLang);
      if (!def || !def.isEnabled) {
        if (self.languages.length > 0) {
          return self.languages.find(obj => obj.isEnabled) || self.languages[0];
        }
        return null;
      }
      return def;
    },
    get locationDisplay() {
      return self.langLocation || `(${self.env.t('job.nolocation')})`;
    },
    get title() {
      return self.langTitle || `(${self.env.t('job.notitle')})`;
    },
    get langTitle() {
      return self.defaultLanguageData ? self.defaultLanguageData.title : '';
    },
    get langLocation() {
      return self.defaultLanguageData ? self.defaultLanguageData.location : '';
    },
    get deadlineDisplay() {
      return self.deadline
        ? moment(self.deadline).format('dddd, MMMM Do YYYY')
        : `(${self.env.t('job.nodeadline')})`;
    },
    get shortDeadlineDisplay() {
      return self.deadline
        ? moment(self.deadline).format("MMM Do 'YY")
        : self.env.t('job.nodeadline');
    },
    get numFilteredOut() {
      return self.numApplications - self.filteredApplicationCountTotal;
    },
    get filteredApplicationCountTotal() {
      return self.stages.list.reduce((sum, stage) => sum + stage.count, 0);
    },
    get filteredApplicationCountLoaded() {
      return self.stages.list.reduce((sum, stage) => sum + stage.applications.length, 0);
    },
    get allStagesLoaded() {
      return self.stages.list.length > 0 && self.stages.list.every(st => st.state === 'loaded');
    }
  }));

export const Job = t.compose(JobBase, JobLanguages, JobIntegrations, JobRoutes).named('Job');
