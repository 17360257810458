import React, { useState } from 'react';
import styled from 'styled-components/macro';
import createMentionPlugin from 'draft-js-mention-plugin';

import { MentionEntry } from './MentionEntry';
import { MentionText } from './MentionText';

const mentionTheme = {
  mentionSuggestions: 'mention-suggestions-list',
  mentionSuggestionsEntry: 'mention-suggestions-entry',
  mentionSuggestionsEntryFocused: 'mention-suggestions-entry',
  mention: 'mention-confirmed'
};

export const useMentionPlugin = (editMode = false) => {
  const [state] = useState(() => {
    const plugin = createMentionPlugin({
      theme: mentionTheme,
      entityMutability: 'IMMUTABLE',
      mentionComponent: mentionProps => <MentionText {...mentionProps} editMode={editMode} />
    });

    const Component = ({ onSearch, suggestions }) => (
      <MentionSuggestionsWrapper>
        <plugin.MentionSuggestions
          onSearchChange={onSearch}
          suggestions={suggestions}
          entryComponent={MentionEntry}
        />
      </MentionSuggestionsWrapper>
    );
    return [plugin, Component];
  });

  return state;
};

const MentionSuggestionsWrapper = styled.div`
  .mention-suggestions-list {
    border: 1px solid #eee;
    margin-top: 0.4em;
    position: absolute;
    min-width: 220px;
    max-width: 440px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: 7px 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    transform: scale(0);
    max-height: 300px;
    overflow-y: auto;
  }
  .mention-suggestions-entry {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 4px;

    > div:nth-child(1) > img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
      border-radius: 2px;
    }

    > div:nth-child(2) {
      > div:nth-child(1) {
        color: #6b6b6b;
        font-size: 14px;
      }
      > div:nth-child(2) {
        opacity: 0.65;
        color: #6b6b6b;
        font-size: 12px;
      }
    }

    :hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.04);
      > div > div:nth-child(1) {
        color: ${p => p.theme.primaryColor};
      }
    }
  }

  .mention-confirmed {
    background-color: rgba(0, 0, 0, 0.06);
    padding: 2px 4px;
    border-radius: 4px;
  }
`;
