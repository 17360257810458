import axios from 'axios';

export const getVisibleWidth = () => {
  return (
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0
  );
};

export const getVisibleHeight = () => {
  return (
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0
  );
};

export const getElementHeight = el => {
  return el ? el.clientHeight || el.offsetHeight || el.scrollHeight : 0;
};

export const getElementWidth = el => {
  return el ? el.clientWidth || el.offsetWidth || el.scrollWidth : 0;
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const cookiesAllowed = () => {
  try {
    document.cookie = 'checkCookie=yes;';
    return document.cookie.indexOf('checkCookie=') > -1;
  } catch (e) {
    return false;
  }
};

class Storage {
  constructor() {
    this.cookiesAllowed = cookiesAllowed();
  }

  set(key, value) {
    if (this.cookiesAllowed && !!localStorage) {
      try {
        localStorage.setItem(key, value);
        return true;
      } catch (e) {}
    }
    return false;
  }

  get(key) {
    if (this.cookiesAllowed && !!localStorage) {
      try {
        return localStorage.getItem(key);
      } catch (e) {}
    }
    return null;
  }

  remove(key) {
    if (this.cookiesAllowed && !!localStorage) {
      try {
        return localStorage.removeItem(key);
      } catch (e) {}
    }
  }

  clear() {
    if (this.cookiesAllowed && !!localStorage) {
      try {
        return localStorage.clear();
      } catch (e) {}
    }
  }
}

export const storage = new Storage();

export const createAuthedRequestObj = (token, on401) => (
  url,
  method = 'get',
  data = {},
  options = {}
) => {
  let req = createRequestObj(url, method, data, options);
  req.headers = { Authorization: 'Token ' + token };
  return performRequest(req, on401);
};

export const createRequestObj = (url, method, data, options) => {
  let req = {
    url,
    method,
    data,
    responseType: options.responseType || 'json'
  };

  if (options.responseType === 'blob') {
    // Have to unset the 'application/json' content type
    // when we fetch blobs from s3.
    req.transformRequest = [
      (data, headers) => {
        delete headers.common['Content-Type'];
        return data;
      }
    ];
  }
  return req;
};

const performRequest = async (obj, on401) => {
  try {
    const res = await axios(obj);
    return {
      data: res.data,
      status: res.status,
      error: false
    };
  } catch (error) {
    const cpy = { ...error };
    if (cpy && cpy.response) {
      const errorObj = {
        data: cpy.response.data || cpy.response.statusText || 'Error',
        status: cpy.response.status,
        error: true
      };

      if (cpy.response.status === 401) {
        on401 && on401();
      } else if (window.Raven && cpy.response.status !== 404) {
        window.Raven.captureException(error, {
          extra: errorObj
        });
      }
      return errorObj;
    }
  }
  return { error: true };
};

export const request = async (url, method = 'get', data = {}, options = {}) => {
  const obj = createRequestObj(url, method, data, options);
  try {
    const res = await axios(obj);
    return {
      data: res.data,
      status: res.status,
      error: false
    };
  } catch (error) {
    const cpy = { ...error };
    if (cpy && cpy.response) {
      const errorObj = {
        data: cpy.response.data || cpy.response.statusText || 'Error',
        status: cpy.response.status,
        error: true
      };
      if (window.Raven && cpy.response.status !== 404) {
        window.Raven.captureException(error, {
          extra: errorObj
        });
      }
      return errorObj;
    }
  }
  return { error: true };
};
