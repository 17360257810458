import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconIntegrationTimon = props => (
  <Svg32 {...props} viewBox="0 0 40 40">
    <path
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
      fill="#FF493C"
    />
    <path
      d="M26.248 26.686l-3.776-7.468c-.405-.755-1.501-1-2.47-1-.97 0-2.242.187-2.706 1.057l-3.474 7.304c-.119.22-.125.34-.09.424.186.45 1.578.688 3.062.811v2.006a.208.208 0 00.207.207h6.073a.207.207 0 00.206-.207v-1.95c1.472-.1 2.878-.316 3.064-.762.032-.082.021-.181-.096-.422zM20.035 17.544a2.647 2.647 0 100-5.295 2.647 2.647 0 000 5.295zM28.585 15.25a2.64 2.64 0 100-5.28 2.64 2.64 0 000 5.28zM33.057 18.438c-.218-1.886-.747-2.462-2.745-2.462h-3.157c-1.996 0-2.75.311-3.042 2.462l-.096.779 4.14 8.563h3.32a.208.208 0 00.206-.207v-1.866h2.038a.209.209 0 00.207-.207l-.871-7.062zM11.415 15.25a2.64 2.64 0 100-5.28 2.64 2.64 0 000 5.28zM6.943 18.438c.218-1.886.747-2.462 2.744-2.462h3.163c1.998 0 2.752.311 3.042 2.462l.092.75a.1.1 0 01-.008.055l-4.134 8.537h-3.32a.207.207 0 01-.207-.207v-1.866H6.279a.207.207 0 01-.206-.207l.87-7.062z"
      fill="#fff"
    />
  </Svg32>
);
