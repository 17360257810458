import { types as t, flow } from 'mobx-state-tree';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import {
  isSameEditorState,
  placeholdersToBrackets,
  isEditorStateEmpty,
  htmlToEditorState,
  isEditorHtmlEmpty
} from 'components/TextEditor';
import { emailTemplateTypes } from './emailTemplateTypes';
import { EmailTemplate } from './';
import { withEnv } from 'models/withEnv';

export const EmailTemplateEdit = t
  .model('EmailTemplateEdit', {
    original: EmailTemplate,
    contentHtml: '',
    templateType: t.maybe(t.enumeration('EmailTemplateTypesEdit', emailTemplateTypes)),
    title: '',
    isDefault: false,

    // local extras
    saving: false
  })
  .views(withEnv)
  .views(self => ({
    get canSave() {
      const contentEs = htmlToEditorState(self.contentHtml);
      if (self.saving || !self.title || isEditorStateEmpty(contentEs)) {
        return false;
      }
      return self.dirty;
    },
    get diff() {
      const d = ['title', 'isDefault', 'templateType'].reduce((obj, key) => {
        if (self[key] !== self.original[key]) {
          obj[key] = self[key];
        }
        return obj;
      }, {});
      const bothEmpty =
        isEditorHtmlEmpty(self.contentHtml) && isEditorHtmlEmpty(self.original.contentHtml);
      const sameState = isSameEditorState(self.contentHtml, self.original.contentHtml);
      const isSameContent = bothEmpty || sameState;
      if (!isSameContent) {
        d.contentHtml = self.contentHtml;
      }
      return d;
    },
    get dirty() {
      return Object.keys(self.diff).length > 0;
    }
  }))
  .actions(self => ({
    create: flow(function*() {
      self.saving = true;
      const data = {
        templateType: self.templateType,
        title: self.title,
        isDefault: self.isDefault,
        contentHtml: self.contentHtml
      };
      const res = yield self.env.api.companies.createTemplate(data);
      if (!res.error) {
        self.env.templateStore.addEmailTemplate(res.data);
        self.env.notify('success', self.env.t('company.templates.email.create.success'));
        self.env.router.replace(`/templates/emails/${res.data.id}`);
      } else {
        self.env.notify('error', self.env.t('company.templates.email.create.error'));
      }
      self.saving = false;
    }),
    update: flow(function*() {
      self.saving = true;
      const res = yield self.env.api.companies.updateTemplate(self.original.id, self.diff);
      if (!res.error) {
        if (self.isDefault && !self.original.isDefault) {
          self.env.templateStore.emailTemplates
            .filter(template => {
              return template.id !== self.original.id && template.isDefault;
            })
            .forEach(template => template.update({ isDefault: false }));
          // Since its now default we need to set others to not default
        }
        self.env.templateStore.emailTemplates
          .find(({ id }) => self.original.id === id)
          .update(self.diff);
        self.original.update(self.diff);
        self.env.notify('success', self.env.t('company.templates.email.update.success'));
      } else {
        const str = res.data.join('. ');
        self.env.notify('error', self.env.t('company.templates.email.update.error', str));
      }
      self.saving = false;
    }),
    save: flow(function*() {
      if (self.original.id === -1) {
        yield self.create();
      } else {
        yield self.update();
      }
    }),
    reset() {
      self.title = self.original.title || '';
      self.templateType = self.original.templateType;
      self.contentHtml = self.original.contentHtml;
      self.isDefault = self.original.isDefault;
    },
    afterCreate() {
      self.reset();
    },
    setTitle(newTitle) {
      self.title = newTitle;
    },
    setType(newType) {
      self.templateType = newType;
    },
    setContentFromEditorState(editorState) {
      const rawData = convertToRaw(editorState.getCurrentContent());
      const converted = placeholdersToBrackets(rawData);
      self.contentHtml = draftToHtml(converted);
    },
    toggleDefault() {
      self.isDefault = !self.isDefault;
    }
  }));
