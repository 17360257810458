import styled from 'styled-components/macro';

export const Alert = styled.div`
  background-color: ${props =>
    props.type === 'warning' ? props.theme.colors.orange20 : props.theme.colors.red50};
  color: ${props =>
    props.type === 'warning' ? props.theme.colors.orange100 : props.theme.colors.red100};
  border: 1px solid
    ${props =>
      props.type === 'warning' ? props.theme.colors.orange100 : props.theme.colors.red100};
  border-radius: 2px;
  padding: 8px;
`;
