import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { FormLabel, Text } from 'components/Text';
import { UnauthedPage } from 'components/UnauthedPage';
import { H1, P } from 'components/Text';
import { Box } from 'components/Box';

export const ForgotPassword = observer(props => {
  const { email, onChange, onSubmit, error, disableSubmit, submitted, t } = props;
  let submitLabel = disableSubmit ? t('forgotpw.button.submitting') : t('forgotpw.button.submit');

  return (
    <UnauthedPage>
      {submitted && (
        <div>
          <H1>{t('forgotpw.emailsent.heading')}</H1>
          <P>
            {t('forgotpw.emailsent.text')}
            <Text color="green100">
              <a href={'mailto:' + email}>{email}</a>
            </Text>
          </P>
        </div>
      )}

      {!submitted && (
        <div>
          <H1>{t('forgotpw.heading')}</H1>
          <P>{t('forgotpw.instruction')}</P>
          <Box mt="16">
            <FormLabel>{t('forgotpw.label.email')}</FormLabel>
            <Input
              value={email}
              onChange={val => onChange('email', val)}
              error={error}
              disabled={disableSubmit}
            />
          </Box>
          <Actions mt="24" justifyContent="space-between" alignItems="center">
            <Button disabled={disableSubmit} onClick={onSubmit}>
              {submitLabel}
            </Button>
            <Link to="/signin">
              <Text type="regular14" color="purple40">
                {t('forgotpw.button.back')}
              </Text>
            </Link>
          </Actions>
        </div>
      )}
    </UnauthedPage>
  );
});

const Actions = styled(Box)(p => ({
  display: 'flex',
  [p.theme.mobileQuery]: {
    display: 'block',
    '> *': {
      textAlign: 'center',
      width: '100%'
    },
    '> a': {
      display: 'block',
      marginTop: p.theme.spacing.m12
    }
  }
}));
