import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  useMenuState,
  Menu as RMenu,
  MenuItem as RMenuItem,
  MenuDisclosure as RMenuDisclosure,
  MenuSeparator as RMenuSeparator
} from 'reakit/Menu';

const MenuContext = React.createContext();

const Menu = observer(({ children, placement = 'bottom-end', label = 'Options' }) => {
  const [show, setShow] = React.useState(false);
  const menu = useMenuState({ placement, loop: true });
  return (
    <MenuContext.Provider value={{ menu, label, show, setShow }}>{children}</MenuContext.Provider>
  );
});

const MenuItems = observer(({ children, portal = true, ...rest }) => {
  const { menu, label, show } = React.useContext(MenuContext);

  if (!show) return null;

  return (
    <StyledMenu
      data-menu-items
      hideOnClickOutside
      {...menu}
      aria-label={label}
      tabIndex={0}
      unstable_portal={portal}
      {...rest}
    >
      {children}
    </StyledMenu>
  );
});

const MenuSeparator = observer(() => {
  const { menu } = React.useContext(MenuContext);
  return <StyledMenuSeparator {...menu} />;
});

const MenuItem = observer(
  ({
    disabled,
    onClick,
    children,
    variant = 'base',
    stopPropagation = true,
    hideOnClick = true,
    active = false,
    ...rest
  }) => {
    const { menu } = React.useContext(MenuContext);
    return (
      <StyledMenuItem
        data-menu-item
        disabled={disabled}
        active={active}
        onClick={e => {
          hideOnClick && menu.hide();
          stopPropagation && e.stopPropagation();
          onClick && onClick(e);
        }}
        variant={variant}
        {...menu}
        {...rest}
      >
        {children}
      </StyledMenuItem>
    );
  }
);

const MenuButton = observer(({ children, transparent = false, variant = 'icon', ...rest }) => {
  const { menu, show, setShow } = React.useContext(MenuContext);

  if (variant === 'unstyled') {
    return (
      <UnstyledMenuButton
        data-menu-button
        onFocus={!show ? () => setShow(true) : null}
        onMouseEnter={!show ? () => setShow(true) : null}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...menu}
        {...rest}
      >
        {children}
      </UnstyledMenuButton>
    );
  }
  return (
    <StyledMenuButton
      data-menu-button
      transparent={transparent}
      onFocus={!show ? () => setShow(true) : null}
      onMouseEnter={!show ? () => setShow(true) : null}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      {...menu}
      {...rest}
    >
      {children}
    </StyledMenuButton>
  );
});

const TransparentMenuButton = observer(props => <MenuButton {...props} transparent />);

const MenuHeader = styled.div(p => ({
  ...p.theme.text.bold12,
  color: p.theme.colors.purple80,
  textTransform: 'uppercase',
  paddingLeft: '0.5rem',
  height: '2.5rem',
  display: 'flex',
  alignItems: 'center'
}));

export { Menu, MenuButton, MenuHeader, MenuItem, MenuItems, MenuSeparator, TransparentMenuButton };

const UnstyledMenuButton = styled(RMenuDisclosure)(p => ({
  position: 'relative',
  cursor: 'inherit',
  color: 'inherit'
}));

const StyledMenuButton = styled(({ transparent, ...rest }) => <RMenuDisclosure {...rest} />)(p => ({
  borderRadius: '0.25rem',
  height: '2.5rem',
  minHeight: '2.5rem',
  //width: '100%',
  minWidth: '2.5rem',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 200ms ease-out',
  color: p.theme.colors.purple50,
  backgroundColor: p.transparent ? 'transparent' : p.theme.colors.purpleTrans5,

  ':hover, :focus': {
    cursor: 'pointer',
    backgroundColor: p.transparent ? p.theme.colors.purpleTrans5 : p.theme.colors.purpleTrans10,
    color: p.theme.colors.purple80
  }
}));

const StyledMenuSeparator = styled(RMenuSeparator)(p => ({
  borderColor: p.theme.purpleTrans10,
  margin: p.theme.spacing.m8,
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none'
}));

const StyledMenu = styled(RMenu)(p => ({
  backgroundColor: '#fff',
  width: p.width || '14.5rem',
  minWidth: '14.5rem',
  padding: '1rem',
  zIndex: 10000,
  position: 'relative',
  boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.1)',
  borderRadius: '0.25rem',

  ':focus': {
    outline: 'none'
  }
}));

const StyledMenuItem = styled(({ variant, active, ...rest }) => <RMenuItem {...rest} />)(p => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  width: '100%',
  color: !p.disabled ? p.theme.colors.purple80 : p.theme.colors.purple20,
  transition: 'all 100ms ease-out',
  ...(!p.disabled && {
    ':focus, :hover': {
      color: p.variant === 'danger' ? p.theme.colors.red100 : p.theme.colors.green100,
      backgroundColor: p.theme.colors.purpleTrans5,
      cursor: 'pointer',
      borderRadius: '0.25rem'
    }
  }),
  ...(p.active && {
    color: p.variant === 'danger' ? p.theme.colors.red100 : p.theme.colors.green100,
    backgroundColor: p.theme.colors.purpleTrans5,
    borderRadius: '0.25rem'
  }),
  ...p.theme.text.regular14,
  lineHeight: '1.5rem',
  padding: `${p.theme.spacing.m8} ${p.theme.spacing.m12}`
}));

if (process.env.NODE_ENV === 'development') {
  Menu.propTypes = {
    label: PropTypes.string.isRequired,
    placement: PropTypes.oneOf([
      'auto-start',
      'auto',
      'auto-end',
      'top-start',
      'top',
      'top-end',
      'right-start',
      'right',
      'right-end',
      'bottom-end',
      'bottom',
      'bottom-start',
      'left-end',
      'left',
      'left-start'
    ])
  };
}
