import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from 'components/IconButton';
import { IconClose } from 'components/icons/IconClose';
import { Header } from 'components/Header';
import { useStore } from 'models/Provider';
import { BreadCrumb, BreadCrumbPath } from 'components/BreadCrumb';
import { useMobile } from 'hooks/useMobile';

export const ApplicationPageHeader = observer(({ application }) => {
  const { state } = useLocation();
  const isMobile = useMobile();
  let actions;
  if (state && state.onCloseUrl && !isMobile) {
    actions = (
      <Link to={state.onCloseUrl}>
        <IconButton icon={IconClose} />
      </Link>
    );
  }
  return (
    <Header
      breadcrumbs={<Crumbs application={application} />}
      actions={actions}
      mobileTitle={application.name}
    />
  );
});

const Crumbs = observer(({ application }) => {
  const { t } = useStore();
  return (
    <BreadCrumb>
      <BreadCrumbPath link="/jobs">{t('dashboard.tabs.jobs')}</BreadCrumbPath>
      <BreadCrumbPath link={`/jobs/${application.job.id}`}>{application.job.title}</BreadCrumbPath>
      <BreadCrumbPath active>{application.name}</BreadCrumbPath>
    </BreadCrumb>
  );
});
