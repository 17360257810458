import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { UserModal } from 'components/UserModal';
import { CreateUserForm } from 'components/CreateUserForm';

export const UserModalCreate = observer(({ closeModal }) => {
  const { userStore, jobStore, t } = useStore();
  const [disabled, setDisabled] = useState(false);

  async function saveUser() {
    setDisabled(true);
    const success = await userStore.editing.create();
    if (success) {
      closeModal();
    } else {
      setDisabled(false);
    }
  }

  useEffect(() => {
    userStore.setEditing({ id: -1 });
    return () => userStore.clearEditing();
  }, [userStore]);

  const user = userStore.editing;
  if (!user) return null;
  return (
    <UserModal title={t('user.create.heading')}>
      <CreateUserForm
        t={t}
        user={user}
        close={closeModal}
        jobs={jobStore.notArchived}
        save={saveUser}
        errors={user.errors}
        disabled={disabled || !user.name || !user.email}
      />
    </UserModal>
  );
});
