import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import moment from 'moment';

import { abbreviation } from 'shared/utils/strings';

import { useStore } from 'models/Provider';

import { Box } from 'components/Box';
import { Text } from 'components/Text';
import { ApplicationLabels } from 'components/ApplicationLabels';
import { IconRating20 } from 'components/icons/IconRating20';
import { IconChat20 } from 'components/icons/IconChat20';
import { IconNotes20 } from 'components/icons/IconNotes20';
import { IconFiles20 } from 'components/icons/IconFiles20';
import { IconAssignee20 } from 'components/icons/IconAssignee20';
import { IconTasklist20 } from 'components/icons/IconTasklist20';
import { Card, Name, SectionContent } from 'components/CardList';

export const LargeApplicationCard = observer(({ a }) => {
  const { t, userStore } = useStore();
  const tip = `card-${a.id}`;
  const assignedTo = a.assignedTo && userStore.items.get(a.assignedTo);
  const date = moment(a.date).format(' MMMM Do YYYY');
  return (
    <Link to={`/jobs/${a.jobId}/application/${a.id}`}>
      <Box mb={8}>
        <Card>
          <ApplicationStatusBorder isHired={a.isHired} isRejected={a.isRejected} />
          <Box flexDirection="column" m={8} grow>
            <Box>
              <Name>{a.jobTitle}</Name>
              <Text type="semibold12" color="purple50">
                {t('job.applicants.label.applied')}: {date}
              </Text>
              <Text type="semibold12" color="purple50">
                {t('job.applicants.label.stage')}: {a.stageName}
              </Text>
            </Box>
            <Box flex wrap>
              {a.numReviews > 0 && (
                <SectionContent
                  data-for={tip}
                  data-tip={`Applicant has been rated ${a.numReviews} time(s).`}
                  icon={IconRating20}
                  value={a.numReviews}
                />
              )}
              {a.numMessages > 0 && (
                <SectionContent
                  data-for={tip}
                  data-tip={`Applicant has ${a.numMessages} message(s).`}
                  icon={IconChat20}
                  value={a.numMessages}
                />
              )}
              {a.numNotes > 0 && (
                <SectionContent
                  data-for={tip}
                  data-tip={`Applicant has ${a.numNotes} note(s).`}
                  icon={IconNotes20}
                  value={a.numNotes}
                />
              )}
              {a.numAttachments > 0 && (
                <SectionContent
                  data-for={tip}
                  data-tip={`Applicant has ${a.numAttachments} attached file(s).`}
                  icon={IconFiles20}
                  value={a.numAttachments}
                />
              )}
              {a.assignedTo && (
                <SectionContent
                  data-for={tip}
                  data-tip={`Applicant has been assigned to ${assignedTo.name}.`}
                  icon={IconAssignee20}
                  value={abbreviation(assignedTo.name)}
                />
              )}
              {a.taskListItems && a.taskListItems.length > 0 && a.taskListItems[0].total > 0 && (
                <SectionContent
                  data-for={tip}
                  data-tip={`The onboarding tasklist for this applicant has ${Number(
                    a.taskListItems[0].total
                  ) - Number(a.taskListItems[0].checked)} unfinished tasks.`}
                  icon={IconTasklist20}
                  value={`${a.taskListItems[0].checked} / ${a.taskListItems[0].total}`}
                />
              )}
            </Box>
          </Box>
          <Labels show={a.referredBy || a.isRejected || a.isNew || a.isRetracted}>
            <ApplicationLabels application={a} />
          </Labels>
        </Card>
      </Box>
    </Link>
  );
});

const ApplicationStatusBorder = styled.div(p => ({
  width: 4,
  backgroundColor: p.isHired
    ? p.theme.colors.green100
    : p.isRejected
    ? p.theme.colors.red100
    : 'transparent',
  borderRadius: 4
}));

const Labels = styled.div(p => ({
  display: 'none',
  ...(p.show && {
    marginTop: p.theme.spacing.m8,
    marginRight: p.theme.spacing.m8,
    display: 'flex'
  })
}));
