import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const isSameEditorState = (first, second) => {
  if (!first || !second) {
    return first === second;
  }

  // This is disgusting but the editor can have some invisible characters
  // and newlines so we need to construct a new clean editorstate to compare.
  const prev = htmlToDraft(first);
  const prevContentState = ContentState.createFromBlockArray(prev.contentBlocks);
  const prevEditorState = EditorState.createWithContent(prevContentState);
  const curr = htmlToDraft(second);
  const currContentState = ContentState.createFromBlockArray(curr.contentBlocks);
  const currEditorState = EditorState.createWithContent(currContentState);
  const prevStr = draftToHtml(convertToRaw(prevEditorState.getCurrentContent()));
  const currStr = draftToHtml(convertToRaw(currEditorState.getCurrentContent()));
  return prevStr === currStr;
};

export const isEditorHtmlEmpty = html => {
  if (!html) return true;
  const { contentBlocks: blocks } = htmlToDraft(html);
  return blocks.length === 1 && blocks[0].text === '';
};

export const isEditorStateEmpty = editorState => {
  if (!editorState) return true;

  const raw = convertToRaw(editorState.getCurrentContent());
  return raw.blocks.length === 1 && raw.blocks[0].text === '';
};

export const htmlToEditorState = html => {
  if (!html) return EditorState.createEmpty();

  const contentBlock = htmlToDraft(html);
  if (!contentBlock) return EditorState.createEmpty();

  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  return EditorState.createWithContent(contentState);
};

export const makeEditorState = val => {
  if (!val) return EditorState.createEmpty();

  try {
    const data = JSON.parse(val);
    const editorState = EditorState.createWithContent(convertFromRaw(data));
    return EditorState.moveSelectionToEnd(editorState);
  } catch (e) {}

  try {
    const contentBlock = htmlToDraft(val);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return EditorState.moveSelectionToEnd(editorState);
    }
  } catch (e) {}

  return EditorState.createEmpty();
};

export const editorStateToHtml = editorState => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};
