import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Manager, Reference, Popper } from 'react-popper';

import ClickOutside from 'shared/layout/ClickOutside';
import { getElementHeight, getVisibleHeight } from 'shared/utils/browser';

import {
  Dropdown,
  DropdownContainer,
  DropdownDisplay,
  DropdownItem,
  DropdownTitle,
  DropdownTitleContainer,
  Error,
  HelperText
} from './styles';

function FormDropDown({
  className,
  items = [],
  onChange,
  defaultValue = null,
  error,
  fullSize = true,
  title,
  bgColor,
  height,
  disableSelect,
  getDisplayText,
  getTitleDisplayText,
  disable,
  inModal,
  variant,
  dataId
}) {
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(320);
  const container = useRef(null);

  let def = null;
  if (defaultValue !== null) {
    def = items.find(item => item.value === defaultValue);
  }
  const [chosen, setChosen] = useState(def ? def.display : '');

  function click() {
    if (disable) return;
    setOpen(old => {
      if (inModal && old) {
        resetModalOverflow();
      }
      return !old;
    });
  }

  function onOutsideClick(e) {
    if (open) {
      e.preventDefault();
      e.stopPropagation();
      click();

      if (inModal) {
        resetModalOverflow();
      }
    }
  }

  function resetModalOverflow() {
    const mdl = document.getElementById('modal');
    mdl.parentNode.style.overflow = 'auto';
  }

  function select(event, d) {
    event.preventDefault();
    event.stopPropagation();

    if (disableSelect) return;
    setChosen(d.display);
    if (onChange) {
      onChange(d.value, d.link);
    }
    setOpen(false);
    if (inModal) {
      resetModalOverflow();
    }
  }

  function fixModalScroll() {
    if (!inModal) return;
    const mdl = document.getElementById('modal');
    const mdlHt = getElementHeight(mdl);
    const ddHt = getElementHeight(container.current);
    const viewHt = getVisibleHeight();
    if (mdlHt + ddHt >= viewHt - 50) {
      mdl.parentNode.style.overflow = 'auto';
    } else {
      mdl.parentNode.style.overflow = 'visible';
    }
  }

  function onClick() {
    if (disable) return;

    fixModalScroll();
    if (container.current) {
      const rect = container.current.getBoundingClientRect();
      const bottomDiff = window.innerHeight - rect.bottom;

      if (bottomDiff < 320) {
        const topDiff = rect.top;
        if (bottomDiff > topDiff) {
          setMaxHeight(bottomDiff - 10);
        } else if (topDiff < 320) {
          setMaxHeight(topDiff - 10);
        } else {
          setMaxHeight(320);
        }
      } else if (maxHeight !== 320) {
        setMaxHeight(320);
      }
    }
  }

  let titleDisplay = chosen || title;
  if (getDisplayText) {
    titleDisplay = getDisplayText(chosen);
  }
  if (getTitleDisplayText) {
    titleDisplay = getTitleDisplayText(chosen);
  }

  return (
    <ClickOutside onOutsideClick={onOutsideClick}>
      <div ref={container} onClick={onClick} style={{ position: 'relative' }} className={className}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <DropdownTitleContainer
                variant={variant}
                ref={ref}
                error={error}
                onClick={click}
                bgColor={bgColor}
                fullSize={fullSize}
                disable={disable}
                height={height}
                data-id={dataId}
              >
                <DropdownTitle>{titleDisplay}</DropdownTitle>
              </DropdownTitleContainer>
            )}
          </Reference>
          {open && (
            <Popper placement="bottom-end" modifiers={popperModifiers}>
              {({ ref, style, placement, arrowProps }) => (
                <DropdownContainer
                  variant={variant}
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  fullSize={fullSize}
                >
                  <Dropdown open={open} maxHeight={maxHeight + 'px'}>
                    {items.map((item, i) => (
                      <DropdownItem
                        variant={variant}
                        key={i}
                        danger={item.danger}
                        onClick={item.readOnly ? null : e => select(e, item)}
                        readOnly={item.readOnly}
                      >
                        <div>
                          <DropdownDisplay variant={variant}>
                            {getDisplayText ? getDisplayText(item.display) : item.display}
                          </DropdownDisplay>
                          {item.helpText && <HelperText>{item.helpText}</HelperText>}
                        </div>
                      </DropdownItem>
                    ))}
                  </Dropdown>
                  <div ref={arrowProps.ref} style={arrowProps.style} />
                </DropdownContainer>
              )}
            </Popper>
          )}
        </Manager>
        {error && <Error>{error}</Error>}
      </div>
    </ClickOutside>
  );
}

export default observer(FormDropDown);

let popperModifiers = {
  preventOverflow: { enabled: false },
  hide: { enabled: false }
};
