import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components/macro';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const StyledComponent = styled.div(p => ({
  '.react--tooltip': {
    maxWidth: '210px !important',
    textAlign: 'center !important',
    backgroundColor: p.theme.colors.purple90 + ' !important',
    color: p.theme.colors.white100,
    opacity: 1,
    ...p.theme.text.semibold14,
    borderRadius: '2px',
    left: 60,
    top: 8,
    zIndex: 1001
  }
}));

export const GlobalTooltipStyle = createGlobalStyle`
  .react--tooltip.place-top::after,
  .react--tooltip.place-bottom::after {
    border-color: ${p => p.theme.colors.purple100} transparent !important;
  }
  .react--tooltip.place-right::after,
  .react--tooltip.place-left::after {
    border-color: transparent ${p => p.theme.colors.purple100} !important;
  }
`;

const isMobile = 'ontouchstart' in document.documentElement;

export const Tooltip = ({ id, place, effect }) => {
  useEffect(() => {
    if (!isMobile) {
      ReactTooltip.rebuild();
    }
  });

  if (isMobile) return null;

  return createPortal(
    <StyledComponent>
      <ReactTooltip id={id} place={place} className="react--tooltip" effect={effect} />
    </StyledComponent>,
    document.getElementById('tooltip-root')
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  effect: PropTypes.oneOf(['solid', 'float'])
};

Tooltip.defaultProps = {
  place: 'top',
  effect: 'solid'
};
