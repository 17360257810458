export const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isValidSsn = ssn => {
  if (!ssn) return false;
  ssn = ssn
    .trim()
    .replace('-', '')
    .replace(' ', '');
  if (ssn.length !== 10) return false;
  let sSum =
    3 * parseInt(ssn.substr(0, 1), 10) +
    2 * parseInt(ssn.substr(1, 1), 10) +
    7 * parseInt(ssn.substr(2, 1), 10) +
    6 * parseInt(ssn.substr(3, 1), 10) +
    5 * parseInt(ssn.substr(4, 1), 10) +
    4 * parseInt(ssn.substr(5, 1), 10) +
    3 * parseInt(ssn.substr(6, 1), 10) +
    2 * parseInt(ssn.substr(7, 1), 10);
  let modRes = sSum % 11;
  if (modRes > 0) modRes = 11 - modRes;
  if (modRes !== parseInt(ssn.substr(8, 1), 10)) return false;
  var century = parseInt(ssn.substr(9, 1), 10);
  if (isNaN(century) || (century !== 0 && century !== 9 && century !== 8)) return false;
  return true;
};

export const isOnlyDigits = value => {
  return /^\d+$/.test(value);
};

export const bankingNumber = value => {
  return value.length <= 4;
};

export const ledger = value => {
  return value.length === 2;
};

export const accountNumber = value => {
  return value.length <= 6;
};
