import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconCareers = props => (
  <Svg {...props}>
    <path
      d="M5 18h10l5-6-5-6H5l5 6-5 6z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
