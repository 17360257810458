import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import FormLabel from 'shared/forms/FormLabel';

import { Checkbox } from 'components/Checkbox';
import { Box } from 'components/Box';

export const CreateUserJobAccessForm = observer(({ user, jobs, t }) => {
  const followingAll = jobs.every(job => user.followingJobs.includes(job.id));
  const followAll = () => jobs.forEach(job => user.followJob(job.id));
  const unfollowAll = () => jobs.forEach(job => user.unfollowJob(job.id));
  return (
    <div>
      <Box flex justifyContent="space-between">
        <FormLabel>{t('user.accessform.heading')}</FormLabel>
        <FollowAll onClick={followingAll ? unfollowAll : followAll}>
          {followingAll ? t('user.accessform.deselect') : t('user.accessform.select')}
        </FollowAll>
      </Box>
      <Box mt="4">
        {jobs.map(j => {
          const isFollowing = user.followingJobs.includes(j.id);
          const onClick = isFollowing ? () => user.unfollowJob(j.id) : () => user.followJob(j.id);

          return (
            <JobAccessCard key={j.id}>
              <Checkbox checked={isFollowing} onClick={onClick} />
              <div>
                <div>{j.title}</div>
                <div>
                  {j.jobTypeDisplay} {j.jobTypeDisplay && '|'} {j.shortDeadlineDisplay}
                </div>
              </div>
              {j.isPublished ? (
                <div>
                  <div>{j.applications.length}</div>
                  <div>{t('user.accessform.applicants')}</div>
                </div>
              ) : (
                <div>{t('user.accessform.draft')}</div>
              )}
            </JobAccessCard>
          );
        })}
      </Box>
    </div>
  );
});

const FollowAll = styled.div(p => ({
  ':hover': {
    cursor: 'pointer'
  },
  fontSize: 14,
  opacity: 0.8,
  color: p.theme.colors.purple40,
  fontStyle: 'italic'
}));

const JobAccessCard = styled.div`
  min-height: 40px;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.purpleTrans10};
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 5px;
  color: #757575;
  padding: 10px 20px;

  > div:nth-child(1) {
    margin: 0 20px 0 0;
  }

  > div:nth-child(2) {
    display: block;
    flex: 1;
    > div:nth-child(1) {
      font-weight: bold;
      word-break: break-word;
    }
    > div:nth-child(2) {
      font-size: 14px;
    }
  }

  > div:nth-child(3) {
    margin: 0 0 0 10px;
    min-width: 47px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div:nth-child(1) {
      font-size: 16px;
      font-weight: bold;
    }
    > div:nth-child(2) {
      font-size: 10px;
    }
  }
`;
