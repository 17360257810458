import moment from 'moment';
import { types as t, getParent, flow } from 'mobx-state-tree';

import { sortByCount, updateMSTObject } from 'helpers';
import { User } from 'models/users';
import { withEnv } from 'models/withEnv';

const JobStatisticsCount = t.model('JobStatisticsCount', {
  hired: t.number,
  rejected: t.number,
  applicants: t.number,
  totalViews: t.number,
  contentCleared: t.maybe(t.number)
});

const JobStatisticsSourcedUrl = t.model('JobStatisticsSourcedUrl', {
  count: t.number,
  hired: t.number
});

const JobStatisticsSources = t.model('JobStatisticsSources', {
  manuallyAdded: t.number,
  sourced: t.number,
  urls: t.map(JobStatisticsSourcedUrl)
});

const JobStatisticsSourcingEmployee = t.model('JobStatisticsSourcingEmployee', {
  id: t.maybe(t.reference(t.late(() => User))),
  hired: t.number,
  count: t.number
});

export const JobStatistics = t
  .model('JobStatistics', {
    lastLoad: t.maybe(t.Date),
    loading: false,
    loaded: false,
    counts: t.maybe(JobStatisticsCount),
    sources: t.maybe(JobStatisticsSources),
    stages: t.maybe(t.map(t.number)),
    sourcingEmployees: t.maybe(t.map(JobStatisticsSourcingEmployee))
  })
  .views(withEnv)
  .views(self => ({
    get job() {
      return getParent(self).id;
    },
    get sortedEmployeeSources() {
      let data = [];
      for (let val of self.sourcingEmployees.values()) {
        data.push({ user: val.id, count: val.count, hired: val.hired });
      }
      return sortByCount(data);
    },
    get sortedSources() {
      let data = [];
      for (let [url, values] of self.sources.urls.entries()) {
        data.push({ url, count: values.count, hired: values.hired });
      }
      return sortByCount(data);
    }
  }))
  .actions(self => ({
    getDateForFilter(filter) {
      switch (filter) {
        case 'today':
          return moment().format('YYYY-MM-DD');
        case 'seven':
          return moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD');
        case 'thirty':
          return moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DD');
        default:
          return undefined;
      }
    },
    load() {
      if (self.loaded) {
        // Reload at most every hour.
        // Beyond that is useless waste of data fetching.
        const nextAllowedLoad = moment(self.lastLoad).add(1, 'h');
        const isAfter = moment().isAfter(nextAllowedLoad);
        if (!isAfter) {
          return;
        }
      }
      self.update();
      self.lastLoad = moment().toDate();
    },
    update: flow(function*(fromDate) {
      self.loading = true;
      const res = yield self.env.api.jobs.loadStatistics(self.job, fromDate);
      if (!res.error) {
        updateMSTObject(self, res.data);
        self.loaded = true;
      }
      self.loading = false;
    }),
    setFilter(filter) {
      if (self.display === `job.statistics.${filter}`) return;
      self.update(self.getDateForFilter(filter));
    }
  }));
