import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconNotes20 = props => (
  <Svg {...props} viewBox="0 0 20 20">
    <path
      d="M5 7h10M5 10h10M5 13h5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
