import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';

const Spinner = props => (
  <StyledLoading viewBox="0 0 50 50" {...props}>
    <circle cx="25" cy="25" r="20" fill="none" strokeWidth="3" strokeLinecap="round" />
  </StyledLoading>
);

export const Loading = ({ delay = 0, ...rest }) => {
  const [show, setShow] = useState(delay === 0);
  useEffect(() => {
    if (delay) {
      const to = setTimeout(() => setShow(true), delay);
      return () => window.clearTimeout(to);
    }
  }, [delay]);
  return show ? <Spinner {...rest} /> : null;
};

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const rotateRule = css`
  ${rotate} 2s linear infinite
`;

const dashRule = css`
  ${dash} 1.5s ease-in-out infinite
`;

const StyledLoading = styled.svg`
  animation: ${rotateRule};
  position: ${p => (p.fixed ? 'fixed' : 'absolute')};
  top: 50%;
  left: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
  height: 3rem;
  circle {
    stroke: ${p => p.theme.colors.green100};
    animation: ${dashRule};
  }
`;
