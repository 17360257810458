import './pre-build';

import React from 'react';
import { render } from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from 'components/GlobalStyles';
import { GlobalTooltipStyle } from 'components/Tooltip';
import AuthProvider from 'shared/auth/AuthProvider';
import ScrollToTop from 'shared/layout/ScrollToTop';

import { theme } from './theme';
import SetPasswordPage from './pages/SetPassword';
import ForgotPasswordPage from './pages/ForgotPassword';
import SigninPage from './pages/Signin';

import { Provider } from 'models/Provider';
import { RootStore } from 'models/RootStore';
import { API_URL } from 'api';
import history from 'routing/history';
import { App } from './App';

const config = {
  authUrl: `${API_URL}authenticate/`,
  setPasswordUrl: `${API_URL}password/`,
  resetPasswordUrl: `${API_URL}password/reset/`
};

const rootStore = new RootStore({ config, history, theme });

window.root = rootStore;

render(
  <Provider store={rootStore}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <ScrollToTop />
          <GlobalTooltipStyle />
          <Switch>
            <Route
              path="/forgotpassword"
              exact
              render={props => <ForgotPasswordPage {...props} />}
            />
            <Route path="/setpassword" exact render={props => <SetPasswordPage {...props} />} />
            <Route path="/signin" exact render={props => <SigninPage {...props} />} />
            <Route
              render={() => (
                <AuthProvider authStore={rootStore.auth}>
                  <App />
                </AuthProvider>
              )}
            />
          </Switch>
        </>
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'production' && window.Raven) {
  window.Raven.config('https://acb7e4ffe2124a69b96abe4a4752547a@sentry.io/1215869').install();
}
