import React, { useState, Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import PrevIcon from 'shared/icons/PrevArrow';
import NextIcon from 'shared/icons/NextArrow';

import DownloadIcon from 'components/icons/IconDownload';
import { BreadCrumbPath } from 'components/BreadCrumb';

import { useStore } from 'models/Provider';

const PDF = React.lazy(() => import('./PDF' /* webpackChunkName: 'cv' */));

export const HeaderContainer = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 0 0 20px;
  @media (max-width: 480px) {
    padding: 0 10px;
  }
  height: 76px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4f4f74;
  z-index: 1000;
  background-color: #3c3c64;

  > div:nth-child(1) {
    flex-grow: 1;
    height: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  > div:nth-child(1) {
    margin-right: 24px;
    @media (max-width: 480px) {
      margin-right: 10px;
    }
  }
`;

const PDFContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 76px 0 0 0;
`;

const Download = styled.div`
  opacity: 0.8;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  margin-right: 75px;
  :hover {
    opacity: 1;
  }
`;

const PrevNextContainer = styled.div`
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100%;
  border-left: 1px solid #4f4f74;
  :hover {
    opacity: 1;
  }
  > span:nth-child(1) {
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
  }
  > span:nth-child(2) {
    padding: 10px;
    cursor: pointer;
  }
`;

const Close = styled.div`
  opacity: 0.8;
  font-size: 20px;
  padding: 32px;
  border-left: 1px solid #4f4f74;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

function FilePreviewModal({ application, value, attachments, closeModal }) {
  const { t } = useStore();

  const [id, setID] = useState(value);

  const attachment = attachments.find(a => a.id === id);
  attachment.loadAttachment();

  const index = attachments.indexOf(attachment);
  const nextAttachmentValue = attachments[(index + 1) % attachments.length].id;
  const prevAttachmentValue = attachments[(index - 1 + attachments.length) % attachments.length].id;

  return (
    <>
      {attachment.loaded && (
        <>
          <HeaderContainer>
            <Container>
              <BreadCrumbPath active>{attachment.fileName}</BreadCrumbPath>
            </Container>
            <a href={attachment.attachment} download target="_blank" rel="noopener noreferrer">
              <Download>
                <DownloadIcon />
                <div style={{ marginLeft: 10 }}>{t('application.attachment.download')}</div>
              </Download>
            </a>
            <PrevNextContainer>
              <span onClick={attachments.length === 1 ? null : () => setID(prevAttachmentValue)}>
                <PrevIcon />
              </span>
              <span onClick={attachments.length === 1 ? null : () => setID(nextAttachmentValue)}>
                <NextIcon />
              </span>
            </PrevNextContainer>
            <Close onClick={closeModal}>x</Close>
          </HeaderContainer>
          {attachment.blob !== null && (
            <PDFContainer>
              <Suspense fallback={<div />}>
                <PDF file={attachment.blob} name={attachment.attachment} />
              </Suspense>
            </PDFContainer>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(observer(FilePreviewModal));
