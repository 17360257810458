import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { UserAvatar } from 'components/UserAvatar';
import ClickOutside from 'shared/layout/ClickOutside';
import { getVisibleWidth } from 'shared/utils/browser';

import { useStore } from 'models/Provider';
import { Menu, MenuButton, MenuHeader, MenuItem, MenuItems, MenuSeparator } from 'components/Menu';
import { Icon50skills } from 'components/icons/Icon50skills';
import { IconApplicants } from 'components/icons/IconApplicants';
import { IconCareers } from 'components/icons/IconCareers';
import { IconCompany } from 'components/icons/IconCompany';
import { IconDashboard } from 'components/icons/IconDashboard';
import { IconOnboarding } from 'components/icons/IconOnboarding';
import { IconNotifications } from 'components/icons/IconNotifications';
import { IconShare } from 'components/icons/IconShare';
import { IconSwap } from 'components/icons/IconSwap';
import { IconTeam } from 'components/icons/IconTeam';
import { IconButton } from 'components/IconButton';
import { Tooltip } from 'components/Tooltip';

const items = [
  { icon: IconDashboard, path: '/jobs', label: 'navigation.dashboard' },
  { icon: IconApplicants, path: '/applicants', label: 'navigation.applicants' },
  { icon: IconOnboarding, path: '/onboarding', label: 'navigation.onboarding' },
  { icon: IconTeam, path: '/team', label: 'navigation.team' },
  { icon: IconShare, path: '/sharing', label: 'navigation.sharing' }
];

const SideNavigationContext = React.createContext();

export const SideNavigationOpenProvider = ({ children }) => {
  const [open, setOpen] = React.useState(getVisibleWidth() > 1600);
  const sidenavRef = React.useRef();
  const toggle = () => setOpen(old => !old);
  return (
    <SideNavigationContext.Provider value={{ open, toggle, sidenavRef }}>
      {children}
    </SideNavigationContext.Provider>
  );
};
export const useSideNavigation = () => React.useContext(SideNavigationContext);

export const SideNavigation = React.memo(
  observer(() => {
    const {
      me,
      t,
      notificationStore,
      authStore,
      companies,
      switchCompany,
      current,
      uiSettings
    } = useStore();
    const [overrideOpen, setOverrideOpen] = React.useState(false);
    const { open, toggle, sidenavRef } = useSideNavigation();
    const { pathname } = useLocation();

    const isOpen = overrideOpen || open;

    const closeOverride = () => setOverrideOpen(false);
    const switchableCompanies = companies.filter(company => Boolean(company.info));
    const unread = notificationStore.unread.length;

    return (
      <div className="hide-md">
        <Tooltip id="fix-pos-tip" place="bottom" />
        <SideNavSpacer fixed={open} />
        <SideNavContainer fixed={open} open={isOpen} ref={sidenavRef}>
          <SideNavLogo>
            <Icon50skills />
            <div id="fix-pos">
              <SwapButton
                data-for="fix-pos-tip"
                data-tip={open ? t('navigation.collapse') : t('navigation.expand')}
                trans
                onClick={e => {
                  e.stopPropagation();
                  toggle();
                }}
                icon={IconSwap}
              />
            </div>
          </SideNavLogo>

          <MainNavItems>
            {items.map((item, index) => {
              let isActive = false;
              if (pathname.startsWith(item.path)) {
                isActive = true;
              } else if (index === 0) {
                // Looking at the dashboard that contains some sub urls.
                const dashpaths = ['/jobs', '/apps'];
                if (dashpaths.includes(pathname)) {
                  isActive = true;
                }
              }

              return (
                <NavigationLink to={item.path} key={item.path}>
                  <SideNavItem active={isActive} data-side-nav-item>
                    <SideNavActiveBorder visible={isActive} />
                    <item.icon />
                    <SideNavLabel active={isActive}>{t(item.label)}</SideNavLabel>
                  </SideNavItem>
                </NavigationLink>
              );
            })}
          </MainNavItems>

          {switchableCompanies.length > 1 && (
            <Menu placement="right-end" label="Select company" tabIndex={0}>
              <UserMenuButton
                tabIndex={0}
                variant="unstyled"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOverrideOpen(true);
                }}
              >
                <SideNavItem style={{ paddingRight: 0 }} data-side-nav-item>
                  <IconCompany />
                  <SideNavLabel>{current.info.companyName || current.info.name}</SideNavLabel>
                </SideNavItem>
              </UserMenuButton>
              <MenuItems data-side-nav-menu>
                <ClickOutside
                  onOutsideClick={e => {
                    if (!overrideOpen) return;
                    if (!e.target.dataset.sideNavMenu) {
                      e.preventDefault();
                      e.stopPropagation();
                      setOverrideOpen(false);
                    }
                  }}
                >
                  <MenuHeader>{t('navigation.companies')}</MenuHeader>
                  <MenuSeparator />
                  {switchableCompanies.map(company => (
                    <MenuItem
                      onClick={current.id === company.id ? null : () => switchCompany(company.id)}
                      key={company.id}
                    >
                      {company.info.companyName || company.info.name}
                    </MenuItem>
                  ))}
                </ClickOutside>
              </MenuItems>
            </Menu>
          )}

          <NavSplit />
          <SideNavItem
            tabIndex={0}
            as="a"
            href={`${process.env.REACT_APP_CAREERS_URL}/${current.info.name}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconCareers />
            <SideNavLabel>{t('navigation.careersite')}</SideNavLabel>
          </SideNavItem>
          <SideNavItem
            tabIndex={0}
            onClick={e => {
              e.stopPropagation();
              uiSettings.toggleNotifications();
            }}
          >
            {unread > 0 && (
              <UnreadNotifications>{unread > 99 ? '99+' : unread}</UnreadNotifications>
            )}
            <IconNotifications />
            <SideNavLabel>{t('user.tabs.notifications')}</SideNavLabel>
          </SideNavItem>
          <Menu placement="right-end" label="User actions">
            <UserMenuButton
              tabIndex={0}
              variant="unstyled"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setOverrideOpen(true);
              }}
            >
              <SideNavItem style={{ paddingRight: 0 }} data-side-nav-item>
                <UserAvatar src={me.picture} name={me.name} />
                <SideNavLabel>{me.name}</SideNavLabel>
              </SideNavItem>
            </UserMenuButton>
            <MenuItems data-side-nav-menu>
              <ClickOutside
                onOutsideClick={e => {
                  if (!overrideOpen) return;
                  if (!e.target.dataset.sideNavMenu) {
                    e.preventDefault();
                    e.stopPropagation();
                    setOverrideOpen(false);
                  }
                }}
              >
                <Link to={`/team/${me.id}/profile`} onClick={closeOverride}>
                  <MenuItem stopPropagation={false}>{t('usermenu.profile')}</MenuItem>
                </Link>
                <Link to={notificationStore.settingsUrl} onClick={closeOverride}>
                  <MenuItem stopPropagation={false}>{t('usermenu.notifications')}</MenuItem>
                </Link>
                <MenuSeparator />
                <Link to="/settings/company" onClick={closeOverride}>
                  <MenuItem stopPropagation={false}>{t('usermenu.company.settings')}</MenuItem>
                </Link>
                <Link to="/settings/apps" onClick={closeOverride}>
                  <MenuItem stopPropagation={false}>{t('usermenu.company.apps')}</MenuItem>
                </Link>
                <MenuSeparator />

                <Link to="/templates/emails" onClick={closeOverride}>
                  <MenuItem stopPropagation={false}>
                    {t('usermenu.company.templates.emails')}
                  </MenuItem>
                </Link>

                <Link to="/templates/tasks" onClick={closeOverride}>
                  <MenuItem stopPropagation={false}>
                    {t('usermenu.company.templates.tasks')}
                  </MenuItem>
                </Link>
                <MenuSeparator />

                <a
                  rel="noopener noreferrer"
                  href="https://help.50skills.com/"
                  target="_blank"
                  onClick={closeOverride}
                >
                  <MenuItem>{t('usermenu.help')}</MenuItem>
                </a>

                <MenuSeparator />
                <MenuItem onClick={authStore.signOut}>{t('usermenu.signout')}</MenuItem>
              </ClickOutside>
            </MenuItems>
          </Menu>
        </SideNavContainer>
      </div>
    );
  })
);

const NavigationLink = styled(Link)(p => ({
  ':focus': {
    outline: 'none',
    '[data-side-nav-item]': {
      color: p.theme.colors.green100
    }
  }
}));

const SwapButton = styled(IconButton)(p => ({
  ':hover, :focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
    color: `${p.theme.colors.green100} !important`
  }
}));

const UserMenuButton = styled(MenuButton)(p => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  width: 'calc(100% - 1.5rem)',
  marginRight: '1.5rem',
  img: {
    transition: 'all 200ms ease-out'
  },
  ':hover, :focus': {
    '[data-side-nav-item]': {
      color: p.theme.colors.green100
    },
    img: {
      border: '2px solid ' + p.theme.colors.green100
    }
  }
}));

const SideNavSpacer = styled.div(p => ({
  transition: 'width 200ms ease',
  width: p.fixed ? '12.5rem' : '4.5rem'
}));

const SideNavLabel = styled.div.attrs({
  'data-nav-label': ''
})(p => ({
  transition: 'opacity 150ms ease-out',
  opacity: 0,
  marginLeft: '1rem',
  ...p.theme.text.semibold14,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'left'
}));

const NavSplit = styled.div(p => ({
  width: '100%',
  height: 1,
  backgroundColor: p.theme.colors.whiteTrans10,
  marginTop: p.theme.spacing.m8,
  marginBottom: p.theme.spacing.m8
}));

const MainNavItems = styled.div({
  position: 'relative',
  width: '100%',
  flexGrow: 1
});

const SideNavLogo = styled.div(p => ({
  color: p.theme.colors.white100,
  height: '4.5rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '1.25em',
  '#fix-pos': {
    transition: 'opacity 0.01s ease',
    opacity: 0,
    position: 'absolute',
    right: '1.25em'
  }
}));

const fullWidth = {
  width: '12.5rem',
  '[data-nav-label]': {
    opacity: 1
  }
};

export const SideNavContainer = styled.div(p => ({
  zIndex: 100,
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  backgroundColor: p.theme.colors.purple100,
  height: '100%',
  transition: 'width 200ms ease',
  width: '4.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '1rem',
  boxShadow: 'none',
  ':hover': {
    ...fullWidth,
    ...(!p.fixed && { boxShadow: '8px 0px 16px rgba(0, 0, 0, 0.24)' }),
    '#fix-pos': {
      opacity: 1,
      transition: 'opacity 300ms ease'
    }
  },
  ...(p.fixed && {
    ...fullWidth,
    '#fix-pos': {
      opacity: 1
    }
  }),
  ...(p.open &&
    !p.fixed && {
      ...fullWidth,
      boxShadow: '8px 0px 16px rgba(0, 0, 0, 0.24)',
      '#fix-pos': {
        opacity: 1
      }
    })
}));

const SideNavItem = styled.div(p => ({
  position: 'relative',
  transition: 'all 200ms ease',
  color: p.active ? p.theme.colors.green100 : p.theme.colors.purple50,
  width: '100%',
  height: '3rem',
  padding: '0 1.5rem',
  display: 'flex',
  alignItems: 'center',
  ':focus': {
    outline: 'none'
  },
  ...(!p.active && {
    ':hover, :focus': {
      cursor: 'pointer',
      color: p.theme.colors.green100
    }
  }),
  svg: {
    minWidth: '1.5rem'
  }
}));

const SideNavActiveBorder = styled.div(p => ({
  transition: p.visible ? 'all 200ms ease-out' : 'all 100ms ease-out',
  backgroundColor: p.theme.colors.green100,
  position: 'absolute',
  left: 0,
  height: '3rem',
  transform: p.visible ? 'scaleY(1) translateY(0px)' : 'scaleY(0) translateY(1.5rem)',
  width: '0.25rem'
}));

const UnreadNotifications = styled.div(p => ({
  position: 'absolute',
  zIndex: 10,
  backgroundColor: p.theme.colors.green100,
  color: p.theme.colors.white100,
  ...p.theme.text.regular10,
  borderRadius: '0.125rem',
  padding: '0.25rem',
  left: '2.25rem',
  minWidth: '1rem',
  top: '0.5rem',
  boxShadow: '2px 0px 4px rgba(0, 0, 0, 0.24)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
