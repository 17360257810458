import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { clone, applySnapshot } from 'mobx-state-tree';

import { validateEmail } from 'shared/utils/validators';
import Radio from 'shared/elements/Radio';

import { useStore } from 'models/Provider';
import { FormLabel, Text } from 'components/Text';
import { Box } from 'components/Box';
import { Input } from 'components/Input';
import { Button, ButtonGroup } from 'components/Button';
import { UserAvatar } from 'components/UserAvatar';

export const TeamDetailsProfile = observer(({ user }) => {
  const fileInputRef = useRef(null);
  const [clonedUser, setClonedUser] = useState(clone(user));
  const { api, notify, t, lang, setLanguage } = useStore();

  const [state, setState] = useState({
    file: null,
    errors: {},
    saving: false,
    displayFile: null
  });
  const dirty =
    user.email !== clonedUser.email || user.name !== clonedUser.name || state.file !== null || user.language !== clonedUser.language;

  function clearError(which) {
    if (Object.keys(state.errors).length) {
      let errors = { ...state.errors };
      delete errors[which];
      setState(old => ({ ...old, errors }));
    }
  }

  function clickUpload() {
    fileInputRef.current.click();
  }

  function setName(name) {
    clonedUser.setName(name);
    clearError('name');
  }

  function setEmail(email) {
    clonedUser.setEmail(email);
    clearError('email');
  }

  async function changeFile(e) {
    const file = e.target.files.length > 0 ? e.target.files[0] : null;
    let displayFile = null;
    try {
      displayFile = await getBase64(file);
    } catch (e) {}
    setState(old => ({ ...old, file, displayFile }));
  }

  function reset() {
    if (state.saving || !dirty) return;
    fileInputRef.current.value = '';
    setClonedUser(clone(user));
    user.setLanguage(lang);
    setState(old => ({ ...old, file: null, errors: {}, displayFile: null }));
  }

  async function saveUser() {
    const { errors, file } = state;
    const newUser = clonedUser;
    setState(old => ({ ...old, saving: true }));

    let data = new FormData();
    if (file) {
      data.append('picture', file);
    }

    if (newUser.name !== user.name) {
      data.append('name', newUser.name);
    }

    if (newUser.email !== user.email) {
      const valid = validateEmail(newUser.email);
      if (valid) {
        data.append('email', newUser.email);
      } else {
        setState(old => ({
          ...old,
          errors: { ...errors, email: t('user.profile.error.email') },
          saving: false
        }));
        return;
      }
    }

    if (newUser.language !== user.language) {
      data.append('language', newUser.language);
    }

    const res = await api.users.updateDetails(user.id, data);
    if (res.error) {
      let str = '';
      Object.keys(res.data).forEach(k => {
        str += res.data[k].join(' ');
      });
      notify('error', t('user.profile.error.generic', str || ['']));
      setState(old => ({
        ...old,
        errors: {
          name: res.data.name ? res.data.name : false,
          email: res.data.email ? res.data.email : false
        }
      }));
    } else {
      applySnapshot(user, res.data);
      setLanguage(newUser.language);
      notify('success', t('user.profile.update.success'));
      setState(old => ({ ...old, file: null }));
      setClonedUser(clone(user));
    }
    setState(old => ({ ...old, saving: false }));
  }

  const { errors, file, saving, displayFile } = state;
  if (!clonedUser) return null;

  return (
    <ProfileContainer>
      <Text type="h2" color="green100">
        {t('user.profile.heading')}
      </Text>
      <ProfileImage>
        <UserAvatar
          src={displayFile ? displayFile : user.picture}
          name={clonedUser.name}
          size="xl"
        />
        {user.isMe && (
          <div>
            <FormLabel>{t('user.profile.label.image')}</FormLabel>
            <UploadButton onClick={clickUpload}>{t('user.profile.image.choose')}</UploadButton>
            <ChosenFile>{file ? file.name : t('user.profile.image.nofile')}</ChosenFile>
            <input
              style={{ display: 'none' }}
              ref={fileInputRef}
              name="file"
              type="file"
              accept="image/*"
              onChange={changeFile}
            />
          </div>
        )}
      </ProfileImage>
      <FormLabel>{t('user.profile.label.name')}</FormLabel>
      <Input
        name="name"
        value={clonedUser.name}
        readOnly={!user.isMe}
        onChange={setName}
        error={errors.name}
        disabled={saving}
      />
      <Box mt="16">
        <FormLabel>{t('user.profile.label.email')}</FormLabel>
        <Input
          name="email"
          value={clonedUser.email}
          readOnly={!user.isMe}
          onChange={setEmail}
          error={errors.email}
          disabled={saving}
        />
      </Box>
      {user.isMe && (
        <Box mt="16">
          <FormLabel>{t('user.profile.label.language')}</FormLabel>
          <Box mb="4">
            <Radio
              value={'en'}
              checked={clonedUser.language === 'en'}
              onCheck={() => clonedUser.setLanguage('en')}
              disabled={saving}
              label={t('user.profile.label.language.english')}
              key={'en'}
              domValue={'en'}
              dataId={'en'}
            />
          </Box>
          <Box mb="4">
            <Radio
              value={'is'}
              checked={clonedUser.language === 'is'}
              onCheck={() => clonedUser.setLanguage('is')}
              disabled={saving}
              label={t('user.profile.label.language.icelandic')}
              key={'is'}
              domValue={'is'}
              dataId={'is'}
            />
          </Box>
        </Box>
      )}
      {user.isMe && (
        <>
          <ButtonGroup right mt="24">
            {dirty && (
              <Button gray onClick={reset}>
                {t('user.profile.button.cancel')}
              </Button>
            )}
            <Button disabled={!dirty || saving} onClick={!dirty ? null : saveUser}>
              {t('user.profile.button.submit')}
            </Button>
          </ButtonGroup>
          <TeamDetailsSocial user={user} t={t} />
        </>
      )}
    </ProfileContainer>
  );
});

const TeamDetailsSocial = observer(() => {
  const { t } = useStore();
  return (
    <Box mt="32">
      <Text type="h2" color="green100">
        {t('user.social.heading')}
      </Text>
      <Box mt="32">
        <a href="https://slack.com/oauth/authorize?scope=identity.basic&client_id=43278704066.262284113062">
          <img
            alt="Sign in with Slack"
            height="40"
            width="172"
            src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
            srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
          />
        </a>
      </Box>
    </Box>
  );
});

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const ProfileContainer = styled.div`
  max-width: 380px;
  margin: 0 auto;
`;

const ProfileImage = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0;
  > div:nth-child(1) {
    margin-right: 20px;
  }
`;

const UploadButton = styled.button`
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 5px 10px 0px;
  padding: 3px 8px;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px #ddd;
  border: 1px solid #bbb;
  :hover {
    transform: scale(1.02);
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
  }
`;

const ChosenFile = styled.span`
  color: ${p => p.theme.textColor};
`;
