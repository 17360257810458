import { types as t } from 'mobx-state-tree';
import isBefore from 'date-fns/isBefore';
import fromUnixTime from 'date-fns/fromUnixTime';

import { UserRole } from 'models/users';

export const AuthToken = t
  .model('AuthToken', {
    raw: t.string,
    company: t.identifierNumber,
    role: UserRole,
    userID: t.number,
    uuid: t.string,
    exp: t.number
  })
  .actions(self => ({
    isValid() {
      return isBefore(new Date(), fromUnixTime(self.exp));
    }
  }));
