import React, { useState } from 'react';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import { TextEditorToolbar } from './TextEditorToolbar';

const toolbarTheme = {
  toolbarStyles: { toolbar: 'rdw-editor-toolbar' }
};

export const useToolbarPlugin = () => {
  const [state] = useState(() => {
    const plugin = createToolbarPlugin({
      theme: toolbarTheme
    });

    const Component = () => {
      return (
        <plugin.Toolbar>
          {({ getEditorState, setEditorState }) => {
            return (
              <TextEditorToolbar setEditorState={setEditorState} getEditorState={getEditorState} />
            );
          }}
        </plugin.Toolbar>
      );
    };

    return [plugin, Component];
  });

  return state;
};
