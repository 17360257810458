const colors = {
  blue100: '#2F80ED',
  blue20: '#D5E6FB',
  textDark: '#757575',
  white: '#fff',
  white100: '#fff',
  lightGray: '#ececec',
  whiteTrans10: 'rgba(255, 255, 255, 0.1)',
  bgGray: '#f6f6f6',
  grayMedium: '#9CA6B0',
  greenMain: '#00C882',
  green100: '#00C882',
  green90: '#31D39B',
  green20: '#CCF4E6',
  greenTrans8: 'rgba(0, 200, 130, 0.08)',
  modalBg: 'rgba(49,47,76,0.5)',
  orange: '#F5B64D',
  orange100: '#F5B64D',
  orange90: '#F6BD5F',
  orange20: '#FDF0DB',
  orange10: 'rgba(245, 182, 77, 0.1)',
  purple100: '#3c3c64',
  purple90: '#505074',
  purple80: '#636383',
  purple70: '#777793',
  purple60: '#8A8AA2',
  purple50: '#9D9DB1',
  purple40: '#B1B1C1',
  purple20: '#D8D8E0',
  purple10: '#ECECF0',
  purpleTrans50: 'rgba(60, 60, 100, 0.5)',
  purpleTrans20: 'rgba(60, 60, 100, 0.2)',
  purpleTrans10: 'rgba(60, 60, 100, 0.1)',
  purpleTrans5: 'rgba(61, 64, 102, 0.05)',
  red: '#EB5757',
  red50: '#F8D9DD',
  red100: '#EB5757',
  red90: '#EF7878',
  red20: '#FBDDDD',
  redTrans5: 'rgba(235, 87, 87, 0.05)',
  textLight: '#9CA6B0',
  yellow100: '#FFDD2D'
};

const sizes = {
  text10: '0.625rem',
  text12: '0.75rem',
  text14: '0.875rem',
  text16: '1rem',
  text18: '1.125rem',
  text20: '1.25rem',
  text24: '1.5rem',
  text64: '4rem'
};

const weights = {
  normal: 400,
  semibold: 600,
  bold: 700
};

const spacing = {
  m4: '0.25rem',
  m8: '0.5rem',
  m12: '0.75rem',
  m16: '1rem',
  m24: '1.5rem',
  m28: '1.75rem',
  m32: '2rem',
  m40: '2.5rem',
  m80: '5rem'
};

const shadows = {
  low: '0px 0.125rem 0.25rem rgba(0, 0, 0, 0.1)',
  medium: '0px 0.25rem 0.5rem rgba(0, 0, 0, 0.05)',
  high: '0px 0.25rem 2.5rem rgba(0, 0, 0, 0.1)'
};

const text = {
  regular10: { fontWeight: weights.normal, fontSize: sizes.text10, lineHeight: '0.75' },
  semibold10: { fontWeight: weights.semibold, fontSize: sizes.text10, lineHeight: '0.75rem' },
  regular12: { fontWeight: weights.normal, fontSize: sizes.text12, lineHeight: '1rem' },
  semibold12: { fontWeight: weights.semibold, fontSize: sizes.text12, lineHeight: '1rem' },
  bold12: { fontWeight: weights.bold, fontSize: sizes.text12, lineHeight: '1rem' },
  regular14: { fontWeight: weights.normal, fontSize: sizes.text14, lineHeight: '1rem' },
  semibold14: { fontWeight: weights.semibold, fontSize: sizes.text14, lineHeight: '1rem' },
  bold14: { fontWeight: weights.bold, fontSize: sizes.text14, lineHeight: '1.25rem' },
  regular16: { fontWeight: weights.normal, fontSize: sizes.text16, lineHeight: '1.5rem' },
  bold16: { fontWeight: weights.bold, fontSize: sizes.text16, lineHeight: '1.5rem' },
  bold18: { fontWeight: weights.bold, fontSize: sizes.text18, lineHeight: '1.5rem' },
  h2: { fontWeight: weights.bold, fontSize: sizes.text20, lineHeight: '1.5rem' },
  h1: { fontWeight: weights.bold, fontSize: sizes.text24, lineHeight: '2rem' },
  monster: { fontWeight: weights.bold, fontSize: sizes.text64, lineHeight: '5rem' }
};

export const theme = {
  primaryColor: colors.green100,
  secondaryColor: '#3D4066',
  primaryBackgroundColor: '#F6F6F6',
  tabColorPrimary: colors.green100,
  tabColorSecondary: '#565A77',
  stepLabelPrimary: colors.greenMain,
  stepLabelSecondary: 'rgba(61,64,102,0.54)',
  textColor: '#565A77',
  textColorLight: '#8C9CB0',
  headingColor: '#312F4C',
  headingHoverColor: '#EC6657',
  facebookColor: '#4963A4',
  linkedinColor: '#488AE9',
  errorColor: colors.red,
  subHeadingColor: '#312F4C',
  dangerColor: '#E05858',
  hyperlinkColor: '#0000EE',
  formLabelColor: colors.purple60,
  formInputColor: colors.purple80,
  bp: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
  },
  mobileQuery: '@media(max-width: 768px)',

  ...colors,
  colors,
  text,
  spacing,
  weights,
  shadows
};
