import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconOptionsSingle = props => (
  <Svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9H2v2h12V9zm0-4H2v2h12V5zM2 15h8v-2H2v2zm19.5-4.5L23 12l-6.99 7-4.51-4.5L13 13l3.01 3 5.49-5.5z"
      fill="currentColor"
    />
  </Svg>
);
