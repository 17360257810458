import { types as t, getParent, destroy } from 'mobx-state-tree';
import uuid4 from 'uuid/v4';
import { sortByOrder } from 'shared/utils/sorting';

export const MultiDropdownOption = t.model('MultiDropdownOption', {
  value: t.string,
  display: t.string,
  error: false
});

export const JobFormQuestionTypes = [
  'one_line',
  'multi_line',
  'dropdown',
  'multidropdown',
  'attachment'
];

export const JobFormQuestionType = t.enumeration('FormQuestionType', JobFormQuestionTypes);

export const JobFormQuestion = t
  .model('JobFormQuestion', {
    id: t.optional(t.identifier, () => uuid4()),
    order: t.optional(t.number, 9999),
    title: t.optional(t.string, ''),
    required: false,
    hidden: t.optional(t.boolean, false),
    type: t.optional(JobFormQuestionType, 'one_line'),
    options: t.maybe(t.array(MultiDropdownOption)),
    error: false
  })
  .views(self => ({
    get parent() {
      return getParent(self, 2);
    }
  }))
  .actions(self => ({
    afterAttach() {
      if (self.parent.condenseOrder) {
        self.parent.condenseOrder();
      }
    },
    setSwitch(value) {
      switch (value) {
        case 'required':
          self.required = true;
          self.hidden = false;
          break;
        case 'included':
          self.required = false;
          self.hidden = false;
          break;
        case 'off':
          self.required = false;
          self.hidden = true;
          break;
        default:
          throw new Error();
      }
    },
    toggleRequired() {
      self.required = !self.required;
    },
    moveUp() {
      self.order = self.order - 2;
      self.parent.condenseOrder();
    },
    moveDown() {
      self.order = self.order + 2;
      self.parent.condenseOrder();
    },
    setTitle(newTitle) {
      self.title = newTitle;
      self.error = false;
    },
    setType(newType) {
      if (newType === self.type) return;
      const isDD = self.type === 'dropdown' || self.type === 'multidropdown';
      const newDD = newType === 'dropdown' || newType === 'multidropdown';
      if (newDD && !isDD) {
        self.options = [];
      }
      self.type = newType;
    },
    addOption(index, value = '') {
      self.options.splice(index + 1, 0, { display: '', value });
    },
    removeOption(index) {
      self.options.splice(index, 1);
    },
    editOption(index, value) {
      self.options[index].display = value;
      self.options[index].error = false;
    },
    setOptionError(index, value) {
      self.options[index].error = value;
    },
    remove() {
      self.parent.remove(self);
    },
    setError(value) {
      self.error = value;
    },
    create() {
      if (!self.title) {
        self.error = true;
        return false;
      } else {
        self.options = self.options.filter(o => o.display.trim() !== '');
        return true;
      }
    },
    getDisplayForOption(id) {
      const opt = self.options.find(opt => opt.value === id);
      return opt ? opt.display : id;
    }
  }));

const JobFormSection = t
  .model('JobFormSection', {
    id: t.maybe(t.string),
    questions: t.array(JobFormQuestion)
  })
  .actions(self => ({
    afterCreate() {
      self.condenseOrder();
    },
    afterAttach() {
      self.condenseOrder();
    },
    condenseOrder() {
      // For when the user erases questions and creates gaps in the order.
      // Mash that gap right down so the order is always the number of questions.
      self.questions = self.questions.slice().sort(sortByOrder);
      self.questions.forEach((q, i) => (q.order = i));
    },
    add(question) {
      self.questions.push(question);
      self.condenseOrder();
    },
    remove(question) {
      destroy(question);
      self.condenseOrder();
    }
  }));

export const JobForm = t
  .model('JobForm', {
    sections: t.array(JobFormSection)
  })
  .views(self => ({
    get job() {
      return getParent(self);
    }
  }))
  .actions(self => ({
    afterAttach() {
      if (self.sections.length === 0) {
        self.addSection();
      }
    },
    addSection() {
      self.sections.push({ id: uuid4() });
    }
  }));
