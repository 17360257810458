import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';

import { Box } from 'components/Box';
import { FormLabel } from 'components/Text';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { Select } from 'components/Select';
import { Separator } from 'components/Separator';
import ShowMore from 'components/ShowMore';
import {
  convertPlaceholders,
  isEditorStateEmpty,
  makeEditorState,
  placeholdersToBrackets
} from 'components/TextEditor';
import { useStore } from 'models/Provider';
import RejectForm from './RejectForm';
import { RejectName } from './styles';

function RejectApplicantsModal({ applicants, applicant, closeModal }) {
  const { current, t, me } = useStore();
  const [submitting, setSubmitting] = useState(false);
  const [method, setMethod] = useState(null);
  const [selected, setSelected] = useState({
    message: EditorState.createEmpty()
  });
  const templates = current.rejectionTemplates;

  const rejectionTypes = [
    {
      value: 'reject',
      display: t('rejectmodal.types.reject'),
      helpText: t('rejectmodal.types.rejecthelp'),
      danger: true
    },
    {
      value: 'rejectAndSendEmail',
      display: t('rejectmodal.types.rejectsend'),
      helpText: t('rejectmodal.types.rejectsendhelp'),
      danger: true
    },
    {
      value: 'markRejectedAndNotified',
      display: t('rejectmodal.types.rejectmark'),
      helpText: t('rejectmodal.types.rejectmarkhelp'),
      danger: true
    }
  ];

  function onTemplateSelect(id) {
    const templates = current.rejectionTemplates;
    const select = templates.find(t => t.id === Number(id));
    if (select) {
      const { contentHtml, contentTxt } = select;
      const editorState = makeEditorState(contentHtml || contentTxt);

      setSelected({
        message: convertPlaceholders(editorState, t)
      });
    }
  }

  function setEditorState(editorState) {
    setSelected(old => ({
      ...old,
      message: convertPlaceholders(editorState, t)
    }));
  }

  async function onSubmit() {
    const { message } = selected;
    const job = applicant ? applicant.job : applicants[0].job;

    let success = true;
    setSubmitting(true);

    switch (method) {
      case 'reject':
        if (applicant) {
          success = await applicant.reject(false);
        } else {
          success = await job.bulkRejectApplicants(applicants, false);
        }
        break;
      case 'markRejectedAndNotified':
        if (applicant) {
          success = await applicant.reject(true);
        } else {
          success = await job.bulkRejectApplicants(applicants, true);
        }
        break;
      case 'rejectAndSendEmail':
        const rawData = convertToRaw(message.getCurrentContent());
        const converted = placeholdersToBrackets(rawData);

        const emailData = {
          message: draftToHtml(converted)
        };
        if (applicant) {
          emailData.applicant = applicant.id;
          success = await applicant.reject(true, emailData);
        } else {
          success = await job.bulkRejectApplicants(applicants, true, emailData);
        }
        break;
      default:
        return;
    }

    if (success) {
      closeModal();
    } else {
      setSubmitting(false);
    }
  }

  function dataForMethod() {
    let will = [];
    let wont = [];

    if (applicants) {
      switch (method) {
        case 'reject':
          applicants.forEach(applicant => {
            if (applicant.isRejected) {
              wont.push(applicant);
            } else {
              will.push(applicant);
            }
          });
          break;
        case 'markRejectedAndNotified':
          applicants.forEach(applicant => {
            if (applicant.isNotifiedOfRejection) {
              wont.push(applicant);
            } else {
              will.push(applicant);
            }
          });
          break;
        case 'rejectAndSendEmail':
          applicants.forEach(applicant => {
            if (applicant.isNotifiedOfRejection) {
              wont.push(applicant);
            } else {
              will.push(applicant);
            }
          });
          break;
        default:
          break;
      }
    }
    return { will, wont };
  }

  let notRejected = [];
  let notNotified = [];
  let rejected = [];
  let rejectedAndNotified = [];
  if (applicants && method !== null) {
    applicants.forEach(a => {
      if (a.isNotifiedOfRejection) {
        rejectedAndNotified.push(a);
      } else if (a.isRejected) {
        rejected.push(a);
        notNotified.push(a);
      } else {
        notRejected.push(a);
      }
    });
  }

  const { will, wont } = dataForMethod();

  const disableButtons =
    method === '' ||
    submitting ||
    (method === 'rejectAndSendEmail' && isEditorStateEmpty(selected.message));
  return (
    <>
      <ModalHeader>{t('rejectmodal.title')}</ModalHeader>
      {submitting && <Loading />}

      {applicant && (
        <ModalText
          as="p"
          dangerouslySetInnerHTML={{
            __html: t('rejectmodal.confirm.single', applicant.name)
          }}
        />
      )}

      {applicants && (
        <>
          {method === null && (
            <>
              <ModalText as="p">{t('rejectmodal.confirm.multi')}</ModalText>
              <Box mt="12">
                <ShowMore
                  max={applicants.length}
                  count={applicants.length > 15 ? 10 : applicants.length}
                  inc={1000}
                  render={({ count }) => (
                    <div>
                      {applicants.slice(0, count).map((a, i) => (
                        <RejectName key={i}>
                          <span>{a.name}</span>
                        </RejectName>
                      ))}
                    </div>
                  )}
                />
              </Box>
            </>
          )}

          {method !== null && will.length > 0 && (
            <>
              <ModalText as="p">
                {method === 'reject' && t('rejectmodal.confirmed.reject')}
                {method === 'rejectAndSendEmail' && t('rejectmodal.confirmed.rejectsend')}
                {method === 'markRejectedAndNotified' && t('rejectmodal.confirmed.rejectmark')}
              </ModalText>
              <Box mt="12" mb="24">
                <ShowMore
                  key={will.length}
                  max={will.length}
                  count={will.length > 15 ? 10 : will.length}
                  inc={100}
                  render={({ count }) => (
                    <div>
                      {will.slice(0, count).map((a, i) => (
                        <RejectName key={i}>
                          <span>{a.name}</span>
                        </RejectName>
                      ))}
                    </div>
                  )}
                />
              </Box>
            </>
          )}

          {method !== null && wont.length > 0 && (
            <>
              <ModalText>{t('rejectmodal.excluded')}</ModalText>
              <Box mt="12" mb="24">
                <ShowMore
                  key={will.length}
                  max={wont.length}
                  count={wont.length > 15 ? 10 : wont.length}
                  inc={100}
                  render={({ count }) => (
                    <div>
                      {wont.slice(0, count).map((a, i) => (
                        <div key={i}>
                          <RejectName>
                            <span>{a.name}</span>
                            <span>
                              {a.isNotifiedOfRejection
                                ? t('rejectmodal.already.notified')
                                : t('rejectmodal.already.rejected')}
                            </span>
                          </RejectName>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </Box>
            </>
          )}
        </>
      )}

      <Separator mt="24" mb="24" />

      <FormLabel>{t('rejectmodal.method.title')}</FormLabel>
      <Select
        inModal
        danger
        fullSize
        items={rejectionTypes}
        title={t('rejectmodal.method.drop.title')}
        onChange={method => setMethod(method)}
      />

      <RejectForm
        disabled={submitting}
        isOpen={method === 'rejectAndSendEmail'}
        templates={templates}
        selected={selected}
        onSelect={onTemplateSelect}
        user={me}
        setEditorState={setEditorState}
      />
      <ModalActions>
        <Button disabled={submitting} gray onClick={closeModal}>
          {t('rejectmodal.button.cancel')}
        </Button>
        <Button disabled={disableButtons || !method} onClick={onSubmit} red>
          {t('rejectmodal.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
}

export default observer(RejectApplicantsModal);
