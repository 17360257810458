import React, { useState } from 'react';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { SetPassword } from './SetPassword';
import { useStore } from 'models/Provider';

function SetPasswordPage({ history, location }) {
  const { authStore, t } = useStore();
  const [state, setState] = useState({
    password1: '',
    password2: '',
    error: '',
    disable: false
  });

  function handleChange(name, value) {
    setState(old => ({ ...old, [name]: value }));
  }

  async function setPassword() {
    setState(old => ({ ...old, error: '', disable: true }));

    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    const uuid = parsed['uuid'];

    const success = await authStore.setPassword(state.password1, state.password2, uuid);
    if (success) {
      history.push('/');
    } else {
      setState({ error: t('setpw.error.notmatching'), disable: false });
    }
  }

  return (
    <SetPassword
      t={t}
      password1={state.password1}
      password2={state.password2}
      onChange={handleChange}
      onSubmit={setPassword}
      error={state.error}
      disableSubmit={state.disable}
    />
  );
}

export default withRouter(observer(SetPasswordPage));
