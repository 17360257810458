import React from 'react';
import styled from 'styled-components/macro';
import { Box } from 'components/Box';

const StyledText = styled(Box)(p => ({
  color: p.theme.colors[p.color] || p.color,
  display: p.block ? 'block' : 'inline-block',
  ...p.theme.text[p.type],
  ...(p.caps && { textTransform: 'uppercase' })
}));

export const Text = ({
  color = 'purple90',
  type = 'regular16',
  block = true,
  caps = false,
  as = 'span',
  ...rest
}) => <StyledText color={color} type={type} block={block} caps={caps} as={as} {...rest} />;

export const FormLabel = ({ children, exactCase = false, ...rest }) => (
  <Text type="bold12" caps={!exactCase} color="purple80" style={{ lineHeight: '1.5rem' }} {...rest}>
    {children}
  </Text>
);

export const TextLabel = props => <Text type="bold12" caps color="purple80" {...props} />;

export const P = props => <Text as="p" type="regular16" {...props} />;
export const H1 = props => <Text as="h1" type="h1" color="purple80" {...props} />;
export const H2 = props => <Text as="h2" type="h2" color="green100" {...props} />;
export const H3 = props => <Text as="h3" type="h3" color="purple80" mt={16} {...props} />;
export const FormError = ({ caps = false, ...rest }) => (
  <Text as="span" type="bold12" color="red100" caps={caps} mt="8" {...rest} />
);

export const TextLink = props => (
  <StyledTextLink>
    <Text as="a" block={false} type="regular16" color="purple50" {...props} />
  </StyledTextLink>
);

const StyledTextLink = styled.span(p => ({
  a: {
    transition: 'all 150ms ease-out',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  'a:hover': {
    cursor: 'pointer',
    color: p.theme.colors.purple80
  }
}));

export const SubText = styled.span`
  color: ${p => p.theme.textColor};
  font-style: italic;
  font-size: 0.75rem;
`;
