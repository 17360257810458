import { types as t, flow } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';
import { Integration } from 'models/integrations';
import { LoadingState } from 'models/ui';

export const JobIntegrations = t
  .model('JobIntegrations', {
    integrations: t.array(Integration),
    integrationsState: LoadingState
  })
  .views(withEnv)
  .actions(self => ({
    loadIntegrations: flow(function*() {
      if (self.integrationsState !== 'init') return;

      self.integrationsState = 'loading';
      const res = yield self.env.api.jobs.loadIntegrations(self.id, 'job_published');
      if (!res.error) {
        self.integrations = res.data.map(d => ({
          ...d,
          type: 'job_published'
        }));
        self.integrationsState = 'loaded';
      } else {
        self.integrationsState = 'error';
      }
    })
  }));
