import moment from 'moment';
import { request } from 'shared/utils/browser';
import { API_URL, APPLICATIONCHAT_URL, APPLICATIONS_URL, REVIEW_URL } from './urls';

export const makeApplicationsClient = r => ({
  verifySsn: appId => r(`${APPLICATIONS_URL}${appId}/verify-ssn/`, 'patch', { verify: true }),
  editSsn: (appId, ssn) => r(`${APPLICATIONS_URL}${appId}/`, 'patch', { ssn }),
  keepOrMatchName: (appId, keepOrMatch) =>
    r(`${APPLICATIONS_URL}${appId}/verify-ssn/`, 'patch', { keepOrMatch }),
  setFollowers: (applicationId, followers) =>
    r(`${APPLICATIONS_URL}${applicationId}/`, 'patch', { followers }),
  create: data => r(APPLICATIONS_URL, 'post', data),
  search: queryString => r(`${API_URL}search/applications/${queryString}`),
  onboarding: queryString => r(`${API_URL}search/onboarding/${queryString}`),
  loadForJob: jobId => r(`${API_URL}search/applications/?job=${jobId}&limit=10000`),
  loadForJobMinimal: jobId =>
    r(`${API_URL}search/applications/?job=${jobId}&limit=10000&minimal=1`),
  loadPictures: ids => r(`${API_URL}pictures/?id=${ids.join('&id=')}`),
  load: id => r(`${APPLICATIONS_URL}${id}/`),
  loadBlob: url => request(url, 'get', {}, { responseType: 'blob' }),
  loadApplicationsUserRead: appIds => {
    return r(`${API_URL}search/read/?${appIds.map(id => `application=${id}`).join('&')}`);
  },
  findApplicationsByEmail: email =>
    r(`${API_URL}search/applications/?limit=100&email=${email}&ordering=-date`),
  read: (id, data) => r(`${APPLICATIONS_URL}${id}/read/`, 'patch', data),
  moveStage: (id, stage) => r(`${APPLICATIONS_URL}${id}/`, 'patch', { stage }),
  update: (id, data) => r(`${APPLICATIONS_URL}${id}/`, 'patch', data),
  addAttachment: (id, data) => r(`${APPLICATIONS_URL}${id}/attachments/`, 'post', data),
  removeAttachment: (id, attachmentId) =>
    r(`${APPLICATIONS_URL}${id}/attachments/${attachmentId}/`, 'delete'),
  delete: id => r(`${APPLICATIONS_URL}${id}/`, 'delete'),
  unReject: id =>
    r(`${APPLICATIONS_URL}${id}/`, 'patch', { isRejected: false, isNotifiedOfRejection: false }),

  reject: (id, markNotified, emailData) => {
    let data = {
      isRejected: true,
      isNotifiedOfRejection: markNotified
    };
    if (emailData) {
      data.emailData = emailData;
    }
    return r(`${APPLICATIONS_URL}${id}/`, 'patch', data);
  },
  hire: id => r(`${APPLICATIONS_URL}${id}/`, 'patch', { isHired: true }),
  unHire: id => r(`${APPLICATIONS_URL}${id}/`, 'patch', { isHired: false }),
  retract: id => r(`${APPLICATIONS_URL}${id}/`, 'patch', { isRetracted: true }),
  unRetract: id => r(`${APPLICATIONS_URL}${id}/`, 'patch', { isRetracted: false }),
  onboard: (id, state) => r(`${APPLICATIONS_URL}${id}/`, 'patch', { isOnboarded: state }),
  setAssignedTo: (id, userId) => r(`${APPLICATIONS_URL}${id}/`, 'patch', { assignedTo: userId }),
  addReview: data => r(REVIEW_URL, 'post', data),
  r: (url, method, data) => {
    const urlWithSlash = url.substr(-1) === '/' ? url : `${url}/`;
    return r(API_URL + urlWithSlash, method, data);
  },
  addNote: data => r(APPLICATIONCHAT_URL, 'post', data),
  removeNote: id => r(`${APPLICATIONCHAT_URL}${id}/`, 'delete'),
  editNote: (id, data) => r(`${APPLICATIONCHAT_URL}${id}/`, 'patch', data),
  loadIntegrations: (applicationId, integrationType) =>
    r(`${APPLICATIONS_URL}${applicationId}/integrations/${integrationType}/`),
  loadIntegration: (applicationId, integrationType, integrationId) =>
    r(`${APPLICATIONS_URL}${applicationId}/integrations/${integrationType}/${integrationId}/`),
  removeTasklist: appID => r(`${APPLICATIONS_URL}${appID}/tasklist/`, 'delete'),
  loadTasks: appID => r(`${APPLICATIONS_URL}${appID}/tasklist/`),
  checkTask: (appID, taskID, checked) =>
    r(`${APPLICATIONS_URL}${appID}/tasklist/${taskID}/`, 'patch', { checked: checked }),
  saveTasklist: (appID, templateId) =>
    r(`${APPLICATIONS_URL}${appID}/tasklist/`, 'post', { templateId: templateId }),
  submitIntegration: (appId, iType, iId, data, refetch = false) =>
    r(`${APPLICATIONS_URL}${appId}/integrations/${iType}/${iId}/`, 'post', {
      data: data,
      refetch: refetch
    }),
  previewIntegration: (appId, iType, iId, data) =>
    r(`${APPLICATIONS_URL}${appId}/integrations/${iType}/${iId}/preview/`, 'post', {
      data: data
    }),
  deleteIntegration: (appId, iType, iId) =>
    r(`${APPLICATIONS_URL}${appId}/integrations/${iType}/${iId}/`, 'delete'),
  sendMessage: (id, emailData) => r(`${APPLICATIONS_URL}${id}/`, 'patch', { emailData }),
  loadForAllStages: (jobId, order, limit = 10, offset = 0) => {
    return r(
      `${API_URL}search/applications/?job=${jobId}&limit=${limit}&offset=${offset}&ordering=${order}`
    );
  },
  loadCountForJob: jobId => {
    return r(`${API_URL}search/applications/?job=${jobId}&limit=1`);
  },
  loadCountForJobToday: jobId => {
    const now = moment();
    const midnight = now.clone().startOf('day');
    const minutes = now.diff(midnight, 'minutes');
    const dateStr = `date__gt=now-${minutes}m`;
    return r(`${API_URL}search/applications/?job=${jobId}&limit=1&${dateStr}`);
  },
  move: (id, data) => r(`${APPLICATIONS_URL}${id}/move/`, 'patch', data)
});
