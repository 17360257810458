import styled, { css } from 'styled-components/macro';

export default styled.button`
  height: 36px;
  border-radius: 18px;
  background-color: ${p => p.theme.primaryColor};
  color: ${p => p.theme.buttonTextColor || '#ffffff'};

  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  min-width: 140px;
  padding: 11px 15px;
  opacity: ${p => (p.disabled ? '0.5' : '1')};
  width: ${p => (p.full ? '100%' : 'auto')};

  :hover {
    background-color: ${p => p.theme.primaryColorDarker};
    cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
  }

  ${p =>
    p.green &&
    css`
      background-color: rgb(0, 200, 130);
      color: #fff;
      transition: all 0.2s ease-in-out;
      :hover {
        background-color: rgba(0, 200, 130, 0.8);
        cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
      }
    `};
`;
