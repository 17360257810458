import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import ReactAutoComplete from 'react-autocomplete';

export const AutoComplete = observer(({ initialValue, onSelect, data, items, error }) => {
  const [value, setValue] = useState(initialValue);
  return (
    <Wrapper>
      <ReactAutoComplete
        getItemValue={item => item.id.toString()}
        items={items}
        shouldItemRender={(item, value) =>
          item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
        }
        renderItem={(item, isHighlighted) => (
          <Item isHighlighted={isHighlighted} key={item.id}>
            {item.label}
          </Item>
        )}
        onSelect={(value, item) => {
          setValue(item.label);
          onSelect(data.name, value);
        }}
        onChange={e => setValue(e.target.value)}
        value={value}
        renderInput={props => {
          const { ref, ...rest } = props;
          return <InputCustom {...rest} ref={ref} />;
        }}
        menuStyle={MenuStyle}
      />
      <Error>{error}</Error>
    </Wrapper>
  );
});

const InputCustom = styled.input`
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: ${p => p.theme.colors.purpleTrans5};
  border: ${p => (p.error ? `1px solid ${p.theme.colors.red100}` : '1px solid transparent')};
  font-size: 1rem;
  font-weight: ${p => (p.bold ? 600 : 400)};
  color: ${p => p.theme.colors.purple80};
  opacity: ${p => (p.disabled && !p.readOnly ? '0.4' : '0.8')};
  line-height: 1.5rem;
  box-sizing: border-box;
  width: 380px;

  ${p =>
    !p.readOnly &&
    css`
      :focus {
        ${p => !p.error && `border: 1px solid ${p.theme.colors.green100}`};
        border-radius: 0.25rem;
        outline: none;
        background-color: ${p => p.theme.colors.white100};
      }
    `};
`;

const Wrapper = styled.div`
  position: relative;
`;

const Error = styled.span`
  color: ${p => p.theme.errorColor};
  font-size: 12px;
`;

const MenuStyle = {
  backgroundColor: '#ffffff'
};

const Item = styled.div`
  ${p => p.isHighlighted && `background-color: rgba(0, 0, 0, 0.05)`};
  border-radius: 4;
  box-sizing: border-box;
  color: #6b6b6b;
  cursor: pointer;
  font-size: 16;
  height: 36px;
  padding: 6px 12px 6px 12px;
  width: 100;
`;
