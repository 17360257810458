import React from 'react';
import { observer } from 'mobx-react-lite';

import { UserAvatar } from 'components/UserAvatar';
import { Box } from 'components/Box';
import { Text } from 'components/Text';
import { IconButton } from 'components/IconButton';
import { IconClose } from 'components/icons/IconClose';

export const NamePlate = observer(props => {
  const { name, title, pic, onRemove, size = 'md' } = props;
  return (
    <>
      <UserAvatar name={name} size={size} src={pic} />
      <Box ml="12" grow>
        <Text type="bold16" color="purple90" children={name} />
        {title && <Text type="regular14" color="purple60" mt="-4" children={title} />}
      </Box>
      {onRemove && <IconButton trans icon={IconClose} onClick={onRemove} />}
    </>
  );
});
