import { useState, useCallback, useMemo } from 'react';
import { isEditorStateEmpty, makeEditorState, editorStateToHtml } from './helpers';

export const useEditorState = (text = '', onChange) => {
  const [initial] = useState(() => text);
  const [editorState, setEditorState] = useState(() => makeEditorState(text));

  const onEditorStateChange = useCallback(
    editorState => {
      setEditorState(editorState);
      if (onChange) {
        onChange(editorState, editorStateToHtml(editorState));
      }
    },
    [onChange]
  );

  const resetEditorState = useCallback(() => {
    setEditorState(makeEditorState(initial));
  }, [initial]);

  const isEmpty = useMemo(() => isEditorStateEmpty(editorState), [editorState]);

  return {
    editorState,
    setEditorState: onEditorStateChange,
    resetEditorState,
    isEmpty
  };
};
