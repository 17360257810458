import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch } from 'react-router';

import { Page } from 'components/Page';
import { useStore } from 'models/Provider';
import { TeamDetails } from 'pages/Team/TeamDetails';
import { Settings } from 'pages/Settings';
import Templates from 'pages/Templates';
import { ApplicationContainer } from 'pages/Job/Application';
import { ApplicationOnboarding } from 'pages/Job/ApplicationOnboarding';
import {
  Dashboard,
  Job,
  CreateJob,
  Onboarding,
  Applicants,
  Team,
  SlackAuth,
  Sharing,
  Integrations
} from 'chunks';

export const Routes = observer(() => {
  const { hasPerm } = useStore();
  return (
    <Page>
      <React.Suspense fallback={<div />}>
        <Switch>
          {routeConfig.map((route, index) => {
            if (route.permission && !hasPerm(route.permission)) return null;

            return (
              <Route
                key={index}
                exact={Boolean(route.exact)}
                path={route.path}
                render={props => {
                  if (route.redirect) {
                    const isFn = typeof route.redirect === 'function';
                    return <Redirect to={isFn ? route.redirect(props) : route.redirect} />;
                  }

                  const isFn = typeof route.extra === 'function';
                  const extraProps = isFn ? route.extra(props) : route.extra;

                  return <route.component {...props} {...extraProps} />;
                }}
              />
            );
          })}
        </Switch>
      </React.Suspense>
    </Page>
  );
});

const routeConfig = [
  {
    permission: 'application:onboarding',
    path: '/jobs/:jobID/onboarding/:appID?/:section?/:integrationID?',
    component: ApplicationOnboarding
  },
  { permission: 'company:view-settings', path: '/settings/:section?', component: Settings },
  {
    permission: 'company:apps',
    path: '/apps/:integrationName/:tabName?/:objectId?',
    component: Integrations
  },
  { permission: 'company:apps', path: '/apps', exact: true, component: Dashboard },
  {
    permission: 'dashboard:view',
    path: '/jobs',
    exact: true,
    component: Dashboard
  },
  { permission: 'dashboard:view', path: '/', exact: true, redirect: '/jobs' },
  { permission: 'team:view', path: '/team/:id/:section?', component: TeamDetails },
  { permission: 'team:view', path: '/team', component: Team },
  { permission: 'applicants:view', path: '/applicants', exact: true, component: Applicants },
  { permission: 'applicants:view', path: '/onboarding', exact: true, component: Onboarding },
  {
    permission: 'job:create',
    path: '/create',
    exact: true,
    component: CreateJob,
    extra: { create: true }
  },
  {
    permission: 'job:create',
    path: '/edit/:jobID/:section?',
    component: CreateJob,
    extra: ({ match }) => ({ key: match.params.jobID })
  },
  {
    permission: 'job:view',
    path: '/jobs/:jobID/application/:appID/:section?',
    component: ApplicationContainer
  },
  { permission: 'job:view', path: '/jobs/:jobID/:section?/:appID?', component: Job },
  {
    permission: 'company:view-templates',
    path: '/templates/:section/:templateID?',
    component: Templates
  },
  { permission: 'slack:auth', path: '/slack/auth/', component: SlackAuth },
  { path: '/sharing/:jobID?', component: Sharing },
  { permission: 'dashboard:view', redirect: '/' },
  { redirect: '/sharing' }
];
