import { types as t } from 'mobx-state-tree';
import { ToastStore } from 'shared/modules/toasts/ToastStore';
import { ModalStore } from 'shared/modals/ModalStore';

export const UIStore = t
  .model('UIStore', {
    toastStore: t.optional(ToastStore, {}),
    modalStore: t.optional(ModalStore, {})
  })
  .actions(self => ({
    notify: (t, m) => self.toastStore.add(t, m),
    openModal: (t, p) => self.modalStore.show(t, p),
    openFullscreenModal: (t, p) => self.modalStore.showFullscreen(t, p)
  }));
