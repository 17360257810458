import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyles = createGlobalStyle(p => {
  const styles = {
    '*': {
      boxSizing: 'border-box',
      fontFamily: '"Work Sans", sans-serif'
    },
    html: {
      fontSize: '16px',
      height: '100%'
    },
    a: {
      color: 'inherit',
      textDecoration: 'inherit'
    },
    'a:visited, a:link, a:hover, a:active': {
      textDecoration: 'inherit'
    },
    body: {
      margin: 0,
      padding: 0,
      height: '100vh',
      backgroundColor: '#f6f6f6',
      overflow: 'hidden',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      '@media(max-width: 768px)': {
        overflow: 'visible'
      }
    },
    'body > #root': {
      height: '100%'
    },
    'h1, h2, h3, h4, h5': {
      margin: 0
    },
    button: {
      outline: 'none',
      border: 'none',
      background: 'none',
      margin: 0,
      padding: 0
    },
    h2: {
      ...p.theme.text.h2,
      color: p.theme.colors.purple100
    },
    '.react-datepicker-wrapper': {
      width: '100%'
    },
    '.react-datepicker-popper': {
      zIndex: 1000
    }
  };
  Object.keys(p.theme.bp).forEach(bp => {
    styles[`.hide-${bp}`] = {
      [`@media(max-width: ${p.theme.bp[bp]})`]: {
        display: 'none !important'
      }
    };
  });
  return styles;
});
