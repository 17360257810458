import React from 'react';
import { observer } from 'mobx-react-lite';

import { getIconForRating, getTextForRating } from 'helpers';
import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { TextLabel } from 'components/Text';
import { NamePlate } from 'components/NamePlate';
import {
  ApplicationReviewContainer,
  ApplicationReviewVote,
  ThumbContainer,
  FixedThumbButton,
  NoContent,
  YourReviewContainer,
  ThumbButton
} from './styles';

export const ApplicationReviews = observer(({ application: detail }) => {
  const { t } = useStore();
  const { reviews } = detail;
  detail.setUserRead('ratings', true);

  const reviewsToDisplay = reviews.filter(r => !!r.thumb);

  return (
    <Box mt="24">
      <TextLabel mb="12">{t('application.reviews.header.you')}</TextLabel>
      <ReviewVote detail={detail} t={t} />
      <Box flex justifyContent="space-between" mt="32" mb="12">
        <TextLabel>{t('application.reviews.header.team')}</TextLabel>
        {detail.averageRatingText !== '-' && (
          <TextLabel>{t('application.reviews.average', detail.averageRatingText)}</TextLabel>
        )}
      </Box>
      {reviewsToDisplay.length === 0 && <NoContent>{t('application.reviews.nocontent')}</NoContent>}
      {reviewsToDisplay.map((a, i) => (
        <ApplicationReviewContainer key={i} topBorder={i === 0}>
          <NamePlate pic={a.user.picture} name={a.user.displayName} size="lg" />
          <ApplicationReviewVote>
            <FixedThumbButton thumb={a.thumb} active disabled noMargin>
              <ThumbContainer>
                {getIconForRating(a.thumb)}
                <span>{t(getTextForRating(a.doesThink))}</span>
              </ThumbContainer>
            </FixedThumbButton>
          </ApplicationReviewVote>
        </ApplicationReviewContainer>
      ))}
    </Box>
  );
});

const ReviewVote = observer(({ detail, t }) => {
  const { myReview } = detail;

  return (
    <YourReviewContainer>
      <ThumbButton
        thumb="D"
        active={myReview && myReview.doesThink === 'D'}
        onClick={() => detail.setReview('D')}
      >
        <ThumbContainer>
          {getIconForRating('D')}
          <span>{t('application.rating.defno')}</span>
        </ThumbContainer>
      </ThumbButton>
      <ThumbButton
        thumb="N"
        active={myReview && myReview.doesThink === 'N'}
        onClick={() => detail.setReview('N')}
      >
        <ThumbContainer>
          {getIconForRating('N')}
          <span>{t('application.rating.no')}</span>
        </ThumbContainer>
      </ThumbButton>
      <ThumbButton
        thumb="Y"
        active={myReview && myReview.doesThink === 'Y'}
        onClick={() => detail.setReview('Y')}
      >
        <ThumbContainer>
          {getIconForRating('Y')}
          <span>{t('application.rating.yes')}</span>
        </ThumbContainer>
      </ThumbButton>
      <ThumbButton
        noMargin
        thumb="S"
        active={myReview && myReview.doesThink === 'S'}
        onClick={() => detail.setReview('S')}
      >
        <ThumbContainer>
          {getIconForRating('S')}
          <span>{t('application.rating.strongyes')}</span>
        </ThumbContainer>
      </ThumbButton>
    </YourReviewContainer>
  );
});
