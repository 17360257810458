import { types as t } from 'mobx-state-tree';
import { UserRef } from 'models/users';
import { withEnv } from 'models/withEnv';

const ApplicationId = t.model('ApplicationId', {
  id: t.identifierNumber
});

export const Notification = t
  .model('Notification', {
    after: t.maybeNull(t.union(t.string, t.number, t.boolean)),
    afterText: t.maybeNull(t.string),
    applicationIds: t.maybeNull(t.array(ApplicationId)),
    applicationId: t.maybeNull(t.number),
    applicationName: t.maybeNull(t.string),
    before: t.maybeNull(t.union(t.string, t.number, t.boolean)),
    beforeText: t.maybeNull(t.string),
    created: t.string,
    id: t.identifierNumber,
    isRead: t.boolean,
    jobId: t.maybeNull(t.number),
    jobTitle: t.maybeNull(t.string),
    notificationType: t.string,
    user: t.maybeNull(t.late(() => UserRef)),
    objectId: t.maybeNull(t.number),
    triggerId: t.maybeNull(t.number),
    triggerType: t.maybeNull(t.string),
    triggerName: t.maybeNull(t.string),
    isToday: false
  })
  .views(withEnv)
  .actions(self => ({
    markAsRead: () => {
      self.env.api.notifications.markAsRead(self.id);
      self.isRead = true;
    }
  }))
  .views(self => ({
    get jobUrl() {
      return `/jobs/${self.jobId}`;
    },
    get applicationUrl() {
      return `/jobs/${self.jobId}/application/${self.applicationId}`;
    },
    get ratingsUrl() {
      return `/jobs/${self.jobId}/application/${self.applicationId}/ratings`;
    },
    get notesUrl() {
      return `/jobs/${self.jobId}/application/${self.applicationId}/notes`;
    },
    get integrationUrl() {
      return `/jobs/${self.jobId}/onboarding/${self.applicationId}/integrations/${self.triggerId}`;
    },
    get messagesUrl() {
      return `/jobs/${self.jobId}/application/${self.applicationId}/messages`;
    }
  }));
