import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconPhone32 = props => (
  <Svg32 {...props}>
    <rect x="1" y="1" width="30" height="30" rx="15" fill="#3C3C64" stroke="#fff" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.756 12.178l-.89.889c1.6 1.6 1.6 4.088 0 5.777l.89.89c2.222-2.045 2.222-5.423 0-7.556zm-1.867 1.866l-.889.89a1.734 1.734 0 010 2.044l.889.889c1.067-1.067 1.067-2.667 0-3.823zM20.143 7h-9.286C9.836 7 9 7.736 9 8.636v14.728c0 .9.836 1.636 1.857 1.636h9.286C21.164 25 22 24.264 22 23.364V8.636C22 7.736 21.164 7 20.143 7zM20 23h-9V9h9v14z"
      fill="#F6F6F6"
    />
  </Svg32>
);
