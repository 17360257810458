import React from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter, Redirect } from 'react-router-dom';

function AuthProvider({ location, authStore, children }) {
  if (authStore.isAuthenticated()) {
    if (location.pathname === '/signin') {
      return <Redirect to={{ pathname: '/' }} />;
    }
    return children;
  } else {
    authStore.clearStorage();
    const redirect = { pathname: '/signin', state: { from: location.pathname } };
    return <Redirect to={redirect} />;
  }
}

export default withRouter(observer(AuthProvider));
