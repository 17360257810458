import React from 'react';
import styled from 'styled-components';
import { useStore } from 'models/Provider';

export const LoadingMore = () => {
  const { t } = useStore();
  return (
    <Loading>
      <div>{t('job.stages.loadingMore')}</div>
    </Loading>
  );
};

const Loading = styled.div(p => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  height: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: p.theme.colors.white,
  backgroundColor: p.theme.colors.purple80,
  ...p.theme.text.bold16,
  borderRadius: '0 0 0.25rem 0.25rem',

  overflow: 'hidden',
  width: '100%'
}));
