import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { useStore } from 'models/Provider';

function ShowMore({ count = 5, inc = 5, max = 0, render }) {
  const { t } = useStore();
  const [state, setState] = useState({
    count: count || 5,
    inc: inc || 5
  });

  useEffect(() => {
    setState(old => ({ ...old, count }));
  }, [count]);

  function increment(e) {
    e.preventDefault();
    e.stopPropagation();
    setState(old => ({
      ...old,
      count: Math.min(old.count + old.inc, max)
    }));
  }

  return (
    <div>
      {render({ count: state.count, inc: state.inc, max })}
      {state.count < max && (
        <ShowMoreText onClick={increment}>
          {t('showmore')} ({max - state.count})
        </ShowMoreText>
      )}
    </div>
  );
}

export default observer(ShowMore);

export const ShowMoreText = styled.div`
  padding: 10px;
  opacity: 0.5;
  color: #7d7f97;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;
