import styled from 'styled-components/macro';
import { IconClose } from 'components/icons/IconClose';

export const Container = styled.div`
  display: flex;
  @media (max-width: ${p => p.theme.bp.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-left: 1.5rem;
  @media (max-width: ${p => p.theme.bp.md}) {
    margin-left: 0;
  }
`;

export const Item = styled.div`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid ${props => (props.hasFocus ? props.theme.primaryColor : 'transparent')};
  border-radius: 4px;
  display: flex;
  margin-bottom: 3px;
  padding: 12px;
  [data-close] {
    display: none;
  }
  :hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    [data-close] {
      display: block;
    }
  }
  span {
    margin-left: 12px;
    width: 100%;
  }
  > input {
    width: 100%;
    font-size: 0.875rem;
    font-family: inherit;
  }
  > input:focus {
    outline: none;
    margin-left: 12px;
    border: 0;
  }
  ${props => (props.isDragging ? 'transform: rotate(-3deg)' : '')};
`;

export const CloseIcon = styled(IconClose)(p => ({
  color: p.theme.colors.purple100,
  opacity: 0.65,
  ':hover': {
    opacity: 1,
    cursor: 'pointer'
  }
}));
