import React from 'react';
import styled from 'styled-components/macro';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { PlaceholderMenu } from './PlaceholderMenu';

// This is the component that is displayed instead of the {{ tag }} in the editor.
const Placeholder = ({ children }) => {
  return <PlaceholderContainer>{children}</PlaceholderContainer>;
};

// The 'strategy' for how to decide if an entity in the editor is a placeholder
// If it is, that means it should render the 'Placeholder' component above.
function findPlaceholders(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'placeholder';
  }, callback);
}

// Convert placeholder entities to bracket tags in the editorState
// So the API can just search and replace the {{ tags }} without
// having to know the complicated DraftJS object.
export const placeholdersToBrackets = raw => {
  let blx = [...raw.blocks].map(block => {
    let obj = { ...block };
    block.entityRanges.forEach(er => {
      const isPlaceholder = raw.entityMap[er.key].type === 'placeholder';
      if (!isPlaceholder) return;

      let str = block.text.substring(er.offset, er.offset + er.length);
      obj.text = obj.text.replace(str, raw.entityMap[er.key].data);
    });
    return obj;
  });
  return {
    ...raw,
    blocks: blx
  };
};

// Convert {{ placeholder }} tags in the editor to proper placeholder
// components with proper entity data.
export const convertPlaceholders = (editorState, placeholderMap) => {
  const selectionsToReplace = [];
  // Match any {{ stuff }} key
  const regex = new RegExp('\\{\\{\\s\\w+\\s\\}\\}|\\{\\{\\w+\\}\\}', 'g');

  let contentState = editorState.getCurrentContent();
  contentState.getBlockMap().forEach(contentBlock => {
    findWithRegex(regex, contentBlock, (start, end, matchStr) => {
      if (placeholderMap.hasOwnProperty(matchStr)) {
        // This is one of the placeholder keys, not just some {{ random }} key
        const blockKey = contentBlock.getKey();
        const blockSelection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end
        });
        selectionsToReplace.push({
          selectionState: blockSelection,
          match: matchStr
        });
      }
    });
  });

  if (selectionsToReplace.length === 0) {
    return editorState;
  }

  // Need to reverse the order of selections to replace.
  // Otherwise the stored offsets to replace (in the array above) will
  // mess up on each insert since the placeholder may not have the same
  // number of characters as the string being replaced.
  selectionsToReplace.reverse().forEach(({ selectionState, match }) => {
    const entity = contentState.createEntity('placeholder', 'IMMUTABLE', match);
    const entityKey = entity.getLastCreatedEntityKey();
    contentState = Modifier.replaceText(
      contentState,
      selectionState,
      placeholderMap[match],
      null,
      entityKey
    );
  });

  const es = EditorState.push(editorState, contentState);
  return EditorState.forceSelection(es, contentState.getSelectionAfter());
};

// Insert the placeholder into the draftjs instance. This means adding
// the 'placeholder' entity into the entity map and also add the
// placeholder component into the content.
export const insertPlaceholder = (editorState, label, data) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const entity = currentContent.createEntity('placeholder', 'IMMUTABLE', data);
  const entityKey = entity.getLastCreatedEntityKey();
  const textWithEntity = Modifier.insertText(currentContent, selection, label, null, entityKey);
  return EditorState.push(editorState, textWithEntity, 'insert-characters');
};

export const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr, start, end;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    const matchStr = matchArr[0];
    callback(start, end, matchStr);
  }
};

export const createPlaceholderPlugin = t => {
  let store = {};
  return {
    initialize: ({ getEditorState, setEditorState }) => {
      // Hook into the initialization of the editor so we can access
      // getState and setState in our plugin.
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
      store.t = t;
    },

    PlaceholderMenu: props => <PlaceholderMenu {...props} {...store} />,
    name: 'placeholder',
    decorators: [
      {
        strategy: findPlaceholders,
        component: ({ children }) => <Placeholder children={children} />
      }
    ]
  };
};

const PlaceholderContainer = styled.span`
  background-color: ${p => p.theme.primaryColor};
  padding: 0 2px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.04);
  color: ${p => p.theme.primaryColor};
  line-height: 20px;
`;
