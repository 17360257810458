import { API_URL, SEARCH_USERS_URL, USERS_URL } from './urls';

export const makeUsersClient = r => ({
  loadUser: id => r(`${USERS_URL}${id}/`),
  load: () => r(`${SEARCH_USERS_URL}?limit=5000`),
  loadPictures: () => r(`${USERS_URL}?pictures=1`),
  signIn: (url, data) => r(url, 'post', data),
  setPassword: (url, data) => r(url, 'patch', data),
  resetPassword: (url, data) => r(url, 'post', data),
  create: data => r(USERS_URL, 'post', data),
  remove: id => r(`${USERS_URL}${id}/`, 'delete'),
  updateDetails: (id, data) => r(`${USERS_URL}${id}/`, 'patch', data),
  updatePassword: data => r(`${API_URL}password/`, 'patch', data),
  authenticateSlack: code => r(`${API_URL}integrations/slack/auth/?code=${code}`),
  loadFollowingStats: () => r(`${API_URL}users-following-stats/`),
  grantJobAccess: (user, job) => r(`${USERS_URL}${user}/jobs/${job}/`, 'post')
});
