import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Tab, TabList, useTabState } from 'components/Tabs';
import { BreadCrumbPath, BreadCrumb } from 'components/BreadCrumb';
import { Header } from 'components/Header';
import { Page, PageContent } from 'components/Page';

import { Apps } from './Apps';
import { CompanySettings } from './CompanySettings';

export const Settings = observer(() => {
  const { t } = useStore();
  return (
    <Page>
      <Header
        mobileTitle={t('company.settings.crumb.root')}
        breadcrumbs={<SettingsCrumbs />}
        tabs={<SettingsTabs />}
      />
      <PageContent>
        <Switch>
          <Route path="/settings/company/" component={CompanySettings} />
          <Route path="/settings/apps/" component={Apps} />
        </Switch>
      </PageContent>
    </Page>
  );
});

const SettingsCrumbs = observer(() => {
  const { t } = useStore();
  const { section } = useParams();
  return (
    <BreadCrumb>
      <BreadCrumbPath active={!section}>{t('company.settings.crumb.root')}</BreadCrumbPath>
      {section && <BreadCrumbPath active>{t(`company.settings.tabs.${section}`)}</BreadCrumbPath>}
    </BreadCrumb>
  );
});

const SettingsTabs = observer(() => {
  const { t } = useStore();
  const { section } = useParams();
  const tabId = getTabIdFromSection(section);
  const tabState = useTabState({ selectedId: tabId });

  return (
    <TabList state={tabState} label="Dashboard selection">
      <Tab link="/settings/company">{t('company.settings.tabs.company')}</Tab>
      <Tab link="/settings/apps">{t('company.settings.tabs.apps')}</Tab>
    </TabList>
  );
});

const getTabIdFromSection = section => {
  if (section === 'company') return 0;
  return 1;
};
