import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';
import { FormError } from 'components/Text';
import { Box } from 'components/Box';

const StyledInput = styled.input(p => ({
  height: '2.5rem',
  width: '100%',
  borderRadius: '0.25rem',
  backgroundColor: p.theme.colors.purpleTrans5,
  transition: 'all 100ms ease-out',
  border: '1px solid',
  borderColor: 'transparent',
  color: p.theme.colors.purple80,
  padding: '0.5rem 0.75rem',
  ...(p.bold ? p.theme.text.bold16 : p.theme.text.regular16),
  opacity: 1,
  ...(p.error && { borderColor: p.theme.colors.red100 }),
  ...(p.disabled && !p.readOnly && { opacity: 0.65 }),
  ...(!p.readOnly &&
    !p.disabled && {
      ':hover': {
        backgroundColor: p.theme.colors.purpleTrans10
      },
      ':focus': {
        outline: 'none',
        borderRadius: '0.25rem',
        backgroundColor: p.theme.colors.white100,
        ...(!p.error && { border: '0.0625rem solid' + p.theme.colors.green100 })
      }
    }),
  '::placeholder': {
    color: p.theme.colors.purple40
  }
}));

const Wrapper = styled.div({
  position: 'relative'
});

const IconContainer = styled.div({
  position: 'absolute',
  top: 6,
  right: 10
});

const InputBase = (props, ref) => {
  const { type = 'text', icon, error, onChange, focusOnMount = false, ...rest } = props;
  const localRef = React.useRef();
  const onUpdate = React.useCallback(e => void onChange(e.target.value), [onChange]);
  React.useEffect(() => {
    if (focusOnMount) {
      if (ref) {
        ref.current.focus();
      } else {
        localRef.current.focus();
      }
    }
  }, [focusOnMount, ref]);
  return (
    <Wrapper>
      <StyledInput
        error={error}
        type={type}
        ref={ref || localRef}
        spellCheck="false"
        {...rest}
        onChange={onUpdate}
      />
      {icon && <IconContainer>{icon}</IconContainer>}
      {error && (
        <Box mt="8">
          <FormError>{error}</FormError>
        </Box>
      )}
    </Wrapper>
  );
};

export const Input = observer(InputBase, { forwardRef: true });
