import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconFiles20 = props => (
  <Svg {...props} viewBox="0 0 20 20">
    <path d="M14 8.414V15H7V5h3.586L14 8.414z" stroke="currentColor" strokeWidth="2" />
    <path
      clipRule="evenodd"
      d="M11 5.5V9h3l-3-3.5z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
