import styled from 'styled-components/macro';

export default styled.div`
  display: flex;
  position: relative;
  ${props => props.direction && `flex-direction: ${props.direction}`};
  ${props => props.justify && `justify-content: ${props.justify}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};
  ${props => props.mt && `margin-top: ${props.mt}`};
  ${props => props.mb && `margin-bottom: ${props.mb}`};
  ${props => props.ml && `margin-left: ${props.ml}`};
  ${props => props.mr && `margin-right: ${props.mr}`};
`;
