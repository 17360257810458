import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconIntegrationWorkplace = props => (
  <Svg32 {...props} viewBox="0 0 40 40">
    <path
      d="M25.209 18.952c-.414.89-.769 1.724-1.124 2.558-.768 1.836-1.596 3.671-2.365 5.507-.473 1.057-1.537 1.724-2.779 1.724-1.241 0-2.365-.667-2.838-1.724-1.123-2.559-2.247-5.173-3.37-7.732-.71-1.613-1.36-3.226-2.07-4.783-.118-.334-.118-.334.237-.334h3.547c.237 0 .355.056.414.278 1.301 3.004 2.602 6.063 3.903 9.067.059.111.059.222.177.278.119-.056.119-.111.119-.167 1.123-2.614 2.246-5.173 3.37-7.843.591-1.446 2.602-2.28 4.257-1.446.65.333 1.123.834 1.42 1.502 1.182 2.78 2.423 5.562 3.606 8.399.06.056.06.167.118.278.237-.222.355-.5.532-.723 2.247-4.06 2.247-8.121-.236-12.015-2.07-3.282-5.203-5.229-9.165-5.674-5.322-.667-9.815.946-13.245 4.784-3.725 4.116-4.612 8.844-2.72 13.906 1.952 5.062 5.913 8.121 11.59 9.178 2.128.39 4.198.278 6.267-.222.355-.056.355-.056.355.222v3.17c0 .223-.06.279-.296.279-6.8 1.168-12.771-.5-17.502-5.229-4.967-4.95-6.327-10.958-4.257-17.521C4.574 10.274 7.529 6.88 11.787 4.6 15.217 2.71 19 1.93 22.962 2.32c4.553.445 8.337 2.392 11.175 5.785 3.075 3.615 4.14 7.787 3.43 12.348-.414 2.56-1.478 4.84-3.193 6.898-.769.946-1.774 1.557-3.134 1.446-1.183-.111-2.07-.667-2.542-1.724-1.124-2.559-2.247-5.173-3.37-7.732 0-.111 0-.223-.12-.39z"
      fill="#373E4C"
    />
  </Svg32>
);
