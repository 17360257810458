import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useStore } from 'models/Provider';

export const UserModal = observer(({ title, children }) => {
  const { jobStore } = useStore();

  useEffect(() => {
    jobStore.loadForUserAccess();
  }, [jobStore]);

  return (
    <CreateUserWrapper>
      <h2>{title}</h2>
      {children}
    </CreateUserWrapper>
  );
});

const CreateUserWrapper = styled.div`
  background-color: #ffffff;
  height: 100%;
  margin: 0 auto;
  max-width: 380px;
  > div {
    flex-grow: 1;
  }
  @media (max-width: 480px) {
    padding: 10px 0;
  }
`;
