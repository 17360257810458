import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from 'models/Provider';
import { BreadCrumb, BreadCrumbPath } from 'components/BreadCrumb';
import { Header } from 'components/Header';
import { PageContent } from 'components/Page';
import { Tab, TabList, TabPanel, TabPanelList, useTabState } from 'components/Tabs';
import { TeamDetailsNotifications } from './TeamDetailsNotifications';
import { TeamDetailsProfile } from './TeamDetailsProfile';
import { TeamDetailsPassword } from 'pages/Team/TeamDetailsPassword';

export const TeamDetails = observer(() => {
  const { userStore } = useStore();
  const { id, section } = useParams();
  const user = userStore.items.get(id);
  const tabState = useTabState({ selectedId: getTabIdFromSection(section) });

  if (section !== 'profile' && !user.isMe) {
    return <Redirect to={`/team/${id}/profile`} />;
  }

  return (
    <>
      <Header
        mobileTitle={user.name}
        tabs={!user.isMe ? null : <TeamDetailTabs tabState={tabState} user={user} />}
        breadcrumbs={<TeamDetailCrumbs user={user} />}
      />
      <PageContent style={{ overflowY: 'auto' }}>
        <TabPanelList state={tabState}>
          <TabPanel>
            <TeamDetailsProfile user={user} />
          </TabPanel>
          {user.isMe && (
            <TabPanel>
              <TeamDetailsNotifications />
            </TabPanel>
          )}
          {user.isMe && (
            <TabPanel>
              <TeamDetailsPassword />
            </TabPanel>
          )}
        </TabPanelList>
      </PageContent>
    </>
  );
});

const TeamDetailCrumbs = observer(({ user }) => {
  const { t } = useStore();
  const { section } = useParams();
  return (
    <BreadCrumb>
      <BreadCrumbPath link="/team">{t('navigation.team')}</BreadCrumbPath>
      {user && <BreadCrumbPath active={!section}>{user.name}</BreadCrumbPath>}
      {section && <BreadCrumbPath active>{section}</BreadCrumbPath>}
    </BreadCrumb>
  );
});

const TeamDetailTabs = observer(({ tabState, user }) => {
  const { t } = useStore();
  const { id } = useParams();
  return (
    <TabList state={tabState} label="User details">
      <Tab link={`/team/${id}/profile`}>{t('user.tabs.profile')}</Tab>
      {user.isMe && <Tab link={`/team/${id}/notifications`}>{t('user.tabs.notifications')}</Tab>}
      {user.isMe && <Tab link={`/team/${id}/password`}>{t('user.tabs.password')}</Tab>}
    </TabList>
  );
});

const getTabIdFromSection = section => {
  if (!section) return 0;
  switch (section) {
    case 'profile':
      return 0;
    case 'notifications':
      return 1;
    case 'password':
      return 2;
    default:
      return 0;
  }
};
