import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams } from 'react-router-dom';
import { Tab, TabList, useTabState } from 'components/Tabs';
import { useApplicationTabs } from 'hooks/useApplicationTabs';
import { Box } from 'components/Box';

export const ApplicationTabs = observer(
  ({ application, border = false }, ref) => {
    const { section } = useParams();
    const loc = useLocation();
    const useMessages = application.job.useMessages;
    const tabState = useTabState({ selectedId: sectionToTabIndex(section, useMessages) });
    const tabs = useApplicationTabs(application);
    const addOnClose = loc && loc.state && loc.state.onCloseUrl;

    return (
      <TabList state={tabState} border={border} ref={ref} collapseOnMobile>
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            link={
              addOnClose
                ? { pathname: tab.link, state: { onCloseUrl: loc.state.onCloseUrl } }
                : tab.link
            }
          >
            <Box flex alignItems="center">
              <tab.icon />
              <Box ml="4">{tab.display}</Box>
              {tab.count ? <Value active={tab.activeCount}>{tab.count}</Value> : null}
            </Box>
          </Tab>
        ))}
      </TabList>
    );
  },
  { forwardRef: true }
);

const sectionToTabIndex = (section, useMessages) => {
  switch (section) {
    case 'details':
      return 0;
    case 'notes':
      return 1;
    case 'ratings':
      return 2;
    case 'files':
      return 3;
    case 'messages':
      return 4;
    case 'access':
      return useMessages ? 5 : 4;
    default:
      return 0;
  }
};

const Value = styled.div(p => ({
  minHeight: '1rem',
  minWidth: '1rem',
  borderRadius: '0.125rem',
  backgroundColor: p.theme.colors.purpleTrans10,
  color: p.theme.colors.purple80,
  ...p.theme.text.semibold10,
  padding: '0.125rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '0.25rem',
  ...(p.active && {
    color: p.theme.colors.white100,
    backgroundColor: p.theme.colors.green100
  })
}));
