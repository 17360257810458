import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { ApplicationLabels } from 'components/ApplicationLabels';
import { Input } from 'components/Input';
import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { FormLabel, Text } from 'components/Text';
import { Loading } from 'components/Loading';
import { Separator } from 'components/Separator';
import { Tooltip } from 'components/Tooltip';
import { UserAvatar } from 'components/UserAvatar';
import { IconUnverified } from 'components/icons/IconUnverified';
import { OnboardingApplicationDetails, VerifySsn, VerifyIconContainer } from './styles';

function ApplicationOnboardingDetails({ application, useSsnRegistry }) {
  const { t } = useStore();
  const [updating, setUpdating] = useState(false);
  const [editingSsn, setEditingSsn] = useState(false);
  const [ssn, setSsn] = useState(application.ssn);
  const ssnRef = useRef();

  useEffect(() => {
    setSsn(application.ssn);
  }, [application.ssn]);

  function startEditing() {
    setEditingSsn(true);
    ssnRef.current.focus();
  }

  function reset() {
    setSsn(application.ssn);
    setEditingSsn(false);
  }

  async function saveEditedSsn() {
    setUpdating(true);
    const success = await application.updateSsn(ssn);
    if (success) {
      reset();
    }
    setUpdating(false);
  }

  function updateSsn(value) {
    const val = value.trim();
    if (!isNaN(val)) {
      setSsn(val);
    }
  }

  async function wrapUpdate(fn) {
    setUpdating(true);
    await fn();
    setUpdating(false);
  }

  function verify() {
    wrapUpdate(application.verifySsn);
  }

  function matchName() {
    wrapUpdate(application.matchName);
  }

  function keepName() {
    wrapUpdate(application.keepName);
  }

  function renderNameIcon() {
    if (!useSsnRegistry) return null;

    switch (application.ssnStatus) {
      case 'unverified':
        return <Exclamation />;
      case 'verified':
        return <Checkmark />;
      case 'ssn_only':
        return (
          <Checkmark
            neutral
            tip={t('application.onboarding.tip.ssn_only', application.ssnRegistryResult.name)}
          />
        );
      case 'keep_or_match':
        return (
          <Mismatch
            tip={t('application.onboarding.tip.keep_or_match', application.ssnRegistryResult.name)}
          />
        );
      default:
        return null;
    }
  }

  function renderSsnIcon() {
    if (!useSsnRegistry) return null;

    switch (application.ssnStatus) {
      case 'unverified':
        return <Exclamation />;
      case 'verified':
      case 'ssn_only':
        return <Checkmark />;
      case 'error':
        return <Mismatch tip={t('application.onboarding.tip.error')} />;
      default:
        return null;
    }
  }

  const stillSameSsn = application.ssn === ssn;
  const dt = format(new Date(application.date), 'MMMM do yyyy');

  return (
    <>
      <Box flex>
        <UserAvatar src={application.picture} name={application.name} size="xl" />
        <Box ml="16">
          <Text type="h1" color="purple100">
            {application.name}
          </Text>
          <Text type="regular16" color="purple60" mt="-4">
            {t('application.onboarding.applied')} &bull; {dt}
          </Text>
          <ApplicationLabels application={application} />
        </Box>
      </Box>
      <OnboardingApplicationDetails>
        <Tooltip id="details-tip" place="top" />

        <FormLabel>{t('application.onboarding.label.name')}</FormLabel>
        <div style={{ position: 'relative' }}>
          <Input value={application.name} readOnly />
          <VerifyIconContainer>{renderNameIcon()}</VerifyIconContainer>
        </div>

        <Box mt="16">
          <FormLabel>{t('application.onboarding.label.email')}</FormLabel>
          <Input value={application.email} readOnly />
        </Box>

        {application.phone && (
          <Box mt="16">
            <FormLabel>{t('application.onboarding.label.phone')}</FormLabel>
            <div style={{ position: 'relative' }}>
              <Input value={application.phone} readOnly />
            </div>
          </Box>
        )}

        {(application.ssn || useSsnRegistry) && (
          <Box mt="16">
            <FormLabel>{t('application.onboarding.label.ssn')}</FormLabel>
            <div style={{ position: 'relative' }}>
              <Input
                value={ssn || ''}
                ref={ssnRef}
                onChange={useSsnRegistry ? updateSsn : null}
                onFocus={useSsnRegistry ? startEditing : null}
                readOnly={!useSsnRegistry}
              />
              {ssn && useSsnRegistry && !editingSsn && (
                <VerifyIconContainer>{renderSsnIcon()}</VerifyIconContainer>
              )}
            </div>
          </Box>
        )}

        {updating && <Loading />}

        {useSsnRegistry && application.ssnStatus === 'keep_or_match' && (
          <VerifySsn>
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    'application.onboarding.verifyssn.keepormatch',
                    application.ssnRegistryResult.name
                  )
                }}
              />
            </div>
            <Box mb="8">
              <Button gray fullWidth onClick={updating ? null : keepName} disabled={updating}>
                {t('application.onboarding.button.keep')}
              </Button>
            </Box>
            <Button fullWidth onClick={updating ? null : matchName} disabled={updating}>
              {t('application.onboarding.button.match')}
            </Button>
          </VerifySsn>
        )}

        {!editingSsn &&
          application.ssn &&
          useSsnRegistry &&
          (application.ssnStatus === 'verified' ||
            application.ssnStatus === 'ssn_only' ||
            application.ssnStatus === 'error') && (
            <Button fullWidth gray onClick={verify} disabled={updating} mt="16">
              {t('application.onboarding.button.again')}
            </Button>
          )}

        {editingSsn && ssn && (
          <Box flex justifyContent="space-between" mt="16">
            <Button gray onClick={reset} disabled={updating}>
              Cancel
            </Button>
            <Button onClick={saveEditedSsn} disabled={stillSameSsn || updating}>
              Update SSN
            </Button>
          </Box>
        )}

        {application.ssn && useSsnRegistry && application.ssnStatus === 'unverified' && (
          <VerifySsn>
            <div dangerouslySetInnerHTML={{ __html: t('application.onboarding.unverified') }} />

            <Button fullWidth onClick={updating ? null : verify} disabled={updating}>
              {t('application.onboarding.button.verify')}
            </Button>
          </VerifySsn>
        )}
        <Separator mt="32" />
      </OnboardingApplicationDetails>
    </>
  );
}

export default observer(ApplicationOnboardingDetails);

function Exclamation() {
  return <IconUnverified data-tip="Unverified" data-for="details-tip" />;
}

function Checkmark({ neutral, tip }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-tip={tip || 'Verified'}
      data-for="details-tip"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        fill={!neutral ? '#00C882' : '#9CA6B0'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.42L5 12.42L6.41 11.01L10 14.59L17.59 7L19 8.42L10 17.42Z"
        fill="white"
      />
    </svg>
  );
}

function Mismatch({ tip }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-tip={tip}
      data-for="details-tip"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        fill="#EB5757"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z"
        fill="#F6F6F6"
      />
    </svg>
  );
}
