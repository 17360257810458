import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconOptionsMulti = props => (
  <Svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9H2v2h12V9zm0-4H2v2h12V5zm4 8V9h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 15h8v-2H2v2z"
      fill="currentColor"
    />
  </Svg>
);
