import { types as t, getParent } from 'mobx-state-tree';
import { isEditorHtmlEmpty } from 'components/TextEditor';
import { JobForm } from 'models/jobs';
import { Language } from 'models/ui';

export const JobLanguage = t
  .model('JobLanguage', {
    description: '',
    location: '',
    form: t.maybeNull(JobForm),
    shortDescriptionHtml: t.maybeNull(t.optional(t.string, '')),
    title: '',
    language: t.frozen(Language),
    isEnabled: true,

    // local variables
    isLocal: false
  })
  .actions(self => ({
    afterCreate() {
      if (!self.form) {
        self.form = JobForm.create();
      }
    },
    afterAttach() {
      const job = getParent(self, 2);
      if (job.isPublished && self.isLocal) {
        self.isEnabled = false;
      }
    },
    setTitle(title) {
      self.title = title;
    },
    setLocation(loc) {
      self.location = loc;
    },
    setShortDescription(shortDescription) {
      self.shortDescriptionHtml = shortDescription;
    },
    setDescription(description) {
      self.description = description;
    },
    disable() {
      self.isEnabled = false;
    },
    enable() {
      self.isEnabled = true;
    },
    findOptionDisplayByValue(value) {
      try {
        for (let i = 0; i < self.form.sections.length; i++) {
          const section = self.form.sections[i];
          for (let j = 0; j < section.questions.length; j++) {
            const question = section.questions[j];

            if (question.type !== 'multidropdown' && question.type !== 'dropdown') continue;
            if (!question.options) continue;

            for (let k = 0; k < question.options.length; k++) {
              const opt = question.options[k];
              if (opt.value === value) {
                return opt.display;
              }
            }
          }
        }
      } catch (e) {}
      return null;
    }
  }))
  .views(self => ({
    get display() {
      switch (self.language) {
        case 'is':
          return 'Icelandic';
        case 'en':
          return 'English';
        default:
          throw new Error('Invalid language');
      }
    },
    get descriptionEmpty() {
      return isEditorHtmlEmpty(self.description);
    },
    get shortDescriptionEmpty() {
      return isEditorHtmlEmpty(self.shortDescriptionHtml);
    },
    get formIncomplete() {
      return (
        self.form &&
        self.form.sections.some(section => {
          return (
            section.questions &&
            section.questions.some(question => !question.title && !question.hidden)
          );
        })
      );
    },
    get descriptionIncomplete() {
      return !self.title || self.shortDescriptionEmpty || !self.location;
    },
    get isComplete() {
      return !self.descriptionIncomplete && !self.formIncomplete;
    }
  }));

export const JobLanguages = t
  .model('JobLanguages', {
    languages: t.array(JobLanguage)
  })
  .views(self => ({
    get langIds() {
      return self.languages.map(obj => obj.language);
    }
  }))
  .actions(self => ({
    notifyIncompletes(lang) {
      let errors = [];
      const obj = self.languages.find(obj => obj.language === lang);
      if (!obj.title) errors.push(self.env.t('job.create.languages.missing.title'));
      if (!obj.location) errors.push(self.env.t('job.create.languages.missing.location'));
      if (obj.shortDescriptionEmpty) errors.push(self.env.t('job.create.languages.missing.short'));
      if (obj.formIncomplete) errors.push(self.env.t('job.create.languages.missing.form'));
      if (errors.length > 0) {
        self.env.notify(
          'error',
          self.env.t('job.create.languages.publish.incomplete', obj.display, errors.join(', '))
        );
      }
    }
  }));
