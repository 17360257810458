export const capitalizeFirst = str => {
  if (!str) return '';
  return str[0].toUpperCase() + str.substring(1);
};

export const abbreviation = name => {
  const names = name.split(' ');
  if (names.length > 1) {
    return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
  } else {
    return names[0].charAt(0).toUpperCase();
  }
};
