import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { Loading } from 'components/Loading';
import { Switch } from 'components/Switch';
import { Text } from 'components/Text';

export const TeamDetailsNotifications = observer(() => {
  const { notificationStore, t } = useStore();
  notificationStore.loadSettings();

  return (
    <>
      <Text type="h2" color="green100">
        {t('user.notifications.emailHeading')}
      </Text>

      <Header mt="24">
        <Text type="semibold14" color="purple90" caps>
          {t('user.notifications.emailTableHeader')}
        </Text>
        <Text type="semibold14" color="purple90" caps>
          {t('user.notifications.mail')}
        </Text>
      </Header>

      {notificationStore.loadingSettings && <Loading />}
      {notificationStore.loadedSettings && (
        <Row>
          <Text type="regular16" color="purple90">
            {t('user.notifications.applicant_applies')}
          </Text>
          <Switch
            value={notificationStore.emailSettings.newApplication}
            onToggle={notificationStore.emailSettings.toggleNewApplication}
          />
        </Row>
      )}

      <Text type="h2" color="green100" mt="32">
        {t('user.notifications.heading')}
      </Text>

      <Header mt="24">
        <Text type="semibold14" color="purple90" caps>
          {t('user.notifications.tableHeader')}
        </Text>
        <Text type="semibold14" color="purple90" caps>
          {t('user.notifications.desktop')}
        </Text>
      </Header>
      {notificationStore.loadedSettings &&
        NOTIFICATION_MAP.map(({ key, label }) => {
          let s = notificationStore.listSettings.find(x => x.notificationType === key);
          if (!s) return null;
          return (
            <Row key={s.id}>
              <Text type="regular16" color="purple90">
                {t(label)}
              </Text>
              <Switch
                value={s.notifyInApp}
                onToggle={() => notificationStore.settings.get(s.id).toggleSetting(s.id)}
              />
            </Row>
          );
        })}
    </>
  );
});

const NOTIFICATION_MAP = [
  { key: 'APPLY_JOB', label: 'user.notifications.applicant_applies' },
  { key: 'MOVE_STAGE', label: 'user.notifications.applicant_moved' },
  { key: 'ADD_RATING', label: 'user.notifications.applicant_rated' },
  { key: 'ADD_NOTE', label: 'user.notifications.applicant_noted' },
  { key: 'APPLICANT_HIRED', label: 'user.notifications.applicant_hired' },
  { key: 'APPLICANT_REJECTED', label: 'user.notifications.applicant_rejected' },
  { key: 'APPLICANT_ASSIGNED', label: 'user.notifications.applicant_assigned' },
  { key: 'BULK_REJECT_APPLICANTS', label: 'user.notifications.applicants_bulk_rejected' },
  { key: 'ADD_TO_HIRING_TEAM', label: 'user.notifications.add_to_hiring_team' },
  { key: 'PUBLISH_JOB', label: 'user.notifications.job_published' },
  { key: 'ARCHIVE_JOB', label: 'user.notifications.job_archived' },
  { key: 'EXPIRE_JOB', label: 'user.notifications.job_expired' },
  { key: 'INTEGRATION', label: 'user.notifications.integration' }
];

const Row = styled(Box).attrs({
  flex: true,
  justifyContent: 'space-between'
})(p => ({
  padding: '1rem 0',
  borderBottom: '1px solid ' + p.theme.colors.purpleTrans10
}));

const Header = styled(Box).attrs({
  flex: true,
  justifyContent: 'space-between'
})(p => ({
  borderBottom: '1px solid ' + p.theme.colors.purpleTrans10,
  paddingBottom: '0.75rem'
}));
