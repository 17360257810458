import React from 'react';
import styled from 'styled-components/macro';
import { Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useMobile } from 'hooks/useMobile';
import { useStore } from 'models/Provider';
import { Loading } from 'components/Loading';
import { Box } from 'components/Box';
import { PageContent } from 'components/Page';
import { ApplicationPageHeader } from './ApplicationPageHeader';
import { ApplicationMoveCopy } from './ApplicationMoveCopy';
import Application from './Application';
import { ApplicantList } from './ApplicantList';
import GDPRApplication from './GDPRApplication';

export const ApplicationContainer = observer(() => {
  const [error, setError] = React.useState(false);
  const { appID, jobID, section } = useParams();
  const { applicationStore, jobStore, t } = useStore();
  const isMobile = useMobile();
  const job = jobStore.items.get(jobID);
  const application = applicationStore.items.get(appID);

  React.useLayoutEffect(() => {
    jobStore.loadJob(jobID);
  }, [jobStore, jobID]);

  React.useLayoutEffect(() => {
    applicationStore.load(appID).then(success => {
      if (!success) setError(true);
    });
  }, [appID, applicationStore]);

  if (error) {
    return <Redirect to={`/jobs/${jobID}`} />;
  }

  if (!application || !job) {
    return <Loading />;
  }

  if (application.contentCleared) {
    return <GDPRApplication t={t} />;
  }

  switch (section) {
    case 'move':
    case 'copy':
      return <ApplicationMoveCopy application={application} move={section === 'move'} />;
    default:
      return (
        <>
          <ApplicationPageHeader application={application} />
          <PageContent alwaysScroll>
            <Box flex grow justifyContent="flex-end">
              <Application job={job} application={application} />
              {!isMobile && (
                <ApplicantListContainer>
                  <ApplicantListFixed>
                    <ApplicantList selectedApplication={application} job={job} />
                  </ApplicantListFixed>
                </ApplicantListContainer>
              )}
            </Box>
          </PageContent>
        </>
      );
  }
});

const ApplicantListContainer = styled.div(p => ({
  minWidth: '17.5rem',
  width: '17.5rem',
  marginLeft: p.theme.spacing.m40,
  position: 'relative'
}));

const ApplicantListFixed = styled.div({
  position: 'fixed'
});
