import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  isValidSsn,
  isOnlyDigits,
  bankingNumber,
  ledger,
  accountNumber
} from 'shared/utils/validators';

import { IntegrationData } from 'models/integrations';
import { useStore } from 'models/Provider';
import { Button } from 'components/Button';
import { FormLabel } from 'components/Text';
import { Input } from 'components/Input';
import { TextArea } from 'components/TextArea';
import { Switch } from 'components/Switch';
import { IntegrationField } from 'components/IntegrationField';
import { Loading } from 'components/Loading';
import { IconOpen } from 'components/icons/IconOpen';
import { Text, H3, SubText } from 'components/Text';
import { Box } from 'components/Box';
import { Checkbox } from 'components/Checkbox';
import { Field, File, IntegrationsButtonWrapper } from './styles';

function Integrations({ integration }) {
  const { t, openFullscreenModal, api } = useStore();
  const [saving, setSaving] = useState(false);
  const [validating, setValidating] = useState(false);

  async function preview() {
    setSaving(true);
    if (integration.isValid()) {
      const previewUrl = await integration.previewForApplication();
      const data = IntegrationData.create({ value: previewUrl }, { api });
      openFullscreenModal('INTEGRATION_FILE_PREVIEW', { data });
    } else {
      const firstError = integration.firstError();
      if (firstError && firstError.name) {
        const el = document.querySelector(`[data-id=${firstError.name}]`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
    setSaving(false);
  }

  async function submit(retrigger, save) {
    setSaving(true);
    if (save || integration.isValid()) {
      await integration.submitForApplication(retrigger, save);
    } else {
      const firstError = integration.firstError();
      if (firstError && firstError.name) {
        const el = document.querySelector(`[data-id=${firstError.name}]`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
    setSaving(false);
  }

  async function remove() {
    setSaving(true);
    await integration.deleteForApplication();
    setSaving(false);
  }

  function renderTriggeredInput(data) {
    switch (data.type) {
      case 'boolean':
        return <Switch value={data.value} id={data.name} name={data.name} readOnly />;
      case 'checkbox':
        return <Checkbox checked={data.value} label={data.name} disabled readOnly />;
      case 'hidden':
        return null;
      case 'file':
        return (
          <File onClick={() => openFullscreenModal('INTEGRATION_FILE_PREVIEW', { data })}>
            <Box flex alignItems="center" color="purple60">
              <IconOpen />
              <Text ml="8" color="purple100" type="semibold14">
                {data.fileName}
              </Text>
            </Box>
          </File>
        );
      case 'fileurl':
        return (
          <a href={data.value} target="_blank" rel="noopener noreferrer">
            <File>
              <Box flex alignItems="center" color="purple60">
                <IconOpen />
                <Text ml="8" color="purple100" type="semibold14">
                  {data.fileName}
                </Text>
              </Box>
            </File>
          </a>
        );
      case 'url':
        return (
          <a href={data.value} target="_blank" rel="noopener noreferrer">
            {data.value}
          </a>
        );
      case 'separator':
        return <hr />;
      case 'header':
        return <H3>{data.value}</H3>;
      case 'subtext':
        return <SubText>{data.value}</SubText>;
      case 'text':
        return <TextArea name={data.name} value={data.value} readOnly />;
      default:
        return <Input name={data.name} value={data.inputValue} readOnly />;
    }
  }
  integration.loadForApplication();

  function validateItem(value, data) {
    setValidating(true);
    data.validators.every(v => {
      switch (v) {
        case 'ssn':
          if (data.value && !isValidSsn(data.value)) {
            data.setError(t('integration.validators.ssn'));
            return false;
          }
          data.setError('');
          return true;
        case 'number':
          if (data.value && !isOnlyDigits(data.value)) {
            data.setError(t('integration.validators.number'));
            return false;
          }
          data.setError('');
          return true;
        case 'bank-number':
          if (data.value && !bankingNumber(data.value)) {
            data.setError(t('integration.validators.bankNumber'));
            return false;
          }
          data.setError('');
          return true;
        case 'ledger':
          if (data.value && !ledger(data.value)) {
            data.setError(t('integration.validators.ledger'));
            return false;
          }
          data.setError('');
          return true;
        case 'account-number':
          if (data.value && !accountNumber(data.value)) {
            data.setError(t('integration.validators.accountNumber'));
            return false;
          }
          data.setError('');
          return true;
        default:
          data.setError('');
          return true;
      }
    });
    setValidating(false);
  }

  return (
    <>
      <Text type="h2" color="green100">
        {integration.name}
      </Text>
      <Text color="purple60" mb="24">
        {integration.description}
      </Text>
      {saving && <Loading />}
      {integration.state === 'loading' && <Loading />}
      {integration.state === 'loaded' && (
        <>
          {integration.triggered &&
            !integration.canRetrigger &&
            integration.data.map((data, i) => (
              <Field key={i}>
                {data.label && <FormLabel>{data.label}</FormLabel>}
                {renderTriggeredInput(data)}
              </Field>
            ))}
          {(!integration.triggered || (integration.triggered && integration.canRetrigger)) &&
            integration.data.map((data, i) => (
              <IntegrationField data={data} validator={validateItem} key={i} />
            ))}

          {integration.triggered && integration.canRetrigger && (
            <IntegrationsButtonWrapper>
              <Button disabled={saving} onClick={() => submit(true, false)}>
                {t('application.onboarding.integration.resend')}
              </Button>
            </IntegrationsButtonWrapper>
          )}
          {integration.canDelete && integration.triggered && (
            <IntegrationsButtonWrapper>
              <Button red disabled={saving} onClick={remove}>
                {t('application.onboarding.integration.delete')}
              </Button>
            </IntegrationsButtonWrapper>
          )}
          {!integration.triggered && (
            <IntegrationsButtonWrapper>
              <Button blue disabled={saving} onClick={() => submit(false, true)}>
                {t('application.onboarding.integration.save')}
              </Button>
              {integration.canPreview && (
                <Button
                  purple
                  disabled={saving || validating || integration.hasErrors()}
                  onClick={() => preview()}
                >
                  {t('application.onboarding.integration.preview')}
                </Button>
              )}
              <Button
                disabled={saving || validating || integration.hasErrors()}
                onClick={() => submit(false, false)}
              >
                {t('application.onboarding.integration.submit')}
              </Button>
            </IntegrationsButtonWrapper>
          )}
        </>
      )}
    </>
  );
}

export default observer(Integrations);
