import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { Tooltip } from 'components/Tooltip';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { FormLabel, FormError } from 'components/Text';
import { JobFormQuestionTypes, JobFormQuestionEdit } from 'models/jobs';
import { ModalActions, ModalHeader } from 'components/Modal';
import { IconClose } from 'components/icons/IconClose';
import { IconButton } from 'components/IconButton';
import { Box } from 'components/Box';
import { IconPlus } from 'components/icons/IconPlus';
import { Select } from 'components/Select';
import { IconAttachment } from 'components/icons/IconAttachment';
import { IconOptionsMulti } from 'components/icons/IconOptionsMulti';
import { IconOptionsSingle } from 'components/icons/IconOptionsSingle';
import { IconTextParagraph } from 'components/icons/IconTextParagraph';
import { IconTextShort } from 'components/icons/IconTextShort';

const itemsDisplay = {
  one_line: { icon: <IconTextShort />, text: 'short' },
  multi_line: { icon: <IconTextParagraph />, text: 'para' },
  dropdown: { icon: <IconOptionsSingle />, text: 'single' },
  multidropdown: { icon: <IconOptionsMulti />, text: 'multi' },
  attachment: { icon: <IconAttachment />, text: 'attachment' }
};

function QuestionModal({ questionId, job, closeModal, t }) {
  const model = useState(() => JobFormQuestionEdit.create({ job, questionId }))[0];
  const question = model.current;
  const optionPlaceholders = model.optionPlaceholders;
  const titlePlaceholder = model.titlePlaceholder;

  const questionTypes = JobFormQuestionTypes.map(key => ({
    value: key,
    display: (
      <Box flex alignItems="center" key={key}>
        <Box flex mr="8">
          {itemsDisplay[key].icon}
        </Box>
        <div>{t(`job.create.form.questions.type.${itemsDisplay[key].text}`)}</div>
      </Box>
    )
  }));

  function submit(e) {
    if (e) {
      e.preventDefault();
    }
    if (model.submitForJob(job)) {
      closeModal();
    }
  }

  return (
    <>
      <ModalHeader>
        {questionId
          ? t('job.create.form.questions.title.edit')
          : t('job.create.form.questions.title')}
      </ModalHeader>
      <form onSubmit={submit}>
        <LanguageSwitcher model={model} t={t} />

        <Box mt="24">
          <FormLabel>{t('job.create.form.questions.type.label')}</FormLabel>
        </Box>

        <Select
          inModal
          onChange={model.setType}
          defaultValue={question.type}
          items={questionTypes}
        />

        <Box mt="16">
          <FormLabel>{t('job.create.form.questions.qlabel')}</FormLabel>
          <Input
            tabIndex={0}
            name="title"
            placeholder={titlePlaceholder}
            onChange={question.setTitle}
            value={question.title}
            error={question.error && t('job.create.form.questions.qerror')}
          />
        </Box>
        {(question.type === 'multidropdown' || question.type === 'dropdown') && (
          <Box mt="16">
            <FormLabel>{t('job.create.form.questions.qopts')}</FormLabel>
            <Tooltip id="option-action" />
            {question.options.map((o, i) => (
              <Option key={i} mt={i === 0 ? undefined : '8'}>
                <Input
                  placeholder={optionPlaceholders.length > 0 ? optionPlaceholders[i] : ''}
                  tabIndex={0}
                  fullSize
                  name={`option${i}`}
                  onChange={val => question.editOption(i, val)}
                  value={question.options[i].display}
                  error={question.options[i].error && t('job.create.form.options.empty')}
                />
                <Box flex ml="8">
                  <IconButton
                    onClick={() => {
                      if (question.options.length > 1) {
                        // Dont allow the user to clear all options, makes no sense.
                        model.removeOption(i);
                      }
                    }}
                    data-for="option-action"
                    data-tip={t('job.create.form.options.tip.remove')}
                    icon={IconClose}
                  />
                </Box>
                <Box flex ml="8">
                  <IconButton
                    onClick={() => model.addOption(i)}
                    data-for="option-action"
                    data-tip={t('job.create.form.options.tip.add')}
                    icon={IconPlus}
                  />
                </Box>
              </Option>
            ))}
          </Box>
        )}
        <ModalActions>
          <Button gray onClick={closeModal}>
            {t('job.create.form.questions.button.cancel')}
          </Button>
          <Button onClick={submit}>
            {t(`job.create.form.questions.button.submit${questionId ? 'edit' : ''}`)}
          </Button>
        </ModalActions>
        {model.langErrors.find(Boolean) && (
          <Box flex justifyContent="flex-end" mt="12">
            <FormError>The question form contains errors.</FormError>
          </Box>
        )}
      </form>
    </>
  );
}

const LanguageSwitcher = observer(({ model, t }) => {
  if (model.langs.length < 2) return null;

  const errors = model.langErrors;

  return (
    <div style={{ display: 'flex', marginBottom: 30 }}>
      <Tooltip id="question-lang-error" />
      {model.langs.map((lang, index) => (
        <LanguageTab
          data-for={errors[index] && 'question-lang-error'}
          data-tip="Language data contains errors"
          key={lang}
          active={model.selectedLanguage === lang}
          onClick={() => model.selectLanguage(lang)}
          error={errors[index]}
        >
          {t(`general.${lang}`)}
        </LanguageTab>
      ))}
    </div>
  );
});

const Option = styled(Box)(p => ({
  display: 'flex',
  '> div:nth-child(1)': {
    flexGrow: 1
  }
}));

const LanguageTab = styled.div`
  font-size: 13px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  ${p => p.active && 'font-weight: bold'};
  color: ${p => (p.active ? p.theme.colors.green100 : p.theme.textColor)};
  opacity: ${p => (p.active ? 1 : 0.8)};
  margin-right: 16px;
  :hover {
    cursor: ${p => (p.active ? 'initial' : 'pointer')};
  }

  ${p =>
    p.error &&
    !p.active &&
    css`
      font-weight: bold;
      color: ${p => p.theme.errorColor};
    `};
`;

export default observer(QuestionModal);
