import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Draggable } from 'react-beautiful-dnd';

import { Checkbox } from 'components/Checkbox';
import InlineEdit from 'shared/inputs/InlineEdit';

import { Item, CloseIcon } from './styles';

function TaskList({ items, provided }) {
  const [hasFocus, setHasFocus] = useState(-1);
  function setFocus(index) {
    setHasFocus(index);
  }

  return (
    <div ref={provided.innerRef}>
      <div>
        {items.map((item, index) => (
          <Draggable key={item.text + item.order} draggableId={item.order.toString()} index={index}>
            {(provided, snapshot) => (
              <>
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Item key={index} isDragging={snapshot.isDragging} hasFocus={hasFocus === index}>
                    <Checkbox />
                    <InlineEdit
                      text={item.text}
                      paramName="text"
                      change={obj => item.setText(obj.text)}
                      onFocus={() => setFocus(index)}
                      onBlur={() => {
                        setFocus(-1);
                        if (item.new && !item.text) {
                          item.remove();
                        }
                      }}
                      editing={item.new}
                    />
                    <CloseIcon onClick={item.remove} data-close />
                  </Item>
                </div>
                {provided.placeholder}
              </>
            )}
          </Draggable>
        ))}
      </div>
      {provided.placeholder}
    </div>
  );
}

export default observer(TaskList);
