import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconTextParagraph = props => (
  <Svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 13h16v2H4v-2zM4 9h16v2H4V9zM4 5h16v2H4V5zM4 17h10v2H4v-2z"
      fill="currentColor"
    />
  </Svg>
);
