import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';

import { IntegrationIcon } from 'components/IntegrationIcon';
import { Loading } from 'components/Loading';
import { CardList, CardListHeader, CardListItems } from 'components/CardList';
import { Text } from 'components/Text';
import { Integration, NoIntegrations } from './styles';

function IntegrationList({ triggers, loading, url, t }) {
  const { integrationID } = useParams();
  return (
    <CardList>
      <CardListHeader>
        <Text type="bold14" color="purple80">
          {t('job.integrations.list.title')}
        </Text>
      </CardListHeader>
      <CardListItems>
        {loading && <Loading style={{ width: 260, padding: 10 }} />}
        {!loading && triggers.length === 0 && (
          <NoIntegrations>{t('job.integrations.list.empty')}</NoIntegrations>
        )}
        {triggers.length > 0 &&
          triggers.map(t => {
            return (
              <Link
                to={`${url}/integrations/${t.triggerId}`}
                key={t.triggerId}
                style={{ display: 'block' }}
              >
                <Integration active={Number(integrationID) === t.triggerId}>
                  <IntegrationIcon integrationName={t.name} />
                  <Text ml="12" style={{ lineHeight: '2rem' }}>
                    {t.name}
                  </Text>
                </Integration>
              </Link>
            );
          })}
      </CardListItems>
    </CardList>
  );
}

export default observer(IntegrationList);
