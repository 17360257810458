import React from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Text, TextLabel } from 'components/Text';
import { Box } from 'components/Box';
import { IconTextShort } from 'components/icons/IconTextShort';
import { IconTextParagraph } from 'components/icons/IconTextParagraph';
import { IconOptionsSingle } from 'components/icons/IconOptionsSingle';
import { IconOptionsMulti } from 'components/icons/IconOptionsMulti';
import { IconAttachment } from 'components/icons/IconAttachment';
import { Checkbox } from 'components/Checkbox';
import { Button } from 'components/Button';
import { IconOpen } from 'components/icons/IconOpen';

const Answer = ({ q, application }) => {
  const { openFullscreenModal, t } = useStore();
  const { value, type } = q;

  if (!value) {
    return (
      <Text type="regular14" color="purple60" style={{ fontStyle: 'italic' }}>
        {t('application.details.noanswer')}
      </Text>
    );
  }

  switch (type) {
    case 'dropdown':
      return <Checkbox checked readOnly label={value} />;
    case 'multidropdown':
      return (
        <>
          {value.map((v, index) => (
            <Box mt={index === 0 ? 0 : 8} key={index}>
              <Checkbox checked readOnly label={v} />
            </Box>
          ))}
        </>
      );
    case 'attachment':
      const attachments = application.attachments.filter(a => a.questionId !== null);
      if (!application.attachMap[value]) {
        return (
          <Text type="regular14" color="purple60" style={{ fontStyle: 'italic' }}>
            {t('application.details.noanswer')}
          </Text>
        );
      }
      return (
        <Button
          data-for="qtip"
          data-tip={t('application.details.attachtip')}
          onClick={() => {
            openFullscreenModal('FILE_PREVIEW', { application, value, attachments });
          }}
          gray
          icon={IconOpen}
        >
          {application.attachMap[value].fileName}
        </Button>
      );
    case 'one_line':
    case 'multi_line':
    default:
      return (
        <Linkify properties={{ target: '_blank' }}>
          <Text type="regular14" color="purple60">
            {value}
          </Text>
        </Linkify>
      );
  }
};

export const QuestionAnswer = observer(({ q, application }) => {
  return (
    <>
      <Box flex alignItems="center" color="purple80" mt="16">
        {iconForQuestionType(q.type)}
        <TextLabel ml="8">{q.title}</TextLabel>
      </Box>
      <AnswerCard mt="8">
        <Answer q={q} application={application} />
      </AnswerCard>
    </>
  );
});

export const AnswerCard = styled(Box)(p => ({
  backgroundColor: p.theme.colors.white100,
  borderRadius: '0.125rem',
  padding: '0.75rem 1rem',
  wordBreak: 'break-all',
  a: {
    cursor: 'pointer',
    opacity: 1,
    color: p.theme.colors.green100,
    ':hover': {
      opacity: 0.8
    }
  }
}));

export const iconForQuestionType = type => {
  switch (type) {
    case 'one_line':
      return <IconTextShort />;
    case 'multi_line':
      return <IconTextParagraph />;
    case 'dropdown':
      return <IconOptionsSingle />;
    case 'multidropdown':
      return <IconOptionsMulti />;
    case 'attachment':
      return <IconAttachment />;
    default:
      return <IconTextShort />;
  }
};
