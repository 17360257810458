import { types as t } from 'mobx-state-tree';

export const ModalStore = t
  .model('ModalStore', {
    size: t.optional(t.enumeration('ModalSize', ['standard', 'fullscreen']), 'standard')
  })
  .volatile(self => ({
    modalType: '',
    modalProps: {}
  }))
  .actions(self => ({
    show(type, props) {
      self.size = 'standard';
      self.modalType = type;
      self.modalProps = props;
    },
    hide() {
      self.modalType = '';
      self.modalProps = {};
      self.size = 'standard';
    },
    showFullscreen(type, props) {
      self.size = 'fullscreen';
      self.modalType = type;
      self.modalProps = props;
    }
  }));
