import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconCheckmark = props => (
  <Svg {...props}>
    <path
      d="M17 8l-7.944 9L6 13.143"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
