import { types as t, getParent, flow } from 'mobx-state-tree';

import { debounce, updateMSTObject } from 'helpers';
import { withEnv } from 'models/withEnv';
import { JobSidebarSettings } from 'models/jobs';
import { LoadingState } from 'models/ui';

export const ApplicationSortOrder = t.enumeration('ApplicationOrder', [
  'name-desc',
  'name-asc',
  'date-desc',
  'date-asc',
  'notes-desc',
  'notes-asc',
  'avgvote-desc',
  'avgvote-asc',
  'votes-desc',
  'votes-asc'
]);

const StageSettings = t
  .model('StageSettings', {
    stage: t.number,
    order: t.optional(ApplicationSortOrder, 'date-desc')
  })
  .actions(self => ({
    setOrder(newOrder) {
      self.order = newOrder;
    }
  }));

export const JobSettings = t
  .model('JobSettings', {
    searchStr: '',
    sidebar: t.optional(JobSidebarSettings, {}),
    selectedMobileStage: 1,
    stages: t.array(StageSettings),
    state: t.optional(LoadingState, 'init'),

    searchOpen: false
  })
  .views(withEnv)
  .views(self => ({
    get parent() {
      return getParent(self);
    },
    get tabText() {
      switch (self.chosenTab) {
        case 0:
          return self.env.t('jobsettings.tab.applicants');
        case 1:
          return self.env.t('jobsettings.tab.team');
        case 2:
          return self.env.t('jobsettings.tab.statistics');
        case 3:
          return self.env.t('jobsettings.tab.integrations');
        default:
          throw new Error(`Incorrect tab selected for job:${self.job.id}`);
      }
    }
  }))
  .actions(self => ({
    onSearchUpdate: debounce(() => self.parent.onSettingsUpdate(), 300),
    load: flow(function*() {
      self.state = 'loading';
      const res = yield self.env.api.jobs.loadSettings(self.parent.id);
      if (!res.error && res.data.settings && res.data.settings.stages) {
        const settings = res.data.settings;
        const diff = self.parent.numStages - settings.stages.length;
        if (diff) {
          for (let i = settings.stages.length; i < self.parent.numStages; i++) {
            settings.stages.push({ stage: i + 1 });
          }
        }
        updateMSTObject(self, settings);
      } else if (self.stages.length === 0) {
        Array.from({ length: self.parent.numStages }).forEach((_, index) => {
          self.stages.push({ stage: index + 1 });
        });
      }
      self.state = 'loaded';
      self.sidebar.startWatching();
    }),
    setApplicationSort(stage, type) {
      self.stages[stage - 1].setOrder(type);
      self.saveToStorage();
    },
    resetFilters() {
      self.clearSearchValue();
      self.sidebar.resetFilters();
      self.saveToStorage();
    },
    setSearchValue(newValue) {
      if (newValue !== self.searchStr) {
        self.searchStr = newValue;
        self.onSearchUpdate();
      }
    },
    clearSearchValue() {
      if (self.searchStr !== '') {
        self.searchStr = '';
        self.onSearchUpdate();
      }
    },
    openSearch() {
      if (!self.searchOpen) {
        self.searchOpen = true;
      }
    },
    closeSearch() {
      if (self.searchOpen) {
        self.searchOpen = false;
      }
    },
    saveToStorage() {
      const { searchStr, searchOpen, sidebar, job, ...rest } = self.toJSON();
      const { selected, questions, isOpen, ...sidebarRest } = sidebar;
      const toSave = { ...rest, sidebar: sidebarRest };
      self.env.api.jobs.updateSettings(self.parent.id, toSave);
      self.parent.onSettingsUpdate();
    },
    selectMobileStage(stage) {
      if (self.selectedMobileStage !== stage) {
        self.selectedMobileStage = stage;
        self.saveToStorage();
      }
    }
  }));
