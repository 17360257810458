import React from 'react';
import DP from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

import { IconClose } from 'components/icons/IconClose';

export const DatePicker = observer(
  ({ value, onChange, onClear, dateFormat = 'EEEE, MMMM do yyyy', ...rest }) => {
    const [open, setOpen] = React.useState(false);
    return (
      <div style={{ position: 'relative' }}>
        <StyledDatePicker
          open={open}
          onCalendarOpen={() => setOpen(true)}
          onCalendarClose={() => setOpen(false)}
          selected={value}
          onChange={onChange}
          dateFormat={dateFormat}
          {...rest}
        />
        {Boolean(value) && Boolean(onClear) && (
          <Close onClick={onClear}>
            <IconClose />
          </Close>
        )}
      </div>
    );
  }
);

const Close = styled.div(p => ({
  position: 'absolute',
  right: '0.5rem',
  top: 'calc(50% - 0.75rem)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: p.theme.colors.purple50,
  ':hover, :focus': {
    cursor: 'pointer',
    backgroundColor: p.theme.colors.purpleTrans10,
    color: p.theme.colors.purple80
  }
}));

const StyledDatePicker = styled(DP)(p => ({
  height: '2.5rem',
  width: '100%',
  borderRadius: '0.25rem',
  backgroundColor: p.theme.colors.purpleTrans5,
  fontFamily: 'inherit',
  ...p.theme.text.regular16,
  border: '0.0625rem solid',
  borderColor: 'transparent',
  color: p.theme.colors.purple80,
  padding: '0.5rem 0.75rem',
  ':hover': {
    borderColor: p.theme.colors.green100
  },
  ...(p.open && {
    outline: 'none',
    borderRadius: '0.25rem',
    backgroundColor: p.theme.colors.white100,
    borderColor: p.theme.colors.green100
  })
}));
