import { lazy } from 'react';

export const Applicants = lazy(() => import('./pages/Applicants' /* webpackChunkName: 'applicants' */));
export const CreateJob = lazy(() => import('./pages/CreateJob' /* webpackChunkName: 'create-job' */));
export const Dashboard = lazy(() => import('./pages/Dashboard' /* webpackChunkName: 'dashboard' */));
export const Job = lazy(() => import('./pages/Job' /* webpackChunkName: 'job' */));
export const Onboarding = lazy(() => import('./pages/Onboarding' /* webpackChunkName: 'onboarding' */));
export const Settings = lazy(() => import('./pages/Settings' /* webpackChunkName: 'settings' */));
export const Sharing = lazy(() => import('./pages/Sharing' /* webpackChunkName: 'sharing' */));
export const SlackAuth = lazy(() => import('pages/Slack' /* webpackChunkName: 'slack-auth' */));
export const Team = lazy(() => import('./pages/Team' /* webpackChunkName: 'team' */));
export const Templates = lazy(() => import('./pages/Templates' /* webpackChunkName: 'templates' */));
export const Integrations = lazy(() => import('./pages/Integrations' /* webpackChunkName: 'integrations' */));
