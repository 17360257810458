import React from 'react';
import PropTypes from 'prop-types';

class LocaleProvider extends React.Component {
  static childContextTypes = {
    locale: PropTypes.object.isRequired
  };

  getChildContext() {
    const { locales, current } = this.props;
    return {
      locale: locales[current]
    };
  }

  render() {
    return this.props.children;
  }
}

export default LocaleProvider;
