import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { useStore } from 'models/Provider';
import { UserAvatar } from 'components/UserAvatar';
import { Box } from 'components/Box';
import { Text } from 'components/Text';

export const MentionText = ({ className, children, mention, editMode }) => {
  const [open, setOpen] = useState(false);
  const { userStore } = useStore();
  const user = userStore.items.get(mention.id);
  if (!user) return null;

  function mouseEnter() {
    setOpen(true);
  }

  function mouseLeave() {
    setOpen(false);
  }

  return (
    <span onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} style={{ position: 'relative' }}>
      <Link to={`/team/detail/${user.id}`} onClick={e => editMode && e.preventDefault()}>
        <span className={className}>{children}</span>
      </Link>
      <Container open={open}>
        <Box flex alignItems="center">
          <UserAvatar size="sm" name={user.name} src={user.picture} />
          <Box ml="8">
            <Text type="bold16" color="white100" children={user.name} />
            <Text type="regular14" color="purple40" mt="-4" children={user.roleDisplay} />
          </Box>
        </Box>
      </Container>
    </span>
  );
};

const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 25px;
  left: 0;
  display: ${p => (p.open ? 'block' : 'none')};
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  min-width: 230px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.0001) 100%
    ),
    #3c3c64;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);

  ::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 30%;
    margin-top: -10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #3d4066 transparent;
  }
`;
