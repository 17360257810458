import styled from 'styled-components/macro';

export const OnboardingContentContainer = styled.div`
  display: flex;
  max-width: 1440px;
  flex-wrap: wrap;
  align-items: flex-start;
  > div:nth-child(1) {
    width: 100%;
    max-width: 280px;

    @media (max-width: ${p => p.theme.bp.md}) {
      max-width: 380px;
    }
  }
  @media (max-width: ${p => p.theme.bp.md}) {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    > div:not(:nth-child(1)) {
      margin-top: 30px;
    }
  }
`;

export const OnboardingContent = styled.div`
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
`;

export const OnboardingSidebar = styled.div`
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
`;

export const OnboardingApplicationDetails = styled.div`
  margin-top: 30px;
`;

export const Tasks = styled.div`
  margin-top: 14px;
  width: 100%;
`;

export const IntegrationsButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Field = styled.div`
  margin-bottom: 10px;
`;

export const VerifySsn = styled.div`
  margin-top: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${p => p.theme.textColor};
  padding: 20px;
  background-color: #fff;

  > div:nth-child(1) {
    line-height: 24px;
    margin-bottom: 10px;
  }

  span {
    font-weight: bold;
  }
`;

export const VerifyIconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
`;

export const File = styled.div`
  background-color: rgba(61, 64, 102, 0.05);
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;
