import React from 'react';
import styled from 'styled-components/macro';

const Label = styled(({ color, active = false, bgColor, small, ...rest }) => <div {...rest} />)(
  p => ({
    ...p.theme.text.semibold10,
    textTransform: 'uppercase',
    borderWidth: '0.0625rem',
    borderStyle: 'solid',
    borderRadius: '0.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: p.small ? '1rem' : '1.5rem',
    padding: p.small ? '0.25rem' : '0.5rem',
    borderColor: '(107, 107, 107, 0.3)',
    color: '#d2d2d2',
    transition: 'all 150ms ease-out',

    ...(p.onClick && {
      cursor: 'pointer'
    }),

    ...(!p.active && {
      ':hover, :focus': {
        borderColor: p.theme.colors[p.color],
        color: p.theme.colors[p.color]
      }
    }),

    ...(p.active && {
      borderColor: p.theme.colors[p.color],
      color: p.theme.colors[p.color],
      backgroundColor: p.theme.colors[p.bgColor]
    })
  })
);

export const LabelPurple = ({ small = false, ...rest }) => (
  <Label small={small} bgColor="purple20" color="purple90" {...rest} />
);
export const LabelGreen = ({ small = false, ...rest }) => (
  <Label small={small} bgColor="green20" color="green100" {...rest} />
);
export const LabelRed = ({ small = false, ...rest }) => (
  <Label small={small} bgColor="red20" color="red100" {...rest} />
);
export const LabelOrange = ({ small = false, ...rest }) => (
  <Label small={small} bgColor="orange20" color="orange100" {...rest} />
);
export const LabelBlue = ({ small = false, ...rest }) => (
  <Label small={small} bgColor="blue20" color="blue100" {...rest} />
);
