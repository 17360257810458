import React, { useMemo, useReducer, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { Loading } from 'components/Loading';

import { useStore } from 'models/Provider';
import { NamePlate } from 'components/NamePlate';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { RecruiterItem } from '../AddRecruiterModal/styles';

const initialState = {
  saving: false,
  selected: [],
  original: []
};

const reducer = (state, { type, payload }) => {
  if (type === 'load') {
    return { ...state, original: payload, selected: payload };
  }

  if (type === 'remove') {
    return { ...state, selected: [...state.selected.filter(a => a.id !== payload)] };
  }

  if (type === 'saving') {
    return { ...state, saving: true };
  }

  if (type === 'stopSaving') {
    return { ...state, saving: false };
  }

  return state;
};

function EditApplicationAccessModal({ job, user, closeModal }) {
  const { api, notify, t } = useStore();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { saving, selected, original } = state;

  useEffect(() => {
    api.jobs.loadApplicationAccessForJob(job.id, user.id).then(res => {
      if (!res.error) {
        dispatch({ type: 'load', payload: res.data });
      }
    });
  }, [job.id, user.id, api]);

  const save = async () => {
    dispatch({ type: 'saving' });
    const applicationIds = original.filter(a => !selected.includes(a)).map(a => a.id);
    const res = await api.jobs.bulkRemoveApplicationAccess(job.id, [user.id], applicationIds);
    if (!res.error) {
      notify('success', t('job.recruiters.editaccess.success', user.name, applicationIds.length));
      job.editAppFollowerCount(user.id, selected.length);
      closeModal();
      return;
    }
    notify('error', t('job.recruiters.editaccess.error', user.name, applicationIds.length));
    dispatch({ type: 'stopSaving' });
  };

  const isSame = useMemo(() => {
    if (selected.length !== original.length) return false;
    return selected.every(a => original.includes(a));
  }, [selected, original]);

  return (
    <>
      <ModalHeader>{t('job.recruiters.editaccess.heading', user.name)}</ModalHeader>
      {saving && <Loading />}

      {selected.map(s => (
        <RecruiterItem selected key={s.id}>
          <NamePlate
            name={s.name}
            pic={s.picture}
            title={s.appliedDisplay}
            onRemove={() => dispatch({ type: 'remove', payload: s.id })}
          />
        </RecruiterItem>
      ))}

      {selected.length === 0 && (
        <ModalText mt="24">
          {t('job.recruiters.editaccess.noapplicantsselected', user.name)}
        </ModalText>
      )}

      <ModalActions>
        <Button gray onClick={closeModal} disabled={saving}>
          {t('job.recruiters.editaccess.button.cancel')}
        </Button>
        {!isSame && (
          <Button onClick={save} disabled={saving}>
            {t('job.recruiters.editaccess.button.submit')}
          </Button>
        )}
      </ModalActions>
    </>
  );
}

export default observer(EditApplicationAccessModal);
