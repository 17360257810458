import {
  SEARCH_NOTIFICATIONS_URL,
  NOTIFICATIONS_URL,
  NOTIFICATION_SETTINGS_URL,
  USER_URL
} from './urls';

export const makeNotificationsClient = r => ({
  loadSettings: () => r(NOTIFICATION_SETTINGS_URL),
  loadUser: () => r(USER_URL),
  toggleSetting: (id, val) => {
    let data = { notifyInApp: val };
    return r(`${NOTIFICATION_SETTINGS_URL}${id}/`, 'patch', data);
  },
  toggleNewApplicantEmail: val => r(USER_URL, 'patch', { receiveNewApplicationEmail: val }),
  markAsRead: id => r(`${NOTIFICATIONS_URL}${id}/`, 'patch', { isRead: true }),
  markAllAsRead: id => r(`${NOTIFICATIONS_URL}read/?latest=${id}`, 'post'),
  // prettier-ignore
  load: lastChecked => r(`${SEARCH_NOTIFICATIONS_URL}?limit=100&ordering=-created${lastChecked ? '&created__lt=' + lastChecked : ''}`)
});
