import { types as t, getParent, flow } from 'mobx-state-tree';

import { UserRef } from 'models/users';
import { withEnv } from 'models/withEnv';

export const ApplicationTask = t
  .model('ApplicationTask', {
    id: t.number,
    text: t.string,
    description: t.maybeNull(t.string),
    order: t.maybe(t.number),
    checked: false,
    checkedBy: t.maybeNull(t.late(() => UserRef)),
    onboardingList: t.maybe(t.number)
  })
  .views(withEnv)
  .views(self => ({
    get parent() {
      return getParent(self, 3);
    }
  }))
  .actions(self => ({
    toggle: flow(function*() {
      self.checked = !self.checked;
      const res = yield self.env.api.applications.checkTask(self.parent.id, self.id, self.checked);
      if (res.error) {
        self.checked = !self.checked;
        self.env.notify(
          'error',
          self.checked
            ? self.env.t('application.task.toggle.error.done', self.text)
            : self.env.t('application.task.toggle.error.notdone', self.text)
        );
      } else {
        self.env.notify(
          'success',
          self.checked
            ? self.env.t('application.task.toggle.success.done', self.text)
            : self.env.t('application.task.toggle.success.notdone', self.text)
        );
      }
    })
  }));
