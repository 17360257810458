import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';

import { useModel } from 'hooks/useModel';
import { EmailTemplateEdit as EmailTemplateEditModel } from 'models/templates';
import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { FixedBottomActions } from 'components/FixedBottomActions';
import { Input } from 'components/Input';
import { FormLabel } from 'components/Text';
import { Loading } from 'components/Loading';
import { Select } from 'components/Select';
import { TextEditor, useEditorState, usePlaceholderMap } from 'components/TextEditor';

export const EmailTemplateEdit = observer(({ template }) => {
  const { t, api, notify, templateStore, router } = useStore();
  const og = template
    ? getSnapshot(template)
    : { id: -1, templateType: 'applicant_new_application' };
  const model = useModel(
    EmailTemplateEditModel,
    { original: og },
    { api, notify, t, templateStore, router }
  );
  const { editorState, setEditorState, resetEditorState } = useEditorState(model.contentHtml);
  const placeholderMap = usePlaceholderMap(model.templateType);

  const updateEditorState = editorState => {
    setEditorState(editorState);
    model.setContentFromEditorState(editorState);
  };

  // Only these two for now
  const typeItems = ['applicant_new_application', 'rejection', 'message'].map(type => ({
    value: type,
    display: t(`company.templates.type.${type}`)
  }));

  return (
    <>
      {model.saving && <Loading />}
      <EmailTemplateEditContainer>
        <FormLabel>{t('company.templates.email.label.title')}</FormLabel>
        <Input
          placeholder={t('company.templates.email.title.placeholder')}
          value={model.title}
          onChange={model.setTitle}
          disabled={model.saving}
        />
        <Box mt="16">
          <FormLabel>{t('company.templates.email.label.message')}</FormLabel>
        </Box>
        <TextEditor
          placeholder={t('company.templates.email.message.placeholder')}
          disabled={model.saving}
          placeholderMap={placeholderMap}
          editorState={editorState}
          setEditorState={updateEditorState}
          plugins={['toolbar', 'linkify', 'placeholder']}
        >
          {({ EditorToolbar, EditorBox, EditorInput, EditorPlaceholderMenu }) => (
            <>
              <Box flex justifyContent="space-between" alignItems="center">
                <EditorToolbar />
                <Box mb="8">
                  <EditorPlaceholderMenu placeholderMap={placeholderMap} />
                </Box>
              </Box>
              <EditorBox>
                <EditorInput />
              </EditorBox>
            </>
          )}
        </TextEditor>
        <EmailTemplateTypeContainer>
          <FormLabel>{t('company.templates.email.label.type')}</FormLabel>
          <Select
            disable={model.saving}
            defaultValue={model.templateType}
            title={t('company.templates.email.type.title')}
            items={typeItems}
            onChange={model.setType}
          />
        </EmailTemplateTypeContainer>
        {model.templateType === 'applicant_new_application' && (
          <Box flex alignItems="center" mt="16">
            <Checkbox
              checked={model.isDefault}
              onClick={model.toggleDefault}
              disabled={model.saving}
            />
            <SetDefaultText>{t('company.templates.email.label.setdefault')}</SetDefaultText>
          </Box>
        )}
      </EmailTemplateEditContainer>
      <FixedBottomActions>
        {model.dirty && model.original.id !== -1 && (
          <Button
            disabled={model.saving}
            gray
            onClick={() => {
              model.reset();
              resetEditorState();
            }}
          >
            {t('company.templates.email.button.cancel')}
          </Button>
        )}
        {(!model.dirty || model.original.id === -1) && (
          <Link to="/templates/emails">
            <Button gray>{t('company.templates.email.button.back')}</Button>
          </Link>
        )}

        <Button onClick={model.save} disabled={!model.canSave}>
          {t('company.templates.email.button.save')}
        </Button>
      </FixedBottomActions>
    </>
  );
});

const EmailTemplateEditContainer = styled.div({
  width: '100%',
  maxWidth: 580,
  margin: 'auto'
});

const SetDefaultText = styled.span(p => ({
  color: p.theme.colors.textDark,
  opacity: 0.8,
  fontSize: 14,
  marginLeft: 5
}));

const EmailTemplateTypeContainer = styled.div({
  maxWidth: 300,
  marginTop: 20
});
