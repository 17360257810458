import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconInfo = props => (
  <Svg viewBox="0 0 14 14" height="1rem" width="1rem" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      fill="#9D9DB1"
    />
    <rect x="6" y="2" width="2" height="2" rx="1" fill="white" />
    <path d="M7 11V6H5" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </Svg>
);
