import uuid4 from 'uuid/v4';
import { types as t, destroy, isAlive, getParent } from 'mobx-state-tree';

const Toast = t
  .model('Toast', {
    id: t.identifier,
    type: t.enumeration('ToastType', ['success', 'error']),
    text: t.union(t.string, t.array(t.string))
  })
  .actions(self => ({
    delete() {
      if (isAlive(self)) {
        getParent(self, 2).remove(self);
      }
    }
  }));

export const ToastStore = t
  .model('ToastStore', {
    toasts: t.array(Toast, [])
  })
  .actions(self => ({
    add(type, text) {
      self.toasts.push({
        type,
        text: Array.isArray(text) ? text.join('\n') : text,
        id: uuid4()
      });
    },
    remove(toast) {
      destroy(toast);
    }
  }));
