import React from 'react';
import { getElementHeight, getVisibleHeight } from 'shared/utils/browser';

export const useOnDDClick = elementId => {
  return React.useCallback(() => {
    onDDClick(elementId);
  }, [elementId]);
};

function onDDClick(id) {
  const mdl = document.getElementById('modal');
  const mdlHt = getElementHeight(document.getElementById('modal'));
  const ddHt = getElementHeight(document.getElementById(id));
  if (ddHt === 0) {
    mdl.parentNode.style.overflow = 'auto';
    return;
  }
  const viewHt = getVisibleHeight();
  if (mdlHt + ddHt >= viewHt - 50) {
    mdl.parentNode.style.overflow = 'auto';
  } else {
    mdl.parentNode.style.overflow = 'visible';
  }
}
