import React, { Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import DownloadIcon from 'components/icons/IconDownload';
import { BreadCrumbPath } from 'components/BreadCrumb';

import { useStore } from 'models/Provider';

const PDF = React.lazy(() => import('./PDF' /* webpackChunkName: 'cv' */));

export const HeaderContainer = styled.div`
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 0 0 20px;
  @media (max-width: 480px) {
    padding: 0 10px;
  }
  height: 76px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4f4f74;
  z-index: 1000;
  background-color: #3c3c64;

  > div:nth-child(1) {
    flex-grow: 1;
    height: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  > div:nth-child(1) {
    margin-right: 24px;
    @media (max-width: 480px) {
      margin-right: 10px;
    }
  }
`;

const PDFContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 76px 0 0 0;
`;

const Download = styled.div`
  opacity: 0.8;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  margin-right: 75px;
  :hover {
    opacity: 1;
  }
`;

const Close = styled.div`
  opacity: 0.8;
  font-size: 20px;
  padding: 32px;
  border-left: 1px solid #4f4f74;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

function IntegrationFilePreviewModal({ data, closeModal }) {
  const { t } = useStore();

  data.loadBlob();

  return (
    <>
      {data.loadedBlob && (
        <>
          <HeaderContainer>
            <Container>
              <BreadCrumbPath active>{data.fileName}</BreadCrumbPath>
            </Container>
            <a href={data.value} download target="_blank" rel="noopener noreferrer">
              <Download>
                <DownloadIcon />
                <div style={{ marginLeft: 10 }}>{t('application.attachment.download')}</div>
              </Download>
            </a>
            <Close onClick={closeModal}>x</Close>
          </HeaderContainer>
          {data.blob !== null && (
            <PDFContainer>
              <Suspense fallback={<div />}>
                <PDF file={data.blob} name={data.value} />
              </Suspense>
            </PDFContainer>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(observer(IntegrationFilePreviewModal));
