import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconChat20 = props => (
  <Svg {...props} viewBox="0 0 20 20">
    <path
      d="M10.528 12.5a3 3 0 011.341.317L15 14.382V6a1 1 0 00-1-1H6a1 1 0 00-1 1v5.5a1 1 0 001 1h4.528z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </Svg>
);
