import React from 'react';
import { useStore } from 'models/Provider';

/**
 * Ensure a job, if it exists in the jobstore, is loaded.
 * Useful f.ex. for archived jobs that aren't loaded on mount.
 */

export const useEnsureJobLoaded = jobID => {
  const { jobStore } = useStore();
  const job = jobStore.items.get(jobID);

  React.useEffect(
    () => {
      !job && jobStore.loadJob(jobID);
    },
    [job, jobStore, jobID]
  );
};
