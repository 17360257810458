import { types as t } from 'mobx-state-tree';
import sharedEN from 'shared/locale/en.json';
import sharedIS from 'shared/locale/is.json';
import localeEN from 'locale/en.js';
import localeIS from 'locale/is.js';
import { storage } from 'shared/utils/browser';

const locale = {
  en: Object.assign({}, sharedEN, localeEN),
  is: Object.assign({}, sharedIS, localeIS)
};

export const I18nStore = t
  .model('I18nStore', {
    lang: t.optional(t.enumeration('ChosenLanguage', ['en', 'is']), 'en')
  })
  .views(self => ({
    get t() {
      return (str, ...args) => {
        if (str === '' || str === undefined || str === null) {
          return '';
        }

        let s = locale[self.lang][str];
        if (!s) {
          console.error(`Missing translation for string: ${str}, in language: ${self.lang}`);
          return str;
        }
        if (args.length === 0) return s;

        let i = 0;
        if (s) {
          for (let arg of args) {
            let index = '{' + i + '}';
            s = s.replace(index, arg);
            i++;
          }
        }
        return s;
      };
    }
  }))
  .actions(self => ({
    afterCreate() {
      const lang = storage.get('50_lang');
      if (lang) {
        self.lang = lang;
      }
    },
    setLanguage: lang => {
      self.lang = lang;
      storage.set('50_lang', lang);
    }
  }));
