import styled from 'styled-components/macro';

// export default styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 10px;
//   :hover {
//     cursor: pointer;
//   }
// `;

export const ToolbarButton = styled.div`
  color: ${p => (p.active ? p.theme.primaryColor : '#6E6E6E')};
  transition: color 150ms ease-in;

  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  :hover {
    cursor: pointer;
    ${p => !p.active && `color: ${p.theme.primaryColor}`};
  }
`;
