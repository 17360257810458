import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Button } from 'components/Button';
import ShowMore from 'components/ShowMore';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { Loading } from 'components/Loading';
import { Box } from 'components/Box';
import { Select } from 'components/Select';

function MoveToStageModal({ job, applicants, closeModal }) {
  const [selected, setSelected] = useState(0);
  const [saving, setSaving] = useState(false);
  const { t } = useStore();

  async function moveToStage() {
    setSaving(true);
    const success = await job.bulkMoveToStage(applicants, selected);
    setSaving(false);
    if (success) {
      closeModal();
    }
  }

  function selectStage(stage) {
    setSelected(stage);
  }

  return (
    <>
      <ModalHeader>{t('modals.movetostage.heading')}</ModalHeader>

      {saving && <Loading />}
      <ModalText mb="12">{t('modals.movetostage.applicantsmsg')}</ModalText>
      <ShowMore
        max={applicants.length}
        count={applicants.length > 10 ? 5 : applicants.length}
        inc={1000}
        render={({ count }) => (
          <List>
            {applicants.slice(0, count).map((a, i) => (
              <div key={i}>{a.name}</div>
            ))}
          </List>
        )}
      />
      <ModalText mt="16">{t('modals.movetostage.stagemsg')}</ModalText>
      <Box mt="12">
        <Select
          inModal
          items={job.dropdownStages}
          fullSize
          title={t('modals.movetostage.stage.select')}
          onChange={selectStage}
        />
      </Box>

      <ModalActions>
        <Button gray onClick={closeModal} disabled={saving}>
          {t('modals.movetostage.button.cancel')}
        </Button>
        <Button
          onClick={saving || selected.length === 0 ? null : moveToStage}
          disabled={saving || selected.length === 0}
        >
          {t('modals.movetostage.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
}

export default observer(MoveToStageModal);

const List = styled.div`
  color: ${p => p.theme.textColor};
  font-weight: bold;
  margin-bottom: 20px;
`;
