import styled from 'styled-components/macro';

const Spacer = styled.div`
  margin-top: ${props => props.top ?  props.top + 'px' : '0'};
  margin-bottom: ${props => props.bottom ? props.bottom + 'px' : '0'};
  margin-left: ${props => props.left ? props.left + 'px' : '0'};
  margin-right: ${props => props.right ? props.right + 'px' : '0'};
`;

export default Spacer;
