import { types as t, getParent, onPatch } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

const SidebarQuestionResponse = t
  .model('SidebarQuestionResponse', {
    question: t.string,
    value: t.string
  })
  .views(self => ({
    get form() {
      return getParent(self, 2).job.form;
    }
  }));

export const JobSidebarSettings = t
  .model('JobSidebarSettings', {
    active: true,
    assignedTo: 'anyone',
    averageRating: 'SYNDX',
    isHired: true,
    isNotified: true,
    isRejected: true,
    isRetracted: true,
    noLabel: true,
    questionResponses: t.array(SidebarQuestionResponse),
    referredBy: true,
    stage: 'all',
    language: t.optional(t.enumeration('LanguageSelection', ['is', 'en', 'all']), 'all'),

    // Local extras
    selected: t.maybe(t.string),
    isOpen: false
  })
  .preProcessSnapshot(snapshot => {
    const rx = new RegExp(/\w+--/, 'g');
    if (snapshot.questionResponses) {
      return {
        ...snapshot,
        questionResponses: snapshot.questionResponses.map(qr => ({
          value: qr.value,
          question: qr.question.replace(rx, '')
        }))
      };
    }
    return snapshot;
  })
  .views(withEnv)
  .views(self => ({
    get hasSomeLabel() {
      return self.noLabel || self.referredBy || self.isNotified;
    },
    get hasSomeRating() {
      return self.averageRating !== '';
    },
    get selectedResponses() {
      return self.questionResponses.map(qr => ({
        ...qr,
        question: self.questions.find(q => q.id === qr.question)
      }));
    },
    get stageOptions() {
      let opts = [{ display: self.env.t('job.sidebar.stagefilter.all'), value: 'all' }];
      Array.from({ length: self.job.numStages }).forEach((_, i) => {
        opts.push({
          display: self.job[`stage${i + 1}Name`],
          value: (i + 1).toString()
        });
      });
      return opts;
    },
    get assigneeOptions() {
      let opts = [
        { display: self.env.t('job.sidebar.assignedtofilter.anyone'), value: 'anyone' },
        { display: self.env.t('job.sidebar.assignedtofilter.none'), value: 'none' },
        { display: self.env.t('job.sidebar.assignedtofilter.me'), value: 'me' }
      ];

      self.job.combinedFollowers.forEach(follower => {
        if (!follower.isMe) {
          opts.push({
            display: follower.name,
            value: follower.id.toString()
          });
        }
      });
      return opts;
    },
    get languageSelectOptions() {
      let langs = [{ display: self.env.t('job.sidebar.languagefilter.all'), value: 'all' }];
      self.job.languages.forEach(obj => {
        langs.push({
          display: self.env.t(`general.${obj.language}`),
          value: obj.language
        });
      });
      return langs;
    },
    get showLanguages() {
      return self.job.languages.length > 1;
    },
    get job() {
      return getParent(self, 2);
    },
    get parent() {
      return getParent(self);
    },
    get showStrongYes() {
      return self.averageRating.indexOf('S') > -1;
    },
    get showYes() {
      return self.averageRating.indexOf('Y') > -1;
    },
    get showNo() {
      return self.averageRating.indexOf('N') > -1;
    },
    get showDefiniteNo() {
      return self.averageRating.indexOf('D') > -1;
    },
    get showNoAverage() {
      return self.averageRating.indexOf('X') > -1;
    },
    get selectableOptions() {
      let opts = [];
      if (self.selected) {
        // Don't allow setting the same option for the same question twice.
        opts = self.questions.find(q => q.id === self.selected).options;
        opts = opts.filter(o => {
          let found = false;
          self.questionResponses.forEach(qr => {
            if (qr.question === self.selected && o.value === qr.value) {
              found = true;
            }
          });
          return !found;
        });
      }
      return opts;
    },
    get questions() {
      if (!self.job || !self.job.languages || self.job.languages.length === 0) return [];
      let qs = [];
      const form = self.job.languages[0].form;
      if (form) {
        form.sections.forEach(section => {
          section.questions.forEach(q => {
            if (q.type === 'multidropdown' || q.type === 'dropdown') {
              qs.push(q);
            }
          });
        });
      }
      return qs;
    },
    get available() {
      return self.questions
        .filter(q => {
          if (q.type === 'multidropdown') {
            const respForQ = self.questionResponses.filter(qr => qr.question.id === q.id);
            if (respForQ.length === q.options.length) return false;
            return true;
          }
          return !self.questionResponses.find(qr => {
            return q.id === qr.question.id;
          });
        })
        .map(q => ({
          ...q,
          display: q.title,
          value: q.id
        }));
    }
  }))
  .actions(self => ({
    startWatching() {
      onPatch(self, patch => {
        if (
          patch.path.includes('isOpen') ||
          patch.path.includes('selected') ||
          patch.path.includes('questions')
        ) {
          return;
        }
        self.parent.saveToStorage();
      });
    },
    selectAnswer(value) {
      self.questionResponses.push({ question: self.selected, value });
      self.selected = undefined;
    },
    setLanguage(val) {
      self.language = val;
    },
    selectQuestion(question) {
      self.selected = question;
    },
    unselectQuestion(question, value) {
      self.questionResponses = self.questionResponses.filter(q => {
        return !(q.question === question && q.value === value);
      });
    },
    open() {
      self.isOpen = true;
    },
    close() {
      self.isOpen = false;
    },
    toggleActive() {
      self.active = !self.active;
    },
    toggleHired() {
      self.isHired = !self.isHired;
    },
    toggleRejected() {
      self.isRejected = !self.isRejected;
    },
    toggleRetracted() {
      self.isRetracted = !self.isRetracted;
    },
    toggleReferredBy() {
      self.referredBy = !self.referredBy;
    },
    toggleNoLabel() {
      self.noLabel = !self.noLabel;
    },
    toggleNotified() {
      self.isNotified = !self.isNotified;
    },
    resetFilters() {
      self.active = true;
      self.isHired = true;
      self.isRejected = true;
      self.isRetracted = true;
      self.referredBy = true;
      self.noLabel = true;
      self.isNotified = true;
      self.questionResponses = [];
      self.selected = undefined;
      self.stage = 'all';
      self.language = 'all';
      self.assignedTo = 'anyone';
      self.averageRating = 'SYNDX';
    },
    setStage(stage) {
      self.stage = stage;
    },
    setAssignedTo(assignedTo) {
      self.assignedTo = assignedTo.toString();
    },
    toggleShowAverageS() {
      self.toggleAverageRating('S');
    },
    toggleShowAverageY() {
      self.toggleAverageRating('Y');
    },
    toggleShowAverageN() {
      self.toggleAverageRating('N');
    },
    toggleShowAverageD() {
      self.toggleAverageRating('D');
    },
    toggleShowNoAverage() {
      self.toggleAverageRating('X');
    },
    toggleAverageRating(avg) {
      if (self.averageRating.indexOf(avg) > -1) {
        self.averageRating = self.averageRating.replace(avg, '');
      } else {
        self.averageRating += avg;
      }
    }
  }));
