import { COMPANY_URL, INTEGRATIONS_URL, TEMPLATES_URL } from './urls';

export const makeCompaniesClient = r => ({
  load: () => r(COMPANY_URL),
  loadTemplates: () => r(TEMPLATES_URL),
  updateTemplate: (id, data) => r(`${TEMPLATES_URL}${id}/`, 'patch', data),
  createTemplate: data => r(TEMPLATES_URL, 'post', data),
  deleteTemplate: id => r(`${TEMPLATES_URL}${id}/`, 'delete'),
  update: data => r(COMPANY_URL, 'patch', data),
  loadIntegrations: () => r(INTEGRATIONS_URL),
  loadIntegrationInfo: (integrationName, queryString) =>
    r(`${INTEGRATIONS_URL}${integrationName}/${queryString || ''}`),
  loadIntegrationDetail: (integrationName, tabName, id) =>
    r(`${INTEGRATIONS_URL}${integrationName}/${tabName}/${id}/`),
  patchIntegration: url => r(`${process.env.REACT_APP_API_URL}${url}/`, 'patch')
});
