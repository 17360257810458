import React, { createContext, useContext } from 'react';
import { observer } from 'mobx-react-lite';

const context = createContext();

export const Provider = observer(({ children, store }) => {
  let ctx = {
    t: store.i18n.t,
    lang: store.i18n.lang,
    setLanguage: store.i18n.setLanguage,
    theme: store.theme,
    router: store.router,
    openModal: store.ui.openModal,
    openFullscreenModal: store.ui.openFullscreenModal,
    notify: store.ui.notify,
    authStore: store.auth,
    toastStore: store.ui.toastStore,
    modalStore: store.ui.modalStore,
    companies: store.companies,
    switchCompany: store.switchCompany
  };

  if (store.current) {
    ctx.current = store.current;
    ctx.api = store.current.api;
    ctx.userStore = store.current.users;
    ctx.me = store.current.users.me;
    ctx.jobStore = store.current.jobs;
    ctx.applicationStore = store.current.applications;
    ctx.notificationStore = store.current.notifications;
    ctx.applicantSearchStore = store.current.applicantSearch;
    ctx.onboardingStore = store.current.onboarding;
    ctx.companyIntegrationStore = store.current.integrations;
    ctx.templateStore = store.current.templates;
    ctx.uiSettings = store.current.uiSettings;
    ctx.hasPerm = store.current.permissions.has;
  }
  return <context.Provider value={ctx}>{children}</context.Provider>;
});

export const useStore = () => useContext(context);
