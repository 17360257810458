import { reaction } from 'mobx';
import { flow, types as t } from 'mobx-state-tree';
import moment from 'moment';

import { withEnv } from 'models/withEnv';
import { ApplicationSearch } from 'models/applicantSearch';

const IntegrationStatusStore = t.model('IntegrationStatusStore', {
  name: t.identifier,
  status: 'all'
});

export const OnboardingStore = t
  .model('OnboardingStore', {
    applications: t.map(ApplicationSearch),
    loading: true,
    numResults: 0,
    searchStr: '',
    dateFilter: 'all_time',
    assignedTo: 'anyone',
    jobId: 'all',
    integrationStatuses: t.map(IntegrationStatusStore),
    sortBy: 'date',
    sortType: 'desc'
  })
  .views(withEnv)
  .views(self => ({
    get list() {
      return Array.from(self.applications.values());
    },
    get queryString() {
      let queryString = `?limit=1000`;
      if (self.searchStr !== '') {
        queryString += `&search_multi_match=${self.searchStr}`;
      }
      if (self.dateStr !== '') {
        queryString += `&date__gt=now-${self.dateStr}`;
      }
      if (self.jobId !== 'all') {
        queryString += `&job=${self.jobId}`;
      }
      if (self.assignedTo !== 'anyone') {
        if (self.assignedTo === 'none') {
          queryString += `&assigned_to__isnull=true`;
        } else {
          queryString += `&assigned_to=${self.assignedTo}`;
        }
      }
      self.integrationStatuses.forEach(i => {
        if (i.status !== 'all') {
          queryString += `&triggers=${i.name}__${i.status}`;
        }
      });
      if (self.sortBy) {
        queryString += `&ordering=${self.sortType === 'desc' ? '-' : ''}${self.sortBy}`;
      }
      return queryString;
    },
    get noFilters() {
      return self.queryString === '?limit=1000&ordering=-date';
    },
    get dateStr() {
      if (self.dateFilter === 'today') {
        const now = moment();
        const midnight = now.clone().startOf('day');
        const minutes = now.diff(midnight, 'minutes');
        return minutes + 'm';
      } else if (self.dateFilter === 'past_seven') {
        return '7d';
      } else if (self.dateFilter === 'past_thirty') {
        return '30d';
      }
      return '';
    }
  }))
  .actions(self => ({
    afterCreate() {
      reaction(
        () => self.searchStr.length,
        () => {
          self.search();
        },
        { delay: 300 }
      );
    },
    setOrdering(value) {
      if (self.sortBy === value) {
        if (self.sortType === 'asc') {
          self.sortType = 'desc';
        } else {
          self.sortType = 'asc';
        }
      } else {
        self.sortBy = value;
      }
      self.search();
    },
    setSearchStr(searchStr) {
      self.searchStr = searchStr;
    },
    search: flow(function*() {
      self.loading = true;

      const res = yield self.env.api.applications.onboarding(self.queryString);
      self.numResults = res.data.count;
      self.applications.clear();
      res.data.results.forEach(a => {
        self.applications.put(a);
      });
      self.loading = false;

      const applicationsIds = Array.from(self.applications.keys());
      if (applicationsIds.length) {
        self.env.api.applications.loadPictures(applicationsIds).then(res => {
          res.data.forEach((a, index) => {
            setTimeout(() => {
              self.env.applicationPictureStore.setUrl(a.id, a.picture);
            }, index * 2);
          });
        });
      }
    }),
    setDateFilter(value) {
      self.dateFilter = value;
      self.search();
    },
    setJob(value) {
      self.jobId = value.toString();
      self.search();
    },
    setAssignedTo(value) {
      self.assignedTo = value.toString();
      self.search();
    },
    setIntegrationStatus(name, value) {
      self.integrationStatuses.put({ name: name, status: value });
      self.search();
    }
  }));
