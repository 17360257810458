import React, { useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';

import { Alert } from 'components/Alert';
import { AutoComplete } from 'components/AutoComplete';
import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { DatePicker } from 'components/DatePicker';
import { FormLabel, Text } from 'components/Text';
import { IconOpen } from 'components/icons/IconOpen';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import { Switch } from 'components/Switch';
import { TextArea } from 'components/TextArea';

import { Field, File } from './styles';

function getText(choices, value) {
  for (let i = 0; i < choices.length; i++) {
    if (Number(choices[i][0]) === Number(value)) {
      return choices[i][1];
    }
  }
  return value;
}

export const IntegrationField = observer(({ data, validator }) => {
  const { openFullscreenModal } = useStore();
  useEffect(() => {
    validator && validator(data.value, data);
  }, [data, validator]);
  return (
    <Field key={data.name}>
      {data.type !== 'hidden' && data.type !== 'file' && data.label && (
        <FormLabel>
          {data.required && '* '}
          {data.label}
        </FormLabel>
      )}
      {data.type === 'hidden' && (
        <Input
          onChange={data.setValue}
          value={data.value}
          error={data.error}
          data-id={data.name}
          hidden
        />
      )}
      {data.type === 'header' && <h3>{data.value}</h3>}
      {data.type === 'separator' && <hr />}
      {data.type === 'alert' && <Alert type={data.alertType}>{data.value}</Alert>}
      {(data.type === 'string' || data.type === 'derived') && (
        <Input
          onChange={data.setValue}
          value={data.value || ''}
          error={data.error}
          data-id={data.name}
          onBlur={e => {
            validator && validator(e.target.value, data);
            data.dynamic && data.refetch();
          }}
        />
      )}
      {data.type === 'text' && (
        <TextArea
          onChange={data.setValue}
          value={data.value || ''}
          error={data.error}
          data-id={data.name}
          onBlur={e => {
            validator && validator(e.target.value, data);
            data.dynamic && data.refetch();
          }}
        />
      )}
      {data.type === 'email' && (
        <Input
          onChange={data.setValue}
          type="email"
          value={data.value}
          error={data.error}
          data-id={data.name}
          onBlur={e => {
            validator && validator(e.target.value, data);
            data.dynamic && data.refetch();
          }}
        />
      )}
      {data.type === 'boolean' && (
        <Switch
          value={data.value}
          id={data.name}
          name={data.name}
          data-id={data.name}
          onToggle={val => {
            data.setValue(val);
            data.dynamic && data.refetch();
          }}
        />
      )}
      {data.type === 'choices' && (
        <Select
          items={data.choices.map(choice => {
            return { value: choice[0], display: choice[1] };
          })}
          onChange={value => {
            data.setValue(value);
            data.dynamic && data.refetch();
          }}
          key={data.value}
          defaultValue={data.value || null}
          error={data.error}
          dataId={data.name}
        />
      )}
      {data.type === 'multiple' && (
        <Select
          multi
          items={data.choices.map(choice => {
            return { value: choice[0], display: choice[1] };
          })}
          onChange={value => {
            data.setValue(value);
            data.dynamic && data.refetch();
          }}
          title={getText(data.choices, data.value)}
          error={data.error}
          data-id={data.name}
        />
      )}
      {data.type === 'autocomplete' && (
        <AutoComplete
          items={data.choices.map(choice => {
            return { id: choice[0], label: choice[1] };
          })}
          onSelect={(_, value) => {
            data.setValue(value);
            data.dynamic && data.refetch();
          }}
          data={data}
          initialValue={getText(data.choices, data.value)}
          error={data.error}
          data-id={data.name}
        />
      )}
      {data.type === 'date' && (
        <DatePicker
          value={data.value ? moment(data.value).toDate() : null}
          onChange={data.setValue}
          error={data.error}
          data-id={data.name}
        />
      )}
      {data.type === 'file' && (
        <File onClick={() => openFullscreenModal('INTEGRATION_FILE_PREVIEW', { data })}>
          <Box flex alignItems="center" color="purple60">
            <IconOpen />
            <Text ml="8" color="purple100" type="semibold14">
              {data.label}
            </Text>
          </Box>
        </File>
      )}
    </Field>
  );
});
