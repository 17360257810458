import moment from 'moment';
import { SEARCH_JOBS_URL, JOBS_URL } from './urls';

export const makeJobsClient = r => ({
  create: data => r(JOBS_URL, 'post', data),
  edit: (id, data) => r(`${JOBS_URL}${id}/`, 'patch', data),
  delete: id => r(`${JOBS_URL}${id}/`, 'delete'),
  loadFromDb: id => r(`${JOBS_URL}${id}/`),
  loadJob: id => r(`${SEARCH_JOBS_URL}?id=${id}`),
  loadPictures: id => r(`${JOBS_URL}${id}/?pictures=1`),
  loadMinimal: () => r(`${SEARCH_JOBS_URL}?limit=10000&minimal=true`),
  loadMinimalWithoutArchived: () =>
    r(`${SEARCH_JOBS_URL}?limit=10000&minimal=true&is_archived=false`),
  load: (status, size, searchStr) => {
    const search =
      searchStr !== undefined && searchStr !== '' ? `&search_multi_match=${searchStr}` : '';
    switch (status) {
      case 'published':
        // prettier-ignore
        return r(`${SEARCH_JOBS_URL}?limit=${Math.max(size, 15)}&is_published=true&is_archived=false&deadline__gtnowornull=true&list=true${search}`);
      case 'drafts':
        // prettier-ignore
        return r(`${SEARCH_JOBS_URL}?limit=${Math.max(size, 15)}&is_published=false&is_archived=false&list=true${search}`);
      case 'expired':
        // prettier-ignore
        return r(`${SEARCH_JOBS_URL}?limit=${Math.max(size, 15)}&is_published=true&is_archived=false&deadline__lt=now&list=true${search}`);
      case 'archived':
        // prettier-ignore
        return r(`${SEARCH_JOBS_URL}?limit=${Math.max(size, 15)}&is_archived=true&list=true${search}`);
      default:
        return;
    }
  },
  search: (title, includeArchived) => {
    let words = title.toLowerCase().split(' ');
    let searchStr = words.join('&title__contains=');
    if (includeArchived) {
      return r(
        `${SEARCH_JOBS_URL}?limit=10000&title__contains=${searchStr}&ordering=title&title_only=true`
      );
    }
    return r(
      `${SEARCH_JOBS_URL}?limit=10000&is_archived=false&title__contains=${searchStr}&ordering=title&title_only=true`
    );
  },
  loadSharing: () => r(`${JOBS_URL}sharing`),
  moveToDrafts: id => r(`${JOBS_URL}${id}/`, 'patch', { isPublished: false, isArchived: false }),
  moveToArchive: id => r(`${JOBS_URL}${id}/`, 'patch', { isArchived: true, isPublished: false }),
  loadStatistics: (id, from) => {
    let query = '';
    if (from) {
      query = `?from=${from}`;
    }
    return r(`${JOBS_URL}${id}/statistics/${query}`);
  },

  unPublish: id => r(`${JOBS_URL}${id}/`, 'patch', { isPublished: false }),
  publish: id => {
    const data = { isPublished: true, isArchived: false, datePublished: moment() };
    return r(`${JOBS_URL}${id}/`, 'patch', data);
  },
  duplicate: id => r(`${JOBS_URL}${id}/duplicate/`, 'post'),
  loadIntegrations: (jobId, iType) => r(`${JOBS_URL}${jobId}/integrations/${iType}/`),
  loadIntegration: (jobId, iType, iId) => r(`${JOBS_URL}${jobId}/integrations/${iType}/${iId}/`),
  loadSettings: jobId => r(`${JOBS_URL}${jobId}/settings/`),
  updateSettings: (jobId, data) => r(`${JOBS_URL}${jobId}/settings/`, 'patch', data),
  bulkRejectApplicants: (jobId, applications, markNotified, emailData) => {
    let data = { markNotified, applications };
    if (emailData) {
      data.emailData = emailData;
    }
    return r(`${JOBS_URL}${jobId}/bulk/reject/`, 'patch', data);
  },
  bulkMessageApplicants: (jobId, applications, emailData) => {
    let data = { emailData, applications };
    return r(`${JOBS_URL}${jobId}/bulk/message/`, 'patch', data);
  },

  bulkGrantAccessToApplicants: (jobId, applications, users) => {
    const data = { applications, users };
    return r(`${JOBS_URL}${jobId}/bulk/grant/`, 'patch', data);
  },
  submitIntegration: (jobId, iType, iId, data) =>
    r(`${JOBS_URL}${jobId}/integrations/${iType}/${iId}/`, 'post', { data }),
  bulkPurgeAccess: (user, jobId) => r(`${JOBS_URL}${jobId}/bulk/purge`, 'patch', { user }),
  bulkMoveToStage: (jobId, applications, stage) =>
    r(`${JOBS_URL}${jobId}/bulk/move_stage/`, 'patch', { applications, stage }),
  deleteLanguage: (jobId, langKey) => r(`${JOBS_URL}${jobId}/language/${langKey}/`, 'delete'),
  loadForUserAccess: () => r(`${SEARCH_JOBS_URL}?limit=50&is_archived=false&ordering=-date`),
  loadApplicationAccessForJob: (jobId, userId) => r(`${JOBS_URL}${jobId}/access/${userId}/`),
  bulkRemoveApplicationAccess: (jobId, users, applications) => {
    const data = { users, applications };
    return r(`${JOBS_URL}${jobId}/bulk/remove-application-access`, 'patch', data);
  },
  exportApplicants: (jobId, fields, exportType) =>
    r(`${JOBS_URL}${jobId}/export/`, 'post', { fields, exportType })
});
