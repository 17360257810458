import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';

import { Application } from 'models/applications';
import { useStore } from 'models/Provider';
import { FileUpload } from 'components/FileUpload';
import { FormLabel } from 'components/Text';
import { Loading } from 'components/Loading';
import { Input } from 'components/Input';
import { TextArea } from 'components/TextArea';
import { Button } from 'components/Button';
import { Select } from 'components/Select';

import { prepData } from './utils';
import { Box } from 'components/Box';
import { Separator } from 'components/Separator';
import { ModalActions, ModalHeader } from 'components/Modal';

function AddApplicantModal({ job, closeModal }) {
  const { t } = useStore();
  const [cv, setCv] = useState(null);
  const [files, setFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [coverLetter, setCoverLetter] = useState(null);
  const [language, setLanguage] = useState(job.defaultLanguageData.language);
  const [application, setApplication] = useState(() =>
    Application.create({ form: getSnapshot(job.defaultLanguageData.form), id: -1, language })
  );

  useEffect(() => {
    const newForm = getSnapshot(job.languages.find(obj => obj.language === language).form);
    const newApp = Application.create({ form: newForm, id: -1, language });
    newApp.setName(application.name);
    newApp.setEmail(application.email);
    newApp.setSsn(application.ssn);
    newApp.setPhone(application.phone);

    application.form.sections.forEach(section => {
      section.questions.forEach(question => {
        newApp.form.sections.forEach(nfs => {
          nfs.questions.forEach(nfq => {
            if (nfq.id === question.id && question.value) {
              if (question.type === 'multidropdown' && question.value.length > 0) {
                const newVal = question.value.reduce((res, item) => {
                  res[item] = true;
                  return res;
                }, {});
                nfq.setValue(newVal);
              } else {
                nfq.setValue(question.value);
              }
            }
          });
        });
      });
    });

    setApplication(newApp);
    // eslint-disable-next-line
  }, [language]);

  const setFile = (questionId, file) => {
    setFiles(old => {
      if (old[questionId] === undefined) {
        return { ...old, [questionId]: file };
      }
      const newObj = { ...old };
      delete newObj[questionId];
      return newObj;
    });
  };

  function isValid(data) {
    let err = {};
    let hasErrors = false;
    if (!data.name) {
      err.name = t('errors.field.required');
      hasErrors = true;
    }
    if (!data.email) {
      err.email = t('errors.field.required');
      hasErrors = true;
    }
    if (hasErrors) {
      setErrors(err);
      return false;
    }
    return true;
  }

  function clearError(field) {
    if (errors[field]) {
      let err = { ...errors };
      delete err[field];
      setErrors(err);
    }
  }

  function setName(val) {
    clearError('name');
    application.setName(val);
  }

  function setEmail(val) {
    clearError('email');
    application.setEmail(val);
  }

  async function submit() {
    const data = getSnapshot(application);
    if (!isValid(data)) {
      return;
    }

    setLoading(true);
    const obj = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      ssn: data.ssn,
      sections: data.form.sections,
      cv,
      coverLetter,
      language,
      files
    };
    const form = prepData(obj);
    const res = await job.addApplicant(form);
    if (!res.error) {
      closeModal();
    } else {
      setLoading(false);
      setErrors(res.data);
    }
  }

  function getQuestionField(q) {
    let el = null;

    switch (q.type) {
      case 'attachment':
        el = (
          <>
            <FormLabel mt="24">{q.title}</FormLabel>
            <FileUpload
              key={q.id}
              file={files[q.id] || null}
              onChange={file => setFile(q.id, file)}
            />
          </>
        );
        break;
      case 'multidropdown':
        let val = null;
        if (q.value) {
          val = q.value.reduce((res, item) => {
            res[item] = true;
            return res;
          }, {});
        }
        el = (
          <>
            <FormLabel mt="24">{q.title}</FormLabel>
            <Select
              multi
              defaultValue={val}
              inModal
              items={q.options.slice()}
              onChange={q.setValue}
            />
          </>
        );
        break;
      case 'dropdown':
        el = (
          <>
            <FormLabel mt="24">{q.title}</FormLabel>
            <Select
              defaultValue={q.value}
              inModal
              items={q.options.slice()}
              onChange={q.setValue}
            />
          </>
        );
        break;
      case 'multi_line':
        el = (
          <>
            <FormLabel mt="24">{q.title}</FormLabel>
            <TextArea resize value={q.value || ''} onChange={q.setValue} />
          </>
        );
        break;
      case 'one_line':
      default:
        el = (
          <>
            <FormLabel mt="24">{q.title}</FormLabel>
            <Input name={q.title} value={q.value || ''} onChange={q.setValue} />
          </>
        );
        break;
    }
    return <div key={q.id}>{el}</div>;
  }
  if (!application) return null;

  return (
    <div style={{ minHeight: 150 }} key={application.language}>
      {loading && <Loading />}
      <ModalHeader>{t('modals.addapplicant.header')}</ModalHeader>
      <Box style={{ opacity: loading ? 0.6 : 1 }}>
        {job.enabledLanguages.length > 1 && (
          <Box mb="12">
            <FormLabel>{t('job.create.form.label.language')}</FormLabel>
            <Select
              inModal
              defaultValue={language}
              items={job.enabledLanguages.map(obj => ({
                display: t(`general.${obj.language}`),
                value: obj.language
              }))}
              onChange={setLanguage}
            />
          </Box>
        )}
        <FormLabel>* {t('job.create.form.label.name')}</FormLabel>
        <Input value={application.name || ''} onChange={setName} error={errors['name']} />
        <FormLabel mt="12">* {t('job.create.form.label.email')}</FormLabel>
        <Input value={application.email || ''} onChange={setEmail} error={errors['email']} />
        {job.includePhone && (
          <>
            <FormLabel mt="12">{t('job.create.form.label.phone')}</FormLabel>
            <Input value={application.phone || ''} onChange={application.setPhone} />
          </>
        )}
        {job.includeSsn && (
          <>
            <FormLabel mt="12">{t('job.create.form.label.ssn')}</FormLabel>
            <Input value={application.ssn || ''} onChange={application.setSsn} />
          </>
        )}
        <FormLabel mt="12">{t('modals.addapplicant.field.cv')}</FormLabel>
        <FileUpload file={cv} onChange={setCv} />

        <FormLabel mt="12">{t('modals.addapplicant.field.cover')}</FormLabel>
        <FileUpload file={coverLetter} onChange={setCoverLetter} />

        {application.form.sections.map(f => {
          return (
            <div key={f.id}>
              <Separator mt="24" mb="24" />
              {f.questions.map(getQuestionField)}
            </div>
          );
        })}
        <ModalActions>
          <Button gray disabled={loading} onClick={closeModal}>
            {t('modals.addapplicant.button.cancel')}
          </Button>
          <Button disabled={loading} onClick={submit}>
            {t('modals.addapplicant.button.submit')}
          </Button>
        </ModalActions>
      </Box>
    </div>
  );
}

export default observer(AddApplicantModal);
