import { useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

function Intercom({ appID, user }) {
  const startedRef = useRef(false);

  useEffect(() => {
    if (!canUseDOM) return;
    window.intercomSettings = { user, app_id: appID };
    if (window.Intercom) {
      window.Intercom('update', { user: user });
    }
  }, [user, appID]);

  useEffect(() => {
    return () => {
      if (!canUseDOM || !window.Intercom) return false;
      window.Intercom('shutdown');
      delete window.Intercom;
    };
  }, []);

  if (!startedRef.current) {
    startedRef.current = true;

    if (!appID || !canUseDOM) {
      return;
    }

    if (!window.Intercom) {
      (function(w, d, id, s, x) {
        function i() {
          i.c(arguments);
        }
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, appID);
    }

    window.intercomSettings = { app_id: appID, user };

    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: appID,
        email: user.email,
        user_id: user.user_id
      });
    }
  }

  return null;
}

export default observer(Intercom);
