import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';

import { useTextEditorState } from './TextEditor';

export const TextEditorInput = observer(() => {
  const {
    readOnly,
    editorState,
    setEditorState,
    plugins,
    editorRef,
    allowShortcuts,
    stripPastedStyles,
    isEmpty,
    disabled,
    placeholder
  } = useTextEditorState();

  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <div style={{ position: 'relative' }}>
      {isEmpty && placeholder && <PlaceholderMessage>{placeholder}</PlaceholderMessage>}
      <Editor
        readOnly={readOnly || disabled}
        onChange={setEditorState}
        editorState={editorState}
        plugins={plugins}
        ref={editorRef}
        stripPastedStyles={stripPastedStyles}
        handleKeyCommand={allowShortcuts ? handleKeyCommand : null}
      />
    </div>
  );
});

const PlaceholderMessage = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  color: ${p => p.theme.colors.grayMedium};
`;
