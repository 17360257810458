import React from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import background from 'shared/static/images/login-image.jpg';

import { Box } from 'components/Box';
import { Icon50skills } from 'components/icons/Icon50skills';

export const UnauthedPage = observer(({ children, title }) => {
  return (
    <Box flex alignItems="center">
      <Left />
      <Right>
        <Logo />
        {children}
      </Right>
    </Box>
  );
});

const Logo = styled(Icon50skills)(p => ({
  position: 'absolute',
  left: '2.5rem',
  top: '2.5rem',
  height: '3rem',
  width: '3rem',
  color: p.theme.colors.green100
}));

const Right = styled.div({
  position: 'relative',
  padding: '2.5rem',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '28.75rem',
  width: '100%'
});

const Left = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  width: 40%;
  flex-shrink: 1;
  height: 100vh;
  @media (max-width: 768px) {
    display: none;
  }
`;
