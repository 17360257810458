import { useStore } from 'models/Provider';
import { IconApplication20 } from 'components/icons/IconApplication20';
import { IconNotes20 } from 'components/icons/IconNotes20';
import { IconRating20 } from 'components/icons/IconRating20';
import { IconFiles20 } from 'components/icons/IconFiles20';
import { IconAccess20 } from 'components/icons/IconAccess20';
import { IconChat20 } from 'components/icons/IconChat20';

export const useApplicationTabs = application => {
  const { t } = useStore();
  const useMessages = application.job.useMessages;
  const linkRoot = `/jobs/${application.job.id}/application/${application.id}/`;

  const items = [
    {
      display: t('application.tabs.details'),
      icon: IconApplication20,
      value: 'details',
      link: linkRoot + 'details'
    },
    {
      display: t('application.tabs.notes'),
      icon: IconNotes20,
      value: 'notes',
      count: application.numNotes,
      activeCount: application.userRead && !application.userRead.notes,
      link: linkRoot + 'notes'
    },
    {
      display: t('application.tabs.ratings'),
      icon: IconRating20,
      value: 'ratings',
      count: application.numReviews,
      activeCount: application.userRead && !application.userRead.ratings,
      link: linkRoot + 'ratings'
    },
    {
      display: t('application.tabs.files'),
      icon: IconFiles20,
      value: 'files',
      count: application.numAttachments,
      activeCount: application.userRead && !application.userRead.files,
      link: linkRoot + 'files'
    },
    {
      display: t('application.tabs.access'),
      icon: IconAccess20,
      value: 'access',
      link: linkRoot + 'access'
    }
  ];

  if (useMessages) {
    items.splice(4, 0, {
      display: t('application.tabs.messages'),
      icon: IconChat20,
      count: application.numMessages,
      value: 'messages',
      activeCount: application.userRead && !application.userRead.messages,
      link: linkRoot + 'messages'
    });
  }
  return items;
};
