import { flow, types as t, destroy } from 'mobx-state-tree';

import { transformApplication } from 'helpers';
import { Application } from 'models/applications';
import { withEnv } from 'models/withEnv';

export const ApplicationStore = t
  .model('ApplicationStore', {
    items: t.map(Application)
  })
  .views(withEnv)
  .views(self => ({
    get list() {
      return Array.from(self.items.values());
    }
  }))
  .actions(self => ({
    load: flow(function*(id) {
      const local = self.items.get(id);
      if (local) {
        local.load();
        return true;
      } else {
        const res = yield self.env.api.applications.load(id);
        if (res.error) return false;
        self.addOrUpdate({ ...res.data, loaded: true }, res.data.job);
        const app = self.items.get(id);
        app.setPicture(res.data.picture);
        return true;
      }
    }),
    addOrUpdate(application, job) {
      let a = transformApplication(application);
      a.job = job;
      const curr = self.items.get(a.id);

      if (!curr) {
        self.items.put(a);
      } else {
        self.items.put({ ...curr, ...a });
      }
    },
    delete: flow(function*(application) {
      const res = yield self.env.api.applications.delete(application.id);
      const job = application.job;
      const stage = application.stage;
      if (!res.error) {
        self.env.notify(
          'success',
          self.env.t('application.store.delete.success', application.name)
        );
        if (self.env.router.location.pathname.includes(application.baseUrl)) {
          const currentApplicantList = job.stages.items.get([stage]).applications;
          const idx = currentApplicantList.indexOf(application);
          if (currentApplicantList.length === 1) {
            // No applications left in this stage, go to application view
            job.goToDetail();
          } else {
            // Either go to the next application in the list,
            // or if this is the last in the list, go back one
            if (idx === currentApplicantList.length - 1) {
              currentApplicantList[idx - 1].goToDetail();
            } else {
              currentApplicantList[idx + 1].goToDetail();
            }
          }
        }
        job.removeApplication(application.id);
        destroy(application);
        return true;
      } else {
        self.env.notify('error', self.env.t('application.store.delete.error', application.name));
        return false;
      }
    })
  }));
