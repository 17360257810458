import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { convertToRaw } from 'draft-js';

import { useStore } from 'models/Provider';
import { NoteTextEditorBox } from 'components/NoteTextEditorBox';
import { ReadOnlyTextEditor, TextEditor, useEditorState } from 'components/TextEditor';
import { Menu, MenuItem, MenuItems, TransparentMenuButton } from 'components/Menu';
import { Tooltip } from 'components/Tooltip';
import { IconInfo } from 'components/icons/IconInfo';
import { IconLock } from 'components/icons/IconLock';
import { IconOptions } from 'components/icons/IconOptions';
import { Text, TextLabel } from 'components/Text';
import { Button } from 'components/Button';
import { Box } from 'components/Box';
import { Switch } from 'components/Switch';
import { UserAvatar } from 'components/UserAvatar';
import { NoteEditButtons } from './styles';

export const ApplicationNotes = observer(({ application: detail }) => {
  const [editing, setEditing] = useState({});
  const { openModal, t } = useStore();
  const job = detail.job;
  detail.setUserRead('notes', true);

  function removeModal(note) {
    openModal('COMMON_REMOVE_CONFIRM', {
      minWidth: '200px',
      autoWidth: true,
      submit: null,
      data: {
        id: note.id,
        type: 'Note'
      },
      confirm: note.remove
    });
  }

  function editNote(note) {
    setEditing(note);
  }

  function cancelEdit() {
    setEditing({});
  }

  function settingsForNote(note) {
    return (
      <Box mr="12">
        <Menu label="Note actions">
          <TransparentMenuButton>
            <IconOptions />
          </TransparentMenuButton>
          <MenuItems>
            <MenuItem onClick={() => editNote(note)}>
              {t('application.notes.actions.edit')}
            </MenuItem>
            <MenuItem variant="danger" onClick={() => removeModal(note)}>
              {t('application.notes.actions.delete')}
            </MenuItem>
          </MenuItems>
        </Menu>
      </Box>
    );
  }

  return (
    <>
      <Tooltip id="note-visibility-tip" />
      <TextLabel mt="24" mb="16">
        {t('application.notes.header')}
      </TextLabel>

      <NotesTextBox job={job} application={detail} disabled={!!editing.id} t={t} />

      {detail.chat.map(note => (
        <Box flex justifyContent="flex-end" key={note.id} mt="24">
          <Box style={{ width: editing.id === note.id ? '100%' : 'auto' }}>
            <Box flex justifyContent="flex-end" grow>
              {editing.id !== note.id && note.user && note.user.isMe && settingsForNote(note)}
              {editing.id === note.id ? (
                <NoteEdit t={t} key={note.text} note={editing} job={job} onCancel={cancelEdit} />
              ) : (
                <Note key={note.text} note={note} job={job} />
              )}
            </Box>
            <Box flex mt={4} justifyContent="flex-end" alignItems="center">
              {note.isAdminNote && (
                <Box mr={8}>
                  <IconLock
                    data-for="note-visibility-tip"
                    data-tip={t('application.notes.textbox.admin_note.label')}
                  />
                </Box>
              )}
              <Text type="regular14" color="purple60" mt="4" style={{ textAlign: 'right' }}>
                {note.dateDisplay}
              </Text>
            </Box>
          </Box>
          <Box ml="16">
            <UserAvatar src={note.user.picture} name={note.user.name} size="lg" />
          </Box>
        </Box>
      ))}
    </>
  );
});

const Note = observer(({ note }) => (
  <ReadOnlyTextEditor html={note.text} box={NoteTextEditorBox} />
));

const NotesTextBox = observer(({ application, disabled, t }) => {
  const { me } = useStore();
  const job = application.job;
  const [adding, setAdding] = useState(false);
  const [adminNote, setAdminNote] = useState(false);
  const [search, setSearch] = useState('');
  const { editorState, resetEditorState, setEditorState, isEmpty } = useEditorState();
  const onSearch = ({ value }) => setSearch(value);

  const followers = useMemo(() => {
    let followers = [...job.activeFollowers];
    application.followers.forEach(af => followers.push(af));
    if (adminNote) {
      followers = followers.filter(f => f.role === 'admin');
    }
    return followers;
  }, [application.followers, job.activeFollowers, adminNote]);

  const suggestions = useMemo(() => {
    if (!search) return followers;
    const str = search.toLowerCase();
    return followers.filter(s => s.name.toLowerCase().includes(str));
  }, [followers, search]);

  const saveNote = async () => {
    setAdding(true);
    const raw = convertToRaw(editorState.getCurrentContent());
    const success = await application.addNote(JSON.stringify(raw), adminNote);
    if (success) {
      resetEditorState();
      setAdminNote(false);
    }
    setAdding(false);
  };

  return (
    <Box mb="32" grow>
      <TextEditor
        disabled={disabled}
        placeholder={
          adminNote
            ? t('application.notes.textbox.placeholder.admin')
            : t('application.notes.textbox.placeholder.regular')
        }
        plugins={['toolbar', 'mention', 'linkify']}
        editorState={editorState}
        setEditorState={setEditorState}
      >
        {({ EditorToolbar, EditorMention, EditorInput, EditorBox }) => (
          <>
            <Tooltip id="visibility-tip" />
            <Box mb="8">
              <EditorToolbar />
            </Box>
            <EditorBox component={NoteTextEditorBox}>
              <EditorMention suggestions={suggestions} onSearch={onSearch} />
              <EditorInput />
              <Box flex justifyContent="space-between" alignItems="center">
                <Box flex alignItems="center">
                  {me.role === 'admin' && (
                    <>
                      <Switch value={adminNote} onToggle={() => setAdminNote(!adminNote)} />
                      <Text ml={8} mr={8}>
                        {t('application.notes.textbox.switch.admin')}
                      </Text>
                      {adminNote && (
                        <IconInfo
                          data-for="visibility-tip"
                          data-tip={t('application.notes.textbox.info.admin')}
                        />
                      )}
                      {!adminNote && (
                        <IconInfo
                          data-for="visibility-tip"
                          data-tip={t('application.notes.textbox.info.regular')}
                        />
                      )}
                    </>
                  )}
                </Box>
                <Button onClick={saveNote} disabled={adding || isEmpty}>
                  {adminNote && t('application.notes.textbox.button.submit.admin')}
                  {!adminNote && t('application.notes.textbox.button.submit.regular')}
                </Button>
              </Box>
            </EditorBox>
          </>
        )}
      </TextEditor>
    </Box>
  );
});

const NoteEdit = observer(({ note, onCancel, job, t }) => {
  const [adding, setAdding] = useState(false);
  const [search, setSearch] = useState('');
  const { editorState, setEditorState, isEmpty } = useEditorState(note.text);

  useEffect(() => {
    note.edit();
  }, [note]);

  const onSearch = ({ value }) => setSearch(value);

  const suggestions = useMemo(() => {
    let followers = [...job.activeFollowers];
    if (note.isAdminNote) {
      followers = followers.filter(f => f.role === 'admin');
    }
    if (!search) return followers;
    const str = search.toLowerCase();
    return followers.filter(s => s.name.toLowerCase().includes(str));
  }, [job.activeFollowers, search, note.isAdminNote]);

  async function updateNote() {
    setAdding(true);
    const text = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const success = await note.update(text);
    success && onCancel();
  }

  return (
    <TextEditor
      focusOnMount
      editorState={editorState}
      setEditorState={setEditorState}
      plugins={['toolbar', 'mention', 'linkify']}
    >
      {({ EditorBox, EditorToolbar, EditorInput, EditorMention }) => (
        <Box grow>
          <Box mb="8">
            <EditorToolbar />
          </Box>

          <EditorBox component={NoteTextEditorBox}>
            <EditorMention suggestions={suggestions} onSearch={onSearch} />
            <EditorInput />

            <NoteEditButtons>
              <Button purple onClick={onCancel}>
                {t('application.notes.edit.button.cancel')}
              </Button>
              <Button onClick={updateNote} disabled={isEmpty || adding}>
                {t('application.notes.edit.button.submit')}
              </Button>
            </NoteEditButtons>
          </EditorBox>
        </Box>
      )}
    </TextEditor>
  );
});
