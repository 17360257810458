import React from 'react';
import { observer } from 'mobx-react-lite';
import { clone } from 'mobx-state-tree';

import { useStore } from 'models/Provider';
import { Menu, MenuButton, MenuItem, MenuItems } from 'components/Menu';
import { IconOptions } from 'components/icons/IconOptions';

export const ApplicationOptions = observer(({ data, grayBg }) => {
  const { t, hasPerm, openModal, router } = useStore();
  if (!hasPerm('application:view-options')) return null;

  function removeModal() {
    openModal('REMOVE_CONFIRM', {
      minWidth: '200px',
      autoWidth: true,
      submit: null,
      data: clone(data),
      confirm: data.delete
    });
  }

  function rejectModal() {
    openModal('REJECT_APPLICANTS', {
      applicant: data,
      width: '37.5rem'
    });
  }

  function retractModal() {
    openModal('RETRACT_APPLICANT', {
      autoWidth: false,
      applicant: data
    });
  }

  function hireModal() {
    openModal('HIRE_APPLICANT', {
      autoWidth: false,
      applicant: data,
      onboard: onboardModal
    });
  }

  function onboardModal() {
    openModal('ONBOARD_APPLICANT', {
      autoWidth: false,
      applicant: data
    });
  }

  function sendEmail() {
    if (data.email) {
      window.location.href = `mailto:${data.email}`;
    }
  }

  function move() {
    router.push(`/jobs/${data.job.id}/application/${data.id}/move`, {
      onCloseUrl: router.location.pathname
    });
  }

  function copy() {
    router.push(`/jobs/${data.job.id}/application/${data.id}/copy`, {
      onCloseUrl: router.location.pathname
    });
  }

  return (
    <Menu label="Application options">
      <MenuButton transparent={!grayBg}>
        <IconOptions />
      </MenuButton>
      <MenuItems>
        {!data.job.useMessages && data.email && (
          <MenuItem onClick={sendEmail}>{t('application.options.email')}</MenuItem>
        )}
        {hasPerm('application:delete') && (
          <MenuItem variant="danger" onClick={removeModal}>
            {t('application.options.delete')}
          </MenuItem>
        )}
        <MenuItem onClick={copy}>{t('application.options.copy')}</MenuItem>
        <MenuItem onClick={move}>{t('application.options.move')}</MenuItem>
        {!data.isHired && !data.isRetracted && (
          <MenuItem
            variant={data.isRejected ? 'base' : 'danger'}
            onClick={data.isRejected ? data.unReject : rejectModal}
          >
            {t(`application.options.${data.isRejected ? 'unreject' : 'reject'}`)}
          </MenuItem>
        )}
        {!data.isRejected && !data.isRetracted && (
          <MenuItem onClick={data.isHired ? data.unHire : hireModal}>
            {t(`application.options.${data.isHired ? 'unhire' : 'hire'}`)}
          </MenuItem>
        )}
        {!data.isHired && !data.isRejected && (
          <MenuItem onClick={data.isRetracted ? data.unRetract : retractModal}>
            {t(`application.options.${data.isRetracted ? 'unretract' : 'retract'}`)}
          </MenuItem>
        )}
        {data.isHired && (
          <MenuItem onClick={data.goToOnboarding}>{t('application.options.onboard')}</MenuItem>
        )}
      </MenuItems>
    </Menu>
  );
});
