import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconIntegrationGetAccept = props => (
  <Svg32 {...props} viewBox="0 0 100 100">
    <path d="M78.7 37.8l-4.4-9.3a40.1 40.1 0 104.4 9.3z" fill="#f59300" />
    <path d="M93.1 9.6L40.8 62 20 41.2l-7 7 27.6 27.6 7-7L100 16.5l-6.9-6.9z" fill="#fff" />
    <path d="M78.7 37.8L100 16.5l-6.9-6.9-18.8 18.8a40.1 40.1 0 014.4 9.3z" fill="#f59300" />
  </Svg32>
);
