import { types as t, flow, destroy } from 'mobx-state-tree';
import { sortBy } from 'helpers';
import { withEnv } from 'models/withEnv';
import { TasklistTemplate } from 'models/templates';

export const TasklistTemplateStore = t
  .model('TasklistTemplateStore', {
    items: t.array(TasklistTemplate),
    selected: t.maybe(t.reference(TasklistTemplate)),
    state: t.optional(
      t.enumeration('TasklistTemplatestoreState', ['init', 'loading', 'loaded']),
      'init'
    )
  })
  .views(withEnv)
  .actions(self => ({
    afterCreate() {
      self.items = sortBy(self.items, 'id');
    },
    load: flow(function*() {
      if (self.state !== 'init') return;
      self.state = 'loading';

      const res = yield self.env.api.templates.loadTasklists();
      if (!res.error) {
        self.items = sortBy(res.data, 'id');
      }
      self.state = 'loaded';
    }),
    select(id) {
      self.selected = id;
    },
    clearSelected() {
      self.selected = undefined;
    },
    addTasklistTemplate(data) {
      self.items.push(data);
    },
    removeTasklistTemplate: flow(function*(template) {
      if (template.id === -1) {
        destroy(template);
        return true;
      }

      const res = yield self.env.api.templates.deleteTasklist(template.id);
      if (!res.error) {
        self.env.notify('success', self.env.t('templates.store.removetasklist.success'));
        if (self.selected && self.selected.id === template.id) {
          self.clearSelected();
          self.env.router.replace('/templates/tasks');
        }
        destroy(template);
        return true;
      }
      self.env.notify('error', self.env.t('templates.store.removetasklist.success'));
      return false;
    }),
    addNew() {
      self.items.push({
        name: self.env.t('templates.store.newtasklist.name'),
        items: [],
        id: -1
      });
      self.env.router.push('/templates/tasks/-1');
      self.select(-1);
    },
    removeNew() {
      self.items.splice(self.items.length - 1, 1);
    }
  }));
