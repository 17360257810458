import React from 'react';
import { observer } from 'mobx-react-lite';

import { IntegrationsContentContainer, IntegrationsContent } from 'pages/Job/Integrations/styles';

import { useStore } from 'models/Provider';
import { IntegrationIcon } from 'components/IntegrationIcon';
import {
  Integration,
  Integrations,
  IntegrationsContainer,
  IntegrationsHeader
} from 'components/styles';
import { Text } from 'components/Text';
import { Box } from 'components/Box';

export const Apps = observer(() => {
  const { t } = useStore();
  return (
    <IntegrationsContentContainer>
      <IntegrationsContainer>
        <IntegrationsHeader>{t('company.settings.tabs.apps')}</IntegrationsHeader>
        <Integrations>
          <Integration active>
            <IntegrationIcon integrationName="Slack" />
            <span>Slack</span>
          </Integration>
        </Integrations>
      </IntegrationsContainer>
      <IntegrationsContent>
        <Text type="h2" color="green100">
          Slack
        </Text>
        <Text type="regular16" mt="16">
          {t('company.settings.apps.slack.cta')}
        </Text>
        <Box mt="24">
          <a href="https://slack.com/oauth/authorize?client_id=43278704066.262284113062&scope=chat:write:bot,users:read.email,users:read,channels:read,bot,groups:write">
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </Box>
      </IntegrationsContent>
    </IntegrationsContentContainer>
  );
});
