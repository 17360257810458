import React from 'react';
import { observer } from 'mobx-react-lite';

import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { H1, P, FormLabel } from 'components/Text';
import { Input } from 'components/Input';
import { UnauthedPage } from 'components/UnauthedPage';

export const SetPassword = observer(props => {
  const { password1, password2, onChange, onSubmit, error, disableSubmit, t } = props;

  let submitLabel = disableSubmit ? t('setpw.button.submitting') : t('setpw.button.submit');

  return (
    <UnauthedPage>
      <H1>{t('setpw.heading')}</H1>
      <P>{t('setpw.instruction')}</P>
      <Box mt="16">
        <FormLabel>{t('setpw.label.password')}</FormLabel>
        <Input
          type="password"
          value={password1}
          onChange={val => onChange('password1', val)}
          error={error}
          disabled={disableSubmit}
        />
      </Box>
      <Box mt="16">
        <FormLabel>{t('setpw.label.confirm')}</FormLabel>
        <Input
          type="password"
          value={password2}
          onChange={val => onChange('password2', val)}
          error={error}
          disabled={disableSubmit}
        />
      </Box>
      <Box flex mt="24">
        <Button disabled={disableSubmit} onClick={onSubmit}>
          {submitLabel}
        </Button>
      </Box>
    </UnauthedPage>
  );
});
