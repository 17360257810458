import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconOptions = props => (
  <Svg {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7a2 2 0 100-4 2 2 0 000 4zM12 14a2 2 0 100-4 2 2 0 000 4zM12 21a2 2 0 100-4 2 2 0 000 4z"
      fill="currentColor"
    />
  </Svg>
);
