export const sortByCount = data =>
  data.sort((a, b) => {
    if (b.count < a.count) {
      return -1;
    }
    if (b.count > a.count) {
      return 1;
    }
    return 0;
  });

export const sortCompareSingle = (a, b, sortBy = undefined, sortType = 'asc') => {
  let aval = a;
  let bval = b;
  if (sortBy) {
    aval = typeof a[sortBy] === 'string' ? a[sortBy].toLowerCase() : a[sortBy];
    bval = typeof b[sortBy] === 'string' ? b[sortBy].toLowerCase() : b[sortBy];
  }
  if (bval < aval) {
    return sortType === 'asc' ? 1 : -1;
  }
  if (bval > aval) {
    return sortType === 'asc' ? -1 : 1;
  }
  return 0;
};

export const sortBy = (data, sortBy, sortType = 'asc') => {
  return data.slice().sort((a, b) => sortCompareSingle(a, b, sortBy, sortType));
};

export const sortJobApplications = (items, order) => {
  switch (order) {
    case 'name-desc':
      return sortBy(items, 'name', 'desc');
    case 'name-asc':
      return sortBy(items, 'name', 'asc');
    case 'date-desc':
      return sortBy(items, 'date', 'desc');
    case 'date-asc':
      return sortBy(items, 'date', 'asc');
    case 'stage-desc':
      return sortBy(items, 'stage', 'desc');
    case 'stage-asc':
      return sortBy(items, 'stage', 'asc');
    case 'notes-desc':
      return items.sort((a, b) => b.numNotes - a.numNotes);
    case 'notes-asc':
      return items.sort((a, b) => a.numNotes - b.numNotes);
    case 'avgvote-desc':
      return sortBy(items, 'averageRating', 'desc');
    case 'avgvote-asc':
      return sortBy(items, 'averageRating', 'asc');
    case 'votes-desc':
      return items.sort((a, b) => b.numReviews - a.numReviews);
    case 'votes-asc':
      return items.sort((a, b) => a.numReviews - b.numReviews);
    case 'messages-desc':
      return items.sort((a, b) => b.numMessages - a.numMessages);
    case 'messages-asc':
      return items.sort((a, b) => a.numMessages - b.numMessages);
    default:
      return items;
  }
};
