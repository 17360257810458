import { types as t, flow, getParent } from 'mobx-state-tree';
import { updateMSTObject } from 'helpers';
import { emailTemplateTypes } from './emailTemplateTypes';
import { withEnv } from 'models/withEnv';

export const EmailTemplate = t
  .model('EmailTemplate', {
    id: t.identifierNumber,
    isGlobal: false,
    isDefault: false,
    contentHtml: '',
    name: t.maybeNull(t.optional(t.string, '')),
    templateType: t.enumeration('EmailTemplateType', emailTemplateTypes),
    title: t.maybeNull(t.optional(t.string, ''))
  })
  .views(withEnv)
  .actions(self => ({
    update(data) {
      updateMSTObject(self, data);
    },
    delete: flow(function*() {
      if (self.isDefault) return;

      const res = yield self.env.api.companies.deleteTemplate(self.id);
      if (!res.error) {
        self.env.notify('success', self.env.t('company.templates.email.delete.success'));
        getParent(self, 2).deleteTemplate(self.id);
        return true;
      } else {
        self.env.notify('error', self.env.t('company.templates.email.delete.error'));
        return false;
      }
    })
  }));
