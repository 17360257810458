import styled from 'styled-components/macro';

export const FormWrapper = styled.div`
  max-height: ${p => (p.isOpen ? '1000px' : '0px')};
  transition: max-height 1s ease-in-out;
  overflow: hidden;
`;

export const FormLabel = styled.div`
  color: ${props => props.theme.formLabelColor};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const RejectName = styled.div`
  color: ${p => p.theme.textColor};
  > span:nth-child(1) {
    font-weight: bold;
  }
  > span:nth-child(2) {
    font-style: italic;
    margin-left: 5px;
  }
`;
