import React from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Box } from 'components/Box';
import { Loading } from 'components/Loading';

import { ApplicationAccess } from './ApplicationAccess';
import { ApplicationDetails } from './ApplicationDetails';
import { ApplicationFiles } from './ApplicationFiles';
import { ApplicationHeader } from './ApplicationHeader';
import { ApplicationMessages } from './ApplicationMessages';
import { ApplicationNotes } from './ApplicationNotes';
import { ApplicationReviews } from './ApplicationReviews';
import { ApplicationTabs } from './ApplicationTabs';
import { useMobile } from 'hooks/useMobile';

function Application({ application }) {
  const isMobile = useMobile();
  if (!application.loaded) {
    return (
      <Box grow>
        <Loading />
      </Box>
    );
  }

  return (
    <Box grow>
      <Box mb={isMobile ? '16' : '32'}>
        <ApplicationHeader data={application} />
      </Box>
      <ApplicationTabs application={application} border />

      <Switch>
        <Route path="/jobs/:jobID/application/:appID/details">
          <ApplicationDetails application={application} />
        </Route>
        <Route path="/jobs/:jobID/application/:appID/access">
          <ApplicationAccess application={application} />
        </Route>
        <Route path="/jobs/:jobID/application/:appID/notes">
          <ApplicationNotes application={application} />
        </Route>
        <Route path="/jobs/:jobID/application/:appID/files">
          <ApplicationFiles application={application} />
        </Route>
        <Route path="/jobs/:jobID/application/:appID/ratings">
          <ApplicationReviews application={application} />
        </Route>
        <Route path="/jobs/:jobID/application/:appID/messages">
          <ApplicationMessages application={application} />
        </Route>
        <Route
          path="/jobs/:jobID/application/:appID"
          exact
          render={({ location }) => <Redirect to={location.pathname + '/details'} />}
        />
      </Switch>
    </Box>
  );
}

export default observer(Application);
