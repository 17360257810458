import { types as t } from 'mobx-state-tree';
import { reaction } from 'mobx';
import { storage } from 'shared/utils/browser';
import { withEnv } from 'models/withEnv';

const DashboardNextUrls = t.model('DashboardNextUrls', {
  published: t.maybeNull(t.string),
  drafts: t.maybeNull(t.string),
  expired: t.maybeNull(t.string),
  archived: t.maybeNull(t.string)
});

const DashboardSettings = t
  .model('DashboardSettings', {
    chosenTab: 0,
    searchStr: '',
    searchOpen: false,
    nextUrls: t.optional(DashboardNextUrls, {})
  })
  .views(withEnv)
  .views(self => ({
    get tabDisplay() {
      switch (self.chosenTab) {
        case 0:
          return self.env.t('dashboardsettings.published');
        case 1:
          return self.env.t('dashboardsettings.drafts');
        case 2:
          return self.env.t('dashboardsettings.expired');
        case 3:
          return self.env.t('dashboardsettings.archived');
        default:
          throw new Error('What happened here?');
      }
    },
    get tabText() {
      switch (self.chosenTab) {
        case 0:
          return 'published';
        case 1:
          return 'drafts';
        case 2:
          return 'expired';
        case 3:
          return 'archived';
        default:
          throw new Error('What happened here?');
      }
    }
  }))
  .actions(self => ({
    afterCreate() {
      const chosenTab = storage.get('dashboard_tab');
      if (chosenTab !== null) {
        self.chosenTab = parseInt(chosenTab, 10);
      }
      reaction(
        () => self.searchStr.length,
        () => {
          self.env.jobStore.load(self.tabText);
        },
        { delay: 300 }
      );
    },
    changeTab(newTab) {
      self.chosenTab = newTab;
      storage.set('dashboard_tab', newTab);
    },
    setSearchValue(newValue) {
      self.searchStr = newValue;
    },
    clearSearchValue() {
      self.searchStr = '';
    },
    openSearch() {
      if (!self.searchOpen) {
        self.searchOpen = true;
      }
    },
    closeSearch() {
      if (self.searchOpen) {
        self.searchOpen = false;
      }
    },
    setNextUrl(status, url) {
      self.nextUrls[status] = url;
    }
  }));

const TeamSettings = t
  .model('TeamSettings', {
    chosenTab: 0,
    searchStr: '',
    searchOpen: false,
    sortBy: 'name',
    sortType: 'asc'
  })
  .views(withEnv)
  .views(self => ({
    get tabs() {
      return [
        { display: self.env.t('user.tabs.all'), value: 0 },
        { display: self.env.t('user.tabs.admins'), value: 1 },
        { display: self.env.t('user.tabs.recruiters'), value: 2 },
        { display: self.env.t('user.tabs.limited'), value: 3 },
        { display: self.env.t('user.tabs.employees'), value: 4 }
      ];
    }
  }))
  .actions(self => ({
    afterCreate() {
      const chosenTab = storage.get('team_tab');
      if (chosenTab !== null) {
        self.chosenTab = parseInt(chosenTab, 10);
      }
    },
    setSorting(val) {
      self.sortType = 'asc';
      self.sortBy = val;
    },
    reverseSort() {
      self.sortType = self.sortType === 'asc' ? 'desc' : 'asc';
    },
    changeTab(newTab) {
      self.chosenTab = newTab;
      storage.set('team_tab', newTab);
    },
    setSearchValue(newValue) {
      self.searchStr = newValue;
    },
    clearSearchValue() {
      self.searchStr = '';
    },
    openSearch() {
      if (!self.searchOpen) {
        self.searchOpen = true;
      }
    },
    closeSearch() {
      if (self.searchOpen) {
        self.searchOpen = false;
      }
    }
  }));

export const CompanyUISettings = t
  .model('CompanyUISettings', {
    dashboard: t.optional(DashboardSettings, {}),
    team: t.optional(TeamSettings, {}),
    showNotifications: false
  })
  .actions(self => ({
    toggleNotifications() {
      self.showNotifications = !self.showNotifications;
    },
    closeNotifications() {
      self.showNotifications = false;
    }
  }));
