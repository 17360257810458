import preval from 'preval.macro';

const { buildSha, buildDate } = preval`
  const { execSync } = require('child_process');
  const moment = require('moment');
  module.exports = {
    buildSha: execSync('git rev-parse HEAD').toString().substr(0, 7),
    buildDate: moment(new Date()).format()
  };
`;

window.buildSha = buildSha;
window.buildDate = buildDate;
