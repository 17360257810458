import { types as t } from 'mobx-state-tree';

import { sortBy } from 'helpers';
import { ApplicationTask } from 'models/applications';

export const ApplicationTasklist = t
  .model('ApplicationTasklist', {
    id: t.maybe(t.number),
    name: t.string,
    items: t.array(ApplicationTask)
  })
  .views(self => ({
    get orderedTasks() {
      return sortBy(self.items.slice(), 'id', 'asc');
    }
  }));
