import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconIntegrationSlack = props => (
  <Svg32 {...props} viewBox="0 0 40 40">
    <path
      d="M9.718 24.7a3.732 3.732 0 01-3.734 3.736 3.732 3.732 0 01-3.734-3.737 3.732 3.732 0 013.734-3.737h3.734V24.7zM11.585 24.7a3.732 3.732 0 013.734-3.738 3.732 3.732 0 013.733 3.737v9.342a3.732 3.732 0 01-3.733 3.737 3.732 3.732 0 01-3.734-3.737v-9.342z"
      fill="#E01E5A"
    />
    <path
      d="M15.319 9.696a3.732 3.732 0 01-3.734-3.737 3.732 3.732 0 013.734-3.737 3.732 3.732 0 013.733 3.737v3.737H15.32zM15.319 11.592a3.732 3.732 0 013.733 3.737 3.732 3.732 0 01-3.733 3.737H5.956a3.732 3.732 0 01-3.734-3.737 3.732 3.732 0 013.734-3.737h9.363z"
      fill="#36C5F0"
    />
    <path
      d="M30.282 15.33a3.732 3.732 0 013.734-3.738 3.732 3.732 0 013.733 3.737 3.732 3.732 0 01-3.733 3.737h-3.734v-3.737zM28.415 15.33a3.732 3.732 0 01-3.734 3.736 3.732 3.732 0 01-3.733-3.737V5.96a3.732 3.732 0 013.733-3.737 3.732 3.732 0 013.734 3.737v9.37z"
      fill="#2EB67D"
    />
    <path
      d="M24.681 30.304a3.732 3.732 0 013.734 3.737 3.732 3.732 0 01-3.734 3.737 3.732 3.732 0 01-3.733-3.737v-3.737h3.733zM24.681 28.436a3.732 3.732 0 01-3.733-3.737 3.732 3.732 0 013.733-3.737h9.363a3.732 3.732 0 013.734 3.737 3.732 3.732 0 01-3.734 3.737h-9.363z"
      fill="#ECB22E"
    />
  </Svg32>
);
