import { types as t, flow, destroy, getParent } from 'mobx-state-tree';
import moment from 'moment';

import { withEnv } from 'models/withEnv';
import { Integration } from 'models/integrations';
import { Job } from 'models/jobs';
import { Language, LoadingState } from 'models/ui';
import { UserRef } from 'models/users';
import {
  ApplicationChat,
  ApplicationForm,
  ApplicationMessage,
  ApplicationQuestionMinimal,
  ApplicationReview,
  ApplicationTasklist,
  ApplicationUserRead
} from 'models/applications';

import { getTextForRating, updateMSTObject, sortBy, transformApplication } from 'helpers';

const SSNRegistryResult = t.model('SSNRegistryResult', {
  ssn: t.maybe(t.string),
  name: t.maybe(t.string),
  verified: t.optional(t.boolean, false),
  ssnError: t.optional(t.boolean, false)
});

export const Attachment = t
  .model('Attachment', {
    attachment: t.maybeNull(t.string),
    blob: t.optional(t.frozen(), null),
    id: t.identifierNumber,
    loaded: false,
    fileName: t.maybe(t.string),
    questionId: t.maybeNull(t.string),
    questionTitle: t.maybeNull(t.string),
    date: t.maybeNull(t.optional(t.string, '')),
    user: t.maybeNull(t.union(t.undefined, t.late(() => UserRef)))
  })
  .views(withEnv)
  .views(self => ({
    get noPreview() {
      return self.attachment && self.loaded && self.blob === null;
    }
  }))
  .actions(self => ({
    loadAttachment: flow(function*() {
      if (self.loaded) return;
      if (!self.attachment) {
        self.loaded = true;
        return;
      }

      const res = yield self.env.api.applications.loadBlob(self.attachment);
      if (res.status === 200 && res.data.type === 'application/pdf') {
        self.blob = res.data;
      }
      self.loaded = true;
    })
  }));

export const ApplicationTrigger = t
  .model('ApplicationTrigger', {
    name: t.string,
    status: t.string,
    ratio: t.maybeNull(t.number)
  })
  .views(withEnv)
  .views(self => ({
    get statusText() {
      return self.status === 'completed'
        ? self.env.t('onboarding.applicants.status.completed')
        : self.status === 'in_progress'
        ? self.env.t('onboarding.applicants.status.inprogress')
        : self.env.t('onboarding.applicants.status.notstarted');
    }
  }));

export const Application = t
  .model('Application', {
    chat: t.array(ApplicationChat),
    contentCleared: t.optional(t.boolean, false),
    coverLetter: t.maybeNull(t.optional(t.string, '')),
    cv: t.maybeNull(t.optional(t.string, '')),
    date: t.optional(t.string, ''),
    email: t.maybeNull(t.optional(t.string, '')),
    followers: t.array(t.late(() => UserRef)),
    form: t.maybeNull(t.maybe(ApplicationForm)),
    id: t.identifierNumber,
    isHired: t.optional(t.boolean, false),
    isManuallyAdded: t.optional(t.boolean, false),
    isNotifiedOfRejection: t.optional(t.boolean, false),
    isRejected: t.optional(t.boolean, false),
    isRetracted: t.optional(t.boolean, false),
    isOnboarded: t.optional(t.boolean, false),
    status: '',
    job: t.maybe(t.reference(t.late(() => Job))),
    language: t.maybeNull(Language),
    name: t.maybeNull(t.optional(t.string, '')),
    order: t.optional(t.number, 0),
    phone: t.maybeNull(t.string),
    questions: t.array(ApplicationQuestionMinimal),
    referredBy: t.maybeNull(t.union(t.undefined, t.late(() => UserRef))),
    referredSite: t.maybeNull(t.optional(t.string, '')),
    assignedTo: t.maybeNull(t.union(t.undefined, t.late(() => UserRef))),
    reviews: t.array(ApplicationReview),
    averageReviews: '-',
    averageReviewsNumber: 0,
    sex: t.maybeNull(t.union(t.undefined, t.enumeration('Sex', ['m', 'f', 'o']))),
    ssn: t.maybeNull(t.optional(t.string, '')),
    ssnStatus: t.maybeNull(
      t.union(
        t.undefined,
        t.enumeration('SSN Status', [
          'error',
          'unverified',
          'verified',
          'keep_or_match',
          'ssn_only'
        ])
      )
    ),
    ssnRegistryResult: t.maybeNull(SSNRegistryResult),
    stage: t.optional(t.number, 1),
    stageName: t.optional(t.string, ''),

    integrations: t.array(Integration),
    integrationsState: LoadingState,
    isHiredTriggers: t.optional(t.array(ApplicationTrigger), []),
    dateOfHire: t.maybeNull(t.optional(t.string, '')),
    tasklist: t.maybe(ApplicationTasklist),
    tasklistState: LoadingState,
    taskListItems: t.maybe(t.array(t.number)),
    messages: t.array(ApplicationMessage),

    numAttachments: 0,
    numMessages: 0,
    numNotes: 0,
    numReviews: 0,

    coverLetterBlob: t.optional(t.frozen(), null),
    coverLetterLoaded: false,
    coverLetterNoPreview: false,
    cvBlob: t.optional(t.frozen(), null),
    cvLoaded: false,
    cvNoPreview: false,
    attachments: t.array(Attachment),
    loaded: false,
    userRead: t.maybeNull(ApplicationUserRead)
  })
  .views(withEnv)
  .views(self => ({
    get attachMap() {
      return self.attachments.reduce((res, item) => {
        res[item.id] = item;
        return res;
      }, {});
    },
    get cvFileName() {
      if (!self.cv) return '';
      const split = self.cv.split('?Signature')[0].split('/');
      return split[split.length - 1];
    },
    get coverLetterFileName() {
      if (!self.coverLetter) return '';
      const split = self.coverLetter.split('?Signature')[0].split('/');
      return split[split.length - 1];
    },
    get picture() {
      const appPicture = self.env.applicationPictureStore.items.get(self.id);
      return appPicture ? appPicture.url : '';
    },
    get noCvPreview() {
      return self.cv && self.cvLoaded && self.cvBlob === null;
    },
    get noCoverLetterPreview() {
      return self.coverLetter && self.coverLetterLoaded && self.coverLetterBlob === null;
    },
    get averageRating() {
      return self.averageReviewsNumber;
    },
    get averageRatingText() {
      const rating = getTextForRating(self.averageReviews);
      return rating !== '-' ? self.env.t(rating) : rating;
    },
    get averageRatingLetter() {
      return self.averageReviews;
    },
    get myReview() {
      return self.reviews.find(review => review.user.isMe);
    },
    get baseUrl() {
      return `/jobs/${self.job.id}/application/${self.id}`;
    },
    get detailUrl() {
      return `/jobs/${self.job.id}/application/${self.id}/details`;
    },
    get onboardingUrl() {
      return `/jobs/${self.job.id}/onboarding/${self.id}`;
    },
    get onboardingDetailsUrl() {
      return `/jobs/${self.job.id}/onboarding/${self.id}/details`;
    },
    get onboardingRewardsUrl() {
      return `/jobs/${self.job.id}/onboarding/${self.id}/rewards`;
    },
    get appliedDisplay() {
      const momentObj = moment(self.date);
      const date = momentObj.format(' MMMM Do YYYY');
      return `Applied: ${date}`;
    },
    get allFollowers() {
      return sortBy(self.job.followers.concat(self.followers), 'name', 'asc');
    },
    get formQuestions() {
      const langObj =
        self.job.languages.find(obj => obj.language === self.language) || self.job.languages[0];
      if (!langObj) return [];
      let questions = [];
      self.form.sections.forEach(section => {
        section.questions.forEach(question => {
          if (question.type === 'multidropdown' || question.type === 'dropdown') {
            if (!question.value) {
              questions.push({ ...question });
              return;
            }
            const slice = question.value.slice();
            let val = Array.isArray(slice) ? slice : [question.value];
            val = val.map(v => langObj.findOptionDisplayByValue(v) || v);
            questions.push({ ...question, value: val });
          } else {
            questions.push(question);
          }
        });
      });
      return questions;
    }
  }))
  .actions(self => ({
    setJob(job) {
      self.job = job;
    },
    sendMessage: flow(function*(message) {
      const res = yield self.env.api.applications.sendMessage(self.id, { message });
      if (!res.error) {
        self.messages = res.data.messages;
        self.env.notify('success', self.env.t('application.messages.send.success'));
        return true;
      } else {
        self.env.notify('error', self.env.t('application.messages.send.error'));
        return false;
      }
    }),
    updateSsn: flow(function*(ssn) {
      const res = yield self.env.api.applications.editSsn(self.id, ssn);
      if (!res.error) {
        self.ssn = ssn;
        self.ssnStatus = 'unverified';
        self.ssnRegistryResult = null;
        self.env.notify('success', self.env.t('application.edit.ssn.success'));
        return true;
      }
      self.env.notify('error', self.env.t('application.edit.ssn.error'));
      return false;
    }),
    setPicture(picture) {
      self.env.applicationPictureStore.setUrl(self.id, picture);
    },
    setApplicationFollower(user) {
      self.followers.push(user);
      user.followCandidate(self.id);
    },
    setAllUserRead(data) {
      self.userRead = data;
    },
    setUserRead(fieldName, value) {
      if (self.userRead && self.userRead[fieldName] !== value) {
        self.userRead[fieldName] = value;
        self.env.api.applications.read(self.id, { [fieldName]: value });
      }
    },
    setAssignedTo(user) {
      const res = self.env.api.applications.setAssignedTo(self.id, user ? user.id : null);
      if (!res.error) {
        if (user) {
          self.env.notify(
            'success',
            self.env.t('application.setassignedto.success', self.name, user.name)
          );
          self.assignedTo = user;
        } else {
          self.env.notify('success', self.env.t('application.setassignedto.remove', self.name));
          self.assignedTo = null;
        }
        return true;
      } else {
        self.env.notify(
          'error',
          self.env.t('application.setassignedto.error', self.name, user.name)
        );
        return false;
      }
    },
    grantFollowerAccessMulti: flow(function*(users) {
      const followers = self.followers.slice().map(f => f.id);
      const ids = users.map(u => u.id);
      followers.push(...ids);
      const res = yield self.env.api.applications.setFollowers(self.id, followers);
      if (!res.error) {
        self.env.notify(
          'success',
          self.env.t('application.grantmultifollowers.success', ids.length, self.name)
        );
        self.followers.push(...users);
        users.forEach(u => {
          u.followCandidate(self.id);
          self.job.addToAppFollowCountForUser(u, 1);
        });
        return true;
      } else {
        self.env.notify('error', self.env.t('application.grantmultifollowers.error'));
        return false;
      }
    }),
    grantFollowerAccess: flow(function*(user) {
      const followers = self.followers.slice().map(f => f.id);
      followers.push(user.id);
      const res = yield self.env.api.applications.setFollowers(self.id, followers);
      if (!res.error) {
        self.env.notify(
          'success',
          self.env.t('application.grantfollower.success', user.name, self.name)
        );
        self.followers.push(user);
        user.followCandidate(self.id);
        self.job.addToAppFollowCountForUser(user, 1);
        return true;
      } else {
        self.env.notify('error', self.env.t('application.grantfollower.error'));
        return false;
      }
    }),
    shallowRemoveFollower(user) {
      self.followers = self.followers.filter(f => f.id !== user.id);
    },
    removeFollowerAccess: flow(function*(user) {
      const followers = self.followers
        .slice()
        .filter(f => f.id !== user.id)
        .map(f => f.id);
      const res = yield self.env.api.applications.setFollowers(self.id, followers);
      if (!res.error) {
        self.env.notify(
          'success',
          self.env.t('application.removefollower.success', user.name, self.name)
        );
        self.shallowRemoveFollower(user);
        user.unfollowCandidate(self.id);
        return true;
      } else {
        self.env.notify('error', self.env.t('application.removefollower.error'));
        return false;
      }
    }),
    removeTasklist: flow(function*() {
      const res = yield self.env.api.applications.removeTasklist(self.id);
      if (!res.error) {
        destroy(self.tasklist);
        self.env.notify('success', self.env.t('application.removetasklist.success'));
        return true;
      }
      self.env.notify('error', self.env.t('application.removetasklist.error'));
      return false;
    }),
    changeTasklist: flow(function*(templateId) {
      self.tasklistState = 'loading';
      const res = yield self.env.api.applications.saveTasklist(self.id, templateId);
      if (!res.error) {
        /**
         * @Refactor Graceful MST migration - Remove after server trim PR is merged.
         */
        self.tasklist = {
          ...res.data,
          items: res.data.items.map(item => ({
            ...item,
            checkedBy: item.checkedBy
              ? typeof item.checkedBy === 'object'
                ? item.checkedBy.id
                : item.checkedBy
              : null
          }))
        };
        self.tasklistState = 'loaded';
        return true;
      }
      self.tasklistState = 'error';
      return false;
    }),
    loadTasklist: flow(function*() {
      if (self.tasklistState !== 'init') return;
      self.tasklistState = 'loading';

      const res = yield self.env.api.applications.loadTasks(self.id);
      if (res.status === 404) {
        self.tasklistState = 'loaded';
      } else if (res.error) {
        self.tasklistState = 'error';
      } else {
        self.env.api.applications.read(self.id, { taskList: true });
        /**
         * @Refactor Graceful MST migration - Remove after server trim PR is merged.
         */
        self.tasklist = {
          ...res.data,
          items: res.data.items.map(item => ({
            ...item,
            checkedBy: item.checkedBy
              ? typeof item.checkedBy === 'object'
                ? item.checkedBy.id
                : item.checkedBy
              : null
          }))
        };
        self.tasklistState = 'loaded';
      }
    }),
    removeNote: flow(function*(note) {
      const res = yield self.env.api.applications.removeNote(note.id);
      if (!res.error) {
        self.numNotes -= 1;
        self.env.notify('success', self.env.t('application.removenote.success'));
        destroy(note);
        return true;
      } else {
        self.env.notify('error', self.env.t('application.removenote.error'));
        return false;
      }
    }),
    addNote: flow(function*(message, isAdminNote) {
      const res = yield self.env.api.applications.addNote({
        text: message,
        application: self.id,
        isAdminNote: isAdminNote
      });
      if (!res.error) {
        self.numNotes += 1;
        self.env.notify('success', self.env.t('application.addnote.success'));
        /**
         * @Refactor Graceful MST migration - Remove after server trim PR is merged.
         */
        self.chat.unshift({
          ...res.data,
          user: typeof res.data.user === 'object' ? res.data.user.id : res.data.user
        });
        return true;
      } else {
        self.env.notify('error', self.env.t('application.addnote.error'));
        return false;
      }
    }),
    setReview: flow(function*(review) {
      if (self.myReview && self.myReview.doesThink === review) {
        const res = yield self.env.api.applications.addReview({
          doesThink: '',
          application: self.id
        });
        if (!res.error) {
          self.numReviews -= 1;
          self.myReview.setRating('');
          self.env.notify('success', self.env.t('application.resetreview.success', self.name));
        } else {
          self.env.notify('error', self.env.t('application.resetreview.error', self.name));
        }
      } else {
        const res = yield self.env.api.applications.addReview({
          doesThink: review,
          application: self.id
        });
        if (!res.error) {
          if (!self.myReview || self.myReview.doesThink === '') {
            self.numReviews += 1;
          }
          self.env.notify('success', self.env.t('application.setreview.success', self.name));
          if (self.myReview) {
            self.myReview.setRating(review);
          } else {
            /**
             * @Refactor Graceful MST migration - Remove after server trim PR is merged.
             */
            self.reviews.push({
              ...res.data,
              user: typeof res.data.user === 'object' ? res.data.user.id : res.data.user
            });
          }
        } else {
          self.env.notify('error', self.env.t('application.setreview.error', self.name));
        }
      }
      self.load();
    }),
    load: flow(function*() {
      if (self.userRead && (!self.userRead.newApplication || !self.userRead.assignedTo)) {
        self.userRead.newApplication = true;
        self.userRead.assignedTo = true;
        self.env.api.applications.read(self.id, { newApplication: true, assignedTo: true });
      }
      const res = yield self.env.api.applications.load(self.id);
      self.setPicture(res.data.picture);
      updateMSTObject(self, transformApplication(res.data));
      self.stageName = self.job[`stage${self.stage}Name`];
      self.job.loadApplicationUserRead([self.id]);
      self.loaded = true;
    }),
    loadIntegrations: flow(function*() {
      if (self.integrationsState !== 'init') return;

      self.integrationsState = 'loading';
      const res = yield self.env.api.applications.loadIntegrations(self.id, 'applicant_hired');
      if (!res.error) {
        self.integrations = res.data.map(d => ({ ...d, type: 'applicant_hired' }));
        self.integrationsState = 'loaded';
      } else {
        self.integrationsState = 'error';
      }
    }),
    goToDetail(state) {
      self.env.router.push(`/jobs/${self.job.id}/application/${self.id}/details`, state);
    },
    loadCv: flow(function*() {
      if (self.cvLoaded) return;
      if (!self.cv) {
        self.cvLoaded = true;
        return;
      }

      const res = yield self.env.api.applications.loadBlob(self.cv);
      if (res.status === 200 && res.data.type === 'application/pdf') {
        self.cvBlob = res.data;
      }
      self.cvLoaded = true;
    }),
    loadCoverLetter: flow(function*() {
      if (self.coverLetterLoaded) return;
      if (!self.coverLetter) {
        self.coverLetterLoaded = true;
        return;
      }

      const res = yield self.env.api.applications.loadBlob(self.coverLetter);
      if (res.status === 200 && res.data.type === 'application/pdf') {
        self.coverLetterBlob = res.data;
      }
      self.coverLetterLoaded = true;
    }),
    addAttachment: flow(function*(data) {
      const res = yield self.env.api.applications.addAttachment(self.id, data);
      if (res.status === 201) {
        self.numAttachments += 1;
        self.attachments.unshift({
          attachment: res.data.attachment,
          fileName: res.data.fileName,
          id: res.data.id,
          user: res.data.userId,
          date: res.data.date
        });
        self.env.notify('success', self.env.t('application.attachments.add.success'));
      } else {
        self.env.notify('error', self.env.t('application.attachments.add.error'));
      }
    }),
    removeAttachment: flow(function*(attachment) {
      const res = yield self.env.api.applications.removeAttachment(self.id, attachment.id);
      if (res.status === 204) {
        self.numAttachments -= 1;
        destroy(attachment);
        self.env.notify('success', self.env.t('application.attachments.remove.success'));
      } else {
        self.env.notify('error', self.env.t('application.attachments.remove.error'));
      }
      return true;
    }),
    changeStage: flow(function*(stage) {
      if (self.stage === stage) return;
      const oldStage = self.stage;
      self.stage = stage;
      const res = yield self.env.api.applications.moveStage(self.id, stage);
      if (!res.error) {
        self.stageName = self.job[`stage${stage}Name`];
        self.env.notify(
          'success',
          self.env.t('application.changestage.success', self.name, self.job[`stage${stage}Name`])
        );
        const oldStageObj = self.job.stages.items.get(oldStage);
        const newStageObj = self.job.stages.items.get(stage);
        oldStageObj.removeApplication(self.id);
        newStageObj.addApplication(self.id);
      } else {
        self.stage = oldStage;
        self.env.notify('error', self.env.t('application.changestage.error', self.name));
      }
      const dynamicStage = self.job.dynamicStages.find(
        dynamicStage => dynamicStage.stage === stage
      );
      dynamicStage &&
        self.env.openModal(dynamicStage.autoOpenModal, {
          autoWidth: false,
          overlayClose: false,
          applicant: self
        });
    }),
    delete() {
      return getParent(self, 2).delete(self);
    },
    markOnboarded: flow(function*(state) {
      const res = yield self.env.api.applications.onboard(self.id, state);
      if (!res.error) {
        self.isOnboarded = state;
        self.env.notify(
          'success',
          self.env.t(
            state ? 'application.onboard.success' : 'application.unonboard.success',
            self.name
          )
        );
        return true;
      } else {
        self.env.notify(
          'error',
          self.env.t(state ? 'application.onboard.error' : 'application.unonboard.error', self.name)
        );
        return false;
      }
    }),
    hire: flow(function*() {
      const res = yield self.env.api.applications.hire(self.id);
      if (!res.error) {
        self.isHired = true;
        self.env.notify('success', self.env.t('application.hire.success', self.name));
        return true;
      } else {
        self.env.notify('error', self.env.t('application.hire.error', self.name));
        return false;
      }
    }),
    unHire: flow(function*() {
      const res = yield self.env.api.applications.unHire(self.id);
      if (!res.error) {
        self.env.notify('success', self.env.t('application.unhire.success'));
        self.isHired = false;
      } else {
        self.env.notify('error', self.env.t('application.unhire.error'));
      }
    }),
    reject: flow(function*(markNotified = false, emailData) {
      const res = yield self.env.api.applications.reject(self.id, markNotified, emailData);
      if (!res.error) {
        let msg = self.env.t('application.reject.success');
        if (emailData) {
          msg = self.env.t('application.reject.success.rejection');
        }
        self.isRejected = true;
        if (markNotified) {
          self.isNotifiedOfRejection = true;
        }
        self.env.notify('success', msg);
        return true;
      } else {
        self.env.notify('error', self.env.t('application.reject.error'));
        return false;
      }
    }),
    unReject: flow(function*() {
      const res = yield self.env.api.applications.unReject(self.id);
      if (!res.error) {
        self.env.notify('success', self.env.t('application.unreject.success'));
        self.isRejected = false;
        self.isNotifiedOfRejection = false;
      } else {
        self.env.notify('error', self.env.t('application.unreject.error'));
      }
    }),
    retract: flow(function*() {
      const res = yield self.env.api.applications.retract(self.id);
      if (!res.error) {
        self.isRetracted = true;
        self.env.notify('success', self.env.t('application.retract.success', self.name));
        return true;
      } else {
        self.env.notify('error', self.env.t('application.retract.error', self.name));
        return false;
      }
    }),
    unRetract: flow(function*() {
      const res = yield self.env.api.applications.unRetract(self.id);
      if (!res.error) {
        self.env.notify('success', self.env.t('application.unretract.success'));
        self.isRetracted = false;
      } else {
        self.env.notify('error', self.env.t('application.unretract.error'));
      }
    }),
    keepName: flow(function*() {
      const res = yield self.env.api.applications.keepOrMatchName(self.id, 'keep');
      if (!res.error) {
        self.ssnStatus = 'ssn_only';
        self.env.notify('success', self.env.t('application.keepname.success'));
      } else {
        self.env.notify('error', self.env.t('application.keepname.error'));
      }
    }),
    matchName: flow(function*() {
      const res = yield self.env.api.applications.keepOrMatchName(self.id, 'match');
      if (!res.error) {
        self.name = self.ssnRegistryResult.name;
        self.ssnStatus = 'verified';
        self.env.notify('success', self.env.t('application.matchname.success'));
      } else {
        self.env.notify('error', self.env.t('application.matchname.error'));
      }
    }),
    verifySsn: flow(function*() {
      const res = yield self.env.api.applications.verifySsn(self.id);

      if (!res.error) {
        self.ssnRegistryResult = res.data.ssnRegistryResult;
        self.ssnStatus = res.data.ssnStatus;
        if (self.ssnStatus === 'verified') {
          self.env.notify('success', self.env.t('application.verifyssn.success'));
        } else if (self.ssnStatus === 'keep_or_match') {
          self.env.notify('success', self.env.t('application.verifyssn.keepormatch'));
        }
      } else {
        if (res.status === 400) {
          self.ssnStatus = 'error';
          self.env.notify('error', self.env.t('application.verifyssn.error.notfound'));
        } else {
          self.env.notify('error', self.env.t('application.verifyssn.error'));
        }
      }
    }),
    goToOnboarding() {
      self.env.router.push(`/jobs/${self.job.id}/onboarding/${self.id}`);
    },
    setName(name) {
      self.name = name;
    },
    setEmail(email) {
      self.email = email;
    },
    setPhone(phone) {
      self.phone = phone;
    },
    setSsn(ssn) {
      self.ssn = ssn;
    },
    setRejected(rejected) {
      self.isRejected = rejected;
    },
    setNotifiedOfRejection(notified) {
      self.isNotifiedOfRejection = notified;
    },
    setStage(id, name) {
      self.stage = id;
      self.stageName = name;
    }
  }));
