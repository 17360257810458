import React, { useRef } from 'react';
import { formatDistanceToNow } from 'date-fns';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Button } from 'components/Button';
import { Box } from 'components/Box';
import { IconAttachment } from 'components/icons/IconAttachment';
import { IconButton } from 'components/IconButton';
import { IconClose } from 'components/icons/IconClose';
import { IconOpen } from 'components/icons/IconOpen';
import { Text, TextLabel } from 'components/Text';
import { Tooltip } from 'components/Tooltip';
import { UserAvatar } from 'components/UserAvatar';
import { useMobile } from 'hooks/useMobile';

export const ApplicationFiles = observer(({ application: detail }) => {
  const { openFullscreenModal, openModal, t } = useStore();
  const fileRef = useRef(null);
  const isMobile = useMobile();
  detail.setUserRead('files', true);

  function uploadClick() {
    fileRef.current.click();
  }

  function onChange(e) {
    const file = e.target.files.length > 0 ? e.target.files[0] : null;
    const formData = new FormData();
    formData.append('attachment', file);
    detail.addAttachment(formData);
  }

  function removeAttachment(attachment) {
    openModal('COMMON_REMOVE_CONFIRM', {
      width: '400px',
      autoWidth: true,
      submit: null,
      data: {
        id: attachment,
        type: 'Attachment'
      },
      confirm: detail.removeAttachment
    });
  }

  const attachments = detail.attachments.filter(a => a.user !== null);

  return (
    <div>
      <TextLabel mt="24" mb="16">
        {t('application.header.files')}
      </TextLabel>
      <Tooltip id="filetip" />
      {attachments.map(a => (
        <Box
          flex
          mb="16"
          key={a.id}
          alignItems={isMobile ? 'flex-start' : 'center'}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <FileBox
            key={a.id}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              openFullscreenModal('FILE_PREVIEW', {
                application: detail,
                value: a.id,
                attachments: attachments
              })
            }
          >
            <Box
              data-for="filetip"
              data-tip={t('application.files.hoveropen')}
              flex
              grow
              alignItems="center"
              style={{ overflow: 'hidden', height: '2.5rem' }}
            >
              <IconOpen />
              <Text
                ml="4"
                type="regular12"
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {a.fileName}
              </Text>
            </Box>
            <IconButton
              data-for="filetip"
              data-tip={t('application.files.remove')}
              trans
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                removeAttachment(a);
              }}
              icon={IconClose}
            />
          </FileBox>
          {a.user && (
            <Box flex ml={isMobile ? 0 : 16} mb={isMobile ? 4 : 0}>
              <UserAvatar
                src={a.user.picture}
                name={a.user.name}
                style={{ margin: '0 10px 0 5px' }}
                size="md"
              />
              <div>
                <Text type="bold14" color="purple90">
                  {a.user && a.user.name} {t('application.files.attached')}
                </Text>
                <Text type="regular12" color="purple50" mt="-4">
                  {a.date && formatDistanceToNow(new Date(a.date), { addSuffix: true })}
                </Text>
              </div>
            </Box>
          )}
        </Box>
      ))}
      <input style={{ display: 'none' }} type="file" ref={fileRef} onChange={onChange} />
      <Button gray icon={IconAttachment} onClick={uploadClick} mt="16">
        <span>{t('application.files.add')}</span>
      </Button>
    </div>
  );
});

const FileBox = styled.div(p => ({
  backgroundColor: p.theme.colors.purpleTrans5,
  color: p.theme.colors.purple60,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '0.75rem',
  ':hover': {
    cursor: 'pointer'
  },
  width: '100%',
  maxWidth: '17.5rem',
  overflow: 'hidden',
  height: '2.5rem',
  textOverflow: 'ellipsis',
  svg: {
    minWidth: '1.5rem'
  },
  button: {
    minWidth: '2.5rem'
  }
}));
