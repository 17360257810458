import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconHamburger = props => (
  <Svg {...props} viewBox="0 0 40 40" height="2rem" width="2rem">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 29v3h32v-3H4zm0-10.5v3h32v-3H4zM4 8v3h32V8H4z"
      fill="currentColor"
    />
  </Svg>
);
