import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useEnsureJobLoaded } from 'hooks/useEnsureJobLoaded';
import { useStore } from 'models/Provider';
import IntegrationList from 'components/IntegrationList';
import { Header } from 'components/Header';
import { BreadCrumbPath, BreadCrumb } from 'components/BreadCrumb';
import PlaceholderPage from 'components/PlaceholderPage';
import { PageContent } from 'components/Page';
import { Tab, TabList, useTabState } from 'components/Tabs';
import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import ApplicationOnboardingDetails from './ApplicationOnboardingDetails';
import Rewards from './Rewards';
import Integrations from './Integrations';
import Tasklist from './Tasklist';
import { OnboardingContentContainer, OnboardingContent, OnboardingSidebar } from './styles';

export const ApplicationOnboarding = observer(() => {
  const { applicationStore, current, t } = useStore();
  const [updating, setUpdating] = useState(false);
  const params = useParams();
  useEnsureJobLoaded(params.jobID);

  const { useSsnRegistry } = current.info;
  const application = applicationStore.items.get(params.appID);

  useEffect(() => {
    if (!application) {
      applicationStore.load(params.appID);
    }
  }, [params.appID, applicationStore, application]);

  if (!application) {
    return <PlaceholderPage />;
  }

  application.load();
  application.loadIntegrations();
  const job = application.job;

  async function markOnboarded() {
    setUpdating(true);
    await application.markOnboarded(true);
    setUpdating(false);
  }

  async function unmarkOnboarded() {
    setUpdating(true);
    await application.markOnboarded(false);
    setUpdating(false);
  }

  return (
    <div>
      <Header
        mobileTitle={application.name}
        tabs={<ApplicationOnboardingTabs application={application} />}
        breadcrumbs={
          application.loaded ? <ApplicationOnboardingCrumbs application={application} /> : null
        }
      />
      <PageContent>
        {application.integrationsState !== 'loading' && (
          <OnboardingContentContainer>
            <IntegrationList
              url={application.onboardingUrl}
              triggers={application.integrations}
              loading={false}
              t={t}
            />
            <OnboardingContent>
              <Switch>
                <Route
                  path="/jobs/:jobID/onboarding/:appID/integrations/:integrationID"
                  render={props => {
                    const id = Number(props.match.params.integrationID);
                    const integration = application.integrations.find(i => i.triggerId === id);
                    if (!integration) {
                      return <Redirect to={{ pathname: application.onboardingUrl }} />;
                    }
                    return (
                      <Integrations
                        key={props.match.params.integrationID}
                        integration={integration}
                        {...props}
                      />
                    );
                  }}
                />
                <Route path="/jobs/:jobID/onboarding/:appID/rewards">
                  <Rewards job={job} application={application} t={t} />
                </Route>
                <Route>
                  <ApplicationOnboardingDetails
                    application={application}
                    useSsnRegistry={useSsnRegistry}
                  />
                </Route>
                />
              </Switch>
            </OnboardingContent>
            <OnboardingSidebar>
              {updating && <Loading />}
              {!updating && (
                <Box mb="24">
                  {application.isOnboarded && (
                    <Button purple fullWidth onClick={unmarkOnboarded}>
                      {t('application.onboarding.unmark_onboarded')}
                    </Button>
                  )}
                  {!application.isOnboarded && (
                    <Button fullWidth onClick={markOnboarded}>
                      {t('application.onboarding.mark_onboarded')}
                    </Button>
                  )}
                </Box>
              )}
              <Tasklist application={application} t={t} />
            </OnboardingSidebar>
          </OnboardingContentContainer>
        )}
      </PageContent>
    </div>
  );
});

const sectionToTabIndex = section => {
  switch (section) {
    case 'integrations':
      return -1;
    case 'details':
      return 0;
    case 'rewards':
      return 1;
    default:
      return 0;
  }
};

const ApplicationOnboardingTabs = observer(({ application }) => {
  const { t } = useStore();
  const { section } = useParams();
  const tabState = useTabState({ selectedId: sectionToTabIndex(section) });

  return (
    <TabList state={tabState}>
      <Tab link={application.onboardingDetailsUrl}>{t('application.onboarding.tabs.details')}</Tab>
      <Tab link={application.onboardingRewardsUrl}>{t('application.onboarding.tabs.sourcing')}</Tab>
    </TabList>
  );
});

const ApplicationOnboardingCrumbs = observer(({ application }) => {
  const { t } = useStore();
  const job = application.job;
  return (
    <BreadCrumb>
      <BreadCrumbPath link="/jobs">{t('dashboard.tabs.jobs')}</BreadCrumbPath>
      <BreadCrumbPath link={job.detailUrl}>{job.title}</BreadCrumbPath>
      <BreadCrumbPath link={application.detailUrl}>{application.name}</BreadCrumbPath>
      <BreadCrumbPath active>{t('application.onboarding.crumb.onboard')}</BreadCrumbPath>
    </BreadCrumb>
  );
});
