import styled from 'styled-components/macro';

export const HeaderItem = styled.div(props => ({
  transition: 'all 100ms ease-in',
  height: '100%',
  color: '#fff',
  opacity: props.faded ? 0.5 : 1,
  fontSize: 13,
  textTransform: 'uppercase',
  letterSpacing: 1,
  ':hover': {
    opacity: 1
  }
}));

export const HeaderSeparator = styled.div({
  backgroundColor: 'rgba(187, 188, 201, 0.5)',
  height: 76,
  width: 1
});

export const NavContainer = styled.div`
  background-color: #3d4066;
  height: 76px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  > a {
    opacity: 0.5;
    font-size: 14px;
    padding: 20px 0;
    margin-right: 40px;
    text-transform: uppercase;
    :hover {
      opacity: 1;
    }
  }

  .material-nav-tab {
    color: #fff !important;
    opacity: 0.5 !important;
    :hover {
      opacity: 1 !important;
    }

    &.--active {
      opacity: 1 !important;
    }
  }
`;

export const NavLogoAndTabs = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NavLogoSpacer = styled.div`
  padding-top: 3px;
  margin-right: 40px;
  img {
    width: 42px;
    height: 42px;
  }
`;

export const IntegrationsContainer = styled.div`
  background-color: rgba(61, 64, 102, 0.05);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 30px;
  max-width: 280px;
  min-width: 280px;
  width: 100%;
  @media (max-width: ${p => p.theme.bp.md}) {
    max-width: 380px;
    margin: 0 auto;
  }
`;

export const Integrations = styled.div``;

export const IntegrationsHeader = styled.div`
  color: #3d4066;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  margin: 14px 0 12px 0;
  opacity: 0.8;
  text-transform: uppercase;
`;

export const NoIntegrations = styled.div`
  color: #565a77;
  font-size: 14px;
  margin: 12px 10px;
`;

export const Integration = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.white100};
  border: 0.125rem solid ${p => (p.active ? p.theme.primaryColor : 'transparent')};
  border-radius: 0.25rem;
  display: flex;
  height: 3rem;
  padding: 0 0.75rem;
  width: 100%;
  :hover {
    cursor: pointer;
  }
`;

export const Field = styled.div`
  margin-bottom: 20px;
`;

export const IntegrationIconContainer = styled.img`
  height: 32px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 76px;
  border-radius: 0 4px 4px 0;
`;

export const File = styled.div`
  background-color: rgba(61, 64, 102, 0.05);
  border-radius: 4px;
  color: rgba(49, 47, 76, 0.8);
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  svg {
    margin-right: 14px;
  }
`;
