import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Box } from 'components/Box';
import { Select } from 'components/Select';
import { FormLabel } from 'components/Text';
import { TextEditor, usePlaceholderMap } from 'components/TextEditor';
import { FormWrapper } from './styles';

function RejectForm({ isOpen, templates, selected, setEditorState, disabled, onSelect }) {
  const { t } = useStore();
  const placeholderMap = usePlaceholderMap('rejection');

  return (
    <FormWrapper isOpen={isOpen}>
      {templates.length > 0 && (
        <Box mt="24" mb="24">
          <RejectTemplateSelector templates={templates} onChange={onSelect} />
        </Box>
      )}

      <Box mt="24">
        <FormLabel>{t('rejectform.label.message')}</FormLabel>
        <TextEditor
          placeholderMap={placeholderMap}
          readOnly={disabled}
          editorState={selected.message}
          setEditorState={setEditorState}
          plugins={['toolbar', 'linkify', 'placeholder']}
        >
          {({ EditorBox, EditorInput, EditorToolbar, EditorPlaceholderMenu }) => (
            <>
              <Box flex justifyContent="space-between" alignItems="center" mb="4">
                <EditorToolbar />
                <EditorPlaceholderMenu />
              </Box>
              <EditorBox>
                <EditorInput />
              </EditorBox>
            </>
          )}
        </TextEditor>
      </Box>
    </FormWrapper>
  );
}

export default observer(RejectForm);

const RejectTemplateSelector = observer(({ onChange, templates }) => {
  const { t } = useStore();
  const items = templates.map(t => {
    return {
      value: t.id,
      display: t.title || t.name
    };
  });
  return (
    <>
      <FormLabel>{t('rejectform.label.templateselect')}</FormLabel>
      <Select
        inModal
        title={t('rejectform.template.drop.title')}
        fullSize
        items={items}
        onChange={onChange}
      />
    </>
  );
});
