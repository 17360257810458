import { reaction, decorate, observable } from 'mobx';

import { storage } from 'shared/utils/browser';

import { AuthStore } from 'models/auth';
import { Company } from 'models/companies';
import { UIStore, I18nStore } from 'models/ui';

export class RootStore {
  // stores
  auth;
  ui = UIStore.create();
  i18n = I18nStore.create();
  router = null;
  theme = null;

  // companies
  companies = [];
  current = null;

  constructor({ config, theme, history }) {
    this.auth = AuthStore.create({ config });
    this.router = history;
    this.theme = theme;

    reaction(
      () => this.auth.tokenList.length,
      length => {
        if (length > 0) {
          this.setCompanies();
          this.initCurrent();
        }
      }
    );
    this.setCompanies();
    this.initCurrent();
  }

  setSentryContext = () => {
    if (process.env.NODE_ENV === 'production' && window.Raven) {
      const user = this.current.users.me;
      if (user) {
        window.Raven.setUserContext({
          email: user.email,
          id: user.id,
          role: user.role
        });
      }
    }
  };

  switchCompany = async id => {
    const firstRun = this.current === null;
    const company = this.companies.find(company => company.id === id);
    if (!company) {
      return;
    }

    const path = this.router.location.pathname;
    if (!firstRun && !['/team', '/', '/applicants', '/sharing'].includes(path)) {
      this.router.push('/');
    }

    this.current = company;
    await this.current.load();
    this.setSentryContext();
    storage.set('50_current', id);

    if (!firstRun) {
      this.ui.notify('success', this.i18n.t('companies.successfulSwitch', this.current.info.name));
    }
  };

  initCurrent = () => {
    if (this.companies.length === 0) return;

    const stored = storage.get('50_current');
    if (stored) {
      const found = this.companies.find(company => company.id === Number(stored));
      if (found) {
        this.switchCompany(found.id);
        return;
      }
    }
    this.switchCompany(this.companies[0].id);
  };

  setCompanies = () => {
    const env = {
      signOut: this.auth.signOut,
      t: this.i18n.t,
      notify: this.ui.notify,
      openModal: this.ui.openModal,
      openFullscreenModal: this.ui.openFullscreenModal,
      theme: this.theme,
      router: this.router
    };

    this.auth.tokenList.forEach(token => {
      const company = Company.create({ token: { ...token }, id: token.company }, env);
      company.loadInfo();
      this.companies.push(company);
    });
  };
}

decorate(RootStore, {
  current: observable,
  companies: observable
});
