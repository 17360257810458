import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';

export const HireApplicantModal = observer(({ applicant, closeModal, onboard }) => {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useStore();

  function onSubmit() {
    setSubmitting(true);
    const success = applicant.hire();
    if (success) {
      closeModal();
      onboard && onboard();
    } else {
      setSubmitting(false);
    }
  }

  return (
    <>
      <ModalHeader>{t('hiremodal.title')}</ModalHeader>
      <ModalText>
        {t('hiremodal.confirmtext')} <strong>{applicant.name}</strong>?
      </ModalText>
      {submitting && <Loading />}

      <ModalActions>
        <Button disabled={submitting} gray onClick={closeModal}>
          {t('hiremodal.button.cancel')}
        </Button>
        <Button disabled={submitting} onClick={onSubmit}>
          {t('hiremodal.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
});
