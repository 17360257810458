import styled, { css } from 'styled-components/macro';

export const User = styled.div`
  padding: 20px;
  flex: 1;
`;

export const Applied = styled.div`
  color: #9e9e9e;
`;

export const Attachment = styled.div`
  background-color: rgba(61, 64, 102, 0.05);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  max-width: 280px;
  letter-spacing: 0.5px;
  opacity: 0.8;
  :hover {
    background-color: ${p => p.theme.colors.purpleTrans10};
  }
  svg:nth-child(1) {
    margin-right: 14px;
  }
  > div {
    > span {
      color: ${p => p.theme.colors.purple90};
      font-size: 12px;
    }
  }
`;

export const ApplicationReviewContainer = styled.div`
  ${p => p.topBorder && 'border-top: 1px solid rgba(0, 0, 0, 0.1)'};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
`;

export const ApplicationReviewVote = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const YourReviewContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  @media (max-width: ${p => p.theme.bp.md}) {
    display: block;
    > button {
      margin-bottom: 12px;
    }

    > button:nth-child(2),
    > button:nth-child(4) {
      margin-right: 0;
    }
  }
`;

export const ThumbContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  color: #3d4066;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 16px;
  > img {
    width: 36px;
  }
  > span {
    text-align: center;
    width: calc(100% - 72px);
  }
`;

const thumbActiveRule = css`
  ${p => p.thumb === 'S' && 'background-color: #00C882'};
  ${p => p.thumb === 'Y' && 'background-color: #7ADEBB'};
  ${p => p.thumb === 'N' && 'background-color: #F5B64D'};
  ${p => p.thumb === 'D' && 'background-color: #E05858'};
`;

export const ThumbButton = styled.button`
  height: 38px;
  width: 100%;
  max-width: 205px;
  min-width: 135px;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  margin-right: ${p => (p.noMargin ? '0' : '20px')};
  ${p => p.active && thumbActiveRule};
  ${p =>
    !p.disabled &&
    css`
      :hover {
        cursor: pointer;
        ${thumbActiveRule};
      }
    `}

  @media (max-width: ${p => p.theme.bp.md}) {
    max-width: calc(50% - 10px);
    width: 100%;
  }
  @media (max-width: 480px) {
    max-width: 100%;
    display: block;
    margin-bottom: 10px;
  }
`;

export const FixedThumbButton = styled(ThumbButton)`
  width: 205px;
  @media (max-width: ${p => p.theme.bp.md}) {
    width: 205px;
    max-width: 100%;
  }
  @media (max-width: 480px) {
    width: auto;
    margin-bottom: 0;
  }
`;

export const NoContent = styled.div`
  color: #8c9cb0;
  font-size: 18px;
`;

export const NoteEditButtons = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 9;
  display: flex;

  > button:nth-child(1) {
    margin-right: 10px;
  }
`;

export const GDPRApplicationWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  height: ${p => p.ht};
  border-radius: 4px;
  padding: 40px;

  img {
    height: 90px;
    width: 90px;

    margin-right: 40px;
  }

  > div:nth-child(1) {
    display: flex;
    margin-bottom: 80px;
  }

  h2 {
    margin: 0;
    color: #6b6b6b;
    font-size: 28px;
    line-height: 40px;
  }

  p {
    color: #8c9cb0;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    max-width: 70%;
  }

  .fake-text {
    display: flex;
    flex-direction: column;
    div {
      height: 30px;
      border-radius: 4px;
      background-color: #ebebed;
      margin-bottom: 10px;
    }
    div:nth-child(4) {
      width: 60%;
      margin-bottom: 50px;
    }

    div:nth-child(5) {
      align-self: flex-end;
      width: 35%;
    }
    div:nth-child(6) {
      align-self: flex-end;
      width: 40%;
    }
  }
`;

export const HiringTeamAccessEntry = styled.div`
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 14px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:nth-child(1) {
    flex-grow: 1;
    max-width: 40%;

    @media (max-width: 600px) {
      max-width: 100%;
      flex-grow: 0;
    }
    display: flex;
    align-items: center;
    .hiring-team-name {
      margin-left: 0.75rem;
    }
  }
  > div:nth-child(2) {
    flex-grow: 1;
    max-width: 60%;
    @media (max-width: 600px) {
      max-width: 100%;
      flex-grow: 0;
      flex-direction: column;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const GrantAccessButton = styled.button`
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: ${p => p.theme.textColor};
  font-weight: bold;
  font-size: 13px;
  height: 36px;
  max-width: 140px;
  opacity: 0.8;
  text-transform: uppercase;
  width: 100%;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 1;
  }
`;
