import React, { createContext, useContext } from 'react';
import Flex from 'shared/layout/Flex';

import {
  TextEditorBold,
  TextEditorBulletedList,
  TextEditorHeading,
  TextEditorItalic,
  TextEditorNumberedList,
  TextEditorUnderline
} from './icons';
import { ToolbarBlockStyleButton } from './ToolbarBlockStyleButton';
import { ToolbarInlineStyleButton } from './ToolbarInlineStyleButton';
import { Tooltip } from 'components/Tooltip';

const TextEditorToolbarContext = createContext();
export const useToolbarContext = () => useContext(TextEditorToolbarContext);

export const TextEditorToolbar = ({ getEditorState, setEditorState }) => {
  if (!getEditorState) return null;

  return (
    <TextEditorToolbarContext.Provider value={{ getEditorState, setEditorState }}>
      <Tooltip id="toolbartip" />
      <Flex>
        <ToolbarBlockStyleButton
          data-tip="Heading / normal"
          data-for="toolbartip"
          blockStyle="header-three"
          icon={<TextEditorHeading />}
        />
        <ToolbarInlineStyleButton
          data-tip="Bold"
          data-for="toolbartip"
          inlineStyle="BOLD"
          icon={<TextEditorBold />}
        />
        <ToolbarInlineStyleButton
          data-tip="Italic"
          data-for="toolbartip"
          inlineStyle="ITALIC"
          icon={<TextEditorItalic />}
        />
        <ToolbarInlineStyleButton
          data-tip="Underline"
          data-for="toolbartip"
          inlineStyle="UNDERLINE"
          icon={<TextEditorUnderline />}
        />
        <ToolbarBlockStyleButton
          data-tip="Bulleted list"
          data-for="toolbartip"
          blockStyle="unordered-list-item"
          icon={<TextEditorBulletedList />}
        />
        <ToolbarBlockStyleButton
          data-tip="Numbered list"
          data-for="toolbartip"
          blockStyle="ordered-list-item"
          icon={<TextEditorNumberedList />}
        />
      </Flex>
    </TextEditorToolbarContext.Provider>
  );
};
