import { createAuthedRequestObj } from 'shared/utils/browser';
import { makeApplicationsClient } from './applications';
import { makeCompaniesClient } from './companies';
import { makeJobsClient } from './jobs';
import { makeNotificationsClient } from './notifications';
import { makeTemplatesClient } from './templates';
import { makeUsersClient } from './users';
export * from './urls';

export const makeAuthedApiClient = (token, on401) => {
  const authedRequest = createAuthedRequestObj(token, on401);
  return makeApiClient(authedRequest);
};

export const makeApiClient = req => ({
  applications: makeApplicationsClient(req),
  companies: makeCompaniesClient(req),
  jobs: makeJobsClient(req),
  notifications: makeNotificationsClient(req),
  templates: makeTemplatesClient(req),
  users: makeUsersClient(req),
  get: url => req(url)
});
