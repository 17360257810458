export default {
  'application.addnote.error': 'Ekki tókst að bæta við athugasemd. Vinsamlegast reyndu aftur.',
  'application.addnote.success': 'Athugasemd bætt við',
  'application.changestage.error': 'Ekki tókst að færa {0}. Vinsamlegast reyndu aftur.',
  'application.changestage.success': '{0} fært yfir í dálk {1}',
  'application.chat.update.error': 'Ekki tókst að uppfæra athugasemd. Vinsamlegast reyndu aftur.',
  'application.chat.update.success': 'Athugasemd uppfærð',
  'application.setassignedto.success': 'Umsókn {0} hefur verið úthlutað á {1}',
  'application.setassignedto.remove': 'Úthlutun umsóknar {0} hefur verið fjarlægð',
  'application.setassignedto.error': 'Ekki tókst að úthluta notanda {0} á umsókn {1}',
  'application.grantfollower.error': 'Ekki tókst að bæta við réttindum. Vinsamlegast reynið aftur.',
  'application.grantfollower.success': "Notandanum: {0} hefur verið bætt við á umsækjanda: {1}",
  'application.grantmultifollowers.error': 'Ekki tókst að bæta við réttindum. Vinsamlegast reynið aftur.',
  'application.grantmultifollowers.success': "Notandanum: {0} hefur verið bætt við á umsækjanda: {1}",
  'application.hire.error': 'Ekki tókst að merkja umsækjanda {0} sem ráðinn. Vinsamlegast reynið aftur.',
  'application.hire.success': 'Umsækjandi {0} hefur verið merktur sem ráðinn.',
  'application.onboard.error': 'Ekki tókst að merkja umsækjanda {0} sem virkan. Vinsamlegast reynið aftur.',
  'application.onboard.success': 'Umsækjandi {0} hefur verið merktur sem virkur.',
  'application.unonboard.error': 'Ekki tókst að merkja {0} sem óvirkan. Vinsamlegast reynið aftur.',
  'application.unonboard.success': '{0} hefur verið merktur sem virkur.',
  'application.keepname.error': 'Ekki tókst að uppfæra umsækjanda. Vinsamlegast reynið aftur.',
  'application.keepname.success': 'Uppfærsla á nafni umsækjanda tókst',
  'application.matchname.error': 'Ekki tókst að uppfæra nafn umsækjanda. Vinsamlegast reynið aftur.',
  'application.matchname.success': 'Nafn umsækjanda samsvarar núna þjóðskrá.',
  'application.reject.error': 'Ekki tókst að hafna umsækjanda. Vinsamlegast hafið samband við 50skills.',
  'application.reject.success.rejection': 'Umsækjandi hefur verið merktur sem hafnað. Tölvupóstur hefur verið sendur.',
  'application.reject.success': 'Umsækjanda hafnað.',
  'application.removefollower.error': 'Ekki tókst að breyta réttindum. Vinsamlegast reynið aftur.',
  'application.removefollower.success': "Réttindi notanda {0} frá {1} hefur verið fjarlægð",
  'application.removenote.error': 'Ekki tókst að fjarlægja athugasemd. Vinsamlegast reynið aftur.',
  'application.removenote.success': 'Athugasemd fjarlægð.',
  'application.removetasklist.error': 'Ekki tókst að fjarlægja gátlista. Vinsamlegast reynið aftur.',
  'application.removetasklist.success': 'Gátlisti fjarlægður.',
  'application.resetreview.error': 'Ekki tókst að endurstilla einkunnargjöf fyrir umsókn {0}. Vinsamlegast reynið aftur.',
  'application.resetreview.success': 'Einkunnargjöf fyrir {0} hefur verið endurstillt.',
  'application.setreview.error': 'Ekki tókst að gefa einkunn fyrir {0}. Vinsamlegast reynið aftur.',
  'application.setreview.success': 'Einkunn fyrir {0} hefur verið skráð',
  'application.store.delete.error': "Ekki tókst að eyða {0}. Vinsamlegast reynið aftur.",
  'application.store.delete.success': "{0} hefur verið eytt.",
  'application.task.toggle.error.done': 'Ekki tókst að merkja {0} sem lokið',
  'application.task.toggle.error.notdone': 'Ekki tókst að merkja {0} sem ekki lokið',
  'application.task.toggle.success.done': '{0} hefur verið merkt sem lokið',
  'application.task.toggle.success.notdone': '{0} hefur verið merkt sem ekki lokið',
  'application.unreject.error': 'Ekki var hægt að fjarlægja höfnun af umsækjanda. Vinsamlegast reynið aftur.',
  'application.unreject.success': 'Höfnun hefur verið fjarlægð af umsækjanda.',
  'application.unhire.error': 'Ekki var hægt að fjarlægja ráðningu af umsækjanda. Vinsamlegast reynið aftur eða hafið samband við 50skills.',
  'application.unhire.success': 'Ráðning hefur verið fjarlægð af umsækjanda',
  'application.retract.error': 'Ekki tókst að merkja umsókn sem afturkallaða. Vinsamlegast reynið aftur eða hafið samband við 50skills.',
  'application.retract.success': 'Umsókn hefur verið merkt sem afturkölluð.',
  'application.unretract.error': 'Ekki tókst að fjarlægja afturköllun af umsókn. Vinsamlegast reynið aftur eða hafið samband við 50skills.',
  'application.unretract.success': 'Umsókn hefur verið merkt sem afturkölluð',
  'application.verifyssn.error.notfound': 'Ekki tókst að finna kennitölu í þjóðskrá',
  'application.verifyssn.error': 'Ekki tókst að staðfesta kennitölu. Vinsamlegast reynið aftur.',
  'application.verifyssn.keepormatch': 'Kennitala staðfest en nafn umsækjanda passar ekki við kennitölu',
  'application.verifyssn.success': 'Nafn og kennitala hefur verið staðfest.',
  'application.edit.ssn.success': 'Kennitala umsækjanda hefur verið uppfærð',
  'application.edit.ssn.error': 'Ekki tókst að uppfæra kennitölu. Vinsamlegast reynið aftur.',
  'changepass.submit.error': 'Ekki tókst að breyta lykilorði. Staðfestir að gamla lykilorðið sé rétt og nýju lykilorðin séu eins.',
  'changepass.submit.success': 'Lykilorði hefur verið breytt',
  'changepass.validate.pass2error': "Nýju lykilorðin eru ekki eins.",
  'company.update.error': 'Ekki tókst að uppfæra fyrirtækja upplýsingar. Vinsamlegast reynið aftur.',
  'company.update.success': 'Fyrirtækjaupplýsingum hefur verið breytt',
  'jobs.list.applicants': 'Umsóknir',
  'jobs.list.applicantstoday': 'Í dag',
  'jobs.list.location': 'Staðsetning',
  'jobs.list.deadline': 'Umsóknarfr.',
  'dashboard.createjob': 'Nýtt starf',
  'jobs.tabs.archived': 'Geymsla',
  'jobs.tabs.drafts': 'Drög',
  'jobs.tabs.expired': 'Útrunnið',
  'jobs.tabs.published': 'Í loftinu',
  'dashboard.tabs.archived': 'Geymsla',
  'dashboard.tabs.drafts': 'Drög',
  'dashboard.tabs.expired': 'Útrunnið',
  'dashboard.tabs.published': 'Í loftinu',
  'dashboardsettings.archived': 'Geymsla',
  'dashboardsettings.drafts': 'Drög',
  'dashboardsettings.expired': 'Útrunnið',
  'dashboardsettings.published': 'Í loftinu',
  'edituser.create.error': 'Villa kom upp við stofnun notanda. Vinsamlegast reynið aftur eða hafið samband við 50skills',
  'edituser.create.success': 'Notandi {0} hefur verið stofnaður.',
  'errors.required': 'Nauðsynlegt er að fylla þennan reit út',
  'general.en': 'Enska',
  'general.is': 'Íslenska',
  'helpers.defno': 'Sterkt nei!',
  'helpers.no': 'Nei',
  'helpers.strongyes': 'Sterkt já!',
  'helpers.yes': 'Já',
  'hiremodal.button.cancel': 'Hætta við',
  'hiremodal.button.submit': 'Ráða',
  'hiremodal.confirmtext': 'Viltu merkja umsækjandann sem ráðinn?',
  'hiremodal.title': 'Ráða umsækjanda',
  'retractmodal.button.cancel': 'Hætta við',
  'retractmodal.button.submit': 'Merkja umsókn sem afturkallaða',
  'retractmodal.confirmtext': 'Ertu viss um að þú viljir merkja umsóknina sem afturkallaða?',
  'retractmodal.title': 'Merkja umsókn sem afturkallaða',
  'exportmodal.title': 'Flytja út umsækjendur',
  'exportmodal.labels.exporttype': 'Tegund útflutnings',
  'exportmodal.labels.headers': 'Veldu flokka',
  'exportmodal.labels.questions': 'Veldu spurningar',
  'exportmodal.exporttype.xlsx': 'XLSX',
  'exportmodal.exporttype.csv': 'CSV',
  'exportmodal.fields.id': 'Umsóknar ID',
  'exportmodal.fields.name': 'Nafn',
  'exportmodal.fields.email': 'Netfang',
  'exportmodal.fields.phone': 'Símanúmer',
  'exportmodal.fields.ssn': 'Kennitala',
  'exportmodal.fields.date': 'Dagsetning umsóknar',
  'exportmodal.fields.is_rejected': 'Er hafnað',
  'exportmodal.fields.is_notified_of_rejection': 'Höfnunartilkynning send',
  'exportmodal.fields.date_rejected': 'Dagsetning höfnunar',
  'exportmodal.fields.is_hired': 'Er ráðin/n',
  'exportmodal.fields.date_hired': 'Dagsetning ráðningar',
  'exportmodal.fields.is_retracted': 'Er umsókn dregin til baka',
  'exportmodal.fields.is_manually_added': 'Handvirkt bætt við',
  'exportmodal.fields.referred_site': 'Uppspretta',
  'exportmodal.fields.stage_name': 'Nafn dálks',
  'exportmodal.fields.num_reviews': 'Fjöldi einkunna',
  'exportmodal.fields.num_notes': 'Fjöldi umsagna',
  'exportmodal.select': 'Velja allt',
  'exportmodal.deselect': 'Afvelja allt',
  'exportmodal.button.cancel': 'Hætta við',
  'exportmodal.button.submit': 'Flytja út',
  'exportmodal.button.close': 'Loka',
  'exportmodal.submitted.title': 'Sæki gögn',
  'exportmodal.submitted.confirmtext': 'Skráin er í vinnslu og verður send á þitt netfang: <strong>{0}</strong> þegar hún er tilbúin.',
  'exportmodal.submitted.notice': 'Athugið að þetta gæti tekið nokkrar mínútur og ekki gleyma að athuga ruslpóstinn ef þú sérð ekki póstinn.',
  'integration.submit.error': 'Gat ekki sent inn gögn. Vinsamlegast reynið aftur.',
  'integration.delete.error': 'Villa. Vinsamlegast hafið samband við 50skills..',
  'integration.preview.error': 'Villa kom upp við forskoðun. Vinsamlegast reynið aftur eða hafið samband við 50skills.',
  'integration.validators.ssn': 'Ógild kennitala',
  'integration.validators.number': 'Inntak ætti aðeins að innihalda tölur',
  'integration.validators.bankNumber': 'Bankanúmer þarf að vera á bilinu 1-4 tölustafir',
  'integration.validators.ledger': 'Höfuðbók ætti að vera 2 tölustafir',
  'integration.validators.accountNumber': 'Reikningsnúmer þarf að vera 1-6 tölustafir',
  'job.addapplicant.error': 'Ekki tókst að bæta við umsækjanda {0}. Vinsamlegast yfirfarið formið og reynið aftur.',
  'job.addapplicant.success': 'Umsókn {0} hefur verið búin til.',
  'job.archive.error': 'Villa kom upp við að geyma starf. Vinsamlegast reynið aftur.',
  'job.archive.success': 'Starf hefur verið sent í geymslu.',
  'job.bulkgrant.error': 'Ekki tókst að hafna völdum umsækjendum. Vinsamlegast hafið samband við 50skills.',
  'job.bulkreject.success.marked': '{0} umsækjendum hefur verið hafnað og umsókn þeirra merkt sem Tilkynnt',
  'job.bulkreject.success.notified': '{0} umsækjendum hefur verið hafnað og tilkynning send út',
  'job.bulkreject.success': '{0} umsóknum hefur verið hafnað.',
  'job.bulkmove.success': '{0} umsóknir færðar í dálk {1}.',
  'job.bulkmove.error': 'Ekki tókst að færa {0} umsóknir í dálk {1}.',
  'job.bulkmessage.error': 'Ekki tókst að senda skilaboð. Vinsamlegast hafið samband við 50skills.',
  'job.bulkmessage.success': '{0} skilaboð hafa verið send',
  'job.bulkmessage.title': 'Senda skilaboð',
  'job.bulkmessage.confirm.signel': 'Ertu viss um að þú viljir senda skilaboð á <strong>{0}</strong>?',
  'job.bulkmessage.confirm.multi': 'Ertu viss um að þú viljir senda skilaboð á eftirfarandi aðila?',
  'job.bulkmessage.button.cancel': 'Hætta við',
  'job.bulkmessage.button.submit': 'Senda skilaboð',
  'job.create.error': 'Ekki tókst að stofna starf. Vinsamlegast reynið aftur.',
  'job.create.success': 'Starf vistað sem drög.',
  'job.create.field.required': 'Nauðsynlegt er að fylla þennan reit út',
  'job.drafts.error': 'Ekki tókst að færa starf. Vinsamlegast reynið aftur.',
  'job.drafts.success': 'Starf hefur verið fært yfir í drög.',
  'job.duplicate.error': 'Ekki tókst að afrita starf {0}. Vinsamlegast reynið aftur.',
  'job.duplicate.success': 'Starf {0} hefur verið afritað.',
  'job.immediateupdate.error': 'Ekki tókst að uppfæra starfið. Vinsamlegast reynið aftur.',
  'job.immediateupdate.success': 'Starf hefur verið uppfært',
  'job.nodeadline': 'Enginn umsóknarfrestur hefur verið valinn',
  'job.nolocation': 'Engin staðsetning hefur verið valin',
  'job.notitle': 'Enginn titill hefur verið settur',
  'job.publish.error': 'Villa við virkjun starfs {0}. Vinsamlegast reynið aftur.',
  'job.publish.success': 'Starf {0} hefur verið virkjað.',
  'job.statistics.all': 'Frá upphafi',
  'job.statistics.seven': 'Síðustu 7 daga',
  'job.statistics.thirty': 'Síðustu 30 daga',
  'job.statistics.today': 'Í dag',
  'job.statistics.from': 'Tímabil:',
  'job.tips.notvisible': 'Starfið er falið (sést ekki á ráðningarsíðu)',
  'job.tips.visible': 'Starfið er virkt og birt á ráðningarsíðu',
  'job.type.employee': 'Starfsmaður',
  'job.type.intern': 'Starfsnemi',
  'job.type.not_specified': 'Á ekki við',
  'job.type.seasonal': 'Árstíðabundin',
  'job.type.temporary': 'Tímabundin',
  'job.unpublish.error': 'Ekki tókst að taka starf {0} úr birtingu. Vinsamlegast reynið aftur.',
  'job.unpublish.success': 'Starf {0} hefur verið tekið úr birtingu.',
  'job.update.description.error': "Ekki tókst að uppfæra starfslýsingu. Vinsamlegast reynið aftur.",
  'job.update.description.success': "Starf hefur verið uppfært",
  'job.update.followers.error': "Ekki tókst að uppfæra ráðningarteymi. Vinsamlegast reynið aftur.",
  'job.update.followers.success': "Ráðningarteymi hefur verið uppfært",
  'job.update.form.error': "Ekki tókst að uppfæra umsóknarform. Vinsamlegast reynið aftur.",
  'job.update.form.success': "Umsóknarform hefur verið uppfært.",
  'job.update.rewards.error': "Ekki tókst að uppfæra lýsingu verðlauna. Vinsamlegast reynið aftur.",
  'job.update.rewards.success': "Verðlaun hafa verið uppfærð.",
  'job.options.archive': 'Setja í geymslu',
  'job.options.drafts': 'Færa í drög',
  'job.options.duplicate': 'Afrita',
  'job.options.edit': 'Breyta',
  'job.options.publish': 'Setja í birtingu',
  'job.options.share': 'Deila',
  'job.options.unpublish': 'Taka úr birtingu',
  'job.options.delete': 'Eyða',
  'job.options.delete.confirmation': 'starfi og öllum umsóknum sem það hefur að geyma',
  'job.options.export': 'Flytja út',
  'job.store.delete.error': "Ekki tókst að eyða {0}. Vinsamlegast reynið aftur.",
  'job.store.delete.success': "Starfi {0} var eytt.",
  'jobsettings.tab.applicants': 'Umsækjendur',
  'jobsettings.tab.integrations': 'Samþættingar',
  'jobsettings.tab.statistics': 'Tölfræði',
  'jobsettings.tab.team': 'Notendur',
  'navigation.careersite': 'Ráðningarsíða',
  'navigation.dashboard': 'Yfirsýn',
  'navigation.applicants': 'Umsækjendur',
  'navigation.onboarding': 'Onboarding',
  'navigation.sharing': 'Deila',
  'navigation.team': 'Notendur',
  'navigation.expand': 'Festa hlið',
  'navigation.collapse': 'Losa hlið',
  'navigation.companies': 'Fyrirtæki',
  'templates.store.newtasklist.name': 'Nýr aðgerðarlisti',
  'templates.store.removetasklist.error': 'Ekki tókst að fjarlægja sniðmát. Vinsamlegast reynið aftur.',
  'templates.store.removetasklist.success': 'Sniðmát fyrir aðgerðarlista hefur verið fjarlægt',
  'user.grant.error': 'Ekki tókst að gefa notanda {0} aðgang að umsókn {1}. Vinsamlegast reynið aftur.',
  'user.grant.success': 'Notandi {0} hefur verið veitt aðgang að umsókn {1}.',
  'user.inactive': 'óvirk',
  'user.revoke.error': "Ekki tókst að fjarlægja réttindi. Vinsamlegast reynið aftur.",
  'user.revoke.success': "{0}'s access to {1} successfully revoked.",
  'user.role.admin': 'Stjórnandi',
  'user.role.limited': 'Takmarkaður notandi',
  'user.role.recruiter': 'Ráðningarstjóri',
  'user.role.user': 'Almennur starfsmaður',
  'usermenu.careerpage': 'Ráðningarsíða',
  'usermenu.notifications': 'Tilkynningastillingar',
  'usermenu.company.settings': 'Fyrirtækjastillingar',
  'usermenu.company.templates': 'Fyrirtækjasniðmát',
  'usermenu.company.templates.emails': 'Tölvupósts sniðmát',
  'usermenu.company.templates.tasks': 'Aðgerðir',
  'usermenu.company.apps': 'Apps / Samþættingar',
  'usermenu.help': 'Hjálp',
  'usermenu.profile': 'Prófíll',
  'usermenu.signout': 'Útskráning',
  'userstore.delete.error': 'Ekki tókst að eyða notanda. Vinsamlegast reynið aftur.',
  'userstore.delete.success': 'Notanda eytt.',
  'job.header.applicants': 'Umsækjendur',
  'job.header.today': 'Í dag',
  'job.followermenu.title': 'Ráðningarteymi',
  'application.onboardmodal.title': 'Innleiða umsækjanda',
  'application.onboardmodal.confirm': 'Viltu hefja innleiðingu nýs starfsmanns <strong>{0}</strong> núna?',
  'application.onboardmodal.button.later': 'Seinna',
  'application.onboardmodal.button.go': 'Fara á onboarding svæði',
  'rejectmodal.types.reject': 'Hafna',
  'rejectmodal.types.rejecthelp': 'Merkja starfsmann sem hafnað. Engin tilkynning er send á umsækjanda.',
  'rejectmodal.types.rejectsend': 'Hafna og senda tilkynningarpóst.',
  'rejectmodal.types.rejectsendhelp': 'Senda höfnunarpóst til valinna umsækjenda.',
  'rejectmodal.types.rejectmark': 'Merkja sem hafnað og tilkynnt',
  'rejectmodal.types.rejectmarkhelp': 'Merkja starfsmann sem hafnað og tilkynnt. Engin tilkynning er send á umsækjanda.',
  'rejectmodal.title': 'Hafna umsækjendum.',
  'rejectmodal.confirm.single': 'Ertu viss um að þú viljir hafna <strong>{0}</strong>?',
  'rejectmodal.confirm.multi': 'Ertu viss um að þú viljir hafna eftirfarandi umsækjendum?',
  'rejectmodal.confirmed.reject': 'Eftirfarandi umsækjendur verða merktir sem hafnaðir:',
  'rejectmodal.confirmed.rejectsend': 'Eftirfarandi umsækjendur fá höfnunarpóst:',
  'rejectmodal.confirmed.rejectmark': 'Eftirfarandi umsækjendum verður hafnað og merktir sem "Tilkynnt":',
  'rejectmodal.excluded': 'Eftirfarandi umsækjendur verða ekki með í þessari aðgerð:',
  'rejectmodal.already.notified': '(þegar hafnað og tilkynnt',
  'rejectmodal.already.rejected': '(þegar hafnað)',
  'rejectmodal.method.title': 'Höfnunarleið',
  'rejectmodal.method.drop.title': 'Veldu höfnunarleið',
  'rejectmodal.button.cancel': 'Hætta við',
  'rejectmodal.button.submit': 'Hafna',
  'assignmodal.button.cancel': 'Hætta við',
  'assignmodal.button.submit': 'Úthluta',
  'assignmodal.header': 'Úthluta umsækjanda',
  'assignmodal.description': 'Úthluta umsækjanda á notanda',
  'assignmodal.select.emptystate': 'Veldu notanda',
  'rejectform.label.from': 'Frá',
  'rejectform.label.message': 'Skilaboð',
  'rejectform.label.templateselect': 'Nafn sniðmáts',
  'rejectform.template.drop.title': 'Veldu sniðmát',
  'rejectform.error.message': 'Vinsamlegast bættu við meginmálstexta í tölvupóstinn.',
  'editor.placeholder.title': 'Staðsetningartexti',
  'editor.placeholder.name': 'Nafn umsækjanda',
  'editor.placeholder.job_title':'Starfstitill',
  'editor.placeholder.sender_name': 'Nafn sendanda',
  'editor.placeholder.first_name': 'Fornafn umsækjanda',
  'editor.placeholder.last_name': 'Eftirnafn umsækjanda',
  'sharejob.header': 'Deila starfi',
  'sharejob.copy.header': 'beinn hlekkur starfs',
  'copy.button.text': 'afrita',
  'copy.button.copied': 'afritun tókst',
  'sharejob.maintext': 'Þetta er varanlegur hlekkur sem vísar til 50skills auglýsingar þinnar. Hlekkurinn breytist ekki þegar þú breytir heiti atvinnuauglýsingarinnar. Þetta er ráðlögð leið til að deila starfinu þínu.',
  'sharejob.share.media': 'Share via <strong>{0}</strong>',
  'sharejob.rewards.header': 'Verðlaun fyrir ráðinn umsækjanda',
  'userlist.header.name': 'Nafn',
  'userlist.header.email': 'Netfang',
  'userlist.header.role': 'Hlutverk',
  'userlist.header.access': 'aðgangur',
  'userlist.header.status': 'staða',
  'userlist.header.options': 'valmöguleikar',
  'application.options.email': 'Senda tölvupóst',
  'application.options.move': 'Færa í annað starf',
  'application.options.copy': 'Afrita í annað starf',
  'application.options.delete': 'Eyða',
  'application.options.reject': 'Hafna',
  'application.options.unreject': 'Af-hafna',
  'application.options.hire': 'Ráða',
  'application.options.unhire': 'Af-ráða',
  'application.options.retract': 'Merkja umsókn sem dregin til baka',
  'application.options.unretract': 'Af-merkja umsókn sem dregin til baka',
  'application.options.onboard': 'Onboarding',
  'application.tip.assign': 'Úthluta á notanda',
  'modals.customconfirm.title': 'Staðfesta',
  'modals.customconfirm.warning': 'Þessa aðgerð er ekki hægt að afturkalla!',
  'modals.customconfirm.button.cancel': 'Hætta við',
  'modals.customconfirm.button.submit': 'Staðfesta',
  'modals.removeconfirm.title': 'Staðfesta eyðingu',
  'modals.removeconfirm.confirm': 'Ertu viss um að þú viljir eyða þessari umsókn?',
  'modals.removeconfirm.warning': 'Warning, this action can not be undone!',
  'modals.removeconfirm.button.cancel': 'Hætta við',
  'modals.removeconfirm.button.submit': 'Eyða',
  'job.create.crumb.new': 'Ný starfsauglýsing',
  'job.create.crumb.editing': 'Breyta starfsauglýsingu',
  'job.create.step.1': 'Starfsslýsing',
  'job.create.step.1.mobile': '1',
  'job.create.step.2': 'Umsóknarform',
  'job.create.step.2.mobile': '2',
  'job.create.step.3': 'Verðlaun',
  'job.create.step.3.mobile': '3',
  'job.create.step.4': 'Ráðningarteymi',
  'job.create.step.4.mobile': '4',
  'job.create.step.5': 'Forskoðun og birting',
  'job.create.step.5.live': 'Forskoðun',
  'job.create.step.5.mobile': '5',
  'job.create.form.header': 'Umsóknarform',
  'job.create.form.show': 'Sýna',
  'job.create.form.required': 'Nauðsynlegt',
  'job.create.form.social.header': 'Samfélagsmiðlar',
  'job.create.form.questions.header': 'Spurningar',
  'job.create.form.button.addquestion': 'Bæta við spurningu',
  'job.create.form.label.coverletter': 'Kynningarbréf',
  'job.create.form.label.cv': 'Ferilskrá',
  'job.create.form.label.name': 'Nafn',
  'job.create.form.label.email': 'Netfang',
  'job.create.form.label.phone': 'Símanúmer',
  'job.create.form.label.ssn': 'Kennitala',
  'job.create.form.label.language': 'Tungumál umsóknar',
  'job.create.form.questions.type.short': 'Stutt svar',
  'job.create.form.questions.type.para': 'Langt svar',
  'job.create.form.questions.type.single': 'Fellilisti - eitt val',
  'job.create.form.questions.type.multi': 'Fellilisti - fjölval',
  'job.create.form.questions.type.attachment': 'Viðhengi (skrá)',
  'job.create.form.questions.title.edit': 'Breyta spurningu',
  'job.create.form.questions.title': 'Bæta við spurningu',
  'job.create.form.questions.type.label': 'Tegund spurningu',
  'job.create.form.questions.qlabel': 'Spurning',
  'job.create.form.questions.qerror': 'Hvað viltu vita?',
  'job.create.form.questions.qopts': 'Valmöguleikar',
  'job.create.form.questions.button.cancel': 'Hætta við',
  'job.create.form.questions.button.submit': 'Bæta við spurningu',
  'job.create.form.questions.button.submitedit': 'Uppfæra spurningu',
  'job.create.form.questions.option.edit': 'Breyta',
  'job.create.form.questions.option.moveup': 'Færa upp',
  'job.create.form.questions.option.movedn': 'Færa niður',
  'job.create.form.questions.option.delete': 'Eyða',
  'job.create.form.questions.seeoptions': 'Smelltu til að sjá valmöguleika',
  'job.create.form.options.tip.remove': 'Fjærlægja valmöguleika',
  'job.create.form.options.tip.add': 'Bæta við valmöguleika fyrir neðan',
  'job.create.form.options.empty': 'Valmöguleiki á ekki að vera tómur',
  'job.create.header': 'Búa til starfsauglýsingu',
  'job.create.header.edit': 'Breyta starfsauglýsingu',
  'job.create.description.header': 'Starfsslýsing',
  'job.create.button.cancel': 'Hætta við',
  'job.create.button.save': 'Vista',
  'job.create.button.back': 'Fara til baka',
  'job.create.button.savedraft': 'Vista sem drög',
  'job.create.button.cont': 'Halda áfram',
  'job.create.button.savecont': 'Vista og halda áfram',
  'job.create.label.title': 'Starfstitill',
  'job.create.label.location': 'Staðsetning',
  'job.create.label.type': 'Tegund starfs',
  'job.create.label.status': 'Staða starfs',
  'job.create.label.deadline': 'Umsóknarfrestur (valkvætt)',
  'job.create.label.short': 'Stutt lýsing',
  'job.create.label.visibility': 'Sýnileiki starfs',
  'job.create.short.error': 'Nauðsynlegt er að fylla út stutta lýsingu',
  'job.create.save.language.error': 'Ekki tókst að vista starfsauglýsingu. Þetta er starf er í birtingu og kveikt er á {0} og inniheldur villu. Vinsamlegast lagfærið villu eða afvirkið tungumál.',
  'job.create.location.error': 'Nauðsynlegt er að fylla staðsetningu',
  'job.create.title.error': 'Nauðsynlegt er að fylla inn starfstitil',
  'job.create.label.long': 'Um starfið',
  'job.create.preview.button.close': 'Loka',
  'job.create.preview.button.edit': 'Breyta starfsauglýsingu',
  'job.create.preview.button.publish': 'Birta starfsauglýsingu',
  'job.create.preview.text': 'forskoðun',
  'job.create.preview.openings': 'störf í boði',
  'job.create.preview.apply': 'sækja um starf',
  'job.create.linkedField.tip': 'Hlekkur milli tungumála',
  'job.create.publish.language.error': 'Ekki tókst að birta starfsauglýsingu. Tungumál {0} er virkt en þýðing hefur ekki verið kláruð.',
  'job.create.publish.no_enabled_language': 'Ekki tókst að birta starfsauglýsingu. Engin virk tungumál eru sett á starfsauglýsinguna.',

  'job.create.languages.missing.title': 'Titill',
  'job.create.languages.missing.description': 'Starfslýsing',
  'job.create.languages.missing.short': 'Stutt lýsing',
  'job.create.languages.missing.location': 'Staðsetning',
  'job.create.languages.missing.form': 'Spurningaform',
  'job.create.languages.header': 'Tungumál',
  'job.create.languages.add': 'Bæta við tungumáli',
  'job.create.languages.formIncomplete': 'Form óklárað',
  'job.create.languages.descIncomplete': 'Lýsing ókláruð',
  'job.create.languages.menu.publish': 'Virkja tungumál',
  'job.create.languages.menu.unpublish': 'Afvirkja tungumál',
  'job.create.languages.menu.delete': 'Fjarlægja tungumál',
  'job.create.languages.publish.incomplete': 'Ekki var hægt að virkja {0} þar sem tungumálagögnin eru óútfyllt. Vinsamlegast bættu við gögnum þar sem vantar og reyndu að virkja aftur. Óklárað: {1}',
  'job.create.languages.publish.error': 'Ekki tókst að virkja {0}. Vinsamlegast reynið aftur.',
  'job.create.languages.publish.success': '{0} hefur verið virkjað.',
  'job.create.languages.unpublish.onlyone': 'Ekki tókst að afvirkja {0}. Starfið er í birtingu og er bara með eitt tungumál á sér.',
  'job.create.languages.unpublish.error': 'Ekki tókst að afvirkja {0}. Vinsamlegast reynið aftur.',
  'job.create.languages.unpublish.success': '{0} hefur verið afvirkjað.',
  'job.create.languages.delete.error': 'Ekki tókst að fjarlægja tungumál: {0}',
  'job.create.languages.delete.success': 'Tungumál fjarlægt.',
  'job.create.missing.question': 'Enginn spurningartexti hefur verið settur',
  'job.create.languages.enabled': 'Þetta tungumál er virkt en ekki í birtingu',
  'job.create.languages.enabled.live': 'Þetta tungumál er virkt og í birtingu',
  'job.create.languages.disabled': 'Þetta tungumál er óvirkt',

  'job.types.employee': 'Starfsmaður',
  'job.types.temporary': 'Tímabundið / Samningur',
  'job.types.intern': 'Starfsnemi',
  'job.types.seasonal': 'Árstíðabundin',
  'job.types.not_specified': 'Á ekki við',
  'job.status.full_time': 'Full vinna',
  'job.status.part_time': 'Hlutastarf',
  'job.status.per_diem': 'Dagpeningur',
  'job.status.not_specified': 'Á ekki við',
  'job.visibility.public': 'Sýna á ráðningarsíðu',
  'job.visibility.private': 'Ekki sýna starf á ráðningasíðu. Bara hægt að sækja um starf í gegnum beinan hlekk.',

  'job.create.publish.modal.title': 'Starf hefur verið sett í birtingu',
  'job.create.publish.modal.tagline': 'Deildu því með heiminum!',
  'job.create.publish.modal.direct.title': 'Beinn hlekkur (fyrir starfatorg osfrv.)',
  'job.create.publish.modal.direct.title.single': 'Beinn hlekkur',
  'job.create.publish.modal.en': 'Ensk starfslýsing',
  'job.create.publish.modal.is': 'Íslensk starfslýsing',
  'job.create.publish.modal.direct.head': 'Beinn hlekkur',
  'job.create.publish.modal.direct.sub': 'fyrir starfatorg osfrv.',
  'job.create.publish.modal.refer.title': 'Tilvísun',
  'job.create.publish.modal.refer.head': 'Sérstakur tilvísunartengill',
  'job.create.publish.modal.button.close': 'Loka',
  'job.create.rewards.title': 'Verðlaun',
  'job.create.rewards.tagline': 'Veldu verðlaun fyrir ykkar starfsmenn ef að þeirra deiling leiðir til ráðningar.',
  'job.create.rewards.toggle': 'Verðlaun fyrir ráðningu',
  'job.create.rewards.label.title': 'Titill',
  'job.create.rewards.label.reward': 'Upphæð eða verðlaun',
  'job.create.recruiters.title': 'Ráðningarteymi',
  'job.create.recruiters.pitch': 'Bæta við notendum í ráðningarteymi þessara starfsauglýsingu. Þessir notendur munu geta séð þessa starfsauglýsingu og allar umsóknir sem henni berast. Það er alltaf hægt að bæta við notendum eftir á!',
  'job.create.recruiters.search.placeholder': 'Leita',
  'dashboard.nojobs': 'Engin {0} störf',
  'dashboard.alljobs': 'Öll {0} störf munu birtast hér',
  'dashboard.nosearchjobs': 'Ekkert fannst',
  'dashboard.nosearchjobs.subtitle': 'Engar niðurstöður fundust',
  'dashboard.tabs.mobile.display': 'Sýna: {0}',
  'dashboard.search.placeholder': 'Leita',
  'dashboard.search.tip': 'Leita að starfi',
  'dashboard.tabs.jobs': 'Störf',
  'dashboard.tabs.apps': 'Apps',
  'forgotpw.reseterror': 'Ekkert netfang fannst',
  'forgotpw.button.submit': 'Endurstilla lykilorð',
  'forgotpw.button.submitting': 'Endurstilli...',
  'forgotpw.heading': 'Gleymdirðu lykilorðinu þínu?',
  'forgotpw.instruction': 'Skráðu inn netfangið þitt og við sendum á þig leiðbeiningar til að endurstilla lykilorðið þitt.',
  'forgotpw.label.email': 'Netfang',
  'forgotpw.button.back': 'Aftur á innskráningarsíðu',
  'forgotpw.emailsent.heading': 'Vinsamlegast athugaðu póstinn þinn',
  'forgotpw.emailsent.text': 'Hlekkur til að endurstilla lykilorðið þitt hefur verið sent á:',
  'setpw.error.notmatching': 'Lykilorðin eru ekki eins',
  'setpw.button.submit': 'Stilltu þitt lykilorð',
  'setpw.button.submitting': 'Set lykilorð...',
  'setpw.heading': 'Veldu lykilorð',
  'setpw.instruction': 'Þú munt nota netfangið þitt og lykilorð til að skrá þig inn í 50skills.',
  'setpw.label.password': 'Lykilorð',
  'setpw.label.confirm': 'Staðfesta lykilorð',
  'company.settings.crumb.root': 'Stillingar',
  'company.settings.tabs.company': 'Fyrirtæki',
  'company.settings.tabs.apps': 'Apps',
  'company.settings.apps.slack.cta': 'Smelltu á takkann hér fyrir neðan til að tengja 50skills við Slack',
  'company.settings.root.selectlangs': 'Velja tungumál',
  'company.settings.root.heading': 'Fyrirtækjaprófill',
  'company.settings.root.label.name': 'Nafn fyrirtækis',
  'company.settings.root.label.url': 'Fyrirtækjaslóð',
  'company.settings.root.label.langs': 'Tungumál ráðningarsíðu',
  'company.settings.root.label.lang': 'Sjálfgefið tungumál starfsauglýsingu',
  'company.settings.root.button.cancel': 'Hætta við',
  'company.settings.root.button.submit': 'Vista',
  'company.settings.save.empty.languages': 'Ekki tókst að vista. Vinsamlegast veldu að minnsta kosti eitt tungumál fyrir ráðningarsíðu.',
  'sharejob.careersite.maintext': 'Þetta er varanlegur hlekkur sem vísar til 50skills ráðningarsíðu þíns fyrirtækis. Þetta er ráðlögð leið til að deila störfum.',
  'sharejob.careersite.title': 'Deila ráðningarsíðu',
  'sharejob.header.crumb.root': 'Deila',
  'sharejob.header.crumb.careers': 'Ráðningarsíða',
  'sharejob.button.careers': 'Deila ráðningarsíðu',
  'sharejob.tips.reward': 'Verðlaun í boði fyrir tilvísun sem leiðir til ráðningar',
  'sharejob.tips.social': 'Deila á samfélagsmiðlum',
  'sharejob.button.submit': 'Deila',
  'signin.forgotpassword': 'Gleymdirðu lykilorðinu?',
  'signin.error': 'Rangt netfang eða lykilorð',
  'signin.button.submit': 'Skrá inn',
  'signin.button.submitting': 'Skrái inn notanda...',
  'signin.heading': 'Skrá inn',
  'signin.label.email': 'Netfang',
  'signin.label.password': 'Lykilorð',
  'slack.success.bot': 'Slack Bot tilbúinn',
  'slack.success.user': 'Slack aðgangur tengdur',

  'user.create.heading': 'Búa til nýjan notanda',
  'user.create.label.name': 'Nafn',
  'user.create.label.email': 'Netfang',
  'user.create.label.role': 'Hlutverk',
  'user.role.user.help': 'Hefur aðeins aðgang að ráðningarsíðu',
  'user.role.limited.help': 'Hefur aðeins aðgang að völdnum umsóknum',
  'user.role.recruiter.help': 'Getur séð og unnið með völdnum störfum',
  'user.role.admin.help': 'Full réttindi á að búa til og breyta störfum ásamt stjórnun notenda.',

  'user.create.sendinvite.toggle': 'Senda boð í tölvupósti',
  'user.create.addtonewjobs.toggle': 'Bæta sjálfvirkt við á ný störf',
  'user.create.button.cancel': 'Hætta við',
  'user.create.button.submit': 'Vista',

  'user.edit.update.errors': 'Ekki tókst að breyta notanda. Athugaðu villur í formi og reyndu aftur.',
  'user.edit.update.success': 'Notandi uppfærður',
  'user.edit.heading': 'Breyta notanda',
  'user.accessform.heading': 'Aðgangur',
  'user.accessform.applicants': 'Umsækjendur',
  'user.accessform.draft': 'Drög',
  'user.accessform.select': 'Velja allt',
  'user.accessform.deselect': 'Afvelja allt',

  'user.tabs.all': 'Allir notendur',
  'user.tabs.admins': 'Stjórnendur',
  'user.tabs.recruiters': 'Ráðningarstjórar',
  'user.tabs.limited': 'Takmarkaðir notendur',
  'user.tabs.employees': 'Almennir starfsmenn',
  'user.tabs.mobile.display': 'Sýna: {0}',
  'user.button.create': 'Bæta við notanda',
  'user.search.tip': 'Leita',

  'user.status.joined': 'Tengdur',
  'user.status.created': 'Stofnaður',
  'user.access.loading': 'Hleð...',
  'user.access.sharingonly': 'Einungis ráðningarsíða',
  'user.access.jobs.singular': 'starf',
  'user.access.jobs.plural': 'störf',
  'user.access.apps.singular': 'umsókn',
  'user.access.apps.plural': 'umsóknir',
  'user.options.edit': 'Breyta',
  'user.options.delete': 'Eyða',
  'user.crumbs.team': 'Teymi',
  'user.tabs.profile': 'Prófíll',
  'user.tabs.password': 'Lykilorð',
  'user.tabs.notifications': 'Tilkynningar',
  'user.changepw.heading': 'Breyta lykilorði',
  'user.changepw.label.old': 'Gamla lykilorðið',
  'user.changepw.label.new': 'Nýja lykilorðið',
  'user.changepw.label.new2': 'Staðfestu lykilorð',
  'user.changepw.button.cancel': 'Hætta við',
  'user.changepw.button.submit': 'Breyta',

  'user.notifications.emailHeading': 'Stillingar tölvupósts',
  'user.notifications.heading': 'Stillingar tilkynninga',
  'user.notifications.emailTableHeader': 'Senda tölvupóst þegar',
  'user.notifications.tableHeader': 'Senda tilkynningu þegar',
  'user.notifications.mail': 'Tölvupóstur',
  'user.notifications.desktop': 'Í forriti',
  'user.notifications.applicant_applies': 'Umsækjandi sækir um starf',
  'user.notifications.applicant_rated': 'Umsækjandi fær einkunn',
  'user.notifications.applicant_noted': 'Umsækjandi fær nýja umsögn',
  'user.notifications.applicant_rejected': 'Umsækjanda er hafnað',
  'user.notifications.applicant_assigned': 'Umsækjandi er úthlutaður á þig (assigned)',
  'user.notifications.applicant_hired': 'Umsækjandi er ráðinn',
  'user.notifications.applicant_moved': 'Umsækjandi er færður um dálk',
  'user.notifications.applicants_bulk_rejected': 'Umsækjendum er fjölhafnað (group reject)',
  'user.notifications.add_to_hiring_team': 'Þér er bætt í ráðningarteymi',
  'user.notifications.job_archived': 'Starf er sett í geymslu',
  'user.notifications.job_published': 'Starf er gefið út',
  'user.notifications.job_expired': 'Starf rennur út',
  'user.notifications.integration': 'Onboardin form er sent út eða skilað af umsækjanda',
  'user.notifications.save_notification': 'Stillingar hafa verið vistaðar',
  'notifications.title': 'Tilkynningar',
  'notifications.title.latest': 'Nýjustu',
  'notifications.title.mark_all_as_read': 'Merkja allar sem lesnar',
  'notifications.tooltips.mark_as_read': 'Merkja sem lesin',
  'notifications.tooltips.settings': 'Stillingar',
  'notifications.tooltips.close': 'Loka',
  'notifications.new_notifications.singular': 'Ný tilkynning',
  'notifications.new_notifications.plural': 'Nýjar tilkynningar',
  'notifications.single.applicant_hired': '{0} hefur merkt <strong>{1}</strong> sem ráðin/n',
  'notifications.multiple.applicant_hired': '{0} hefur merkt <strong>{1} umsækjendur</strong> sem ráðna',
  'notifications.single.applicant_rejected': '{0} hefur merkt <strong>{1}</strong> sem hafnað',
  'notifications.multiple.applicant_rejected': '{0} hefur merkt <strong>{1} umsækjendur</strong> sem hafnaða',
  'notifications.single.applicant_assigned': '{0} úthlutaði umsækjanda, <strong>{1}</strong>, á þig',
  'notifications.multiple.applicant_assigned': '{0} úthlutaði <strong>{1} umsækjendum</strong> á þig',
  'notifications.single.move_stage': '{0} færði <strong>{1}</strong> frá dálki {2} til {3}',
  'notifications.multiple.move_stage': '{0} færði <strong>{1} umsækjendur</strong> frá dálki {2} til {3}',
  'notifications.single.job_archived': '{0} setti starf í geymslu',
  'notifications.single.job_published': '{0} gaf út nýtt starf',
  'notifications.single.job_expired': 'Starf er runnið út',
  'notifications.single.add_to_hiring_team': '{0} bætti við <strong>{1}</strong> í ráðningarteymi',
  'notifications.multiple.add_to_hiring_team': '{0} bætti við <strong>{1} notendum</strong> í ráðningarteymi',
  'notifications.single.new_applications': 'Ný umsókn: <strong>{0}</strong>',
  'notifications.multiple.new_applications': '<strong>{0}</strong> nýjar umsóknir',
  'notifications.single.add_rating': '{0} setti inn nýja einkunn á umsækjanda <strong>{1}</strong>',
  'notifications.multiple.add_rating': '{0} setti inn nýja einkunn á <strong>{1} umsækjendur</strong>',
  'notifications.single.add_note': '{0} setti inn nýja umsögn á umsækjanda <strong>{1}</strong>',
  'notifications.multiple.add_note': '{0} setti inn nýja umsögn á <strong>{1} umsækjendur</strong>',
  'notifications.single.bulk_reject': '{0} hafnaði <strong>{1} umsækjendum</strong>',
  'notifications.integrations.onboarding_form.send': '{0} sendi onboarding form á <strong>{1}</strong>',
  'notifications.integrations.onboarding_form.finished': 'Nýr starfsmaður, <strong>{0}</strong>, hefur skilað inn onboarding formi',
  'notifications.single.message': 'Ný skilaboð á umsækjanda <strong>{0}</strong>',
  'notifications.multiple.message': '<strong>{0}</strong> ný skilaboð á umsækjendur',

  'user.profile.error.email': 'Ógilt netfang slegið inn.',
  'user.profile.error.generic': 'Ekki tókst að breyta notanda. {0} Vinsamlegast reynið aftur.',
  'user.profile.update.success': 'Notendastillingar vistaðar',
  'user.profile.heading': 'Prófíll',
  'user.profile.label.image': 'Prófílmynd',
  'user.profile.image.choose': 'Veldu mynd',
  'user.profile.image.nofile': 'Engin mynd valin',
  'user.profile.label.name': 'Nafn',
  'user.profile.label.email': 'Netfang',
  'user.profile.label.language': 'Tungumál',
  'user.profile.label.language.english': 'Enska',
  'user.profile.label.language.icelandic': 'Íslenska',
  'user.profile.button.cancel': 'Hætta við',
  'user.profile.button.submit': 'Vista',
  'user.social.heading': 'Samfélagsmiðlar',

  'company.templates.default.yes': 'Já',
  'company.templates.default.no': 'Nei',
  'company.templates.tasklist.header': 'Aðgerðalistar',
  'company.templates.crumbs.root': 'Sniðmát',
  'company.templates.tabs.tasks': 'Aðgerðir',
  'company.templates.tabs.emails': 'Tölvupóstar',
  'company.templates.addTemplate': 'Bæta við sniðmáti',
  'company.templates.options.edit': 'Breyta',
  'company.templates.options.delete': 'Eyða',
  'company.templates.update.success': 'Aðgerðarlisti uppfærður',
  'company.templates.update.error': 'Ekki tókst að breyta aðgerðarlista. Vinsamlegast reynið aftur.',
  'company.templates.create.success': 'Aðgerðarlisti stofnaður',
  'company.templates.create.error': 'Ekki tókst að stofna aðgerðarlista. Vinsamlegast reynið aftur.',
  'company.templates.tasks.create': 'Bæta við aðgerð',
  'company.templates.button.cancel': 'Hætta við',
  'company.templates.button.submit': 'Vista',
  'company.templates.tasks.label.title': 'Titill',
  'company.templates.type.applicant_new_application': 'Ný umsókn',
  'company.templates.type.rejection': 'Hafna',
  'company.templates.type.message': 'Skilaboð',
  'company.templates.email.button.cancel': 'Hætta við',
  'company.templates.email.button.save': 'Vista',
  'company.templates.email.button.back': 'Aftur tilbaka',
  'company.templates.email.create.error': 'Ekki tókst að búa til sniðmát. Vinsamlegast reynið aftur.',
  'company.templates.email.create.success': 'Sniðmát stofnað',
  'company.templates.email.delete.error': 'Ekki tókst að eyða sniðmáti. Vinsamlegast reynið aftur.',
  'company.templates.email.delete.success': 'Sniðmáti hefur verið eytt.',
  'company.templates.email.delete.text': 'Ertu viss um að þú viljir fjarlægja sniðmát<strong>{0}</strong>?',
  'company.templates.email.menu.delete': 'Eyða sniðmáti',
  'company.templates.email.menu.edit': 'Breyta sniðmáti',
  'company.templates.email.message.placeholder': 'Búa til nýtt sniðmát fyrir tölvupóst',
  'company.templates.email.title.placeholder': 'Veldu lýsandi titil á sniðmátið',
  'company.templates.email.update.error': 'Ekki tókst að uppfæra sniðmát. {0}.',
  'company.templates.email.update.success': 'Sniðmát uppfært',
  'company.templates.email.label.message': 'Skilaboð',
  'company.templates.email.label.title': 'Titill',
  'company.templates.email.label.type': 'Tegund',
  'company.templates.email.label.default': 'Sjálfvalið',
  'company.templates.email.label.options': 'Valmöguleikar',
  'company.templates.email.type.title': 'Veldu tegund',
  'company.templates.email.label.setdefault': 'Setja sem sjálfvalið',
  'company.integrations.configured_apps': 'Tengd apps',
  'company.integrations.upsell.title': 'Fleiri tengingar mögulegar',
  'company.integrations.none': 'Engin apps tengd',
  'company.integrations.upsell.body': 'Til að tengjast samskiptaforritum eins og Workplace og Slack eða tengjast mannauðslausnum, vinsamlegast hafið samband við okkur.',
  'company.integrations.detail.hired': 'Ráðin/n • {0} • af {1}',

  'applicants.none_found': 'Engir umsækjendur fundust',
  'applicants.filters.header': 'Síur á umsóknir',
  'applicants.filters.sub_header': 'Sýni {0} / {1} umsóknir',
  'applicants.filters.search.placeholder': 'Leita að umsækjendum',
  'applicants.filters.titles.show': 'Staða',
  'applicants.filters.date.label': 'Tímabil:',
  'applicants.filters.date.today': 'Í dag',
  'applicants.filters.date.past_seven': 'Síðustu 7 daga',
  'applicants.filters.date.past_thirty': 'Síðustu 30 daga',
  'applicants.filters.date.all_time': 'Frá upphafi',
  'applicants.filters.integrations.all': 'Allar',
  'applicants.filters.integrations.not_started': 'Ekki hafið',
  'applicants.filters.integrations.in_progress': 'Í vinnslu',
  'applicants.filters.integrations.completed': 'Lokið',
  'applicants.filters.active': 'Virkar umsóknir',
  'applicants.filters.sidebar.hired': 'Ráðin/n',
  'applicants.filters.sidebar.rejected': 'Hafnað',
  'applicants.filters.sidebar.retracted': 'Dregnar til baka',
  'applicants.filters.jobs.all': 'Öll',
  'applicants.filters.assignment.anyone': 'Hvern sem er',
  'onboarding.filters.header': 'Onboarding síur:',
  'onboarding.filters.sub_header': 'Sýni {0} ráðna umsækjendur',
  'onboarding.sidebar.title.integrations': 'Staða samþættingar',
  'onboarding.none_found.no_filters.header': 'Engir umsækjendur í on-boarding',
  'onboarding.none_found.filters.header': 'Engir umsækjendur fundust',
  'onboarding.none_found.no_filters.sub_header': 'Það eru engir umækjendur í on-boarding ferli',
  'onboarding.none_found.filters.sub_header': 'Engir umsækjendur finnast undir þessum síu stillingum',
  'job.filters.loading': 'Hleð...',
  'job.filters.numfilteredout': '{0} umsækjendur síaðir út',
  'job.filters.clear': 'Hreinsa síur',
  'job.filters.empty': 'Engir umsækjendur síaðir út',
  'job.tabs.pipeline': 'Dálkar',
  'job.tabs.applicants': 'Listasýn',
  'job.tabs.recruiters': 'Ráðningarteymi',
  'job.tabs.statistics': 'Tölfræði',
  'job.tabs.integrations': 'Apps',
  'job.actions.bulk': 'Fjölda aðgerðir',
  'job.actions.bulk.reject': 'Hafna',
  'job.actions.bulk.grant': 'Gefa aðgang',
  'job.actions.bulk.movetostage': 'Færa á milli dálka',
  'job.actions.bulk.message': 'Senda skilaboð',
  'job.actions.bulk.noselected': 'Engir umsækjendur valdnir',
  'job.addapplicant': 'Bæta við umsækjanda',
  'job.addrecruiter': 'Bæta við notanda',
  'errors.field.required': 'Nauðsynlegt er að fylla út þennan reit',
  'modals.addapplicant.header': 'Bæta við umsækjanda',
  'modals.addapplicant.label.name': '',
  'modals.addapplicant.button.cancel': 'Hætta við',
  'modals.addapplicant.button.submit': 'Vista',
  'modals.addapplicant.field.cv': 'Ferilskrá',
  'modals.addapplicant.field.cover': 'Kynningarbréf',
  'modals.addrecruiter.heading': 'Bæta við í ráðningarteymi',
  'modals.addrecruiter.select.empty': 'Engir notendur tiltækir',
  'modals.addrecruiter.select': 'Veldu notendur til að bæta við',
  'modals.addrecruiter.button.cancel': 'Hætta við',
  'modals.addrecruiter.button.submit': 'Bæta við',
  'job.applicants.empty': 'Engir umsækjendur',
  'job.applicants.msg.all': 'Allir umsækjendur munu birtast hér',
  'job.applicants.msg.rejected': 'Umsóknum sem verður hafnað munu birtast hér',
  'job.applicants.label.name' : 'Nafn',
  'job.applicants.label.email' : 'Netfang',
  'job.applicants.label.job' : 'Starf',
  'job.applicants.label.applied' : 'Sótt um',
  'job.applicants.label.stage' : 'Dálkur',
  'job.applicants.label.reviews' : '# Einkunnir',
  'job.applicants.label.avgrating' : 'Meðaleinkunn',
  'job.applicants.label.messages' : 'Skilaboð',
  'job.applicants.label.notes' : 'Umsagnir',
  'job.applicants.label.options' : 'Valmöguleikar',
  'job.applicants.label.status' : 'Staða',
  'onboarding.applicants.label.name' : 'Nafn',
  'onboarding.applicants.label.hired' : 'Ráðin/n',
  'onboarding.applicants.label.job' : 'Starf',
  'onboarding.applicants.label.status' : 'Onboarding staða',
  'onboarding.applicants.status': '{0} af {1} lokið',
  'onboarding.applicants.status.completed': 'Lokið',
  'onboarding.applicants.status.inprogress': 'Í vinnslu',
  'onboarding.applicants.status.notstarted': 'Ekki hafið',
  'application.label.sourced' : 'Sótt',
  'application.label.rejectionsent' : 'Höfnunartilkynning send',
  'application.label.retracted' : 'Dregin til baka',
  'application.label.new' : 'Ný',
  'job.statistics.bystage': 'Umsækjendur eftir dálki',
  'job.statistics.overview.summary': 'Samanlagt',
  'job.statistics.overview.applicants': 'Fjöldi umsókna',
  'job.statistics.overview.views': 'Heildarfjöldi skoðana',
  'job.statistics.overview.cleared': 'Umsóknir runnar út (GDPR)',
  'job.statistics.overview.status': 'Staða',
  'job.statistics.overview.hired': 'Ráðin/n',
  'job.statistics.overview.active': 'Virkar',
  'job.statistics.overview.rejected': 'Hafnað',
  'job.statistics.overview.source': 'Uppspretta',
  'job.statistics.overview.direct': 'Beinar',
  'job.statistics.overview.sourced': 'Sótt',
  'job.statistics.overview.manual': 'Handvirkt bætt við',
  'job.statistics.sourcing.heading': 'Virkustu starfsmenn í að finna umsóknir',
  'job.statistics.sourcing.empty': 'Engar umsóknir voru sóttar af starfsmönnum',
  'job.statistics.applicants': 'Umsækjendur',
  'job.statistics.hired': 'Ráðin/n',
  'job.statistics.traffic.heading': 'Uppspretta umsókna',
  'job.statistics.traffic.empty': 'Engar uppsprettur umsókna fundust',
  'job.filters.tip.stages': 'Dálkasýn',
  'job.filters.tip.list': 'Listasýn á umsækjendur',
  'job.filters.tip.toggle': 'Opna síur',
  'job.filters.tip.search': 'Leita',
  'job.sidebar.title.filters': 'Síur á störf',
  'job.sidebar.title.language': 'Tungumál',
  'job.sidebar.stagefilter.all': 'Allar',
  'job.sidebar.languagefilter.all': 'Allar',
  'job.sidebar.assignedtofilter.anyone': 'Hvern sem er',
  'job.sidebar.assignedtofilter.none': 'Engan',
  'job.sidebar.assignedtofilter.me': 'Mig',
  'job.sidebar.filters.showing': 'Sýni {0} / {1} umsækjendur',
  'job.sidebar.title.show': 'Sýni',
  'job.sidebar.active': 'Virkar',
  'job.sidebar.hired': 'Ráðin/n',
  'job.sidebar.rejected': 'Hafnað',
  'job.sidebar.retracted': 'Dróg til baka',
  'job.sidebar.title.labels': 'Merki',
  'job.sidebar.labels.none': 'Engin merki',
  'job.sidebar.labels.sourced': 'Sótt af',
  'job.sidebar.labels.rejectionsent': 'Höfnun send',
  'job.sidebar.title.avg': 'Meðaleinkunn',
  'job.sidebar.avg.none': 'Engar umsagnir',
  'job.sidebar.avg.strongy': 'Sterkt já',
  'job.sidebar.avg.yes': 'Já',
  'job.sidebar.avg.no': 'Nei',
  'job.sidebar.avg.defno': 'Sterkt nei',
  'job.sidebar.title.stage': 'Staða',
  'job.sidebar.title.assignment': 'Úthlutað á',
  'job.sidebar.title.byq': 'Eftir svari',
  'job.sidebar.title.job': 'Starf',
  'job.sidebar.title.jobs': 'Störf',
  'job.sidebar.removefilter': 'Hreinsa síur',
  'job.sidebar.chooseq': 'Veldu spurningu',
  'job.sidebar.choosea': 'Veldu svar',
  'modals.grantmulti.heading': 'Gefa aðgang',
  'modals.grantmulti.applicantsmsg': 'Ertu viss um að þú viljir gefa aðgang að eftirfarandi umsóknum:',
  'modals.grantmulti.usersmsg': 'Til eftirfarandi notenda:',
  'modals.grantmulti.users.select': 'Veldu þá notendur sem eiga að fá aðgang að þessum umsóknum',
  'modals.grantmulti.users.empty': 'Engir notendur tiltækir',
  'modals.grantmulti.button.cancel': 'Hætta við',
  'modals.grantmulti.button.submit': 'Gefa aðgang',
  'modals.movetostage.heading': 'Færa í dálk',
  'modals.movetostage.applicantsmsg': 'Ertu viss um að þú viljir færa eftirfarandi umsóknir:',
  'modals.movetostage.stagemsg': 'Í eftirfarandi dálk:',
  'modals.movetostage.stage.select': 'Veldu dálk til að færa umsóknir í',
  'modals.movetostage.button.cancel': 'Hætta við',
  'modals.movetostage.button.submit': 'Færa',
  'job.integrations.button.submit': 'Senda inn',
  'job.integrations.list.title': 'Apps',
  'job.integrations.list.empty': 'Enga samþættingur tiltækar. Ef þú hefur áhuga á að tengja þín kerfi við ráðningarferlið þá hafðu samband við okkur hérna í kerfinu eða í tölvupóst í gegnum info@50skills.com',
  'job.integrations.empty.title': 'Engar samþættingar stilltar',
  'job.integrations.empty.text': 'Ef þú vilt tengja innri samskiptahugbúnað fyrirtækisins t.d. Slack, Facebook Workplace eða annan svipaðan hugbúnað, vinsamlegast hafðu samband við okkur hérna í kerfinu eða í tölvupóst í gegnum info@50skills.com!',
  'job.applications.search.tip': 'Leita að umsækjanda',
  'application.onboarding.crumb.onboard' : 'Onboard',
  'application.onboarding.tabs.details' : 'Nánari upplýsingar',
  'application.onboarding.tabs.sourcing' : 'Verðlaun',
  'application.onboarding.tip.ssn_only': 'Nafn passar ekki við þjóðskrá ({0}) en kennitala er gild',
  'application.onboarding.tip.keep_or_match': 'Passar ekki við þjóðskrá ({0})',
  'application.onboarding.tip.error': 'Kennitala fannst ekki í þjóðskrá',
  'application.onboarding.applied': 'Sótti um',
  'application.onboarding.label.name': 'Nafn',
  'application.onboarding.label.phone': 'Símanúmer',
  'application.onboarding.label.email': 'Netfang',
  'application.onboarding.label.ssn': 'Kennitala',
  'application.onboarding.verifyssn.keepormatch': '<span>Nafn</span> passar ekki við upplýsingar úr þjóðskrá: {0}',
  'application.onboarding.button.keep': 'Halda nafni',
  'application.onboarding.button.match': 'Uppfæra í samræmi við þjóðskrá',
  'application.onboarding.button.verify': 'Staðfesta',
  'application.onboarding.button.again': 'Endurstaðfestu kennitölu',
  'application.onboarding.unverified': 'Staðfestu að þú hafir rétt <span>Nafn</span> og <span>Kennitölu</span> í gegnum þjóðskrá.',
  'application.onboarding.changetemplate.text' : 'Viltu breyta sniðmáti? Núverandi aðgerðarlisti verður fjarlægður.',
  'application.onboarding.removetemplate.text' : 'Viltu fjarlægja núverandi aðgerðarlista?',
  'application.onboarding.tasklist.nocreated' : 'Enginn aðgerðarlisti tiltækur',
  'application.onboarding.tasklist.create' : 'Stofna aðgerðarlista',
  'application.onboarding.tasklist.heading': 'Aðgerðarlisti',
  'application.onboarding.tasklist.choose': 'Veldu sniðmát',
  'application.onboarding.tasklist.remove': 'Fjarlægja aðgerðarlista',
  'application.onboarding.integration.save': 'Vista',
  'application.onboarding.integration.resend': 'Endursenda',
  'application.onboarding.integration.submit': 'Senda',
  'application.onboarding.integration.delete': 'Eyða',
  'application.onboarding.integration.preview': 'Skoða (preview)',
  'application.onboarding.rewards.header': 'Verðlaun',
  'application.onboarding.rewards.norewards': 'Engin verðlaun hafa verið stillt fyrir þetta starf',
  'application.onboarding.rewards.sourcedby': 'Sótt af',
  'application.onboarding.rewards.nosource': 'Enginn sótti þennan starfsmann.',
  'application.onboarding.mark_onboarded': 'Merkja sem onboarded',
  'application.onboarding.unmark_onboarded': 'Afmerkja sem onboarded',
  'job.recruiters.actions.revoke': 'Fjarlægja aðgang að starfi',
  'job.recruiters.access.isjobfollower': 'Allir umsækjendur',
  'job.recruiters.access.applicants': '{0} umsækjendur',
  'job.recruiters.editaccess.select': 'Veldu þá umsækjendur sem gefa skal aðgang að',
  'job.recruiters.editaccess.noselect': 'Engir umsækjendur tiltækir',
  'job.recruiters.editaccess.noapplicantsselected': 'Engir umsækjendur valdnir. Öll einstök aðgangsmál verða hreinsuð fyrir {0} í þessu starfi.',
  'job.recruiters.editaccess.button.cancel': 'Eyða',
  'job.recruiters.editaccess.button.submit': 'Vista',
  'job.recruiters.editaccess.heading': 'Breyta aðgangi fyrir umsókn {0}',
  'job.recruiters.editaccess.success': 'Fjarlægði aðgang {0} að {1} umsóknum.',
  'job.recruiters.editaccess.error': 'Ekki tókst að fjarlægja aðgang {0}\'s að {1} umsóknum. Vinsamlegast reynið aftur eða hafið samband við 50skills ef vandamálið leysist ekki.',
  'job.stages.loadingMore': 'Hleð fleirum...',
  'job.stages.noapplicants.header': 'Engir umsækjendur',
  'job.stages.noapplicants.text': 'Allir umsækjendur birtast hér',
  'job.stages.noapplicants.filtered.header': 'Engir umsækjendur fundust',
  'job.stages.noapplicants.filtered.text': 'Engir umsækjendur passa við þínar síu stillingar',
  'job.stages.menu.sort.title': 'Raða umsóknum eftir',
  'job.stages.menu.sort.name-asc': 'Nafn (Hækkandi) (A til Ö)',
  'job.stages.menu.sort.name-desc': 'Nafn (Lækkandi) (Ö til A)',
  'job.stages.menu.sort.newest': 'Dagsetning umsóknar (Nýjastar fyrst)',
  'job.stages.menu.sort.oldest': 'Dagsetning umsóknar (Elstu fyrst)',
  'job.stages.menu.sort.notes.highest': 'Fjöldi umsagna (Hæstu fyrst)',
  'job.stages.menu.sort.notes.lowest': 'Fjöldi umsagna (Lægstu fyrst)',
  'job.stages.menu.sort.avgvote.highest': 'Meðaleinkunn (Highest fyrst)',
  'job.stages.menu.sort.avgvote.lowest': 'Meðaleinkunn (Lægstu fyrst)',
  'job.stages.menu.sort.votecount.highest': 'Fjöldi umsagna (Hæstu fyrst)',
  'job.stages.menu.sort.votecount.lowest': 'Fjöldi umsagna (Lægstu fyrst)',
  'job.stages.menu.title': 'Valmöguleikar',
  'job.stages.menu.applicants': 'Umsækjendur',
  'job.stages.menu.rename': 'Nefna dálk',
  'job.stages.menu.sort': 'Raða...',
  'job.stages.menu.reject': 'Hafna öllum í dálki',
  'job.stages.menu.editname.tip.submit': 'Staðfesta',
  'job.stages.menu.editname.tip.cancel': 'Hætta við',
  'job.stages.menu.def.title': 'Dálkaaðgerðir',
  'job.stages.menu.def.sort': 'Raða umsóknum...',
  'job.stages.menu.def.reject': 'Hafna',
  'job.stages.mobile.displaytext': 'Dálkur: {0}',
  'application.gotoonboarding': 'Onboarding svæði',
  'application.access.addmodal.header': 'Gefa aðgang að stökum umsækjanda',
  'application.access.addmodal.maintext': 'Veittu notendum utan ráðningateymisins aðgang að þessari umsókn. Þeir munu aðeins geta séð þessa umsókn ásamt öðrum sem þeim hefur verið bætt á handvirkt í þessu starfi.',
  'application.access.addmodal.select': 'Veldu notendur til að gefa aðgang',
  'application.access.addmodal.noselect': 'Engir notendur tiltækir',
  'application.access.addmodal.button.cancel': 'Hætta við',
  'application.access.addmodal.button.submit': 'Bæta við',
  'application.gdpr.header': 'Umsókn runnin út',
  'application.gdpr.maintext': 'Vegna reglugerðar ESB um gagnavernd (GDPR) hafa gögn þessa umsækjanda runnið út og hafa þar af leiðandi verið fjarlægð.',
  'application.tabs.details': 'Nánari upplýsingar',
  'application.tabs.contact': 'Tengiliðsupplýsingar',
  'application.tabs.notes': 'Umsagnir',
  'application.tabs.messages': 'Skilaboð',
  'application.tabs.ratings': 'Einkunnir',
  'application.tabs.files': 'Skrár',
  'application.tabs.access': 'Aðgangsmál',
  'application.tabs.attachments': 'Viðhengi',
  'application.header.applied': 'Sótti um',
  'application.header.answers': 'Umsóknarsvör',
  'application.header.sourced': 'Sótt af',
  'application.header.access': 'Stakir aðgangar',
  'application.header.assignedto': 'Úthlutað á',
  'application.header.files': 'Skrár',
  'application.details.header.applied_to': 'Hefur sótt um • {0} önnur störf',
  'application.details.header.show_all': 'Sýna allt',
  'application.details.header.collapse': 'Fela',
  'application.details.header.cv': 'Ferilskrá',
  'application.details.header.cl': 'Kynningarbréf',
  'application.details.nocvpreview': 'Ekki tókst að forsýna ferilskrá',
  'application.details.noclpreview': 'Ekki tókst að forsýna kynningarbréf',
  'application.details.nocvuploaded': 'Engin ferilskrá var send inn með umsókn',
  'application.details.nocluploaded': 'Ekkert kynningarbréf var sent inn með umsókn',
  'application.details.noanswer': '(Ekkert svar gefið)',
  'application.attachment.nopreview': 'Ekki tókst að forsýna viðhengi',
  'application.attachment.download': 'Hala niður',
  'application.attachments.add.success': 'Skrá hefur verið bætt við',
  'application.attachments.add.error': 'Ekki tókst að bæta við nýrri skrá',
  'application.attachments.remove.success': 'Skrá hefur verið fjarlægð',
  'application.attachments.remove.error': 'Ekki tókst að fjarlægja skrá',
  'application.clicktodownload': 'Smelltu til að niðurhala skrá',
  'application.list.allstages': 'Allir dálkar',
  'application.list.cardlistname': 'Umsækjendur',
  'application.access.header': 'Ráðningarteymi',
  'application.access.roleheader': 'Hlutverk',
  'application.access.revoke': 'Fjarlægja',
  'application.access.revoking': 'Fjarlægi aðgang...',
  'application.access.noaccess': 'Umsækjandi er ekki sýnilegur af neinum utan ráðningateymisins.',
  'application.access.button.submit': 'Bæta við notanda',
  'application.header.contact': 'Tengiliðsupplýsingar',
  'application.contact.name': 'Fullt nafn',
  'application.contact.email': 'Netfang',
  'application.contact.phone': 'Símanúmer',
  'application.contact.ssn': 'Kennitala',
  'application.header.social': 'Samfélagsmiðlaprófíll',
  'application.contact.link': 'Hlekkur',
  'application.contact.nocontent': 'Þessu umsækjandi bætti ekki við neinum samfélagsmiðlum',

  'application.messages.header': 'Skilaboð',
  'application.messages.textbox.button.submit': 'Senda skilaboð',
  'application.messages.send.success': 'Skilaboð send',
  'application.messages.send.error': 'Ekki tókst að senda skilaboð',
  'application.messages.editor.placeholder': 'Senda skilaboð á umsækjandann í tölvupósti',
  'application.messages.footer.rejection': 'Hafna',
  'application.messages.footer.application': 'Umsókn',
  'application.messages.confirm': 'Eftirfarandi skilaboð verða send í tölvupósti á umsækjandann {0}:',
  'application.messages.send': 'Senda skilaboð',

  'application.notes.actions.edit': 'Breyta',
  'application.notes.actions.delete': 'Eyða',
  'application.notes.header': 'Umsagnir teymis',
  'application.reviews.nocontent': 'Þessi umsækjandi hefur engar umsagnir frá þínu teymi',
  'application.reviews.header.you': 'Þínar einkunnir',
  'application.reviews.header.team': 'Einkunnir teymis',
  'application.reviews.average': 'Meðaleinkunn: {0}',
  'application.notes.edit.button.cancel': 'Hætta við',
  'application.notes.edit.button.submit': 'Uppfæra',
  'application.notes.textbox.button.submit': 'Vista umsögn',
  'application.notes.textbox.admin_note.label': 'Stjórnenda umsögn',
  'application.notes.textbox.switch.admin': 'Stjórnenda umsögn',
  'application.notes.textbox.info.admin': 'Aðeins sýnileg stjórnendum',
  'application.notes.textbox.info.regular': 'Sýnileg ráðningarteymi',
  'application.notes.textbox.button.submit.regular': 'Vista umsögn',
  'application.notes.textbox.button.submit.admin': 'Vista stjórnenda umsögn',
  'application.notes.textbox.placeholder.regular': 'Bæta við umsögn eða notaðu @ merkið til að láta samstarfsmenn þína vita.',
  'application.notes.textbox.placeholder.admin': 'Bæta við stjórnenda umsögn á umsókn - Aðeins stjórnendur sjá þessar umsagnir',

  'application.removeconfirm.header': 'Staðfesta eyðingu',
  'application.removeconfirm.confirm': 'Ertu viss um að þú viljir eyða umsókn {0}?',
  'application.removeconfirm.warning': 'Þessi aðgerð er óafturkræf!',
  'application.removeconfirm.button.cancel': 'Hætta við',
  'application.removeconfirm.button.submit': 'Staðfesta',
  'application.rating.no': 'Nei',
  'application.rating.defno': 'Sterkt nei!',
  'application.rating.yes': 'Já',
  'application.rating.strongyes': 'Sterkt já!',
  'application.files.add': 'BÆTA VIÐ SKRÁ',
  'application.files.attached': 'hengdi við',
  'application.files.remove': 'Fjarlægja viðhengi',
  'application.files.hoveropen': 'Smelltu til að skoða skrá',
  'application.move.success': 'Umsókn fyrir {0} færð til {1}',
  'application.move.error': 'Ekki tókst að færa {0} til {1}. Vinsamlegast reynið aftur eða hafið samband við 50skills.',
  'application.copy.success': 'Umsókn fyrir {0} afrituð til {1}',
  'application.copy.error': 'Ekki tókst að afrita {0} til {1}. Vinsamlegast reynið aftur eða hafið samband við 50skills.',
  'application.copy.title': 'Afrita umsókn til',
  'application.move.title': 'Færa umsókn til',
  'application.movecopy.loadingjobs': 'Hleð störf...',
  'application.movecopy.selectjob': 'Velja starf',
  'application.movecopy.selectstage': 'Velja dálk',
  'application.movecopy.label.company': 'Fyrirtæki',
  'application.movecopy.label.job': 'Starf',
  'application.movecopy.label.stage': 'Dálkur',
  'application.movecopy.label.include': 'Innihalda gögn',
  'application.movecopy.label.notes': 'Umsagnir',
  'application.movecopy.label.files': 'Skrár',
  'application.movecopy.label.responses': 'Svör við spurningum',
  'application.movecopy.label.ratings': 'Einkunnir',
  'application.movecopy.responses.message': 'Þú munt verða beðin/n um að para saman spurningar milli starfanna í næsta skrefi.',
  'application.movecopy.warning.message': 'Allt sem er ekki hakað við mun tapast, þetta er óafturkræf aðgerð.',
  'application.movecopy.button.cancel': 'Hætta við',
  'application.movecopy.button.cont': 'Áfram',
  'application.movecopy.current.resp': 'Núverandi svör',
  'application.movecopy.new.resp': 'Ný svör',
  'application.movecopy.button.back': 'Til baka',
  'application.movecopy.button.move': 'Færa',
  'application.movecopy.button.copy': 'Afrita',
  'application.movecopy.addfile': 'Smelltu til að bæta við skrá',
  'application.movecopy.addresp': 'Smelltu til að bæta við svari',
  'application.confirmcopy.title': 'Ertu viss?',
  'application.confirmcopy.main': 'Allt sem er ekki við hakað mun tapast. Þú hefur valið að taka ekki með {0}',
  'application.confirmcopy.warn': 'Þessi aðgerð er óafturkræf.',
  'application.confirmcopy.cta': 'Viltu halda áfram?',
  'application.confirmcopy.back': 'Nei, fara til baka!',
  'application.confirmcopy.proc': 'Áfram',
  'showmore': 'Sýna meira',
  'companies.switchTo': 'Skipta í fyrirtæki: {0}',
  'companies.successfulSwitch': 'Skipting yfir í fyrirtæki: {0} tókst',
  'autocomplete.search.jobs': 'Byrjaðu að skrifa til að leita',
  'autocomplete.search.jobs.loading': 'Leita...',
  'autocomplete.search.jobs.notfound': 'Engin störf fundust'
};

