import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconShare = props => (
  <Svg {...props}>
    <path
      d="M10 4H5a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1v-5M10 14L20 4m0 0v5m0-5h-5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
