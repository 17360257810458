import React from 'react';
import { Svg32 } from 'components/icons/Svg';

export const IconIntegrationH3 = props => (
  <Svg32 {...props} viewBox="0 0 40 40">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.757 14.86V7.986L19.785 4.58l5.972 10.28z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 4.58l5.972-3.469 6.035 3.469H19.785z"
      fill="url(#paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.793 4.58v6.811l-6.036 3.469 6.035-10.28z"
      fill="url(#paint2_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 4.58v6.811l5.972 3.469-5.972-10.28z"
      fill="url(#paint3_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.757 14.86V7.986l6.035-3.406-6.035 10.28z"
      fill="url(#paint4_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.793 4.58l-6.036 3.406-5.972-3.406h12.008z"
      fill="url(#paint5_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.793 25.14l-6.036 3.469-5.972-3.469h12.008z"
      fill="url(#paint6_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.793 25.14v6.874l-6.036 3.47 6.035-10.344z"
      fill="url(#paint7_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.757 35.483V28.61l6.035-3.469-6.035 10.343z"
      fill="url(#paint8_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 25.14v6.874l5.972 3.47-5.972-10.344z"
      fill="url(#paint9_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.757 35.483V28.61l-5.972-3.469 5.972 10.343z"
      fill="url(#paint10_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 25.14l5.972-3.406 6.035 3.406H19.785z"
      fill="url(#paint11_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 14.86l6.035-3.469 5.972 3.469H13.75z"
      fill="url(#paint12_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.757 14.86v6.874l-5.972 3.406 5.972-10.28z"
      fill="url(#paint13_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 25.14v-6.874L13.75 14.86l6.035 10.28z"
      fill="url(#paint14_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 14.86v6.874l6.035 3.406-6.035-10.28z"
      fill="url(#paint15_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 25.14v-6.874l5.972-3.406-5.972 10.28z"
      fill="url(#paint16_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.757 14.86l-5.972 3.406-6.035-3.406h12.007z"
      fill="url(#paint17_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 25.14l-6.035 3.469-5.972-3.469h12.007z"
      fill="url(#paint18_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.778 25.14v-6.874l5.972-3.406-5.972 10.28z"
      fill="url(#paint19_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.778 25.14l5.972-3.406 6.035 3.406H7.778z"
      fill="url(#paint20_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 14.86v6.874L7.778 25.14l5.972-10.28z"
      fill="url(#paint21_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.785 25.14v6.874l-6.035 3.47 6.035-10.344z"
      fill="url(#paint22_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.757 35.483l-5.972 3.406-6.035-3.406h12.007z"
      fill="url(#paint23_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 35.483l6.035-3.468 5.972 3.468H13.75z"
      fill="url(#paint24_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.75 35.483V28.61l6.035-3.469-6.035 10.343z"
      fill="url(#paint25_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="22.769"
        y1="18.194"
        x2="22.769"
        y2="2.115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="19.771"
        y1="2.83"
        x2="31.765"
        y2="2.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="33.533"
        y1="14.436"
        x2="26.326"
        y2="11.97"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="22.79"
        y1="15.317"
        x2="22.472"
        y2="4.38"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="28.766"
        y1="18.157"
        x2="28.766"
        y2="6.392"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="18.204"
        y1="3.153"
        x2="21.133"
        y2="13.89"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="20.123"
        y1="23.778"
        x2="21.73"
        y2="30.709"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".453" stopColor="#5F943D" />
        <stop offset="1" stopColor="#AFD475" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="33.535"
        y1="35.059"
        x2="26.329"
        y2="32.624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="28.766"
        y1="38.781"
        x2="28.766"
        y2="27.013"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="17.054"
        y1="24.953"
        x2="31.634"
        y2="31.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="14.65"
        y1="24.941"
        x2="31.559"
        y2="28.542"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="16.703"
        y1="23.246"
        x2="30.094"
        y2="28.887"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".453" stopColor="#5F943D" />
        <stop offset="1" stopColor="#AFD475" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="13.774"
        y1="13.14"
        x2="25.767"
        y2="13.14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="27.537"
        y1="24.748"
        x2="20.335"
        y2="22.335"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="16.773"
        y1="28.506"
        x2="16.773"
        y2="12.423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="16.773"
        y1="23.967"
        x2="16.773"
        y2="11.45"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1="22.769"
        y1="28.47"
        x2="22.769"
        y2="16.701"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1="11.835"
        y1="13.976"
        x2="15.491"
        y2="24.786"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="3.829"
        y1="25.778"
        x2="14.725"
        y2="36.975"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1="10.776"
        y1="28.47"
        x2="10.776"
        y2="16.701"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1="7.778"
        y1="23.451"
        x2="19.771"
        y2="23.451"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE700" />
        <stop offset=".018" stopColor="#FFE401" />
        <stop offset=".344" stopColor="#F8A912" />
        <stop offset=".623" stopColor="#F47E1E" />
        <stop offset=".841" stopColor="#F16425" />
        <stop offset=".969" stopColor="#F05A28" />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="15.544"
        y1="24.748"
        x2="8.342"
        y2="22.335"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1="16.773"
        y1="34.964"
        x2="16.773"
        y2="23.948"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1="11.842"
        y1="30.995"
        x2="13.158"
        y2="40.285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="25.975"
        y1="31.904"
        x2="24.604"
        y2="41.373"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#008DAC" />
        <stop offset="1" stopColor="#3AC3DF" />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1="19.386"
        y1="32.919"
        x2="10.944"
        y2="30.066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C4007E" />
        <stop offset="1" stopColor="#471267" />
      </linearGradient>
    </defs>
  </Svg32>
);
