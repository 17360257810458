import { types as t, getRoot } from 'mobx-state-tree';
import { User } from 'models/users';

export const UserRef = t.maybe(
  t.reference(User, {
    get(identifier, parent) {
      const root = getRoot(parent);
      if (root.users) {
        return root.users.items.get(identifier);
      }
      return root.env.userStore.items.get(identifier);
    },
    set(value) {
      return value.id;
    }
  })
);
