import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconAssignee20 = props => (
  <Svg {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7a1 1 0 10.002-1.998A1 1 0 0010 7zm3-1a3 3 0 11-6 0 3 3 0 116 0zm-7.086 4.726C7.274 10.242 8.888 10 10 10s2.725.242 4.086.726c.68.242 1.361.567 1.892 1.003.531.435 1.022 1.08 1.022 1.938V16H3v-2.333c0-.858.491-1.503 1.022-1.938.53-.436 1.213-.76 1.892-1.003zm-.624 2.549c-.281.23-.29.363-.29.392V14h10v-.333c0-.029-.009-.161-.29-.392-.281-.23-.724-.461-1.295-.665C12.277 12.205 10.892 12 10 12c-.89 0-2.277.205-3.415.61-.571.204-1.014.434-1.295.665z"
      fill="currentColor"
    />
  </Svg>
);
