import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconSettings = props => (
  <Svg {...props}>
    <path
      d="M4 12h8M12 6h8M16 12h4M16 14v-4M12 18h8M12 20v-4M7 6H4M8 18H4M8 8V4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
