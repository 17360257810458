import { types as t, flow, applySnapshot, getEnv } from 'mobx-state-tree';

import { makeAuthedApiClient, CAREERS_URL } from 'api';
import { updateMSTObject } from 'helpers';
import { CompanyInfo, CompanyUISettings } from 'models/companies';
import { UserStore } from 'models/users';
import { AuthToken, PermissionStore } from 'models/auth';
import { JobStore } from 'models/jobs';
import { ApplicationStore, ApplicationPictureStore } from 'models/applications';
import { withEnv } from 'models/withEnv';
import { CompanyIntegrationStore } from 'models/integrations';
import { NotificationStore } from 'models/notifications';
import { ApplicantSearchStore } from 'models/applicantSearch';
import { OnboardingStore } from 'models/onboarding';
import { LoadingState } from 'models/ui';
import { TemplateStore } from 'models/templates';

export const Company = t
  .model('Company', {
    id: t.identifierNumber,
    info: t.maybe(CompanyInfo),
    token: AuthToken,
    jobs: t.optional(JobStore, {}),
    applications: t.optional(ApplicationStore, {}),
    users: t.optional(UserStore, {}),
    notifications: t.optional(NotificationStore, {}),
    applicantSearch: t.optional(ApplicantSearchStore, {}),
    onboarding: t.optional(OnboardingStore, {}),
    permissions: t.optional(PermissionStore, {}),
    templates: t.optional(TemplateStore, {}),
    applicationPictures: t.optional(ApplicationPictureStore, {}),
    integrations: t.optional(CompanyIntegrationStore, {}),

    uiSettings: t.optional(CompanyUISettings, {}),
    state: LoadingState
  })
  .views(withEnv)
  .views(self => ({
    get langs() {
      return self.info.langs;
    },
    get careersUrl() {
      return `${CAREERS_URL}/${self.info.name}`;
    },
    get api() {
      if (self.env.api) return self.env.api;
      const on401 = getEnv(self).signOut;
      return makeAuthedApiClient(self.token.raw, on401);
    },
    get rejectionTemplates() {
      return self.templates.emailTemplates.filter(t => t.templateType === 'rejection');
    },
    get messageTemplates() {
      return self.templates.emailTemplates.filter(t => t.templateType === 'message');
    }
  }))
  .actions(self => ({
    loadInfo: flow(function*() {
      const res = yield self.api.companies.load();
      if (res.status === 200) {
        self.info = res.data;
      }
    }),
    load: flow(function*() {
      self.state = 'loading';
      self.templates.load();
      if (self.token.role !== 'user') {
        self.notifications.load();
      } else {
        yield self.users.loadIndividual(self.token.userID);
      }

      yield self.users.load();
      self.users.setMe(self.token.userID);
      self.users.me.setUuid(self.token.uuid);
      self.state = 'loaded';
    }),
    loadTemplates: flow(function*() {
      const res = yield self.api.companies.loadTemplates();
      if (!res.error) {
        const data = res.data.filter(template => template.company.id === self.id);
        applySnapshot(self.templates, data);
      }
    }),
    update: flow(function*(data) {
      const res = yield self.api.companies.update(data);
      if (!res.error) {
        updateMSTObject(self.info, data);
        self.env.notify('success', self.env.t('company.update.success'));
        return true;
      } else {
        self.env.notify('error', self.env.t('company.update.error'));
        return false;
      }
    })
  }));
