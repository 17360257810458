import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { Icon50skills } from 'components/icons/Icon50skills';
import { Box } from 'components/Box';
import { H2, Text } from 'components/Text';
import { IconHamburger } from 'components/icons/IconHamburger';
import { getVisibleHeight } from 'shared/utils/browser';
import { IconClose } from 'components/icons/IconClose';
import { IconDashboard } from 'components/icons/IconDashboard';
import { IconApplicants } from 'components/icons/IconApplicants';
import { IconOnboarding } from 'components/icons/IconOnboarding';
import { IconNotifications } from 'components/icons/IconNotifications';
import { IconTeam } from 'components/icons/IconTeam';
import { IconShare } from 'components/icons/IconShare';
import { useStore } from 'models/Provider';
import { Separator } from 'components/Separator';
import { UserAvatar } from 'components/UserAvatar';

const items = [
  { icon: IconDashboard, path: '/jobs', label: 'navigation.dashboard' },
  { icon: IconApplicants, path: '/applicants', label: 'navigation.applicants' },
  { icon: IconOnboarding, path: '/onboarding', label: 'navigation.onboarding' },
  { icon: IconTeam, path: '/team', label: 'navigation.team' },
  { icon: IconShare, path: '/sharing', label: 'navigation.sharing' }
];

export const MobileHeader = observer(({ children }) => {
  const [open, setOpen] = React.useState(false);
  const {
    t,
    me,
    companies,
    current,
    switchCompany,
    authStore,
    uiSettings,
    notificationStore
  } = useStore();
  const toggleOpen = () => setOpen(old => !old);
  const close = () => setOpen(false);
  const maxHeight = getVisibleHeight();
  const switchableCompanies = companies.filter(
    company => Boolean(company.info) && company.id !== current.id
  );
  const unread = notificationStore.unread.length;
  return (
    <Container>
      <Box flex alignItems="center" color="white100">
        <Link to="/">
          <Icon50skills height="2rem" width="2rem" />
        </Link>
        <H2 ml="24" caps mr="24">
          {children}
        </H2>
      </Box>
      <Box flex alignItems="center">
        <MenuButton
          onClick={e => {
            e.stopPropagation();
            uiSettings.toggleNotifications();
          }}
        >
          {unread > 0 && <UnreadNotifications>{unread > 99 ? '99+' : unread}</UnreadNotifications>}
          <IconNotifications />
        </MenuButton>
        <Box ml="16">
          <MenuButton onClick={toggleOpen}>{open ? <LargerClose /> : <IconHamburger />}</MenuButton>
        </Box>
      </Box>
      <HeaderMenu open={open} maxHeight={maxHeight}>
        {items.map((item, index) => {
          let isActive = false;
          if (window.location.pathname.startsWith(item.path)) {
            isActive = true;
          } else if (index === 0) {
            // Looking at the dashboard that contains some sub urls.
            const dashpaths = ['/jobs', '/apps'];
            if (dashpaths.includes(window.location.pathname)) {
              isActive = true;
            }
          }
          return (
            <NavigationLink to={item.path} key={item.path}>
              <SideNavItem active={isActive}>
                <SideNavActiveBorder visible={isActive} />
                <item.icon />
                <SideNavLabel active={isActive}>{t(item.label)}</SideNavLabel>
              </SideNavItem>
            </NavigationLink>
          );
        })}
        <Separator bg="purple90" mt="24" mb="24" />
        <Box ml="24" flex alignItems="center">
          <UserAvatar size="lg" name={me.name} src={me.picture} />
          <Box ml="16">
            <Text type="semibold14" color="purple50">
              {me.name}
            </Text>
            <Text type="regular12" color="purple60">
              {me.email}
            </Text>
          </Box>
        </Box>
        <Link to={`/team/${me.id}/profile`} onClick={close}>
          <Text
            ml="24"
            mt="24"
            type="semibold14"
            color="purple50"
            children={t('usermenu.profile')}
          />
        </Link>
        <Link to="/settings/company" onClick={close}>
          <Text
            ml="24"
            mt="24"
            type="semibold14"
            color="purple50"
            children={t('usermenu.company.settings')}
          />
        </Link>
        <Link to="/templates/emails" onClick={close}>
          <Text
            ml="24"
            mt="24"
            type="semibold14"
            color="purple50"
            children={t('usermenu.company.templates')}
          />
        </Link>
        <a
          onClick={close}
          href={`${process.env.REACT_APP_CAREERS_URL}/${current.info.name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text
            ml="24"
            mt="24"
            type="semibold14"
            color="purple50"
            children={t('navigation.careersite')}
          />
        </a>
        {switchableCompanies.length > 0 && (
          <>
            <Separator bg="purple90" mt="24" mb="24" />
            {switchableCompanies.map(company => (
              <Text
                onClick={() => switchCompany(company.id)}
                key={company.id}
                ml="24"
                mt="24"
                type="semibold14"
                color="purple50"
                children={t('companies.switchTo', company.info.companyName)}
              />
            ))}
          </>
        )}
        <Separator bg="purple90" mt="24" mb="24" />
        <a
          rel="noopener noreferrer"
          href="https://help.50skills.com/"
          target="_blank"
          onClick={close}
        >
          <Text ml="24" mt="24" type="semibold14" color="purple50" children={t('usermenu.help')} />
        </a>
        <Separator bg="purple90" mt="24" mb="24" />
        <Text
          onClick={authStore.signOut}
          ml="24"
          mt="24"
          type="semibold14"
          color="purple50"
          children={t('usermenu.signout')}
        />
      </HeaderMenu>
    </Container>
  );
});

const NavigationLink = styled(Link)(p => ({
  ':focus': {
    outline: 'none',
    '[data-side-nav-item]': {
      color: p.theme.colors.green100
    }
  }
}));

const MenuButton = styled.button(p => ({
  color: p.theme.colors.purple50,
  position: 'relative',
  ':hover': {
    color: p.theme.colors.purple80,
    cursor: 'pointer'
  }
}));

const LargerClose = styled(IconClose)({
  width: '2rem',
  height: '2rem'
});

const Container = styled.div(p => ({
  zIndex: 100,
  position: 'relative',
  padding: '0 1rem',
  height: '4.5rem',
  width: '100%',
  backgroundColor: p.theme.colors.purple100,
  borderBottom: '1px solid ' + p.theme.colors.purple90,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const HeaderMenu = styled.div(p => ({
  transition: 'all 300ms ease-out',
  height: 'calc(100vh - 4.5rem)',
  opacity: p.open ? 1 : 0,
  maxHeight: p.open ? `calc(${p.maxHeight}px - 4.5rem)` : 0,
  overflow: p.open ? 'auto' : 'hidden',
  width: '100%',
  position: 'absolute',
  backgroundColor: p.theme.colors.purple100,
  color: p.theme.colors.white100,
  top: '4.5rem',
  left: 0,
  right: 0,
  bottom: 0,
  padding: p.open ? '1.5rem 0' : 0
}));

const SideNavLabel = styled.div.attrs({
  'data-nav-label': ''
})(p => ({
  transition: 'opacity 150ms ease-out',
  opacity: 1,
  marginLeft: '1rem',
  ...p.theme.text.semibold14,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'left'
}));

const SideNavItem = styled.div(p => ({
  position: 'relative',
  transition: 'all 200ms ease',
  color: p.active ? p.theme.colors.green100 : p.theme.colors.purple50,
  width: '100%',
  height: '3rem',
  padding: '0 1.5rem',
  display: 'flex',
  alignItems: 'center',
  ':focus': {
    outline: 'none'
  },
  ...(!p.active && {
    ':hover, :focus': {
      cursor: 'pointer',
      color: p.theme.colors.green100
    }
  }),
  svg: {
    minWidth: '1.5rem'
  }
}));

const SideNavActiveBorder = styled.div(p => ({
  transition: p.visible ? 'all 200ms ease-out' : 'all 100ms ease-out',
  backgroundColor: p.theme.colors.green100,
  position: 'absolute',
  left: 0,
  height: '3rem',
  transform: p.visible ? 'scaleY(1) translateY(0px)' : 'scaleY(0) translateY(1.5rem)',
  width: '0.25rem'
}));

const UnreadNotifications = styled.div(p => ({
  position: 'absolute',
  zIndex: 10,
  backgroundColor: p.theme.colors.green100,
  color: p.theme.colors.white100,
  ...p.theme.text.regular10,
  borderRadius: '0.125rem',
  padding: '0.25rem',
  left: '0.75rem',
  minWidth: '1rem',
  top: '-0.25rem',
  boxShadow: '2px 0px 4px rgba(0, 0, 0, 0.24)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
