import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import { useStore } from 'models/Provider';
import { Button } from 'components/Button';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { Loading } from 'components/Loading';

function CustomConfirmModal({ confirm, data, closeModal, onCancel, danger }) {
  const { t } = useStore();
  const [submitting, setSubmitting] = useState(false);

  function cancel() {
    if (onCancel) {
      onCancel();
    }
    closeModal();
  }

  async function submit() {
    setSubmitting(true);
    const success = await confirm(data.id);
    setSubmitting(false);
    if (success) {
      closeModal();
    }
  }
  return (
    <>
      <ModalHeader>{t('modals.customconfirm.title')}</ModalHeader>
      {submitting && <Loading />}
      <span>{data.text}</span>
      <ModalText as="p">
        <strong>{t('modals.customconfirm.warning')}</strong>
      </ModalText>
      <ModalActions>
        <Button gray onClick={!submitting ? cancel : null} disabled={submitting}>
          {t('modals.customconfirm.button.cancel')}
        </Button>
        <Button red={danger} onClick={!submitting ? submit : null} disabled={submitting}>
          {t('modals.customconfirm.button.submit')}
        </Button>
      </ModalActions>
    </>
  );
}

export default withRouter(observer(CustomConfirmModal));
