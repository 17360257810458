import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/macro';

export const Table = observer(
  ({
    columns,
    data,
    minTableWidth = '1000px',
    onRowClick,
    rowWrap: RowWrap,
    tight = false,
    onSort
  }) => {
    const tbodyRef = React.useRef();
    const [{ sorting, order }, setSorting] = useState(() => {
      const defaultSortingCol = columns.find(col => col.defaultSort !== undefined);
      if (defaultSortingCol) {
        return {
          sorting: defaultSortingCol.sortKey || defaultSortingCol.title,
          order: defaultSortingCol.defaultSort
        };
      }
      return '';
    });

    const columnCount = useMemo(
      () =>
        columns.reduce((sum, col) => {
          return sum + (col.width || 1);
        }, 0),
      [columns]
    );

    const sortBy = title => {
      if (sorting === title) {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setSorting({ sorting: title, order: newOrder });
        onSort && onSort({ sorting: title, order: newOrder });
      } else {
        setSorting({ sorting: title, order: 'asc' });
        onSort && onSort({ sorting: title, order: 'asc' });
      }
    };

    const renderData = useMemo(() => {
      if (onSort !== undefined) return data;
      const sorted = data.slice();
      if (sorting) {
        const sorter = columns.find(col => col.title === sorting).sorter;
        sorted.sort(sorter);
        if (order === 'desc') {
          sorted.reverse();
        }
        return sorted;
      }
      return data;
    }, [data, sorting, order, columns, onSort]);

    return (
      <TableContainer minWidth={minTableWidth}>
        <TableHead>
          <TableHeadRow>
            {columns.map((col, colIndex) => {
              const canSort = col.sorter !== undefined || col.sortKey !== undefined;
              const onClick = () => (canSort ? sortBy(col.sortKey || col.title) : null);
              return (
                <TableHeadColumn
                  canSort={canSort}
                  key={colIndex}
                  colWidth={calculateWidthForColumn(col, columnCount)}
                  onClick={onClick}
                  style={col.style}
                >
                  <div>{typeof col.title === 'function' ? col.title() : col.title || ''}</div>
                  {canSort && (sorting === col.sortKey || sorting === col.title) && (
                    <SortArrow asc={order === 'asc'} />
                  )}
                </TableHeadColumn>
              );
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody ref={tbodyRef}>
          {renderData.map((item, itemIndex) => {
            const row = (
              <TableBodyRow
                tight={tight}
                key={itemIndex}
                onClick={onRowClick ? () => onRowClick(item) : null}
              >
                {columns.map((col, colIndex) => (
                  <TableBodyColumn
                    key={colIndex}
                    colWidth={calculateWidthForColumn(col, columnCount)}
                    align={col.align}
                    style={col.style}
                  >
                    {col.render ? col.render(item) : col.dataKey ? item[col.dataKey] : '' || ''}
                  </TableBodyColumn>
                ))}
              </TableBodyRow>
            );
            if (!RowWrap) {
              return row;
            }
            return (
              <RowWrap item={item} key={itemIndex}>
                {row}
              </RowWrap>
            );
          })}
        </TableBody>
      </TableContainer>
    );
  }
);

const calculateWidthForColumn = (column, total) => {
  if (column.fixedWidth) return column.fixedWidth;
  return (((column.width || 1) / total) * 100).toString() + '%';
};

const TableContainer = styled.div(p => ({
  width: '100%',
  minWidth: p.minWidth
}));

const TableRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const TableBody = styled.div(p => ({
  color: p.theme.colors.purple80,
  fontSize: 14
}));

const TableHead = styled.div({});

const TableBodyRow = styled(TableRow)(p => ({
  backgroundColor: '#fff',
  borderRadius: 2,
  marginBottom: 4,
  paddingTop: p.tight ? 4 : 16,
  paddingBottom: p.tight ? 4 : 16,
  minHeight: 68,
  ...(p.onClick && { ':hover': { cursor: 'pointer' } })
}));

const TableHeadRow = styled(TableRow)(p => ({
  fontSize: 14,
  color: p.theme.colors.purple60,
  fontWeight: 600,
  textTransform: 'uppercase',
  padding: '0.5rem 0',
  '> div:first-child': {
    paddingLeft: 4,
    textAlign: 'left'
  },
  '> div:last-child': {
    paddingRight: 4,
    textAlign: 'right'
  }
}));

const TableColumn = styled.div(p => ({
  minWidth: p.colWidth || '100%',
  width: p.colWidth || '100%',
  padding: '0 16px'
}));

const TableHeadColumn = styled(TableColumn)(p => ({
  ...(p.canSort && { ':hover': { cursor: 'pointer' } }),
  display: 'flex',
  alignItems: 'center'
}));
const TableBodyColumn = styled(TableColumn)(p => ({
  textAlign: p.align || 'left'
}));

const SortArrow = styled.div(p => ({
  width: 0,
  height: 0,
  marginLeft: 4,
  transition: 'all 300ms ease',
  borderLeft: '4px solid transparent',
  borderRight: '4px solid transparent',
  borderTop: `6px solid ${p.theme.colors.purple50}`,
  ...(p.asc && { transform: 'rotate(180deg)' })
}));
