import { flow, getParent, types as t } from 'mobx-state-tree';

import { buildApplicationSearchQuery, sortJobApplications } from 'helpers';
import { ApplicationSortOrder } from 'models/jobs/JobSettings';
import { LoadingState } from 'models/ui';
import { Application } from 'models/applications';
import { withEnv } from 'models/withEnv';

export const JobListView = t
  .model('JobListView', {
    _applications: t.array(t.reference(t.late(() => Application))),
    order: t.optional(ApplicationSortOrder, 'date-desc'),
    next: t.maybeNull(t.string),
    state: t.optional(LoadingState, 'init'),
    minimumLimit: 20,
    selected: t.array(t.number),
    count: 0
  })
  .views(withEnv)
  .views(self => ({
    get applications() {
      return sortJobApplications(self._applications, self.order);
    },
    get job() {
      return getParent(self);
    },
    get anySelected() {
      return self.selected.length > 0;
    },
    get allSelected() {
      return self.selected.length > 0 && self.selected.length === self.applications.length;
    }
  }))
  .actions(self => ({
    removeApplication(id) {
      const idx = self._applications.findIndex(a => a.id === id);
      if (idx !== -1) {
        self._applications.splice(idx, 1);
      }
    },
    onSettingsUpdate() {
      self.load();
    },
    clearSelected() {
      self.selected.clear();
    },
    selectAll() {
      self.selected = self._applications.map(a => a.id);
    },
    toggleSelect(id) {
      const index = self.selected.indexOf(id);
      if (index === -1) {
        self.selected.push(id);
      } else {
        self.selected.splice(index, 1);
      }
    },
    loadNext: flow(function*() {
      if (self.state === 'loading') return;
      self.state = 'loading';
      const res = yield self.env.api.get(self.next);
      if (!res.error) {
        self.setDataFromResponse(res.data, false);
        self.state = 'loaded';
      } else {
        self.state = 'error';
      }
    }),
    load: flow(function*() {
      self.state = 'loading';
      const res = yield self.env.api.applications.search(
        buildApplicationSearchQuery(20, self.order, self.job, undefined, self.env.me)
      );
      if (!res.error) {
        self.setDataFromResponse(res.data, true);
        self.state = 'loaded';
      } else {
        self.state = 'error';
      }
    }),
    reset() {
      self._applications.clear();
      self.next = null;
      self.count = 0;
    },
    addApplication(id) {
      if (!self._applications.find(a => a.id === id)) {
        self._applications.push(id);
        self.count += 1;
      }
    },
    setOrder(order) {
      self.order = order;
      self.load();
    },
    setDataFromResponse(data, clear = false) {
      if (clear) {
        self.reset();
      }
      let appIds = [];

      data.results.forEach(a => {
        // @Migration: Remove this spread when the api stops sending
        // chat, attachments, messages and reviews.
        const { chat, attachments, messages, reviews, ...rest } = a;

        if (!self.env.applicationStore.items.has(a.id)) {
          appIds.push(a.id);
        }

        self.env.applicationStore.addOrUpdate(rest, self.job.id);
        self.addApplication(a.id);
      });
      self.count = data.count;
      self.next = data.next;

      self.job.loadPicturesForApplications(appIds);
      self.job.loadApplicationUserRead(appIds);
    }
  }));
