import { types as t, flow } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

export const ChangePassword = t
  .model('ChangePassword', {
    oldPassword: '',
    newPassword1: '',
    newPassword2: '',
    oldPasswordError: '',
    newPassword1Error: '',
    newPassword2Error: ''
  })
  .views(withEnv)
  .views(self => ({
    get valid() {
      return self.newPassword1 === self.newPassword2;
    },
    get valuesSet() {
      return self.newPassword1 !== '' && self.newPassword2 !== '' && self.oldPassword !== '';
    },
    get dirty() {
      return self.newPassword1 !== '' || self.newPassword2 !== '' || self.oldPassword !== '';
    }
  }))
  .actions(self => ({
    setOldPassword(pass) {
      self.oldPassword = pass;
      self.oldPasswordError = '';
    },
    setNewPassword1(pass) {
      self.newPassword1 = pass;
      self.newPassword1Error = '';
    },
    setNewPassword2(pass) {
      self.newPassword2 = pass;
      self.newPassword2Error = '';
    },
    reset() {
      self.oldPassword = '';
      self.newPassword1 = '';
      self.newPassword2 = '';
      self.oldPasswordError = '';
      self.newPassword1Error = '';
      self.newPassword2Error = '';
    },
    validateAndSubmit() {
      if (self.disable) return;
      if (!self.valid) {
        self.newPassword2Error = self.env.t('changepass.validate.pass2error');
        return;
      }
      self.submit();
    },
    submit: flow(function*() {
      const res = yield self.env.api.users.updatePassword({
        oldPassword: self.oldPassword,
        password1: self.newPassword1,
        password2: self.newPassword2
      });

      if (res.error) {
        self.env.notify('error', self.env.t('changepass.submit.error'));
        if (res.data.oldPassword) {
          self.oldPasswordError = res.data.oldPassword.join(' ');
        }
        if (res.data.password1) {
          self.newPassword1Error = res.data.password1.join('\n');
        }
        if (res.data.password2) {
          self.newPassword2Error = res.data.password2.join(' ');
        }
      } else {
        self.env.notify('success', self.env.t('changepass.submit.success'));
        self.reset();
      }
    })
  }));
