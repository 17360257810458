import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';

import { debounce } from 'helpers';

import { DynamicAutoComplete } from 'components/DynamicAutoComplete';

export const JobSearch = observer(({ includeArchived, onSelect }) => {
  const { api, t } = useStore();
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  const searchJobs = useCallback(
    debounce(searchStr => {
      let jobs = [];
      setLoading(true);
      api.jobs.search(searchStr, includeArchived).then(res => {
        if (!res.data.results.length) {
          jobs.push({
            label: t('autocomplete.search.jobs.notfound'),
            id: 'none',
            readOnly: true
          });
        } else {
          res.data.results.forEach(job => {
            jobs.push({ label: job.title, id: job.id });
          });
        }
        setJobList(jobs);
        setLoading(false);
      });
    }, 300),
    [api]
  );

  useEffect(() => {
    searchStr && searchJobs(searchStr);
  }, [searchStr, searchJobs]);

  let items = jobList;
  if (!searchStr) {
    items = [{ label: t('autocomplete.search.jobs'), id: 'more', readOnly: true }];
  } else if (loading) {
    items = [{ label: t('autocomplete.search.jobs.loading'), id: 'loading', readOnly: true }];
  }
  return (
    <DynamicAutoComplete
      initialValue={includeArchived ? t('applicants.filters.jobs.all') : ''}
      items={items}
      onChange={setSearchStr}
      onSelect={onSelect}
      onBlur={() => setJobList([])}
    />
  );
});
