import React, { useState, useEffect } from 'react';
import { RichUtils } from 'draft-js';
import { getSelectedBlock } from 'draftjs-utils';

import { ToolbarButton } from './ToolbarButton';
import { useToolbarContext } from './TextEditorToolbar';

export const ToolbarBlockStyleButton = ({ blockStyle, icon, title, ...rest }) => {
  const { getEditorState, setEditorState } = useToolbarContext();
  const [active, setActive] = useState(false);

  // eslint-disable-next-line
  useEffect(() => {
    const hasType = getSelectedBlock(getEditorState()).get('type') === blockStyle;
    hasType && !active && setActive(true);
    !hasType && active && setActive(false);
  });

  const toggle = e => {
    e.preventDefault();
    const newState = RichUtils.toggleBlockType(getEditorState(), blockStyle);
    setActive(old => !old);
    newState && setEditorState(newState);
  };

  return (
    <ToolbarButton
      onClick={toggle}
      active={active}
      title={title}
      onMouseDown={e => e.preventDefault()}
      {...rest}
    >
      {icon}
    </ToolbarButton>
  );
};
