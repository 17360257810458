import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import ClickOutside from 'shared/layout/ClickOutside';

import { useStore } from 'models/Provider';
import { UserAvatar } from 'components/UserAvatar';
import { useSideNavigation } from 'components/SideNavigation';
import { Box } from 'components/Box';
import { IntegrationIcon } from 'components/IntegrationIcon';
import { Tooltip } from 'components/Tooltip';
import { Text } from 'components/Text';
import { IconArrowBackward } from 'components/icons/IconArrowBackward';
import { IconButton } from 'components/IconButton';
import { IconNewHire } from 'components/icons/IconNewHire';
import { IconNewIndicator } from 'components/icons/IconNewIndicator';
import { IconSettings } from 'components/icons/IconSettings';
import { IconPersonAdd } from 'components/icons/IconPersonAdd';
import { IconCalendar } from 'components/icons/IconCalendar';

export const Notifications = observer(() => {
  const { notificationStore, uiSettings } = useStore();
  const { open: sidenavOpen } = useSideNavigation();

  const onOutsideClick = React.useCallback(() => {
    uiSettings.showNotifications && uiSettings.toggleNotifications();
  }, [uiSettings]);

  return (
    <ClickOutside onOutsideClick={onOutsideClick}>
      <Container open={uiSettings.showNotifications} sidenavOpen={sidenavOpen}>
        <NotificationsHeader />
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <NotificationList notifications={notificationStore.list} />
        </div>
      </Container>
    </ClickOutside>
  );
});

const NotificationsHeader = observer(() => {
  const { notificationStore, t, uiSettings } = useStore();
  const unread = notificationStore.unread.length;
  return (
    <HeaderContainer>
      <Tooltip id="notTip" place="right" />
      <Box flex justifyContent="space-between" alignItems="center">
        <div>
          <Text type="bold14" caps color="purple100">
            {t('notifications.title')}
          </Text>
          <Text type="regular14" color="purple50">
            {unread}{' '}
            {unread === 1
              ? t('notifications.new_notifications.singular')
              : t('notifications.new_notifications.plural')}
          </Text>
        </div>
        <Box flex>
          <Link
            data-for="notTip"
            data-tip={t('notifications.tooltips.settings')}
            to={notificationStore.settingsUrl}
            onClick={uiSettings.toggleNotifications}
          >
            <IconButton icon={IconSettings} />
          </Link>
          <Box ml="8">
            <IconButton
              data-for="notTip"
              data-tip={t('notifications.tooltips.close')}
              onClick={uiSettings.toggleNotifications}
              icon={IconArrowBackward}
            />
          </Box>
        </Box>
      </Box>
      <Box flex justifyContent="space-between" alignItems="center" mt="16">
        <Text type="bold14" caps color="purple80">
          {t('notifications.title.latest')}
        </Text>
        {unread > 0 && (
          <MarkRead
            onClick={e => {
              e.stopPropagation();
              notificationStore.markAllAsRead();
            }}
            type="regular14"
            color="purple50"
          >
            {t('notifications.title.mark_all_as_read')}
          </MarkRead>
        )}
      </Box>
    </HeaderContainer>
  );
});

const Notification = observer(({ notification }) => {
  const { t, userStore } = useStore();
  return (
    <RenderNotification
      content={getContentForNotification(notification, t, userStore)}
      url={getUrlForNotification(notification)}
      notification={notification}
      title={notification.jobTitle}
      icon={getIconForNotification(notification)}
    />
  );
});

const RenderNotification = observer(({ notification, title, icon, content, url }) => {
  const { notificationStore, uiSettings } = useStore();
  const unread = !notification.isRead;
  const date = formatDistanceToNow(new Date(notification.created), { addSuffix: true });
  return (
    <NotificationContainer unread={unread}>
      <NotificationHeader
        date={date}
        unread={unread}
        notification={notification}
        title={title}
        icon={icon}
      />
      <NotificationContent
        content={content}
        url={url}
        onClick={() => {
          if (unread) {
            notificationStore.markAsRead(notification.ids);
          }
          uiSettings.closeNotifications();
        }}
      />
    </NotificationContainer>
  );
});

const getUrlForNotification = n => {
  switch (n.notificationType) {
    case 'ADD_RATING':
      return n.ratingsUrl;
    case 'ADD_NOTE':
      return n.notesUrl;
    case 'INTEGRATION':
      return n.integrationUrl;
    case 'APPLICATION_MESSAGE':
      return n.messagesUrl;
    case 'EXPIRE_JOB':
      return n.jobUrl;
    default:
      return n.applicationUrl;
  }
};

const getColorPropsForIntegration = name => {
  if (name === 'Onboarding Form') {
    return { bgColor: 'green100', color: 'white100' };
  }
  return { bgColor: 'white100', color: 'purple100' };
};

const getIconForNotification = n => {
  if (n.triggerName) {
    return (
      <IconContainer {...getColorPropsForIntegration(n.triggerName)}>
        <IntegrationIcon integrationName={n.triggerName} />
      </IconContainer>
    );
  }

  if (n.notificationType === 'APPLY_JOB') {
    return (
      <IconContainer bgColor="green100">
        <IconPersonAdd />
      </IconContainer>
    );
  }

  if (n.notificationType === 'APPLICANT_HIRED') {
    return (
      <IconContainer bgColor="green100">
        <IconNewHire />
      </IconContainer>
    );
  }

  if (n.notificationType === 'EXPIRE_JOB') {
    return (
      <IconContainer bgColor="red100">
        <IconCalendar />
      </IconContainer>
    );
  }

  return n.user ? (
    <UserAvatar src={n.user.picture} name={n.user.name} size="lg" />
  ) : (
    <IconContainer bgColor="green100" />
  );
};

const NotificationHeader = observer(({ icon, title, notification, date, unread }) => {
  const { notificationStore, t } = useStore();
  return (
    <Box flex alignItems="center">
      {icon}
      <Box ml="8" grow>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationDate>{date}</NotificationDate>
      </Box>
      {unread && (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            notificationStore.markAsRead(notification.ids);
          }}
          data-tip={t('notifications.tooltips.mark_as_read')}
          data-for="notTip"
          trans
          data-unread={unread}
        >
          <IconNewIndicator />
        </IconButton>
      )}
    </Box>
  );
});

const NotificationList = observer(({ notifications }) => {
  return notifications.map(n => <Notification key={n.id} notification={n} />);
});

const NotificationContent = observer(({ content, url, onClick }) => {
  if (url) {
    return (
      <Link to={url} onClick={onClick}>
        <ContentText hasLink dangerouslySetInnerHTML={{ __html: content }} />
      </Link>
    );
  }
  return <ContentText dangerouslySetInnerHTML={{ __html: content }} />;
});

const NotificationTitle = observer(props => (
  <Text as="div" type="semibold14" color="purple90" {...props} />
));
const NotificationDate = observer(props => (
  <Text as="div" type="regular12" color="purple60" {...props} />
));

const getContentForNotification = (notification, t, userStore) => {
  const user = notification.user ? (notification.user.isMe ? 'You' : notification.user.name) : '';
  const applicant = notification.applicationId ? notification.applicationName : null;
  const notificationType = notification.notificationType;
  const instances = notification.instances;
  const multiple = instances > 1;

  switch (notificationType) {
    case 'APPLICATION_MESSAGE':
      return multiple
        ? t('notifications.multiple.message', instances)
        : t('notifications.single.message', applicant);
    case 'APPLICANT_HIRED':
      return multiple
        ? t('notifications.multiple.applicant_hired', user, instances)
        : t('notifications.single.applicant_hired', user, applicant);
    case 'APPLICANT_REJECTED':
      return multiple
        ? t('notifications.multiple.applicant_rejected', user, instances)
        : t('notifications.single.applicant_rejected', user, applicant);
    case 'APPLICANT_ASSIGNED':
      return multiple
        ? t('notifications.multiple.applicant_assigned', user, instances)
        : t('notifications.single.applicant_assigned', user, applicant);
    case 'MOVE_STAGE':
      return multiple
        ? t(
            'notifications.multiple.move_stage',
            user,
            instances,
            notification.beforeText,
            notification.afterText
          )
        : t(
            'notifications.single.move_stage',
            user,
            applicant,
            notification.beforeText,
            notification.afterText
          );
    case 'ARCHIVE_JOB':
      return t('notifications.single.job_archived', user);
    case 'PUBLISH_JOB':
      return t('notifications.single.job_published', user);
    case 'EXPIRE_JOB':
      return t('notifications.single.job_expired');
    case 'ADD_TO_HIRING_TEAM':
      const affectedUser = userStore.items.get(notification.objectId);
      return multiple
        ? t('notifications.multiple.add_to_hiring_team', user, instances)
        : t(
            'notifications.single.add_to_hiring_team',
            user,
            affectedUser.isMe ? 'You' : affectedUser.name
          );
    case 'APPLY_JOB':
      return multiple
        ? t('notifications.multiple.new_applications', instances)
        : t('notifications.single.new_applications', applicant);
    case 'ADD_RATING':
      return multiple
        ? t('notifications.multiple.add_rating', user, instances)
        : t('notifications.single.add_rating', user, applicant);
    case 'ADD_NOTE':
      return multiple
        ? t('notifications.multiple.add_note', user, instances)
        : t('notifications.single.add_note', user, applicant);
    case 'BULK_REJECT_APPLICANTS':
      return multiple
        ? t('notifications.single.bulk_reject', user, notification.applicationIds.length)
        : t('notifications.single.bulk_reject', user, notification.applicationIds.length);
    case 'INTEGRATION':
      switch (notification.triggerType) {
        case 'ONBOARDING_FORM_SEND':
          return t('notifications.integrations.onboarding_form.send', user, applicant);
        case 'ONBOARDING_FORM_FINISHED':
          return t('notifications.integrations.onboarding_form.finished', applicant);
        default:
          return '';
      }
    default:
      return '';
  }
};

const ContentText = styled(({ hasLink, ...rest }) => (
  <Text mt="8" type="regular14" color="purple90" {...rest} />
))(p => ({
  ...(p.hasLink && {
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  })
}));

const NotificationContainer = styled.div(p => ({
  borderTop: '0.0625rem solid ' + p.theme.colors.purpleTrans5,
  padding: '1rem 2.5rem',
  ...(p.unread && { backgroundColor: p.theme.colors.bgGray }),
  '[data-unread]': {
    display: 'none'
  },
  ':hover': {
    '[data-unread]': {
      display: 'block'
    },
    '[data-unread=true]': {
      color: p.theme.colors.green100
    }
  },
  [p.theme.mobileQuery]: {
    padding: '1rem 1.5rem'
  }
}));

const IconContainer = styled.div(p => ({
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: p.bgColor ? p.theme.colors[p.bgColor] : 'transparent',
  color: p.color ? p.theme.colors[p.color] : p.theme.colors.white100
}));

const MarkRead = styled(Text)(p => ({
  transition: 'color 150ms ease-out',
  lineHeight: '1.25rem',
  ':hover': {
    cursor: 'pointer',
    color: p.theme.colors.green100
  }
}));

const Container = styled.div(p => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  opacity: p.open ? 1 : 0,
  left: p.open ? (p.sidenavOpen ? '10rem' : '2rem') : p.sidenavOpen ? '-15rem' : '-21.75rem',
  paddingLeft: '2.5rem',
  width: '26.25rem',
  borderRadius: '0.25rem 0 0 0.25rem',
  backgroundColor: p.theme.colors.white100,
  boxShadow: p.open ? '0 10px 20px 6px rgba(0, 0, 0, 0.06)' : '0',
  zIndex: 90,
  transition: 'all 0.5s cubic-bezier(0.45, 0.6, 0.24, 1.3)',
  display: 'flex',
  flexDirection: 'column',
  [p.theme.mobileQuery]: {
    left: p.open ? '0%' : '-100%',
    right: 0,
    width: '100%',
    paddingLeft: 0,
    zIndex: 999,
    transition: 'left 0.2s, opacity 0.2s'
  }
}));

const HeaderContainer = styled.div(p => ({
  padding: '2.5rem 2.5rem 1rem 2.5rem',
  [p.theme.mobileQuery]: {
    padding: '2.5rem 1.5rem 1rem 1.5rem'
  }
}));
