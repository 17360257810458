import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch, useParams } from 'react-router';

import { useStore } from 'models/Provider';
import { Button } from 'components/Button';
import { BreadCrumb, BreadCrumbPath } from 'components/BreadCrumb';
import { Header } from 'components/Header';
import { Tab, useTabState, TabList } from 'components/Tabs';
import { Link } from 'react-router-dom';
import { PageContent } from 'components/Page';
import Tasklists from './Tasklists';
import { EmailTemplates, EmailTemplateEdit } from './EmailTemplates';
import { IconPlus } from 'components/icons/IconPlus';
import { useMobile } from 'hooks/useMobile';

export const Templates = observer(() => {
  const { templateStore, t } = useStore();
  const { section, templateID } = useParams();
  const { emailTemplates, tasklistTemplates } = templateStore;

  const template = useMemo(() => {
    if (!section || templateID === 'new') return null;
    switch (section) {
      case 'tasks':
        return tasklistTemplates.find(({ id }) => id === Number(templateID));
      case 'emails':
      default:
        return emailTemplates.find(({ id }) => id === Number(templateID));
    }
  }, [section, templateID, emailTemplates, tasklistTemplates]);

  return (
    <>
      <Header
        mobileTitle={t('company.templates.crumbs.root')}
        actions={<TemplateActions />}
        tabs={<TemplateTabs />}
        breadcrumbs={<TemplateCrumbs template={template} />}
      />
      <PageContent>
        <Switch>
          <Route path="/templates/tasks/:tasklistID?" component={Tasklists} />
          <Route path="/templates/emails/new" component={EmailTemplateEdit} />
          <Route
            exact
            path="/templates/emails/:templateID"
            render={() => {
              if (!template) return null;
              return <EmailTemplateEdit template={template} />;
            }}
          />
          <Route exact path="/templates/emails/" component={EmailTemplates} />
        </Switch>
      </PageContent>
    </>
  );
});

const TemplateCrumbs = observer(({ template }) => {
  const { t } = useStore();
  const { section, templateID } = useParams();

  return (
    <BreadCrumb>
      <BreadCrumbPath active={!section}>{t('company.templates.crumbs.root')}</BreadCrumbPath>
      {section && (
        <BreadCrumbPath active={!templateID} link={`/templates/${section}`}>
          {section}
        </BreadCrumbPath>
      )}
      {template && <BreadCrumbPath active>{template.title || template.name}</BreadCrumbPath>}
      {templateID && !template && <BreadCrumbPath active>New template</BreadCrumbPath>}
    </BreadCrumb>
  );
});

const TemplateActions = observer(() => {
  const { hasPerm, t } = useStore();
  const isMobile = useMobile();
  const { section, templateID } = useParams();

  if (templateID) return null;
  if (!hasPerm('company:view-templates')) return null;
  if (section !== 'emails') return null;

  return (
    <Link to="/templates/emails/new" style={{ width: isMobile ? '100%' : 'auto' }}>
      <Button fullWidth={isMobile} icon={IconPlus}>
        {t('company.templates.addTemplate')}
      </Button>
    </Link>
  );
});

const TemplateTabs = observer(() => {
  const { t, hasPerm } = useStore();
  const { section } = useParams();
  if (!hasPerm('company:view-templates')) return null;

  const tabId = templateSectionToId(section);
  const tabState = useTabState({ selectedId: tabId });
  return (
    <TabList state={tabState} label="Template selection">
      <Tab link="/templates/emails">{t('company.templates.tabs.emails')}</Tab>
      <Tab link="/templates/tasks">{t('company.templates.tabs.tasks')}</Tab>
    </TabList>
  );
});

const templateSectionToId = section => {
  if (section === 'emails') return 0;
  return 1;
};
