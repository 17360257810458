import { types as t } from 'mobx-state-tree';

import { Application } from 'models/applications';
import { UserRef } from 'models/users';

export const ApplicationReview = t
  .model('ApplicationReview', {
    id: t.identifierNumber,
    application: t.maybe(t.reference(t.late(() => Application))),
    user: t.maybe(t.late(() => UserRef)),
    doesThink: t.maybe(t.enumeration('Review', ['', 'S', 'Y', 'N', 'D'])),
    thumb: t.maybe(t.enumeration('Review', ['', 'S', 'Y', 'N', 'D']))
  })
  .actions(self => ({
    setRating(rating) {
      self.doesThink = rating;
      self.thumb = rating;
    }
  }));
