import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'models/Provider';
import { Menu, MenuItem, MenuItems, TransparentMenuButton } from 'components/Menu';
import { IconOptions } from 'components/icons/IconOptions';

export const EmailTemplateOptions = observer(({ template }) => {
  const { t, hasPerm, openModal } = useStore();
  if (!hasPerm('company:edit-templates')) return null;

  const deleteTemplate = () => {
    openModal('CUSTOM_CONFIRM', {
      submit: null,
      danger: true,
      data: {
        text: (
          <div
            dangerouslySetInnerHTML={{
              __html: t('company.templates.email.delete.text', template.title || template.name)
            }}
          />
        )
      },
      confirm: template.delete
    });
  };

  if (template.isDefault) return null;

  return (
    <Menu label="Email template actions">
      <TransparentMenuButton>
        <IconOptions />
      </TransparentMenuButton>
      <MenuItems>
        <MenuItem variant="danger" onClick={deleteTemplate}>
          {t('company.templates.email.menu.delete')}
        </MenuItem>
      </MenuItems>
    </Menu>
  );
});
