import { getEnv, isRoot, getRoot } from 'mobx-state-tree';

export const withEnv = instance => {
  const env = getEnv(instance);

  if (isRoot(instance)) {
    return {
      get env() {
        return env;
      }
    };
  }

  const root = getRoot(instance);
  return {
    get env() {
      return {
        t: env.t,
        notify: env.notify,
        openModal: env.openModal,
        openFullscreenModal: env.openFullscreenModal,
        theme: env.theme,
        router: env.router,

        current: root,
        company: root,
        api: root.api,
        jobStore: root.jobs,
        userStore: root.users,
        applicationStore: root.applications,
        applicationPictureStore: root.applicationPictures,
        me: root.users.me
      };
    }
  };
};
