import React from 'react';
import { Svg } from 'components/icons/Svg';

export const IconApplication20 = props => (
  <Svg {...props} viewBox="0 0 20 20">
    <path
      d="M8 7h7M8 10h7M8 13h7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 14a1 1 0 100-2 1 1 0 000 2zM5 11a1 1 0 100-2 1 1 0 000 2zM5 8a1 1 0 100-2 1 1 0 000 2z"
      fill="currentColor"
    />
  </Svg>
);
