import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import MultiSelectDropDown from 'shared/modules/MultiSelectDropDown';

import { useOnDDClick } from 'hooks/useOnDDClick';
import { useStore } from 'models/Provider';
import { sortBy } from 'helpers';
import { Button } from 'components/Button';
import { ModalActions, ModalHeader } from 'components/Modal';
import { Loading } from 'components/Loading';
import { Box } from 'components/Box';
import { NamePlate } from 'components/NamePlate';
import { RecruiterItem } from './styles';

function AddRecruiterModal({ job, closeModal }) {
  const { userStore, t } = useStore();
  const [adding, setAdding] = useState(false);
  const [selected, setSelected] = useState([]);
  const onDDClick = useOnDDClick('add-recruiter-modal');

  async function addUsersToJob() {
    setAdding(true);
    for (const user of selected) {
      await user.grantJobAccess(job.id, true);
    }
    setAdding(false);
    closeModal();
  }

  function usersWithoutSelected() {
    const users = userStore.list.filter(u => {
      if (u.role === 'user' || !u.isActive) return false;
      if (job.activeFollowers.includes(u)) return false;
      return selected.filter(s => u.id === s.id).length === 0;
    });
    return sortBy(users, 'name');
  }

  function removeUserFromSelection(user) {
    setSelected(old => [...old.filter(u => u.id !== user.id)]);
  }

  function selectUser(user) {
    setSelected(old => [...old, user]);
  }

  const users = usersWithoutSelected();

  return (
    <>
      <ModalHeader>{t('modals.addrecruiter.heading')}</ModalHeader>
      {adding && <Loading />}

      <div id="add-recruiter-modal" onClick={onDDClick}>
        <MultiSelectDropDown
          text={
            users.length > 0
              ? t('modals.addrecruiter.select')
              : t('modals.addrecruiter.select.empty')
          }
        >
          {users.map(u => (
            <RecruiterItem key={u.id} onClick={() => selectUser(u)}>
              <NamePlate name={u.name} size="md" pic={u.picture} title={u.roleDisplay} />
            </RecruiterItem>
          ))}
        </MultiSelectDropDown>
      </div>

      <Box mt="12">
        {selected.map(s => (
          <RecruiterItem selected key={s.id}>
            <NamePlate
              name={s.name}
              size="md"
              pic={s.picture}
              title={s.roleDisplay}
              onRemove={() => removeUserFromSelection(s)}
            />
          </RecruiterItem>
        ))}
      </Box>

      <ModalActions>
        <Button gray onClick={closeModal} disabled={adding}>
          {t('modals.addrecruiter.button.cancel')}
        </Button>
        {selected.length > 0 && (
          <Button onClick={addUsersToJob} disabled={adding}>
            {t('modals.addrecruiter.button.submit')}
          </Button>
        )}
      </ModalActions>
    </>
  );
}

export default observer(AddRecruiterModal);
