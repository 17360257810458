import { types as t, destroy, clone, getParent } from 'mobx-state-tree';
import { sortByOrder } from 'shared/utils/sorting';
import { updateMSTObject } from 'helpers';

import { TaskTemplate } from 'models/templates';

export const TasklistTemplate = t
  .model('TasklistTemplate', {
    id: t.identifierNumber,
    name: t.optional(t.string, ''),
    items: t.array(TaskTemplate, [])
  })
  .views(self => ({
    get parent() {
      return getParent(self, 2);
    }
  }))
  .actions(self => ({
    afterCreate() {
      self.items.sort(sortByOrder);
    },
    setName(newName) {
      self.name = newName;
    },
    add(item) {
      self.items.push(item);
    },
    removeTaskTemplate(item) {
      destroy(item);
    },
    delete() {
      return self.parent.removeTasklistTemplate(self);
    },
    update(data) {
      updateMSTObject(self, data);
    },
    moveItem(from, to) {
      const item = clone(self.items[from]);
      self.items.splice(from, 1);
      self.items.splice(to, 0, item);
      self.items.forEach((item, index) => (item.order = index));
    },
    addTask() {
      self.items.push({
        order: self.items.length,
        text: '',
        description: '',
        new: true
      });
    }
  }));
