import { types as t, flow, getParent } from 'mobx-state-tree';
import { withEnv } from 'models/withEnv';

import { IntegrationData } from 'models/integrations';

const CompanyIntegrationDataHeader = t
  .model('CompanyIntegrationDataHeader', {
    field: t.string,
    title: t.string,
    picture: t.maybeNull(t.string),
    type: t.maybeNull(t.string),
    ordering: t.maybeNull(t.string)
  })
  .views(self => ({
    get store() {
      return getParent(self, 6);
    },
    get parent() {
      return getParent(self, 4);
    },
    get isOrderingBy() {
      return [self.ordering, `-${self.ordering}`].includes(self.store.queryParams.ordering);
    },
    get orderingMethod() {
      if (self.store.queryParams.ordering === self.ordering) return 'asc';
      if (self.store.queryParams.ordering === `-${self.ordering}`) return 'desc';
      return null;
    }
  }))
  .actions(self => ({
    setOrderValue(value) {
      if (self.store.queryParams.ordering === value) {
        self.store.updateQueryString(self.parent.integrationName, 'ordering', '-' + value);
      } else {
        self.store.updateQueryString(self.parent.integrationName, 'ordering', value);
      }
    }
  }));

const CompanyIntegrationFilter = t
  .model('CompanyIntegrationFilter', {
    field: t.string,
    title: t.string,
    type: t.enumeration(['search', 'dropdown', 'boolean']),
    values: t.maybeNull(t.array(t.frozen()))
  })
  .views(self => ({
    get store() {
      return getParent(self, 6);
    },
    get parent() {
      return getParent(self, 4);
    },
    get value() {
      return self.store.queryParams[self.field];
    }
  }))
  .actions(self => ({
    setFilterValue(value) {
      if (self.type === 'boolean') {
        if (self.value === true || self.value === 'true') {
          self.store.updateQueryString(self.parent.integrationName, self.field, false);
        } else {
          self.store.updateQueryString(self.parent.integrationName, self.field, true);
        }
      } else {
        self.store.updateQueryString(self.parent.integrationName, self.field, value);
      }
    }
  }));

const CompanyIntegrationPaginator = t
  .model('CompanyIntegrationPaginator', {
    hasPrevious: t.boolean,
    hasNext: t.boolean,
    numPages: t.number,
    current: t.number
  })
  .actions(self => ({
    shouldDisplay(pageNumber) {
      return (
        pageNumber > 1 &&
        pageNumber < self.numPages &&
        (pageNumber === self.current ||
          (self.current <= 3 && pageNumber <= 3) ||
          (self.current >= self.numPages - 2 && pageNumber >= self.numPages - 2))
      );
    }
  }));

const CompanyIntegrationData = t.model('CompanyIntegrationData', {
  tabTitle: t.identifier,
  type: t.enumeration(['table']),
  hasDetail: t.boolean,
  pagination: t.maybe(CompanyIntegrationPaginator),
  headers: t.array(CompanyIntegrationDataHeader),
  items: t.array(t.frozen()),
  total: t.number,
  current: t.number,
  filters: t.array(CompanyIntegrationFilter)
});

export const CompanyIntegration = t
  .model('CompanyIntegration', {
    integrationName: t.identifier,
    name: t.string,
    triggers: t.array(CompanyIntegrationData)
  })
  .views(withEnv)
  .actions(self => ({
    patch: flow(function*(url) {
      const res = yield self.env.api.companies.patchIntegration(url);
      if (!res.error) {
        self.store.loadIntegrationInfo(self.integrationName);
      }
    })
  }))
  .views(self => ({
    get firstTabTitle() {
      return self.triggers.length > 0 && self.triggers[0].tabTitle;
    },
    get store() {
      return getParent(self, 2);
    }
  }));

const CompanyIntegrationDetailAction = t
  .model('CompanyIntegrationDetailAction', {
    label: t.string,
    title: t.string,
    url: t.string,
    value: t.maybeNull(
      t.optional(t.union(t.string, t.number, t.boolean, t.Date, t.array(t.string)), '')
    )
  })
  .views(withEnv)
  .views(self => ({
    get store() {
      return getParent(self, 4);
    },
    get integrationName() {
      return getParent(self, 3).integrationName;
    }
  }))
  .actions(self => ({
    patch: flow(function*() {
      const res = yield self.env.api.companies.patchIntegration(self.url);
      if (!res.error) {
        self.value = res.data.value;
        self.store.loadIntegrationInfo(self.integrationName);
      }
    })
  }));

const CompanyIntegrationDetailData = t.model('CompanyIntegrationDetailData', {
  actions: t.array(CompanyIntegrationDetailAction),
  data: t.array(IntegrationData),
  application: t.frozen()
});

export const CompanyIntegrationDetail = t.model('CompanyIntegrationDetail', {
  integrationName: t.identifier,
  name: t.string,
  data: CompanyIntegrationDetailData
});
