import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components/macro';
import { FormError } from 'components/Text';
import ReactAutoComplete from 'react-autocomplete';

export const DynamicAutoComplete = observer(
  ({ initialValue, onSelect, onBlur, items, onChange, error }) => {
    const [value, setValue] = useState(initialValue);
    const [placeholder, setPlaceholder] = useState(initialValue);
    const onFocus = event => {
      setPlaceholder(value);
      onChange('');
      setValue('');
    };
    return (
      <Wrapper>
        <ReactAutoComplete
          getItemValue={item => item.id.toString()}
          items={items}
          shouldItemRender={(item, value) =>
            item.label.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.readOnly
          }
          renderItem={(item, isHighlighted) => (
            <Item isHighlighted={isHighlighted} key={item.id} readOnly={item.readOnly}>
              {item.label}
            </Item>
          )}
          onSelect={(value, item) => {
            setValue(item.label);
            onSelect(item.id);
          }}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          value={value}
          inputProps={{ placeholder: placeholder, onFocus: onFocus, onBlur: onBlur }}
          renderInput={props => {
            const { ref, ...rest } = props;
            return <InputCustom {...rest} ref={ref} />;
          }}
          isItemSelectable={item => {
            if (item.readOnly) return false;
            return true;
          }}
          menuStyle={menuStyle(items.length)}
          wrapperStyle={{}}
        />
        <FormError>{error}</FormError>
      </Wrapper>
    );
  }
);

const InputCustom = styled.input`
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: ${p => p.theme.colors.purpleTrans5};
  border: ${p => (p.error ? `1px solid ${p.theme.colors.red100}` : '1px solid transparent')};
  font-size: 1rem;
  font-weight: ${p => (p.bold ? 600 : 400)};
  color: ${p => p.theme.colors.purple80};
  opacity: ${p => (p.disabled ? '0.4' : '0.8')};
  line-height: 1.5rem;
  box-sizing: border-box;

  ${p =>
    css`
      :focus {
        ${p => !p.error && `border: 1px solid ${p.theme.colors.green100}`};
        border-radius: 0.25rem;
        outline: none;
        background-color: ${p => p.theme.colors.white100};
      }
    `};
`;

const Wrapper = styled.div`
  position: relative;
`;

const menuStyle = numItems => ({
  backgroundColor: '#ffffff',
  position: 'absolute',
  top: 0,
  left: 0,
  marginTop: 40,
  zIndex: 99,
  ...(numItems && { padding: '1rem' }),
  borderRadius: '0.25rem',
  boxShadow: '0 0.25rem 2.5rem 0 rgba(0, 0, 0, 0.1)'
});

const Item = styled.div(p => ({
  color: p.theme.colors.purple80,
  padding: '0.5rem 0.75rem',
  display: 'flex',
  alignItems: 'center',
  ...p.theme.text.regular16,
  transition: 'all 150ms ease-out',
  ...(p.readOnly && {
    fontStyle: 'italic'
  }),

  ...(p.isHighlighted && {
    outline: 'none',
    backgroundColor: p.theme.colors.purpleTrans5,
    color: p.theme.colors.green100,
    cursor: 'pointer'
  })
}));
