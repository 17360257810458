import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import MultiSelectDropDown from 'shared/modules/MultiSelectDropDown';

import { useOnDDClick } from 'hooks/useOnDDClick';
import { sortBy } from 'helpers';
import { useStore } from 'models/Provider';
import { ModalActions, ModalHeader, ModalText } from 'components/Modal';
import { Button } from 'components/Button';
import { Loading } from 'components/Loading';
import { Box } from 'components/Box';
import { NamePlate } from 'components/NamePlate';
import { RecruiterItem } from '../AddRecruiterModal/styles';

function AddApplicationAccessModal({ application, closeModal }) {
  const { userStore, t } = useStore();
  const [selected, setSelected] = useState([]);
  const [adding, setAdding] = useState(false);
  const users = usersWithoutSelected();
  const onDDClick = useOnDDClick('add-access-modal');

  async function addUsersToApplication() {
    setAdding(true);
    let success;
    if (selected.length > 1) {
      success = await application.grantFollowerAccessMulti(selected);
    } else {
      success = await application.grantFollowerAccess(selected[0]);
    }
    setAdding(false);
    if (success) {
      closeModal();
    }
  }

  function usersWithoutSelected() {
    const users = userStore.list.filter(user => {
      if (
        user.role === 'user' ||
        user.role === 'admin' ||
        selected.includes(user) ||
        application.followers.includes(user) ||
        application.job.followers.includes(user)
      ) {
        return false;
      }
      return true;
    });
    return sortBy(users, 'name');
  }

  function removeUserFromSelection(user) {
    setSelected(old => [...old.filter(u => u.id !== user.id)]);
  }

  function selectUser(user) {
    setSelected(old => [...old, user]);
  }

  return (
    <>
      <ModalHeader>{t('application.access.addmodal.header')}</ModalHeader>
      {adding && <Loading />}

      <ModalText>{t('application.access.addmodal.maintext')}</ModalText>

      <Box mt="16" id="add-access-modal" onClick={onDDClick}>
        <MultiSelectDropDown
          text={
            users.length > 0
              ? t('application.access.addmodal.select')
              : t('application.access.addmodal.noselect')
          }
        >
          {users.map(u => (
            <RecruiterItem key={u.id} onClick={() => selectUser(u)}>
              <NamePlate name={u.name} size="md" pic={u.picture} title={u.roleDisplay} />
            </RecruiterItem>
          ))}
        </MultiSelectDropDown>
      </Box>

      <Box mt="16">
        {selected.map(s => (
          <RecruiterItem selected key={s.id}>
            <NamePlate
              name={s.name}
              size="md"
              pic={s.picture}
              title={s.roleDisplay}
              onRemove={() => removeUserFromSelection(s)}
            />
          </RecruiterItem>
        ))}
      </Box>

      <ModalActions>
        <Button gray onClick={closeModal} disabled={adding}>
          {t('application.access.addmodal.button.cancel')}
        </Button>
        {selected.length > 0 && (
          <Button onClick={addUsersToApplication} disabled={adding}>
            {t('application.access.addmodal.button.submit')}
          </Button>
        )}
      </ModalActions>
    </>
  );
}

export default observer(AddApplicationAccessModal);
